import React from 'react';
import { Icon } from 'framework7-react';

import './style.less';

const ViewDetails = ({ count }: { count: number }) => (
  <div className="view-details">
    <Icon className="ic-view" /><span>{count}</span>
  </div>
)

export default ViewDetails