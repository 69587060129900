import React, { FC, memo, useEffect, useState } from "react";
import { Profile } from "../../reducers/sessionReducer";
import { Block, BlockTitle, Button, Col, f7, List, ListItem, Page, Preloader, Row } from "framework7-react";
import DeliveryInfoEditForm, { DeliveryErrorMessages } from "../DeliveryInfoEditForm";
import ProductInfo from "./ProductInfo";
import { OrderWsControllerApi } from "../../types/paymentapi";
import { getProfile } from "../../selectors/profile";
import { IApplicationStore } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Router } from "framework7/modules/router/router";
import BigNumber from "bignumber.js";
import ExternalPayment from "../../pages/cart/checkout/ExternalPayment";
import Navbar from "../navbar";

interface ThirdPartyOrderProps {
  profile?: Profile;
  calculateDeliveryLoading?: boolean;
}

export type DeliveryAddress = {
  city: string;
  countryCode: string;
  firstAddressLine: string;
  postalCode: string;
  state?: string;
  uid?: string;
  dueDate: string;
};

export interface Product {
  id: number;
  link: string;
  quantity: number;
  comment: string;
  priceInCad?: string;
  calculatedPrice?: string;
  errors: { [key: string]: string };
}

const homeUrl = process.env.HOME_URL;
const defaultFirstProduct = {
  id: 0,
  link: "",
  quantity: 1,
  comment: "",
  priceInCad: "",
  errors: {},
};

const ThirdPartyOrder: FC<ThirdPartyOrderProps & DeliveryAddress> = ({ $f7router }: Router.Route) => {
  //third party info
  const { t } = useTranslation();

  //product
  const [products, setProducts] = useState<Product[]>([defaultFirstProduct]);
  const [deliveryErrorMessages, setDeliveryErrorMessages] = useState<DeliveryErrorMessages>({
    city: "",
    countryCode: "",
    firstAddressLine: "",
    postalCode: "",
    dueDate: "",
  });
  const [productNextId, setProductMaxId] = useState(1);

  //delivery info
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>({
    city: "",
    countryCode: "",
    firstAddressLine: "",
    postalCode: "",
    dueDate: "",
  });

  //Login && register popup
  const [isLoginPopupOpened, setIsLoginPopupOpened] = useState(false);
  const [activeTrigger, setActiveTrigger] = useState(false);

  const profile = useSelector((state: IApplicationStore) => getProfile(state));

  const [calculating, setCalculating] = useState(false);
  const [calculated, setCalculated] = useState(false);

  const [summary, setSummary] = useState({
    amountTotal: 0,
    totalShippingPrice: 0,
    maxBonusMoneyAmountForOrder: 0,
    items: "",
    delivery: "",
    bonusPoints: 0,
    amountToPay: 0,
    total: "",
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<null | string>(null);
  const buyerCurrencyCode = useSelector((state: IApplicationStore) => state.rootReducer.localConfig.buyerCurrencyCode);

  useEffect(() => {
    if (profile && !profile.uid && activeTrigger) setIsLoginPopupOpened(true);
  }, [profile, activeTrigger]);

  useEffect(() => {
    activeTrigger && setTimeout(() => setActiveTrigger(false), 1000);
  }, [activeTrigger]);

  const openCustomDialog = (close: boolean) => {
    if (!close) {
      f7.dialog.close();
    } else {
      f7.dialog.preloader(t("wait please...").toString());
    }
  };

  const validateProducts = () => {
    let errorsCount = 0;
    const newProducts = products.map((product) => {
      const newProduct = { ...product };
      Object.keys(product)
        .filter((key) => key !== "id" && key !== "errors")
        .forEach((fieldName) => {
          const [error, value] = validateProduct(fieldName, product[fieldName]);
          if (error) errorsCount++;
          newProduct[fieldName] = value;
          newProduct["errors"] = { ...newProduct.errors, [fieldName]: error };
        });
      return newProduct;
    });
    setProducts(newProducts);
    return !!errorsCount;
  };

  const validatePaymentMethods = () => {
    if (selectedPaymentMethod !== null) return false;
    f7.dialog.alert(t("Please select payment method").toString(), t("Error").toString());
    return true;
  };

  const validateDelivery = () => {
    let errorDeliveryFlag = false;
    let newDeliveryErrorMessages = {};
    Object.entries(deliveryAddress)
      .filter(([key]) => key !== "postalCode")
      .forEach(([key, value]) => {
        if (value) return;
        newDeliveryErrorMessages[key] = t("Please fill out this field.").toString();
        errorDeliveryFlag = true;
      });
    setDeliveryErrorMessages(newDeliveryErrorMessages);
    return !!errorDeliveryFlag;
  };

  const buildData = () => {
    return {
      deliveryAddress: {
        uid: "",
        city: deliveryAddress.city,
        countryCode: deliveryAddress.countryCode,
        firstAddressLine: deliveryAddress.firstAddressLine,
        postalCode: deliveryAddress.postalCode,
      },
      paymentMethod: selectedPaymentMethod,
      dueDate: deliveryAddress.dueDate,
      externalProductList: products
        .map((product) => {
          const priceInCad = parseFloat(product.priceInCad);
          if (isNaN(priceInCad) || priceInCad === 0) return null;
          return {
            buyerCurrencyCode,
            buyerPrice: priceInCad,
            comment: product.comment,
            productUrl: product.link,
            quantity: product.quantity,
          };
        })
        .filter((product) => product !== null),
    };
  };

  const validateProduct = (fieldName, value): [string | null, any] => {
    switch (fieldName) {
      case "link":
        if (!value) return [t("Please enter URL").toString(), value];
        return [value && !validURL(value) ? t("Please enter right URL").toString() : null, value];
      case "comment":
        return [value === "" ? t("Please fill in the field").toString() : null, value];
      case "quantity":
        const intValue = parseInt(value);
        const error = intValue < 1 ? t("min value: 1").toString() : intValue > 999 ? t("max value 999").toString() : "";
        return [error || null, intValue < 1 ? 1 : intValue > 999 ? 999 : intValue];
      case "priceInCad":
        if (!value) return [t("Please enter price").toString(), value];
        return [value && value <= 0 ? t("Please enter price above 0").toString() : null, value];
    }
    return [null, value];
  };

  const sendOrder = async () => {
    openCustomDialog(true);
    const data = buildData();
    const errorDeliveryFlag = validateDelivery();
    const errorProductsFlag = validateProducts();
    const errorPaymentFlag = validatePaymentMethods();
    let title = "";
    let text = "";
    let isError = false;
    if (!errorDeliveryFlag && !errorProductsFlag && !errorPaymentFlag) {
      try {
        const result = await new OrderWsControllerApi().createExternalProductsPurchaseOrderUsingPOST({
          ...data,
          bonusMoneyAmount: summary.maxBonusMoneyAmountForOrder,
        });
        if (result.errorData) {
          title = t("Error");
          text = result.errorData.errorMessage;
          isError = true;
        } else {
          title = t("Successful");
          text = t("successExternalOrderDescription");
        }
      } catch (error) {
        title = t("Error");
        text = error.message;
        isError = true;
      } finally {
        setCalculating(false);
      }
    }
    openCustomDialog(false);
    f7.dialog.alert(text, title, () => {
      setProducts([]);
      setProducts([defaultFirstProduct]);
      setCalculated(false);
      setProductMaxId(1);
    });
  };

  const onDeliveryChange = (fieldName, value) => {
    setDeliveryErrorMessages({ ...deliveryErrorMessages, [fieldName]: "" });

    if (!value) {
      if (fieldName !== "postalCode") {
        setDeliveryErrorMessages({
          ...deliveryErrorMessages,
          [fieldName]: t("Please fill out this field.").toString(),
        });
      }
    }

    if (fieldName === "dueDate") {
      const isDateValid = moment(value).toDate() - moment().startOf("day").add(1, "days").toDate() >= 0;
      if (isDateValid) {
        setDeliveryAddress({
          ...deliveryAddress,
          [fieldName]: moment(value).startOf("hour").format("YYYYMMDD000000"),
        });
      } else {
        setDeliveryErrorMessages({
          ...deliveryErrorMessages,
          [fieldName]: t("Due date must be greater or equal to the current date").toString(),
        });
      }
    } else if (["countryCode", "city", "postalCode", "firstAddressLine"].includes(fieldName)) {
      if (!value && fieldName === "postalCode") {
        setDeliveryAddress({
          ...deliveryAddress,
          [fieldName]: value,
        });
      } else {
        setDeliveryAddress({
          ...deliveryAddress,
          [fieldName]: value,
        });
      }
    }
    setCalculated(false);
  };

  const handleClickAddProductButton = () => {
    setProducts([
      ...products,
      {
        id: productNextId,
        link: "",
        quantity: 1,
        priceInCad: "",
        comment: "",
        errors: {},
      },
    ]);
    setProductMaxId(productNextId + 1);
    profile && !profile.uid && setActiveTrigger(true);
    setCalculated(false);
  };

  const removeProduct = (id) => {
    setProducts(products.filter((product) => product.id !== id));
    profile && !profile.uid && setActiveTrigger(true);
    setCalculated(false);
  };

  const validURL = (str) => {
    try {
      new URL(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const changeProduct = (fieldName, id, value) => {
    const [error, _value] = validateProduct(fieldName, value);
    const current = products.find((p) => p.id === id);
    if (!current) return;
    const newProducts = products.map((product) => {
      if (product.id !== id) return product;
      return {
        ...product,
        [fieldName]: _value,
        errors: { ...product.errors, [fieldName]: error },
      };
    });
    profile && !profile.uid && setActiveTrigger(true);
    setCalculated(false);
    setProducts(newProducts);
  };

  const handleCalculate = async () => {
    if (profile && !profile.uid && !activeTrigger) return setActiveTrigger(true);
    const errorDeliveryFlag = validateDelivery();
    const errorProductsFlag = validateProducts();
    const errorPaymentFlag = validatePaymentMethods();
    if (errorDeliveryFlag || errorProductsFlag || errorPaymentFlag) return;
    setCalculating(true);
    const data = buildData();
    try {
      const result = await new OrderWsControllerApi().calculateExternalProductsPurchaseOrderUsingPOST(data);
      if (result.errorData) {
        f7.dialog.alert(result.errorData.errorMessage, t("Error"));
        return setCalculating(false);
      }
      const newProducts: Product[] = products.map((product, index) => {
        const externalProduct = result.externalProductList[index];
        return {
          ...product,
          link: externalProduct.productUrl,
          quantity: externalProduct.quantity,
          comment: externalProduct.comment,
          priceInCad: String(externalProduct.buyerPrice),
          calculatedPrice: String(externalProduct.calculatedPrice),
          errors: {},
        };
      });
      setProducts(newProducts);
      setSummary({
        amountTotal: result.amountTotal,
        totalShippingPrice: result.totalShippingPrice,
        maxBonusMoneyAmountForOrder: result.maxBonusMoneyAmountForOrder,
        items: new BigNumber(result.amountTotal).minus(result.totalShippingPrice).toString(),
        delivery: result.totalShippingPrice.toString(),
        bonusPoints: result.maxBonusMoneyAmountForOrder,
        amountToPay: new BigNumber(result.amountTotal).minus(result.maxBonusMoneyAmountForOrder).toNumber(),
        total: result.amountTotal.toString(),
      });
      setCalculated(true);
    } catch (err) {
      f7.dialog.alert(err.message, t("Error"));
      console.log(err);
    }
    setCalculating(false);
  };

  const logoLink = () => {
    if (homeUrl) {
      window.location.href = homeUrl;
    }
  };

  return (
    <Page id="third-party-order" name="third-party-order">
      <Navbar
        profile={profile}
        showProfileLink
        onClickLogoLink={logoLink}
        slot="fixed"
        f7router={$f7router}
        activeTrigger={activeTrigger}
      />
      <Block>
        <Row className="row-mobile">
          <Col width="70" className="div-mobile">
            <BlockTitle medium style={{ marginTop: 0 }}>
              {t("Shop in Canadian stores")}
            </BlockTitle>
            <Block strong>
              <p>1. {t("Shop in Canadian stores 1")}</p>
              <p>2. {t("Shop in Canadian stores 2")}</p>
              <p>3. {t("Shop in Canadian stores 3")}</p>
              <p>4. {t("Shop in Canadian stores 4")}</p>
              <p>5. {t("Shop in Canadian stores 5")}</p>
              <p>6. {t("Shop in Canadian stores 6")}</p>
              <p>{t("List of major Canadian stores:")}</p>
              <p>
                <strong>
                  <a href={"https://www.amazon.ca/"}>{t("Shop 1")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.thebay.com/"}>{t("Shop 2")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.bestbuy.ca/en-ca"}>{t("Shop 3")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.walmart.ca/en"}>{t("Shop 4")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://canadiantire.ca/en"}>{t("Shop 5")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.sportsexperts.ca/en-CA/"}>{t("Shop 6")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.apple.com/ca/"}>{t("Shop 7")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.simons.ca/en"}>{t("Shop 8")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://www.sephora.com/ca/en/?country_switch=ca&lang=en"}>{t("Shop 9")}</a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href={"https://eshop.marinedutyfree.com/market/#/"}>{t("Shop 10")}</a>
                </strong>
              </p>
            </Block>

            <BlockTitle medium>{t("Product Links")}</BlockTitle>
            <Block strong>
              {products.map((product) => (
                <ProductInfo
                  key={product.id}
                  product={product}
                  onProductChange={changeProduct}
                  removeProduct={removeProduct}
                  onFieldClick={() => profile && !profile.uid && setActiveTrigger(true)}
                />
              ))}
              <Button onClick={handleClickAddProductButton} className="button-add-card">
                <i className="icon ic-add-card" />
                {t("Add additional product")}
              </Button>
            </Block>
            <ExternalPayment
              onSelected={(code) => {
                setSelectedPaymentMethod(code);
                profile && !profile.uid && setActiveTrigger(true);
                setCalculated(false);
              }}
            />
            <BlockTitle medium>{t("Delivery Information")}</BlockTitle>
            <Block strong>
              <DeliveryInfoEditForm
                onChange={onDeliveryChange}
                onFieldClick={() => profile && !profile.uid && setActiveTrigger(true)}
                address={deliveryAddress}
                errors={deliveryErrorMessages}
              />
            </Block>
          </Col>
          <Col width="30" className="div-mobile">
            <div className="external-products-order-summary-container">
              <BlockTitle medium>{t("Request summary")}</BlockTitle>
              {calculated && (
                <List className="order-summary" noHairlines noChevron noHairlinesBetween>
                  <ListItem title={t("Items").toString()} after={summary.items} />
                  <ListItem title={t("Delivery").toString()} after={String(summary.delivery)} />
                  <ListItem title={t("Total").toString()} after={String(summary.amountTotal)} className="total" />
                  {summary.bonusPoints > 0 ? (
                    <ListItem title={t("Bonus Money").toString()} after={String(summary.bonusPoints)} />
                  ) : null}
                  {summary.amountToPay > 0 && summary.bonusPoints > 0 ? (
                    <ListItem
                      title={t("Amount to Pay").toString()}
                      after={String(summary.amountToPay)}
                      className="total"
                    />
                  ) : null}
                </List>
              )}
              <Block>
                {!calculated && (
                  <Button fill round large className="margin-top" onClick={handleCalculate} disabled={calculating}>
                    {calculating ? <Preloader color="white" size={24} /> : t("Calculate")}
                  </Button>
                )}
                {calculated && (
                  <Button fill round large className="margin-top" onClick={sendOrder}>
                    {t("Submit price request")}
                  </Button>
                )}
              </Block>
              <Block>
                <p style={{ textAlign: "left" }}>
                  {t("The prices in Canadian part 1")}
                  <strong>{t("The prices in Canadian part 2")}</strong>
                  {t("The prices in Canadian part 3")}
                </p>
                <p>{t("We will come back to you within 48 hours.")}</p>
              </Block>
            </div>
          </Col>
        </Row>
      </Block>
    </Page>
  );
};

export default memo(ThirdPartyOrder);
