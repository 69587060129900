import React, { Component } from "react";
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  NavRight,
  Link,
  ListItem,
  List,
} from "framework7-react";
import SortByButtonsGroup from "../components/SortByButtonsGroup";
import connectLocalConfig from "../store/connectLocalConfig";
import {
  IApplicationStore,
  ICategory,
  ILocalConfig,
} from "../store/rootReducer";
import { compose } from "redux";
import { connect } from "react-redux";
import { IFilterState, SortBy } from "../reducers/filterReducer";
import connectFilter from "../store/connectFilter";
import {
  allFiltresChooseSortBy,
  allFiltresClear,
  applyAllFiltres,
  allFiltresFill,
} from "../actions/filterActions";
import { Button } from "../components/ThemedButton";
import {
  ISearchParams,
  searchClear,
  searchProducts,
  SortBy as SortByField,
  updateSearchResultCount,
} from "../actions/productActions";
import { withTranslation, WithTranslation } from "react-i18next";

import "./all-filtres-popup.less";

type Props = WithTranslation &
  IFilterState & {
    category?: ICategory;
    chooseSortBy?(sortBy: SortBy): void;
    searchLoading?: boolean;
    totalCount?: number;
    updateSearchResultCount?(searchParams: ISearchParams): void;
    search?(searchParams: ISearchParams): void;
    clearSearch?(): void;
    clearFilter?(): void;
    applyAllFiltres?(): void;
    fillFiltres?(): void;
    localConfig?: ILocalConfig;
  };

const getItemStyles = (item: ICategory) => ({ backgroundColor: item.color });

export const getSortByFields = (sortBy: SortBy[]): SortByField[] => {
  return sortBy.map((item) => {
    return {
      direction: item === SortBy.price_high_to_low ? "DESC" : "ASC",
      field: getFieldNameBySortBy(item),
      sortingIndex:
        item === SortBy.price_high_to_low || item === SortBy.price_low_to_high
          ? 1
          : 0,
    };
  });
};

const getFieldNameBySortBy = (sortBy: SortBy) => {
  switch (sortBy) {
    case SortBy.popular:
      return "popular";
    case SortBy.price_high_to_low:
      return "price";
    case SortBy.price_low_to_high:
      return "price";
    case SortBy.sales_first:
      return "discountedPrice";
    case SortBy.what_s_new:
      return "what_s_new";
  }
};

class AllFiltresPopup extends Component<Props> {
  openHandle = () => {
    this.props.fillFiltres();
  };

  componentDidUpdate(prevProps: Props) {
    const { chosenSubcategoryId } = this.props;
    if (chosenSubcategoryId !== prevProps.chosenSubcategoryId) {
      const params: ISearchParams = {
        category: chosenSubcategoryId,
        /* sortBy: getSortByField(sortBy) */
      };
      this.props.updateSearchResultCount(params);
    }
  }

  componentWillUnmount() {
    this.props.clearFilter();
  }

  sortByClickHandle = (sortBy: SortBy) => {
    this.props.chooseSortBy(sortBy);
  };

  showGoodsHandle = () => {
    this.props.applyAllFiltres();
    this.props.clearSearch();
    this.search();
    this.$f7router.back();
  };

  clearFilterHandle = () => {
    this.props.clearFilter();
  };

  search = () => {
    const { chosenSubcategoryId, sortBy } = this.props;
    const params: ISearchParams = {
      category: chosenSubcategoryId,
    };
    if (sortBy.length) {
      params["sortBy"] = getSortByFields(sortBy);
    }
    this.props.search(params);
  };

  backLinkHandle = () => {
    this.$f7router.back("/");
  };

  render() {
    const { sortBy, category, totalCount, localConfig, t } = this.props;

    return (
      <Page
        id="all_filtres_popup"
        className="all-filtres-popup"
        onPageInit={this.openHandle}
      >
        <Navbar
          title={t("All Filtres")}
          backLink={t("Back").toString()}
          onClickBack={this.backLinkHandle}
          noHairline
          noShadow
        >
          <NavRight>
            <Link text={t("Clear")} onClick={this.clearFilterHandle} />
          </NavRight>
        </Navbar>
        <BlockTitle>{t("Choose category")}</BlockTitle>
        <List noHairlines>
          <ListItem link="/all-filtres/categories/" title={category.name}>
            <span
              slot="media"
              className="category-item"
              style={getItemStyles(category)}
            >
              <i className={`icon ${category.icon}`}/>
            </span>
          </ListItem>
        </List>
        <BlockTitle>{t("Sort by")}</BlockTitle>
        <Block>
          <SortByButtonsGroup
            selected={sortBy}
            onClick={this.sortByClickHandle}
          />
        </Block>
        {localConfig.showLocationFilter && (
          <List noHairlines>
            <ListItem
              link="/location/"
              header={t("Location").toString()}
              title="Belarus, Minsk"
            />
          </List>
        )}
        <Block>
          {category.id !== "all" && (
            <Button
              onClick={this.showGoodsHandle}
              disabled={this.props.searchLoading}
              fill
              large
              raised
              round
            >
              {t("Show goods", { totalCount })}
            </Button>
          )}
        </Block>
      </Page>
    );
  }
}

const appCategoriesItem = {
  name: "All categories",
  id: "all",
  color: "",
  icon: "ic-all-categories",
};

const mapStateToProps = (state: IApplicationStore, props: Props) => ({
  category: (state.rootReducer.localConfig &&
    state.rootReducer.localConfig.categories.filter(
      (item) => item.id === state.filterReducer.allFiltresChosenCategoryId
    )[0]) || { ...appCategoriesItem, name: props.t(appCategoriesItem.name) },

  chosenCategoryId: state.filterReducer.allFiltresChosenCategoryId,
  chosenSubcategoryId: state.filterReducer.allFiltresChosenSubcategoryId,
  sortBy: state.filterReducer.allFiltresSortBy,
  totalCount: state.productReducer.totalCount,
  searchLoading: state.productReducer.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  chooseSortBy: (sortBy: SortBy) => dispatch(allFiltresChooseSortBy(sortBy)),
  clearSearch: () => dispatch(searchClear()),
  clearFilter: () => dispatch(allFiltresClear()),
  updateSearchResultCount: (searchParams: ISearchParams) =>
    dispatch(updateSearchResultCount(searchParams)),
  search: (searchParams: ISearchParams) =>
    dispatch(searchProducts(searchParams)),
  applyAllFiltres: () => dispatch(applyAllFiltres()),
  fillFiltres: () => dispatch(allFiltresFill()),
});

export default compose(
  withTranslation(),
  connectFilter,
  connectLocalConfig,
  connect(mapStateToProps, mapDispatchToProps)
)(AllFiltresPopup) as React.ComponentType<Props>;
