import React, { FC } from "react";
import { Col, Link, List, ListItem, Navbar, NavRight, NavTitle, Page, Popup, Row } from "framework7-react";
import { LinkedCard } from "../../types/commonapi";
import { useTranslation } from "react-i18next";
import { ListInput } from "../ListInput";

interface EditCardProps {
  card?: LinkedCard;
  opened?: boolean;
  closePopup: () => void;
}

const EditCard: FC<EditCardProps> = ({ opened, card, closePopup }: EditCardProps) => {
  const { t } = useTranslation();
  return (
    <Popup id="payment_card_create" swipeToClose opened={opened} onPopupClosed={closePopup}>
      <Page>
        <Navbar noHairline noShadow>
          <NavTitle sliding>{t("View card")}</NavTitle>
          <NavRight>
            <Link popupClose>{t("Close")}</Link>
          </NavRight>
        </Navbar>
        <List noHairlinesMd form className="no-margin-top">
          <ListInput
            className="credit-card-list-item"
            name="cardNumber"
            label={t("Card Number").toString()}
            floatingLabel
            type="tel"
            maxlength={24}
            placeholder=""
            slot="list"
            disabled
            value={card ? card.maskedNumber : "0000"}
          >
            <div slot="input" className="card-type"></div>
          </ListInput>
          <ListItem slot="list" className="no-content-after">
            <Row>
              <Col>
                <ListInput
                  name="expireDate"
                  label={t("MM/YY").toString()}
                  floatingLabel
                  type="tel"
                  placeholder=""
                  wrap={false}
                  cleaveFormatInputOptions={{
                    datePattern: ["m", "y"],
                    date: true,
                  }}
                  disabled
                  required
                  value={card ? card.expYear : ""}
                />
              </Col>
              <Col>
                <ListInput
                  name="cvv"
                  label={t("CVV").toString()}
                  floatingLabel
                  type="tel"
                  maxlength={4}
                  placeholder=""
                  wrap={false}
                  cleaveFormatInputOptions={{ blocks: [3] }}
                  disabled
                  value={"***"}
                />
              </Col>
            </Row>
          </ListItem>
          <ListInput
            name="cardHolder"
            label={t("Card Holder").toString()}
            floatingLabel
            type="text"
            placeholder=""
            slot="list"
            disabled
            required
            value={card ? card.cardHolder : "undefined"}
          />
        </List>
        <List noHairlinesMd form className="no-margin-top">
          <ListInput
            name="country"
            label={t("Billing Address Country").toString()}
            placeholder=""
            slot="list"
            disabled
            value={card && card.address ? card.address.country.name : ""}
          ></ListInput>
          <ListInput
            name="city"
            label={t("Billing Address City").toString()}
            floatingLabel
            type="text"
            placeholder=""
            slot="list"
            required
            disabled
            value={card && card.address ? card.address.city : ""}
          />
          <ListInput
            name="firstAddressLine"
            label={t("Billing Address Address Line").toString()}
            floatingLabel
            type="text"
            placeholder=""
            slot="list"
            required
            disabled
            value={card && card.address ? card.address.firstAddressLine : ""}
          />
          <ListInput
            name="postalCode"
            label={t("Billing Address Postal Code").toString()}
            floatingLabel
            type="text"
            placeholder=""
            slot="list"
            required
            disabled
            value={card && card.address ? card.address.postalCode : ""}
          />
        </List>
      </Page>
    </Popup>
  );
};

export default EditCard;
