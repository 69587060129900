import React from "react";
import { Link, Icon } from "framework7-react";
import classNames from "classnames";

import "./style.less";

export default (props: Link.Props): JSX.Element => {
  const { className, text, ...rest } = props;
  return (
    <Link className={classNames("to-messenger-button", className)} {...rest}>
      <Icon material="arrow_back" />
      <span>{text}</span>
    </Link>
  );
};
