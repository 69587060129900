import React, { ReactElement, ReactNode } from "react";
import { Sheet } from "framework7-react";
import "./style.less";

type SheetComponentProps = Sheet.Props & Readonly<{ children?: ReactNode }>;

const SheetComponent = ({ children, ...props }: SheetComponentProps): ReactElement => (
  <Sheet {...props}>{children}</Sheet>
);

export default SheetComponent;
