/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from "url";
import portableFetch from "../../axios/portableFetchWrapper";
import { Configuration } from "./configuration";

const BASE_PATH = "/marketplaceapi".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {string}
   * @memberof Account
   */
  companyLegalAddress?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  companyRegistrationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  countryCode?: string;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  creationDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  isAllowedSellUsingMarketServices?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  lastLoginDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  profilePictureUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface AccountCategory
 */
export interface AccountCategory {
  /**
   *
   * @type {string}
   * @memberof AccountCategory
   */
  accountUid?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCategory
   */
  externalCategoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCategory
   */
  externalCategoryName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCategory
   */
  internalCategoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCategory
   */
  internalCategoryName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCategory
   */
  internalCategoryPath?: string;
}

/**
 *
 * @export
 * @interface ActiveFeatureDetails
 */
export interface ActiveFeatureDetails {
  /**
   *
   * @type {Date}
   * @memberof ActiveFeatureDetails
   */
  expireDate?: Date;
  /**
   *
   * @type {LocalTime}
   * @memberof ActiveFeatureDetails
   */
  startAt?: LocalTime;
  /**
   *
   * @type {Date}
   * @memberof ActiveFeatureDetails
   */
  startDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ActiveFeatureDetails
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveFeatureDetails
   */
  typeDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveFeatureDetails
   */
  typeName?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveFeatureDetails
   */
  typeUid?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveFeatureDetails
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface AddProductReviewImageRequest
 */
export interface AddProductReviewImageRequest {
  /**
   *
   * @type {string}
   * @memberof AddProductReviewImageRequest
   */
  imageUrl?: string;
}

/**
 *
 * @export
 * @interface AddProductReviewRequest
 */
export interface AddProductReviewRequest {
  /**
   *
   * @type {boolean}
   * @memberof AddProductReviewRequest
   */
  anonymously?: boolean;
  /**
   *
   * @type {number}
   * @memberof AddProductReviewRequest
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof AddProductReviewRequest
   */
  text?: string;
}

/**
 *
 * @export
 * @interface AddPromotionPageProductRequest
 */
export interface AddPromotionPageProductRequest {
  /**
   *
   * @type {string}
   * @memberof AddPromotionPageProductRequest
   */
  productUid?: string;
}

/**
 *
 * @export
 * @interface AddPromotionPageRequest
 */
export interface AddPromotionPageRequest {
  /**
   *
   * @type {string}
   * @memberof AddPromotionPageRequest
   */
  title?: string;
}

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  coordinates?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  firstAddressLine?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  placeId?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  secondAddressLine?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state?: string;
}

/**
 *
 * @export
 * @interface Banner
 */
export interface Banner {
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  targetUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface BaseUidObject
 */
export interface BaseUidObject {
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof BaseUidObject
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface BuildProperties
 */
export interface BuildProperties {
  /**
   *
   * @type {string}
   * @memberof BuildProperties
   */
  artifact?: string;
  /**
   *
   * @type {string}
   * @memberof BuildProperties
   */
  group?: string;
  /**
   *
   * @type {string}
   * @memberof BuildProperties
   */
  name?: string;
  /**
   *
   * @type {Date}
   * @memberof BuildProperties
   */
  time?: Date;
  /**
   *
   * @type {string}
   * @memberof BuildProperties
   */
  version?: string;
}

/**
 *
 * @export
 * @interface CalculateDeliveryRequest
 */
export interface CalculateDeliveryRequest {
  /**
   *
   * @type {string}
   * @memberof CalculateDeliveryRequest
   */
  cartUid?: string;
  /**
   *
   * @type {Address}
   * @memberof CalculateDeliveryRequest
   */
  deliveryAddress?: Address;
  /**
   *
   * @type {Date}
   * @memberof CalculateDeliveryRequest
   */
  deliveryDate?: Date;
  /**
   *
   * @type {Array<CartItem>}
   * @memberof CalculateDeliveryRequest
   */
  items?: Array<CartItem>;
}

/**
 *
 * @export
 * @interface CalculateDeliveryResponse
 */
export interface CalculateDeliveryResponse {
  /**
   *
   * @type {number}
   * @memberof CalculateDeliveryResponse
   */
  bonusMoneyAmountForOrder?: number;
  /**
   *
   * @type {number}
   * @memberof CalculateDeliveryResponse
   */
  bonusMoneyAmountTotal?: number;
  /**
   *
   * @type {Array<ProductDelivery>}
   * @memberof CalculateDeliveryResponse
   */
  productDeliveryList?: Array<ProductDelivery>;
  /**
   *
   * @type {string}
   * @memberof CalculateDeliveryResponse
   */
  shippingPriceCurrency?: string;
  /**
   *
   * @type {number}
   * @memberof CalculateDeliveryResponse
   */
  totalShippingPrice?: number;
}

/**
 *
 * @export
 * @interface Cart
 */
export interface Cart {
  /**
   *
   * @type {string}
   * @memberof Cart
   */
  accountUid?: string;
  /**
   *
   * @type {Date}
   * @memberof Cart
   */
  createDate?: Date;
  /**
   *
   * @type {Array<CartItem>}
   * @memberof Cart
   */
  items?: Array<CartItem>;
  /**
   *
   * @type {string}
   * @memberof Cart
   */
  orderUid?: string;
  /**
   *
   * @type {string}
   * @memberof Cart
   */
  status: Cart.StatusEnum;
  /**
   * Card UID. Required for update operations only.
   * @type {string}
   * @memberof Cart
   */
  uid?: string;
  /**
   *
   * @type {Date}
   * @memberof Cart
   */
  updateDate?: Date;
}

/**
 * @export
 * @namespace Cart
 */
export namespace Cart {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    O = <any>"O",
  }
}

/**
 *
 * @export
 * @interface CartItem
 */
export interface CartItem {
  /**
   *
   * @type {Array<DeliveryMethod>}
   * @memberof CartItem
   */
  availableDeliveryMethods?: Array<DeliveryMethod>;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  availableQuantity?: number;
  /**
   *
   * @type {boolean}
   * @memberof CartItem
   */
  deliveryConfigured?: boolean;
  /**
   *
   * @type {DeliveryMethodOption}
   * @memberof CartItem
   */
  deliveryMethodOption?: DeliveryMethodOption;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  imageThumbnailUrl1?: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  itemUid?: string;
  /**
   *
   * @type {Array<ProductItemParam>}
   * @memberof CartItem
   */
  parameters?: Array<ProductItemParam>;
  /**
   *
   * @type {boolean}
   * @memberof CartItem
   */
  pickupAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  productCurrencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  productDescription?: string;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  productDiscountedPrice?: number;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  productName?: string;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  productPrice?: number;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  productSalePrice?: number;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  productUid?: string;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  sellerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  sellerIban?: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  sellerName?: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  sellerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  sellerUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof CartItem
   */
  shippingAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  type?: string;
}

/**
 *
 * @export
 * @interface Classificator
 */
export interface Classificator {
  /**
   *
   * @type {string}
   * @memberof Classificator
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Classificator
   */
  dependency?: string;
  /**
   *
   * @type {string}
   * @memberof Classificator
   */
  entity?: string;
  /**
   *
   * @type {string}
   * @memberof Classificator
   */
  field?: string;
  /**
   *
   * @type {string}
   * @memberof Classificator
   */
  seoText?: string;
  /**
   *
   * @type {string}
   * @memberof Classificator
   */
  value?: string;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseAccountCategory
 */
export interface CommonMarketplaceApiResponseAccountCategory {
  /**
   *
   * @type {Array<AccountCategory>}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  body?: Array<AccountCategory>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseAccountCategory
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseBanner
 */
export interface CommonMarketplaceApiResponseBanner {
  /**
   *
   * @type {Array<Banner>}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  body?: Array<Banner>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseBanner
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseBuildProperties
 */
export interface CommonMarketplaceApiResponseBuildProperties {
  /**
   *
   * @type {Array<BuildProperties>}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  body?: Array<BuildProperties>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseBuildProperties
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseCalculateDeliveryResponse
 */
export interface CommonMarketplaceApiResponseCalculateDeliveryResponse {
  /**
   *
   * @type {Array<CalculateDeliveryResponse>}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  body?: Array<CalculateDeliveryResponse>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseCart
 */
export interface CommonMarketplaceApiResponseCart {
  /**
   *
   * @type {Array<Cart>}
   * @memberof CommonMarketplaceApiResponseCart
   */
  body?: Array<Cart>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseCart
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseCart
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseCart
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseCart
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseCart
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseCart
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseClassificator
 */
export interface CommonMarketplaceApiResponseClassificator {
  /**
   *
   * @type {Array<Classificator>}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  body?: Array<Classificator>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseClassificator
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseDeliveryMethod
 */
export interface CommonMarketplaceApiResponseDeliveryMethod {
  /**
   *
   * @type {Array<DeliveryMethod>}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  body?: Array<DeliveryMethod>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseDeliveryMethod
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseOmnideskTicket
 */
export interface CommonMarketplaceApiResponseOmnideskTicket {
  /**
   *
   * @type {Array<OmnideskTicket>}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  body?: Array<OmnideskTicket>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseOmnideskTicket
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProduct
 */
export interface CommonMarketplaceApiResponseProduct {
  /**
   *
   * @type {Array<Product>}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  body?: Array<Product>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProduct
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductCategory
 */
export interface CommonMarketplaceApiResponseProductCategory {
  /**
   *
   * @type {Array<ProductCategory>}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  body?: Array<ProductCategory>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductCategory
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductChat
 */
export interface CommonMarketplaceApiResponseProductChat {
  /**
   *
   * @type {Array<ProductChat>}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  body?: Array<ProductChat>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductChat
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductExt
 */
export interface CommonMarketplaceApiResponseProductExt {
  /**
   *
   * @type {Array<ProductExt>}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  body?: Array<ProductExt>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductExt
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductFeatureType
 */
export interface CommonMarketplaceApiResponseProductFeatureType {
  /**
   *
   * @type {Array<ProductFeatureType>}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  body?: Array<ProductFeatureType>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductFeatureType
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductReview
 */
export interface CommonMarketplaceApiResponseProductReview {
  /**
   *
   * @type {Array<ProductReview>}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  body?: Array<ProductReview>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductReview
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductSource
 */
export interface CommonMarketplaceApiResponseProductSource {
  /**
   *
   * @type {Array<ProductSource>}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  body?: Array<ProductSource>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductSource
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductSourceContent
 */
export interface CommonMarketplaceApiResponseProductSourceContent {
  /**
   *
   * @type {Array<ProductSourceContent>}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  body?: Array<ProductSourceContent>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductSourceContent
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseProductSourceType
 */
export interface CommonMarketplaceApiResponseProductSourceType {
  /**
   *
   * @type {Array<ProductSourceType>}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  body?: Array<ProductSourceType>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseProductSourceType
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponsePromotionPage
 */
export interface CommonMarketplaceApiResponsePromotionPage {
  /**
   *
   * @type {Array<PromotionPage>}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  body?: Array<PromotionPage>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponsePromotionPage
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseSale
 */
export interface CommonMarketplaceApiResponseSale {
  /**
   *
   * @type {Array<Sale>}
   * @memberof CommonMarketplaceApiResponseSale
   */
  body?: Array<Sale>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseSale
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseSale
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseSale
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseSale
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseSale
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseSale
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseSearchResult
 */
export interface CommonMarketplaceApiResponseSearchResult {
  /**
   *
   * @type {Array<SearchResult>}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  body?: Array<SearchResult>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseSearchResult
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseStatistics
 */
export interface CommonMarketplaceApiResponseStatistics {
  /**
   *
   * @type {Array<Statistics>}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  body?: Array<Statistics>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStatistics
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseStore
 */
export interface CommonMarketplaceApiResponseStore {
  /**
   *
   * @type {Array<Store>}
   * @memberof CommonMarketplaceApiResponseStore
   */
  body?: Array<Store>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStore
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStore
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStore
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseStore
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStore
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStore
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseStoreCheck
 */
export interface CommonMarketplaceApiResponseStoreCheck {
  /**
   *
   * @type {Array<StoreCheck>}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  body?: Array<StoreCheck>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStoreCheck
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseStoreHomepage
 */
export interface CommonMarketplaceApiResponseStoreHomepage {
  /**
   *
   * @type {Array<StoreHomepage>}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  body?: Array<StoreHomepage>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseStoreHomepage
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface CommonMarketplaceApiResponseUploadedImage
 */
export interface CommonMarketplaceApiResponseUploadedImage {
  /**
   *
   * @type {Array<UploadedImage>}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  body?: Array<UploadedImage>;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  count?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  successful?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  totalCount?: number;
  /**
   *
   * @type {ErrorData}
   * @memberof CommonMarketplaceApiResponseUploadedImage
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface DeliveryCountry
 */
export interface DeliveryCountry {
  /**
   *
   * @type {string}
   * @memberof DeliveryCountry
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeliveryCountry
   */
  excluded?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeliveryCountry
   */
  name?: string;
}

/**
 *
 * @export
 * @interface DeliveryMethod
 */
export interface DeliveryMethod {
  /**
   *
   * @type {Date}
   * @memberof DeliveryMethod
   */
  createDate?: Date;
  /**
   *
   * @type {string}
   * @memberof DeliveryMethod
   */
  name: string;
  /**
   *
   * @type {Array<DeliveryMethodOption>}
   * @memberof DeliveryMethod
   */
  options?: Array<DeliveryMethodOption>;
  /**
   *
   * @type {string}
   * @memberof DeliveryMethod
   */
  type: DeliveryMethod.TypeEnum;
  /**
   *
   * @type {string}
   * @memberof DeliveryMethod
   */
  typeName?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof DeliveryMethod
   */
  uid?: string;
  /**
   *
   * @type {Date}
   * @memberof DeliveryMethod
   */
  updateDate?: Date;
}

/**
 * @export
 * @namespace DeliveryMethod
 */
export namespace DeliveryMethod {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DELIVERY = <any>"DELIVERY",
    PICKUP = <any>"PICKUP",
  }
}

/**
 *
 * @export
 * @interface DeliveryMethodOption
 */
export interface DeliveryMethodOption {
  /**
   * Used for delivery options only.
   * @type {Array<DeliveryCountry>}
   * @memberof DeliveryMethodOption
   */
  countries?: Array<DeliveryCountry>;
  /**
   *
   * @type {Date}
   * @memberof DeliveryMethodOption
   */
  createDate?: Date;
  /**
   *
   * @type {string}
   * @memberof DeliveryMethodOption
   */
  currencyCode: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryMethodOption
   */
  deliveryMethodUid?: string;
  /**
   *
   * @type {number}
   * @memberof DeliveryMethodOption
   */
  deliveryTimeDaysMax: number;
  /**
   *
   * @type {number}
   * @memberof DeliveryMethodOption
   */
  deliveryTimeDaysMin: number;
  /**
   *
   * @type {string}
   * @memberof DeliveryMethodOption
   */
  description?: string;
  /**
   * Required for pickup options only.
   * @type {Address}
   * @memberof DeliveryMethodOption
   */
  pickupAddress?: Address;
  /**
   *
   * @type {number}
   * @memberof DeliveryMethodOption
   */
  price: number;
  /**
   * Delivery method option UID. Required for update operations only.
   * @type {string}
   * @memberof DeliveryMethodOption
   */
  uid?: string;
  /**
   *
   * @type {Date}
   * @memberof DeliveryMethodOption
   */
  updateDate?: Date;
}

/**
 *
 * @export
 * @interface EditProductReviewRequest
 */
export interface EditProductReviewRequest {
  /**
   *
   * @type {boolean}
   * @memberof EditProductReviewRequest
   */
  anonymously?: boolean;
  /**
   *
   * @type {number}
   * @memberof EditProductReviewRequest
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof EditProductReviewRequest
   */
  text?: string;
}

/**
 *
 * @export
 * @interface ErrorData
 */
export interface ErrorData {
  /**
   *
   * @type {number}
   * @memberof ErrorData
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ErrorData
   */
  message?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ErrorData
   */
  parameters?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ErrorData
   */
  requestUid?: string;
}

/**
 *
 * @export
 * @interface ExternalProductRequest
 */
export interface ExternalProductRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalProductRequest
   */
  accountUid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalProductRequest
   */
  externalUid?: string;
}

/**
 *
 * @export
 * @interface GetStatisticsRequest
 */
export interface GetStatisticsRequest {
  /**
   *
   * @type {Date}
   * @memberof GetStatisticsRequest
   */
  dateFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof GetStatisticsRequest
   */
  dateTo?: Date;
  /**
   *
   * @type {string}
   * @memberof GetStatisticsRequest
   */
  groupBy: GetStatisticsRequest.GroupByEnum;
  /**
   *
   * @type {string}
   * @memberof GetStatisticsRequest
   */
  periodType: GetStatisticsRequest.PeriodTypeEnum;
}

/**
 * @export
 * @namespace GetStatisticsRequest
 */
export namespace GetStatisticsRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum GroupByEnum {
    Country = <any>"country",
    Category = <any>"category",
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PeriodTypeEnum {
    Hour = <any>"hour",
    Day = <any>"day",
    Month = <any>"month",
  }
}

/**
 *
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  hour?: number;
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  minute?: number;
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  nano?: number;
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  second?: number;
}

/**
 *
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
  /**
   *
   * @type {boolean}
   * @memberof ModelAndView
   */
  empty?: boolean;
  /**
   *
   * @type {any}
   * @memberof ModelAndView
   */
  model?: any;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof ModelAndView
   */
  modelMap?: { [key: string]: any };
  /**
   *
   * @type {boolean}
   * @memberof ModelAndView
   */
  reference?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelAndView
   */
  status?: ModelAndView.StatusEnum;
  /**
   *
   * @type {View}
   * @memberof ModelAndView
   */
  view?: View;
  /**
   *
   * @type {string}
   * @memberof ModelAndView
   */
  viewName?: string;
}

/**
 * @export
 * @namespace ModelAndView
 */
export namespace ModelAndView {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    _100CONTINUE = <any>"100 CONTINUE",
    _101SWITCHINGPROTOCOLS = <any>"101 SWITCHING_PROTOCOLS",
    _102PROCESSING = <any>"102 PROCESSING",
    _103CHECKPOINT = <any>"103 CHECKPOINT",
    _200OK = <any>"200 OK",
    _201CREATED = <any>"201 CREATED",
    _202ACCEPTED = <any>"202 ACCEPTED",
    _203NONAUTHORITATIVEINFORMATION = <any>"203 NON_AUTHORITATIVE_INFORMATION",
    _204NOCONTENT = <any>"204 NO_CONTENT",
    _205RESETCONTENT = <any>"205 RESET_CONTENT",
    _206PARTIALCONTENT = <any>"206 PARTIAL_CONTENT",
    _207MULTISTATUS = <any>"207 MULTI_STATUS",
    _208ALREADYREPORTED = <any>"208 ALREADY_REPORTED",
    _226IMUSED = <any>"226 IM_USED",
    _300MULTIPLECHOICES = <any>"300 MULTIPLE_CHOICES",
    _301MOVEDPERMANENTLY = <any>"301 MOVED_PERMANENTLY",
    _302FOUND = <any>"302 FOUND",
    _302MOVEDTEMPORARILY = <any>"302 MOVED_TEMPORARILY",
    _303SEEOTHER = <any>"303 SEE_OTHER",
    _304NOTMODIFIED = <any>"304 NOT_MODIFIED",
    _305USEPROXY = <any>"305 USE_PROXY",
    _307TEMPORARYREDIRECT = <any>"307 TEMPORARY_REDIRECT",
    _308PERMANENTREDIRECT = <any>"308 PERMANENT_REDIRECT",
    _400BADREQUEST = <any>"400 BAD_REQUEST",
    _401UNAUTHORIZED = <any>"401 UNAUTHORIZED",
    _402PAYMENTREQUIRED = <any>"402 PAYMENT_REQUIRED",
    _403FORBIDDEN = <any>"403 FORBIDDEN",
    _404NOTFOUND = <any>"404 NOT_FOUND",
    _405METHODNOTALLOWED = <any>"405 METHOD_NOT_ALLOWED",
    _406NOTACCEPTABLE = <any>"406 NOT_ACCEPTABLE",
    _407PROXYAUTHENTICATIONREQUIRED = <any>"407 PROXY_AUTHENTICATION_REQUIRED",
    _408REQUESTTIMEOUT = <any>"408 REQUEST_TIMEOUT",
    _409CONFLICT = <any>"409 CONFLICT",
    _410GONE = <any>"410 GONE",
    _411LENGTHREQUIRED = <any>"411 LENGTH_REQUIRED",
    _412PRECONDITIONFAILED = <any>"412 PRECONDITION_FAILED",
    _413PAYLOADTOOLARGE = <any>"413 PAYLOAD_TOO_LARGE",
    _413REQUESTENTITYTOOLARGE = <any>"413 REQUEST_ENTITY_TOO_LARGE",
    _414URITOOLONG = <any>"414 URI_TOO_LONG",
    _414REQUESTURITOOLONG = <any>"414 REQUEST_URI_TOO_LONG",
    _415UNSUPPORTEDMEDIATYPE = <any>"415 UNSUPPORTED_MEDIA_TYPE",
    _416REQUESTEDRANGENOTSATISFIABLE = <any>"416 REQUESTED_RANGE_NOT_SATISFIABLE",
    _417EXPECTATIONFAILED = <any>"417 EXPECTATION_FAILED",
    _418IAMATEAPOT = <any>"418 I_AM_A_TEAPOT",
    _419INSUFFICIENTSPACEONRESOURCE = <any>"419 INSUFFICIENT_SPACE_ON_RESOURCE",
    _420METHODFAILURE = <any>"420 METHOD_FAILURE",
    _421DESTINATIONLOCKED = <any>"421 DESTINATION_LOCKED",
    _422UNPROCESSABLEENTITY = <any>"422 UNPROCESSABLE_ENTITY",
    _423LOCKED = <any>"423 LOCKED",
    _424FAILEDDEPENDENCY = <any>"424 FAILED_DEPENDENCY",
    _425TOOEARLY = <any>"425 TOO_EARLY",
    _426UPGRADEREQUIRED = <any>"426 UPGRADE_REQUIRED",
    _428PRECONDITIONREQUIRED = <any>"428 PRECONDITION_REQUIRED",
    _429TOOMANYREQUESTS = <any>"429 TOO_MANY_REQUESTS",
    _431REQUESTHEADERFIELDSTOOLARGE = <any>"431 REQUEST_HEADER_FIELDS_TOO_LARGE",
    _451UNAVAILABLEFORLEGALREASONS = <any>"451 UNAVAILABLE_FOR_LEGAL_REASONS",
    _500INTERNALSERVERERROR = <any>"500 INTERNAL_SERVER_ERROR",
    _501NOTIMPLEMENTED = <any>"501 NOT_IMPLEMENTED",
    _502BADGATEWAY = <any>"502 BAD_GATEWAY",
    _503SERVICEUNAVAILABLE = <any>"503 SERVICE_UNAVAILABLE",
    _504GATEWAYTIMEOUT = <any>"504 GATEWAY_TIMEOUT",
    _505HTTPVERSIONNOTSUPPORTED = <any>"505 HTTP_VERSION_NOT_SUPPORTED",
    _506VARIANTALSONEGOTIATES = <any>"506 VARIANT_ALSO_NEGOTIATES",
    _507INSUFFICIENTSTORAGE = <any>"507 INSUFFICIENT_STORAGE",
    _508LOOPDETECTED = <any>"508 LOOP_DETECTED",
    _509BANDWIDTHLIMITEXCEEDED = <any>"509 BANDWIDTH_LIMIT_EXCEEDED",
    _510NOTEXTENDED = <any>"510 NOT_EXTENDED",
    _511NETWORKAUTHENTICATIONREQUIRED = <any>"511 NETWORK_AUTHENTICATION_REQUIRED",
  }
}

/**
 *
 * @export
 * @interface MultipleMarketplaceApiResponseProductAndProductCategory
 */
export interface MultipleMarketplaceApiResponseProductAndProductCategory {
  /**
   *
   * @type {{ [key: string]: Array<Product>; }}
   * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
   */
  body?: { [key: string]: Array<Product> };
  /**
   *
   * @type {ErrorData}
   * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
   */
  errorData?: ErrorData;
  /**
   *
   * @type {boolean}
   * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
   */
  successful?: boolean;
  /**
   *
   * @type {ErrorData}
   * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface MultipleMarketplaceApiResponseProductCategoryProduct
 */
export interface MultipleMarketplaceApiResponseProductCategoryProduct {
  /**
   *
   * @type {{ [key: string]: Array<Product>; }}
   * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
   */
  body?: { [key: string]: Array<Product> };
  /**
   *
   * @type {ErrorData}
   * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
   */
  errorData?: ErrorData;
  /**
   *
   * @type {boolean}
   * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
   */
  successful?: boolean;
  /**
   *
   * @type {ErrorData}
   * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
   */
  warningData?: ErrorData;
}

/**
 *
 * @export
 * @interface OmnideskTicket
 */
export interface OmnideskTicket {
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  accountUid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OmnideskTicket
   */
  attachments?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  companyName?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof OmnideskTicket
   */
  customParameters?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  languageCode?: string;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof OmnideskTicket
   */
  subject?: string;
}

/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {{ [key: string]: Array<ActiveFeatureDetails>; }}
   * @memberof Product
   */
  activeFeatures?: { [key: string]: Array<ActiveFeatureDetails> };
  /**
   *
   * @type {ProductAddress}
   * @memberof Product
   */
  address?: ProductAddress;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  allowedCountries?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  categoryExternalUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  color?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  colors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  coordinates?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  currencyCode: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  currencySymbol?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  customField1?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  customField2?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  customField3?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  dealType?: Product.DealTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  declineReason?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  declinedBy?: string;
  /**
   *
   * @type {Array<DeliveryMethod>}
   * @memberof Product
   */
  deliveryMethods?: Array<DeliveryMethod>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  disallowedCountries?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  discount?: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  discountedPrice?: number;
  /**
   *
   * @type {Date}
   * @memberof Product
   */
  expirationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  externalUid?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  externalUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  featureCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  hashtags?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageThumbnailUrl1?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageThumbnailUrl2?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageThumbnailUrl3?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageThumbnailUrl4?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageThumbnailUrl5?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageUrl1?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageUrl2?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageUrl3?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageUrl4?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  imageUrl5?: string;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  inStock: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  isWishlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  languageCode: string;
  /**
   *
   * @type {Array<ProductLocalization>}
   * @memberof Product
   */
  localizations?: Array<ProductLocalization>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  manufacturerPartNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  originalLanguageCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  ownerUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  pickupAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  placeId?: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  price: number;
  /**
   *
   * @type {Date}
   * @memberof Product
   */
  productDate?: Date;
  /**
   *
   * @type {Array<ProductParam>}
   * @memberof Product
   */
  productParams?: Array<ProductParam>;
  /**
   *
   * @type {Date}
   * @memberof Product
   */
  publishDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  purchasable?: boolean;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  quantityReserved?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  rating?: number;
  /**
   *
   * @type {ProductRentOptions}
   * @memberof Product
   */
  rentOptions?: ProductRentOptions;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  returnAccepted?: boolean;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  reviewCount?: number;
  /**
   *
   * @type {Sale}
   * @memberof Product
   */
  sale?: Sale;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  salePrice?: number;
  /**
   *
   * @type {Seller}
   * @memberof Product
   */
  seller?: Seller;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sellerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sellerIban?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sellerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sellerUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  shippingAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  status: Product.StatusEnum;
  /**
   *
   * @type {Store}
   * @memberof Product
   */
  store?: Store;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  type: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof Product
   */
  uid?: string;
  /**
   *
   * @type {ProductVideo}
   * @memberof Product
   */
  video?: ProductVideo;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  viewCount?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  weight?: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  weightUnit?: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  wishCount?: number;
}

/**
 * @export
 * @namespace Product
 */
export namespace Product {
  /**
   * @export
   * @enum {string}
   */
  export enum DealTypeEnum {
    RENT = <any>"RENT",
    SELL = <any>"SELL",
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    DRF = <any>"DRF",
    DLT = <any>"DLT",
    AFR = <any>"AFR",
    DCL = <any>"DCL",
    FRB = <any>"FRB",
    APR = <any>"APR",
    PBL = <any>"PBL",
    EXP = <any>"EXP",
    DSC = <any>"DSC",
    SUS = <any>"SUS",
    OOS = <any>"OOS",
  }
}

/**
 *
 * @export
 * @interface ProductAddress
 */
export interface ProductAddress {
  /**
   *
   * @type {string}
   * @memberof ProductAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ProductAddress
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductAddress
   */
  firstAddressLine?: string;
  /**
   *
   * @type {string}
   * @memberof ProductAddress
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductAddress
   */
  secondAddressLine?: string;
  /**
   *
   * @type {string}
   * @memberof ProductAddress
   */
  state?: string;
}

/**
 *
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  categoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  color?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  dependency?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  iconCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  imageUrl?: string;
  /**
   *
   * @type {ProductCategory}
   * @memberof ProductCategory
   */
  parent?: ProductCategory;
  /**
   *
   * @type {boolean}
   * @memberof ProductCategory
   */
  pickUpAllowed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProductCategory
   */
  productCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProductCategory
   */
  returnAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  seoText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductCategory
   */
  shippingAllowed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProductCategory
   */
  sortOrder?: number;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  thumbnailUrl?: string;
}

/**
 *
 * @export
 * @interface ProductChat
 */
export interface ProductChat {
  /**
   *
   * @type {string}
   * @memberof ProductChat
   */
  accountIdentity?: string;
  /**
   *
   * @type {string}
   * @memberof ProductChat
   */
  accountUid?: string;
  /**
   *
   * @type {string}
   * @memberof ProductChat
   */
  productUid?: string;
}

/**
 *
 * @export
 * @interface ProductDelivery
 */
export interface ProductDelivery {
  /**
   *
   * @type {DeliveryMethod}
   * @memberof ProductDelivery
   */
  deliveryMethod?: DeliveryMethod;
  /**
   *
   * @type {DeliveryMethodOption}
   * @memberof ProductDelivery
   */
  deliveryMethodOption?: DeliveryMethodOption;
  /**
   *
   * @type {string}
   * @memberof ProductDelivery
   */
  productUid?: string;
}

/**
 *
 * @export
 * @interface ProductExpirationDateRequest
 */
export interface ProductExpirationDateRequest {
  /**
   *
   * @type {Date}
   * @memberof ProductExpirationDateRequest
   */
  expirationDate: Date;
  /**
   *
   * @type {string}
   * @memberof ProductExpirationDateRequest
   */
  uid: string;
}

/**
 *
 * @export
 * @interface ProductExt
 */
export interface ProductExt {
  /**
   *
   * @type {{ [key: string]: Array<ActiveFeatureDetails>; }}
   * @memberof ProductExt
   */
  activeFeatures?: { [key: string]: Array<ActiveFeatureDetails> };
  /**
   *
   * @type {ProductAddress}
   * @memberof ProductExt
   */
  address?: ProductAddress;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductExt
   */
  allowedCountries?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  categoryExternalUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  color?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductExt
   */
  colors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  coordinates?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  currencyCode: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  currencySymbol?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  customField1?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  customField2?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  customField3?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  dealType?: ProductExt.DealTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  declineReason?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  declinedBy?: string;
  /**
   *
   * @type {Array<DeliveryMethod>}
   * @memberof ProductExt
   */
  deliveryMethods?: Array<DeliveryMethod>;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductExt
   */
  disallowedCountries?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  discount?: string;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  discountedPrice?: number;
  /**
   *
   * @type {Date}
   * @memberof ProductExt
   */
  expirationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  externalUid?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  externalUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductExt
   */
  featureCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  hashtags?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageThumbnailUrl1?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageThumbnailUrl2?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageThumbnailUrl3?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageThumbnailUrl4?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageThumbnailUrl5?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageUrl1?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageUrl2?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageUrl3?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageUrl4?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  imageUrl5?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductExt
   */
  inStock: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductExt
   */
  isWishlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  languageCode: string;
  /**
   *
   * @type {Array<ProductLocalization>}
   * @memberof ProductExt
   */
  localizations?: Array<ProductLocalization>;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  manufacturerPartNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  originalCurrencyCode?: string;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  originalDiscountedPrice?: number;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  originalLanguageCode?: string;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  originalPrice?: number;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  ownerUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductExt
   */
  pickupAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  placeId?: string;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  price: number;
  /**
   *
   * @type {Date}
   * @memberof ProductExt
   */
  productDate?: Date;
  /**
   *
   * @type {Array<ProductParam>}
   * @memberof ProductExt
   */
  productParams?: Array<ProductParam>;
  /**
   *
   * @type {Date}
   * @memberof ProductExt
   */
  publishDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ProductExt
   */
  purchasable?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  quantityReserved?: number;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  rating?: number;
  /**
   *
   * @type {ProductRentOptions}
   * @memberof ProductExt
   */
  rentOptions?: ProductRentOptions;
  /**
   *
   * @type {boolean}
   * @memberof ProductExt
   */
  returnAccepted?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  reviewCount?: number;
  /**
   *
   * @type {Sale}
   * @memberof ProductExt
   */
  sale?: Sale;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  salePrice?: number;
  /**
   *
   * @type {Seller}
   * @memberof ProductExt
   */
  seller?: Seller;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  sellerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  sellerIban?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  sellerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  sellerUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductExt
   */
  shippingAllowed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  status: ProductExt.StatusEnum;
  /**
   *
   * @type {Store}
   * @memberof ProductExt
   */
  store?: Store;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  type: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof ProductExt
   */
  uid?: string;
  /**
   *
   * @type {ProductVideo}
   * @memberof ProductExt
   */
  video?: ProductVideo;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  viewCount?: number;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  weight?: number;
  /**
   *
   * @type {string}
   * @memberof ProductExt
   */
  weightUnit?: string;
  /**
   *
   * @type {number}
   * @memberof ProductExt
   */
  wishCount?: number;
}

/**
 * @export
 * @namespace ProductExt
 */
export namespace ProductExt {
  /**
   * @export
   * @enum {string}
   */
  export enum DealTypeEnum {
    RENT = <any>"RENT",
    SELL = <any>"SELL",
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    DRF = <any>"DRF",
    DLT = <any>"DLT",
    AFR = <any>"AFR",
    DCL = <any>"DCL",
    FRB = <any>"FRB",
    APR = <any>"APR",
    PBL = <any>"PBL",
    EXP = <any>"EXP",
    DSC = <any>"DSC",
    SUS = <any>"SUS",
    OOS = <any>"OOS",
  }
}

/**
 *
 * @export
 * @interface ProductFeatureType
 */
export interface ProductFeatureType {
  /**
   *
   * @type {Array<ProductFeatureType>}
   * @memberof ProductFeatureType
   */
  bonusFeatures?: Array<ProductFeatureType>;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  duration?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProductFeatureType
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  typeDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  typeName?: string;
  /**
   *
   * @type {string}
   * @memberof ProductFeatureType
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface ProductImageDeleteRequest
 */
export interface ProductImageDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ProductImageDeleteRequest
   */
  imageLink: string;
  /**
   *
   * @type {string}
   * @memberof ProductImageDeleteRequest
   */
  uid: string;
}

/**
 *
 * @export
 * @interface ProductItemParam
 */
export interface ProductItemParam {
  /**
   *
   * @type {string}
   * @memberof ProductItemParam
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemParam
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemParam
   */
  value?: string;
}

/**
 *
 * @export
 * @interface ProductListRequest
 */
export interface ProductListRequest {
  /**
   *
   * @type {boolean}
   * @memberof ProductListRequest
   */
  archived?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductListRequest
   */
  category?: string;
  /**
   *
   * @type {number}
   * @memberof ProductListRequest
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof ProductListRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductListRequest
   */
  featured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductListRequest
   */
  hashtags?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductListRequest
   */
  inStock?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductListRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProductListRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof ProductListRequest
   */
  priceFrom?: number;
  /**
   *
   * @type {number}
   * @memberof ProductListRequest
   */
  priceTo?: number;
  /**
   *
   * @type {number}
   * @memberof ProductListRequest
   */
  quantity?: number;
  /**
   *
   * @type {Sorting}
   * @memberof ProductListRequest
   */
  sortBy?: Sorting;
  /**
   *
   * @type {string}
   * @memberof ProductListRequest
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductListRequest
   */
  statuses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ProductListRequest
   */
  type?: string;
}

/**
 *
 * @export
 * @interface ProductLocalization
 */
export interface ProductLocalization {
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  color?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  customField1?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  customField2?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  customField3?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductLocalization
   */
  empty?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  language: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  originalLanguageCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof ProductLocalization
   */
  weightUnit?: string;
}

/**
 *
 * @export
 * @interface ProductParam
 */
export interface ProductParam {
  /**
   *
   * @type {string}
   * @memberof ProductParam
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductParam
   */
  mandatory?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductParam
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductParam
   */
  validationRegExp?: string;
}

/**
 *
 * @export
 * @interface ProductQuantityRequest
 */
export interface ProductQuantityRequest {
  /**
   *
   * @type {number}
   * @memberof ProductQuantityRequest
   */
  quantity: number;
  /**
   *
   * @type {string}
   * @memberof ProductQuantityRequest
   */
  status: ProductQuantityRequest.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProductQuantityRequest
   */
  uid: string;
}

/**
 * @export
 * @namespace ProductQuantityRequest
 */
export namespace ProductQuantityRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    DRF = <any>"DRF",
    DLT = <any>"DLT",
    AFR = <any>"AFR",
    DCL = <any>"DCL",
    FRB = <any>"FRB",
    APR = <any>"APR",
    PBL = <any>"PBL",
    EXP = <any>"EXP",
    DSC = <any>"DSC",
    SUS = <any>"SUS",
    OOS = <any>"OOS",
  }
}

/**
 *
 * @export
 * @interface ProductRentOptions
 */
export interface ProductRentOptions {
  /**
   *
   * @type {LocalTime}
   * @memberof ProductRentOptions
   */
  dayRentEndTime?: LocalTime;
  /**
   *
   * @type {LocalTime}
   * @memberof ProductRentOptions
   */
  dayRentStartTime?: LocalTime;
  /**
   *
   * @type {number}
   * @memberof ProductRentOptions
   */
  dayRentTimezone?: number;
  /**
   *
   * @type {number}
   * @memberof ProductRentOptions
   */
  deposit: number;
  /**
   *
   * @type {number}
   * @memberof ProductRentOptions
   */
  minPeriod?: number;
  /**
   *
   * @type {string}
   * @memberof ProductRentOptions
   */
  period: ProductRentOptions.PeriodEnum;
}

/**
 * @export
 * @namespace ProductRentOptions
 */
export namespace ProductRentOptions {
  /**
   * @export
   * @enum {string}
   */
  export enum PeriodEnum {
    HOUR = <any>"HOUR",
    DAY = <any>"DAY",
    MONTH = <any>"MONTH",
  }
}

/**
 *
 * @export
 * @interface ProductReview
 */
export interface ProductReview {
  /**
   *
   * @type {boolean}
   * @memberof ProductReview
   */
  anonymously?: boolean;
  /**
   *
   * @type {ProductReviewAuthor}
   * @memberof ProductReview
   */
  author?: ProductReviewAuthor;
  /**
   *
   * @type {Date}
   * @memberof ProductReview
   */
  creationDate?: Date;
  /**
   *
   * @type {Array<ProductReviewImage>}
   * @memberof ProductReview
   */
  images?: Array<ProductReviewImage>;
  /**
   *
   * @type {string}
   * @memberof ProductReview
   */
  languageCode: string;
  /**
   *
   * @type {Date}
   * @memberof ProductReview
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ProductReview
   */
  originalLanguageCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReview
   */
  originalText?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReview
   */
  productUid: string;
  /**
   *
   * @type {number}
   * @memberof ProductReview
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof ProductReview
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReview
   */
  text?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof ProductReview
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface ProductReviewAuthor
 */
export interface ProductReviewAuthor {
  /**
   *
   * @type {string}
   * @memberof ProductReviewAuthor
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewAuthor
   */
  profilePictureUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewAuthor
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewAuthor
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface ProductReviewImage
 */
export interface ProductReviewImage {
  /**
   *
   * @type {Date}
   * @memberof ProductReviewImage
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ProductReviewImage
   */
  imageThumbnailUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewImage
   */
  imageUrl?: string;
  /**
   *
   * @type {Date}
   * @memberof ProductReviewImage
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ProductReviewImage
   */
  reviewUid: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewImage
   */
  status?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof ProductReviewImage
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface ProductSearchListRequest
 */
export interface ProductSearchListRequest {
  /**
   *
   * @type {ProductAddress}
   * @memberof ProductSearchListRequest
   */
  address?: ProductAddress;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  allowedCountryCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductSearchListRequest
   */
  autoHashtags?: boolean;
  /**
   * Product category OR product list type (new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers)
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  coordinates?: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  dealType?: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  discountedPriceFrom?: number;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  discountedPriceTo?: number;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  distance?: number;
  /**
   *
   * @type {Date}
   * @memberof ProductSearchListRequest
   */
  expirationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  fullAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  hashtags?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductSearchListRequest
   */
  inStock?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  offset?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProductSearchListRequest
   */
  pickupAllowed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  priceFrom?: number;
  /**
   *
   * @type {number}
   * @memberof ProductSearchListRequest
   */
  priceTo?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  promotionPageUid?: string;
  /**
   *
   * @type {Date}
   * @memberof ProductSearchListRequest
   */
  publishDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ProductSearchListRequest
   */
  returnAccepted?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  saleUid?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  sellerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  sellerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  sellerUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductSearchListRequest
   */
  shippingAllowed?: boolean;
  /**
   *
   * @type {Array<Sorting>}
   * @memberof ProductSearchListRequest
   */
  sortBy?: Array<Sorting>;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  storeUid?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearchListRequest
   */
  type?: string;
}

/**
 *
 * @export
 * @interface ProductSource
 */
export interface ProductSource {
  /**
   *
   * @type {boolean}
   * @memberof ProductSource
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  authPassword?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  authUsername?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  contentType?: string;
  /**
   *
   * @type {Date}
   * @memberof ProductSource
   */
  creationDate?: Date;
  /**
   *
   * @type {ProductSourceContent}
   * @memberof ProductSource
   */
  currentContent?: ProductSourceContent;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  languageCode: string;
  /**
   *
   * @type {Date}
   * @memberof ProductSource
   */
  modificationDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof ProductSource
   */
  nextExecutionDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ProductSource
   */
  skipNew?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  title: string;
  /**
   *
   * @type {ProductSourceType}
   * @memberof ProductSource
   */
  type: ProductSourceType;
  /**
   * Source UID. Required for update operations only.
   * @type {string}
   * @memberof ProductSource
   */
  uid?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductSource
   */
  updateExisting?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  url: string;
}

/**
 *
 * @export
 * @interface ProductSourceContent
 */
export interface ProductSourceContent {
  /**
   *
   * @type {Array<AccountCategory>}
   * @memberof ProductSourceContent
   */
  categoryMapping?: Array<AccountCategory>;
  /**
   *
   * @type {Date}
   * @memberof ProductSourceContent
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ProductSourceContent
   */
  errorMessage?: string;
  /**
   *
   * @type {number}
   * @memberof ProductSourceContent
   */
  failedCount?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSourceContent
   */
  hash?: string;
  /**
   *
   * @type {number}
   * @memberof ProductSourceContent
   */
  newCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProductSourceContent
   */
  processed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProductSourceContent
   */
  skippedCount?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSourceContent
   */
  sourceUid?: string;
  /**
   *
   * @type {number}
   * @memberof ProductSourceContent
   */
  updatedCount?: number;
}

/**
 *
 * @export
 * @interface ProductSourceType
 */
export interface ProductSourceType {
  /**
   *
   * @type {string}
   * @memberof ProductSourceType
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ProductSourceType
   */
  name?: string;
}

/**
 *
 * @export
 * @interface ProductStatusRequest
 */
export interface ProductStatusRequest {
  /**
   *
   * @type {string}
   * @memberof ProductStatusRequest
   */
  status: ProductStatusRequest.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProductStatusRequest
   */
  uid: string;
}

/**
 * @export
 * @namespace ProductStatusRequest
 */
export namespace ProductStatusRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    DRF = <any>"DRF",
    DLT = <any>"DLT",
    AFR = <any>"AFR",
    DCL = <any>"DCL",
    FRB = <any>"FRB",
    APR = <any>"APR",
    PBL = <any>"PBL",
    EXP = <any>"EXP",
    DSC = <any>"DSC",
    SUS = <any>"SUS",
    OOS = <any>"OOS",
  }
}

/**
 *
 * @export
 * @interface ProductUidListRequest
 */
export interface ProductUidListRequest {
  /**
   *
   * @type {string}
   * @memberof ProductUidListRequest
   */
  uid: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductUidListRequest
   */
  uidList?: Array<string>;
}

/**
 *
 * @export
 * @interface ProductUidRequest
 */
export interface ProductUidRequest {
  /**
   *
   * @type {string}
   * @memberof ProductUidRequest
   */
  uid: string;
}

/**
 *
 * @export
 * @interface ProductVideo
 */
export interface ProductVideo {
  /**
   *
   * @type {boolean}
   * @memberof ProductVideo
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductVideo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductVideo
   */
  type: ProductVideo.TypeEnum;
}

/**
 * @export
 * @namespace ProductVideo
 */
export namespace ProductVideo {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    YOUTUBE = <any>"YOUTUBE",
    VIMEO = <any>"VIMEO",
    VK = <any>"VK",
  }
}

/**
 *
 * @export
 * @interface PromotionPage
 */
export interface PromotionPage {
  /**
   *
   * @type {PromotionPageBanner}
   * @memberof PromotionPage
   */
  banner?: PromotionPageBanner;
  /**
   *
   * @type {Array<ProductCategory>}
   * @memberof PromotionPage
   */
  categories?: Array<ProductCategory>;
  /**
   *
   * @type {Date}
   * @memberof PromotionPage
   */
  creationDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof PromotionPage
   */
  modificationDate?: Date;
  /**
   *
   * @type {Array<PromotionPageProduct>}
   * @memberof PromotionPage
   */
  products?: Array<PromotionPageProduct>;
  /**
   *
   * @type {string}
   * @memberof PromotionPage
   */
  title?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof PromotionPage
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface PromotionPageBanner
 */
export interface PromotionPageBanner {
  /**
   *
   * @type {Date}
   * @memberof PromotionPageBanner
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof PromotionPageBanner
   */
  desktopImageUrl: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageBanner
   */
  languageCode?: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageBanner
   */
  mobileImageUrl: string;
  /**
   *
   * @type {Date}
   * @memberof PromotionPageBanner
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof PromotionPageBanner
   */
  targetUrl: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageBanner
   */
  title: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof PromotionPageBanner
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface PromotionPageProduct
 */
export interface PromotionPageProduct {
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  categoryName?: string;
  /**
   *
   * @type {Date}
   * @memberof PromotionPageProduct
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PromotionPageProduct
   */
  discountedPrice?: number;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  imageThumbnailUrl1?: string;
  /**
   *
   * @type {Date}
   * @memberof PromotionPageProduct
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PromotionPageProduct
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  productUid?: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  rootCategoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof PromotionPageProduct
   */
  rootCategoryName?: string;
  /**
   *
   * @type {number}
   * @memberof PromotionPageProduct
   */
  salePrice?: number;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof PromotionPageProduct
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface RandomProductListRequest
 */
export interface RandomProductListRequest {
  /**
   *
   * @type {string}
   * @memberof RandomProductListRequest
   */
  allowedCountryCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RandomProductListRequest
   */
  categoryList?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RandomProductListRequest
   */
  coordinates?: string;
  /**
   *
   * @type {number}
   * @memberof RandomProductListRequest
   */
  distance?: number;
  /**
   *
   * @type {number}
   * @memberof RandomProductListRequest
   */
  limitPerCategory?: number;
}

/**
 *
 * @export
 * @interface RemoveCartItemRequest
 */
export interface RemoveCartItemRequest {
  /**
   *
   * @type {string}
   * @memberof RemoveCartItemRequest
   */
  itemUid?: string;
  /**
   *
   * @type {string}
   * @memberof RemoveCartItemRequest
   */
  sellerUid?: string;
}

/**
 *
 * @export
 * @interface Sale
 */
export interface Sale {
  /**
   *
   * @type {string}
   * @memberof Sale
   */
  accountUid?: string;
  /**
   *
   * @type {number}
   * @memberof Sale
   */
  activeProducts?: number;
  /**
   *
   * @type {Date}
   * @memberof Sale
   */
  creationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof Sale
   */
  disabledProducts?: number;
  /**
   *
   * @type {number}
   * @memberof Sale
   */
  discountAmountFixed?: number;
  /**
   *
   * @type {number}
   * @memberof Sale
   */
  discountAmountPercent?: number;
  /**
   *
   * @type {Date}
   * @memberof Sale
   */
  endDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Sale
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Sale
   */
  name?: string;
  /**
   *
   * @type {Array<SaleProduct>}
   * @memberof Sale
   */
  products?: Array<SaleProduct>;
  /**
   *
   * @type {string}
   * @memberof Sale
   */
  roundingType: Sale.RoundingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Sale
   */
  sourceSaleUid?: string;
  /**
   *
   * @type {Date}
   * @memberof Sale
   */
  startDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Sale
   */
  status: Sale.StatusEnum;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof Sale
   */
  uid?: string;
  /**
   *
   * @type {number}
   * @memberof Sale
   */
  validDiscountAmountRangeFrom?: number;
  /**
   *
   * @type {number}
   * @memberof Sale
   */
  validDiscountAmountRangeTo?: number;
}

/**
 * @export
 * @namespace Sale
 */
export namespace Sale {
  /**
   * @export
   * @enum {string}
   */
  export enum RoundingTypeEnum {
    _00 = <any>"00",
    _49 = <any>"49",
    _99 = <any>"99",
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>"NEW",
    ACT = <any>"ACT",
    EXP = <any>"EXP",
    DLT = <any>"DLT",
  }
}

/**
 *
 * @export
 * @interface SaleProduct
 */
export interface SaleProduct {
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  categoryName?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleProduct
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof SaleProduct
   */
  discountedPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  error?: string;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  imageThumbnailUrl1?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleProduct
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SaleProduct
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  productUid?: string;
  /**
   *
   * @type {number}
   * @memberof SaleProduct
   */
  salePrice?: number;
  /**
   *
   * @type {string}
   * @memberof SaleProduct
   */
  saleUid?: string;
}

/**
 *
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
  /**
   *
   * @type {number}
   * @memberof SearchRequest
   */
  limit?: number;
  /**
   *
   * @type {string}
   * @memberof SearchRequest
   */
  searchString?: string;
}

/**
 *
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  categoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  result?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  resultType?: string;
}

/**
 *
 * @export
 * @interface Seller
 */
export interface Seller {
  /**
   *
   * @type {string}
   * @memberof Seller
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Seller
   */
  iban?: string;
  /**
   *
   * @type {string}
   * @memberof Seller
   */
  imageUrl?: string;
  /**
   *
   * @type {Date}
   * @memberof Seller
   */
  lastLoginDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Seller
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Seller
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof Seller
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface Sorting
 */
export interface Sorting {
  /**
   *
   * @type {string}
   * @memberof Sorting
   */
  direction?: string;
  /**
   *
   * @type {string}
   * @memberof Sorting
   */
  field?: string;
  /**
   *
   * @type {number}
   * @memberof Sorting
   */
  sortingIndex?: number;
}

/**
 *
 * @export
 * @interface Statistics
 */
export interface Statistics {
  /**
   *
   * @type {number}
   * @memberof Statistics
   */
  accounts?: number;
  /**
   *
   * @type {string}
   * @memberof Statistics
   */
  categoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof Statistics
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof Statistics
   */
  country?: string;
  /**
   *
   * @type {Date}
   * @memberof Statistics
   */
  date?: Date;
  /**
   *
   * @type {number}
   * @memberof Statistics
   */
  messages?: number;
  /**
   *
   * @type {number}
   * @memberof Statistics
   */
  orders?: number;
  /**
   *
   * @type {number}
   * @memberof Statistics
   */
  views?: number;
}

/**
 *
 * @export
 * @interface Store
 */
export interface Store {
  /**
   *
   * @type {Account}
   * @memberof Store
   */
  account?: Account;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  accountUid?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  code?: string;
  /**
   *
   * @type {Date}
   * @memberof Store
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  homepage?: string;
  /**
   *
   * @type {StoreHomepage}
   * @memberof Store
   */
  homepageConfig?: StoreHomepage;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  imageUrl?: string;
  /**
   *
   * @type {Array<StoreLink>}
   * @memberof Store
   */
  links?: Array<StoreLink>;
  /**
   *
   * @type {Date}
   * @memberof Store
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  originalDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  phone?: string;
  /**
   *
   * @type {Array<ProductCategory>}
   * @memberof Store
   */
  productCategories?: Array<ProductCategory>;
  /**
   *
   * @type {Array<Product>}
   * @memberof Store
   */
  products?: Array<Product>;
  /**
   *
   * @type {Array<Sale>}
   * @memberof Store
   */
  sales?: Array<Sale>;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  statusDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Store
   */
  storeLanguage?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof Store
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface StoreCheck
 */
export interface StoreCheck {
  /**
   *
   * @type {string}
   * @memberof StoreCheck
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof StoreCheck
   */
  codeAvailable?: boolean;
  /**
   *
   * @type {string}
   * @memberof StoreCheck
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof StoreCheck
   */
  nameAvailable?: boolean;
  /**
   *
   * @type {string}
   * @memberof StoreCheck
   */
  offeredCode?: string;
}

/**
 *
 * @export
 * @interface StoreHomepage
 */
export interface StoreHomepage {
  /**
   *
   * @type {string}
   * @memberof StoreHomepage
   */
  accountUid?: string;
  /**
   *
   * @type {StoreHomepageBanner}
   * @memberof StoreHomepage
   */
  banner?: StoreHomepageBanner;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepage
   */
  creationDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof StoreHomepage
   */
  draft?: boolean;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepage
   */
  modificationDate?: Date;
  /**
   *
   * @type {Array<StoreHomepageProduct>}
   * @memberof StoreHomepage
   */
  products?: Array<StoreHomepageProduct>;
  /**
   *
   * @type {string}
   * @memberof StoreHomepage
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepage
   */
  storeUid?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof StoreHomepage
   */
  uid?: string;
  /**
   *
   * @type {Array<StoreHomepageWidget>}
   * @memberof StoreHomepage
   */
  widgets?: Array<StoreHomepageWidget>;
}

/**
 *
 * @export
 * @interface StoreHomepageBanner
 */
export interface StoreHomepageBanner {
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageBanner
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageBanner
   */
  desktopImageUrl: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageBanner
   */
  mobileImageUrl: string;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageBanner
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageBanner
   */
  targetUrl: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageBanner
   */
  title: string;
}

/**
 *
 * @export
 * @interface StoreHomepageProduct
 */
export interface StoreHomepageProduct {
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageProduct
   */
  creationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageProduct
   */
  imageThumbnailUrl1?: string;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageProduct
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageProduct
   */
  productCurrencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageProduct
   */
  productDescription?: string;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageProduct
   */
  productDiscountedPrice?: number;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageProduct
   */
  productName?: string;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageProduct
   */
  productPrice?: number;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageProduct
   */
  productSalePrice?: number;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageProduct
   */
  productUid?: string;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageProduct
   */
  sortOrder?: number;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageProduct
   */
  storeHomepageUid?: string;
}

/**
 *
 * @export
 * @interface StoreHomepageProductsRequest
 */
export interface StoreHomepageProductsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof StoreHomepageProductsRequest
   */
  productUid?: Array<string>;
}

/**
 *
 * @export
 * @interface StoreHomepageWidget
 */
export interface StoreHomepageWidget {
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  categoryName?: string;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageWidget
   */
  creationDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageWidget
   */
  modificationDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  rootCategoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  rootCategoryName?: string;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageWidget
   */
  saleDiscountAmountFixed?: number;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageWidget
   */
  saleDiscountAmountPercent?: number;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageWidget
   */
  saleEndDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  saleName?: string;
  /**
   *
   * @type {Date}
   * @memberof StoreHomepageWidget
   */
  saleStartDate?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  saleUid?: string;
  /**
   *
   * @type {number}
   * @memberof StoreHomepageWidget
   */
  sortOrder?: number;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  storeHomepageUid?: string;
  /**
   *
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  type?: string;
  /**
   * UID. Required for update operations only.
   * @type {string}
   * @memberof StoreHomepageWidget
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface StoreLink
 */
export interface StoreLink {
  /**
   *
   * @type {string}
   * @memberof StoreLink
   */
  uid?: string;
  /**
   *
   * @type {string}
   * @memberof StoreLink
   */
  url?: string;
}

/**
 *
 * @export
 * @interface StoreSearchRequest
 */
export interface StoreSearchRequest {
  /**
   *
   * @type {string}
   * @memberof StoreSearchRequest
   */
  accountUid?: string;
  /**
   *
   * @type {string}
   * @memberof StoreSearchRequest
   */
  code?: string;
  /**
   *
   * @type {Date}
   * @memberof StoreSearchRequest
   */
  createdFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof StoreSearchRequest
   */
  createdTo?: Date;
  /**
   *
   * @type {string}
   * @memberof StoreSearchRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StoreSearchRequest
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof StoreSearchRequest
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface UidRequest
 */
export interface UidRequest {
  /**
   *
   * @type {string}
   * @memberof UidRequest
   */
  uid: string;
}

/**
 *
 * @export
 * @interface UploadedImage
 */
export interface UploadedImage {
  /**
   *
   * @type {string}
   * @memberof UploadedImage
   */
  url?: string;
}

/**
 *
 * @export
 * @interface View
 */
export interface View {
  /**
   *
   * @type {string}
   * @memberof View
   */
  contentType?: string;
}

/**
 * AdminControllerApi - fetch parameter creator
 * @export
 */
export const AdminControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search account stores
     * @param {StoreSearchRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreListUsingPOST(request: StoreSearchRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling accountStoreListUsingPOST."
        );
      }
      const localVarPath = `/admin/store/search`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreSearchRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new product sources
     * @param {ProductSource} source source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductSourceUsingPUT(source: ProductSource, accountUid?: string, options: any = {}): FetchArgs {
      // verify required parameter 'source' is not null or undefined
      if (source === null || source === undefined) {
        throw new RequiredError(
          "source",
          "Required parameter source was null or undefined when calling addProductSourceUsingPUT."
        );
      }
      const localVarPath = `/admin/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductSource" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(source || {}) : source || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add promotion page banner
     * @param {PromotionPageBanner} banner banner
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageBannerUsingPUT(
      banner: PromotionPageBanner,
      pageUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'banner' is not null or undefined
      if (banner === null || banner === undefined) {
        throw new RequiredError(
          "banner",
          "Required parameter banner was null or undefined when calling addPromotionPageBannerUsingPUT."
        );
      }
      // verify required parameter 'pageUid' is not null or undefined
      if (pageUid === null || pageUid === undefined) {
        throw new RequiredError(
          "pageUid",
          "Required parameter pageUid was null or undefined when calling addPromotionPageBannerUsingPUT."
        );
      }
      const localVarPath = `/admin/promotionpage/{pageUid}/banner`.replace(
        `{${"pageUid"}}`,
        encodeURIComponent(String(pageUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"PromotionPageBanner" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(banner || {}) : banner || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add promotion page product
     * @param {string} pageUid pageUid
     * @param {AddPromotionPageProductRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageProductUsingPUT(
      pageUid: string,
      request: AddPromotionPageProductRequest,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'pageUid' is not null or undefined
      if (pageUid === null || pageUid === undefined) {
        throw new RequiredError(
          "pageUid",
          "Required parameter pageUid was null or undefined when calling addPromotionPageProductUsingPUT."
        );
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling addPromotionPageProductUsingPUT."
        );
      }
      const localVarPath = `/admin/promotionpage/{pageUid}/product`.replace(
        `{${"pageUid"}}`,
        encodeURIComponent(String(pageUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"AddPromotionPageProductRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create promotion page
     * @param {AddPromotionPageRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageUsingPUT(request: AddPromotionPageRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling addPromotionPageUsingPUT."
        );
      }
      const localVarPath = `/admin/promotionpage`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"AddPromotionPageRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete existing product sources
     * @param {UidRequest} request request
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductSourceUsingDELETE(request: UidRequest, accountUid?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteProductSourceUsingDELETE."
        );
      }
      const localVarPath = `/admin/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"UidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete promotion page banner
     * @param {string} bannerUid bannerUid
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageBannerUsingDELETE(
      bannerUid: string,
      pageUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'bannerUid' is not null or undefined
      if (bannerUid === null || bannerUid === undefined) {
        throw new RequiredError(
          "bannerUid",
          "Required parameter bannerUid was null or undefined when calling deletePromotionPageBannerUsingDELETE."
        );
      }
      // verify required parameter 'pageUid' is not null or undefined
      if (pageUid === null || pageUid === undefined) {
        throw new RequiredError(
          "pageUid",
          "Required parameter pageUid was null or undefined when calling deletePromotionPageBannerUsingDELETE."
        );
      }
      const localVarPath = `/admin/promotionpage/{pageUid}/banner/{bannerUid}`
        .replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)))
        .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete promotion page product
     * @param {string} pageUid pageUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageProductUsingDELETE(
      pageUid: string,
      productUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'pageUid' is not null or undefined
      if (pageUid === null || pageUid === undefined) {
        throw new RequiredError(
          "pageUid",
          "Required parameter pageUid was null or undefined when calling deletePromotionPageProductUsingDELETE."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling deletePromotionPageProductUsingDELETE."
        );
      }
      const localVarPath = `/admin/promotionpage/{pageUid}/product/{productUid}`
        .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)))
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete promotion page
     * @param {string} pageUid pageUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageUsingDELETE(pageUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'pageUid' is not null or undefined
      if (pageUid === null || pageUid === undefined) {
        throw new RequiredError(
          "pageUid",
          "Required parameter pageUid was null or undefined when calling deletePromotionPageUsingDELETE."
        );
      }
      const localVarPath = `/admin/promotionpage/{pageUid}`.replace(
        `{${"pageUid"}}`,
        encodeURIComponent(String(pageUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSourceUsingGET(sourceUid: string, accountUid?: string, options: any = {}): FetchArgs {
      // verify required parameter 'sourceUid' is not null or undefined
      if (sourceUid === null || sourceUid === undefined) {
        throw new RequiredError(
          "sourceUid",
          "Required parameter sourceUid was null or undefined when calling getProductSourceUsingGET."
        );
      }
      const localVarPath = `/admin/product/import/source/{sourceUid}`.replace(
        `{${"sourceUid"}}`,
        encodeURIComponent(String(sourceUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options: any = {}): FetchArgs {
      // verify required parameter 'sourceUid' is not null or undefined
      if (sourceUid === null || sourceUid === undefined) {
        throw new RequiredError(
          "sourceUid",
          "Required parameter sourceUid was null or undefined when calling productSourceHistoryUsingGET."
        );
      }
      const localVarPath = `/admin/product/import/source/{sourceUid}/history`.replace(
        `{${"sourceUid"}}`,
        encodeURIComponent(String(sourceUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of product sources
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceListUsingGET(accountUid?: string, options: any = {}): FetchArgs {
      const localVarPath = `/admin/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update account store
     * @param {Store} store store
     * @param {string} [accountUid] accountUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountStoreUsingPOST(store: Store, accountUid?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'store' is not null or undefined
      if (store === null || store === undefined) {
        throw new RequiredError(
          "store",
          "Required parameter store was null or undefined when calling updateAccountStoreUsingPOST."
        );
      }
      const localVarPath = `/admin/store`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Store" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(store || {}) : store || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update existing product sources
     * @param {ProductSource} source source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductSourceUsingPOST(source: ProductSource, accountUid?: string, options: any = {}): FetchArgs {
      // verify required parameter 'source' is not null or undefined
      if (source === null || source === undefined) {
        throw new RequiredError(
          "source",
          "Required parameter source was null or undefined when calling updateProductSourceUsingPOST."
        );
      }
      const localVarPath = `/admin/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductSource" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(source || {}) : source || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options: any = {}): FetchArgs {
      // verify required parameter 'sourceUid' is not null or undefined
      if (sourceUid === null || sourceUid === undefined) {
        throw new RequiredError(
          "sourceUid",
          "Required parameter sourceUid was null or undefined when calling validateProductSourceUsingGET."
        );
      }
      const localVarPath = `/admin/product/import/source/{sourceUid}/validate`.replace(
        `{${"sourceUid"}}`,
        encodeURIComponent(String(sourceUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search account stores
     * @param {StoreSearchRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreListUsingPOST(
      request: StoreSearchRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).accountStoreListUsingPOST(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new product sources
     * @param {ProductSource} source source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductSourceUsingPUT(
      source: ProductSource,
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addProductSourceUsingPUT(
        source,
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add promotion page banner
     * @param {PromotionPageBanner} banner banner
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageBannerUsingPUT(
      banner: PromotionPageBanner,
      pageUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addPromotionPageBannerUsingPUT(
        banner,
        pageUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add promotion page product
     * @param {string} pageUid pageUid
     * @param {AddPromotionPageProductRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageProductUsingPUT(
      pageUid: string,
      request: AddPromotionPageProductRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addPromotionPageProductUsingPUT(
        pageUid,
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Create promotion page
     * @param {AddPromotionPageRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageUsingPUT(
      request: AddPromotionPageRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addPromotionPageUsingPUT(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete existing product sources
     * @param {UidRequest} request request
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductSourceUsingDELETE(
      request: UidRequest,
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deleteProductSourceUsingDELETE(
        request,
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete promotion page banner
     * @param {string} bannerUid bannerUid
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageBannerUsingDELETE(
      bannerUid: string,
      pageUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deletePromotionPageBannerUsingDELETE(
        bannerUid,
        pageUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete promotion page product
     * @param {string} pageUid pageUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageProductUsingDELETE(
      pageUid: string,
      productUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(
        configuration
      ).deletePromotionPageProductUsingDELETE(pageUid, productUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete promotion page
     * @param {string} pageUid pageUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageUsingDELETE(
      pageUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deletePromotionPageUsingDELETE(
        pageUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSourceUsingGET(
      sourceUid: string,
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).getProductSourceUsingGET(
        sourceUid,
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceHistoryUsingGET(
      sourceUid: string,
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceContent> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).productSourceHistoryUsingGET(
        sourceUid,
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return list of product sources
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceListUsingGET(
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).productSourceListUsingGET(
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update account store
     * @param {Store} store store
     * @param {string} [accountUid] accountUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountStoreUsingPOST(
      store: Store,
      accountUid?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateAccountStoreUsingPOST(
        store,
        accountUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update existing product sources
     * @param {ProductSource} source source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductSourceUsingPOST(
      source: ProductSource,
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateProductSourceUsingPOST(
        source,
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductSourceUsingGET(
      sourceUid: string,
      accountUid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).validateProductSourceUsingGET(
        sourceUid,
        accountUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Search account stores
     * @param {StoreSearchRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreListUsingPOST(request: StoreSearchRequest, language?: string, options?: any) {
      return AdminControllerApiFp(configuration).accountStoreListUsingPOST(request, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Add new product sources
     * @param {ProductSource} source source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductSourceUsingPUT(source: ProductSource, accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).addProductSourceUsingPUT(source, accountUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Add promotion page banner
     * @param {PromotionPageBanner} banner banner
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageBannerUsingPUT(banner: PromotionPageBanner, pageUid: string, language?: string, options?: any) {
      return AdminControllerApiFp(configuration).addPromotionPageBannerUsingPUT(
        banner,
        pageUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add promotion page product
     * @param {string} pageUid pageUid
     * @param {AddPromotionPageProductRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageProductUsingPUT(
      pageUid: string,
      request: AddPromotionPageProductRequest,
      language?: string,
      options?: any
    ) {
      return AdminControllerApiFp(configuration).addPromotionPageProductUsingPUT(
        pageUid,
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Create promotion page
     * @param {AddPromotionPageRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPromotionPageUsingPUT(request: AddPromotionPageRequest, language?: string, options?: any) {
      return AdminControllerApiFp(configuration).addPromotionPageUsingPUT(request, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete existing product sources
     * @param {UidRequest} request request
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductSourceUsingDELETE(request: UidRequest, accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).deleteProductSourceUsingDELETE(
        request,
        accountUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete promotion page banner
     * @param {string} bannerUid bannerUid
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageBannerUsingDELETE(bannerUid: string, pageUid: string, language?: string, options?: any) {
      return AdminControllerApiFp(configuration).deletePromotionPageBannerUsingDELETE(
        bannerUid,
        pageUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete promotion page product
     * @param {string} pageUid pageUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageProductUsingDELETE(pageUid: string, productUid: string, language?: string, options?: any) {
      return AdminControllerApiFp(configuration).deletePromotionPageProductUsingDELETE(
        pageUid,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete promotion page
     * @param {string} pageUid pageUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePromotionPageUsingDELETE(pageUid: string, options?: any) {
      return AdminControllerApiFp(configuration).deletePromotionPageUsingDELETE(pageUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).getProductSourceUsingGET(
        sourceUid,
        accountUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).productSourceHistoryUsingGET(
        sourceUid,
        accountUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return list of product sources
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceListUsingGET(accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).productSourceListUsingGET(accountUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update account store
     * @param {Store} store store
     * @param {string} [accountUid] accountUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountStoreUsingPOST(store: Store, accountUid?: string, language?: string, options?: any) {
      return AdminControllerApiFp(configuration).updateAccountStoreUsingPOST(
        store,
        accountUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update existing product sources
     * @param {ProductSource} source source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductSourceUsingPOST(source: ProductSource, accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).updateProductSourceUsingPOST(
        source,
        accountUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
      return AdminControllerApiFp(configuration).validateProductSourceUsingGET(
        sourceUid,
        accountUid,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
  /**
   *
   * @summary Search account stores
   * @param {StoreSearchRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public accountStoreListUsingPOST(request: StoreSearchRequest, language?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).accountStoreListUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new product sources
   * @param {ProductSource} source source
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public addProductSourceUsingPUT(source: ProductSource, accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).addProductSourceUsingPUT(
      source,
      accountUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add promotion page banner
   * @param {PromotionPageBanner} banner banner
   * @param {string} pageUid pageUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public addPromotionPageBannerUsingPUT(
    banner: PromotionPageBanner,
    pageUid: string,
    language?: string,
    options?: any
  ) {
    return AdminControllerApiFp(this.configuration).addPromotionPageBannerUsingPUT(
      banner,
      pageUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add promotion page product
   * @param {string} pageUid pageUid
   * @param {AddPromotionPageProductRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public addPromotionPageProductUsingPUT(
    pageUid: string,
    request: AddPromotionPageProductRequest,
    language?: string,
    options?: any
  ) {
    return AdminControllerApiFp(this.configuration).addPromotionPageProductUsingPUT(
      pageUid,
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Create promotion page
   * @param {AddPromotionPageRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public addPromotionPageUsingPUT(request: AddPromotionPageRequest, language?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).addPromotionPageUsingPUT(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete existing product sources
   * @param {UidRequest} request request
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public deleteProductSourceUsingDELETE(request: UidRequest, accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).deleteProductSourceUsingDELETE(
      request,
      accountUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete promotion page banner
   * @param {string} bannerUid bannerUid
   * @param {string} pageUid pageUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public deletePromotionPageBannerUsingDELETE(bannerUid: string, pageUid: string, language?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).deletePromotionPageBannerUsingDELETE(
      bannerUid,
      pageUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete promotion page product
   * @param {string} pageUid pageUid
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public deletePromotionPageProductUsingDELETE(pageUid: string, productUid: string, language?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).deletePromotionPageProductUsingDELETE(
      pageUid,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete promotion page
   * @param {string} pageUid pageUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public deletePromotionPageUsingDELETE(pageUid: string, options?: any) {
    return AdminControllerApiFp(this.configuration).deletePromotionPageUsingDELETE(pageUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Return product sources details
   * @param {string} sourceUid sourceUid
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).getProductSourceUsingGET(
      sourceUid,
      accountUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return product sources import history (10 max)
   * @param {string} sourceUid sourceUid
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).productSourceHistoryUsingGET(
      sourceUid,
      accountUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return list of product sources
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public productSourceListUsingGET(accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).productSourceListUsingGET(accountUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Update account store
   * @param {Store} store store
   * @param {string} [accountUid] accountUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public updateAccountStoreUsingPOST(store: Store, accountUid?: string, language?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).updateAccountStoreUsingPOST(
      store,
      accountUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update existing product sources
   * @param {ProductSource} source source
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public updateProductSourceUsingPOST(source: ProductSource, accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).updateProductSourceUsingPOST(
      source,
      accountUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Validate product source
   * @param {string} sourceUid sourceUid
   * @param {string} [accountUid] accountUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
    return AdminControllerApiFp(this.configuration).validateProductSourceUsingGET(
      sourceUid,
      accountUid,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * BannerControllerApi - fetch parameter creator
 * @export
 */
export const BannerControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bannerListUsingGET(channel: string, count?: number, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'channel' is not null or undefined
      if (channel === null || channel === undefined) {
        throw new RequiredError(
          "channel",
          "Required parameter channel was null or undefined when calling bannerListUsingGET."
        );
      }
      const localVarPath = `/banner`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (channel !== undefined) {
        localVarQueryParameter["channel"] = channel;
      }

      if (count !== undefined) {
        localVarQueryParameter["count"] = count;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options: any = {}): FetchArgs {
      // verify required parameter 'bannerUid' is not null or undefined
      if (bannerUid === null || bannerUid === undefined) {
        throw new RequiredError(
          "bannerUid",
          "Required parameter bannerUid was null or undefined when calling processManualClickUsingGET."
        );
      }
      // verify required parameter 'channel' is not null or undefined
      if (channel === null || channel === undefined) {
        throw new RequiredError(
          "channel",
          "Required parameter channel was null or undefined when calling processManualClickUsingGET."
        );
      }
      const localVarPath = `/banner/{bannerUid}/click`.replace(
        `{${"bannerUid"}}`,
        encodeURIComponent(String(bannerUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (channel !== undefined) {
        localVarQueryParameter["channel"] = channel;
      }

      if (refId !== undefined) {
        localVarQueryParameter["refId"] = refId;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BannerControllerApi - functional programming interface
 * @export
 */
export const BannerControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bannerListUsingGET(
      channel: string,
      count?: number,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
      const localVarFetchArgs = BannerControllerApiFetchParamCreator(configuration).bannerListUsingGET(
        channel,
        count,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processManualClickUsingGET(
      bannerUid: string,
      channel: string,
      refId?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
      const localVarFetchArgs = BannerControllerApiFetchParamCreator(configuration).processManualClickUsingGET(
        bannerUid,
        channel,
        refId,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * BannerControllerApi - factory interface
 * @export
 */
export const BannerControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bannerListUsingGET(channel: string, count?: number, language?: string, options?: any) {
      return BannerControllerApiFp(configuration).bannerListUsingGET(
        channel,
        count,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options?: any) {
      return BannerControllerApiFp(configuration).processManualClickUsingGET(
        bannerUid,
        channel,
        refId,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * BannerControllerApi - object-oriented interface
 * @export
 * @class BannerControllerApi
 * @extends {BaseAPI}
 */
export class BannerControllerApi extends BaseAPI {
  /**
   *
   * @summary Return N marketing banners in random order
   * @param {string} channel channel
   * @param {number} [count] count
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BannerControllerApi
   */
  public bannerListUsingGET(channel: string, count?: number, language?: string, options?: any) {
    return BannerControllerApiFp(this.configuration).bannerListUsingGET(
      channel,
      count,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Manually register banner click to get banner click statistics
   * @param {string} bannerUid bannerUid
   * @param {string} channel channel
   * @param {string} [refId] refId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BannerControllerApi
   */
  public processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options?: any) {
    return BannerControllerApiFp(this.configuration).processManualClickUsingGET(
      bannerUid,
      channel,
      refId,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * BannerProxyControllerApi - fetch parameter creator
 * @export
 */
export const BannerProxyControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processClickUsingGET(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'bannerUid' is not null or undefined
      if (bannerUid === null || bannerUid === undefined) {
        throw new RequiredError(
          "bannerUid",
          "Required parameter bannerUid was null or undefined when calling processClickUsingGET."
        );
      }
      // verify required parameter 'channel' is not null or undefined
      if (channel === null || channel === undefined) {
        throw new RequiredError(
          "channel",
          "Required parameter channel was null or undefined when calling processClickUsingGET."
        );
      }
      const localVarPath = `/banner/{bannerUid}`.replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      if (channel !== undefined) {
        localVarQueryParameter["channel"] = channel;
      }

      if (refId !== undefined) {
        localVarQueryParameter["refId"] = refId;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processClickUsingPOST(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'bannerUid' is not null or undefined
      if (bannerUid === null || bannerUid === undefined) {
        throw new RequiredError(
          "bannerUid",
          "Required parameter bannerUid was null or undefined when calling processClickUsingPOST."
        );
      }
      // verify required parameter 'channel' is not null or undefined
      if (channel === null || channel === undefined) {
        throw new RequiredError(
          "channel",
          "Required parameter channel was null or undefined when calling processClickUsingPOST."
        );
      }
      const localVarPath = `/banner/{bannerUid}`.replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      if (channel !== undefined) {
        localVarQueryParameter["channel"] = channel;
      }

      if (refId !== undefined) {
        localVarQueryParameter["refId"] = refId;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BannerProxyControllerApi - functional programming interface
 * @export
 */
export const BannerProxyControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processClickUsingGET(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = BannerProxyControllerApiFetchParamCreator(configuration).processClickUsingGET(
        bannerUid,
        channel,
        accountUid,
        refId,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processClickUsingPOST(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = BannerProxyControllerApiFetchParamCreator(configuration).processClickUsingPOST(
        bannerUid,
        channel,
        accountUid,
        refId,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * BannerProxyControllerApi - factory interface
 * @export
 */
export const BannerProxyControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processClickUsingGET(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
      return BannerProxyControllerApiFp(configuration).processClickUsingGET(
        bannerUid,
        channel,
        accountUid,
        refId,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processClickUsingPOST(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
      return BannerProxyControllerApiFp(configuration).processClickUsingPOST(
        bannerUid,
        channel,
        accountUid,
        refId,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * BannerProxyControllerApi - object-oriented interface
 * @export
 * @class BannerProxyControllerApi
 * @extends {BaseAPI}
 */
export class BannerProxyControllerApi extends BaseAPI {
  /**
   *
   * @summary Public proxy service to get banner click statistics
   * @param {string} bannerUid bannerUid
   * @param {string} channel channel
   * @param {string} [accountUid] accountUid
   * @param {string} [refId] refId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BannerProxyControllerApi
   */
  public processClickUsingGET(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
    return BannerProxyControllerApiFp(this.configuration).processClickUsingGET(
      bannerUid,
      channel,
      accountUid,
      refId,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Public proxy service to get banner click statistics
   * @param {string} bannerUid bannerUid
   * @param {string} channel channel
   * @param {string} [accountUid] accountUid
   * @param {string} [refId] refId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BannerProxyControllerApi
   */
  public processClickUsingPOST(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
    return BannerProxyControllerApiFp(this.configuration).processClickUsingPOST(
      bannerUid,
      channel,
      accountUid,
      refId,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * BasicErrorControllerApi - fetch parameter creator
 * @export
 */
export const BasicErrorControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingDELETE(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingGET(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingHEAD(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "HEAD" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingOPTIONS(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "OPTIONS" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPATCH(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PATCH" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPOST(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPUT(options: any = {}): FetchArgs {
      const localVarPath = `/error`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingDELETE(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingGET(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingHEAD(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingHEAD(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingOPTIONS(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingOPTIONS(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingPATCH(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingPOST(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPUT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any }> {
      const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorUsingPUT(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingDELETE(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingDELETE(options)(fetch, basePath);
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingGET(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingGET(options)(fetch, basePath);
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingHEAD(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingHEAD(options)(fetch, basePath);
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingOPTIONS(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingOPTIONS(options)(fetch, basePath);
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPATCH(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingPATCH(options)(fetch, basePath);
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPOST(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingPOST(options)(fetch, basePath);
    },
    /**
     *
     * @summary error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorUsingPUT(options?: any) {
      return BasicErrorControllerApiFp(configuration).errorUsingPUT(options)(fetch, basePath);
    },
  };
};

/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingDELETE(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingDELETE(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingGET(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingGET(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingHEAD(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingHEAD(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingOPTIONS(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingOPTIONS(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingPATCH(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingPATCH(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingPOST(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingPOST(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary error
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicErrorControllerApi
   */
  public errorUsingPUT(options?: any) {
    return BasicErrorControllerApiFp(this.configuration).errorUsingPUT(options)(this.fetch, this.basePath);
  }
}

/**
 * CartControllerApi - fetch parameter creator
 * @export
 */
export const CartControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new item to cart or update existing item
     * @param {CartItem} item item
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemToCartUsingPOST(item: CartItem, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'item' is not null or undefined
      if (item === null || item === undefined) {
        throw new RequiredError(
          "item",
          "Required parameter item was null or undefined when calling addItemToCartUsingPOST."
        );
      }
      const localVarPath = `/cart/add`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CartItem" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(item || {}) : item || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new items to cart or update existing items
     * @param {Array<CartItem>} items items
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemsToCartUsingPOST(items: Array<CartItem>, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'items' is not null or undefined
      if (items === null || items === undefined) {
        throw new RequiredError(
          "items",
          "Required parameter items was null or undefined when calling addItemsToCartUsingPOST."
        );
      }
      const localVarPath = `/cart/items/add`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;CartItem&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(items || {}) : items || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Calculate delivery price based on cartUid or product list
     * @param {CalculateDeliveryRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDeliveryUsingPOST(request: CalculateDeliveryRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling calculateDeliveryUsingPOST."
        );
      }
      const localVarPath = `/cart/calculateDelivery`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CalculateDeliveryRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCartUsingDELETE(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling deleteCartUsingDELETE."
        );
      }
      const localVarPath = `/cart/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account cart
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountCartUsingGET(country?: string, language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/cart`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCartUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError("uid", "Required parameter uid was null or undefined when calling getCartUsingGET.");
      }
      const localVarPath = `/cart/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove item from cart
     * @param {RemoveCartItemRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeItemFromCartUsingPOST(
      request: RemoveCartItemRequest,
      country?: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling removeItemFromCartUsingPOST."
        );
      }
      const localVarPath = `/cart/remove`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"RemoveCartItemRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CartControllerApi - functional programming interface
 * @export
 */
export const CartControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new item to cart or update existing item
     * @param {CartItem} item item
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemToCartUsingPOST(
      item: CartItem,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).addItemToCartUsingPOST(
        item,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new items to cart or update existing items
     * @param {Array<CartItem>} items items
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemsToCartUsingPOST(
      items: Array<CartItem>,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).addItemsToCartUsingPOST(
        items,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Calculate delivery price based on cartUid or product list
     * @param {CalculateDeliveryRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDeliveryUsingPOST(
      request: CalculateDeliveryRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCalculateDeliveryResponse> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).calculateDeliveryUsingPOST(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCartUsingDELETE(
      uid: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).deleteCartUsingDELETE(
        uid,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get account cart
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountCartUsingGET(
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).getAccountCartUsingGET(
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get account cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCartUsingGET(
      uid: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).getCartUsingGET(
        uid,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Remove item from cart
     * @param {RemoveCartItemRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeItemFromCartUsingPOST(
      request: RemoveCartItemRequest,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
      const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).removeItemFromCartUsingPOST(
        request,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CartControllerApi - factory interface
 * @export
 */
export const CartControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Add new item to cart or update existing item
     * @param {CartItem} item item
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemToCartUsingPOST(item: CartItem, country?: string, language?: string, options?: any) {
      return CartControllerApiFp(configuration).addItemToCartUsingPOST(
        item,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new items to cart or update existing items
     * @param {Array<CartItem>} items items
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addItemsToCartUsingPOST(items: Array<CartItem>, country?: string, language?: string, options?: any) {
      return CartControllerApiFp(configuration).addItemsToCartUsingPOST(
        items,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Calculate delivery price based on cartUid or product list
     * @param {CalculateDeliveryRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDeliveryUsingPOST(request: CalculateDeliveryRequest, language?: string, options?: any) {
      return CartControllerApiFp(configuration).calculateDeliveryUsingPOST(request, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCartUsingDELETE(uid: string, country?: string, language?: string, options?: any) {
      return CartControllerApiFp(configuration).deleteCartUsingDELETE(uid, country, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get account cart
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountCartUsingGET(country?: string, language?: string, options?: any) {
      return CartControllerApiFp(configuration).getAccountCartUsingGET(country, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get account cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCartUsingGET(uid: string, country?: string, language?: string, options?: any) {
      return CartControllerApiFp(configuration).getCartUsingGET(uid, country, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Remove item from cart
     * @param {RemoveCartItemRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeItemFromCartUsingPOST(request: RemoveCartItemRequest, country?: string, language?: string, options?: any) {
      return CartControllerApiFp(configuration).removeItemFromCartUsingPOST(
        request,
        country,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * CartControllerApi - object-oriented interface
 * @export
 * @class CartControllerApi
 * @extends {BaseAPI}
 */
export class CartControllerApi extends BaseAPI {
  /**
   *
   * @summary Add new item to cart or update existing item
   * @param {CartItem} item item
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public addItemToCartUsingPOST(item: CartItem, country?: string, language?: string, options?: any) {
    return CartControllerApiFp(this.configuration).addItemToCartUsingPOST(
      item,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new items to cart or update existing items
   * @param {Array<CartItem>} items items
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public addItemsToCartUsingPOST(items: Array<CartItem>, country?: string, language?: string, options?: any) {
    return CartControllerApiFp(this.configuration).addItemsToCartUsingPOST(
      items,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Calculate delivery price based on cartUid or product list
   * @param {CalculateDeliveryRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public calculateDeliveryUsingPOST(request: CalculateDeliveryRequest, language?: string, options?: any) {
    return CartControllerApiFp(this.configuration).calculateDeliveryUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete cart by uid
   * @param {string} uid uid
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public deleteCartUsingDELETE(uid: string, country?: string, language?: string, options?: any) {
    return CartControllerApiFp(this.configuration).deleteCartUsingDELETE(
      uid,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get account cart
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public getAccountCartUsingGET(country?: string, language?: string, options?: any) {
    return CartControllerApiFp(this.configuration).getAccountCartUsingGET(
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get account cart by uid
   * @param {string} uid uid
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public getCartUsingGET(uid: string, country?: string, language?: string, options?: any) {
    return CartControllerApiFp(this.configuration).getCartUsingGET(
      uid,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Remove item from cart
   * @param {RemoveCartItemRequest} request request
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CartControllerApi
   */
  public removeItemFromCartUsingPOST(
    request: RemoveCartItemRequest,
    country?: string,
    language?: string,
    options?: any
  ) {
    return CartControllerApiFp(this.configuration).removeItemFromCartUsingPOST(
      request,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * ChatControllerApi - fetch parameter creator
 * @export
 */
export const ChatControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Register for chat support
     * @param {ProductUidRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerProductChatUsingPUT(request: ProductUidRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling registerProductChatUsingPUT."
        );
      }
      const localVarPath = `/chat/register`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductUidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChatControllerApi - functional programming interface
 * @export
 */
export const ChatControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Register for chat support
     * @param {ProductUidRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerProductChatUsingPUT(
      request: ProductUidRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductChat> {
      const localVarFetchArgs = ChatControllerApiFetchParamCreator(configuration).registerProductChatUsingPUT(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ChatControllerApi - factory interface
 * @export
 */
export const ChatControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Register for chat support
     * @param {ProductUidRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerProductChatUsingPUT(request: ProductUidRequest, options?: any) {
      return ChatControllerApiFp(configuration).registerProductChatUsingPUT(request, options)(fetch, basePath);
    },
  };
};

/**
 * ChatControllerApi - object-oriented interface
 * @export
 * @class ChatControllerApi
 * @extends {BaseAPI}
 */
export class ChatControllerApi extends BaseAPI {
  /**
   *
   * @summary Register for chat support
   * @param {ProductUidRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatControllerApi
   */
  public registerProductChatUsingPUT(request: ProductUidRequest, options?: any) {
    return ChatControllerApiFp(this.configuration).registerProductChatUsingPUT(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * ClassificatorControllerApi - fetch parameter creator
 * @export
 */
export const ClassificatorControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get classificator list
     * @param {string} entity entity
     * @param {string} field field
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entityListUsingGET(entity: string, field: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'entity' is not null or undefined
      if (entity === null || entity === undefined) {
        throw new RequiredError(
          "entity",
          "Required parameter entity was null or undefined when calling entityListUsingGET."
        );
      }
      // verify required parameter 'field' is not null or undefined
      if (field === null || field === undefined) {
        throw new RequiredError(
          "field",
          "Required parameter field was null or undefined when calling entityListUsingGET."
        );
      }
      const localVarPath = `/classificator/{entity}/{field}`
        .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
        .replace(`{${"field"}}`, encodeURIComponent(String(field)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Product Category list
     * @param {string} [accountUid] accountUid
     * @param {string} [categoryCode] categoryCode
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productCategoryUsingGET(
      accountUid?: string,
      categoryCode?: string,
      country?: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/classificator/productCategory`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      if (categoryCode !== undefined) {
        localVarQueryParameter["categoryCode"] = categoryCode;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClassificatorControllerApi - functional programming interface
 * @export
 */
export const ClassificatorControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get classificator list
     * @param {string} entity entity
     * @param {string} field field
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entityListUsingGET(
      entity: string,
      field: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseClassificator> {
      const localVarFetchArgs = ClassificatorControllerApiFetchParamCreator(configuration).entityListUsingGET(
        entity,
        field,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get Product Category list
     * @param {string} [accountUid] accountUid
     * @param {string} [categoryCode] categoryCode
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productCategoryUsingGET(
      accountUid?: string,
      categoryCode?: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductCategory> {
      const localVarFetchArgs = ClassificatorControllerApiFetchParamCreator(configuration).productCategoryUsingGET(
        accountUid,
        categoryCode,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ClassificatorControllerApi - factory interface
 * @export
 */
export const ClassificatorControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Get classificator list
     * @param {string} entity entity
     * @param {string} field field
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entityListUsingGET(entity: string, field: string, language?: string, options?: any) {
      return ClassificatorControllerApiFp(configuration).entityListUsingGET(
        entity,
        field,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get Product Category list
     * @param {string} [accountUid] accountUid
     * @param {string} [categoryCode] categoryCode
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productCategoryUsingGET(
      accountUid?: string,
      categoryCode?: string,
      country?: string,
      language?: string,
      options?: any
    ) {
      return ClassificatorControllerApiFp(configuration).productCategoryUsingGET(
        accountUid,
        categoryCode,
        country,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * ClassificatorControllerApi - object-oriented interface
 * @export
 * @class ClassificatorControllerApi
 * @extends {BaseAPI}
 */
export class ClassificatorControllerApi extends BaseAPI {
  /**
   *
   * @summary Get classificator list
   * @param {string} entity entity
   * @param {string} field field
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClassificatorControllerApi
   */
  public entityListUsingGET(entity: string, field: string, language?: string, options?: any) {
    return ClassificatorControllerApiFp(this.configuration).entityListUsingGET(
      entity,
      field,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get Product Category list
   * @param {string} [accountUid] accountUid
   * @param {string} [categoryCode] categoryCode
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClassificatorControllerApi
   */
  public productCategoryUsingGET(
    accountUid?: string,
    categoryCode?: string,
    country?: string,
    language?: string,
    options?: any
  ) {
    return ClassificatorControllerApiFp(this.configuration).productCategoryUsingGET(
      accountUid,
      categoryCode,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * DeliveryMethodControllerApi - fetch parameter creator
 * @export
 */
export const DeliveryMethodControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductDeliveryMethodUsingPUT(
      deliveryMethodUid: string,
      productUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'deliveryMethodUid' is not null or undefined
      if (deliveryMethodUid === null || deliveryMethodUid === undefined) {
        throw new RequiredError(
          "deliveryMethodUid",
          "Required parameter deliveryMethodUid was null or undefined when calling addProductDeliveryMethodUsingPUT."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addProductDeliveryMethodUsingPUT."
        );
      }
      const localVarPath = `/product/{productUid}/delivery/method/{deliveryMethodUid}`
        .replace(`{${"deliveryMethodUid"}}`, encodeURIComponent(String(deliveryMethodUid)))
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new delivery method
     * @param {DeliveryMethod} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountDeliveryMethodUsingPUT(request: DeliveryMethod, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createAccountDeliveryMethodUsingPUT."
        );
      }
      const localVarPath = `/account/delivery/method`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"DeliveryMethod" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductDeliveryMethodUsingDELETE(
      deliveryMethodUid: string,
      productUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'deliveryMethodUid' is not null or undefined
      if (deliveryMethodUid === null || deliveryMethodUid === undefined) {
        throw new RequiredError(
          "deliveryMethodUid",
          "Required parameter deliveryMethodUid was null or undefined when calling deleteProductDeliveryMethodUsingDELETE."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling deleteProductDeliveryMethodUsingDELETE."
        );
      }
      const localVarPath = `/product/{productUid}/delivery/method/{deliveryMethodUid}`
        .replace(`{${"deliveryMethodUid"}}`, encodeURIComponent(String(deliveryMethodUid)))
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return information about specified delivery method or about account specified methods, if uid is not specified
     * @param {string} [language] language
     * @param {string} [uid] uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options: any = {}): FetchArgs {
      const localVarPath = `/account/delivery/method`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (uid !== undefined) {
        localVarQueryParameter["uid"] = uid;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete existing delivery method
     * @param {UidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountDeliveryMethodUsingDELETE(request: UidRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateAccountDeliveryMethodUsingDELETE."
        );
      }
      const localVarPath = `/account/delivery/method`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"UidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update existing delivery method
     * @param {DeliveryMethod} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountDeliveryMethodUsingPOST(request: DeliveryMethod, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateAccountDeliveryMethodUsingPOST."
        );
      }
      const localVarPath = `/account/delivery/method`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"DeliveryMethod" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update product delivery methods
     * @param {Array<BaseUidObject>} deliveryMethodList deliveryMethodList
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductDeliveryMethodsUsingPOST(
      deliveryMethodList: Array<BaseUidObject>,
      productUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'deliveryMethodList' is not null or undefined
      if (deliveryMethodList === null || deliveryMethodList === undefined) {
        throw new RequiredError(
          "deliveryMethodList",
          "Required parameter deliveryMethodList was null or undefined when calling updateProductDeliveryMethodsUsingPOST."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling updateProductDeliveryMethodsUsingPOST."
        );
      }
      const localVarPath = `/product/{productUid}/delivery`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;BaseUidObject&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(deliveryMethodList || {})
        : deliveryMethodList || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeliveryMethodControllerApi - functional programming interface
 * @export
 */
export const DeliveryMethodControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductDeliveryMethodUsingPUT(
      deliveryMethodUid: string,
      productUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).addProductDeliveryMethodUsingPUT(deliveryMethodUid, productUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new delivery method
     * @param {DeliveryMethod} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountDeliveryMethodUsingPUT(
      request: DeliveryMethod,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).createAccountDeliveryMethodUsingPUT(request, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Remove product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductDeliveryMethodUsingDELETE(
      deliveryMethodUid: string,
      productUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid, productUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return information about specified delivery method or about account specified methods, if uid is not specified
     * @param {string} [language] language
     * @param {string} [uid] uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountDeliveryMethodsUsingGET(
      language?: string,
      uid?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).getAccountDeliveryMethodsUsingGET(language, uid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete existing delivery method
     * @param {UidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountDeliveryMethodUsingDELETE(
      request: UidRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).updateAccountDeliveryMethodUsingDELETE(request, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update existing delivery method
     * @param {DeliveryMethod} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountDeliveryMethodUsingPOST(
      request: DeliveryMethod,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).updateAccountDeliveryMethodUsingPOST(request, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update product delivery methods
     * @param {Array<BaseUidObject>} deliveryMethodList deliveryMethodList
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductDeliveryMethodsUsingPOST(
      deliveryMethodList: Array<BaseUidObject>,
      productUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
      const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(
        configuration
      ).updateProductDeliveryMethodsUsingPOST(deliveryMethodList, productUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * DeliveryMethodControllerApi - factory interface
 * @export
 */
export const DeliveryMethodControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Add product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductDeliveryMethodUsingPUT(deliveryMethodUid: string, productUid: string, language?: string, options?: any) {
      return DeliveryMethodControllerApiFp(configuration).addProductDeliveryMethodUsingPUT(
        deliveryMethodUid,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new delivery method
     * @param {DeliveryMethod} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountDeliveryMethodUsingPUT(request: DeliveryMethod, language?: string, options?: any) {
      return DeliveryMethodControllerApiFp(configuration).createAccountDeliveryMethodUsingPUT(
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Remove product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductDeliveryMethodUsingDELETE(
      deliveryMethodUid: string,
      productUid: string,
      language?: string,
      options?: any
    ) {
      return DeliveryMethodControllerApiFp(configuration).deleteProductDeliveryMethodUsingDELETE(
        deliveryMethodUid,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return information about specified delivery method or about account specified methods, if uid is not specified
     * @param {string} [language] language
     * @param {string} [uid] uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options?: any) {
      return DeliveryMethodControllerApiFp(configuration).getAccountDeliveryMethodsUsingGET(
        language,
        uid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete existing delivery method
     * @param {UidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountDeliveryMethodUsingDELETE(request: UidRequest, language?: string, options?: any) {
      return DeliveryMethodControllerApiFp(configuration).updateAccountDeliveryMethodUsingDELETE(
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update existing delivery method
     * @param {DeliveryMethod} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountDeliveryMethodUsingPOST(request: DeliveryMethod, language?: string, options?: any) {
      return DeliveryMethodControllerApiFp(configuration).updateAccountDeliveryMethodUsingPOST(
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update product delivery methods
     * @param {Array<BaseUidObject>} deliveryMethodList deliveryMethodList
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductDeliveryMethodsUsingPOST(
      deliveryMethodList: Array<BaseUidObject>,
      productUid: string,
      language?: string,
      options?: any
    ) {
      return DeliveryMethodControllerApiFp(configuration).updateProductDeliveryMethodsUsingPOST(
        deliveryMethodList,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * DeliveryMethodControllerApi - object-oriented interface
 * @export
 * @class DeliveryMethodControllerApi
 * @extends {BaseAPI}
 */
export class DeliveryMethodControllerApi extends BaseAPI {
  /**
   *
   * @summary Add product delivery method
   * @param {string} deliveryMethodUid deliveryMethodUid
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public addProductDeliveryMethodUsingPUT(
    deliveryMethodUid: string,
    productUid: string,
    language?: string,
    options?: any
  ) {
    return DeliveryMethodControllerApiFp(this.configuration).addProductDeliveryMethodUsingPUT(
      deliveryMethodUid,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new delivery method
   * @param {DeliveryMethod} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public createAccountDeliveryMethodUsingPUT(request: DeliveryMethod, language?: string, options?: any) {
    return DeliveryMethodControllerApiFp(this.configuration).createAccountDeliveryMethodUsingPUT(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Remove product delivery method
   * @param {string} deliveryMethodUid deliveryMethodUid
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public deleteProductDeliveryMethodUsingDELETE(
    deliveryMethodUid: string,
    productUid: string,
    language?: string,
    options?: any
  ) {
    return DeliveryMethodControllerApiFp(this.configuration).deleteProductDeliveryMethodUsingDELETE(
      deliveryMethodUid,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return information about specified delivery method or about account specified methods, if uid is not specified
   * @param {string} [language] language
   * @param {string} [uid] uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options?: any) {
    return DeliveryMethodControllerApiFp(this.configuration).getAccountDeliveryMethodsUsingGET(
      language,
      uid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete existing delivery method
   * @param {UidRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public updateAccountDeliveryMethodUsingDELETE(request: UidRequest, language?: string, options?: any) {
    return DeliveryMethodControllerApiFp(this.configuration).updateAccountDeliveryMethodUsingDELETE(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update existing delivery method
   * @param {DeliveryMethod} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public updateAccountDeliveryMethodUsingPOST(request: DeliveryMethod, language?: string, options?: any) {
    return DeliveryMethodControllerApiFp(this.configuration).updateAccountDeliveryMethodUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update product delivery methods
   * @param {Array<BaseUidObject>} deliveryMethodList deliveryMethodList
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryMethodControllerApi
   */
  public updateProductDeliveryMethodsUsingPOST(
    deliveryMethodList: Array<BaseUidObject>,
    productUid: string,
    language?: string,
    options?: any
  ) {
    return DeliveryMethodControllerApiFp(this.configuration).updateProductDeliveryMethodsUsingPOST(
      deliveryMethodList,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * FavoriteStoreControllerApi - fetch parameter creator
 * @export
 */
export const FavoriteStoreControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add store to favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreToFavoriteUsingPUT(storeUid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling addStoreToFavoriteUsingPUT."
        );
      }
      const localVarPath = `/store/favorite/{storeUid}`.replace(
        `{${"storeUid"}}`,
        encodeURIComponent(String(storeUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get favorite stores
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoriteStoresUsingGET(language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/store/favorite`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove store from favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling removeStoreFromFavoriteUsingDELETE."
        );
      }
      const localVarPath = `/store/favorite/{storeUid}`.replace(
        `{${"storeUid"}}`,
        encodeURIComponent(String(storeUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FavoriteStoreControllerApi - functional programming interface
 * @export
 */
export const FavoriteStoreControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add store to favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreToFavoriteUsingPUT(
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = FavoriteStoreControllerApiFetchParamCreator(configuration).addStoreToFavoriteUsingPUT(
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get favorite stores
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoriteStoresUsingGET(
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = FavoriteStoreControllerApiFetchParamCreator(configuration).getFavoriteStoresUsingGET(
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Remove store from favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStoreFromFavoriteUsingDELETE(
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = FavoriteStoreControllerApiFetchParamCreator(
        configuration
      ).removeStoreFromFavoriteUsingDELETE(storeUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * FavoriteStoreControllerApi - factory interface
 * @export
 */
export const FavoriteStoreControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Add store to favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreToFavoriteUsingPUT(storeUid: string, language?: string, options?: any) {
      return FavoriteStoreControllerApiFp(configuration).addStoreToFavoriteUsingPUT(
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get favorite stores
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoriteStoresUsingGET(language?: string, options?: any) {
      return FavoriteStoreControllerApiFp(configuration).getFavoriteStoresUsingGET(language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Remove store from favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, options?: any) {
      return FavoriteStoreControllerApiFp(configuration).removeStoreFromFavoriteUsingDELETE(
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * FavoriteStoreControllerApi - object-oriented interface
 * @export
 * @class FavoriteStoreControllerApi
 * @extends {BaseAPI}
 */
export class FavoriteStoreControllerApi extends BaseAPI {
  /**
   *
   * @summary Add store to favorites
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteStoreControllerApi
   */
  public addStoreToFavoriteUsingPUT(storeUid: string, language?: string, options?: any) {
    return FavoriteStoreControllerApiFp(this.configuration).addStoreToFavoriteUsingPUT(
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get favorite stores
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteStoreControllerApi
   */
  public getFavoriteStoresUsingGET(language?: string, options?: any) {
    return FavoriteStoreControllerApiFp(this.configuration).getFavoriteStoresUsingGET(language, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Remove store from favorites
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteStoreControllerApi
   */
  public removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, options?: any) {
    return FavoriteStoreControllerApiFp(this.configuration).removeStoreFromFavoriteUsingDELETE(
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * FeatureControllerApi - fetch parameter creator
 * @export
 */
export const FeatureControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return available product features
     * @param {string} [currency] currency
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productFeatureListUsingGET(currency?: string, language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/feature/list`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (currency !== undefined) {
        localVarQueryParameter["currency"] = currency;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeatureControllerApi - functional programming interface
 * @export
 */
export const FeatureControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return available product features
     * @param {string} [currency] currency
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productFeatureListUsingGET(
      currency?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductFeatureType> {
      const localVarFetchArgs = FeatureControllerApiFetchParamCreator(configuration).productFeatureListUsingGET(
        currency,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * FeatureControllerApi - factory interface
 * @export
 */
export const FeatureControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Return available product features
     * @param {string} [currency] currency
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productFeatureListUsingGET(currency?: string, language?: string, options?: any) {
      return FeatureControllerApiFp(configuration).productFeatureListUsingGET(
        currency,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * FeatureControllerApi - object-oriented interface
 * @export
 * @class FeatureControllerApi
 * @extends {BaseAPI}
 */
export class FeatureControllerApi extends BaseAPI {
  /**
   *
   * @summary Return available product features
   * @param {string} [currency] currency
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureControllerApi
   */
  public productFeatureListUsingGET(currency?: string, language?: string, options?: any) {
    return FeatureControllerApiFp(this.configuration).productFeatureListUsingGET(
      currency,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * FileUploadControllerApi - fetch parameter creator
 * @export
 */
export const FileUploadControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload file to storage
     * @param {any} file file
     * @param {any} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileUsingPOST(file: any, bucket?: any, options: any = {}): FetchArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling uploadFileUsingPOST."
        );
      }
      const localVarPath = `/file/upload`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (bucket !== undefined) {
        localVarFormParams.set("bucket", bucket as any);
      }

      if (file !== undefined) {
        localVarFormParams.set("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload BASE64 image to storage
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} ownerUid ownerUid
     * @param {string} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageByFormUsingPOST(
      base64File: string,
      fileName: string,
      ownerUid: string,
      bucket?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'base64File' is not null or undefined
      if (base64File === null || base64File === undefined) {
        throw new RequiredError(
          "base64File",
          "Required parameter base64File was null or undefined when calling uploadImageByFormUsingPOST."
        );
      }
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          "fileName",
          "Required parameter fileName was null or undefined when calling uploadImageByFormUsingPOST."
        );
      }
      // verify required parameter 'ownerUid' is not null or undefined
      if (ownerUid === null || ownerUid === undefined) {
        throw new RequiredError(
          "ownerUid",
          "Required parameter ownerUid was null or undefined when calling uploadImageByFormUsingPOST."
        );
      }
      const localVarPath = `/image/{ownerUid}/upload/base64`.replace(
        `{${"ownerUid"}}`,
        encodeURIComponent(String(ownerUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (base64File !== undefined) {
        localVarFormParams.set("base64File", base64File as any);
      }

      if (bucket !== undefined) {
        localVarFormParams.set("bucket", bucket as any);
      }

      if (fileName !== undefined) {
        localVarFormParams.set("fileName", fileName as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload image to storage
     * @param {any} file file
     * @param {string} ownerUid ownerUid
     * @param {any} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageByMultipartUsingPOST(file: any, ownerUid: string, bucket?: any, options: any = {}): FetchArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling uploadImageByMultipartUsingPOST."
        );
      }
      // verify required parameter 'ownerUid' is not null or undefined
      if (ownerUid === null || ownerUid === undefined) {
        throw new RequiredError(
          "ownerUid",
          "Required parameter ownerUid was null or undefined when calling uploadImageByMultipartUsingPOST."
        );
      }
      const localVarPath = `/image/{ownerUid}/upload/multipart`.replace(
        `{${"ownerUid"}}`,
        encodeURIComponent(String(ownerUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (bucket !== undefined) {
        localVarFormParams.set("bucket", bucket as any);
      }

      if (file !== undefined) {
        localVarFormParams.set("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload BASE64 image to storage
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} ownerUid ownerUid
     * @param {string} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageUsingPOST1(
      base64File: string,
      fileName: string,
      ownerUid: string,
      bucket?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'base64File' is not null or undefined
      if (base64File === null || base64File === undefined) {
        throw new RequiredError(
          "base64File",
          "Required parameter base64File was null or undefined when calling uploadImageUsingPOST1."
        );
      }
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          "fileName",
          "Required parameter fileName was null or undefined when calling uploadImageUsingPOST1."
        );
      }
      // verify required parameter 'ownerUid' is not null or undefined
      if (ownerUid === null || ownerUid === undefined) {
        throw new RequiredError(
          "ownerUid",
          "Required parameter ownerUid was null or undefined when calling uploadImageUsingPOST1."
        );
      }
      const localVarPath = `/image/{ownerUid}/upload`.replace(`{${"ownerUid"}}`, encodeURIComponent(String(ownerUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (base64File !== undefined) {
        localVarFormParams.set("base64File", base64File as any);
      }

      if (bucket !== undefined) {
        localVarFormParams.set("bucket", bucket as any);
      }

      if (fileName !== undefined) {
        localVarFormParams.set("fileName", fileName as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload product BASE64 image
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadProductFileByFormUsingPOST(
      base64File: string,
      fileName: string,
      productUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'base64File' is not null or undefined
      if (base64File === null || base64File === undefined) {
        throw new RequiredError(
          "base64File",
          "Required parameter base64File was null or undefined when calling uploadProductFileByFormUsingPOST."
        );
      }
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          "fileName",
          "Required parameter fileName was null or undefined when calling uploadProductFileByFormUsingPOST."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling uploadProductFileByFormUsingPOST."
        );
      }
      const localVarPath = `/product/{productUid}/upload/base64`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (base64File !== undefined) {
        localVarFormParams.set("base64File", base64File as any);
      }

      if (fileName !== undefined) {
        localVarFormParams.set("fileName", fileName as any);
      }

      if (language !== undefined) {
        localVarFormParams.set("language", language as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload product image
     * @param {any} file file
     * @param {string} productUid productUid
     * @param {any} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadProductFileByMultipartUsingPOST(file: any, productUid: string, language?: any, options: any = {}): FetchArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling uploadProductFileByMultipartUsingPOST."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling uploadProductFileByMultipartUsingPOST."
        );
      }
      const localVarPath = `/product/{productUid}/upload/multipart`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (file !== undefined) {
        localVarFormParams.set("file", file as any);
      }

      if (language !== undefined) {
        localVarFormParams.set("language", language as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload product BASE64 image
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUsingPOST(
      base64File: string,
      fileName: string,
      productUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'base64File' is not null or undefined
      if (base64File === null || base64File === undefined) {
        throw new RequiredError(
          "base64File",
          "Required parameter base64File was null or undefined when calling uploadUsingPOST."
        );
      }
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          "fileName",
          "Required parameter fileName was null or undefined when calling uploadUsingPOST."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling uploadUsingPOST."
        );
      }
      const localVarPath = `/product/{productUid}/upload`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (base64File !== undefined) {
        localVarFormParams.set("base64File", base64File as any);
      }

      if (fileName !== undefined) {
        localVarFormParams.set("fileName", fileName as any);
      }

      if (language !== undefined) {
        localVarFormParams.set("language", language as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FileUploadControllerApi - functional programming interface
 * @export
 */
export const FileUploadControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload file to storage
     * @param {any} file file
     * @param {any} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileUsingPOST(
      file: any,
      bucket?: any,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadFileUsingPOST(
        file,
        bucket,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Upload BASE64 image to storage
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} ownerUid ownerUid
     * @param {string} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageByFormUsingPOST(
      base64File: string,
      fileName: string,
      ownerUid: string,
      bucket?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadImageByFormUsingPOST(
        base64File,
        fileName,
        ownerUid,
        bucket,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Upload image to storage
     * @param {any} file file
     * @param {string} ownerUid ownerUid
     * @param {any} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageByMultipartUsingPOST(
      file: any,
      ownerUid: string,
      bucket?: any,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadImageByMultipartUsingPOST(
        file,
        ownerUid,
        bucket,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Upload BASE64 image to storage
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} ownerUid ownerUid
     * @param {string} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageUsingPOST1(
      base64File: string,
      fileName: string,
      ownerUid: string,
      bucket?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadImageUsingPOST1(
        base64File,
        fileName,
        ownerUid,
        bucket,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Upload product BASE64 image
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadProductFileByFormUsingPOST(
      base64File: string,
      fileName: string,
      productUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(
        configuration
      ).uploadProductFileByFormUsingPOST(base64File, fileName, productUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Upload product image
     * @param {any} file file
     * @param {string} productUid productUid
     * @param {any} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadProductFileByMultipartUsingPOST(
      file: any,
      productUid: string,
      language?: any,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(
        configuration
      ).uploadProductFileByMultipartUsingPOST(file, productUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Upload product BASE64 image
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUsingPOST(
      base64File: string,
      fileName: string,
      productUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadUsingPOST(
        base64File,
        fileName,
        productUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * FileUploadControllerApi - factory interface
 * @export
 */
export const FileUploadControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Upload file to storage
     * @param {any} file file
     * @param {any} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileUsingPOST(file: any, bucket?: any, options?: any) {
      return FileUploadControllerApiFp(configuration).uploadFileUsingPOST(file, bucket, options)(fetch, basePath);
    },
    /**
     *
     * @summary Upload BASE64 image to storage
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} ownerUid ownerUid
     * @param {string} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageByFormUsingPOST(base64File: string, fileName: string, ownerUid: string, bucket?: string, options?: any) {
      return FileUploadControllerApiFp(configuration).uploadImageByFormUsingPOST(
        base64File,
        fileName,
        ownerUid,
        bucket,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Upload image to storage
     * @param {any} file file
     * @param {string} ownerUid ownerUid
     * @param {any} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageByMultipartUsingPOST(file: any, ownerUid: string, bucket?: any, options?: any) {
      return FileUploadControllerApiFp(configuration).uploadImageByMultipartUsingPOST(
        file,
        ownerUid,
        bucket,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Upload BASE64 image to storage
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} ownerUid ownerUid
     * @param {string} [bucket] bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImageUsingPOST1(base64File: string, fileName: string, ownerUid: string, bucket?: string, options?: any) {
      return FileUploadControllerApiFp(configuration).uploadImageUsingPOST1(
        base64File,
        fileName,
        ownerUid,
        bucket,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Upload product BASE64 image
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadProductFileByFormUsingPOST(
      base64File: string,
      fileName: string,
      productUid: string,
      language?: string,
      options?: any
    ) {
      return FileUploadControllerApiFp(configuration).uploadProductFileByFormUsingPOST(
        base64File,
        fileName,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Upload product image
     * @param {any} file file
     * @param {string} productUid productUid
     * @param {any} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadProductFileByMultipartUsingPOST(file: any, productUid: string, language?: any, options?: any) {
      return FileUploadControllerApiFp(configuration).uploadProductFileByMultipartUsingPOST(
        file,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Upload product BASE64 image
     * @param {string} base64File base64File
     * @param {string} fileName fileName
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUsingPOST(base64File: string, fileName: string, productUid: string, language?: string, options?: any) {
      return FileUploadControllerApiFp(configuration).uploadUsingPOST(
        base64File,
        fileName,
        productUid,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * FileUploadControllerApi - object-oriented interface
 * @export
 * @class FileUploadControllerApi
 * @extends {BaseAPI}
 */
export class FileUploadControllerApi extends BaseAPI {
  /**
   *
   * @summary Upload file to storage
   * @param {any} file file
   * @param {any} [bucket] bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadFileUsingPOST(file: any, bucket?: any, options?: any) {
    return FileUploadControllerApiFp(this.configuration).uploadFileUsingPOST(
      file,
      bucket,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Upload BASE64 image to storage
   * @param {string} base64File base64File
   * @param {string} fileName fileName
   * @param {string} ownerUid ownerUid
   * @param {string} [bucket] bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadImageByFormUsingPOST(
    base64File: string,
    fileName: string,
    ownerUid: string,
    bucket?: string,
    options?: any
  ) {
    return FileUploadControllerApiFp(this.configuration).uploadImageByFormUsingPOST(
      base64File,
      fileName,
      ownerUid,
      bucket,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Upload image to storage
   * @param {any} file file
   * @param {string} ownerUid ownerUid
   * @param {any} [bucket] bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadImageByMultipartUsingPOST(file: any, ownerUid: string, bucket?: any, options?: any) {
    return FileUploadControllerApiFp(this.configuration).uploadImageByMultipartUsingPOST(
      file,
      ownerUid,
      bucket,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Upload BASE64 image to storage
   * @param {string} base64File base64File
   * @param {string} fileName fileName
   * @param {string} ownerUid ownerUid
   * @param {string} [bucket] bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadImageUsingPOST1(base64File: string, fileName: string, ownerUid: string, bucket?: string, options?: any) {
    return FileUploadControllerApiFp(this.configuration).uploadImageUsingPOST1(
      base64File,
      fileName,
      ownerUid,
      bucket,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Upload product BASE64 image
   * @param {string} base64File base64File
   * @param {string} fileName fileName
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadProductFileByFormUsingPOST(
    base64File: string,
    fileName: string,
    productUid: string,
    language?: string,
    options?: any
  ) {
    return FileUploadControllerApiFp(this.configuration).uploadProductFileByFormUsingPOST(
      base64File,
      fileName,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Upload product image
   * @param {any} file file
   * @param {string} productUid productUid
   * @param {any} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadProductFileByMultipartUsingPOST(file: any, productUid: string, language?: any, options?: any) {
    return FileUploadControllerApiFp(this.configuration).uploadProductFileByMultipartUsingPOST(
      file,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Upload product BASE64 image
   * @param {string} base64File base64File
   * @param {string} fileName fileName
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileUploadControllerApi
   */
  public uploadUsingPOST(base64File: string, fileName: string, productUid: string, language?: string, options?: any) {
    return FileUploadControllerApiFp(this.configuration).uploadUsingPOST(
      base64File,
      fileName,
      productUid,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * InfoControllerApi - fetch parameter creator
 * @export
 */
export const InfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Show current build information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    versionUsingGET(options: any = {}): FetchArgs {
      const localVarPath = `/version`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InfoControllerApi - functional programming interface
 * @export
 */
export const InfoControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Show current build information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    versionUsingGET(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBuildProperties> {
      const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).versionUsingGET(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * InfoControllerApi - factory interface
 * @export
 */
export const InfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Show current build information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    versionUsingGET(options?: any) {
      return InfoControllerApiFp(configuration).versionUsingGET(options)(fetch, basePath);
    },
  };
};

/**
 * InfoControllerApi - object-oriented interface
 * @export
 * @class InfoControllerApi
 * @extends {BaseAPI}
 */
export class InfoControllerApi extends BaseAPI {
  /**
   *
   * @summary Show current build information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfoControllerApi
   */
  public versionUsingGET(options?: any) {
    return InfoControllerApiFp(this.configuration).versionUsingGET(options)(this.fetch, this.basePath);
  }
}

/**
 * OmnideskTicketControllerApi - fetch parameter creator
 * @export
 */
export const OmnideskTicketControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Omnidesk ticket
     * @param {OmnideskTicket} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOmnideskTicketUsingPUT(request: OmnideskTicket, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createOmnideskTicketUsingPUT."
        );
      }
      const localVarPath = `/omnidesk/ticket`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OmnideskTicket" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OmnideskTicketControllerApi - functional programming interface
 * @export
 */
export const OmnideskTicketControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Omnidesk ticket
     * @param {OmnideskTicket} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOmnideskTicketUsingPUT(
      request: OmnideskTicket,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseOmnideskTicket> {
      const localVarFetchArgs = OmnideskTicketControllerApiFetchParamCreator(
        configuration
      ).createOmnideskTicketUsingPUT(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * OmnideskTicketControllerApi - factory interface
 * @export
 */
export const OmnideskTicketControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Create Omnidesk ticket
     * @param {OmnideskTicket} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOmnideskTicketUsingPUT(request: OmnideskTicket, options?: any) {
      return OmnideskTicketControllerApiFp(configuration).createOmnideskTicketUsingPUT(request, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * OmnideskTicketControllerApi - object-oriented interface
 * @export
 * @class OmnideskTicketControllerApi
 * @extends {BaseAPI}
 */
export class OmnideskTicketControllerApi extends BaseAPI {
  /**
   *
   * @summary Create Omnidesk ticket
   * @param {OmnideskTicket} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OmnideskTicketControllerApi
   */
  public createOmnideskTicketUsingPUT(request: OmnideskTicket, options?: any) {
    return OmnideskTicketControllerApiFp(this.configuration).createOmnideskTicketUsingPUT(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * PrivateProductControllerApi - fetch parameter creator
 * @export
 */
export const PrivateProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Calculate delivery price
     * @param {string} accountUid accountUid
     * @param {CalculateDeliveryRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDeliveryUsingPOST1(accountUid: string, request: CalculateDeliveryRequest, options: any = {}): FetchArgs {
      // verify required parameter 'accountUid' is not null or undefined
      if (accountUid === null || accountUid === undefined) {
        throw new RequiredError(
          "accountUid",
          "Required parameter accountUid was null or undefined when calling calculateDeliveryUsingPOST1."
        );
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling calculateDeliveryUsingPOST1."
        );
      }
      const localVarPath = `/private/product/calculateDelivery`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CalculateDeliveryRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search product by external UID
     * @param {ExternalProductRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalProductListUsingPOST(request: ExternalProductRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling externalProductListUsingPOST."
        );
      }
      const localVarPath = `/private/product/external`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ExternalProductRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get extended product info
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productExtInfoUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling productExtInfoUsingGET."
        );
      }
      const localVarPath = `/private/product/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Simple product list
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleProductListUsingPOST(productUidList: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'productUidList' is not null or undefined
      if (productUidList === null || productUidList === undefined) {
        throw new RequiredError(
          "productUidList",
          "Required parameter productUidList was null or undefined when calling simpleProductListUsingPOST."
        );
      }
      const localVarPath = `/private/product/simpleList`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(productUidList || {}) : productUidList || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PrivateProductControllerApi - functional programming interface
 * @export
 */
export const PrivateProductControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Calculate delivery price
     * @param {string} accountUid accountUid
     * @param {CalculateDeliveryRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDeliveryUsingPOST1(
      accountUid: string,
      request: CalculateDeliveryRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCalculateDeliveryResponse> {
      const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).calculateDeliveryUsingPOST1(
        accountUid,
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Search product by external UID
     * @param {ExternalProductRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalProductListUsingPOST(
      request: ExternalProductRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductExt> {
      const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(
        configuration
      ).externalProductListUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get extended product info
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productExtInfoUsingGET(
      uid: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductExt> {
      const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).productExtInfoUsingGET(
        uid,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Simple product list
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleProductListUsingPOST(
      productUidList: Array<string>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductExt> {
      const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).simpleProductListUsingPOST(
        productUidList,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PrivateProductControllerApi - factory interface
 * @export
 */
export const PrivateProductControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Calculate delivery price
     * @param {string} accountUid accountUid
     * @param {CalculateDeliveryRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDeliveryUsingPOST1(accountUid: string, request: CalculateDeliveryRequest, options?: any) {
      return PrivateProductControllerApiFp(configuration).calculateDeliveryUsingPOST1(
        accountUid,
        request,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Search product by external UID
     * @param {ExternalProductRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalProductListUsingPOST(request: ExternalProductRequest, options?: any) {
      return PrivateProductControllerApiFp(configuration).externalProductListUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Get extended product info
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productExtInfoUsingGET(uid: string, country?: string, language?: string, options?: any) {
      return PrivateProductControllerApiFp(configuration).productExtInfoUsingGET(
        uid,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Simple product list
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simpleProductListUsingPOST(productUidList: Array<string>, options?: any) {
      return PrivateProductControllerApiFp(configuration).simpleProductListUsingPOST(productUidList, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * PrivateProductControllerApi - object-oriented interface
 * @export
 * @class PrivateProductControllerApi
 * @extends {BaseAPI}
 */
export class PrivateProductControllerApi extends BaseAPI {
  /**
   *
   * @summary Calculate delivery price
   * @param {string} accountUid accountUid
   * @param {CalculateDeliveryRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivateProductControllerApi
   */
  public calculateDeliveryUsingPOST1(accountUid: string, request: CalculateDeliveryRequest, options?: any) {
    return PrivateProductControllerApiFp(this.configuration).calculateDeliveryUsingPOST1(
      accountUid,
      request,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Search product by external UID
   * @param {ExternalProductRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivateProductControllerApi
   */
  public externalProductListUsingPOST(request: ExternalProductRequest, options?: any) {
    return PrivateProductControllerApiFp(this.configuration).externalProductListUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Get extended product info
   * @param {string} uid uid
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivateProductControllerApi
   */
  public productExtInfoUsingGET(uid: string, country?: string, language?: string, options?: any) {
    return PrivateProductControllerApiFp(this.configuration).productExtInfoUsingGET(
      uid,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Simple product list
   * @param {Array<string>} productUidList productUidList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivateProductControllerApi
   */
  public simpleProductListUsingPOST(productUidList: Array<string>, options?: any) {
    return PrivateProductControllerApiFp(this.configuration).simpleProductListUsingPOST(productUidList, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * ProductControllerApi - fetch parameter creator
 * @export
 */
export const ProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new product
     * @param {Product} product product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductUsingPUT(product: Product, options: any = {}): FetchArgs {
      // verify required parameter 'product' is not null or undefined
      if (product === null || product === undefined) {
        throw new RequiredError(
          "product",
          "Required parameter product was null or undefined when calling addProductUsingPUT."
        );
      }
      const localVarPath = `/product`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Product" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(product || {}) : product || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive active products
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveProductsUsingPOST(productUidList: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'productUidList' is not null or undefined
      if (productUidList === null || productUidList === undefined) {
        throw new RequiredError(
          "productUidList",
          "Required parameter productUidList was null or undefined when calling archiveProductsUsingPOST."
        );
      }
      const localVarPath = `/product/archive`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(productUidList || {}) : productUidList || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete product image
     * @param {ProductImageDeleteRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductImageUsingDELETE(request: ProductImageDeleteRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteProductImageUsingDELETE."
        );
      }
      const localVarPath = `/product/image`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductImageDeleteRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete existing product
     * @param {ProductUidListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductUsingDELETE(request: ProductUidListRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteProductUsingDELETE."
        );
      }
      const localVarPath = `/product`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductUidListRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update existing product
     * @param {Product} product product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProductUsingPOST(product: Product, options: any = {}): FetchArgs {
      // verify required parameter 'product' is not null or undefined
      if (product === null || product === undefined) {
        throw new RequiredError(
          "product",
          "Required parameter product was null or undefined when calling editProductUsingPOST."
        );
      }
      const localVarPath = `/product`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Product" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(product || {}) : product || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product details
     * @param {ProductUidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productDetailsUsingPOST(request: ProductUidRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productDetailsUsingPOST."
        );
      }
      const localVarPath = `/product/details`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductUidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product list
     * @param {ProductListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productListUsingPOST(request: ProductListRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productListUsingPOST."
        );
      }
      const localVarPath = `/product/list`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductListRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search for matches by keywords
     * @param {SearchRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productPresearchUsingPOST(
      request: SearchRequest,
      country?: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productPresearchUsingPOST."
        );
      }
      const localVarPath = `/product/presearch`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"SearchRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling productSearchDetailsUsingGET."
        );
      }
      const localVarPath = `/product/search/details/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get published product details
     * @param {ProductUidRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingPOST(
      request: ProductUidRequest,
      country?: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productSearchDetailsUsingPOST."
        );
      }
      const localVarPath = `/product/search/details`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductUidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchUsingPOST(
      request: ProductSearchListRequest,
      country?: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productSearchUsingPOST."
        );
      }
      const localVarPath = `/product/search`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductSearchListRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productWidgetListUsingGET(listType: string, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'listType' is not null or undefined
      if (listType === null || listType === undefined) {
        throw new RequiredError(
          "listType",
          "Required parameter listType was null or undefined when calling productWidgetListUsingGET."
        );
      }
      const localVarPath = `/product/list/{listType}`.replace(`{${"listType"}}`, encodeURIComponent(String(listType)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return random products by category
     * @param {RandomProductListRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    randomProductSearchUsingPOST(
      request: RandomProductListRequest,
      country?: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling randomProductSearchUsingPOST."
        );
      }
      const localVarPath = `/product/random`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"RandomProductListRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Restore archived products
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreProductsUsingPOST(productUidList: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'productUidList' is not null or undefined
      if (productUidList === null || productUidList === undefined) {
        throw new RequiredError(
          "productUidList",
          "Required parameter productUidList was null or undefined when calling restoreProductsUsingPOST."
        );
      }
      const localVarPath = `/product/restore`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(productUidList || {}) : productUidList || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update product expiration date
     * @param {ProductExpirationDateRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductExpirationDateUsingPOST(request: ProductExpirationDateRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateProductExpirationDateUsingPOST."
        );
      }
      const localVarPath = `/product/expiration`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductExpirationDateRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update product quantity
     * @param {ProductQuantityRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductQuantityUsingPOST(request: ProductQuantityRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateProductQuantityUsingPOST."
        );
      }
      const localVarPath = `/product/quantity`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductQuantityRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update product status
     * @param {ProductStatusRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductStatusUsingPOST(request: ProductStatusRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateProductStatusUsingPOST."
        );
      }
      const localVarPath = `/product/status`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductStatusRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductControllerApi - functional programming interface
 * @export
 */
export const ProductControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new product
     * @param {Product} product product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductUsingPUT(
      product: Product,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).addProductUsingPUT(
        product,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Archive active products
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveProductsUsingPOST(
      productUidList: Array<string>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).archiveProductsUsingPOST(
        productUidList,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete product image
     * @param {ProductImageDeleteRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductImageUsingDELETE(
      request: ProductImageDeleteRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).deleteProductImageUsingDELETE(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete existing product
     * @param {ProductUidListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductUsingDELETE(
      request: ProductUidListRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).deleteProductUsingDELETE(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update existing product
     * @param {Product} product product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProductUsingPOST(
      product: Product,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).editProductUsingPOST(
        product,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get product details
     * @param {ProductUidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productDetailsUsingPOST(
      request: ProductUidRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productDetailsUsingPOST(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get product list
     * @param {ProductListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productListUsingPOST(
      request: ProductListRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productListUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Search for matches by keywords
     * @param {SearchRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productPresearchUsingPOST(
      request: SearchRequest,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSearchResult> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productPresearchUsingPOST(
        request,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingGET(
      uid: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productSearchDetailsUsingGET(
        uid,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get published product details
     * @param {ProductUidRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingPOST(
      request: ProductUidRequest,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productSearchDetailsUsingPOST(
        request,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchUsingPOST(
      request: ProductSearchListRequest,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productSearchUsingPOST(
        request,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productWidgetListUsingGET(
      listType: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productWidgetListUsingGET(
        listType,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return random products by category
     * @param {RandomProductListRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    randomProductSearchUsingPOST(
      request: RandomProductListRequest,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<MultipleMarketplaceApiResponseProductAndProductCategory> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).randomProductSearchUsingPOST(
        request,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Restore archived products
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreProductsUsingPOST(
      productUidList: Array<string>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).restoreProductsUsingPOST(
        productUidList,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update product expiration date
     * @param {ProductExpirationDateRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductExpirationDateUsingPOST(
      request: ProductExpirationDateRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(
        configuration
      ).updateProductExpirationDateUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update product quantity
     * @param {ProductQuantityRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductQuantityUsingPOST(
      request: ProductQuantityRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductQuantityUsingPOST(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update product status
     * @param {ProductStatusRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductStatusUsingPOST(
      request: ProductStatusRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductStatusUsingPOST(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ProductControllerApi - factory interface
 * @export
 */
export const ProductControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Add new product
     * @param {Product} product product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductUsingPUT(product: Product, options?: any) {
      return ProductControllerApiFp(configuration).addProductUsingPUT(product, options)(fetch, basePath);
    },
    /**
     *
     * @summary Archive active products
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveProductsUsingPOST(productUidList: Array<string>, options?: any) {
      return ProductControllerApiFp(configuration).archiveProductsUsingPOST(productUidList, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete product image
     * @param {ProductImageDeleteRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductImageUsingDELETE(request: ProductImageDeleteRequest, options?: any) {
      return ProductControllerApiFp(configuration).deleteProductImageUsingDELETE(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete existing product
     * @param {ProductUidListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductUsingDELETE(request: ProductUidListRequest, options?: any) {
      return ProductControllerApiFp(configuration).deleteProductUsingDELETE(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update existing product
     * @param {Product} product product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProductUsingPOST(product: Product, options?: any) {
      return ProductControllerApiFp(configuration).editProductUsingPOST(product, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get product details
     * @param {ProductUidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productDetailsUsingPOST(request: ProductUidRequest, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).productDetailsUsingPOST(request, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get product list
     * @param {ProductListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productListUsingPOST(request: ProductListRequest, options?: any) {
      return ProductControllerApiFp(configuration).productListUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary Search for matches by keywords
     * @param {SearchRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productPresearchUsingPOST(request: SearchRequest, country?: string, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).productPresearchUsingPOST(
        request,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).productSearchDetailsUsingGET(
        uid,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get published product details
     * @param {ProductUidRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingPOST(request: ProductUidRequest, country?: string, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).productSearchDetailsUsingPOST(
        request,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchUsingPOST(request: ProductSearchListRequest, country?: string, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).productSearchUsingPOST(
        request,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productWidgetListUsingGET(listType: string, country?: string, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).productWidgetListUsingGET(
        listType,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return random products by category
     * @param {RandomProductListRequest} request request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    randomProductSearchUsingPOST(
      request: RandomProductListRequest,
      country?: string,
      language?: string,
      options?: any
    ) {
      return ProductControllerApiFp(configuration).randomProductSearchUsingPOST(
        request,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Restore archived products
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreProductsUsingPOST(productUidList: Array<string>, options?: any) {
      return ProductControllerApiFp(configuration).restoreProductsUsingPOST(productUidList, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update product expiration date
     * @param {ProductExpirationDateRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductExpirationDateUsingPOST(request: ProductExpirationDateRequest, options?: any) {
      return ProductControllerApiFp(configuration).updateProductExpirationDateUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Update product quantity
     * @param {ProductQuantityRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductQuantityUsingPOST(request: ProductQuantityRequest, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).updateProductQuantityUsingPOST(
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update product status
     * @param {ProductStatusRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductStatusUsingPOST(request: ProductStatusRequest, language?: string, options?: any) {
      return ProductControllerApiFp(configuration).updateProductStatusUsingPOST(
        request,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * ProductControllerApi - object-oriented interface
 * @export
 * @class ProductControllerApi
 * @extends {BaseAPI}
 */
export class ProductControllerApi extends BaseAPI {
  /**
   *
   * @summary Add new product
   * @param {Product} product product
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public addProductUsingPUT(product: Product, options?: any) {
    return ProductControllerApiFp(this.configuration).addProductUsingPUT(product, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Archive active products
   * @param {Array<string>} productUidList productUidList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public archiveProductsUsingPOST(productUidList: Array<string>, options?: any) {
    return ProductControllerApiFp(this.configuration).archiveProductsUsingPOST(productUidList, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete product image
   * @param {ProductImageDeleteRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public deleteProductImageUsingDELETE(request: ProductImageDeleteRequest, options?: any) {
    return ProductControllerApiFp(this.configuration).deleteProductImageUsingDELETE(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete existing product
   * @param {ProductUidListRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public deleteProductUsingDELETE(request: ProductUidListRequest, options?: any) {
    return ProductControllerApiFp(this.configuration).deleteProductUsingDELETE(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Update existing product
   * @param {Product} product product
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public editProductUsingPOST(product: Product, options?: any) {
    return ProductControllerApiFp(this.configuration).editProductUsingPOST(product, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get product details
   * @param {ProductUidRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productDetailsUsingPOST(request: ProductUidRequest, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).productDetailsUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get product list
   * @param {ProductListRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productListUsingPOST(request: ProductListRequest, options?: any) {
    return ProductControllerApiFp(this.configuration).productListUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Search for matches by keywords
   * @param {SearchRequest} request request
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productPresearchUsingPOST(request: SearchRequest, country?: string, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).productPresearchUsingPOST(
      request,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get product details
   * @param {string} uid uid
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).productSearchDetailsUsingGET(
      uid,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get published product details
   * @param {ProductUidRequest} request request
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productSearchDetailsUsingPOST(request: ProductUidRequest, country?: string, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).productSearchDetailsUsingPOST(
      request,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Search published products using different criteria
   * @param {ProductSearchListRequest} request request
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productSearchUsingPOST(request: ProductSearchListRequest, country?: string, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).productSearchUsingPOST(
      request,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
   * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public productWidgetListUsingGET(listType: string, country?: string, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).productWidgetListUsingGET(
      listType,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return random products by category
   * @param {RandomProductListRequest} request request
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public randomProductSearchUsingPOST(
    request: RandomProductListRequest,
    country?: string,
    language?: string,
    options?: any
  ) {
    return ProductControllerApiFp(this.configuration).randomProductSearchUsingPOST(
      request,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Restore archived products
   * @param {Array<string>} productUidList productUidList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public restoreProductsUsingPOST(productUidList: Array<string>, options?: any) {
    return ProductControllerApiFp(this.configuration).restoreProductsUsingPOST(productUidList, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Update product expiration date
   * @param {ProductExpirationDateRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public updateProductExpirationDateUsingPOST(request: ProductExpirationDateRequest, options?: any) {
    return ProductControllerApiFp(this.configuration).updateProductExpirationDateUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Update product quantity
   * @param {ProductQuantityRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public updateProductQuantityUsingPOST(request: ProductQuantityRequest, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).updateProductQuantityUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update product status
   * @param {ProductStatusRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductControllerApi
   */
  public updateProductStatusUsingPOST(request: ProductStatusRequest, language?: string, options?: any) {
    return ProductControllerApiFp(this.configuration).updateProductStatusUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * ProductReviewControllerApi - fetch parameter creator
 * @export
 */
export const ProductReviewControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete product review image
     * @param {string} imageUid imageUid
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingDELETE(
      imageUid: string,
      productUid: string,
      reviewUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'imageUid' is not null or undefined
      if (imageUid === null || imageUid === undefined) {
        throw new RequiredError(
          "imageUid",
          "Required parameter imageUid was null or undefined when calling addProductReviewImageUsingDELETE."
        );
      }
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addProductReviewImageUsingDELETE."
        );
      }
      // verify required parameter 'reviewUid' is not null or undefined
      if (reviewUid === null || reviewUid === undefined) {
        throw new RequiredError(
          "reviewUid",
          "Required parameter reviewUid was null or undefined when calling addProductReviewImageUsingDELETE."
        );
      }
      const localVarPath = `/product/{productUid}/review/{reviewUid}/image/{imageUid}`
        .replace(`{${"imageUid"}}`, encodeURIComponent(String(imageUid)))
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
        .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete product review
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addProductReviewImageUsingDELETE1."
        );
      }
      // verify required parameter 'reviewUid' is not null or undefined
      if (reviewUid === null || reviewUid === undefined) {
        throw new RequiredError(
          "reviewUid",
          "Required parameter reviewUid was null or undefined when calling addProductReviewImageUsingDELETE1."
        );
      }
      const localVarPath = `/product/{productUid}/review/{reviewUid}`
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
        .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add product review image
     * @param {string} productUid productUid
     * @param {AddProductReviewImageRequest} request request
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingPUT(
      productUid: string,
      request: AddProductReviewImageRequest,
      reviewUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addProductReviewImageUsingPUT."
        );
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling addProductReviewImageUsingPUT."
        );
      }
      // verify required parameter 'reviewUid' is not null or undefined
      if (reviewUid === null || reviewUid === undefined) {
        throw new RequiredError(
          "reviewUid",
          "Required parameter reviewUid was null or undefined when calling addProductReviewImageUsingPUT."
        );
      }
      const localVarPath = `/product/{productUid}/review/{reviewUid}/image`
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
        .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"AddProductReviewImageRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit product review
     * @param {string} productUid productUid
     * @param {EditProductReviewRequest} request request
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewUsingPOST(
      productUid: string,
      request: EditProductReviewRequest,
      reviewUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addProductReviewUsingPOST."
        );
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling addProductReviewUsingPOST."
        );
      }
      // verify required parameter 'reviewUid' is not null or undefined
      if (reviewUid === null || reviewUid === undefined) {
        throw new RequiredError(
          "reviewUid",
          "Required parameter reviewUid was null or undefined when calling addProductReviewUsingPOST."
        );
      }
      const localVarPath = `/product/{productUid}/review/{reviewUid}`
        .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
        .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"EditProductReviewRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add product review
     * @param {string} productUid productUid
     * @param {AddProductReviewRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewUsingPUT(
      productUid: string,
      request: AddProductReviewRequest,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addProductReviewUsingPUT."
        );
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling addProductReviewUsingPUT."
        );
      }
      const localVarPath = `/product/{productUid}/review`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"AddProductReviewRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of all reviews by account
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountReviewsUsingGET(language?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/product/reviews`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of product reviews
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReviewsUsingGET(productUid: string, language?: string, sort?: string, options: any = {}): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling getProductReviewsUsingGET."
        );
      }
      const localVarPath = `/product/{productUid}/review`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductReviewControllerApi - functional programming interface
 * @export
 */
export const ProductReviewControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete product review image
     * @param {string} imageUid imageUid
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingDELETE(
      imageUid: string,
      productUid: string,
      reviewUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(
        configuration
      ).addProductReviewImageUsingDELETE(imageUid, productUid, reviewUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete product review
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingDELETE1(
      productUid: string,
      reviewUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(
        configuration
      ).addProductReviewImageUsingDELETE1(productUid, reviewUid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add product review image
     * @param {string} productUid productUid
     * @param {AddProductReviewImageRequest} request request
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingPUT(
      productUid: string,
      request: AddProductReviewImageRequest,
      reviewUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(
        configuration
      ).addProductReviewImageUsingPUT(productUid, request, reviewUid, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Edit product review
     * @param {string} productUid productUid
     * @param {EditProductReviewRequest} request request
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewUsingPOST(
      productUid: string,
      request: EditProductReviewRequest,
      reviewUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewUsingPOST(
        productUid,
        request,
        reviewUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add product review
     * @param {string} productUid productUid
     * @param {AddProductReviewRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewUsingPUT(
      productUid: string,
      request: AddProductReviewRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewUsingPUT(
        productUid,
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return list of all reviews by account
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountReviewsUsingGET(
      language?: string,
      sort?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).getAccountReviewsUsingGET(
        language,
        sort,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return list of product reviews
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReviewsUsingGET(
      productUid: string,
      language?: string,
      sort?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
      const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).getProductReviewsUsingGET(
        productUid,
        language,
        sort,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ProductReviewControllerApi - factory interface
 * @export
 */
export const ProductReviewControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Delete product review image
     * @param {string} imageUid imageUid
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingDELETE(
      imageUid: string,
      productUid: string,
      reviewUid: string,
      language?: string,
      options?: any
    ) {
      return ProductReviewControllerApiFp(configuration).addProductReviewImageUsingDELETE(
        imageUid,
        productUid,
        reviewUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete product review
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options?: any) {
      return ProductReviewControllerApiFp(configuration).addProductReviewImageUsingDELETE1(
        productUid,
        reviewUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add product review image
     * @param {string} productUid productUid
     * @param {AddProductReviewImageRequest} request request
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewImageUsingPUT(
      productUid: string,
      request: AddProductReviewImageRequest,
      reviewUid: string,
      language?: string,
      options?: any
    ) {
      return ProductReviewControllerApiFp(configuration).addProductReviewImageUsingPUT(
        productUid,
        request,
        reviewUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Edit product review
     * @param {string} productUid productUid
     * @param {EditProductReviewRequest} request request
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewUsingPOST(
      productUid: string,
      request: EditProductReviewRequest,
      reviewUid: string,
      language?: string,
      options?: any
    ) {
      return ProductReviewControllerApiFp(configuration).addProductReviewUsingPOST(
        productUid,
        request,
        reviewUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add product review
     * @param {string} productUid productUid
     * @param {AddProductReviewRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductReviewUsingPUT(productUid: string, request: AddProductReviewRequest, language?: string, options?: any) {
      return ProductReviewControllerApiFp(configuration).addProductReviewUsingPUT(
        productUid,
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return list of all reviews by account
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountReviewsUsingGET(language?: string, sort?: string, options?: any) {
      return ProductReviewControllerApiFp(configuration).getAccountReviewsUsingGET(
        language,
        sort,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Return list of product reviews
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReviewsUsingGET(productUid: string, language?: string, sort?: string, options?: any) {
      return ProductReviewControllerApiFp(configuration).getProductReviewsUsingGET(
        productUid,
        language,
        sort,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * ProductReviewControllerApi - object-oriented interface
 * @export
 * @class ProductReviewControllerApi
 * @extends {BaseAPI}
 */
export class ProductReviewControllerApi extends BaseAPI {
  /**
   *
   * @summary Delete product review image
   * @param {string} imageUid imageUid
   * @param {string} productUid productUid
   * @param {string} reviewUid reviewUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public addProductReviewImageUsingDELETE(
    imageUid: string,
    productUid: string,
    reviewUid: string,
    language?: string,
    options?: any
  ) {
    return ProductReviewControllerApiFp(this.configuration).addProductReviewImageUsingDELETE(
      imageUid,
      productUid,
      reviewUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete product review
   * @param {string} productUid productUid
   * @param {string} reviewUid reviewUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options?: any) {
    return ProductReviewControllerApiFp(this.configuration).addProductReviewImageUsingDELETE1(
      productUid,
      reviewUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add product review image
   * @param {string} productUid productUid
   * @param {AddProductReviewImageRequest} request request
   * @param {string} reviewUid reviewUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public addProductReviewImageUsingPUT(
    productUid: string,
    request: AddProductReviewImageRequest,
    reviewUid: string,
    language?: string,
    options?: any
  ) {
    return ProductReviewControllerApiFp(this.configuration).addProductReviewImageUsingPUT(
      productUid,
      request,
      reviewUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Edit product review
   * @param {string} productUid productUid
   * @param {EditProductReviewRequest} request request
   * @param {string} reviewUid reviewUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public addProductReviewUsingPOST(
    productUid: string,
    request: EditProductReviewRequest,
    reviewUid: string,
    language?: string,
    options?: any
  ) {
    return ProductReviewControllerApiFp(this.configuration).addProductReviewUsingPOST(
      productUid,
      request,
      reviewUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add product review
   * @param {string} productUid productUid
   * @param {AddProductReviewRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public addProductReviewUsingPUT(
    productUid: string,
    request: AddProductReviewRequest,
    language?: string,
    options?: any
  ) {
    return ProductReviewControllerApiFp(this.configuration).addProductReviewUsingPUT(
      productUid,
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return list of all reviews by account
   * @param {string} [language] language
   * @param {string} [sort] sort
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public getAccountReviewsUsingGET(language?: string, sort?: string, options?: any) {
    return ProductReviewControllerApiFp(this.configuration).getAccountReviewsUsingGET(
      language,
      sort,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return list of product reviews
   * @param {string} productUid productUid
   * @param {string} [language] language
   * @param {string} [sort] sort
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReviewControllerApi
   */
  public getProductReviewsUsingGET(productUid: string, language?: string, sort?: string, options?: any) {
    return ProductReviewControllerApiFp(this.configuration).getProductReviewsUsingGET(
      productUid,
      language,
      sort,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * ProductSourceControllerApi - fetch parameter creator
 * @export
 */
export const ProductSourceControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account product category mapping
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountProductCategoryListUsingGET(language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/product/import/category`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new product sources
     * @param {ProductSource} source source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductSourceUsingPUT1(source: ProductSource, options: any = {}): FetchArgs {
      // verify required parameter 'source' is not null or undefined
      if (source === null || source === undefined) {
        throw new RequiredError(
          "source",
          "Required parameter source was null or undefined when calling addProductSourceUsingPUT1."
        );
      }
      const localVarPath = `/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductSource" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(source || {}) : source || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete existing product sources
     * @param {UidRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductSourceUsingDELETE1(request: UidRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteProductSourceUsingDELETE1."
        );
      }
      const localVarPath = `/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"UidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSourceUsingGET1(sourceUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'sourceUid' is not null or undefined
      if (sourceUid === null || sourceUid === undefined) {
        throw new RequiredError(
          "sourceUid",
          "Required parameter sourceUid was null or undefined when calling getProductSourceUsingGET1."
        );
      }
      const localVarPath = `/product/import/source/{sourceUid}`.replace(
        `{${"sourceUid"}}`,
        encodeURIComponent(String(sourceUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceHistoryUsingGET1(sourceUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'sourceUid' is not null or undefined
      if (sourceUid === null || sourceUid === undefined) {
        throw new RequiredError(
          "sourceUid",
          "Required parameter sourceUid was null or undefined when calling productSourceHistoryUsingGET1."
        );
      }
      const localVarPath = `/product/import/source/{sourceUid}/history`.replace(
        `{${"sourceUid"}}`,
        encodeURIComponent(String(sourceUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of product sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceListUsingGET1(options: any = {}): FetchArgs {
      const localVarPath = `/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return list of product sources types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceTypeListUsingGET(options: any = {}): FetchArgs {
      const localVarPath = `/product/import/source/type`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update account product category mapping
     * @param {Array<AccountCategory>} categoryMapping categoryMapping
     * @param {boolean} [deleteUnused] deleteUnused
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountProductCategoryUsingPOST(
      categoryMapping: Array<AccountCategory>,
      deleteUnused?: boolean,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'categoryMapping' is not null or undefined
      if (categoryMapping === null || categoryMapping === undefined) {
        throw new RequiredError(
          "categoryMapping",
          "Required parameter categoryMapping was null or undefined when calling updateAccountProductCategoryUsingPOST."
        );
      }
      const localVarPath = `/product/import/category`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (deleteUnused !== undefined) {
        localVarQueryParameter["deleteUnused"] = deleteUnused;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;AccountCategory&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(categoryMapping || {}) : categoryMapping || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update existing product sources
     * @param {ProductSource} source source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductSourceUsingPOST1(source: ProductSource, options: any = {}): FetchArgs {
      // verify required parameter 'source' is not null or undefined
      if (source === null || source === undefined) {
        throw new RequiredError(
          "source",
          "Required parameter source was null or undefined when calling updateProductSourceUsingPOST1."
        );
      }
      const localVarPath = `/product/import/source`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductSource" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(source || {}) : source || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductSourceUsingGET1(sourceUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'sourceUid' is not null or undefined
      if (sourceUid === null || sourceUid === undefined) {
        throw new RequiredError(
          "sourceUid",
          "Required parameter sourceUid was null or undefined when calling validateProductSourceUsingGET1."
        );
      }
      const localVarPath = `/product/import/source/{sourceUid}/validate`.replace(
        `{${"sourceUid"}}`,
        encodeURIComponent(String(sourceUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductSourceControllerApi - functional programming interface
 * @export
 */
export const ProductSourceControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account product category mapping
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountProductCategoryListUsingGET(
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(
        configuration
      ).accountProductCategoryListUsingGET(language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new product sources
     * @param {ProductSource} source source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductSourceUsingPUT1(
      source: ProductSource,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).addProductSourceUsingPUT1(
        source,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete existing product sources
     * @param {UidRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductSourceUsingDELETE1(
      request: UidRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(
        configuration
      ).deleteProductSourceUsingDELETE1(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSourceUsingGET1(
      sourceUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).getProductSourceUsingGET1(
        sourceUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceHistoryUsingGET1(
      sourceUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceContent> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(
        configuration
      ).productSourceHistoryUsingGET1(sourceUid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return list of product sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceListUsingGET1(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs =
        ProductSourceControllerApiFetchParamCreator(configuration).productSourceListUsingGET1(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return list of product sources types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceTypeListUsingGET(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceType> {
      const localVarFetchArgs =
        ProductSourceControllerApiFetchParamCreator(configuration).productSourceTypeListUsingGET(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update account product category mapping
     * @param {Array<AccountCategory>} categoryMapping categoryMapping
     * @param {boolean} [deleteUnused] deleteUnused
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountProductCategoryUsingPOST(
      categoryMapping: Array<AccountCategory>,
      deleteUnused?: boolean,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(
        configuration
      ).updateAccountProductCategoryUsingPOST(categoryMapping, deleteUnused, language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update existing product sources
     * @param {ProductSource} source source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductSourceUsingPOST1(
      source: ProductSource,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(
        configuration
      ).updateProductSourceUsingPOST1(source, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductSourceUsingGET1(
      sourceUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
      const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(
        configuration
      ).validateProductSourceUsingGET1(sourceUid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ProductSourceControllerApi - factory interface
 * @export
 */
export const ProductSourceControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Get account product category mapping
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountProductCategoryListUsingGET(language?: string, options?: any) {
      return ProductSourceControllerApiFp(configuration).accountProductCategoryListUsingGET(language, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Add new product sources
     * @param {ProductSource} source source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductSourceUsingPUT1(source: ProductSource, options?: any) {
      return ProductSourceControllerApiFp(configuration).addProductSourceUsingPUT1(source, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete existing product sources
     * @param {UidRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductSourceUsingDELETE1(request: UidRequest, options?: any) {
      return ProductSourceControllerApiFp(configuration).deleteProductSourceUsingDELETE1(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSourceUsingGET1(sourceUid: string, options?: any) {
      return ProductSourceControllerApiFp(configuration).getProductSourceUsingGET1(sourceUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceHistoryUsingGET1(sourceUid: string, options?: any) {
      return ProductSourceControllerApiFp(configuration).productSourceHistoryUsingGET1(sourceUid, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Return list of product sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceListUsingGET1(options?: any) {
      return ProductSourceControllerApiFp(configuration).productSourceListUsingGET1(options)(fetch, basePath);
    },
    /**
     *
     * @summary Return list of product sources types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSourceTypeListUsingGET(options?: any) {
      return ProductSourceControllerApiFp(configuration).productSourceTypeListUsingGET(options)(fetch, basePath);
    },
    /**
     *
     * @summary Update account product category mapping
     * @param {Array<AccountCategory>} categoryMapping categoryMapping
     * @param {boolean} [deleteUnused] deleteUnused
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountProductCategoryUsingPOST(
      categoryMapping: Array<AccountCategory>,
      deleteUnused?: boolean,
      language?: string,
      options?: any
    ) {
      return ProductSourceControllerApiFp(configuration).updateAccountProductCategoryUsingPOST(
        categoryMapping,
        deleteUnused,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update existing product sources
     * @param {ProductSource} source source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductSourceUsingPOST1(source: ProductSource, options?: any) {
      return ProductSourceControllerApiFp(configuration).updateProductSourceUsingPOST1(source, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductSourceUsingGET1(sourceUid: string, options?: any) {
      return ProductSourceControllerApiFp(configuration).validateProductSourceUsingGET1(sourceUid, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * ProductSourceControllerApi - object-oriented interface
 * @export
 * @class ProductSourceControllerApi
 * @extends {BaseAPI}
 */
export class ProductSourceControllerApi extends BaseAPI {
  /**
   *
   * @summary Get account product category mapping
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public accountProductCategoryListUsingGET(language?: string, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).accountProductCategoryListUsingGET(language, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Add new product sources
   * @param {ProductSource} source source
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public addProductSourceUsingPUT1(source: ProductSource, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).addProductSourceUsingPUT1(source, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete existing product sources
   * @param {UidRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public deleteProductSourceUsingDELETE1(request: UidRequest, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).deleteProductSourceUsingDELETE1(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Return product sources details
   * @param {string} sourceUid sourceUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public getProductSourceUsingGET1(sourceUid: string, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).getProductSourceUsingGET1(sourceUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Return product sources import history (10 max)
   * @param {string} sourceUid sourceUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public productSourceHistoryUsingGET1(sourceUid: string, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).productSourceHistoryUsingGET1(sourceUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Return list of product sources
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public productSourceListUsingGET1(options?: any) {
    return ProductSourceControllerApiFp(this.configuration).productSourceListUsingGET1(options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Return list of product sources types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public productSourceTypeListUsingGET(options?: any) {
    return ProductSourceControllerApiFp(this.configuration).productSourceTypeListUsingGET(options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Update account product category mapping
   * @param {Array<AccountCategory>} categoryMapping categoryMapping
   * @param {boolean} [deleteUnused] deleteUnused
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public updateAccountProductCategoryUsingPOST(
    categoryMapping: Array<AccountCategory>,
    deleteUnused?: boolean,
    language?: string,
    options?: any
  ) {
    return ProductSourceControllerApiFp(this.configuration).updateAccountProductCategoryUsingPOST(
      categoryMapping,
      deleteUnused,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update existing product sources
   * @param {ProductSource} source source
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public updateProductSourceUsingPOST1(source: ProductSource, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).updateProductSourceUsingPOST1(source, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Validate product source
   * @param {string} sourceUid sourceUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSourceControllerApi
   */
  public validateProductSourceUsingGET1(sourceUid: string, options?: any) {
    return ProductSourceControllerApiFp(this.configuration).validateProductSourceUsingGET1(sourceUid, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * PromotionPageControllerApi - fetch parameter creator
 * @export
 */
export const PromotionPageControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get promotion page
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageUsingGET(uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError("uid", "Required parameter uid was null or undefined when calling getPageUsingGET.");
      }
      const localVarPath = `/promotionpage/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PromotionPageControllerApi - functional programming interface
 * @export
 */
export const PromotionPageControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get promotion page
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageUsingGET(
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
      const localVarFetchArgs = PromotionPageControllerApiFetchParamCreator(configuration).getPageUsingGET(
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PromotionPageControllerApi - factory interface
 * @export
 */
export const PromotionPageControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Get promotion page
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageUsingGET(uid: string, language?: string, options?: any) {
      return PromotionPageControllerApiFp(configuration).getPageUsingGET(uid, language, options)(fetch, basePath);
    },
  };
};

/**
 * PromotionPageControllerApi - object-oriented interface
 * @export
 * @class PromotionPageControllerApi
 * @extends {BaseAPI}
 */
export class PromotionPageControllerApi extends BaseAPI {
  /**
   *
   * @summary Get promotion page
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromotionPageControllerApi
   */
  public getPageUsingGET(uid: string, language?: string, options?: any) {
    return PromotionPageControllerApiFp(this.configuration).getPageUsingGET(
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * PublicControllerApi - fetch parameter creator
 * @export
 */
export const PublicControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bannerListUsingGET1(channel: string, count?: number, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'channel' is not null or undefined
      if (channel === null || channel === undefined) {
        throw new RequiredError(
          "channel",
          "Required parameter channel was null or undefined when calling bannerListUsingGET1."
        );
      }
      const localVarPath = `/public/banner`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (channel !== undefined) {
        localVarQueryParameter["channel"] = channel;
      }

      if (count !== undefined) {
        localVarQueryParameter["count"] = count;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processManualClickUsingGET1(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'bannerUid' is not null or undefined
      if (bannerUid === null || bannerUid === undefined) {
        throw new RequiredError(
          "bannerUid",
          "Required parameter bannerUid was null or undefined when calling processManualClickUsingGET1."
        );
      }
      // verify required parameter 'channel' is not null or undefined
      if (channel === null || channel === undefined) {
        throw new RequiredError(
          "channel",
          "Required parameter channel was null or undefined when calling processManualClickUsingGET1."
        );
      }
      const localVarPath = `/public/banner/{bannerUid}/click`.replace(
        `{${"bannerUid"}}`,
        encodeURIComponent(String(bannerUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (accountUid !== undefined) {
        localVarQueryParameter["accountUid"] = accountUid;
      }

      if (channel !== undefined) {
        localVarQueryParameter["channel"] = channel;
      }

      if (refId !== undefined) {
        localVarQueryParameter["refId"] = refId;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingGET1(uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling productSearchDetailsUsingGET1."
        );
      }
      const localVarPath = `/public/product/search/details/{uid}`.replace(
        `{${"uid"}}`,
        encodeURIComponent(String(uid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get published product details
     * @param {ProductUidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingPOST1(request: ProductUidRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productSearchDetailsUsingPOST1."
        );
      }
      const localVarPath = `/public/product/search/details`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductUidRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchUsingPOST1(request: ProductSearchListRequest, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling productSearchUsingPOST1."
        );
      }
      const localVarPath = `/public/product/search`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"ProductSearchListRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productWidgetListUsingGET1(listType: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'listType' is not null or undefined
      if (listType === null || listType === undefined) {
        throw new RequiredError(
          "listType",
          "Required parameter listType was null or undefined when calling productWidgetListUsingGET1."
        );
      }
      const localVarPath = `/public/product/list/{listType}`.replace(
        `{${"listType"}}`,
        encodeURIComponent(String(listType))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get store data
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {string} [productCategory] productCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeInfoUsingGET(storeUid: string, language?: string, productCategory?: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling storeInfoUsingGET."
        );
      }
      const localVarPath = `/public/store/info`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (productCategory !== undefined) {
        localVarQueryParameter["productCategory"] = productCategory;
      }

      if (storeUid !== undefined) {
        localVarQueryParameter["storeUid"] = storeUid;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary TOP store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeTopUsingGET(country?: string, language?: string, rowLimit?: number, options: any = {}): FetchArgs {
      const localVarPath = `/public/store/top`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (rowLimit !== undefined) {
        localVarQueryParameter["rowLimit"] = rowLimit;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Random store list with products
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options: any = {}): FetchArgs {
      const localVarPath = `/public/store/widget`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (rowLimit !== undefined) {
        localVarQueryParameter["rowLimit"] = rowLimit;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bannerListUsingGET1(
      channel: string,
      count?: number,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).bannerListUsingGET1(
        channel,
        count,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processManualClickUsingGET1(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).processManualClickUsingGET1(
        bannerUid,
        channel,
        accountUid,
        refId,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingGET1(
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productSearchDetailsUsingGET1(
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get published product details
     * @param {ProductUidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingPOST1(
      request: ProductUidRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productSearchDetailsUsingPOST1(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchUsingPOST1(
      request: ProductSearchListRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productSearchUsingPOST1(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productWidgetListUsingGET1(
      listType: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productWidgetListUsingGET1(
        listType,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get store data
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {string} [productCategory] productCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeInfoUsingGET(
      storeUid: string,
      language?: string,
      productCategory?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).storeInfoUsingGET(
        storeUid,
        language,
        productCategory,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary TOP store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeTopUsingGET(
      country?: string,
      language?: string,
      rowLimit?: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).storeTopUsingGET(
        country,
        language,
        rowLimit,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Random store list with products
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeWidgetUsingGET(
      country?: string,
      language?: string,
      rowLimit?: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).storeWidgetUsingGET(
        country,
        language,
        rowLimit,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bannerListUsingGET1(channel: string, count?: number, language?: string, options?: any) {
      return PublicControllerApiFp(configuration).bannerListUsingGET1(
        channel,
        count,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processManualClickUsingGET1(
      bannerUid: string,
      channel: string,
      accountUid?: string,
      refId?: string,
      options?: any
    ) {
      return PublicControllerApiFp(configuration).processManualClickUsingGET1(
        bannerUid,
        channel,
        accountUid,
        refId,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingGET1(uid: string, language?: string, options?: any) {
      return PublicControllerApiFp(configuration).productSearchDetailsUsingGET1(
        uid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get published product details
     * @param {ProductUidRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchDetailsUsingPOST1(request: ProductUidRequest, language?: string, options?: any) {
      return PublicControllerApiFp(configuration).productSearchDetailsUsingPOST1(
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearchUsingPOST1(request: ProductSearchListRequest, language?: string, options?: any) {
      return PublicControllerApiFp(configuration).productSearchUsingPOST1(request, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productWidgetListUsingGET1(listType: string, language?: string, options?: any) {
      return PublicControllerApiFp(configuration).productWidgetListUsingGET1(
        listType,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get store data
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {string} [productCategory] productCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeInfoUsingGET(storeUid: string, language?: string, productCategory?: string, options?: any) {
      return PublicControllerApiFp(configuration).storeInfoUsingGET(
        storeUid,
        language,
        productCategory,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary TOP store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeTopUsingGET(country?: string, language?: string, rowLimit?: number, options?: any) {
      return PublicControllerApiFp(configuration).storeTopUsingGET(
        country,
        language,
        rowLimit,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Random store list with products
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options?: any) {
      return PublicControllerApiFp(configuration).storeWidgetUsingGET(
        country,
        language,
        rowLimit,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
  /**
   *
   * @summary Return N marketing banners in random order
   * @param {string} channel channel
   * @param {number} [count] count
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public bannerListUsingGET1(channel: string, count?: number, language?: string, options?: any) {
    return PublicControllerApiFp(this.configuration).bannerListUsingGET1(
      channel,
      count,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Manually register banner click to get banner click statistics
   * @param {string} bannerUid bannerUid
   * @param {string} channel channel
   * @param {string} [accountUid] accountUid
   * @param {string} [refId] refId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public processManualClickUsingGET1(
    bannerUid: string,
    channel: string,
    accountUid?: string,
    refId?: string,
    options?: any
  ) {
    return PublicControllerApiFp(this.configuration).processManualClickUsingGET1(
      bannerUid,
      channel,
      accountUid,
      refId,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get product details
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public productSearchDetailsUsingGET1(uid: string, language?: string, options?: any) {
    return PublicControllerApiFp(this.configuration).productSearchDetailsUsingGET1(
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get published product details
   * @param {ProductUidRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public productSearchDetailsUsingPOST1(request: ProductUidRequest, language?: string, options?: any) {
    return PublicControllerApiFp(this.configuration).productSearchDetailsUsingPOST1(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Search published products using different criteria
   * @param {ProductSearchListRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public productSearchUsingPOST1(request: ProductSearchListRequest, language?: string, options?: any) {
    return PublicControllerApiFp(this.configuration).productSearchUsingPOST1(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
   * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public productWidgetListUsingGET1(listType: string, language?: string, options?: any) {
    return PublicControllerApiFp(this.configuration).productWidgetListUsingGET1(
      listType,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get store data
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {string} [productCategory] productCategory
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public storeInfoUsingGET(storeUid: string, language?: string, productCategory?: string, options?: any) {
    return PublicControllerApiFp(this.configuration).storeInfoUsingGET(
      storeUid,
      language,
      productCategory,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary TOP store list
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {number} [rowLimit] rowLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public storeTopUsingGET(country?: string, language?: string, rowLimit?: number, options?: any) {
    return PublicControllerApiFp(this.configuration).storeTopUsingGET(
      country,
      language,
      rowLimit,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Random store list with products
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {number} [rowLimit] rowLimit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicControllerApi
   */
  public storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options?: any) {
    return PublicControllerApiFp(this.configuration).storeWidgetUsingGET(
      country,
      language,
      rowLimit,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * SaleControllerApi - fetch parameter creator
 * @export
 */
export const SaleControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new sale product productUid or categoryCode
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSaleProductsUsingPUT(saleProduct: SaleProduct, uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'saleProduct' is not null or undefined
      if (saleProduct === null || saleProduct === undefined) {
        throw new RequiredError(
          "saleProduct",
          "Required parameter saleProduct was null or undefined when calling addSaleProductsUsingPUT."
        );
      }
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling addSaleProductsUsingPUT."
        );
      }
      const localVarPath = `/sale/{uid}/products`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"SaleProduct" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(saleProduct || {}) : saleProduct || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new sale
     * @param {Sale} sale sale
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSaleUsingPUT(sale: Sale, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'sale' is not null or undefined
      if (sale === null || sale === undefined) {
        throw new RequiredError("sale", "Required parameter sale was null or undefined when calling addSaleUsingPUT.");
      }
      const localVarPath = `/sale`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Sale" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(sale || {}) : sale || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete sale product
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSaleProductUsingDELETE(
      saleProduct: SaleProduct,
      uid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'saleProduct' is not null or undefined
      if (saleProduct === null || saleProduct === undefined) {
        throw new RequiredError(
          "saleProduct",
          "Required parameter saleProduct was null or undefined when calling deleteSaleProductUsingDELETE."
        );
      }
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling deleteSaleProductUsingDELETE."
        );
      }
      const localVarPath = `/sale/{uid}/products`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"SaleProduct" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(saleProduct || {}) : saleProduct || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete sale
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSaleUsingDELETE(uid: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling deleteSaleUsingDELETE."
        );
      }
      const localVarPath = `/sale/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit sale product
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSaleProductsUsingPOST(saleProduct: SaleProduct, uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'saleProduct' is not null or undefined
      if (saleProduct === null || saleProduct === undefined) {
        throw new RequiredError(
          "saleProduct",
          "Required parameter saleProduct was null or undefined when calling editSaleProductsUsingPOST."
        );
      }
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling editSaleProductsUsingPOST."
        );
      }
      const localVarPath = `/sale/{uid}/products`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"SaleProduct" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(saleProduct || {}) : saleProduct || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit sale
     * @param {Sale} sale sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSaleUsingPOST(sale: Sale, uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'sale' is not null or undefined
      if (sale === null || sale === undefined) {
        throw new RequiredError(
          "sale",
          "Required parameter sale was null or undefined when calling editSaleUsingPOST."
        );
      }
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError("uid", "Required parameter uid was null or undefined when calling editSaleUsingPOST.");
      }
      const localVarPath = `/sale/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Sale" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(sale || {}) : sale || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaleUsingGET(uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError("uid", "Required parameter uid was null or undefined when calling getSaleUsingGET.");
      }
      const localVarPath = `/sale/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get sale list
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalesUsingGET(language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/sale`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SaleControllerApi - functional programming interface
 * @export
 */
export const SaleControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new sale product productUid or categoryCode
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSaleProductsUsingPUT(
      saleProduct: SaleProduct,
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).addSaleProductsUsingPUT(
        saleProduct,
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new sale
     * @param {Sale} sale sale
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSaleUsingPUT(
      sale: Sale,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).addSaleUsingPUT(
        sale,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete sale product
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSaleProductUsingDELETE(
      saleProduct: SaleProduct,
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).deleteSaleProductUsingDELETE(
        saleProduct,
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete sale
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSaleUsingDELETE(
      uid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).deleteSaleUsingDELETE(uid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Edit sale product
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSaleProductsUsingPOST(
      saleProduct: SaleProduct,
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).editSaleProductsUsingPOST(
        saleProduct,
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Edit sale
     * @param {Sale} sale sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSaleUsingPOST(
      sale: Sale,
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).editSaleUsingPOST(
        sale,
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaleUsingGET(
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).getSaleUsingGET(
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get sale list
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalesUsingGET(
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).getSalesUsingGET(language, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * SaleControllerApi - factory interface
 * @export
 */
export const SaleControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Add new sale product productUid or categoryCode
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSaleProductsUsingPUT(saleProduct: SaleProduct, uid: string, language?: string, options?: any) {
      return SaleControllerApiFp(configuration).addSaleProductsUsingPUT(
        saleProduct,
        uid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new sale
     * @param {Sale} sale sale
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSaleUsingPUT(sale: Sale, language?: string, options?: any) {
      return SaleControllerApiFp(configuration).addSaleUsingPUT(sale, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete sale product
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSaleProductUsingDELETE(saleProduct: SaleProduct, uid: string, language?: string, options?: any) {
      return SaleControllerApiFp(configuration).deleteSaleProductUsingDELETE(
        saleProduct,
        uid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete sale
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSaleUsingDELETE(uid: string, options?: any) {
      return SaleControllerApiFp(configuration).deleteSaleUsingDELETE(uid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Edit sale product
     * @param {SaleProduct} saleProduct saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSaleProductsUsingPOST(saleProduct: SaleProduct, uid: string, language?: string, options?: any) {
      return SaleControllerApiFp(configuration).editSaleProductsUsingPOST(
        saleProduct,
        uid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Edit sale
     * @param {Sale} sale sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSaleUsingPOST(sale: Sale, uid: string, language?: string, options?: any) {
      return SaleControllerApiFp(configuration).editSaleUsingPOST(sale, uid, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaleUsingGET(uid: string, language?: string, options?: any) {
      return SaleControllerApiFp(configuration).getSaleUsingGET(uid, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get sale list
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalesUsingGET(language?: string, options?: any) {
      return SaleControllerApiFp(configuration).getSalesUsingGET(language, options)(fetch, basePath);
    },
  };
};

/**
 * SaleControllerApi - object-oriented interface
 * @export
 * @class SaleControllerApi
 * @extends {BaseAPI}
 */
export class SaleControllerApi extends BaseAPI {
  /**
   *
   * @summary Add new sale product productUid or categoryCode
   * @param {SaleProduct} saleProduct saleProduct
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public addSaleProductsUsingPUT(saleProduct: SaleProduct, uid: string, language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).addSaleProductsUsingPUT(
      saleProduct,
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new sale
   * @param {Sale} sale sale
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public addSaleUsingPUT(sale: Sale, language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).addSaleUsingPUT(sale, language, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete sale product
   * @param {SaleProduct} saleProduct saleProduct
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public deleteSaleProductUsingDELETE(saleProduct: SaleProduct, uid: string, language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).deleteSaleProductUsingDELETE(
      saleProduct,
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete sale
   * @param {string} uid uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public deleteSaleUsingDELETE(uid: string, options?: any) {
    return SaleControllerApiFp(this.configuration).deleteSaleUsingDELETE(uid, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Edit sale product
   * @param {SaleProduct} saleProduct saleProduct
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public editSaleProductsUsingPOST(saleProduct: SaleProduct, uid: string, language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).editSaleProductsUsingPOST(
      saleProduct,
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Edit sale
   * @param {Sale} sale sale
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public editSaleUsingPOST(sale: Sale, uid: string, language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).editSaleUsingPOST(
      sale,
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get sale
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public getSaleUsingGET(uid: string, language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).getSaleUsingGET(uid, language, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get sale list
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SaleControllerApi
   */
  public getSalesUsingGET(language?: string, options?: any) {
    return SaleControllerApiFp(this.configuration).getSalesUsingGET(language, options)(this.fetch, this.basePath);
  }
}

/**
 * StatisticsControllerApi - fetch parameter creator
 * @export
 */
export const StatisticsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Product statistics
     * @param {string} productUid productUid
     * @param {GetStatisticsRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStatisticsUsingPOST(
      productUid: string,
      request: GetStatisticsRequest,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling getProductStatisticsUsingPOST."
        );
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getProductStatisticsUsingPOST."
        );
      }
      const localVarPath = `/statistics/product/{productUid}`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetStatisticsRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Sale statistics
     * @param {GetStatisticsRequest} request request
     * @param {string} saleUid saleUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaleStatisticsUsingPOST(
      request: GetStatisticsRequest,
      saleUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getSaleStatisticsUsingPOST."
        );
      }
      // verify required parameter 'saleUid' is not null or undefined
      if (saleUid === null || saleUid === undefined) {
        throw new RequiredError(
          "saleUid",
          "Required parameter saleUid was null or undefined when calling getSaleStatisticsUsingPOST."
        );
      }
      const localVarPath = `/statistics/sale/{saleUid}`.replace(`{${"saleUid"}}`, encodeURIComponent(String(saleUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetStatisticsRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Store statistics
     * @param {GetStatisticsRequest} request request
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreStatisticsUsingPOST(
      request: GetStatisticsRequest,
      storeUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getStoreStatisticsUsingPOST."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling getStoreStatisticsUsingPOST."
        );
      }
      const localVarPath = `/statistics/store/{storeUid}`.replace(
        `{${"storeUid"}}`,
        encodeURIComponent(String(storeUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetStatisticsRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatisticsControllerApi - functional programming interface
 * @export
 */
export const StatisticsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Product statistics
     * @param {string} productUid productUid
     * @param {GetStatisticsRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStatisticsUsingPOST(
      productUid: string,
      request: GetStatisticsRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = StatisticsControllerApiFetchParamCreator(configuration).getProductStatisticsUsingPOST(
        productUid,
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get Sale statistics
     * @param {GetStatisticsRequest} request request
     * @param {string} saleUid saleUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaleStatisticsUsingPOST(
      request: GetStatisticsRequest,
      saleUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = StatisticsControllerApiFetchParamCreator(configuration).getSaleStatisticsUsingPOST(
        request,
        saleUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get Store statistics
     * @param {GetStatisticsRequest} request request
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreStatisticsUsingPOST(
      request: GetStatisticsRequest,
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = StatisticsControllerApiFetchParamCreator(configuration).getStoreStatisticsUsingPOST(
        request,
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * StatisticsControllerApi - factory interface
 * @export
 */
export const StatisticsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Get Product statistics
     * @param {string} productUid productUid
     * @param {GetStatisticsRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductStatisticsUsingPOST(productUid: string, request: GetStatisticsRequest, language?: string, options?: any) {
      return StatisticsControllerApiFp(configuration).getProductStatisticsUsingPOST(
        productUid,
        request,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get Sale statistics
     * @param {GetStatisticsRequest} request request
     * @param {string} saleUid saleUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaleStatisticsUsingPOST(request: GetStatisticsRequest, saleUid: string, language?: string, options?: any) {
      return StatisticsControllerApiFp(configuration).getSaleStatisticsUsingPOST(
        request,
        saleUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get Store statistics
     * @param {GetStatisticsRequest} request request
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreStatisticsUsingPOST(request: GetStatisticsRequest, storeUid: string, language?: string, options?: any) {
      return StatisticsControllerApiFp(configuration).getStoreStatisticsUsingPOST(
        request,
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * StatisticsControllerApi - object-oriented interface
 * @export
 * @class StatisticsControllerApi
 * @extends {BaseAPI}
 */
export class StatisticsControllerApi extends BaseAPI {
  /**
   *
   * @summary Get Product statistics
   * @param {string} productUid productUid
   * @param {GetStatisticsRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsControllerApi
   */
  public getProductStatisticsUsingPOST(
    productUid: string,
    request: GetStatisticsRequest,
    language?: string,
    options?: any
  ) {
    return StatisticsControllerApiFp(this.configuration).getProductStatisticsUsingPOST(
      productUid,
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get Sale statistics
   * @param {GetStatisticsRequest} request request
   * @param {string} saleUid saleUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsControllerApi
   */
  public getSaleStatisticsUsingPOST(request: GetStatisticsRequest, saleUid: string, language?: string, options?: any) {
    return StatisticsControllerApiFp(this.configuration).getSaleStatisticsUsingPOST(
      request,
      saleUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get Store statistics
   * @param {GetStatisticsRequest} request request
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsControllerApi
   */
  public getStoreStatisticsUsingPOST(
    request: GetStatisticsRequest,
    storeUid: string,
    language?: string,
    options?: any
  ) {
    return StatisticsControllerApiFp(this.configuration).getStoreStatisticsUsingPOST(
      request,
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * StoreControllerApi - fetch parameter creator
 * @export
 */
export const StoreControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreListUsingGET(country?: string, language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/store`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account store by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling accountStoreUsingGET."
        );
      }
      const localVarPath = `/store/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add product to account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageProductUsingPUT(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'product' is not null or undefined
      if (product === null || product === undefined) {
        throw new RequiredError(
          "product",
          "Required parameter product was null or undefined when calling addStoreHomepageProductUsingPUT."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageProductUsingPUT."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling addStoreHomepageProductUsingPUT."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/product`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageProduct" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(product || {}) : product || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add product list to account store homepage
     * @param {StoreHomepageProductsRequest} request request
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageProductsUsingPUT(
      request: StoreHomepageProductsRequest,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling addStoreHomepageProductsUsingPUT."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageProductsUsingPUT."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling addStoreHomepageProductsUsingPUT."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/products`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageProductsRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageUsingPUT(storeUid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling addStoreHomepageUsingPUT."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage`.replace(
        `{${"storeUid"}}`,
        encodeURIComponent(String(storeUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add widget to account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageWidgetUsingPUT(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageWidgetUsingPUT."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling addStoreHomepageWidgetUsingPUT."
        );
      }
      // verify required parameter 'widget' is not null or undefined
      if (widget === null || widget === undefined) {
        throw new RequiredError(
          "widget",
          "Required parameter widget was null or undefined when calling addStoreHomepageWidgetUsingPUT."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widget`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageWidget" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(widget || {}) : widget || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add widget to account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {Array<StoreHomepageWidget>} widgets widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageWidgetsUsingPUT(
      storeHomepageUid: string,
      storeUid: string,
      widgets: Array<StoreHomepageWidget>,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageWidgetsUsingPUT."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling addStoreHomepageWidgetsUsingPUT."
        );
      }
      // verify required parameter 'widgets' is not null or undefined
      if (widgets === null || widgets === undefined) {
        throw new RequiredError(
          "widgets",
          "Required parameter widgets was null or undefined when calling addStoreHomepageWidgetsUsingPUT."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widgets`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;StoreHomepageWidget&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(widgets || {}) : widgets || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new account store
     * @param {Store} store store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreUsingPUT(store: Store, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'store' is not null or undefined
      if (store === null || store === undefined) {
        throw new RequiredError(
          "store",
          "Required parameter store was null or undefined when calling addStoreUsingPUT."
        );
      }
      const localVarPath = `/store`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Store" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(store || {}) : store || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check account name or code availability
     * @param {StoreCheck} check check
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountStoreUsingPOST(check: StoreCheck, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'check' is not null or undefined
      if (check === null || check === undefined) {
        throw new RequiredError(
          "check",
          "Required parameter check was null or undefined when calling checkAccountStoreUsingPOST."
        );
      }
      const localVarPath = `/store/check`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreCheck" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(check || {}) : check || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStoreHomepageBannerUsingPUT(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'banner' is not null or undefined
      if (banner === null || banner === undefined) {
        throw new RequiredError(
          "banner",
          "Required parameter banner was null or undefined when calling createStoreHomepageBannerUsingPUT."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling createStoreHomepageBannerUsingPUT."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling createStoreHomepageBannerUsingPUT."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/banner`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageBanner" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(banner || {}) : banner || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete account store homepage
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllStoreHomepagesUsingDELETE(storeUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteAllStoreHomepagesUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage`.replace(
        `{${"storeUid"}}`,
        encodeURIComponent(String(storeUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageBannerUsingDELETE(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'banner' is not null or undefined
      if (banner === null || banner === undefined) {
        throw new RequiredError(
          "banner",
          "Required parameter banner was null or undefined when calling deleteStoreHomepageBannerUsingDELETE."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageBannerUsingDELETE."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteStoreHomepageBannerUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/banner`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageBanner" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(banner || {}) : banner || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete product in account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageProductUsingDELETE(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'product' is not null or undefined
      if (product === null || product === undefined) {
        throw new RequiredError(
          "product",
          "Required parameter product was null or undefined when calling deleteStoreHomepageProductUsingDELETE."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageProductUsingDELETE."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteStoreHomepageProductUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/product`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageProduct" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(product || {}) : product || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete products in account store homepage
     * @param {Array<StoreHomepageProduct>} products products
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageProductsUsingDELETE(
      products: Array<StoreHomepageProduct>,
      storeHomepageUid: string,
      storeUid: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'products' is not null or undefined
      if (products === null || products === undefined) {
        throw new RequiredError(
          "products",
          "Required parameter products was null or undefined when calling deleteStoreHomepageProductsUsingDELETE."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageProductsUsingDELETE."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteStoreHomepageProductsUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/products`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;StoreHomepageProduct&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(products || {}) : products || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageUsingDELETE."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteStoreHomepageUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete widget in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageWidgetUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE."
        );
      }
      // verify required parameter 'widget' is not null or undefined
      if (widget === null || widget === undefined) {
        throw new RequiredError(
          "widget",
          "Required parameter widget was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widget`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageWidget" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(widget || {}) : widget || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete widgets in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {Array<StoreHomepageWidget>} widgets widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageWidgetsUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      widgets: Array<StoreHomepageWidget>,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE."
        );
      }
      // verify required parameter 'widgets' is not null or undefined
      if (widgets === null || widgets === undefined) {
        throw new RequiredError(
          "widgets",
          "Required parameter widgets was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widgets`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;StoreHomepageWidget&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(widgets || {}) : widgets || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling getStoreHomepageByUidUsingGET."
        );
      }
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling getStoreHomepageByUidUsingGET."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{uid}`
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)))
        .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreHomepageUsingGET(storeUid: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling getStoreHomepageUsingGET."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage`.replace(
        `{${"storeUid"}}`,
        encodeURIComponent(String(storeUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Publish account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishStoreHomepageUsingPOST(storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling publishStoreHomepageUsingPOST."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling publishStoreHomepageUsingPOST."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/publish`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageBannerUsingPOST(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'banner' is not null or undefined
      if (banner === null || banner === undefined) {
        throw new RequiredError(
          "banner",
          "Required parameter banner was null or undefined when calling updateStoreHomepageBannerUsingPOST."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageBannerUsingPOST."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling updateStoreHomepageBannerUsingPOST."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/banner`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageBanner" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(banner || {}) : banner || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit product in account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageProductUsingPOST(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'product' is not null or undefined
      if (product === null || product === undefined) {
        throw new RequiredError(
          "product",
          "Required parameter product was null or undefined when calling updateStoreHomepageProductUsingPOST."
        );
      }
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageProductUsingPOST."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling updateStoreHomepageProductUsingPOST."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/product`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageProduct" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(product || {}) : product || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit widget in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageWidgetUsingPOST(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'storeHomepageUid' is not null or undefined
      if (storeHomepageUid === null || storeHomepageUid === undefined) {
        throw new RequiredError(
          "storeHomepageUid",
          "Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageWidgetUsingPOST."
        );
      }
      // verify required parameter 'storeUid' is not null or undefined
      if (storeUid === null || storeUid === undefined) {
        throw new RequiredError(
          "storeUid",
          "Required parameter storeUid was null or undefined when calling updateStoreHomepageWidgetUsingPOST."
        );
      }
      // verify required parameter 'widget' is not null or undefined
      if (widget === null || widget === undefined) {
        throw new RequiredError(
          "widget",
          "Required parameter widget was null or undefined when calling updateStoreHomepageWidgetUsingPOST."
        );
      }
      const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widget`
        .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
        .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"StoreHomepageWidget" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(widget || {}) : widget || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update existing account store
     * @param {Store} store store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreUsingPOST(store: Store, country?: string, language?: string, options: any = {}): FetchArgs {
      // verify required parameter 'store' is not null or undefined
      if (store === null || store === undefined) {
        throw new RequiredError(
          "store",
          "Required parameter store was null or undefined when calling updateStoreUsingPOST."
        );
      }
      const localVarPath = `/store`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (country !== undefined) {
        localVarQueryParameter["country"] = country;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Store" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(store || {}) : store || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StoreControllerApi - functional programming interface
 * @export
 */
export const StoreControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreListUsingGET(
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).accountStoreListUsingGET(
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get account store by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreUsingGET(
      uid: string,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).accountStoreUsingGET(
        uid,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add product to account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageProductUsingPUT(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).addStoreHomepageProductUsingPUT(
        product,
        storeHomepageUid,
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add product list to account store homepage
     * @param {StoreHomepageProductsRequest} request request
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageProductsUsingPUT(
      request: StoreHomepageProductsRequest,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).addStoreHomepageProductsUsingPUT(
        request,
        storeHomepageUid,
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageUsingPUT(
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).addStoreHomepageUsingPUT(
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add widget to account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageWidgetUsingPUT(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).addStoreHomepageWidgetUsingPUT(
        storeHomepageUid,
        storeUid,
        widget,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add widget to account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {Array<StoreHomepageWidget>} widgets widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageWidgetsUsingPUT(
      storeHomepageUid: string,
      storeUid: string,
      widgets: Array<StoreHomepageWidget>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).addStoreHomepageWidgetsUsingPUT(
        storeHomepageUid,
        storeUid,
        widgets,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new account store
     * @param {Store} store store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreUsingPUT(
      store: Store,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).addStoreUsingPUT(
        store,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Check account name or code availability
     * @param {StoreCheck} check check
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountStoreUsingPOST(
      check: StoreCheck,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreCheck> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).checkAccountStoreUsingPOST(
        check,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Create banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStoreHomepageBannerUsingPUT(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).createStoreHomepageBannerUsingPUT(
        banner,
        storeHomepageUid,
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete account store homepage
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllStoreHomepagesUsingDELETE(
      storeUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).deleteAllStoreHomepagesUsingDELETE(
        storeUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageBannerUsingDELETE(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageBannerUsingDELETE(
        banner,
        storeHomepageUid,
        storeUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete product in account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageProductUsingDELETE(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(
        configuration
      ).deleteStoreHomepageProductUsingDELETE(product, storeHomepageUid, storeUid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete products in account store homepage
     * @param {Array<StoreHomepageProduct>} products products
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageProductsUsingDELETE(
      products: Array<StoreHomepageProduct>,
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(
        configuration
      ).deleteStoreHomepageProductsUsingDELETE(products, storeHomepageUid, storeUid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageUsingDELETE(
        storeHomepageUid,
        storeUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete widget in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageWidgetUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageWidgetUsingDELETE(
        storeHomepageUid,
        storeUid,
        widget,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete widgets in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {Array<StoreHomepageWidget>} widgets widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageWidgetsUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      widgets: Array<StoreHomepageWidget>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(
        configuration
      ).deleteStoreHomepageWidgetsUsingDELETE(storeHomepageUid, storeUid, widgets, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreHomepageByUidUsingGET(
      storeUid: string,
      uid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).getStoreHomepageByUidUsingGET(
        storeUid,
        uid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreHomepageUsingGET(
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).getStoreHomepageUsingGET(
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Publish account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishStoreHomepageUsingPOST(
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).publishStoreHomepageUsingPOST(
        storeHomepageUid,
        storeUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageBannerUsingPOST(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).updateStoreHomepageBannerUsingPOST(
        banner,
        storeHomepageUid,
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Edit product in account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageProductUsingPOST(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).updateStoreHomepageProductUsingPOST(
        product,
        storeHomepageUid,
        storeUid,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Edit widget in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageWidgetUsingPOST(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).updateStoreHomepageWidgetUsingPOST(
        storeHomepageUid,
        storeUid,
        widget,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update existing account store
     * @param {Store} store store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreUsingPOST(
      store: Store,
      country?: string,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
      const localVarFetchArgs = StoreControllerApiFetchParamCreator(configuration).updateStoreUsingPOST(
        store,
        country,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * StoreControllerApi - factory interface
 * @export
 */
export const StoreControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Get account store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreListUsingGET(country?: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).accountStoreListUsingGET(country, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get account store by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountStoreUsingGET(uid: string, country?: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).accountStoreUsingGET(uid, country, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Add product to account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageProductUsingPUT(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).addStoreHomepageProductUsingPUT(
        product,
        storeHomepageUid,
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add product list to account store homepage
     * @param {StoreHomepageProductsRequest} request request
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageProductsUsingPUT(
      request: StoreHomepageProductsRequest,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).addStoreHomepageProductsUsingPUT(
        request,
        storeHomepageUid,
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageUsingPUT(storeUid: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).addStoreHomepageUsingPUT(storeUid, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Add widget to account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageWidgetUsingPUT(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).addStoreHomepageWidgetUsingPUT(
        storeHomepageUid,
        storeUid,
        widget,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add widget to account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {Array<StoreHomepageWidget>} widgets widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreHomepageWidgetsUsingPUT(
      storeHomepageUid: string,
      storeUid: string,
      widgets: Array<StoreHomepageWidget>,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).addStoreHomepageWidgetsUsingPUT(
        storeHomepageUid,
        storeUid,
        widgets,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new account store
     * @param {Store} store store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addStoreUsingPUT(store: Store, country?: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).addStoreUsingPUT(store, country, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Check account name or code availability
     * @param {StoreCheck} check check
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountStoreUsingPOST(check: StoreCheck, country?: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).checkAccountStoreUsingPOST(
        check,
        country,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Create banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStoreHomepageBannerUsingPUT(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).createStoreHomepageBannerUsingPUT(
        banner,
        storeHomepageUid,
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete account store homepage
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllStoreHomepagesUsingDELETE(storeUid: string, options?: any) {
      return StoreControllerApiFp(configuration).deleteAllStoreHomepagesUsingDELETE(storeUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageBannerUsingDELETE(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).deleteStoreHomepageBannerUsingDELETE(
        banner,
        storeHomepageUid,
        storeUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete product in account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageProductUsingDELETE(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).deleteStoreHomepageProductUsingDELETE(
        product,
        storeHomepageUid,
        storeUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete products in account store homepage
     * @param {Array<StoreHomepageProduct>} products products
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageProductsUsingDELETE(
      products: Array<StoreHomepageProduct>,
      storeHomepageUid: string,
      storeUid: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).deleteStoreHomepageProductsUsingDELETE(
        products,
        storeHomepageUid,
        storeUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options?: any) {
      return StoreControllerApiFp(configuration).deleteStoreHomepageUsingDELETE(
        storeHomepageUid,
        storeUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete widget in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageWidgetUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).deleteStoreHomepageWidgetUsingDELETE(
        storeHomepageUid,
        storeUid,
        widget,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete widgets in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {Array<StoreHomepageWidget>} widgets widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreHomepageWidgetsUsingDELETE(
      storeHomepageUid: string,
      storeUid: string,
      widgets: Array<StoreHomepageWidget>,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).deleteStoreHomepageWidgetsUsingDELETE(
        storeHomepageUid,
        storeUid,
        widgets,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).getStoreHomepageByUidUsingGET(
        storeUid,
        uid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreHomepageUsingGET(storeUid: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).getStoreHomepageUsingGET(storeUid, language, options)(fetch, basePath);
    },
    /**
     *
     * @summary Publish account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishStoreHomepageUsingPOST(storeHomepageUid: string, storeUid: string, options?: any) {
      return StoreControllerApiFp(configuration).publishStoreHomepageUsingPOST(
        storeHomepageUid,
        storeUid,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update banner for account store homepage
     * @param {StoreHomepageBanner} banner banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageBannerUsingPOST(
      banner: StoreHomepageBanner,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).updateStoreHomepageBannerUsingPOST(
        banner,
        storeHomepageUid,
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Edit product in account store homepage
     * @param {StoreHomepageProduct} product product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageProductUsingPOST(
      product: StoreHomepageProduct,
      storeHomepageUid: string,
      storeUid: string,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).updateStoreHomepageProductUsingPOST(
        product,
        storeHomepageUid,
        storeUid,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Edit widget in account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {StoreHomepageWidget} widget widget
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreHomepageWidgetUsingPOST(
      storeHomepageUid: string,
      storeUid: string,
      widget: StoreHomepageWidget,
      language?: string,
      options?: any
    ) {
      return StoreControllerApiFp(configuration).updateStoreHomepageWidgetUsingPOST(
        storeHomepageUid,
        storeUid,
        widget,
        language,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update existing account store
     * @param {Store} store store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreUsingPOST(store: Store, country?: string, language?: string, options?: any) {
      return StoreControllerApiFp(configuration).updateStoreUsingPOST(
        store,
        country,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * StoreControllerApi - object-oriented interface
 * @export
 * @class StoreControllerApi
 * @extends {BaseAPI}
 */
export class StoreControllerApi extends BaseAPI {
  /**
   *
   * @summary Get account store list
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public accountStoreListUsingGET(country?: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).accountStoreListUsingGET(
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get account store by uid
   * @param {string} uid uid
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public accountStoreUsingGET(uid: string, country?: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).accountStoreUsingGET(
      uid,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add product to account store homepage
   * @param {StoreHomepageProduct} product product
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public addStoreHomepageProductUsingPUT(
    product: StoreHomepageProduct,
    storeHomepageUid: string,
    storeUid: string,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).addStoreHomepageProductUsingPUT(
      product,
      storeHomepageUid,
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add product list to account store homepage
   * @param {StoreHomepageProductsRequest} request request
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public addStoreHomepageProductsUsingPUT(
    request: StoreHomepageProductsRequest,
    storeHomepageUid: string,
    storeUid: string,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).addStoreHomepageProductsUsingPUT(
      request,
      storeHomepageUid,
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new account store homepage
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public addStoreHomepageUsingPUT(storeUid: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).addStoreHomepageUsingPUT(
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add widget to account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {StoreHomepageWidget} widget widget
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public addStoreHomepageWidgetUsingPUT(
    storeHomepageUid: string,
    storeUid: string,
    widget: StoreHomepageWidget,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).addStoreHomepageWidgetUsingPUT(
      storeHomepageUid,
      storeUid,
      widget,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add widget to account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {Array<StoreHomepageWidget>} widgets widgets
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public addStoreHomepageWidgetsUsingPUT(
    storeHomepageUid: string,
    storeUid: string,
    widgets: Array<StoreHomepageWidget>,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).addStoreHomepageWidgetsUsingPUT(
      storeHomepageUid,
      storeUid,
      widgets,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new account store
   * @param {Store} store store
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public addStoreUsingPUT(store: Store, country?: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).addStoreUsingPUT(
      store,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Check account name or code availability
   * @param {StoreCheck} check check
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public checkAccountStoreUsingPOST(check: StoreCheck, country?: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).checkAccountStoreUsingPOST(
      check,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Create banner for account store homepage
   * @param {StoreHomepageBanner} banner banner
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public createStoreHomepageBannerUsingPUT(
    banner: StoreHomepageBanner,
    storeHomepageUid: string,
    storeUid: string,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).createStoreHomepageBannerUsingPUT(
      banner,
      storeHomepageUid,
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete account store homepage
   * @param {string} storeUid storeUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteAllStoreHomepagesUsingDELETE(storeUid: string, options?: any) {
    return StoreControllerApiFp(this.configuration).deleteAllStoreHomepagesUsingDELETE(storeUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete banner for account store homepage
   * @param {StoreHomepageBanner} banner banner
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteStoreHomepageBannerUsingDELETE(
    banner: StoreHomepageBanner,
    storeHomepageUid: string,
    storeUid: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).deleteStoreHomepageBannerUsingDELETE(
      banner,
      storeHomepageUid,
      storeUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete product in account store homepage
   * @param {StoreHomepageProduct} product product
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteStoreHomepageProductUsingDELETE(
    product: StoreHomepageProduct,
    storeHomepageUid: string,
    storeUid: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).deleteStoreHomepageProductUsingDELETE(
      product,
      storeHomepageUid,
      storeUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete products in account store homepage
   * @param {Array<StoreHomepageProduct>} products products
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteStoreHomepageProductsUsingDELETE(
    products: Array<StoreHomepageProduct>,
    storeHomepageUid: string,
    storeUid: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).deleteStoreHomepageProductsUsingDELETE(
      products,
      storeHomepageUid,
      storeUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options?: any) {
    return StoreControllerApiFp(this.configuration).deleteStoreHomepageUsingDELETE(
      storeHomepageUid,
      storeUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete widget in account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {StoreHomepageWidget} widget widget
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteStoreHomepageWidgetUsingDELETE(
    storeHomepageUid: string,
    storeUid: string,
    widget: StoreHomepageWidget,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).deleteStoreHomepageWidgetUsingDELETE(
      storeHomepageUid,
      storeUid,
      widget,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete widgets in account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {Array<StoreHomepageWidget>} widgets widgets
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public deleteStoreHomepageWidgetsUsingDELETE(
    storeHomepageUid: string,
    storeUid: string,
    widgets: Array<StoreHomepageWidget>,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).deleteStoreHomepageWidgetsUsingDELETE(
      storeHomepageUid,
      storeUid,
      widgets,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get account store homepage
   * @param {string} storeUid storeUid
   * @param {string} uid uid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).getStoreHomepageByUidUsingGET(
      storeUid,
      uid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get account store homepage
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public getStoreHomepageUsingGET(storeUid: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).getStoreHomepageUsingGET(
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Publish account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public publishStoreHomepageUsingPOST(storeHomepageUid: string, storeUid: string, options?: any) {
    return StoreControllerApiFp(this.configuration).publishStoreHomepageUsingPOST(
      storeHomepageUid,
      storeUid,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update banner for account store homepage
   * @param {StoreHomepageBanner} banner banner
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public updateStoreHomepageBannerUsingPOST(
    banner: StoreHomepageBanner,
    storeHomepageUid: string,
    storeUid: string,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).updateStoreHomepageBannerUsingPOST(
      banner,
      storeHomepageUid,
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Edit product in account store homepage
   * @param {StoreHomepageProduct} product product
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public updateStoreHomepageProductUsingPOST(
    product: StoreHomepageProduct,
    storeHomepageUid: string,
    storeUid: string,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).updateStoreHomepageProductUsingPOST(
      product,
      storeHomepageUid,
      storeUid,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Edit widget in account store homepage
   * @param {string} storeHomepageUid storeHomepageUid
   * @param {string} storeUid storeUid
   * @param {StoreHomepageWidget} widget widget
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public updateStoreHomepageWidgetUsingPOST(
    storeHomepageUid: string,
    storeUid: string,
    widget: StoreHomepageWidget,
    language?: string,
    options?: any
  ) {
    return StoreControllerApiFp(this.configuration).updateStoreHomepageWidgetUsingPOST(
      storeHomepageUid,
      storeUid,
      widget,
      language,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update existing account store
   * @param {Store} store store
   * @param {string} [country] country
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreControllerApi
   */
  public updateStoreUsingPOST(store: Store, country?: string, language?: string, options?: any) {
    return StoreControllerApiFp(this.configuration).updateStoreUsingPOST(
      store,
      country,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * WishListControllerApi - fetch parameter creator
 * @export
 */
export const WishListControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add products to WishList
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductsToWishListUsingPUT(productUidList: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'productUidList' is not null or undefined
      if (productUidList === null || productUidList === undefined) {
        throw new RequiredError(
          "productUidList",
          "Required parameter productUidList was null or undefined when calling addProductsToWishListUsingPUT."
        );
      }
      const localVarPath = `/wishlist`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(productUidList || {}) : productUidList || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add product to WishList
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addToWishListUsingPUT(productUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling addToWishListUsingPUT."
        );
      }
      const localVarPath = `/wishlist/{productUid}`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete from WishList
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFromWishListUsingDELETE(productUid: string, options: any = {}): FetchArgs {
      // verify required parameter 'productUid' is not null or undefined
      if (productUid === null || productUid === undefined) {
        throw new RequiredError(
          "productUid",
          "Required parameter productUid was null or undefined when calling removeFromWishListUsingDELETE."
        );
      }
      const localVarPath = `/wishlist/{productUid}`.replace(
        `{${"productUid"}}`,
        encodeURIComponent(String(productUid))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete products from WishList
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductsFromWishListUsingDELETE(productUidList: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'productUidList' is not null or undefined
      if (productUidList === null || productUidList === undefined) {
        throw new RequiredError(
          "productUidList",
          "Required parameter productUidList was null or undefined when calling removeProductsFromWishListUsingDELETE."
        );
      }
      const localVarPath = `/wishlist`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(productUidList || {}) : productUidList || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get WishList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishListUsingGET(language?: string, options: any = {}): FetchArgs {
      const localVarPath = `/wishlist`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WishListControllerApi - functional programming interface
 * @export
 */
export const WishListControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add products to WishList
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductsToWishListUsingPUT(
      productUidList: Array<string>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).addProductsToWishListUsingPUT(
        productUidList,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add product to WishList
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addToWishListUsingPUT(
      productUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).addToWishListUsingPUT(
        productUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete from WishList
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFromWishListUsingDELETE(
      productUid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).removeFromWishListUsingDELETE(
        productUid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete products from WishList
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductsFromWishListUsingDELETE(
      productUidList: Array<string>,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = WishListControllerApiFetchParamCreator(
        configuration
      ).removeProductsFromWishListUsingDELETE(productUidList, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get WishList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishListUsingGET(
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
      const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).wishListUsingGET(
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * WishListControllerApi - factory interface
 * @export
 */
export const WishListControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary Add products to WishList
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductsToWishListUsingPUT(productUidList: Array<string>, options?: any) {
      return WishListControllerApiFp(configuration).addProductsToWishListUsingPUT(productUidList, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Add product to WishList
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addToWishListUsingPUT(productUid: string, options?: any) {
      return WishListControllerApiFp(configuration).addToWishListUsingPUT(productUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete from WishList
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFromWishListUsingDELETE(productUid: string, options?: any) {
      return WishListControllerApiFp(configuration).removeFromWishListUsingDELETE(productUid, options)(fetch, basePath);
    },
    /**
     *
     * @summary Delete products from WishList
     * @param {Array<string>} productUidList productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductsFromWishListUsingDELETE(productUidList: Array<string>, options?: any) {
      return WishListControllerApiFp(configuration).removeProductsFromWishListUsingDELETE(productUidList, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary Get WishList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishListUsingGET(language?: string, options?: any) {
      return WishListControllerApiFp(configuration).wishListUsingGET(language, options)(fetch, basePath);
    },
  };
};

/**
 * WishListControllerApi - object-oriented interface
 * @export
 * @class WishListControllerApi
 * @extends {BaseAPI}
 */
export class WishListControllerApi extends BaseAPI {
  /**
   *
   * @summary Add products to WishList
   * @param {Array<string>} productUidList productUidList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishListControllerApi
   */
  public addProductsToWishListUsingPUT(productUidList: Array<string>, options?: any) {
    return WishListControllerApiFp(this.configuration).addProductsToWishListUsingPUT(productUidList, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Add product to WishList
   * @param {string} productUid productUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishListControllerApi
   */
  public addToWishListUsingPUT(productUid: string, options?: any) {
    return WishListControllerApiFp(this.configuration).addToWishListUsingPUT(productUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete from WishList
   * @param {string} productUid productUid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishListControllerApi
   */
  public removeFromWishListUsingDELETE(productUid: string, options?: any) {
    return WishListControllerApiFp(this.configuration).removeFromWishListUsingDELETE(productUid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Delete products from WishList
   * @param {Array<string>} productUidList productUidList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishListControllerApi
   */
  public removeProductsFromWishListUsingDELETE(productUidList: Array<string>, options?: any) {
    return WishListControllerApiFp(this.configuration).removeProductsFromWishListUsingDELETE(productUidList, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary Get WishList
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishListControllerApi
   */
  public wishListUsingGET(language?: string, options?: any) {
    return WishListControllerApiFp(this.configuration).wishListUsingGET(language, options)(this.fetch, this.basePath);
  }
}
