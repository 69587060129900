import { AnyAction } from "redux";
import {
  CHECKOUT_CALCULATE_AMOUNT_TO_PAY,
  CHECKOUT_CALCULATE_DELIVERY_ERROR,
  CHECKOUT_CALCULATE_DELIVERY_LOADING,
  CHECKOUT_CALCULATE_DELIVERY_SUCCESS,
  CHECKOUT_SELECT_DELIVERY_ADDRESS,
  CHECKOUT_SELECT_DELIVERY_DATE,
  CHECKOUT_SELECT_DELIVERY_METHOD,
  CHECKOUT_SELECT_PAYMENT_ADDRESS,
  CHECKOUT_SELECT_PAYMENT_METHOD,
} from "../actions/checkoutActions";
import { Address, Card, LinkedCard } from "../types/commonapi";
import { ProductDelivery } from "../types/marketplaceapi";

export type PaymentMethods = "cash" | "bankcard";

export interface CalculatedDelivery {
  shippingPrice: number;
  shippingCurrency: string;
  methods: ProductDelivery[];
  bonusMoneyAmountForOrder: number;
  bonusMoneyAmountTotal: number;
}

export interface ICheckoutState {
  selectedPaymentMethod?: PaymentMethods;
  selectedPaymentCard?: Card | LinkedCard;
  selectedPaymentAddress?: Address;
  selectedDeliveryAddress?: Address;
  selectedDeliveryDate?: string;
  selectedDeliveryMethod?: ProductDelivery;
  bonusMoney: number;
  amountToPay: number;
  CalculatedDelivery;
  calculateDeliveryLoading?: boolean;
  calculateDeliveryError?: any;
  calculatedDelivery?: CalculatedDelivery;
}

const emptyDelivery: CalculatedDelivery = {
  shippingPrice: 0,
  shippingCurrency: null,
  methods: [],
  bonusMoneyAmountForOrder: 0,
  bonusMoneyAmountTotal: 0,
};

const initialState: ICheckoutState = {
  amountToPay: 0,
  bonusMoney: 0,
  calculateDeliveryLoading: false,
  calculateDeliveryError: null,
  calculatedDelivery: emptyDelivery,
};

const checkoutReducer = (state = initialState, action: AnyAction): ICheckoutState => {
  switch (action.type) {
    case CHECKOUT_SELECT_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.method,
        selectedPaymentCard: action.paymentCard,
      };
    }
    case CHECKOUT_SELECT_PAYMENT_ADDRESS: {
      return {
        ...state,
        selectedPaymentAddress: action.address,
      };
    }
    case CHECKOUT_SELECT_DELIVERY_ADDRESS: {
      return {
        ...state,
        selectedDeliveryAddress: action.address,
      };
    }
    case CHECKOUT_SELECT_DELIVERY_METHOD: {
      return {
        ...state,
        selectedDeliveryMethod: action.delivery,
      };
    }
    case CHECKOUT_SELECT_DELIVERY_DATE: {
      return {
        ...state,
        selectedDeliveryDate: action.date,
      };
    }
    case CHECKOUT_CALCULATE_DELIVERY_LOADING: {
      return {
        ...state,
        calculateDeliveryLoading: true,
        calculateDeliveryError: null,
        calculatedDelivery: emptyDelivery,
      };
    }
    case CHECKOUT_CALCULATE_DELIVERY_SUCCESS: {
      return {
        ...state,
        calculateDeliveryLoading: false,
        calculateDeliveryError: null,
        calculatedDelivery: action.calculatedDelivery,
        bonusMoney: 0,
        amountToPay: 0,
      };
    }
    case CHECKOUT_CALCULATE_DELIVERY_ERROR: {
      return {
        ...state,
        calculateDeliveryLoading: false,
        calculateDeliveryError: action.error,
        calculatedDelivery: emptyDelivery,
      };
    }
    case CHECKOUT_CALCULATE_AMOUNT_TO_PAY: {
      return {
        ...state,
        bonusMoney: action.bonusMoney,
        amountToPay: action.amountToPay,
      };
    }
    default: {
      return state;
    }
  }
};

export default checkoutReducer;
