import React, { FC, useEffect, useState } from "react";
import { Page, Block, Navbar, List, ListItem, BlockTitle, Row, Col } from "framework7-react";
import { useTranslation } from "react-i18next";
import { IApplicationStore, ResizeEvent } from "../../store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { ICartProps } from "../../store/connectCart";
import "./style.less";
import { Button } from "../../components/ThemedButton";
import { formatPrice } from "../../utils";

import { LoginDesktopPopup } from "../../components/LoginDesktop";
import { RegisterDesktopPopup } from "../../components/RegisterDesktop";
import { ShortRegistrationPopup } from "../../components/ShortRegistrationPopup";
import { analytics } from "../../Setup";
import ItemList from "./checkout/ItemList";
import { Router } from "framework7/modules/router/router";
import { ForgotPasswordPopup } from "../../components/ForgotPassword";
import { getProfile } from "../../selectors/profile";
type Props = Page.Props &
  ICartProps & {
    f7router: Router.Router;
  };

const CartPage: FC<Props> = ({ f7router }) => {
  const { t } = useTranslation();
  const resizeEvent: ResizeEvent = useSelector((state: IApplicationStore) => state.rootReducer.resizeEvent);
  const sessionState = useSelector((state: IApplicationStore) => state.sessionReducer);
  const itemsCart = useSelector((state: IApplicationStore) => state.cartReducer.items);
  const totalCart = useSelector((state: IApplicationStore) => state.cartReducer.total);
  const itemsTotalCart = useSelector((state: IApplicationStore) => state.cartReducer.itemsTotal);
  const delivery = useSelector((state: IApplicationStore) => state.cartReducer.delivery);
  const profile = useSelector((state: IApplicationStore) => getProfile(state));

  const [registerPopupOpened, setRegisterPopupOpened] = useState(false);
  const [forgotPasswordPopupOpened, setForgotPasswordPopupOpened] = useState(false);
  const [shortRegisterPopupOpened, setShortRegisterPopupOpened] = useState(false);
  const [loginPopupOpened, setLoginPopupOpened] = useState(false);

  const getCartTitle = () => {
    return `${t("Cart")} ${itemsCart.length > 0 ? `(${itemsCart.length})` : ""}`;
  };

  const renderCartTotal = () => {
    const { currencyCode } = itemsCart[0].product;

    return (
      <div className="cart-total">
        <span className="label">{t("Total:")}</span>
        <span>{formatPrice(totalCart, currencyCode)}</span>
      </div>
    );
  };
  const goToCheckout = () => {
    if (!sessionState.logged) {
      setLoginPopupOpened(true);
      return;
    }
    f7router.navigate("checkout/");
  };
  const handleRegisterButton = () => {
    if (process.env.SIMPLIFIED_REGISTRATION === "true") {
      setShortRegisterPopupOpened(true);
    } else {
      setRegisterPopupOpened(true);
    }
    analytics.openRegistrationForm();
  };
  const currencyCode = itemsCart.length ? itemsCart[0].product.currencyCode : "";
  const deliveryPrice = delivery?.price || 0;

  const [noProducts, setNoProducts] = useState<boolean>(false);
  useEffect(() => {
    if (itemsCart.length == 0) {
      setNoProducts(true);
    }
  }, [itemsCart]);
  const pageAfterInHandle = () => {
    setRegisterPopupOpened(false);
    setForgotPasswordPopupOpened(false);
    setShortRegisterPopupOpened(false);
    setLoginPopupOpened(false);
  };
  return (
    <Page id="cart_page" name="cart-page" onPageAfterIn={pageAfterInHandle}>
      <Navbar title={getCartTitle()} backLink={t("Back").toString()} noHairline noShadow />
      <div className="block-title block-title-medium pure-hidden-xs">
        {t("Shopping cart") + ` (${itemsCart.length})`}
      </div>
      <Row>
        <Col width="100" medium="70">
          <ItemList showButton={true} />
        </Col>
        <Col width="100" medium="30" className="pure-hidden-xs" style={{ position: "relative" }}>
          <div className="order-summary-container">
            <BlockTitle medium>{t("Order summary")}</BlockTitle>
            <List className="order-summary" noHairlines noChevron noHairlinesBetween>
              <ListItem
                title={t("Items") + ` (${itemsCart.length})`}
                after={formatPrice(itemsTotalCart, currencyCode)}
              />
              <ListItem title={t("Delivery").toString()} after={formatPrice(deliveryPrice, currencyCode)} />
              <ListItem title={t("Total").toString()} after={formatPrice(totalCart, currencyCode)} className="total" />
            </List>
            <Block>
              {/* TODO move to component */}
              <Button round large fill onClick={goToCheckout}>
                <span>{t("Go to checkout")}</span>
              </Button>
            </Block>
          </div>
        </Col>
      </Row>
      {itemsCart.length && (
        <Block slot="fixed" className="go-to-checkout-btn-container pure-visible-xs">
          {/* TODO move to component */}
          <Button className="go-to-checkout-btn" round large fill onClick={goToCheckout}>
            <span>{renderCartTotal()}</span>
            <span>{t("Go to checkout")}</span>
          </Button>
        </Block>
      )}
      <LoginDesktopPopup
        opened={loginPopupOpened}
        onPopupClosed={() => {
          setLoginPopupOpened(false);
          if (sessionState.logged) goToCheckout();
        }}
        t={t}
        slot="fixed"
        onRegister={() => {
          setLoginPopupOpened(false);
          handleRegisterButton();
        }}
        onForgotPassword={() => {
          setLoginPopupOpened(false);
          setForgotPasswordPopupOpened(true);
        }}
      />
      <RegisterDesktopPopup
        opened={registerPopupOpened}
        onPopupClosed={() => setRegisterPopupOpened(false)}
        t={t}
        slot="fixed"
      />

      <ForgotPasswordPopup
        profile={profile}
        backdrop={false}
        opened={forgotPasswordPopupOpened}
        onPopupClosed={() => setForgotPasswordPopupOpened(false)}
      />

      <ShortRegistrationPopup
        opened={shortRegisterPopupOpened}
        onPopupClosed={() => setShortRegisterPopupOpened(false)}
        t={t}
        slot="fixed"
      />
    </Page>
  );
};

export default CartPage;
