import React from "react";

export default ({ large = false }) =>
  large ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="8" width="48" height="32" rx="8" fill="#EDEDED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1355 21.8363C24.1144 23.5079 25.6188 24.4407 26.752 24.9953C27.9164 25.5645 28.3075 25.9294 28.3031 26.4382C28.2942 27.2171 27.3742 27.5607 26.5132 27.5741C25.011 27.5975 24.1377 27.1668 23.4433 26.841L22.9023 29.384C23.5989 29.7065 24.8888 29.9877 26.2265 30C29.3664 30 31.4206 28.4434 31.4318 26.0298C31.444 22.9668 27.2131 22.7971 27.242 21.4279C27.252 21.0129 27.6464 20.5698 28.5108 20.4571C28.9385 20.4003 30.1196 20.3567 31.4584 20.976L31.9839 18.5156C31.264 18.2522 30.3384 18 29.1863 18C26.231 18 24.1522 19.5778 24.1355 21.8363ZM37.0337 18.212C36.4604 18.212 35.977 18.5479 35.7615 19.0634L31.2762 29.8192H34.4138L35.0382 28.0862H38.8724L39.2346 29.8192H42L39.5868 18.212H37.0337ZM37.4725 21.3476L38.378 25.7061H35.8981L37.4725 21.3476ZM20.3313 18.212L17.8581 29.8192H20.8479L23.32 18.212H20.3313ZM15.9082 18.212L12.7962 26.1123L11.5373 19.3948C11.3896 18.645 10.8063 18.212 10.1585 18.212H5.07113L5 18.549C6.04442 18.7767 7.23101 19.1438 7.94983 19.5366C8.38976 19.7765 8.51537 19.9862 8.65976 20.5564L11.044 29.8192H14.2039L19.0481 18.212H15.9082Z"
        fill="#1A1F71"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="4" width="32" height="24" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4809 14.8773C16.4649 16.1309 17.6034 16.8305 18.461 17.2465C19.3422 17.6733 19.6381 17.947 19.6347 18.3286C19.628 18.9128 18.9318 19.1705 18.2803 19.1806C17.1434 19.1981 16.4826 18.8751 15.9571 18.6308L15.5477 20.538C16.0748 20.7799 17.051 20.9908 18.0633 21C20.4394 21 21.994 19.8325 22.0024 18.0223C22.0117 15.7251 18.8099 15.5978 18.8318 14.571C18.8393 14.2597 19.1378 13.9274 19.792 13.8429C20.1156 13.8002 21.0094 13.7675 22.0226 14.232L22.4203 12.3867C21.8754 12.1892 21.175 12 20.3032 12C18.0667 12 16.4936 13.1834 16.4809 14.8773ZM26.2417 12.159C25.8078 12.159 25.4421 12.4109 25.279 12.7976L21.8847 20.8644H24.2591L24.7316 19.5647H27.6332L27.9073 20.8644H30L28.1738 12.159H26.2417ZM26.5738 14.5107L27.259 17.7796H25.3824L26.5738 14.5107ZM13.6021 12.159L11.7305 20.8644H13.993L15.8638 12.159H13.6021ZM10.2549 12.159L7.89981 18.0842L6.94718 13.0461C6.83539 12.4838 6.39397 12.159 5.90376 12.159H2.05383L2 12.4118C2.79037 12.5825 3.68833 12.8578 4.2323 13.1524C4.56522 13.3323 4.66028 13.4897 4.76955 13.9173L6.57387 20.8644H8.96514L12.631 12.159H10.2549Z"
        fill="#1A1F71"
      />
    </svg>
  );
