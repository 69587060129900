import React from 'react';
import { Icon } from 'framework7-react';

import './style.less';

const WishDetails = ({ count }: { count: number }) => (
  <div className="wish-details">
    <Icon className="ic-wish-small" /><span>{count}</span>
  </div>
)

export default WishDetails