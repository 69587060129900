import React, { Component, useRef } from 'react';
import { Page, Navbar, Block, Searchbar, Icon } from 'framework7-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IFilterState } from '../reducers/filterReducer';
import connectFilter from '../store/connectFilter';
import Map from '../components/Map';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '../components/ThemedButton';
import './location.less';
import { IApplicationStore } from '../store/rootReducer';

type Props = WithTranslation & IFilterState & {
  location?: string,
  setLocationHandle?(): void,
  mapChangeHandle?(e: any): void,
}

class LocationPage extends Component<Props, { location?: string }> {
  state = {
    location: null
  }

  setLocationHandle = () => {
    console.log(this.refs)
  }

  render() {
    const { location, t } = this.props
    return (
      <Page id="location" name="location">
        <Navbar title={t('Location')} backLink={t('Back').toString()} noHairline noShadow />
        <Searchbar noHairline noShadow />
        <Map ref="map" center={location} />
        <Block className="buttons-container">
          <div></div>
          <div>
            <Button
              fill
              large
              raised
              round
              onClick={this.setLocationHandle}
            >
              {t('Set Location')}
            </Button>
          </div>
          <div className="right">
            <Button
              className="my-location"
              fill
              large
              raised
              round
            >
              <Icon material="gps_fixed" />
            </Button>
          </div>
        </Block>
      </Page>
    )
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  location: state.filterReducer.allFiltresLocation
})

const mapDispatchToProps = (dispatch: any, props: any) => ({
  setLocationHandle: () => {
    props.f7router.back()
  },
  mapChangeHandle: (e) => {
    console.log(e)
  }
})

export default compose(
  connectFilter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(LocationPage)