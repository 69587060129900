import React, { Component } from "react";
import {
  Page,
  Navbar,
  List,
  ListItem,
  Row,
  Col,
  Fab,
  Icon,
  Popup,
  NavTitle,
  NavRight,
  Link,
  Checkbox,
  Block,
  BlockTitle,
  BlockHeader,
} from "framework7-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { ListInput } from "../../components/ListInput";
import { IApplicationStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { Address, Country } from "../../types/commonapi";
import connectAccountAddress, {
  IAccountAddressProps,
} from "../../store/connectAccountAddress";
import connectProfile from "../../store/connectProfile";
import { Profile } from "../../reducers/sessionReducer";
import { Button } from "../../components/ThemedButton";

const config = process.env.CONFIG;

type Props = WithTranslation &
  IAccountAddressProps & {
    saveInfoByDefault?: boolean;
    onPopupClosed(instance?: any, chooseCountry?: boolean): void;
    country?: Country;
    reset?: boolean;
    onAddOrUpdateInfo?(address: Address): void;
    profile: Profile;
    countryClassificator: Country[];
  };

type State = Pick<
  Address,
  "country" | "city" | "firstAddressLine" | "postalCode"
> & {
  name?: string;
  surname?: string;
  email?: string;
  formErrors?: any;
  formValidFields?: any;
  formValid: boolean;
  saveInfo?: boolean;
  chooseCountry?: boolean;
};

const initialState: State = {
  country: null,
  name: "",
  surname: "",
  email: "",
  city: "",
  firstAddressLine: "",
  postalCode: "",
  formErrors: {
    name: "",
    surname: "",
    email: "",
    country: "",
    city: "",
    firstAddressLine: "",
    postalCode: "",
  },
  formValidFields: {
    name: false,
    surname: false,
    email: false,
    country: false,
    city: false,
    firstAddressLine: false,
    postalCode: false,
  },
  formValid: false,
  chooseCountry: false,
};

class DeliveryInfoEditPage extends Component<
  Props & Omit<Popup.Props, "onPopupClosed">,
  State
> {
  constructor(props: Readonly<WithTranslation & Props & Popup.Props>) {
    super(props);
    this.state = {
      ...cloneDeep(initialState),
      saveInfo: props.saveInfoByDefault,
      country: props.country,
    };

    if (props.country) {
      this.state.formValidFields.country = true;
    }
  }

  handlePopupOpen = (instance: any) => {
    const { profile } = this.props;
    const primaryEmail =
      profile && profile.accountEmails
        ? profile.accountEmails.filter((item) => item.primary)[0]
        : null;

    if (this.props.reset) {
      this.setState({
        ...cloneDeep(initialState),
        country: this.props.country,
        name: profile && profile.person ? profile.person.name : "",
        surname: profile && profile.person ? profile.person.surname : "",
        email: primaryEmail ? primaryEmail.email : "",
      });
    } else {
      this.setState({
        country: this.props.country,
        formValidFields: {
          ...this.state.formValidFields,
          country: !!this.props.country,
        },
      });
    }
    if (this.props.onPopupOpen) this.props.onPopupOpen(instance);

    if (this.props.country) {
      this.setState({
        formValidFields: { ...this.state.formValidFields, country: true },
      });
    }
  };

  componentDidUpdate(prevProps: Props) {
    this.$f7.preloader.hide();

    const loading = this.props.accountAddressState.accountAddOrUpdateAddressLoading;
    const prevLoading = prevProps.accountAddressState.accountAddOrUpdateAddressLoading;
    const error = this.props.accountAddressState.accountAddOrUpdateAddressError;

    if (prevLoading && !loading && error) {
      this.$f7.dialog.alert(error);
    }

    if (prevLoading && !loading && !error) {
      this.$f7.popup.close();
    }
  }

  handleBlurInput = (e: any) => this.handleUserInput(e);

  handleUserInput = (e: any) => {
    let { name, value, rawValue = null } = e.target;
    value = rawValue !== null && name !== "expireDate" ? rawValue : value;
    // @ts-ignore
    this.setState({ [name]: value }, () => this.validateField(name, value));
  };

  handleInputClear = (e: any) => {
    let { name } = e.target;
    // @ts-ignore
    this.setState({ [name]: "" }, () => this.validateField(name, ""));
  };

  validateField = (fieldName: keyof State, value: string) => {
    const { t } = this.props;
    let formValidFields = this.state.formValidFields;
    let fieldValidationErrors = this.state.formErrors;
    let errorText = "";
    let requiredFieldErrorText = t("Please fill out this field.");

    if (
      "name, surname, email, city, address, zip, postalCode, firstAddressLine"
        .split(", ")
        .includes(fieldName)
    ) {
      errorText = value.length ? errorText : requiredFieldErrorText;
      fieldValidationErrors[fieldName] = errorText;
      formValidFields[fieldName] = !errorText.length;
    }

    if (fieldName === "country" && !this.state.country) {
      errorText = requiredFieldErrorText;
      fieldValidationErrors[fieldName] = errorText;
      formValidFields[fieldName] = !errorText.length;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        formValidFields,
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({
      formValid: !this.formHasErrors(this.state.formValidFields),
    });
  };

  formHasErrors = (formValidFields: any) => {
    return Object.keys(formValidFields).some((key) => !formValidFields[key]);
  };

  getErrorMessagesProps = (fieldName: string) => {
    const { t } = this.props;
    return {
      errorMessage: t(this.state.formErrors[fieldName]),
      errorMessageForce: !!this.state.formErrors[fieldName],
    };
  };

  handleAddOrUpdateInfo = () => {
    if (
      !this.state.formValid ||
      this.props.accountAddressState.accountAddOrUpdateAddressLoading
    ) {
      Object.keys(this.state.formValidFields).forEach((key) => {
        // @ts-ignore
        this.validateField(key, this.state[key]);
      });

      if (!this.state.formValid) {
        return;
      }
    }

    const {
      city,
      country,
      firstAddressLine,
      postalCode,
    } = this.state;

    this.$f7.preloader.show();
    this.props.addOrUpdateAccountAddress({
      city,
      country,
      postalCode,
      firstAddressLine,
    });
  };

  renderCountryInput() {
    const { t } = this.props;

    if (config === "marine") {
      const countries = this.props.countryClassificator.filter(
        (item) => item.code === "CA"
      );
      return (
        <Col width={100}>
          <BlockHeader>{t("Country")}</BlockHeader>
          {countries.map((country) => (
            <ListItem
              radio
              key={country.code}
              name="country-media-radio"
              value={country.code}
              title={country.name}
              onChange={() => {
                this.setState({ country }, () =>
                  this.validateField("country", country.code)
                );
              }}
              checked={
                this.state.country && country.code === this.state.country.code
              }
            >
              <div slot="text">{t(country.name)}</div>
            </ListItem>
          ))}
        </Col>
      );
    }

    return (
      <Col width={100} medium={50}>
        <ListItem
          link
          header={t("Country").toString()}
          title={
            this.state.country
              ? this.state.country.name
              : t("Choose country").toString()
          }
          onClick={() => {
            this.setState({chooseCountry: true}, () => {
              this.$f7.popup.close();
            });
          }}
        />
      </Col>
    );
  }

  renderCityInput() {
    const {
      accountAddressState: { accountAddOrUpdateAddressLoading },
      t,
    } = this.props;

    if (config === "marine") {
      const cities = ["Montreal", "Vancouver"];
      return (
        <Col width={100}>
          <ListInput
            name="city"
            label={t("City").toString()}
            type="select"
            placeholder={t("Please select city").toString()}
            disabled={accountAddOrUpdateAddressLoading}
            required
            onBlur={this.handleBlurInput}
            onChange={this.handleUserInput}
            value={this.state.city}
            {...this.getErrorMessagesProps("city")}
          >
            <option value={null}>{t("Please select city")}</option>
            {cities.map((city) => (
              <option key={city} value={t(city).toString()}>{t(city)}</option>
            ))}
          </ListInput>
        </Col>
      );
    }

    return (
      <Col width={100} medium={50}>
        <ListInput
          wrap={false}
          name="city"
          label={t("City").toString()}
          floatingLabel
          type="text"
          placeholder=""
          clearButton
          disabled={accountAddOrUpdateAddressLoading}
          required
          onBlur={this.handleBlurInput}
          onChange={this.handleUserInput}
          onInputClear={this.handleInputClear}
          value={this.state.city}
          {...this.getErrorMessagesProps("city")}
        />
      </Col>
    );
  }

  render() {
    const {
      t,
      saveInfoByDefault,
      accountAddressState: { accountAddOrUpdateAddressLoading },
      ...rest
    } = this.props;
    return (
      <Popup
        id="delivery_info_edit__popup"
        swipeToClose
        {...rest}
        onPopupOpen={this.handlePopupOpen}
        onPopupClosed={(instance: any) => {
          if (this.props.onPopupClosed) {
            this.props.onPopupClosed(instance, this.state.chooseCountry);
          }
          this.setState({ chooseCountry: false });
        }}
      >
        <Page>
          <Navbar noHairline noShadow>
            <NavTitle sliding>{t("Delivery information")}</NavTitle>
            <NavRight>
              <Link popupClose iconMd="material:close" iconOnly/>
            </NavRight>
          </Navbar>
          <BlockTitle medium>{t("Contacts")}</BlockTitle>
          <List noHairlinesMd form noHairlinesBetweenMd>
            <ListInput
              name="name"
              label={t("First Name").toString()}
              floatingLabel
              type="text"
              placeholder=""
              clearButton
              disabled={accountAddOrUpdateAddressLoading}
              required
              onBlur={this.handleBlurInput}
              onChange={this.handleUserInput}
              onInputClear={this.handleInputClear}
              value={this.state.name}
              slot="list"
              autocomplete="off"
              {...this.getErrorMessagesProps("name")}
            />
            <ListInput
              name="surname"
              label={t("Last Name").toString()}
              floatingLabel
              type="text"
              placeholder=""
              clearButton
              disabled={accountAddOrUpdateAddressLoading}
              required
              onBlur={this.handleBlurInput}
              onChange={this.handleUserInput}
              onInputClear={this.handleInputClear}
              value={this.state.surname}
              slot="list"
              autocomplete="off"
              {...this.getErrorMessagesProps("surname")}
            />
            <ListInput
              name="email"
              label={t("Email").toString()}
              floatingLabel
              type="text"
              placeholder=""
              clearButton
              disabled={accountAddOrUpdateAddressLoading}
              required={false}
              onBlur={this.handleBlurInput}
              onChange={this.handleUserInput}
              onInputClear={this.handleInputClear}
              value={this.state.email}
              slot="list"
              autocomplete="off"
              {...this.getErrorMessagesProps("email")}
            />
            <List slot="list">
              <Row style={{ width: "100%" }}>
                {this.renderCountryInput()}
                {this.renderCityInput()}
              </Row>
            </List>
            <ListInput
              name="firstAddressLine"
              label={t("Address Line").toString()}
              floatingLabel
              type="text"
              placeholder=""
              disabled={accountAddOrUpdateAddressLoading}
              required
              onBlur={this.handleBlurInput}
              onChange={this.handleUserInput}
              onInputClear={this.handleInputClear}
              value={this.state.firstAddressLine}
              slot="list"
              autocomplete="off"
              {...this.getErrorMessagesProps("firstAddressLine")}
            />
            <ListInput
              name="postalCode"
              label={t("Postal Code").toString()}
              floatingLabel
              type="text"
              placeholder=""
              disabled={accountAddOrUpdateAddressLoading}
              required
              onBlur={this.handleBlurInput}
              onChange={this.handleUserInput}
              onInputClear={this.handleInputClear}
              value={this.state.postalCode}
              slot="list"
              autocomplete="off"
              {...this.getErrorMessagesProps("postalCode")}
            />
          </List>
          {!saveInfoByDefault && (
            <Block>
              <Checkbox
                name="save-info"
                defaultChecked={this.state.saveInfo}
                onChange={() =>
                  this.setState({ saveInfo: !this.state.saveInfo })
                }
              >
                {t("Save Delivery Information")}
              </Checkbox>
            </Block>
          )}
          <Fab
            position="right-bottom"
            onClick={this.handleAddOrUpdateInfo}
            slot="fixed"
            className="pure-visible-xs"
          >
            <Icon ios="f7:checkmark_alt" md="material:check" />
          </Fab>

          <Block className="apply-btn-container pure-hidden-xs">
            <Button
              round
              large
              fill
              onClick={this.handleAddOrUpdateInfo}
              disabled={accountAddOrUpdateAddressLoading}>
              {t("Save")}
            </Button>
          </Block>
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  ...state.paymentCardsReducer,
  countryClassificator: state.classificatorReducer.countryClassificator,
});

export default compose(
  withTranslation(),
  connectAccountAddress,
  connectProfile,
  connect(mapStateToProps, null)
)(DeliveryInfoEditPage) as React.ComponentClass<
  Omit<Popup.Props, "onPopupClosed"> & {
    saveInfoByDefault?: boolean;
    onAddOrUpdateInfo?(address: Address): void;
    onPopupClosed(instance?: any, chooseCountry?: boolean): void;
    country?: Country;
    reset?: boolean;
  }
>;
