import React from "react";
import { List, ListGroup, ListItem } from "framework7-react";
import { compose } from "redux";
import connectCategories from "../../store/connectCategories";
import connectFilter from "../../store/connectFilter";
import { ICategory } from "../../store/rootReducer";
import { IProduct } from "../../reducers/productReducer";
import connectCategoriesClassificator from "../../store/connectCategoriesClassificator";
import { ICategoryClassificator } from "../../reducers/categoryReducer";
import CatalogItem, { SwipeoutActions } from "./CatalogItem";
import chain from "lodash/chain";

import "./style.less";

type Props = {
  items?: IProduct[];
  categories?: ICategory[];
  chosenCategoryId?: string;
  chosenSubcategoryId?: string;
  categoriesClassificator?: ICategoryClassificator[];
  addToWish?(uid?: string): void;
  onClick?(uid: string): void;
  swipeoutActions?: SwipeoutActions[];
  groupBy?: string;
  simplePrice?: boolean;
  showStatus?: boolean;
  showAnalytics?: boolean;
  showFeaturesHiglight?: boolean;
};

const Catalog = ({
  items,
  addToWish,
  categoriesClassificator,
  onClick,
  swipeoutActions,
  groupBy,
  simplePrice,
  showStatus,
  showAnalytics,
  showFeaturesHiglight,
}: Props) => {
  return (
    <List className="catalog" mediaList noChevron noHairlines>
      {!groupBy
        ? items?.map((item, _i) => (
            <CatalogItem
              key={item.uid}
              item={item}
              categories={categoriesClassificator}
              addToWish={addToWish}
              slot="list"
              onClick={onClick}
              swipeoutActions={swipeoutActions}
              simplePrice={simplePrice}
              showStatus={showStatus}
              showAnalytics={showAnalytics}
              showFeaturesHiglight={showFeaturesHiglight}
            />
          ))
        : chain(items)
            .groupBy(groupBy)
            .map((value, key) => (
              <ListGroup key={key}>
                <ListItem title={key} groupTitle />
                {value.map((item, _i) => (
                  <CatalogItem
                    key={item.uid}
                    item={item}
                    categories={categoriesClassificator}
                    addToWish={addToWish}
                    slot="list"
                    onClick={onClick}
                    swipeoutActions={swipeoutActions}
                    simplePrice={simplePrice}
                    showStatus={showStatus}
                    showAnalytics={showAnalytics}
                    showFeaturesHiglight={showFeaturesHiglight}
                  />
                ))}
              </ListGroup>
            ))
            .value()}
    </List>
  );
};

export default compose<any>(
  connectFilter,
  connectCategories,
  connectCategoriesClassificator
)(Catalog) as React.FunctionComponent<Props>;
