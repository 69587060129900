import React, { PureComponent } from 'react';
import { ListInput as F7Input, F7ListInput } from 'framework7-react';
import classNames from 'classnames';
import { F7CleaveListInput } from '.';

import './style.less';

type Props = F7ListInput.Props & {
  cleaveFormatInputOptions?: any,
}

class ListInput extends PureComponent<Props> {

  constructor(props: Readonly<Props>) {
    super(props)
    this.checkNamePropSet(props)
  }

  checkNamePropSet(props: Readonly<Props>) {
    if (!props.name) throw new Error('Name prop must be set!')
  }

  render() {
    const props = this.props
    const { cleaveFormatInputOptions } = props
    const Component = cleaveFormatInputOptions ? F7CleaveListInput : F7Input

    return (
      <Component
        {...props}
        className={classNames(props.className ? props.className : null)}
        options={cleaveFormatInputOptions}
      />
    )
  }
}

export default ListInput