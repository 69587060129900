import React from "react";
import { Popover, F7Popover, Block, BlockTitle, List, ListItem, Icon } from "framework7-react";
import { compose } from "redux";
import { Profile } from "../../reducers/sessionReducer";
import connectLocalConfig from "../../store/connectLocalConfig";
import { ILocalConfig } from "../../store/rootReducer";
import { Avatar } from "../Avatar";
import classNames from "classnames";

import "./style.less";
import { ProfileStatus } from "../ProfileStatus";
import { withTranslation, WithTranslation } from "react-i18next";
import { IcTransaction, IcOrders } from "../../components-ui/icons";
import { IcLogin } from "../../components-ui/icons";

type Props = F7Popover.Props &
  Pick<WithTranslation, "t"> & {
    profile: Profile;
    localConfig?: ILocalConfig;
    onLogout: any;
    onAboutClick: any;
    onInviteClick: () => void;
    onViewClick: () => void;
  };

const LinkItem = ({ title, link, icon }: { title: string; link: string; icon: JSX.Element }) => {
  return (
    <ListItem link={link} title={title} popoverClose>
      {icon}
    </ListItem>
  );
};

class ProfilePopover extends React.Component<Props> {
  menuItemEnabled = (item: string): boolean => {
    const { localConfig } = this.props;
    const profileMenuItems = localConfig.profileMenuItems;
    return profileMenuItems && profileMenuItems.length > 0 && profileMenuItems.includes(item);
  };

  private appName = process.env.APP_NAME;

  render() {
    const { profile, className, t, onLogout, onAboutClick, onInviteClick, onViewClick, ...props } = this.props;
    return (
      <Popover {...props} className={classNames("profile-popover", className)} closeByBackdropClick closeByOutsideClick>
        <div className="view-link" onClick={onViewClick}>
          {t("View").toString()}
        </div>
        <Block className="avatar-container">
          <Avatar profile={profile} />
          <BlockTitle large className="profile-name">
            {profile.person?.name}
          </BlockTitle>
          {profile && <ProfileStatus profile={profile} />}
        </Block>
        <List noHairlines>
          <ul>
            {this.menuItemEnabled("InviteFriend") && (
              <ListItem link="#" title={t("Invite a Friend").toString()} onClick={onInviteClick}>
                <Icon slot="media" f7="person_2_alt" />
              </ListItem>
            )}
            {this.menuItemEnabled("MyProducts") && (
              <LinkItem
                link="/profile/my-goods/"
                title={t("My products").toString()}
                icon={<Icon slot="media" f7="list_bullet" />}
              />
            )}
            {this.menuItemEnabled("MyWishList") && (
              <LinkItem
                link="/wish-list/"
                title={t("My wish list").toString()}
                icon={<Icon slot="media" material="favorite_border" />}
              />
            )}
            {this.menuItemEnabled("MyWallet") && (
              <LinkItem
                link="/profile/wallet/"
                title={t("My wallet").toString()}
                icon={<Icon slot="media" material="account_balance_wallet" />}
              />
            )}
            {this.menuItemEnabled("MyOrders") && (
              <ListItem link="/profile/orders/" title={t("My Orders").toString()} popoverClose>
                <div slot="media" className="display-flex justify-content-center align-content-center">
                  <IcOrders />
                </div>
              </ListItem>
            )}
            {this.menuItemEnabled("Transactions") && (
              <ListItem link="/profile/transactions/" title={t("Transactions").toString()} popoverClose>
                <div slot="media" className="display-flex justify-content-center align-content-center">
                  <IcTransaction />
                </div>
              </ListItem>
            )}
            {this.menuItemEnabled("ProductPromotion") && (
              <ListItem link="product-promotion/" title={t("Product promotion").toString()}>
                <Icon slot="media" f7="star" />
              </ListItem>
            )}
            {this.menuItemEnabled("About") && (
              <ListItem
                link="#"
                title={t("About app", { appName: this.appName }).toString()}
                popoverClose
                onClick={onAboutClick}
              >
                <Icon slot="media" f7="info_circle" />
              </ListItem>
            )}
            {this.menuItemEnabled("Logout") && (
              <ListItem link="#" title={t("Logout").toString()} onClick={onLogout} popoverClose>
                <div slot="media" className="display-flex justify-content-center align-content-center">
                  <IcLogin />
                </div>
              </ListItem>
            )}
          </ul>
        </List>
      </Popover>
    );
  }
}

export default compose<any>(withTranslation(), connectLocalConfig)(ProfilePopover);
