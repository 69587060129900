import { client, marketplaceapiURL } from "../axios";
import { ICategoryClassificator } from "../reducers/categoryReducer";

export const CATEGORY_LIST_LOADING = "CATEGORY_LIST_LOADING";
export const CATEGORY_LIST_LOADING_SUCCESS = "CATEGORY_LIST_LOADING_SUCCESS";
export const CATEGORY_LIST_LOADING_ERROR = "CATEGORY_LIST_LOADING_ERROR";

function mapCategories(
  categories: ICategoryClassificator[],
  allCategories: ICategoryClassificator[],
  parent?: ICategoryClassificator,
  top?: ICategoryClassificator
) {
  return categories.map((item) => {
    const { categoryCode } = item;
    item.children = mapCategories(
      allCategories.filter((item) => item.dependency === categoryCode),
      allCategories,
      item,
      !parent ? item : top
    );

    item.parent = parent;
    /* TODO */
    // @ts-ignore
    item.topParent = top;

    return item;
  });
}

export const loadCategories = () => async (dispatch: any) => {
  dispatch(categoryListLoadingAction());

  try {
    const categories: ICategoryClassificator[] = await (
      await client.get(marketplaceapiURL + "/classificator/productCategory")
    ).data.body;
    dispatch(
      categoryListLoadingSuccessAction(
        mapCategories(
          categories.filter((item) => !item.dependency /* root elements */),
          categories
        ),
        categories
      )
    );
  } catch (err) {
    dispatch(categoryListLoadingErrorAction(err.toString()));
  }
};

const categoryListLoadingAction = () => ({
  type: CATEGORY_LIST_LOADING,
});

const categoryListLoadingSuccessAction = (
  categories: ICategoryClassificator[],
  flat: ICategoryClassificator[]
) => ({
  type: CATEGORY_LIST_LOADING_SUCCESS,
  categories,
  flat,
});

const categoryListLoadingErrorAction = (error: any) => ({
  type: CATEGORY_LIST_LOADING_ERROR,
  error,
});
