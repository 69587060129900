import { WalletWsControllerApi } from "../types/commonapi";
import { throwIsHasErrorData } from "./paymentCardsActions";

export const MY_CURRENCIES_LIST_LOADING = "MY_CURRENCIES_LIST_LOADING";
export const MY_CURRENCIES_LIST_SUCCESS = "MY_CURRENCIES_LIST_SUCCESS";
export const MY_CURRENCIES_LIST_ERROR = "MY_CURRENCIES_LIST_ERROR";

export const loadMyCurrencies = () => async (dispatch: any) => {
  dispatch(MyCurrenciesListLoadingAction());
  try {
    const res = await new WalletWsControllerApi().getAccountWalletUsingPOST({});
    throwIsHasErrorData(res);
    dispatch(MyCurrenciesListSuccessAction(res.walletList));
  } catch (err) {
    dispatch(MyCurrenciesListErrorAction(err.toString()));
  }
};

export const addCurrency = (currencyCode: string) => async (dispatch: any) => {
  dispatch(MyCurrenciesListLoadingAction());
  try {
    const items =
      (
        await new WalletWsControllerApi().createWalletUsingPOST({
          currencyCode,
        })
      ).walletList || [];
    dispatch(MyCurrenciesListSuccessAction(items));
  } catch (err) {
    dispatch(MyCurrenciesListErrorAction(err.toString()));
  }
};

export const setWalletAsPrimary = (uid: string) => async (dispatch: any) => {
  dispatch(MyCurrenciesListLoadingAction());
  try {
    const items =
      (await new WalletWsControllerApi().setWalletAsPrimaryUsingPOST({ uid }))
        .walletList || [];
    dispatch(MyCurrenciesListSuccessAction(items));
  } catch (err) {
    dispatch(MyCurrenciesListErrorAction(err.toString()));
  }
};

export const disableWallet = (uid: string) => async (dispatch: any) => {
  dispatch(MyCurrenciesListLoadingAction());
  try {
    const res = await new WalletWsControllerApi().disableWalletUsingPOST({
      uid,
    });
    throwIsHasErrorData(res);
    dispatch(MyCurrenciesListSuccessAction(res.walletList));
  } catch (err) {
    dispatch(MyCurrenciesListErrorAction(err.toString()));
  }
};

export const removeWallet = (uid: string) => async (dispatch: any) => {
  dispatch(MyCurrenciesListLoadingAction());
  try {
  } catch (err) {
    dispatch(MyCurrenciesListErrorAction(err.toString()));
  }
};

const MyCurrenciesListLoadingAction = () => ({
  type: MY_CURRENCIES_LIST_LOADING,
});

const MyCurrenciesListSuccessAction = (items: any[]) => ({
  type: MY_CURRENCIES_LIST_SUCCESS,
  items,
});

const MyCurrenciesListErrorAction = (error: any) => ({
  type: MY_CURRENCIES_LIST_ERROR,
  error,
});
