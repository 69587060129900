import { ProfilePopover } from "../profile-popover";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationStore } from "../../store/rootReducer";
import { getProfile } from "../../selectors/profile";
import { AboutPopup } from "../AboutPopup";
import ViewProfilePopup from "../ViewProfilePopup";
import { f7 } from "framework7-react";
import InviteFriendPopUp from "../InviteFriendPopUp";
import { ForgotPasswordPopup } from "../ForgotPassword";
import { ContactSupportPopup } from "../ContactSupportPopup";
import { RegisterDesktopPopup } from "../RegisterDesktop";
import { ShortRegistrationPopup } from "../ShortRegistrationPopup";
import LoginDesktopPopup from "../LoginDesktop/LoginDesktopPopup";
import { analytics } from "../../Setup";
import { logoutUser } from "../../actions/sessionActions";
import { Router } from "framework7/modules/router/router";
import { ProfileLink } from "../ProfileLink";

const simplifiedRegistration = process.env.SIMPLIFIED_REGISTRATION === "true";

const ProfileMenu = ({ f7router, showLogin }: { f7router: Router.Router; showLogin: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [invitePopupOpened, setInvitePopupOpened] = useState(false);
  const [profilePopoverOpened, setProfilePopoverOpened] = useState(false);
  const [viewProfilePopupOpened, setViewProfilePopupOpened] = useState(false);
  const [aboutPopupOpened, setAboutPopupOpened] = useState(false);
  const [contactSupportPopupOpened, setContactSupportPopupOpened] = useState(false);
  const [forgotPasswordPopupOpened, setForgotPasswordPopupOpened] = useState(false);
  const [loginPopupOpened, setLoginPopupOpened] = useState(false);
  const [registerPopupOpened, setRegisterPopupOpened] = useState(false);
  const [shortRegisterPopupOpened, setShortRegisterPopupOpened] = useState(false);

  const [referral, setReferral] = useState("");

  useEffect(() => {
    if (!f7router) return;
    const action = f7router.currentRoute.query["action"] || null;
    if (action === "register" && !registerPopupOpened) {
      if (f7router.currentRoute.query["referral"]) {
        setReferral(f7router.currentRoute.query["referral"]);
      }
      simplifiedRegistration ? setShortRegisterPopupOpened(true) : setRegisterPopupOpened(true);
    }
  }, [f7router]);

  useEffect(() => {
    showLogin && setLoginPopupOpened(true);
  }, [showLogin]);

  const profile = useSelector((state: IApplicationStore) => getProfile(state));

  const onClickProfileLink = () => {
    profile && profile.uid ? setProfilePopoverOpened(true) : setLoginPopupOpened(true);
  };

  const handleRegisterButton = () => {
    simplifiedRegistration ? setShortRegisterPopupOpened(true) : setRegisterPopupOpened(true);
    analytics.openRegistrationForm();
  };

  return (
    <>
      <ProfileLink key="profile_link" profile={profile} onClick={onClickProfileLink} href="#" />
      <ProfilePopover
        profile={profile}
        backdrop={false}
        opened={profilePopoverOpened}
        target=".profile-link"
        onPopoverClosed={() => setProfilePopoverOpened(false)}
        t={t}
        slot="fixed"
        onLogout={() => {
          f7.preloader.show();
          dispatch(logoutUser());
        }}
        onInviteClick={() => {
          setInvitePopupOpened(true);
          setProfilePopoverOpened(false);
        }}
        onAboutClick={() => {
          setAboutPopupOpened(true);
        }}
        onViewClick={() => {
          setViewProfilePopupOpened(true);
          setProfilePopoverOpened(false);
        }}
      />
      <AboutPopup
        profile={profile}
        backdrop={false}
        opened={aboutPopupOpened}
        onPopupClosed={() => setAboutPopupOpened(false)}
        onContactSupportClick={() => setContactSupportPopupOpened(true)}
      />
      <ViewProfilePopup
        profile={profile}
        opened={viewProfilePopupOpened}
        f7={f7}
        onPopupClosed={() => setViewProfilePopupOpened(false)}
      />
      <InviteFriendPopUp backdrop={false} opened={invitePopupOpened} onPopupClosed={() => setInvitePopupOpened(true)} />
      <ForgotPasswordPopup
        profile={profile}
        backdrop={false}
        opened={forgotPasswordPopupOpened}
        onPopupClosed={() => setForgotPasswordPopupOpened(true)}
      />
      <ContactSupportPopup
        profile={profile}
        category="Application"
        backdrop={false}
        opened={contactSupportPopupOpened}
        onPopupClosed={() => setContactSupportPopupOpened(false)}
      />
      <LoginDesktopPopup
        f7router={f7router}
        opened={loginPopupOpened}
        onPopupClosed={() => setLoginPopupOpened(false)}
        slot="fixed"
        onRegister={() => {
          setLoginPopupOpened(false);
          handleRegisterButton();
        }}
        onForgotPassword={() => {
          setLoginPopupOpened(false);
          setForgotPasswordPopupOpened(true);
        }}
      />
      {!simplifiedRegistration && (
        <RegisterDesktopPopup
          opened={registerPopupOpened}
          onPopupClosed={() => setRegisterPopupOpened(false)}
          t={t}
          slot="fixed"
        />
      )}
      {simplifiedRegistration && (
        <ShortRegistrationPopup
          inviteCode={referral}
          opened={shortRegisterPopupOpened}
          onPopupClosed={() => setShortRegisterPopupOpened(false)}
          t={t}
          slot="fixed"
        />
      )}
    </>
  );
};

export default ProfileMenu;
