import { AnyAction } from "redux";
import {
  CART_ADD,
  CART_CLEAR,
  CART_REMOVE,
  CART_REMOVE_BY_SELLER,
  CART_UPDATE,
  SET_DELIVERY,
  SET_DELIVERY_INFO,
  SET_DELIVERY_PRICE,
} from "../actions/cartActions";
import { IProduct } from "./productReducer";
import BigNumber from "bignumber.js";

export interface DeliveryMethod {
  code: string;
  price: number;
}

export interface Items {
  product: IProduct;
  price: number;
  count: number;
  discount: number;
  total: number;
  deliveryCountryCode?: string;
  deliveryCode?: string;
}

export interface ICartState {
  isFetching: boolean;
  isUpdating: boolean;
  error?: any;
  items: Items[];
  delivery?: DeliveryMethod;
  itemsTotal: number;
  discount: number;
  total: number;
}

const initialState: ICartState = {
  isFetching: false,
  isUpdating: false,
  items: [],
  delivery: null,
  itemsTotal: 0,
  discount: 0,
  total: 0,
};

const refreshCartState = (cartState: ICartState): ICartState => {
  const items = cartState.items.map((item) => {
    return {
      ...item,
      total: new BigNumber(item.count).multipliedBy(item.price).minus(item.discount).toNumber(),
    };
  });

  if (cartState.items.length === 0) {
    cartState.delivery = null;
  }

  const itemsTotal = items.map((item) => item.total).reduce((p, c) => p + c, 0);
  const deliveryPrice = cartState.delivery ? cartState.delivery.price : 0;
  const total = new BigNumber(itemsTotal).minus(cartState.discount).plus(deliveryPrice).toNumber();

  return {
    ...cartState,
    items,
    itemsTotal,
    total,
  };
};

const getPrice = (item: IProduct) => {
  if (typeof item.discountedPrice !== "undefined" && item.discountedPrice !== 0) return item.discountedPrice;
  return item.price;
};

const cartReducer = (state = initialState, action: AnyAction): ICartState => {
  switch (action.type) {
    case CART_ADD: {
      const { item, count } = action;

      const foundItem = state.items.find((_item) => {
        if (typeof item.productproductParams !== "undefined") {
          return _item.product.uid === item.uid && _item.product.productParams[0].value === item.productParams[0].value;
        } else {
          return _item.product.uid === item.uid;
        }
      });

      if (foundItem) {
        foundItem.count += typeof count !== "undefined" ? count : 1;
        // TODO: Update for saving params
        foundItem.product.productParams = item.productParams;
      } else {
        state.items.push({
          product: item,
          count,
          price: getPrice(item),
          discount: 0,
          total: 0,
        });
      }

      return {
        ...refreshCartState(state),
      };
    }
    case CART_UPDATE: {
      const { item, count } = action;

      const foundItem = state.items.filter((_item) => _item.product.uid === item.uid)[0];

      if (foundItem) {
        foundItem.count = typeof count !== "undefined" ? count : 1;
      } else {
        state.items.push({
          product: item,
          count,
          price: getPrice(item),
          discount: 0,
          total: 0,
        });
      }

      return {
        ...refreshCartState(state),
      };
    }
    case CART_REMOVE: {
      const { item, count } = action;

      const foundItem = state.items.filter((_item) => _item.product.uid === item.uid)[0];

      if (foundItem && typeof count !== "undefined" && foundItem.count > count) {
        foundItem.count -= count;
      } else {
        state.items = state.items.filter((_item) => _item.product.uid !== item.uid);
      }

      return {
        ...refreshCartState(state),
      };
    }
    case CART_REMOVE_BY_SELLER: {
      const { seller } = action;
      state.items = state.items.filter((item) => item.product.sellerUid !== seller);
      return {
        ...refreshCartState(state),
      };
    }
    case CART_CLEAR: {
      state.items = [];
      state.delivery = null;
      return {
        ...refreshCartState(state),
      };
    }
    case SET_DELIVERY_INFO: {
      const { delivery } = action;
      //  const ownerItems = state.items.filter((item) => item.product.ownerUid === ownerUid);

      // ownerItems.forEach((item) => {
      //   item.deliveryCode = deliveryCode;
      // });

      state.delivery = delivery;

      return {
        ...refreshCartState(state),
      };
    }
    case SET_DELIVERY_PRICE: {
      state.delivery.price = action.deliveryPrice;

      return {
        ...refreshCartState(state),
      };
    }

    default:
      return state;
  }
};

export default cartReducer;
