import React, { Component } from "react";
import {
  Page,
  Navbar,
  Block,
  Preloader,
  BlockTitle,
  Fab,
  Icon,
} from "framework7-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { IApplicationStore } from "../store/rootReducer";
import { loadMyGoodsList } from "../actions/myGoodsActions";
import { IProduct } from "../reducers/productReducer";
import { connect } from "react-redux";
import { Catalog } from "../components/Catalog/index";
import { Button } from "../components/ThemedButton";
import classNames from "classnames";
import { deleteProduct } from "../actions/productCreateActions";
import connectProductStatus, {
  IProductStatusProps,
} from "../store/connectProductStatus";
import "./my-goods.less";

type Props = IProductStatusProps &
  WithTranslation & {
    loading?: boolean;
    error?: any;
    products?: IProduct[];
    hasProducts?: boolean;
    loadMyGoodsList?(): void;
    deleteProduct?(uid: string): void;
  };

type State = {
  chooseItemStatusActions?: boolean;
  item?: IProduct;
};

class MyGoodsPage extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      chooseItemStatusActions: false,
    };
  }

  pageInitHandle = () => {
    // always update list on page loading
    if (!this.props.products.length) {
      this.props.loadMyGoodsList();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { loading, error, hasProducts } = this.props;
    if (error && error !== prevProps.error) {
      this.$f7.dialog.alert(error);
    }

    if (!loading && prevProps.loading && this.doneCallback) {
      this.doneCallback();
      this.doneCallback = null;
    }

    if (hasProducts && !prevProps.hasProducts) {
      const ptr = this.$f7.ptr.create("#my_goods .ptr-content");
      ptr.on("refresh", (el, done) => this.refreshHandle(done));
    }

    this.handleChangeProductStatus(prevProps);
  }

  handleChangeProductStatus = (prevProps: Props) => {
    const {
      productStatusReducer: { loading, error, item, action },
      t,
    } = this.props;
    if (error && error !== prevProps.productStatusReducer.error) {
      this.$f7.dialog.alert(t(error));
    }

    if (loading) this.$f7.preloader.show();
    else this.$f7.preloader.hide();

    if (
      item &&
      item !== prevProps.productStatusReducer.item &&
      !loading &&
      !error
    ) {
      let message: string = "";
      switch (action) {
        case "changeQuantity":
          message = t(`Quantity changed to`, { quantity: item.quantity });
          break;
        case "changeStatus":
          message = t(`Status changed`);
          break;
      }
      this.$f7.dialog.alert(message);
    }
  };

  doneCallback: any;

  refreshHandle = (done: () => void) => {
    this.doneCallback = done;
    this.props.loadMyGoodsList();
  };

  selectProductHandle = (uid: string) => {
    this.$f7router.navigate(`product-details/${uid}/`);
  };

  renderProductsList = () => {
    const { loading, products, hasProducts, t } = this.props;

    if (loading && !this.doneCallback)
      return (
        <Block className="text-align-center">
          <Preloader />
        </Block>
      );

    if (!hasProducts) {
      return (
        <Block className="text-align-center">
          <BlockTitle medium>{t("No Products for sale")}</BlockTitle>
          <p>{t("NewProductMessage")}</p>
          <Block>
            <Button href="add/1/" fill large round raised>
              {t("Add Product")}
            </Button>
          </Block>
        </Block>
      );
    }

    return (
      <Catalog
        items={products}
        onClick={this.selectProductHandle}
        simplePrice
        showStatus
        showAnalytics
      />
    );
  };

  render() {
    const { loading, hasProducts, t } = this.props;
    return (
      <Page
        id="my_goods"
        name="my-goods"
        onPageInit={this.pageInitHandle}
        ptr={hasProducts}
        onPtrRefresh={this.refreshHandle}
        className={classNames(!hasProducts && "empty")}
      >
        <Navbar
          title={t("My products")}
          backLink={t("Back").toString()}
          noHairline
          noShadow
        />

        {this.renderProductsList()}

        {!loading && hasProducts && (
          <Fab href="add/1/" position="right-bottom" slot="fixed">
            <Icon ios="f7:plus" md="material:add" />
          </Fab>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  ...state.myGoodsReducer,
  hasProducts:
    state.myGoodsReducer.products !== null &&
    state.myGoodsReducer.products.length > 0,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadMyGoodsList: () => dispatch(loadMyGoodsList()),
  deleteProduct: (uid: string) => dispatch(deleteProduct(uid)),
});

export default compose(
  withTranslation(),
  connectProductStatus,
  connect(mapStateToProps, mapDispatchToProps)
)(MyGoodsPage);
