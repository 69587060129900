import { RouteParameters } from "../../routes";
import CartPage from "./cart";
import CheckoutPage from "./checkout";
import CheckoutNew from "./checkoutNew";
import ItemList from "./checkout/ItemList";

const routes: RouteParameters[] = [
  {
    path: "/cart/",
    component: CartPage,
    routes: [
      {
        path: "/checkout/",
        component: CheckoutNew,
      },
    ],
  },
];

export default routes;
