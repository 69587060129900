import React, { FC, useEffect, useState } from "react";
import {
  Block,
  BlockTitle,
  Col,
  f7,
  Icon,
  Link,
  List,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  PageContent,
  Popup,
  Preloader,
  Row,
  Searchbar,
  theme,
} from "framework7-react";
import { useTranslation } from "react-i18next";
import { IApplicationStore, ResizeEvent } from "../../store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import "./style.less";
import { Sheet } from "../../components/Sheet";
import { AddCardListItem } from "../../components/AddCardListItem";
import { ProductDelivery } from "../../types/marketplaceapi";
import PaymentCardCreatePage from "../payment-card-create";
import { IClassificator } from "../../reducers/classificatorReducer";
import { CalculatedDelivery, PaymentMethods } from "../../reducers/checkoutReducer";
import {
  calculateAmountToPay,
  calculateDelivery,
  selectDeliveryAddress,
  selectDeliveryDate,
  selectPaymentAddress,
  selectPaymentMethod,
} from "../../actions/checkoutActions";
import classNames from "classnames";
import { Address, Card, Country, LinkedCard } from "../../types/commonapi";
import { Profile } from "../../reducers/sessionReducer";
import { SavedCard } from "../../reducers/paymentCardsReducer";
import {
  loadLinkedPaymentCardsList,
  loadPaymentCardsList,
  savePaymentCardTemporary,
} from "../../actions/paymentCardsActions";
import { formatPrice } from "../../utils";
import CongratulationsPage from "./congratulations";
import FailedPage from "./payment-failed";
import { IOrdersState } from "../../reducers/ordersReducer";
import { IProduct } from "../../reducers/productReducer";
import { Button } from "../../components/ThemedButton";
import DeliveryInfoEditPopup from "./delivery-info-edit__popup";
import DeliveryInfoChoosePopup from "./delivery-info-choose__popup";
import { analytics } from "../../Setup";
import { getProfile } from "../../selectors/profile";
import { setDeliveryPrice } from "../../actions/cartActions";
import ItemList from "./checkout/ItemList";
import CardList from "./checkout/CardList";
import { ICardsProps } from "../../store/connectCards";
import { ICartProps } from "../../store/connectCart";
import { AccountAddressState, IAccountAddressProps } from "../../store/connectAccountAddress";
import DeliveryInfoEditForm, { DeliveryErrorMessages } from "../../components/DeliveryInfoEditForm";
import moment from "moment";
import { DeliveryAddress } from "../../components/ThirdParty/ThirdPartyOrder";
import {
  createProductsPurchaseOrder,
  orderClearAction,
  orderClearErrorMessageAction,
} from "../../actions/ordersActions";
import { DeliveryMethod, Items } from "../../reducers/cartReducer";
import logger from "redux-logger";
import { loadMyCurrencies } from "../../actions/myCurrenciesActions";
import Dom7 from "dom7";
import _ from "lodash";

const config = process.env.CONFIG;

type CheckoutProps = Page.Props &
  ICardsProps &
  ICartProps &
  IOrdersState &
  IAccountAddressProps & {
    resizeEvent?: ResizeEvent;
    paymentMethods: IClassificator[];
    selectedPaymentMethod: PaymentMethods;
    selectPaymentMethod?(method: PaymentMethods, paymentCard?: Card): void;
    selectedPaymentCard?: Card;
    selectDeliveryAddress?(address: Address): void;
    selectedDeliveryAddress?: Address;
    profile?: Profile;
    saveCard?(card: SavedCard): () => void;
    createProductsPurchaseOrder?(
      paymentMethod: PaymentMethods,
      deliveryAddress: Address,
      products: {
        product: IProduct;
        count: number;
      }[],
      options: {
        cardUid?: string;
        cvc?: string;
      },
      savedCard?: SavedCard,
      dueDate?: string,
      paymentAddress?: Address
    ): () => void;
    countryClassificator: Country[];
    selectDeliveryDate?(date: string): void;
    selectedDeliveryDate?: string;
    calculateDelivery?(): void;
    calculatedDelivery?: CalculatedDelivery;
    calculateDeliveryError?: any;
    calculateDeliveryLoading?: boolean;
    selectDeliveryMethod?(delivery: ProductDelivery): void;
    selectedDeliveryMethod?: ProductDelivery;
    selectPaymentAddress?(address: Address): void;
    selectedPaymentAddress?: Address;
    deliveryCountries: IClassificator[];
  };

const CheckoutNew: FC<CheckoutProps> = ({ f7router }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //change on cardList (set)
  const [addPaymentMethodSheetOpened, setAddPaymentMethodSheetOpened] = useState<boolean>();
  const [paymentCardCreateOpened, setPaymentCardCreateOpened] = useState<boolean>();

  const [congratulationsPageOpened, setCongratulationsPageOpened] = useState<boolean>(false);
  const [failedPageOpened, setFailedPageOpened] = useState<boolean>();
  const [deliveryInfoEditOpened, setDeliveryInfoEditOpened] = useState<boolean>();
  const [chooseCountryPopupOpened, setChooseCountryPopupOpened] = useState<boolean>();
  const [country, setCountry] = useState<Country>();
  const [countryChoosed, setCountryChoosed] = useState<boolean>();
  const [deliveryInfoEditResetState, setDeliveryInfoEditResetState] = useState<boolean>();
  const [deliveryInfoChooseOpened, setDeliveryInfoChooseOpened] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>({
    city: "",
    countryCode: "",
    firstAddressLine: "",
    postalCode: "",
    dueDate: "",
  });
  const [address, setAddress] = useState<Address>({ postalCode: "N/A" });
  const [dueDate, setDueDate] = useState<string>();
  const [deliveryPriceCalculated, setDeliveryPriceCalculated] = useState<boolean>(false);
  const [deliveryErrorMessages, setDeliveryErrorMessages] = useState<DeliveryErrorMessages>({
    city: "",
    countryCode: "",
    firstAddressLine: "",
    postalCode: "",
    dueDate: "",
  });
  const [disableDeliveryCalculateButton, setDisableDeliveryCalculateButton] = useState(true);
  const [hideDeliveryPrice, setHideDeliveryPrice] = useState(true);

  //redux functional
  //selectors

  const profile: Profile = useSelector((state: IApplicationStore) => getProfile(state));
  const deliveryCountries = useSelector(
    (state: IApplicationStore) => state.classificatorReducer.entitiesClassificators.Delivery_Country
  );
  const paymentMethods = useSelector(
    (state: IApplicationStore) => state.classificatorReducer.entitiesClassificators.Payment_Options
  );
  const accountAddressState: AccountAddressState = useSelector((state: IApplicationStore) => ({
    ...state.profileReducer,
    addresses:
      state.sessionReducer.profile && state.sessionReducer.profile.addresses
        ? state.sessionReducer.profile.addresses
        : [],
  }));
  const { cards, loading } = useSelector((state: IApplicationStore) => state.paymentCardsReducer);
  const {
    calculateDeliveryError,
    selectedPaymentCard,
    selectedPaymentMethod,
    calculatedDelivery: checkoutDelivery,
    selectedDeliveryAddress,
    selectedPaymentAddress,
    bonusMoney,
    amountToPay,
  }: {
    calculateDeliveryError?: any;
    selectedPaymentCard?: LinkedCard | Card;
    selectedPaymentMethod?: PaymentMethods;
    calculatedDelivery?: CalculatedDelivery;
    selectedDeliveryAddress?: Address;
    selectedPaymentAddress?: Address;
    bonusMoney?: any;
    amountToPay?: any;
  } = useSelector((state: IApplicationStore) => state.checkoutReducer);
  const {
    delivery,
    itemsTotal: itemsTotalCart,
    total: totalCart,
    items: itemsCart,
  }: { delivery?: DeliveryMethod; itemsTotal: number; total: number; items: Items[] } = useSelector(
    (state: IApplicationStore) => state.cartReducer
  );

  const addresses = useSelector((state: IApplicationStore) => state.sessionReducer.profile?.addresses || []);
  const { resizeEvent } = useSelector((state: IApplicationStore) => state.rootReducer);
  const { countryClassificator } = useSelector((state: IApplicationStore) => state.classificatorReducer);
  const { order, orderCreating, orderCreatingError } = useSelector((state: IApplicationStore) => state.ordersReducer);

  const currencyCode = itemsCart.length ? itemsCart[0].product.currencyCode : "";
  const isSmallScreen = !resizeEvent.isLG && !resizeEvent.isMD && !resizeEvent.isXL;

  const isDeliveryAvailable = () => itemsCart.every((item) => item.product.type == "S");

  const createOrder = (
    paymentMethod: PaymentMethods,
    deliveryAddress: Address,
    products: {
      product: IProduct;
      count: number;
    }[],
    options: {
      cardUid?: string;
      cvc?: string;
    },
    savedCard?: LinkedCard | SavedCard,
    dueDate?: string,
    paymentAddress?: Address,
    linkedCard?: boolean
  ) => {
    dispatch(
      createProductsPurchaseOrder(
        paymentMethod,
        deliveryAddress,
        products,
        options,
        savedCard,
        dueDate,
        paymentAddress,
        linkedCard
      )
    );
  };

  const getDefaultDeliveryCountry = () => {
    if (config !== "marine" || deliveryCountries.length === 0) {
      return null;
    }
    const country = deliveryCountries[0];
    return { code: country.code, name: country.value };
  };

  const getDeliveryPrice = () => delivery?.price || 0;

  const pageInitHandle = () => {
    setDeliveryPriceCalculated(false);
    if (!accountAddressState.addresses.length) {
      setDeliveryInfoEditOpened(false);
      getDeliveryPrice();
    }

    dispatch(loadLinkedPaymentCardsList());

    setDeliveryInfoEditResetState(true);

    setCountry(getDefaultDeliveryCountry());

    if (isDeliveryAvailable()) {
      setHideDeliveryPrice(false);
      //setDeliveryPriceCalculated(true);
    }
    // analytics.openCheckout(profile);
    return;
  };

  // // //componentDidUpdate
  useEffect(() => {
    setDisableDeliveryCalculateButton(
      !address?.country ||
        !address?.city ||
        !address?.firstAddressLine ||
        !dueDate ||
        Object.values(deliveryErrorMessages).filter(Boolean).length > 0
    );
  }, [address, dueDate, deliveryErrorMessages]);

  useEffect(() => {
    dispatch(loadLinkedPaymentCardsList);
  }, []);

  useEffect(() => {
    dispatch(setDeliveryPrice(checkoutDelivery.shippingPrice));
  }, [checkoutDelivery]);
  useEffect(() => {
    if (calculateDeliveryError) {
      f7.dialog.alert(calculateDeliveryError);
      setHideDeliveryPrice(true);
    }
  }, [calculateDeliveryError]);

  useEffect(() => {
    if (failedPageOpened) {
      f7.preloader.hide();
      analytics.paymentAttempt(profile);
    }
  }, [failedPageOpened]);

  useEffect(() => {
    if (orderCreating) {
      f7.preloader.show();
    } else {
      f7.preloader.hide();
    }
  }, [orderCreating]);

  useEffect(() => {
    if (orderCreatingError) {
      f7.preloader.hide();
      const test = f7.dialog.alert(orderCreatingError);
      test.on("close", () => {
        dispatch(orderClearErrorMessageAction());
      });
    }
  }, [orderCreatingError]);

  useEffect(() => {
    if (order && order.uid !== null) {
      setCongratulationsPageOpened(true);
      dispatch(orderClearAction());
    }
  }, [order]);

  useEffect(() => {
    dispatch(loadMyCurrencies());
  }, [itemsCart]);

  const isItemsAllowCashDelivery = () => {
    const itemsHasTypeS = !!itemsCart.filter((item) => item.product.type === "S").length;
    const cash = paymentMethods.filter((m) => m.code === "cod");
    return !itemsHasTypeS && cash.length > 0;
  };
  const renderCardsList = () => {
    return (
      <List noHairlines noChevron>
        <ul>
          {isItemsAllowCashDelivery() && (
            <ListItem
              link="#"
              title={t("Cash on delivery").toString()}
              onClick={() => {
                setAddPaymentMethodSheetOpened(true);
                dispatch(selectPaymentMethod("cash"));
              }}
            >
              <Icon slot="media" material="account_balance_wallet" />
            </ListItem>
          )}

          {loading && (
            <ListItem className="cards-loading-item">
              <Preloader size={18} />
            </ListItem>
          )}
          {cards.map((item) => (
            <ListItem
              key={item.uid}
              link
              title={item.maskedNumber}
              onClick={() => {
                setAddPaymentMethodSheetOpened(false);
                setTimeout(() => dispatch(selectPaymentMethod("bankcard", item)), 380);
              }}
              noChevron
            >
              <span slot="media">
                <i className={classNames("icon", `ic-${item.type && item.type.toLowerCase()}`)} />
              </span>
              <span slot="footer">
                {item.expMonth}/{item.expYear}
              </span>
              {selectedPaymentCard && selectedPaymentCard.uid === item.uid && (
                <div slot="after">
                  <Icon f7="checkmark_alt" />
                </div>
              )}
            </ListItem>
          ))}
          <AddCardListItem
            title={t("Add card").toString()}
            noChevron
            onClick={() => {
              setAddPaymentMethodSheetOpened(false);
              setTimeout(() => setPaymentCardCreateOpened(true), 380);
            }}
          />
        </ul>
      </List>
    );
  };

  const enterCVC = async (): Promise<string> => {
    f7.preloader.hide();
    return new Promise((resolve) => {
      const dialog = f7.dialog.prompt(
        t("Please, enter CVC/CVV"),
        (val: string) => {
          resolve(val);
        },
        () => resolve()
      );

      const el = dialog.$el
        .find("input")
        .attr("type", "number")
        .attr("minlength", "2")
        .attr("maxlength", "3")[0] as any;

      /* TODO */
      el.addEventListener("keypress", function (ev: { preventDefault: () => void }) {
        if (el.value.length === el.maxLength) {
          ev.preventDefault();
          return false;
        }
        return true;
      });
      el.select();
    });
  };

  const handleCalculateDeliveryClick = async () => {
    f7.preloader.show();
    address && (await dispatch(selectDeliveryAddress(address)));
    await dispatch(selectDeliveryDate(dueDate));
    await dispatch(calculateDelivery());
    setHideDeliveryPrice(false);
    await dispatch(calculateAmountToPay());
    setDeliveryPriceCalculated(true);
  };

  const handleConfirmCheckoutClick = async () => {
    if (!selectedPaymentMethod) {
      f7.preloader.hide();
      f7.dialog.alert(t("Add payment method"));
      return;
    }

    if (!isDeliveryAvailable() && delivery && !selectedDeliveryAddress) {
      f7.preloader.hide();
      f7.dialog.alert(t("Add delivery address"));
      return;
    }

    if (selectedPaymentMethod === "bankcard" && selectedPaymentCard && amountToPay) {
      if (selectedPaymentCard.uid.includes("temporary")) {
        createOrder(
          selectedPaymentMethod,
          selectedDeliveryAddress,
          itemsCart.map((item) => ({ product: item.product, count: item.count })),
          {},
          selectedPaymentCard,
          dueDate,
          selectedPaymentAddress,
          false
        );
      } else {
        const cvc = await enterCVC();
        if (cvc) {
          const selectedPaymentCardModified = { ...selectedPaymentCard, uid: selectedPaymentCard.creditCardUid };
          createOrder(
            selectedPaymentMethod,
            selectedDeliveryAddress,
            itemsCart.map((item) => ({ product: item.product, count: item.count })),
            {
              cardUid: selectedPaymentCard.creditCardUid,
              cvc,
            },
            selectedPaymentCardModified,
            dueDate,
            selectedPaymentCardModified.address,
            true
          );
        }
      }
    } else {
      createOrder(
        selectedPaymentMethod,
        selectedDeliveryAddress,
        itemsCart.map((item) => ({ product: item.product, count: item.count })),
        {},
        null,
        dueDate,
        null,
        false
      );
    }
  };

  const handleSaveCard = (card: LinkedCard | SavedCard) => {
    setPaymentCardCreateOpened(false);
    dispatch(savePaymentCardTemporary(card));
    dispatch(selectPaymentMethod("bankcard", card));
    if (card.address) {
      dispatch(selectPaymentAddress(card.address));
    }
  };

  const onDeliveryChange = (fieldName, value) => {
    console.log("onDeliveryChange", fieldName, value);
    setDeliveryErrorMessages({ ...deliveryErrorMessages, [fieldName]: "" });
    if (!value && fieldName !== "postalCode") {
      setDeliveryErrorMessages({
        ...deliveryErrorMessages,
        [fieldName]: "Please fill out this field.",
      });
    }
    if (fieldName === "dueDate") {
      const isDateValid = moment(value).toDate() - moment().startOf("day").add(1, "days").toDate() >= 0;
      if (isDateValid) {
        const newDate = moment(value).startOf("hour").format("YYYYMMDD000000");
        setDeliveryAddress({
          ...deliveryAddress,
          [fieldName]: newDate,
        });
        setDueDate(newDate);
      } else {
        setDeliveryErrorMessages({
          ...deliveryErrorMessages,
          [fieldName]: "Due date must be greater or equal to the current date",
        });
      }
    } else if (["countryCode", "city", "postalCode", "firstAddressLine"].includes(fieldName)) {
      if (fieldName === "firstAddressLine") {
        value = (value as string)
          .trim()
          .split(" ")
          .filter(Boolean)
          .map((el) => _.upperFirst(el))
          .join(" ");
      }
      setDeliveryAddress({
        ...deliveryAddress,
        [fieldName]: value,
      });
      setAddress({
        ...address,
        [fieldName]: value,
      });
      if (fieldName == "countryCode") {
        const { code, name } = countryClassificator.filter((item) => item.code === value)[0];
        setAddress({
          ...address,
          country: { code, name },
        });
      }
    }
  };

  const renderDeliveryInfoListWeb = () => {
    return (
      <List mediaList noChevron noHairlines noHairlinesBetween className="pure-hidden-xs">
        {addresses.map((item) => {
          return (
            <ListItem
              key={item.uid}
              radio
              name="delivery-media-radio"
              value={item.uid}
              title={item.firstAddressLine}
              onClick={() => {
                dispatch(selectDeliveryAddress(item));
              }}
              checked={item.uid === (selectedDeliveryAddress ? selectedDeliveryAddress.uid : null)}
            >
              <div slot="text">
                {item.country.name}, {item.city} <br />
                {item.firstAddressLine} <br />
                {item.postalCode}
              </div>
            </ListItem>
          );
        })}
        <ListItem
          className="item-add-link"
          link
          title={t("Add delivery Information").toString()}
          noChevron
          onClick={() => {
            setDeliveryInfoEditOpened(true);
          }}
        >
          <span slot="media">
            <Icon ios="f7:plus" md="material:add" />
          </span>
        </ListItem>
      </List>
    );
  };

  return (
    <Page id="checkout_page" name="checkout-page" onPageInit={pageInitHandle}>
      <Navbar title={t("Checkout")} backLink={t("Back").toString()} noHairline noShadow />
      <Row>
        <Col width="100" medium="70">
          {!isDeliveryAvailable() && (
            <>
              <BlockTitle medium>
                <span>{t("Delivery information")}</span>
                {config !== "marine" && (
                  <Link
                    href="#"
                    onClick={() => {
                      setDeliveryInfoChooseOpened(true);
                    }}
                    className="pure-visible-xs"
                  >
                    <Icon material="create" />
                  </Link>
                )}
              </BlockTitle>
              {config !== "marine" && (
                <>
                  {selectedDeliveryAddress && (
                    <Block className="pure-visible-xs">
                      {selectedDeliveryAddress.country.name}, {selectedDeliveryAddress.city}
                      <br />
                      {selectedDeliveryAddress.firstAddressLine}
                    </Block>
                  )}
                  {renderDeliveryInfoListWeb()}
                  <Block className="no-margin-vertical">
                    <hr />
                  </Block>
                </>
              )}
              {config === "marine" && (
                <>
                  <DeliveryInfoEditForm
                    onChange={onDeliveryChange}
                    address={deliveryAddress}
                    errors={deliveryErrorMessages}
                  />
                  <Block className="no-margin-vertical" noHairlines>
                    <Button
                      round
                      large
                      fill
                      onClick={handleCalculateDeliveryClick}
                      disabled={disableDeliveryCalculateButton}
                      className="calculate-delivery-button"
                    >
                      <span>{t("Calculate")}</span>
                    </Button>
                  </Block>
                  {isSmallScreen && (
                    <Block className="no-margin-vertical">
                      <hr />
                    </Block>
                  )}
                </>
              )}
            </>
          )}
          {/*CARD LIST*/}
          <CardList />
          {isDeliveryAvailable() && (
            <Block className="no-margin-vertical" noHairlines>
              <Button round large fill onClick={handleCalculateDeliveryClick} className="calculate-delivery-button">
                <span>{t("Calculate")}</span>
              </Button>
            </Block>
          )}
          <Block className="no-margin-vertical">
            <hr />
          </Block>
          {!resizeEvent?.isXS && (
            <BlockTitle medium>
              <span>{t("Order list")}</span>
            </BlockTitle>
          )}
          {!resizeEvent?.isXS && <ItemList showButton={false} />}
          <BlockTitle medium className="pure-visible-xs">
            {t("Order summary")}
          </BlockTitle>
          <List className="order-summary pure-visible-xs" noHairlines noChevron noHairlinesBetween>
            <ListItem title={t("Items") + ` (${itemsCart.length})`} after={formatPrice(itemsTotalCart, currencyCode)} />
            <ListItem
              title={t("Delivery").toString()}
              after={hideDeliveryPrice ? "?" : formatPrice(getDeliveryPrice(), currencyCode)}
            />
            <ListItem
              title={t("Total").toString()}
              after={hideDeliveryPrice ? "?" : formatPrice(totalCart, currencyCode)}
              className="total"
            />
            {deliveryPriceCalculated && checkoutDelivery.bonusMoneyAmountForOrder && (
              <ListItem title={t("Bonus Money").toString()} after={checkoutDelivery.bonusMoneyAmountForOrder} />
            )}
            {deliveryPriceCalculated && bonusMoney && (
              <ListItem
                title={t("Amount to Pay").toString()}
                after={formatPrice(amountToPay, currencyCode)}
                className="total"
              />
            )}
          </List>
        </Col>
        <Col width="100" medium="30" className="pure-hidden-xs" style={{ position: "relative" }}>
          <div className="order-summary-container">
            <BlockTitle medium>{t("Order summary")}</BlockTitle>
            <List className="order-summary" noHairlines noChevron noHairlinesBetween>
              <ListItem
                title={t("Items") + ` (${itemsCart.length})`}
                after={formatPrice(itemsTotalCart, currencyCode)}
              />
              <ListItem
                title={t("Delivery").toString()}
                after={hideDeliveryPrice ? "?" : formatPrice(getDeliveryPrice(), currencyCode)}
              />
              <ListItem
                title={t("Total").toString()}
                after={hideDeliveryPrice ? "?" : formatPrice(totalCart, currencyCode)}
                className="total"
              />
              {deliveryPriceCalculated && checkoutDelivery.bonusMoneyAmountForOrder && (
                <ListItem title={t("Bonus Money").toString()} after={checkoutDelivery.bonusMoneyAmountForOrder} />
              )}
              {deliveryPriceCalculated && bonusMoney && (
                <ListItem
                  title={t("Amount to Pay").toString()}
                  after={formatPrice(amountToPay, currencyCode)}
                  className="total"
                />
              )}
            </List>
            <Block>
              {/* TODO move to component */}
              <Button
                round
                large
                fill
                onClick={async () => await handleConfirmCheckoutClick()}
                disabled={!deliveryPriceCalculated}
              >
                <span>{t("Confirm and pay")}</span>
              </Button>
            </Block>
          </div>
        </Col>
      </Row>
      <Block className="pure-visible-xs confirm-and-pay-block">
        <Button
          round
          fill
          onClick={handleConfirmCheckoutClick}
          disabled={!deliveryPriceCalculated}
          className="confirm-and-pay-button"
        >
          <span>{t("Confirm and pay")}</span>
        </Button>
      </Block>
      <PaymentCardCreatePage
        opened={paymentCardCreateOpened}
        swipeToClose={isSmallScreen}
        onPopupClosed={() => {
          setPaymentCardCreateOpened(false);
        }}
        onSaveCard={handleSaveCard}
        showAddressFields={config === "marine"}
      />
      {/* TODO move to component */}
      <Sheet
        id="add_payment_method_sheet"
        swipeToClose
        backdrop
        opened={addPaymentMethodSheetOpened}
        onSheetOpened={() => {
          if (!cards || (!cards.length && profile.uid)) {
            loadPaymentCardsList();
          }
        }}
        onSheetClosed={() => setAddPaymentMethodSheetOpened(false)}
        slot="fixed"
        style={{ height: "auto" }}
      >
        <BlockTitle medium>
          <span>{t("Add payment method")}</span>
        </BlockTitle>
        {renderCardsList()}
      </Sheet>

      <CongratulationsPage
        slot="fixed"
        opened={congratulationsPageOpened}
        onPopupClosed={() => {
          setCongratulationsPageOpened(false);
          f7router.navigate({ name: "HomePage" }, { clearPreviousHistory: true, force: true, reloadAll: true });
        }}
      />
      <FailedPage slot="fixed" opened={failedPageOpened} onPopupClosed={() => setFailedPageOpened(false)} />
      <DeliveryInfoEditPopup
        opened={deliveryInfoEditOpened}
        onPopupClosed={(instance, chooseCountry) => {
          setDeliveryInfoEditOpened(false);
          setDeliveryInfoEditResetState(true);
          //country: null,
          if (chooseCountry) {
            setTimeout(() => {
              setChooseCountryPopupOpened(true);
              setCountryChoosed(false);
            }, 300);
          } else {
            if (resizeEvent.width < 768) {
              setDeliveryInfoChooseOpened(true);
            }
          }
        }}
        onAddOrUpdateInfo={(address) => console.log(address)}
        country={country}
        reset={deliveryInfoEditResetState}
      />
      <DeliveryInfoChoosePopup
        opened={deliveryInfoChooseOpened}
        onPopupClosed={(instance: any) => {
          setDeliveryInfoChooseOpened(false);
          setDeliveryInfoEditOpened(instance);
        }}
      />
      <Popup
        id="choose_country__popup"
        className="choose-country__popup"
        opened={chooseCountryPopupOpened}
        onPopupClosed={() => {
          setChooseCountryPopupOpened(false);
          if (countryChoosed) {
            setDeliveryInfoEditOpened(true);
            setDeliveryInfoEditResetState(false);
          }
        }}
      >
        <Navbar backLink={false} noHairline noShadow sliding>
          <NavLeft>
            <Link iconOnly onClick={() => f7.popup.close()}>
              <Icon className="icon-back" />
            </Link>
          </NavLeft>
          <NavTitle>{t("Choose country")}</NavTitle>
          <NavRight>
            <Link
              searchbarEnable=".searchbar-demo-1"
              iconIos="f7:search"
              iconAurora="f7:search"
              iconMd="material:search"
            />
          </NavRight>
          <Searchbar
            className="searchbar-demo-1"
            expandable
            searchContainer=".search-list"
            searchIn=".item-title"
            disableButton={!theme.aurora}
          />
        </Navbar>
        <PageContent style={{ paddingTop: 0 }}>
          <List className="searchbar-not-found" noChevron noHairlines noHairlinesBetween>
            <ListItem title={t("Nothing found").toString()} />
          </List>
          {/* TODO replace with VirtualList! */}
          <List className="search-list searchbar-found" noChevron noHairlines noHairlinesBetween>
            {countryClassificator.map((val) => (
              <ListItem
                link
                key={val.code}
                title={val.name}
                onClick={() => {
                  setCountry(val);
                  setCountryChoosed(true);
                }}
              />
            ))}
          </List>
        </PageContent>
      </Popup>
    </Page>
  );
};

export default CheckoutNew;
