import React from "react";

export default () => (
  <svg
    className="ic-wip"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6866 1.43337C11.299 0.349032 12.8607 0.349037 13.473 1.43337L16.6808 7.11382L20.8336 5.01269C22.1064 4.36874 23.5219 5.58619 23.0756 6.94096L19.7823 16.9385C19.3771 18.1687 18.2281 18.9999 16.9329 18.9999H7.22674C5.93154 18.9999 4.78258 18.1687 4.37735 16.9385L1.08404 6.94096C0.637758 5.58619 2.05328 4.36874 3.32604 5.01269L7.47885 7.11382L10.6866 1.43337Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.57984 21.9999C4.57984 21.4476 5.02756 20.9999 5.57984 20.9999H18.5798C19.1321 20.9999 19.5798 21.4476 19.5798 21.9999C19.5798 22.5522 19.1321 22.9999 18.5798 22.9999H5.57984C5.02756 22.9999 4.57984 22.5522 4.57984 21.9999Z"
    />
  </svg>
);
