import React, { Component } from "react";
import { Page, Navbar, Block, BlockTitle, List, ListItem, Toggle, Icon, NavRight, Link } from "framework7-react";
import { connect } from "react-redux";
import { compose } from "redux";
import "./profile.less";
import { logoutUser } from "../actions/sessionActions";
import { Avatar } from "../components/Avatar";
import { ContactSupportPopup } from "../components/ContactSupportPopup";
import connectProfile from "../store/connectProfile";
import { Profile } from "../reducers/sessionReducer";
import { AboutPopup } from "../components/AboutPopup";
import { getI18n, withTranslation, WithTranslation } from "react-i18next";
import { ProfileStatus } from "../components/ProfileStatus";
import connectLocalConfig from "../store/connectLocalConfig";
import { IApplicationStore, ILanguage, ILocalConfig } from "../store/rootReducer";
import { setLayoutTheme } from "../utils";
import { IcLogin, IcTransaction, IcOrders } from "../components-ui/icons";
import { i18n } from "i18next";
import ViewProfilePopup from "../components/ViewProfilePopup";
import InviteFriendPopUp from "../components/InviteFriendPopUp";

type Props = WithTranslation & {
  profile: Profile;
  localConfig?: ILocalConfig;
  logoutLoading?: boolean;
  language?: string;
  languages?: ILanguage[];
  i18n?: i18n;
  logout?(): void;
};

type State = {
  aboutPopupOpened?: boolean;
  contactSupportPopupOpened?: boolean;
  viewProfilePopupOpened?: boolean;
  invitePopupOpened?: boolean;
};

class ProfilePage extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      aboutPopupOpened: false,
      contactSupportPopupOpened: false,
      viewProfilePopupOpened: false,
      invitePopupOpened: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { logoutLoading } = this.props;
    if (!logoutLoading && prevProps.logoutLoading) {
      this.$f7router.navigate("/");
    }
  }

  changeThemeHandle = (on: boolean) => setLayoutTheme(on ? "dark" : "light");

  handleLogout = () => {
    const { logoutLoading } = this.props;
    if (!logoutLoading) {
      this.props.logout();
    }
  };

  menuItemEnabled = (item: string): boolean => {
    const { localConfig } = this.props;
    const profileMenuItems = localConfig.profileMenuItems;
    return profileMenuItems && profileMenuItems.length > 0 && profileMenuItems.includes(item);
  };

  getCurrentLanguageTitle = () => {
    const { language, languages } = this.props;
    const current = languages.filter((l) => l.code === language)[0] ?? null;
    return current ? current.title : language;
  };

  languageSmartSelectChangeHandle = (e: { target: { value: any; name?: any; type?: any } }) => {
    const { value } = e.target;
    this.props.i18n.changeLanguage(value);
    let url = location.href.replace("language-smart-select-select/", "");
    if (url.includes("language=")) {
      url = url.replace(/([?&])language=[a-z]{2}/, "");
    }
    window.location.href = url + (url.split("?")[1] ? "&" : "?") + "language=" + value;
    window.location.reload();
  };

  private appName = process.env.APP_NAME;

  render() {
    const {
      profile,
      language,
      t,
      localConfig: { settingsEnabled },
    } = this.props;

    return (
      <Page id="profile" name="profile" stacked>
        <Navbar title={t("Profile")} backLink={t("Back").toString()} noHairline noShadow>
          <NavRight>
            <Link className="view-link" href="#" onClick={() => this.setState({ viewProfilePopupOpened: true })}>
              {t("View").toString()}
            </Link>
          </NavRight>
        </Navbar>
        <Block className="avatar-container">
          <Avatar profile={profile} />
          <BlockTitle large className="profile-name">
            {profile.person?.name}
          </BlockTitle>
          {profile.accountEmails && profile.accountEmails.length ? (
            <p className="profile-field">{profile.accountEmails[0].email}</p>
          ) : null}
          {profile && <ProfileStatus profile={profile} />}
        </Block>
        <List noHairlines>
          {this.menuItemEnabled("InviteFriend") && (
            <ListItem
              link="#"
              title={t("Invite a Friend").toString()}
              onClick={() => this.setState({ invitePopupOpened: true })}
            >
              <Icon slot="media" f7="person_2_alt" />
            </ListItem>
          )}
          {this.menuItemEnabled("MyProducts") && (
            <ListItem link="my-goods/" title={t("My products").toString()}>
              <Icon slot="media" f7="list_bullet" />
            </ListItem>
          )}
          {this.menuItemEnabled("MyWishList") && (
            <ListItem link="/wish-list/" title={t("My wish list").toString()}>
              <Icon slot="media" material="favorite_border" />
            </ListItem>
          )}
          {this.menuItemEnabled("MyWallet") && (
            <ListItem link="wallet/" title={t("My wallet").toString()}>
              <Icon slot="media" material="account_balance_wallet" />
            </ListItem>
          )}
          {this.menuItemEnabled("MyOrders") && (
            <ListItem link="orders/" title={t("My Orders").toString()}>
              <div slot="media" className="display-flex justify-content-center align-content-center">
                <IcOrders />
              </div>
            </ListItem>
          )}
          {this.menuItemEnabled("Transactions") && (
            <ListItem link="transactions/" title={t("Transactions").toString()}>
              <div slot="media" className="display-flex justify-content-center align-content-center">
                <IcTransaction />
              </div>
            </ListItem>
          )}
          {this.menuItemEnabled("ProductPromotion") && (
            <ListItem link="product-promotion/" title={t("Product promotion").toString()}>
              <Icon slot="media" f7="star" />
            </ListItem>
          )}
        </List>

        {settingsEnabled && <BlockTitle>{t("Settings")}</BlockTitle>}

        <List noHairlines>
          {settingsEnabled && this.menuItemEnabled("Notifications") && (
            <ListItem title={t("Notifications").toString()}>
              <Icon slot="media" f7="bell" />
              <Toggle slot="after" />
            </ListItem>
          )}
          {settingsEnabled && this.menuItemEnabled("DarkMode") && (
            <ListItem title={t("Dark mode").toString()}>
              <Icon slot="media" f7="moon" />
              <Toggle slot="after" onToggleChange={this.changeThemeHandle} />
            </ListItem>
          )}
          {this.menuItemEnabled("Language") && (
            <ListItem title={this.getCurrentLanguageTitle()} smartSelect smartSelectParams={{ closeOnSelect: true }}>
              <Icon slot="media" material="language" />
              <select
                name="language-smart-select"
                defaultValue={language}
                onChange={this.languageSmartSelectChangeHandle}
              >
                {this.props.languages.map((lang: ILanguage) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.title}
                  </option>
                ))}
              </select>
            </ListItem>
          )}
          {this.menuItemEnabled("About") && (
            <ListItem
              link="#"
              title={t("About app", { appName: this.appName }).toString()}
              popoverClose
              onClick={() => this.setState({ aboutPopupOpened: true })}
            >
              <Icon slot="media" f7="info_circle" />
            </ListItem>
          )}
          {this.menuItemEnabled("Logout") && (
            <ListItem link="#" title={t("Logout").toString()} onClick={this.handleLogout}>
              <div slot="media" className="display-flex justify-content-center align-content-center">
                <IcLogin />
              </div>
            </ListItem>
          )}
        </List>

        <AboutPopup
          profile={profile}
          backdrop={false}
          opened={this.state.aboutPopupOpened}
          onPopupClosed={() => this.setState({ aboutPopupOpened: false })}
          onContactSupportClick={() => this.setState({ contactSupportPopupOpened: true })}
        />

        <ContactSupportPopup
          profile={profile}
          category="Application"
          backdrop={false}
          opened={this.state.contactSupportPopupOpened}
          onPopupClosed={() => this.setState({ contactSupportPopupOpened: false })}
        />

        <ViewProfilePopup
          profile={profile}
          opened={this.state.viewProfilePopupOpened}
          f7={this.$f7}
          onPopupClosed={() => this.setState({ viewProfilePopupOpened: false })}
        />

        <InviteFriendPopUp
          backdrop={false}
          opened={this.state.invitePopupOpened}
          onPopupClosed={() => this.setState({ invitePopupOpened: false })}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  logoutLoading: state.sessionReducer.loading,
  language: state.rootReducer.language,
  languages: state.rootReducer.localConfig.languages,
  i18n: getI18n(),
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logoutUser()),
});

export default compose(
  withTranslation(),
  connectProfile,
  connectLocalConfig,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfilePage);
