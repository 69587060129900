import React, { FC, useState } from "react";
import { BlockTitle, Col, Icon, Link, List, ListItem, Page, Preloader } from "framework7-react";
import { selectPaymentAddress, selectPaymentMethod } from "../../../actions/checkoutActions";
import classNames from "classnames";
import { AddCardListItem } from "../../../components/AddCardListItem";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationStore } from "../../../store/rootReducer";
import { useTranslation } from "react-i18next";
import PaymentCardCreatePage from "../../payment-card-create";
import { SavedCard } from "../../../reducers/paymentCardsReducer";
import { loadLinkedPaymentCardsList, savePaymentCardTemporary } from "../../../actions/paymentCardsActions";
import { Sheet } from "../../../components/Sheet";
import { getProfile } from "../../../selectors/profile";

interface CardListProps {
  loading?: boolean;
}

const CardList: FC<CardListProps> = ({ loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //state
  const [addPaymentMethodSheetOpened, setAddPaymentMethodSheetOpened] = useState<boolean>();
  const [paymentCardCreateOpened, setPaymentCardCreateOpened] = useState<boolean>(false);
  //selectors
  const paymentMethods = useSelector(
    (state: IApplicationStore) => state.classificatorReducer.entitiesClassificators.Payment_Options
  );
  const itemsCart = useSelector((state: IApplicationStore) => state.cartReducer.items);
  const { selectedPaymentMethod, selectedPaymentCard } = useSelector(
    (state: IApplicationStore) => state.checkoutReducer
  );
  const cards = useSelector((state: IApplicationStore) => state.paymentCardsReducer.cards);
  const resizeEvent = useSelector((state: IApplicationStore) => state.rootReducer.resizeEvent);
  const profile = useSelector((state: IApplicationStore) => getProfile(state));
  const isItemsAllowCashDelivery = () => {
    const itemsHasTypeS = !!itemsCart.filter((item) => item.product.type === "S").length;
    const cash = paymentMethods.filter((m) => m.code === "cod");
    return !itemsHasTypeS && cash.length > 0;
  };

  const handleAddCardItem = () => {
    setAddPaymentMethodSheetOpened(false);
    setPaymentCardCreateOpened(true);
  };

  const handleSaveCard = (card: SavedCard) => {
    dispatch(selectPaymentMethod("bankcard", card));
    dispatch(savePaymentCardTemporary(card));
    if (card.address) {
      dispatch(selectPaymentAddress(card.address));
    }
  };

  const config = process.env.CONFIG;
  const isSmallScreen = !resizeEvent.isLG && !resizeEvent.isMD && !resizeEvent.isXL;

  return (
    <>
      {selectedPaymentMethod && (
        <>
          <BlockTitle medium className="pure-visible-xs">
            <span>{t("Payment method")}</span>
            <Link
              href="#"
              onClick={() => {
                setAddPaymentMethodSheetOpened(true);
              }}
            >
              <Icon material="create" />
            </Link>
          </BlockTitle>
          <List noHairlines noChevron className="no-margin-vertical pure-visible-xs">
            {selectedPaymentMethod === "cash" ? (
              <ListItem link="#" title={t("Cash on delivery").toString()}>
                <Icon slot="media" material="account_balance_wallet" />
              </ListItem>
            ) : (
              ""
            )}
            {selectedPaymentMethod === "bankcard" ? (
              <ListItem
                key={selectedPaymentCard.uid}
                link
                title={selectedPaymentCard.maskedNumber}
                // onClick={() => {}}
                noChevron
              >
                <span slot="media">
                  <i
                    className={classNames(
                      "icon",
                      `ic-${selectedPaymentCard.type && selectedPaymentCard.type.toLowerCase()}`
                    )}
                  />
                </span>
                <span slot="footer">
                  {selectedPaymentCard.expMonth}/{selectedPaymentCard.expYear}
                </span>
              </ListItem>
            ) : (
              ""
            )}
          </List>
        </>
      )}

      <BlockTitle medium className="pure-hidden-xs">
        <span>{t("Payment method")}</span>
      </BlockTitle>
      <List noHairlines noChevron className="pure-hidden-xs">
        <ul>
          {isItemsAllowCashDelivery() && (
            <ListItem
              radio
              name="payment-method-media-radio"
              value="cash"
              title={t("Cash on delivery").toString()}
              onClick={() => dispatch(selectPaymentMethod("cash"))}
              checked={selectedPaymentMethod === "cash"}
            >
              <Icon slot="media" material="account_balance_wallet" />
            </ListItem>
          )}

          {loading && (
            <ListItem className="cards-loading-item">
              <Preloader size={18} />
            </ListItem>
          )}
          {cards.map((item) => (
            <ListItem
              key={item.uid}
              radio
              name="payment-method-media-radio"
              value={item.uid}
              title={item.maskedNumber}
              onClick={() => {
                dispatch(selectPaymentMethod("bankcard", item));
              }}
              checked={selectedPaymentMethod === "bankcard" && selectedPaymentCard.uid === item.uid}
              noChevron
            >
              <span slot="media">
                <i className={classNames("icon", `ic-${item.type && item.type.toLowerCase()}`)} />
              </span>
              <span slot="footer">
                {item.expMonth}/{item.expYear}
              </span>
            </ListItem>
          ))}

          <AddCardListItem title={t("Add card").toString()} noChevron onClick={handleAddCardItem} />
        </ul>
      </List>
      {!selectedPaymentMethod && (
        <List noHairlines noChevron className="no-margin-vertical pure-visible-xs">
          <ListItem
            className="item-add-link"
            link
            title={t("Add payment method").toString()}
            noChevron
            onClick={() => {
              setAddPaymentMethodSheetOpened(true);
            }}
          >
            <Icon slot="media" ios="f7:plus" md="material:add" />
          </ListItem>
        </List>
      )}
      <PaymentCardCreatePage
        opened={paymentCardCreateOpened}
        swipeToClose={isSmallScreen}
        onPopupClosed={() => setPaymentCardCreateOpened(false)}
        setPaymentCardCreateOpened={setPaymentCardCreateOpened}
        onSaveCard={(card) => {
          handleSaveCard(card);
        }}
        showAddressFields={config === "marine"}
      />
      <Sheet
        id="add_payment_method_sheet"
        swipeToClose
        backdrop
        opened={addPaymentMethodSheetOpened}
        onSheetOpened={() => {
          if (!cards || (!cards.length && profile.uid)) {
            dispatch(loadLinkedPaymentCardsList());
          }
        }}
        onSheetClosed={() => {
          setAddPaymentMethodSheetOpened(false);
        }}
        slot="fixed"
        style={{ height: "auto" }}
      >
        <BlockTitle medium>
          <span>{t("Add payment method")}</span>
        </BlockTitle>
        <List noHairlines noChevron>
          <ul>
            {isItemsAllowCashDelivery() && (
              <ListItem
                link="#"
                title={t("Cash on delivery").toString()}
                onClick={() => {
                  setAddPaymentMethodSheetOpened(false);
                  dispatch(selectPaymentMethod("cash"));
                }}
              >
                <Icon slot="media" material="account_balance_wallet" />
              </ListItem>
            )}

            {loading && (
              <ListItem className="cards-loading-item">
                <Preloader size={18} />
              </ListItem>
            )}
            {cards.map((item) => (
              <ListItem
                key={item.uid}
                link
                title={item.maskedNumber}
                onClick={() => {
                  setAddPaymentMethodSheetOpened(false);
                  dispatch(selectPaymentMethod("bankcard", item));
                }}
                noChevron
              >
                <span slot="media">
                  <i className={classNames("icon", `ic-${item.type && item.type.toLowerCase()}`)} />
                </span>
                <span slot="footer">
                  {item.expMonth}/{item.expYear}
                </span>
                {selectedPaymentCard && selectedPaymentCard.uid === item.uid && (
                  <div slot="after">
                    <Icon f7="checkmark_alt" />
                  </div>
                )}
              </ListItem>
            ))}
            <AddCardListItem
              title={t("Add card").toString()}
              noChevron
              onClick={() => {
                setAddPaymentMethodSheetOpened(false);
                setPaymentCardCreateOpened(true);
              }}
            />
          </ul>
        </List>
      </Sheet>
    </>
  );
};

export default CardList;
