import { IApplicationStore } from "./rootReducer"
import { connect } from "react-redux"
import { startChat, startChatWithOrder } from '../actions/chatActions'
import { IChatState } from '../reducers/chatReducer'
import { IProduct } from '../reducers/productReducer'

export interface IChatProps {
  chatReducer: IChatState,
  startChat?(sellerPhone: string): void,
  startChatWithOrder?(item: IProduct): void,
}

const mapStateToProps = (state: IApplicationStore): IChatProps => ({
  chatReducer: state.chatReducer,
})

const mapDispatchToProps = (dispatch: any) => ({
  startChat: (sellerPhone: string, message: string) => dispatch(startChat(sellerPhone, message)),
  startChatWithOrder: (item: IProduct) => dispatch(startChatWithOrder(item))
})

export default connect(mapStateToProps, mapDispatchToProps)