import React from "react";
import {
  Popup,
  F7Popup,
  Page,
  Navbar,
  NavRight,
  Link,
  BlockTitle,
  BlockHeader,
  Block,
  Chip,
  List,
  ListInput,
  Icon,
  Fab,
  Button,
} from "framework7-react";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { sendMessage } from "../../actions/contactSupportActions";
import { IClassificator } from "../../reducers/classificatorReducer";
import { SupportMessage } from "../../reducers/contactSupportReducer";
import { IProduct } from "../../reducers/productReducer";
import { Profile } from "../../reducers/sessionReducer";
import { getProfile } from "../../selectors/profile";
import { IApplicationStore } from "../../store/rootReducer";
import { withTranslation, WithTranslation } from "react-i18next";

import "./style.less";

type Props = F7Popup.Props &
  Pick<WithTranslation, "t"> & {
    profile?: Profile;
    loading?: boolean;
    error?: any;
    category?: string;
    product?: IProduct;
    subjects?: {
      Application: IClassificator[];
      Order: IClassificator[];
      Product: IClassificator[];
    };
    sendMessage?(message: SupportMessage): void;
  };

type State = {
  subject: string;
  email: string;
  message: string;
  disableEmail: boolean;
};

class ContactSupportPopup extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      subject: "",
      email: "",
      message: "",
      disableEmail: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { profile, loading, error, t } = this.props;

    if (this.state.email === null && profile && profile.uid) {
      const primaryEmail = profile.accountEmails.filter((item) => item.primary);
      if (primaryEmail.length > 0) {
        this.setState({
          email: primaryEmail[0].email,
          disableEmail: true,
        });
      }
    }

    if (!loading && prevProps.loading) {
      this.$f7.preloader.hide();
    }

    if (error && error != prevProps.error) {
      this.$f7.dialog.alert(t(error));
    }

    if (!loading && prevProps.loading && !error) {
      this.$f7.popup.close();
    }
  }

  sendMessageHandle = () => {
    const { product, t } = this.props;
    const { subject, email, message } = this.state;

    if (!subject) {
      this.$f7.dialog.alert(t("Please select message subject"));
      return;
    }

    if (!email || !message) {
      this.$f7.dialog.alert(t("Please fill out all fields"));
      return;
    }

    this.$f7.preloader.show();

    const parameters = product
      ? {
          productUid: product.uid,
          productName: product.name,
        }
      : {};

    const supportMessage: SupportMessage = {
      subject,
      email,
      message,
      parameters,
    };
    this.props.sendMessage(supportMessage);
  };

  render() {
    const { subjects, category, loading, className, t, ...props } = this.props;
    const { subject } = this.state;

    return (
      <Popup {...props} className={classNames("contact-support-popup", className)}>
        <Page>
          <Navbar noShadow noHairline>
            <NavRight>
              <Link popupClose>
                <i className="icon ic-close" />
              </Link>
            </NavRight>
          </Navbar>
          <Block className="no-margin-top no-margin-bottom">
            <BlockTitle>{t("Contact Support")}</BlockTitle>
            <BlockHeader>{t("Help us understand whats happening. How would you describe it?")}</BlockHeader>
            {subjects[category].map((item: IClassificator) => (
              <Link
                key={item.code}
                onClick={() => {
                  this.setState({ subject: item.code });
                }}
              >
                <Chip text={item.value} className={subject === item.code ? "select" : ""} />
              </Link>
            ))}
          </Block>
          <List noHairlines form className={classNames("contact-support-form")}>
            <ListInput
              name="email"
              label={t("E-mail").toString()}
              type="email"
              placeholder=""
              slot="list"
              required
              clearButton={!this.state.disableEmail}
              floatingLabel
              validateOnBlur
              autofocus={!this.state.disableEmail}
              onInput={(e) => {
                this.setState({ email: e.target.value });
              }}
              value={this.state.email}
              readonly={this.state.disableEmail}
            />
            <ListInput
              name="comment"
              label={t("Your comment").toString()}
              type="textarea"
              placeholder=""
              slot="list"
              required
              clearButton
              floatingLabel
              validateOnBlur
              autofocus={this.state.disableEmail}
              onInput={(e) => {
                this.setState({ message: e.target.value });
              }}
            />
          </List>
          <Block>
            <Button
              fill
              large
              round
              raised
              disabled={loading}
              className="pure-hidden-xs"
              onClick={() => this.sendMessageHandle()}
            >
              {t("Send")}
            </Button>
          </Block>
          <Fab
            position="right-bottom"
            slot="fixed"
            className="pure-visible-xs"
            onClick={() => this.sendMessageHandle()}
          >
            <Icon ios="f7:checkmark_alt" md="material:check" />
          </Fab>
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  profile: getProfile(state),
  loading: state.contactSupportReducer.sending,
  error: state.contactSupportReducer.error,
  subjects: state.classificatorReducer.claimSubjectsClassificators,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendMessage: (message: SupportMessage) => dispatch(sendMessage(message)),
});

export default compose<any>(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ContactSupportPopup);
