import React, { Component } from "react";
import {
  PageContent,
  BlockTitle,
  F7Sheet,
  List,
  ListItem,
} from "framework7-react";
import { IApplicationStore, ICategory } from "../store/rootReducer";
import { connect } from "react-redux";
import { getCategory, getFullCategory, getSubcategories } from "../selectors/category";
import { ICategoryClassificator } from "../reducers/categoryReducer";
import { Dispatch, compose } from "redux";
import { chooseSubcategory } from "../actions/filterActions";
import { WithTranslation, withTranslation } from "react-i18next";
import { Sheet as SheetNamespace } from "framework7/components/sheet/sheet";
import { Sheet } from "../components/Sheet";
import classNames from "classnames";

import "./select-category-sheet.less";

type Props = F7Sheet.Props & {
  category?: ICategory;
  fullCategory?: ICategoryClassificator;
  subcategories?: ICategoryClassificator[];
  chooseSubcategory?(catid?: string): void;
};

type State = {
  selectedId?: string;
  selectedSubcategories: ICategoryClassificator[];
};

class SelectCategorySheetPage extends Component<
  Props & WithTranslation,
  State
> {
  _sheet: SheetNamespace.Sheet;

  constructor(props: Readonly<Props & WithTranslation>) {
    super(props);
    this.state = {
      selectedId: null,
      selectedSubcategories: [],
    };
  }

  componentDidMount() {
    /* TODO */
    const sheetSelector = "#select_category_subcategory_sheet";
    if (!this._sheet) {
      this._sheet = this.$f7.sheet.get(sheetSelector);
    }
  }

  componentDidUpdate(prevProps: Props) {
    /* TODO */
    if (!prevProps.opened && prevProps.opened !== this.props.opened) {
      this.setState({ selectedId: null, selectedSubcategories: [] });
    }
  }

  componentWillUnmount() {
    try {
      if (this._sheet) {
        this._sheet.destroy();
      }
    } catch (err) {}
  }

  selectHandle = (selectedId: string) =>
    this.setState({ selectedId }, () => {
      this.props.chooseSubcategory(selectedId);
    });

  clickSubcategoryHandle = (item: ICategoryClassificator) => {
    const { selectedSubcategories } = this.state;

    if (!item.children.length) {
      this.selectHandle(item.categoryCode);
      return;
    }

    /* TODO */
    const pageContent = this._sheet.$el.find(".page-content");
    if (pageContent.scrollTop() > 0) {
      this._sheet.$el.find(".page-content").scrollTop(0, 150, () => {
        setTimeout(
          () =>
            this.setState({
              selectedSubcategories: [...selectedSubcategories, item],
            }),
          150
        );
      });
    } else {
      this.setState({
        selectedSubcategories: [...selectedSubcategories, item],
      });
    }
  };

  clickBackHandle = () => {
    const { selectedSubcategories } = this.state;
    const item = selectedSubcategories[selectedSubcategories.length - 1];

    this.setState({
      selectedSubcategories: selectedSubcategories.filter(
        (_item) => _item !== item
      ),
    });
  };

  getCategoryTitle = () => {
    return this.props.fullCategory?.categoryName;
  };

  renderPageContent() {
    const { category } = this.props;
    return (
      category && (
        <>
          <BlockTitle medium>{this.getCategoryTitle()}</BlockTitle>
          {this.renderBackLink()}
          {this.renderCategories()}
        </>
      )
    );
  }

  renderBackLink = () => {
    const { t } = this.props;
    const { selectedSubcategories } = this.state;

    return (
      !!selectedSubcategories.length && (
        <List>
          <ListItem
            link="#"
            title={t("Back").toString()}
            onClick={this.clickBackHandle}
          />
        </List>
      )
    );
  };

  renderCategories = () => {
    const { subcategories } = this.props;
    const { selectedSubcategories } = this.state;

    if (selectedSubcategories.length) {
      return (
        <List>
          {selectedSubcategories[selectedSubcategories.length - 1].children.filter(
            (item) => item.productCount > 0).map(
            (item, i) => (
              <ListItem
                key={i}
                link
                title={`${item.categoryName} (${item.productCount})`}
                onClick={() => this.clickSubcategoryHandle(item)}
                noChevron={item.children.length === 0}
              />
            )
          )}
        </List>
      );
    }

    return (
      <List>
        {subcategories
          .filter((item) => item.productCount > 0)
          .map((item, i) => (
          <ListItem
            key={i}
            link="#"
            title={`${item.categoryName} (${item.productCount})`}
            onClick={() => this.clickSubcategoryHandle(item)}
            noChevron={item.children.length === 0}
          />
        ))}
      </List>
    );
  };

  render() {
    const { className, opened, onSheetClosed, ...rest } = this.props;

    return (
      <Sheet
        id="select_category_subcategory_sheet"
        className={classNames("select-category-sheet", className)}
        swipeToClose
        backdrop
        opened={opened}
        onSheetClosed={onSheetClosed}
        {...rest}
      >
        {/*TODO */}
        <PageContent>{opened && this.renderPageContent()}</PageContent>
      </Sheet>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  category: getCategory(state),
  fullCategory: getFullCategory(state),
  subcategories: getSubcategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  chooseSubcategory: (catid?: string) => dispatch(chooseSubcategory(catid)),
});

export default compose<React.ComponentClass<Props>>(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectCategorySheetPage);
