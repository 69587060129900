import { AnyAction } from "redux";
import { Profile } from "./sessionReducer";
import {
  PROFILE_UPDATING,
  PROFILE_UPDATING_SUCCESS,
  PROFILE_UPDATING_ERROR,
  ACCOUNT_ADD_UPDATE_ADDRESS,
  ACCOUNT_ADD_UPDATE_ADDRESS_ERROR,
  ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS,
  ACCOUNT_REMOVE_ADDRESS,
  ACCOUNT_REMOVE_ADDRESS_ERROR,
  ACCOUNT_REMOVE_ADDRESS_SUCCESS,
} from "../actions/profileActions";

export interface IProfileState {
  profile?: Profile;
  updating?: boolean;
  error?: any;

  accountAddOrUpdateAddressLoading?: boolean;
  accountAddOrUpdateAddressError?: string;
  accountRemoveAddressLoading?: boolean;
  accountRemoveAddressError?: string;
}

const initialState: IProfileState = {
  profile: {
    addresses: [],
  },
  updating: false,
  error: null,
};

const profileReducer = (
  state = initialState,
  action: AnyAction
): IProfileState => {
  switch (action.type) {
    case PROFILE_UPDATING: {
      return {
        ...state,
        updating: true,
        error: null,
      };
    }
    case PROFILE_UPDATING_SUCCESS: {
      const { profile } = action;
      return {
        ...state,
        updating: false,
        profile,
      };
    }
    case PROFILE_UPDATING_ERROR: {
      return {
        ...state,
        updating: false,
        error: action.error,
      };
    }
    case ACCOUNT_ADD_UPDATE_ADDRESS: {
      return {
        ...state,
        accountAddOrUpdateAddressError: null,
        accountAddOrUpdateAddressLoading: true,
      };
    }
    case ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS: {
      const { addresses } = action;
      const { profile } = state;

      profile.addresses = [...addresses];

      return {
        ...state,
        profile: { ...profile },
        accountAddOrUpdateAddressError: null,
        accountAddOrUpdateAddressLoading: false,
      };
    }
    case ACCOUNT_ADD_UPDATE_ADDRESS_ERROR: {
      return {
        ...state,
        accountAddOrUpdateAddressError: action.error,
        accountAddOrUpdateAddressLoading: false,
      };
    }
    case ACCOUNT_REMOVE_ADDRESS: {
      return {
        ...state,
        accountRemoveAddressError: null,
        accountRemoveAddressLoading: true,
      };
    }
    case ACCOUNT_REMOVE_ADDRESS_SUCCESS: {
      const { addresses } = action;
      const { profile } = state;

      profile.addresses = [...addresses];

      return {
        ...state,
        profile: { ...profile },
        accountRemoveAddressError: null,
        accountRemoveAddressLoading: false,
      };
    }
    case ACCOUNT_REMOVE_ADDRESS_ERROR: {
      return {
        ...state,
        accountRemoveAddressError: action.error,
        accountRemoveAddressLoading: false,
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
