import React from 'react';
import { Swiper, SwiperSlide, Icon, Link, F7Link } from 'framework7-react';
import { IProductCreateUploadedFileInfo } from '../../reducers/productCreateReducer';
import { WithTranslation, withTranslation } from 'react-i18next';

import './style.less';
import { compose } from 'redux';

type Props = Partial<WithTranslation> & {
  images: IProductCreateUploadedFileInfo[],
  onSelectFile(index: number, file?: File): void,
  onDeleteFile(index: number): void,
}

const getSlideBackgroundStyle = (url: string) => url ? { backgroundImage: `url(${url})` } : null
const RemoveButton = (props: F7Link.Props) => <Link href="#" className="input-clear-button" {...props} />

const SliderImageUploader = (props: Props) => (
  <Swiper
    className="slider-image-uploader"
    init
    params={{
      spaceBetween: 8,
      slidesPerView: 3
    }}
  >
    {
      props.images.map((item, i) => (
        <SwiperSlide
          key={i}
          style={item.attaching || item.detaching ? {}
            : getSlideBackgroundStyle(item.imageLink)
          }
        >
          <Link
            className="content"
            href="#"
            onClick={() => {
              if (item.attaching || item.detaching)
                return
              const input = document.createElement('input')
              document.body.appendChild(input)
              input.type = 'file'
              input.accept = 'image/*'
              input.style.visibility = 'hidden'
              input.style.position = 'absolute'
              input.style.top = '0'
              input.style.left = '-5000px'
              input.addEventListener('change', (ev) => {
                props.onSelectFile(i, (ev.target as any).files[0])
                input.remove()
              })
              input.click()
            }}
          >
            {item.attaching || item.detaching ? <div>{props.t('Compressing...')}</div>
              : !item.imageLink && <Icon icon="ic-image" />}
          </Link>
          {item.imageLink && <RemoveButton onClick={() => props.onDeleteFile(i)} />}
        </SwiperSlide>
      ))
    }
  </Swiper>
)

export default compose(
  withTranslation()
)(SliderImageUploader) as React.ComponentType<Props>