import numeral from "numeral";
import { ILocalConfig } from "./store/rootReducer";
import { ICategoryClassificator } from "./reducers/categoryReducer";
import moment from "moment";
import CreditCardType from "credit-card-type";
import Compressor from "compressorjs";
import luhn from "luhn";
import { SavedCard } from "./reducers/paymentCardsReducer";
import Dom7 from "dom7";
import { LinkedCard } from "./types/commonapi";
import { useEffect, useRef } from "react";

export enum Platform {
  Android = 0,
  iOS,
}

export const getPlatform = () => {
  // @ts-ignore
  if (typeof Android !== "undefined") {
    return Platform.Android;
  }
  // @ts-ignore
  else if (typeof window.webkit !== "undefined") {
    return Platform.iOS;
  } else {
    return null;
  }
};

export function formatPrice(val: number, currencyCode?: string, alwaysDecimal?: boolean) {
  return `${numeral(val).format(alwaysDecimal ? "0,0.00" : "0,0[.]00")}${currencyCode ? ` ${currencyCode}` : ""}`;
}

const config = process.env.CONFIG;
const localConfigPath = "static/config-" + config + ".json";

export async function loadLocalConfig(): Promise<ILocalConfig> {
  return new Promise((resolve, reject) => {
    try {
      const xmlReq = new XMLHttpRequest();
      xmlReq.open("GET", localConfigPath);
      xmlReq.onreadystatechange = () => {
        if (xmlReq.readyState === xmlReq.DONE && xmlReq.status === 200) {
          const config: ILocalConfig = {
            ...JSON.parse(xmlReq.responseText),
            settingsEnabled: !!process.env.SETTINGS_ENABLED,
          };

          try {
            const darkMode = getQueryParameterFromURL("darkMode");
            if (darkMode === "true") {
              config.theme = "dark";
            }
          } catch (err) {}

          resolve(config);
        }
      };
      xmlReq.send(null);
    } catch (err) {
      reject(err);
    }
  });
}

export function getCategoryNameByCategoryId(catid: string, categoriesClassificator: ICategoryClassificator[]) {
  const item = categoriesClassificator.filter(
    (item) => item.children && item.children.filter((item) => item.categoryCode === catid).length
  )[0];
  return item ? item.categoryName : catid;
}

export function getSubcategoryNameBySubcategoryId(catid: string, flat: ICategoryClassificator[]) {
  const item = flat.filter((item) => item.categoryCode === catid)[0];
  return item ? item.categoryName : "";
}

export const formatDate = (date: string | number) => moment(date).format("LL");

export const formatDateTime = (dateTime: string) => {
  return moment(dateTime, "YYYYMMDDHHmmss").format("HH:mm MMM. DD YYYY");
};

export const getAuthCodeFromURL = () => {
  return getQueryParameterFromURL("authCode");
};

export const getCreditCardType = (cardNumber: string) => {
  return CreditCardType(cardNumber)[0];
};

export const getQueryParameterFromURL = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  const locationHref = url.replace("/#/", "");
  const parse = require("url-parse"),
    parsedUrl = parse(locationHref, true);
  return parsedUrl.query.hasOwnProperty(name) ? parsedUrl.query[name] : null;
};

export const getActionRefFromURL = () => {
  return getQueryParameterFromURL("actionRef");
};

export const convertISODateToInputDate = (dateISO: string) => {
  if (!dateISO) return "";
  let date = new Date(dateISO);
  return date.toISOString().substring(0, 10);
};

const MAX_FILE_SIZE = 1024 * 1024 * 2;
const MAX_IMAGE_SIZE_ = 2000;
const DEFAULT_QUALITY = 0.73;

export const getCompressedImage = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: DEFAULT_QUALITY,
      convertSize: MAX_FILE_SIZE,
      maxWidth: MAX_IMAGE_SIZE_,
      success(result) {
        resolve(result as File);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export const generateToken = async (saveCard: LinkedCard): Promise<string> => {
  const card = {
    uid: null,
    ...saveCard,
    expDateMonth: saveCard.expMonth.toString(),
    expDateYear: saveCard.expYear.toString(),
  };
  return new Promise((resolve, reject) => {
    window.cardTokenCallback = (token: any) => (window.cardToken = token);

    window.ccTokenizer = new window.CCTokenizer({
      card,
      format: !card.cardNumber ? "3" : "1",
      errorHandler: (errorCode: any, errorMessage: any) => reject(`${errorCode} ${errorMessage}`),
    });
    window.ccTokenizer.loadToken = (cardToken: string) => (window.cardToken = cardToken);
    console.log(window.ccTokenizer);
    const tokenizerScriptUrlWithToken =
      window.ccTokenizer.tokenServiceUrl + "?value=" + encodeURIComponent(window.ccTokenizer.generateToken());

    const tokenScriptId = "tokenScript_ccTokenizer____script";

    document.querySelectorAll(`#${tokenScriptId}`).forEach((el) => el.remove());

    const tokenScript = document.createElement("script");
    tokenScript.id = tokenScriptId;
    tokenScript.setAttribute("src", tokenizerScriptUrlWithToken);
    tokenScript.async = true;
    tokenScript.addEventListener("error", (ev) => {
      reject(ev.error);
    });
    document.head.appendChild(tokenScript);

    window.cardTokenCallback = (cardToken: string) => resolve(cardToken);
  });
};

function normalizeYear(year: number) {
  // Century fix
  let YEARS_AHEAD = 20;
  if (year < 100) {
    let nowYear = new Date().getFullYear();
    year += Math.floor(nowYear / 100) * 100;
    if (year > nowYear + YEARS_AHEAD) {
      year -= 100;
    } else if (year <= nowYear - 100 + YEARS_AHEAD) {
      year += 100;
    }
  }
  return year;
}

export function isValidCardExpDate(value: string /* MM/YY */) {
  let match = value.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
  if (!match) {
    return false;
  }
  let exp = new Date(normalizeYear(1 * parseInt(match[2])), 1 * parseInt(match[1]) - 1, 1).valueOf();
  let now = new Date();
  let currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
  return exp >= currMonth;
}

export function isValidCardFormat(value: string) {
  return luhn.validate(value);
}

export const setLayoutTheme = (theme: "light" | "dark") => {
  var $html = Dom7("html");
  $html.removeClass("theme-dark theme-light").addClass("theme-" + theme);
};

export const getDaysLeft = (date: Date) => {
  if (!date) return 0;
  return moment(date).diff(new Date(), "days");
};

// Parse URL helpers
const supportedProvidersRegexes = {
  youtube: {
    parse: (url: string) => {
      const match = url.match(/(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i);
      if (!match) {
        return null;
      }
      return { id: match[1], type: "youtube" };
    },
  },
  vimeo: {
    parse: (url: string) => {
      const match = url.match(
        /(vimeo(?:cdn|pro)?)\.com\/(?:(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+|staff\/frame)\/)?videos?)\/)?(\d+)(?:_(\d+)(?:x(\d+))?)?(\.\w+)?/i
      );
      if (!match) {
        return null;
      }
      return { id: match[2], type: "vimeo" };
    },
  },
  vk: {
    parse: (url: string) => {
      const match = url.match(/(vk(?:cdn|pro)?)\.com\/(?:(?:video)([\d+_d+]+))/i);
      if (!match) {
        return null;
      }
      return { id: match[2], type: "vk" };
    },
  },
};

export const parseVideoURL = (url: string): { id: string; type: string } => {
  if (typeof url === "undefined") {
    return undefined;
  }
  const provider = parseProvider(url);
  let result: any;
  const providerRegex = supportedProvidersRegexes[provider];
  if (!provider || !providerRegex) {
    return undefined;
  }
  result = providerRegex.parse(url);
  return result;
};

export const parseProvider = (url: string) => {
  const match = url.match(/(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i);
  return match ? match[1] : undefined;
};

export const createVideoURLLink = (id: string, type: "YOUTUBE" | "VIMEO" | "VK" | string) => {
  switch (type) {
    case "VIMEO":
      return `https://vimeo.com/${id}`;
    case "YOUTUBE":
      return `https://www.youtube.com/watch?v=${id}`;
    case "VK":
      return `https://vk.com/video${id}`;
    default:
      return "";
  }
};

export const createThumbnailVideoURLLink = (id: string, type: "YOUTUBE" | "VIMEO" | "VK" | string) => {
  switch (type) {
    case "VIMEO":
      return "";
    case "YOUTUBE":
      return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
    case "VK":
      return "";
    default:
      return "";
  }
};

export const createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const removeOmnideskLoginWidget = () => {
  const $odwLogin = Dom7("#omnidesk_widget_login_button");
  if ($odwLogin) {
    $odwLogin.hide();
  }
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
