import { client, commonapiURL, updateAxiosClientCredential } from "../axios";
import { CreateAccountRequest, ICreateAccountFormError, Profile, Token } from "../reducers/sessionReducer";
import { IApplicationStore } from "../store/rootReducer";
import { AccountForgotPasswordRequest, AccountResetPasswordRequest, AccountWsControllerApi } from "../types/commonapi";
import { getAuthCodeFromURL } from "../utils";
import { loadMyCurrencies } from "./myCurrenciesActions";
import moment from "moment";
import PasswordValidator from "password-validator";

export const SESSION_RESTORING = "SESSION_RESTORING";
export const SESSION_LOADING = "SESSION_LOADING";
export const SESSION_SUCCESS = "SESSION_SUCCESS";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const SESSION_ERROR = "SESSION_ERROR";
export const VALIDATION_LOADING = "VALIDATION_LOADING";
export const VALIDATION_SUCCESS = "VALIDATION_SUCCESS";
export const REGISTRATION_LOADING = "REGISTRATION_LOADING";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const REGISTRATION_CLEAR = "REGISTRATION_CLEAR";

export const ONETIME_PASSWORD_LOADING = "ONETIME_PASSWORD_LOADING";
export const ONETIME_PASSWORD_SUCCESS = "ONETIME_PASSWORD_SUCCESS";
export const ONETIME_PASSWORD_ERROR = "ONETIME_PASSWORD_ERROR";
export const ONETIME_PASSWORD_LOGIN_LOADING = "ONETIME_PASSWORD_LOGIN_LOADING";
export const ONETIME_PASSWORD_LOGIN_SUCCESS = "ONETIME_PASSWORD_LOGIN_SUCCESS";
export const ONETIME_PASSWORD_LOGIN_ERROR = "ONETIME_PASSWORD_LOGIN_ERROR";
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

// @ts-ignore
async function getAuthorizationCode(username: string, password: string) {
  const res = await client.post(commonapiURL + `/auth/mobile/authorization_code`, { username, password });
  const { code } = res.data;
  return code;
}

async function getAuthorizationDataByCode(code: string) {
  const res = await client.post(commonapiURL + `/auth/mobile/token`, { code });
  return { ...res.data };
}

async function getAuthorizationDataByRefreshToken(refreshToken: string) {
  const res = await client.post(commonapiURL + `/auth/mobile/token`, { refreshToken });
  return { ...res.data };
}

async function getAuthorizationDataByUsernameAndPassword(username: string, password: string) {
  const res = await client.post(commonapiURL + `/auth/mobile/token`, { username, password });
  return { ...res.data };
}

async function getProfile() {
  return (await new AccountWsControllerApi().getFullAccountUsingPOST()).account;
}

export const clearSession = () => async (dispatch: any) => {
  dispatch(sessionError(null));
};

export const restoreSession = () => async (dispatch: any, getState: () => IApplicationStore) => {
  let user = {};
  let authData = null;
  const state = getState();
  const { sessionReducer } = state;

  if (sessionReducer.refreshToken) {
    dispatch(sessionLoading());
    dispatch(sessionRestoring());

    try {
      const result = await getAuthorizationDataByRefreshToken(sessionReducer.refreshToken);
      if (result.errorData && result.errorData.errorMessage) {
        dispatch(sessionError(result.errorData.errorMessage));
      } else {
        authData = result;
        updateAxiosClientCredential(authData.accessToken);
        user = await getProfile();
        dispatch(sessionSuccess(user, authData));
      }
    } catch (err) {
      dispatch(sessionError(null));
    }
  }
};

export const authByUrl = () => async (dispatch: any, getState: () => IApplicationStore) => {
  const state = getState();
  const { sessionReducer } = state;

  const authCode = getAuthCodeFromURL();
  const username = process.env.USERNAME;
  const password = process.env.PASSWORD;
  const refreshToken = sessionReducer.refreshToken;

  if (authCode && authCode.length >= 32) {
    return dispatch(loginWithCode(authCode));
  } else if (username && password) {
    return dispatch(loginWithUserPassword(username, password));
  } else if (refreshToken) {
    return dispatch(restoreSession());
  } else {
    dispatch(sessionError(null));
  }
};

export const loginWithCode = (code: string) => async (dispatch: any) => {
  dispatch(sessionLoading());
  try {
    const authData: Token = await getAuthorizationDataByCode(code);
    if (!authData || !authData.accessToken) {
      throw new Error("Authentication error");
    }
    const profile = await getProfile();
    dispatch(loadMyCurrencies());
    dispatch(sessionSuccess(profile, authData));
  } catch (error) {
    dispatch(sessionError(error.toString()));
  }
};

export const loginWithUserPassword = (username: string, password: string) => async (dispatch: any) => {
  dispatch(sessionLoading());
  try {
    if (!username || !password) {
      throw new Error("Empty username or password");
    }
    const authData: Token = await getAuthorizationDataByUsernameAndPassword(username, password);
    if (!authData || !authData.accessToken) {
      throw new Error("Authentication error");
    }
    const profile = await getProfile();
    dispatch(loadMyCurrencies());
    dispatch(sessionSuccess(profile, authData));
  } catch (error) {
    dispatch(sessionError(error.toString()));
  }
};

export const oneTimePassword = (username: string) => async (dispatch: any, getState: () => IApplicationStore) => {
  dispatch(oneTimePasswordLoading());
  try {
    if (!username) {
      throw new Error("Empty username");
    }
    const state = getState();
    const request: AccountForgotPasswordRequest = {
      username: username,
      language: state.rootReducer.language,
    };
    const response = await new AccountWsControllerApi().oneTimePasswordUsingPOST(request);
    if (response.errorData && response.errorData.errorMessage) {
      dispatch(oneTimePasswordError(response.errorData.errorMessage));
    } else {
      dispatch(oneTimePasswordSuccess());
    }
  } catch (error) {
    dispatch(oneTimePasswordError(error.toString()));
  }
};

export const loginWithOneTimePassword = (username: string, password: string) => async (dispatch: any) => {
  dispatch(oneTimePasswordLoginLoading());
  try {
    if (!username || !password) {
      throw new Error("Empty username or password");
    }
    const authData: Token = await getAuthorizationDataByUsernameAndPassword(username, password);
    if (authData.errorData && authData.errorData.errorMessage) {
      dispatch(oneTimePasswordLoginError(authData.errorData.errorMessage));
    } else {
      const profile = await getProfile();
      if (profile) {
        dispatch(loadMyCurrencies());
        dispatch(oneTimePasswordLoginSuccess(profile, authData));
      }
    }
  } catch (error) {
    dispatch(oneTimePasswordLoginError(error.toString()));
  }
};

export const changePassword = (password: string) => async (dispatch: any) => {
  dispatch(changePasswordLoading());
  try {
    if (!password) {
      throw new Error("Empty password");
    }
    const request: AccountResetPasswordRequest = {
      password: password,
    };
    const response = await new AccountWsControllerApi().resetPasswordUsingPOST(request);
    if (response.errorData && response.errorData.errorMessage) {
      dispatch(changePasswordError(response.errorData.errorMessage));
    } else {
      dispatch(changePasswordSuccess());
    }
  } catch (error) {
    dispatch(changePasswordError(error.toString()));
  }
};

function checkRegistrationFormErrors(request: CreateAccountRequest, fields: string[], parameters: string[]) {
  fields.forEach((field) => {
    if (typeof request[field] === "undefined" || request[field] === null || !request[field].toString().length) {
      parameters.push(field);
    }
  });
}

function validateRegistrationRequest(
  request: CreateAccountRequest,
  fields: string[],
  errorMessage: string[],
  errorParameters: ICreateAccountFormError[]
) {
  const parameters: string[] = [];
  checkRegistrationFormErrors(request, fields, parameters);

  if (parameters.length) {
    errorMessage.push("Please fill out this field.");
    errorParameters.push({ message: "", parameters });

    return;
  }

  const passwordValidator = new PasswordValidator();

  const passValid = passwordValidator
    .has(/^[a-zA-Z\d!@#$%^&*-_]+$/)
    .has(/\d+/)
    .has(/[!@#$%^&*-_]+/)
    .min(8)
    .uppercase(1)
    .lowercase(1)
    .digits(1)
    .validate(request.password);

  if (!passValid) {
    errorMessage.push(
      "Invalid password. The password must be at least 8 characters long and consist of uppercase and lowercase Latin letters of the as well as numbers and one of special characters [!@#$%^&*-_]"
    );
    errorParameters.push({
      message: "Invalid password",
      parameters: ["password"],
    });
    return;
  }

  if (request.password !== request.passwordRepeat) {
    errorMessage.push("Please make sure your passwords match");
    errorParameters.push({
      message: "Please make sure your passwords match",
      parameters: ["password", "passwordRepeat"],
    });

    return;
  }

  if (request.accept !== true) {
    errorMessage.push("You must accept our Privacy Policy and Terms of Use before continue");
    errorParameters.push({
      message: "You must accept our Privacy Policy and Terms of Use before continue",
      parameters: ["accept"],
    });

    return;
  }

  if (request.birthDate) {
    const currentDate = new Date();
    const birthDate = new Date(request.birthDate);

    if (currentDate.getTime() < birthDate.getTime()) {
      errorMessage.push("Birth Date should not be greater than today");
      errorParameters.push({
        message: "Birth Date should not be greater than today",
        parameters: ["birthDate"],
      });

      return;
    }
  }
}

export const validateRequest = (request: CreateAccountRequest, fields: string[]) => (dispatch: any) => {
  dispatch(validationLoading());

  const errorMessage: string[] = [];
  const errorParameters: ICreateAccountFormError[] = [];
  validateRegistrationRequest(request, fields, errorMessage, errorParameters);

  if (errorMessage.length > 0) {
    dispatch(registrationError(errorMessage[0], errorParameters));
  } else {
    dispatch(validationSuccess());
  }
};

export const register = (request: CreateAccountRequest, fields?: string[]) => async (dispatch: any) => {
  dispatch(registrationLoading());

  if (!fields) {
    fields = [
      "email",
      "phone",
      "firstName",
      "lastName",
      "birthDate",
      "country",
      "city",
      "postalCode",
      "addressLine",
      "password",
      "passwordRepeat",
      "accept",
    ];
  }

  const errorMessage: string[] = [];
  const errorParameters: ICreateAccountFormError[] = [];
  validateRegistrationRequest(request, fields, errorMessage, errorParameters);

  if (errorMessage.length > 0) {
    dispatch(registrationError(errorMessage[0], errorParameters));
    return;
  }

  if (request.addressLine && request.postalCode && request.city) {
    request.address = {
      countryCode: request.country,
      city: request.city,
      postalCode: request.postalCode,
      firstAddressLine: request.addressLine,
    };

    delete request.city;
    delete request.postalCode;
    delete request.addressLine;
  }

  if (request.phone) {
    // @ts-ignore
    request.mobilePhone = {
      // @ts-ignore
      fullNumber: request.phone.toString(),
    };
  }

  delete request.phone;
  delete request.passwordRepeat;
  delete request.accept;

  if (request.birthDate) {
    const date = moment(request.birthDate, "YYYY-MM-DD");
    // @ts-ignore
    request.birthDate = date.format("YYYYMMDDHHmmss");
  }

  if (process.env.SHOW_PEP === "false") {
    request.pep = false;
  }

  let profile: Profile;
  try {
    const response = await new AccountWsControllerApi().createPersonalAccountUsingPOST(request);
    if (response.errorData) {
      const message = response.errorData.errorMessage;
      const parameters = response.errorData.parameters || [];
      if (parameters.length > 0) {
        let error: ICreateAccountFormError = {
          message: message,
          parameters: [],
        };
        parameters.forEach((parameter: string) => {
          if (parameter.includes("hone")) {
            error.parameters.push("phone");
          } else if (parameter.includes("countryCode")) {
            error.parameters.push("country");
          } else if (parameter.includes("firstAddressLine")) {
            error.parameters.push("addressLine");
          } else {
            error.parameters.push(parameter);
          }
        });
        const formErrors: ICreateAccountFormError[] = [error];
        dispatch(registrationError(message, formErrors));
      } else {
        throw new Error(message);
      }
    } else {
      profile = response.account;
      dispatch(registrationSuccess(profile));
      dispatch(registrationClear());
    }
  } catch (err) {
    dispatch(registrationError(err.message, []));
    return;
  }
};

export const clearRegistration = () => async (dispatch: any) => {
  dispatch(registrationClear());
};

export const logoutUser = () => (dispatch: any) => {
  dispatch(sessionLoading());
  setTimeout(() => {
    dispatch(sessionLogout());
  }, 2000);
};

const sessionRestoring = () => ({
  type: SESSION_RESTORING,
});

const sessionLoading = () => ({
  type: SESSION_LOADING,
});

const sessionSuccess = (profile: any, authData: Token) => ({
  type: SESSION_SUCCESS,
  profile,
  authData,
});

const sessionError = (error: any) => ({
  type: SESSION_ERROR,
  error,
});

const sessionLogout = () => ({
  type: SESSION_LOGOUT,
});

const validationLoading = () => ({
  type: VALIDATION_LOADING,
});

const validationSuccess = () => ({
  type: VALIDATION_SUCCESS,
});

const registrationLoading = () => ({
  type: REGISTRATION_LOADING,
});

const registrationSuccess = (profile: any) => ({
  type: REGISTRATION_SUCCESS,
  profile,
});

const registrationError = (error: any, parameters: ICreateAccountFormError[]) => ({
  type: REGISTRATION_ERROR,
  error,
  parameters,
});

const registrationClear = () => ({
  type: REGISTRATION_CLEAR,
});

const oneTimePasswordLoading = () => ({
  type: ONETIME_PASSWORD_LOADING,
});

const oneTimePasswordSuccess = () => ({
  type: ONETIME_PASSWORD_SUCCESS,
});

const oneTimePasswordError = (error: any) => ({
  type: ONETIME_PASSWORD_ERROR,
  error,
});

const oneTimePasswordLoginLoading = () => ({
  type: ONETIME_PASSWORD_LOGIN_LOADING,
});

const oneTimePasswordLoginSuccess = (profile: any, authData: Token) => ({
  type: ONETIME_PASSWORD_LOGIN_SUCCESS,
  profile,
  authData,
});

const oneTimePasswordLoginError = (error: any) => ({
  type: ONETIME_PASSWORD_LOGIN_ERROR,
  error,
});

const changePasswordLoading = () => ({
  type: CHANGE_PASSWORD_LOADING,
});

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

const changePasswordError = (error: any) => ({
  type: CHANGE_PASSWORD_ERROR,
  error,
});
