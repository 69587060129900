import Dom7 from "dom7";
import moment from "moment";
import React from "react";
import {
  Popup,
  F7Popup,
  Page,
  Navbar,
  NavRight,
  Link,
  Block,
  ListInput,
  List,
  Button,
  ListItem,
  Row,
  Col,
} from "framework7-react";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { clearRegistration, register, validateRequest } from "../../actions/sessionActions";
import { IClassificator } from "../../reducers/classificatorReducer";
import { CreateAccountRequest, ICreateAccountFormError, Profile } from "../../reducers/sessionReducer";
import connectFilter from "../../store/connectFilter";
import { IApplicationStore } from "../../store/rootReducer";
import { Country } from "../../types/commonapi";
import { withTranslation, WithTranslation } from "react-i18next";

import "./style.less";
import { analytics } from "../../Setup";

type Props = F7Popup.Props &
  Pick<WithTranslation, "t"> & {
    countries: Country[];
    profile?: Profile;
    loading: boolean;
    registrationLoading: boolean;
    error: any;
    registered: boolean;
    request?: CreateAccountRequest;
    formErrors?: ICreateAccountFormError[];
    urls: IClassificator[];
    register?(request: CreateAccountRequest): void;
    validateRequest?(request: CreateAccountRequest, fields: string[]): void;
    clear?(): void;
  };

type State = {
  step: number;
  nextStep: number;
  accepted: boolean;
};

class RegisterDesktopPopup extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      step: 1,
      nextStep: 1,
      accepted: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { registrationLoading, request } = this.props;
    if (prevProps.registrationLoading && !registrationLoading) {
      this.setState({ accepted: request.accept });
      this.$f7.preloader.hide();
    }
    this.handleRegister(prevProps);
  }

  handleRegister = (prevProps: Props) => {
    const { step, nextStep } = this.state;
    const { registrationLoading, error, formErrors, registered, t } = this.props;

    if (error && error !== prevProps.error && this.props.opened) {
      if (registrationLoading) {
        this.$f7.preloader.hide();
      }
      this.$f7.dialog.alert(t(error));
    }

    if (!registrationLoading && prevProps.registrationLoading && formErrors.length) {
      formErrors.forEach((err) => {
        let errorFields = [];
        // @ts-ignore
        if (err.parameters.length > 0 && err.parameters[0].parameters) {
          // @ts-ignore
          errorFields = err.parameters[0].parameters;
        } else if (err.parameters.length > 0) {
          errorFields = err.parameters;
        }
        if (
          errorFields.includes("email") ||
          errorFields.includes("phone") ||
          errorFields.includes("password") ||
          errorFields.includes("passwordRepeat") ||
          errorFields.includes("accept")
        ) {
          this.setState({
            step: 1,
            nextStep: 1,
          });
        } else if (
          errorFields.includes("firstName") ||
          errorFields.includes("lastName") ||
          errorFields.includes("birthDate")
        ) {
          this.setState({
            step: 2,
            nextStep: 2,
          });
        } else if (
          errorFields.includes("country") ||
          errorFields.includes("city") ||
          errorFields.includes("postalCode") ||
          errorFields.includes("addressLine")
        ) {
          this.setState({
            step: 3,
            nextStep: 3,
          });
        }
      });
    }

    if (!registrationLoading && prevProps.registrationLoading && !error && !formErrors.length) {
      if (nextStep > step) {
        this.setState({
          step: nextStep,
          nextStep: nextStep,
        });
      }

      if (registrationLoading) {
        this.$f7.preloader.hide();
      }
    }

    if (!registrationLoading && prevProps.registrationLoading && registered) {
      this.$f7.dialog.alert(
        t(
          "Your registration has been successful. <br> " +
            "You will receive an activation e-mail shortly containing further instructions on how to complete your registration."
        ),
        () => {
          this.reset();
          this.$f7.popup.close();
        }
      );

      analytics.completeRegistrationForm();
    }
  };

  registerClickHandle = () => {
    const { registrationLoading, request } = this.props;
    if (!registrationLoading) {
      this.$f7.preloader.show();
    }
    this.props.register(request);
  };

  getDefaultValue = (fieldName: string, defaultValue: any = ""): any => {
    const { request } = this.props;

    if (
      typeof request[fieldName] !== "undefined" &&
      request[fieldName] !== null &&
      request[fieldName].toString() !== "NaN"
    ) {
      if (fieldName === "birthDate") {
        return moment(request[fieldName], "YYYYMMDDHHmmss").format("YYYY-MM-DD");
      } else {
        return request[fieldName];
      }
    } else {
      return defaultValue;
    }
  };

  blurInputHandle = (e: { target: { name: any; value: any; type: any; checked: boolean } }) => {
    const { name, value, type } = e.target;
    const { request } = this.props;
    if (!name || !type) throw new Error("Name or type for field is not defined!");

    try {
      switch (type) {
        case "number":
          request[name] = parseFloat(value);
          break;
        case "checkbox":
          request[name] = e.target.checked;
          break;
        case "date": {
          let dateISO = new Date(value).toISOString();
          request[name] = dateISO.substr(0, dateISO.lastIndexOf("."));
          break;
        }
        default: {
          request[name] = value;
        }
      }
    } catch (err) {}
  };

  smartSelectInputChangeHandle = (e: { target: { value: any; name?: any; type?: any } }) => {
    const { name, value } = e.target;
    const { request } = this.props;
    request[name] = value;
  };

  getErrorProps = (fieldName: string, properties?: any) => {
    const { t } = this.props;
    const optionalProps = properties || {};
    let errorMessage = t("Please fill out this field.");

    let errorMessageForce = false;

    if (this.props.formErrors && this.props.formErrors.length > 0) {
      errorMessageForce = this.props.formErrors.filter((e) => e.parameters.includes(fieldName)).length > 0;
    }

    if (!errorMessageForce) {
      const formError = this.props.formErrors.filter(
        (item) =>
          !!item.parameters.filter((__item) => {
            // @ts-ignore
            if (__item.parameters) {
              // @ts-ignore
              return !!__item.parameters.includes(fieldName);
            }
            return __item == fieldName;
          }).length
      )[0];

      if (formError) {
        errorMessageForce = true;
        errorMessage = t(formError.message);
      }
    }

    return {
      info: t("required").toString(),
      required: true,
      validateOnBlur: true,
      ignoreStoreData: true,
      errorMessage,
      errorMessageForce,
      ...optionalProps,
    };
  };

  nextStepHandle = () => {
    const { registrationLoading, request, formErrors } = this.props;
    const { step } = this.state;

    if (!registrationLoading) {
      this.$f7.preloader.show();
    }

    switch (step) {
      case 1:
        this.setState({
          nextStep: 2,
        });
        this.props.validateRequest(request, ["email", "phone", "password", "passwordRepeat"]);
        break;
      case 2:
        this.setState({
          nextStep: 3,
        });
        this.props.validateRequest(request, ["firstName", "lastName", "birthDate"]);
        break;
      case 3:
        this.props.validateRequest(request, ["country", "city", "postalCode", "addressLine"]);
        if (!formErrors.length) {
          this.registerClickHandle();
        }
        break;
    }
  };

  prevStepHandle = () => {
    const { step } = this.state;

    if (this.state.step > 1) {
      const prevStep = step - 1;
      this.setState({
        step: prevStep,
        nextStep: prevStep,
      });
    }
  };

  getAcceptLinkUrl = (code: string) => {
    const { urls } = this.props;
    const filtered = urls.filter((u) => u.code === code);
    return filtered.length > 0 ? filtered[0].value : "#";
  };

  reset = () => {
    this.props.clear();
    this.setState({
      step: 1,
      nextStep: 1,
    });
    Dom7(".register-form-block input").each((_index, element) => {
      Dom7(element).val(null);
    });
    this.forceUpdate();
  };

  render() {
    const { step } = this.state;
    const { className, t, ...props } = this.props;

    return (
      <Popup {...props} className={classNames("register-popup", className)}>
        <Page>
          <Navbar noShadow noHairline title={t("Register")}>
            <NavRight>
              <Link popupClose onClick={() => this.reset()}>
                {t("Close")}
              </Link>
            </NavRight>
          </Navbar>
          <Block className="register-form-block">
            <List noHairlines form className={classNames("register-form-list", step == 1 && "form-list-visible")}>
              <ListInput
                name="email"
                label={t("E-mail").toString()}
                floatingLabel
                type="email"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("email")}
                {...this.getErrorProps("email")}
              />
              <ListInput
                name="phone"
                label={t("Mobile Phone").toString()}
                floatingLabel
                type="number"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("phone")}
                {...this.getErrorProps("phone")}
              />
              <ListInput
                name="password"
                label={t("Password").toString()}
                floatingLabel
                type="password"
                placeholder=""
                clearButton
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("password")}
                {...this.getErrorProps("password")}
              />
              <ListInput
                name="passwordRepeat"
                label={t("Repeat Password").toString()}
                floatingLabel
                type="password"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("passwordRepeat")}
                {...this.getErrorProps("passwordRepeat")}
              />
              <ListItem
                name="accept"
                checkbox
                onChange={(e) => {
                  this.blurInputHandle(e);
                  this.setState({ accepted: e.target.checked });
                }}
                className="accept-checkbox"
                checked={this.state.accepted}
                {...this.getErrorProps("accept")}
              >
                <div className="item-title">
                  {t("You agree with our")}&nbsp;
                  <Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(this.getAcceptLinkUrl("PrivacyPolicy"));
                    }}
                  >
                    {t("PrivacyPolicy")}
                  </Link>
                  &nbsp;
                  {t("and")}&nbsp;
                  <Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(this.getAcceptLinkUrl("TermsAndConditions"));
                    }}
                  >
                    {t("TermsAndConditions")}
                  </Link>
                  &nbsp;
                </div>
              </ListItem>
            </List>

            <List noHairlines form className={classNames("register-form-list", step == 2 && "form-list-visible")}>
              <ListInput
                name="firstName"
                label={t("First Name").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                onInput={(e) => {
                  e.target.errorMessage = null;
                }}
                defaultValue={this.getDefaultValue("firstName")}
                {...this.getErrorProps("firstName")}
              />
              <ListInput
                name="lastName"
                label={t("Last Name").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("lastName")}
                {...this.getErrorProps("lastName")}
              />
              <ListInput
                name="birthDate"
                label={t("Birth Date").toString()}
                type="date"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("birthDate")}
                {...this.getErrorProps("birthDate")}
              />
              {process.env.SHOW_PEP === "true" && (
                <ListItem
                  name="pep"
                  checkbox
                  title={t("Politically exposed person").toString()}
                  slot="list"
                  onChange={this.blurInputHandle}
                  defaultChecked={this.props.request.pep}
                />
              )}
            </List>

            <List noHairlines form className={classNames("register-form-list", step == 3 && "form-list-visible")}>
              <ListInput
                name="country"
                label={t("Registration Country").toString()}
                floatingLabel
                type="select"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("country")}
                {...this.getErrorProps("country")}
              >
                <option key="" value="" />
                {this.props.countries.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </ListInput>
              <ListInput
                name="city"
                label={t("Registration City").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("city")}
                {...this.getErrorProps("city")}
              />
              <ListInput
                name="addressLine"
                label={t("Registration Address Line").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("addressLine")}
                {...this.getErrorProps("addressLine")}
              />
              <ListInput
                name="postalCode"
                label={t("Registration Postal Code").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("postalCode")}
                {...this.getErrorProps("postalCode")}
              />
            </List>

            <Block>
              <Row>
                <Col>
                  {step > 1 && (
                    <Button large round raised onClick={this.prevStepHandle} disabled={this.props.registrationLoading}>
                      {t("Back")}
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    fill
                    large
                    round
                    raised
                    onClick={this.nextStepHandle}
                    disabled={this.props.registrationLoading}
                  >
                    {t("Continue")}
                  </Button>
                </Col>
              </Row>
            </Block>
          </Block>
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  countries: state.classificatorReducer.countryClassificator,
  profile: state.sessionReducer.profile,
  loading: state.sessionReducer.loading,
  registrationLoading: state.sessionReducer.registrationLoading,
  error: state.sessionReducer.error,
  formErrors: state.sessionReducer.formErrors,
  request: state.sessionReducer.request,
  registered: state.sessionReducer.registered,
  urls: state.classificatorReducer.entitiesClassificators.Url_app,
});

const mapDispatchToProps = (dispatch: any) => ({
  register: (request: CreateAccountRequest) => dispatch(register(request)),
  validateRequest: (request: CreateAccountRequest, fields: string[]) => dispatch(validateRequest(request, fields)),
  clear: () => dispatch(clearRegistration()),
});

export default compose<any>(
  connectFilter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(RegisterDesktopPopup);
