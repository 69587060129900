import React from "react";

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.329556 3.72118C0.51407 3.04824 1.05443 2.51765 1.73977 2.33647C2.99182 2 8.00003 2 8.00003 2C8.00003 2 13.0082 2 14.2603 2.32353C14.9325 2.50471 15.486 3.04824 15.6705 3.72118C16 4.95059 16 7.5 16 7.5C16 7.5 16 10.0624 15.6705 11.2788C15.486 11.9518 14.9456 12.4824 14.2603 12.6635C12.9951 13 8.00003 13 8.00003 13C8.00003 13 2.99182 13 1.73977 12.6765C1.05443 12.4953 0.51407 11.9647 0.329556 11.2918C6.86646e-05 10.0624 6.86646e-05 7.51294 6.86646e-05 7.51294C6.86646e-05 7.51294 -0.0131111 4.95059 0.329556 3.72118Z"
      fill="#FF0000"
    />
    <path
      d="M5.43012 7.49995L9.59485 5.14465V9.85524L5.43012 7.49995Z"
      fill="white"
    />
  </svg>
);
