import { SupportMessage } from "../reducers/contactSupportReducer";
import { IApplicationStore } from "../store/rootReducer";
import {
  OmnideskTicket,
  OmnideskTicketControllerApi,
} from "../types/marketplaceapi";
import { getPlatform, Platform } from "../utils";

const pjson = require("../../package.json");

export const MESSAGE_SENDING = "MESSAGE_SENDING";
export const MESSAGE_SENDING_SUCCESS = "MESSAGE_SENDING_SUCCESS";
export const MESSAGE_SENDING_ERROR = "MESSAGE_SENDING_ERROR";

export const sendMessage = (message: SupportMessage) => async (
  dispatch: any,
  getState: () => IApplicationStore
) => {
  dispatch(sendMessageAction());

  const state = getState();
  const profile = state.sessionReducer.profile;

  try {
    let ticket: OmnideskTicket = {
      email: message.email,
      subject: message.subject,
      body: message.message,
      languageCode: state.rootReducer.language,
    };

    if (profile && profile.uid) {
      ticket.accountUid = profile.uid;
      ticket.firstName = profile.person.name;
      ticket.lastName = profile.person.surname;

      const phone = profile.accountPhones && profile.accountPhones.filter((p) => p.primary)[0];
      if (phone) {
        ticket.phone = phone.countryCode + phone.number;
      }
    }

    ticket.customParameters = message.parameters || {};
    ticket.customParameters.version = pjson.version;

    const platform = getPlatform();
    switch (platform) {
      case Platform.Android:
        ticket.customParameters.platform = 'Android';
        break;
      case Platform.iOS:
        ticket.customParameters.platform = 'iOS';
        break;
      default:
        ticket.customParameters.platform = 'WEB';
    }

    const createdTicket = (await OmnideskTicketControllerApi.prototype.createOmnideskTicketUsingPUT(ticket)).body;
    console.log(createdTicket);

    dispatch(sendMessageSuccessAction());
  } catch (err) {
    let errorText = err.toString();
    if (err.response && err.response.data && err.response.data.error) {
      errorText = err.response.data.error;
    }
    dispatch(sendMessageErrorAction(errorText));
  }
};

const sendMessageAction = () => ({
  type: MESSAGE_SENDING,
});

const sendMessageSuccessAction = () => ({
  type: MESSAGE_SENDING_SUCCESS,
});

const sendMessageErrorAction = (error: any) => ({
  type: MESSAGE_SENDING_ERROR,
  error,
});
