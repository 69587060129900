import React, { Component } from "react";
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  Link,
  NavRight,
  Icon,
  PageContent,
  List,
  ListItem,
  Fab,
  Row,
  Col,
} from "framework7-react";
import { compose } from "redux";
import connectFilter from "../store/connectFilter";
import connectCategories from "../store/connectCategories";
import { IApplicationStore } from "../store/rootReducer";
import { IProduct } from "../reducers/productReducer";
import { connect } from "react-redux";
import { ICategoryClassificator } from "../reducers/categoryReducer";
import connectCategoriesClassificator from "../store/connectCategoriesClassificator";
import { Price } from "../components/Price/index";
import Slider, { SliderType, SliderItem } from "../components/Slider";
import {
  loadProductDetails,
  updateProductDraft,
} from "../actions/productCreateActions";
import { withTranslation, WithTranslation } from "react-i18next";
import connectChat, { IChatProps } from "../store/connectChat";
import connectShare, { IShareProps } from "../store/connectShare";
import {
  DescriptionDetails,
  TechnicalDetails,
  CustomDetails,
  CategoryDetails,
  ProductPostedBlock,
  ViewDetails,
  WishDetails,
  ProductStatusNotification,
} from "../components/ProductDetails";
import ProductStatusBadge from "../components/Badges/ProductStatusBadge";
import { Product } from "../types/marketplaceapi";
import { IProductStatusState } from "../reducers/productStatusReducer";
import {
  changeProductStatus,
  updateProductExpirationDate,
} from "../actions/productStatusActions";
import { Button } from "../components/ThemedButton";
import { Sheet } from "../components/Sheet";

import "./categories.less";
import "./my-goods-product-details.less";
import { convertISODateToInputDate, getDaysLeft } from "../utils";
import { ListInput } from "../components/ListInput";
import ShareButton from "../components/ShareButton";
import { getProductDetailsLink } from "../actions/shareActions";
import { PromotionDetailsList } from "../components/PromotionDetailsList";
import { ActiveFeatureDetails } from "../types/marketplaceapi";
import ServicePackageDescription from "../components/ServicePackageList/ServicePackageDescription";
import { createPromotionOrder } from "../actions/ordersActions";
import { IOrdersState } from "../reducers/ordersReducer";
import { VideoPlayer } from "../components/VideoPlayer";

type Props = WithTranslation &
  IChatProps &
  IShareProps & {
    uid?: string;
    loading?: boolean;
    error?: any;
    item?: IProduct;
    categoriesClassificator?: ICategoryClassificator[];
    loadProductDetails?(uid: string): void;
    changeProductStatus?(
      uid: string,
      oldStatus: Product.StatusEnum,
      newStatus: Product.StatusEnum,
      quantity?: number
    ): void;
    updateProductExpirationDate?(uid: string, expirationDate: Date): void;
    productStatusReducer?: IProductStatusState;
    updateProductDraft?(item: IProduct, reset?: boolean): void;
    createPromotionOrder?(featureUid: string, productUid: string): void;
    ordersReducer?: IOrdersState;
  };

type State = {
  productActionsSheetOpened?: boolean;
  productActionsSheetSubmitted?: boolean;
  enterExtendPublicationDateSheetOpened?: boolean;
  featureDetailsSheetOpened?: boolean;
  featureDetails?: ActiveFeatureDetails;
  /* TODO */
  expirationDate: string;
  formErrors?: any;
  formValidFields?: any;
  formValid: boolean;

  playVideoSheetOpened?: boolean;
  videoId?: string;
  videoType?: string;

  imageHoveredItemImageSwitcher?: number;
};

class MyGoodsProductDetailsPage extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      productActionsSheetOpened: false,
      productActionsSheetSubmitted: false,
      enterExtendPublicationDateSheetOpened: false,
      featureDetailsSheetOpened: false,
      featureDetails: null,
      expirationDate: "",
      formErrors: { expirationDate: "" },
      formValidFields: { expirationDate: false },
      formValid: false,
    };
  }

  pageAfterInHandle = () => {
    this.loadProductDetails();
  };

  componentDidUpdate(prevProps: Props) {
    const { loading, error } = this.props;

    loading && !prevProps.loading
      ? this.$f7.preloader.show()
      : this.$f7.preloader.hide();

    if (error && error !== prevProps.error) {
      this.$f7.dialog.alert(error);
    }

    this.handleChangeProductStatus(prevProps);
  }

  componentWillUnmount() {
    const { item } = this.props;
    this.props.updateProductDraft({ ...item, uid: null }, true);
  }

  handleChangeProductStatus(prevProps: Props) {
    const { loading, error, action } = this.props.productStatusReducer;
    const { item } = this.props;

    if (loading && !prevProps.loading) {
      this.$f7.preloader.show();
    } else if (!loading && prevProps.loading) {
      this.$f7.preloader.hide();
    }

    if (error && error !== prevProps.productStatusReducer.error) {
      this.$f7.dialog.alert(error);
    } else if (
      !loading &&
      prevProps.productStatusReducer.loading &&
      action === "changeStatus" &&
      item.status === Product.StatusEnum.DLT
    ) {
      this.$f7router.back();
    }
  }

  loadProductDetails = () => {
    const { uid } = this.props;
    this.props.loadProductDetails(uid);
  };

  renderActiveFeatures() {
    const {
      t,
      item: { activeFeatures },
    } = this.props;

    const features = activeFeatures
      ? Object.keys(activeFeatures).reduce((prev, curr, _i) => {
          return [...prev, ...activeFeatures[curr]];
        }, [])
      : [];

    console.log(features);

    return (
      !!features.length && (
        <>
          <BlockTitle>{t("Promotions")}</BlockTitle>
          <Block>
            <PromotionDetailsList
              items={features}
              onClickItem={this.handlePromoClick}
            />
          </Block>
        </>
      )
    );
  }

  getProductImagesSlides = (): SliderItem[] => {
    const { item } = this.props;
    const thumbnails = Object.keys(item)
      .filter((item) => item.startsWith("imageThumbnailUrl"))
      .map((key) => item[key]);

    return (
      item.images.map((image, i) => ({ image, small: thumbnails[i] })) || []
    );
  };

  handleBlurInput = (e: any) => this.handleUserInput(e);

  handleUserInput = (e: any) => {
    let { name, value, rawValue = null } = e.target;
    value = rawValue !== null && name !== "expireDate" ? rawValue : value;
    // @ts-ignore
    this.setState({ [name]: value }, () => this.validateField(name, value));
  };

  handleInputClear = (e: any) => {
    let { name } = e.target;
    // @ts-ignore
    this.setState({ [name]: "" }, () => this.validateField(name, ""));
  };

  validateField = (fieldName: keyof State, value: string) => {
    let formValidFields = this.state.formValidFields;
    let fieldValidationErrors = this.state.formErrors;
    let errorText = "";
    let requiredFieldErrorText = "Please fill out this field.";

    switch (fieldName) {
      case "expirationDate":
        errorText = value.length ? errorText : requiredFieldErrorText;
        fieldValidationErrors.expirationDate = errorText;
        formValidFields.expirationDate = !errorText.length;
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        formValidFields,
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({
      formValid: !this.formHasErrors(this.state.formValidFields),
    });
  };

  formHasErrors = (formValidFields: any) => {
    return Object.keys(formValidFields).some((key) => !formValidFields[key]);
  };

  getErrorMessagesProps = (fieldName: string) => {
    const { t } = this.props;
    return {
      errorMessage: t(this.state.formErrors[fieldName]),
      errorMessageForce: !!this.state.formErrors[fieldName],
    };
  };

  handlePromoClick = (code: string) => {
    const {
      item: { activeFeatures },
    } = this.props;

    const features = activeFeatures
      ? Object.keys(activeFeatures).reduce((prev, curr, _i) => {
          return [...prev, ...activeFeatures[curr]];
        }, [])
      : [];

    const featureDetails = features.filter((item) => item.typeCode === code)[0];
    this.setState({ featureDetailsSheetOpened: true, featureDetails });
  };

  renderBottomImageSwitcher = (imagesItems: any[]) => {
    if (imagesItems.length === 1) return null;

    return (
      <Block className="image-switcher">
        {imagesItems.slice(0, 5).map((item, i) => {
          return (
            <div
              key={i.toString()}
              className="image-switcher-item"
              onMouseOver={() =>
                this.setState({
                  imageHoveredItemImageSwitcher: i,
                })
              }
            >
              {item.videoId ? (
                <i className="icon material-icons">play_circle_filled</i>
              ) : (
                <img src={item.small} />
              )}
            </div>
          );
        })}
      </Block>
    );
  };

  renderProductDetails = () => {
    const { item, t } = this.props;
    const { imageHoveredItemImageSwitcher } = this.state;

    if (!item) return <Block></Block>;

    let imagesItems = this.getProductImagesSlides();
    if (item.video) {
      /* Video must be first */
      imagesItems = [
        { videoId: item.video.id, videoType: item.video.type.toString() },
        ...imagesItems,
      ];
    }

    return (
      <Row>
        <Col width="100" medium="50">
          <Block>
            <ProductStatusNotification item={item} />
          </Block>
          <Block className="no-padding images">
            <Slider
              className="slider-images"
              slides={imagesItems}
              type={SliderType.images}
              videoPlayOnClick={(videoId, videoType) => {
                this.setState({
                  playVideoSheetOpened: true,
                  videoId,
                  videoType,
                });
              }}
              slideIndex={imageHoveredItemImageSwitcher}
            />
          </Block>

          {/* Bottom image switcher */}
          <div className="pure-hidden-xs">
            {this.renderBottomImageSwitcher(imagesItems)}
          </div>
        </Col>

        <Col width="100" medium="50">
          <BlockTitle className="title" medium>
            <div style={{ display: "flex" }}>
              <span>{item.name}</span>
              <div className="pure-hidden-xs">
                <ProductStatusBadge status={item.status} />
              </div>
            </div>
            {item.status === Product.StatusEnum.PBL && (
              <>
                <div className="buttons-container pure-visible-xs">
                  <ShareButton
                    onClick={() =>
                      this.props.share(
                        item.name,
                        getProductDetailsLink(item.uid)
                      )
                    }
                  />
                </div>
                <div className="product-stats pure-hidden-xs">
                  <ViewDetails count={item.viewCount} />
                  <WishDetails count={item.wishCount} />
                </div>
              </>
            )}
          </BlockTitle>

          <Block>
            <div className="pure-visible-xs">
              <ProductStatusBadge status={item.status} />
            </div>
            <div className="product-stats pure-visible-xs">
              <ViewDetails count={item.viewCount} />
              <WishDetails count={item.wishCount} />
            </div>
            <DescriptionDetails
              text={item.description}
              moreLinkText={t("More")}
              lessLinkText={t("Less")}
            />
            <Price
              price={item.price}
              discountedPrice={item.discountedPrice}
              currencyCode={item.currencyCode}
            />
          </Block>

          <TechnicalDetails product={item} />
          <CustomDetails product={item} />
          <CategoryDetails categoryCode={item.category} />
          <ProductPostedBlock
            date={item.productDate}
            postedBlockTitle={t("Posted")}
          />

          {this.renderActiveFeatures()}

          <div className="pure-visible-xs">
            {this.renderActionBottomButtons()}
          </div>
        </Col>
      </Row>
    );
  };

  renderActionBottomButtons = () => {
    const { item, t } = this.props;
    if (!item) return null;

    const { uid, status } = item;

    switch (status) {
      case Product.StatusEnum.DRF:
        return (
          <Block>
            <Button
              large
              fill
              round
              onClick={() =>
                this.$f7.dialog.confirm(t("Continue?"), () =>
                  this.props.changeProductStatus(
                    uid,
                    status,
                    Product.StatusEnum.AFR
                  )
                )
              }
            >
              {t("Send to Review")}
            </Button>
          </Block>
        );
      case Product.StatusEnum.APR:
        return (
          <Block>
            <Button
              large
              fill
              round
              onClick={() =>
                this.$f7.dialog.confirm(t("Continue?"), () =>
                  this.props.changeProductStatus(
                    uid,
                    status,
                    Product.StatusEnum.PBL
                  )
                )
              }
            >
              {t("Publish")}
            </Button>
          </Block>
        );
      case Product.StatusEnum.EXP:
        return (
          <Block>
            <Button
              large
              fill
              round
              onClick={() =>
                this.setState({ enterExtendPublicationDateSheetOpened: true })
              }
            >
              {t("Extend publication")}
            </Button>
          </Block>
        );
      case Product.StatusEnum.OOS:
        return (
          <Block>
            <Button large fill round onClick={this.changeQuantity}>
              {t("Replenish Quantity")}
            </Button>
          </Block>
        );
      case Product.StatusEnum.PBL:
        return (
          <Block>
            <Button
              large
              fill
              round
              onClick={() => this.$f7router.navigate("./promote/")}
            >
              {t("Promote the product")}
            </Button>
          </Block>
        );
      default:
        return null;
    }
  };

  blurHandle = (e) => {
    console.log(e.target.value);
  };

  changeQuantity = () => {
    const {
      t,
      item: { uid, status, quantity },
    } = this.props;
    const dialog = this.$f7.dialog.prompt(
      t("Please, enter quantity"),
      (val: string) => {
        let quantity: number;
        try {
          quantity = parseFloat(val);
        } catch (err) {}
        if (quantity > 0) {
          /* TODO: move new status to actions! */
          this.setState({
            productActionsSheetOpened: false,
            enterExtendPublicationDateSheetOpened: false,
          });
          this.props.changeProductStatus(
            uid,
            status,
            Product.StatusEnum.PBL,
            quantity
          );
        } else {
          this.$f7.dialog.alert(t("Quantity should be positive number!"));
        }
      },
      () => {},
      quantity.toString()
    );

    const el = dialog.$el.find("input").attr("type", "number")[0] as any;
    el.select();
  };

  renderActionSheetItems = () => {
    const { item, t } = this.props;
    if (!item || !this.state.productActionsSheetOpened) return null;

    const { uid, status } = item;

    switch (status) {
      case Product.StatusEnum.APR:
        return (
          <List>
            <ListItem
              link
              title={t("Edit").toString()}
              onClick={() => this.navigateToEdit(uid)}
            />
            <ListItem
              link
              title={t("Withdraw from sale").toString()}
              onClick={() =>
                this.$f7.dialog.confirm(t("Continue?"), () => {
                  this.setState({ productActionsSheetOpened: false }, () => {
                    /* TODO (fix error with React DOM) */
                    setTimeout(
                      () =>
                        this.props.changeProductStatus(
                          uid,
                          status,
                          Product.StatusEnum.DSC
                        ),
                      350
                    );
                  });
                })
              }
              noChevron
            />
          </List>
        );
      case Product.StatusEnum.PBL:
        return (
          <List>
            <ListItem
              link
              title={t("Edit").toString()}
              onClick={() => this.navigateToEdit(uid)}
            />
            <ListItem
              link
              title={t("Change quantity").toString()}
              onClick={this.changeQuantity}
              noChevron
            />
            <ListItem
              link
              title={t("Pause the sale of goods").toString()}
              onClick={() =>
                this.$f7.dialog.confirm(t("Continue?"), () => {
                  this.setState({ productActionsSheetOpened: false }, () => {
                    /* TODO (fix error with React DOM) */
                    setTimeout(
                      () =>
                        this.props.changeProductStatus(
                          uid,
                          status,
                          Product.StatusEnum.SUS
                        ),
                      350
                    );
                  });
                })
              }
              noChevron
            />
          </List>
        );
      case Product.StatusEnum.SUS:
        return (
          <List>
            <ListItem
              link
              title={t("Edit").toString()}
              onClick={() => this.navigateToEdit(uid)}
            />
            <ListItem
              link
              title={t("Change quantity").toString()}
              onClick={this.changeQuantity}
              noChevron
            />
            <ListItem
              link
              title={t("Withdraw from sale").toString()}
              onClick={() =>
                this.$f7.dialog.confirm(t("Continue?"), () => {
                  this.setState({ productActionsSheetOpened: false }, () => {
                    /* TODO (fix error with React DOM) */
                    setTimeout(
                      () =>
                        this.props.changeProductStatus(
                          uid,
                          status,
                          Product.StatusEnum.DSC
                        ),
                      350
                    );
                  });
                })
              }
              noChevron
            />
            <ListItem
              link
              title={t("Resume selling of goods").toString()}
              onClick={() =>
                this.$f7.dialog.confirm(t("Continue?"), () => {
                  this.setState({ productActionsSheetOpened: false }, () => {
                    /* TODO (fix error with React DOM) */
                    setTimeout(
                      () =>
                        this.props.changeProductStatus(
                          uid,
                          status,
                          Product.StatusEnum.PBL
                        ),
                      350
                    );
                  });
                })
              }
              noChevron
            />
          </List>
        );
    }

    return null;
  };

  navigateToEdit = (uid: string) => {
    this.setState(
      {
        productActionsSheetOpened: false,
        enterExtendPublicationDateSheetOpened: false,
      },
      () => {
        this.$f7router.back();
        setTimeout(() => this.$f7router.navigate(`edit/${uid}/1/`), 500);
      }
    );
  };

  renderLinks() {
    const { item, t } = this.props;
    if (!item) return null;

    const { uid, status } = item;

    if (status === Product.StatusEnum.DRF) {
      return (
        <>
          <Link href="#" onClick={() => this.navigateToEdit(uid)}>
            <Icon material="create" />
          </Link>
          <Link
            href="#"
            onClick={() => {
              this.$f7.dialog.confirm(t("Really?"), () => {
                this.props.changeProductStatus(
                  uid,
                  status,
                  Product.StatusEnum.DLT
                );
              });
            }}
          >
            <Icon material="delete_outline" />
          </Link>
        </>
      );
    } else if (status === Product.StatusEnum.DCL_2) {
      return (
        <Link href="#" onClick={() => this.navigateToEdit(uid)}>
          <Icon material="create" />
        </Link>
      );
    } else if (
      status === Product.StatusEnum.APR ||
      status === Product.StatusEnum.PBL ||
      status === Product.StatusEnum.SUS
    ) {
      return (
        <Link
          href="#"
          onClick={() => {
            this.setState({
              productActionsSheetOpened: !this.state.productActionsSheetOpened,
            });
          }}
        >
          <Icon material="more_vertical" />
        </Link>
      );
    }

    return null;
  }

  render() {
    const { t, item } = this.props;
    const {
      featureDetailsSheetOpened,
      featureDetails,
      playVideoSheetOpened,
      videoId,
      videoType,
    } = this.state;

    return (
      <Page
        id="my_goods_product_details"
        name="my-goods-product-details"
        onPageInit={this.pageAfterInHandle}
      >
        <Navbar title="" backLink={t("Back").toString()} noHairline noShadow>
          <NavRight>
            <ShareButton
              onClick={() =>
                this.props.share(item.name, getProductDetailsLink(item.uid))
              }
              className="pure-hidden-xs"
            />
            {this.renderLinks()}
            {/* TODO: replace with container */}
            <div className="pure-hidden-xs">
              {this.renderActionBottomButtons()}
            </div>
            {/*
            <Button
              href="#"
              large
              fill
              round
              onClick={() => this.$f7router.navigate("./promote/")}
              className="pure-hidden-xs"
            >
              {t("Promote the product")}
            </Button>
            */}
          </NavRight>
        </Navbar>

        {this.renderProductDetails()}

        {/* Sheet modals */}
        <Sheet
          id="product_actions_sheet"
          swipeToClose
          backdrop
          opened={this.state.productActionsSheetOpened}
          onSheetClosed={() =>
            this.setState({ productActionsSheetOpened: false })
          }
        >
          <PageContent>{this.renderActionSheetItems()}</PageContent>
        </Sheet>

        {/* TODO */}
        <Sheet
          id="enter_extend_publication_date"
          swipeToClose
          backdrop
          opened={this.state.enterExtendPublicationDateSheetOpened}
          onSheetOpen={() =>
            this.setState(
              {
                expirationDate: convertISODateToInputDate(
                  item.expirationDate.toString()
                ),
                formValidFields: { expirationDate: true },
                formErrors: [],
              },
              () => this.validateForm()
            )
          }
          onSheetClosed={() => {
            if (
              this.state.productActionsSheetSubmitted &&
              this.state.formValidFields.expirationDate
            ) {
              let expdate = new Date(this.state.expirationDate);
              this.props.updateProductExpirationDate(item.uid, expdate);
            }
            this.setState({
              enterExtendPublicationDateSheetOpened: false,
              productActionsSheetSubmitted: false,
            });
          }}
        >
          <PageContent>
            <BlockTitle medium>{t("Extend publication")}</BlockTitle>
            {item && this.state.enterExtendPublicationDateSheetOpened && (
              <>
                <List noHairlines form>
                  <ListInput
                    name="expirationDate"
                    label={t("Expiration date").toString()}
                    floatingLabel
                    type="date"
                    placeholder=""
                    clearButton
                    slot="list"
                    onBlur={this.handleBlurInput}
                    onChange={this.handleUserInput}
                    onInputClear={this.handleInputClear}
                    value={this.state.expirationDate}
                    {...this.getErrorMessagesProps("expirationDate")}
                  />
                </List>
                <Fab
                  position="right"
                  onClick={() => {
                    if (!this.state.formValid) {
                      this.$f7.dialog.alert(
                        t("Please fill field with valid data!")
                      );
                      return;
                    }
                    this.setState({
                      enterExtendPublicationDateSheetOpened: false,
                      productActionsSheetSubmitted: true,
                    });
                  }}
                  slot="fixed"
                >
                  <Icon ios="f7:checkmark_alt" md="material:check" />
                </Fab>
              </>
            )}
          </PageContent>
        </Sheet>

        <Sheet
          id="promo_details_sheet"
          swipeToClose
          backdrop
          opened={featureDetailsSheetOpened}
          onSheetClosed={() =>
            this.setState({
              featureDetailsSheetOpened: false,
              featureDetails: null,
            })
          }
          style={{ height: "auto" }}
        >
          <PageContent>
            {featureDetails && (
              <Block>
                <ServicePackageDescription
                  code={featureDetails.code}
                  typeCode={featureDetails.typeCode}
                  price={featureDetails.price}
                  duration={`${getDaysLeft(featureDetails.expireDate)} ${t(
                    "days left"
                  )}`}
                  title={featureDetails.typeName}
                  description={featureDetails.typeDescription}
                  full
                />
              </Block>
            )}
          </PageContent>
        </Sheet>

        <Sheet
          id="play_video_sheet"
          swipeToClose
          backdrop
          opened={playVideoSheetOpened}
          onSheetClosed={() =>
            this.setState({
              playVideoSheetOpened: false,
              videoId: null,
              videoType: null,
            })
          }
          style={{ backgroundColor: "#000000" }}
        >
          <VideoPlayer videoId={videoId} videoType={videoType} />
        </Sheet>
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore, props: Props) => ({
  loading: state.productCreateReducer.loading,
  error: state.productCreateReducer.error,
  item:
    state.productCreateReducer.product &&
    state.productCreateReducer.product.uid === props.uid
      ? state.productCreateReducer.product
      : null,
  productStatusReducer: state.productStatusReducer,
  ordersReducer: state.ordersReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadProductDetails: (uid: string) => dispatch(loadProductDetails(uid)),
  changeProductStatus: (
    uid: string,
    oldStatus: Product.StatusEnum,
    newStatus: Product.StatusEnum,
    quantity?: number
  ) => dispatch(changeProductStatus(uid, oldStatus, newStatus, quantity)),
  updateProductExpirationDate: (uid: string, expirationDate: Date) =>
    dispatch(updateProductExpirationDate(uid, expirationDate)),
  updateProductDraft: (item: IProduct, reset: boolean) =>
    dispatch(updateProductDraft(item, reset)),
  createPromotionOrder: (featureUid: string, productUid: string) =>
    dispatch(createPromotionOrder(featureUid, productUid, { source: "card" })),
});

export default compose(
  withTranslation(),
  connectFilter,
  connectCategories,
  connectCategoriesClassificator,
  connectChat,
  connectShare,
  connect(mapStateToProps, mapDispatchToProps)
)(MyGoodsProductDetailsPage);
