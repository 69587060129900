import { ISearchParams, mapProductDetailsImage } from "./productActions"
import { IApplicationStore } from "../store/rootReducer"
import { IProduct } from "../reducers/productReducer"
import { isProductInWish } from "./profileActions"
import {
  ProductControllerApi,
  PublicControllerApi,
} from "../types/marketplaceapi"

export const ALL_DEALS_LOADING = 'ALL_DEALS_LOADING'
export const ALL_DEALS_LOADING_SUCCESS = 'ALL_DEALS_LOADING_SUCCESS'
export const ALL_DEALS_LOADING_ERROR = 'ALL_DEALS_LOADING_ERROR'
export const ALL_DEALS_RELOAD = 'ALL_DEALS_RELOAD'

export const loadAllDeals = (searchParams: ISearchParams) => async (dispatch: any, getState: () => IApplicationStore) => {
  dispatch(allDealsLoadingAction())

  const state = getState()
  const { logged } = state.sessionReducer;

  try {
    let items: IProduct[];
    if (logged) {
      items = (await new ProductControllerApi().productSearchUsingPOST(searchParams)).body || []
    } else {
      items = (await new PublicControllerApi().productSearchUsingPOST1(searchParams)).body || []
    }
    items.forEach(item => {
      item.wish = isProductInWish(item.uid, state)
      item.images = mapProductDetailsImage(item)
    })
    dispatch(allDealsLoadingSuccessAction(items))
  } catch (error) {
    dispatch(allDealsLoadingErrorAction(error.toString()))
  }
}

export const reloadAllDeals = ()  => async (dispatch: any) => {
  dispatch(allDealsReloadAction());
};

const allDealsLoadingAction = () => ({
  type: ALL_DEALS_LOADING
})

const allDealsLoadingSuccessAction = (products: IProduct[]) => ({
  type: ALL_DEALS_LOADING_SUCCESS,
  products,
})

const allDealsLoadingErrorAction = (error: any) => ({
  type: ALL_DEALS_LOADING_ERROR,
  error
})

const allDealsReloadAction = () => ({
  type: ALL_DEALS_RELOAD
})
