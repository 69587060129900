import React, { FC, useState } from "react";
import { BlockTitle, Col, Icon, Link, List, ListItem, Page, Preloader } from "framework7-react";
import { selectPaymentAddress, selectPaymentMethod } from "../../../actions/checkoutActions";
import classNames from "classnames";
import { AddCardListItem } from "../../../components/AddCardListItem";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationStore } from "../../../store/rootReducer";
import { useTranslation } from "react-i18next";
import PaymentCardCreatePage from "../../payment-card-create";
import { SavedCard } from "../../../reducers/paymentCardsReducer";
import { loadLinkedPaymentCardsList, savePaymentCardTemporary } from "../../../actions/paymentCardsActions";
import { Sheet } from "../../../components/Sheet";
import { getProfile } from "../../../selectors/profile";
import { IClassificator } from "../../../reducers/classificatorReducer";

interface CardListProps {
  loading?: boolean;
  onSelected?: (code: string) => void;
}

const ExternalPayment: FC<CardListProps> = ({ onSelected }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState("");
  const options = useSelector(
    (store: IApplicationStore) => store.classificatorReducer.entitiesClassificators.Payment_Options
  );

  const selectHandler = (code: string) => {
    setSelected(code);
  };

  const icons = {
    bankcard: "credit_card",
    cod: "account_balance_wallet",
  };

  const renderOptions = (option: IClassificator) => {
    return (
      <ListItem
        radio
        name="payment-method-media-radio"
        key={option.code}
        value={option.code}
        title={t(option.value).toString()}
        onClick={() => {
          selectHandler(option.code);
          onSelected(option.code);
        }}
        checked={selected === option.code}
      >
        <Icon slot="media" material={icons[option.code]} />
      </ListItem>
    );
  };

  return (
    <>
      <BlockTitle medium>
        <span>{t("Payment method")}</span>
      </BlockTitle>
      <List noHairlines noChevron>
        <ul>{options.map(renderOptions)}</ul>
      </List>
    </>
  );
};

export default ExternalPayment;
