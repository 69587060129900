import React, { Component } from "react";
import { F7Navbar, Icon, Link, NavLeft, NavRight } from "framework7-react";
import ToMessengerButton from "../to-messenger-button/ToMessengerButton";
import { withTranslation, WithTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { Logo } from "../Logo";
import { OpenCategoriesButton } from "../open-categories-button";
import { SearchBar } from "../search-bar";
import { ConnectedCartLink } from "../cart-link";
import MyWishButton from "../MyWishButton";
import { LanguageLink } from "../LanguageLink";
import { Profile } from "../../reducers/sessionReducer";
import { connect } from "react-redux";
import { IApplicationStore, ResizeEvent } from "../../store/rootReducer";
import "./styles.less";
import Dom7 from "dom7";
import { createUUID } from "../../utils";
import { searchProducts } from "../../actions/productActions";
import debounce from "lodash/debounce";
import { IProduct } from "../../reducers/productReducer";
import ProfileMenu from "../ProfileMenu";
import { Router } from "framework7/modules/router/router";

type Props = {
  showMessengerButton?: boolean;
  onClickGoToMessenger?(): void;
  onClickLogoLink?(): void;
  showCategoriesMenu?: boolean;
  onClickOpenCategoriesMenu?(): void;
  openCategoriesMenuButton?: boolean;
  onSearchbarEnable?(): void;
  onSearchbarDisable?(): void;
  onSearchInputChange?(val: string, autocomplete?: boolean): void;
  onSearchClickClear?(): void;
  showSearchbar?: boolean;
  showCartLink?: boolean;
  showWishList?: boolean;
  language?: string;
  showLanguageLink?: boolean;
  onClickLanguageLink?(): void;
  profile?: Profile;
  showProfileLink?: boolean;
  findedProducts?: IProduct[];
  findedProductsCount?: number;
  findProductLoading?: boolean;
  onFindedProductItemClick?(uid: string): void;
  resizeEvent?: ResizeEvent;
  onSearchResult?(): void;
  showBackLink?: boolean;
  f7router?: Router.Router;
  activeTrigger?: boolean;
} & F7Navbar.Props;

class Navbar extends Component<Props & WithTranslation & { resizeEvent?: ResizeEvent }> {
  private _searchbarId: string;
  private _searchbar: any;

  constructor(props: any) {
    super(props);
    this._searchbarId = "_" + createUUID().replace(/-/g, "_");
  }

  componentDidUpdate() {
    this.initSearhbar();
  }

  componentWillUnmount() {
    this.$f7.off("click", this.onClickOutsideSearchbarHandler);
  }

  initSearhbar() {
    if (!this._searchbar) {
      const el = Dom7(`#${this._searchbarId}`)[0] as any;
      if (el) {
        this._searchbar = this.$f7.searchbar.create({
          el: Dom7(`#${this._searchbarId}`)[0] as any,
        });
        this.initSearchbarDisableBehavior();
      }
    }
  }

  initSearchbarDisableBehavior() {
    this.$f7.on("click", this.onClickOutsideSearchbarHandler);
  }

  onClickOutsideSearchbarHandler = (ev: any) => {
    const selector = `#${this._searchbarId}`;
    if (this._searchbar) {
      if (!Dom7(ev.target).closest(selector).length && !this._searchbar.value) {
        this._searchbar.disable();
      }
    }
  };

  renderProfileLink() {
    const { showProfileLink } = this.props;
    return showProfileLink && <ProfileMenu f7router={this.props.f7router} showLogin={this.props.activeTrigger} />;
  }

  renderLanguageLink() {
    const { showLanguageLink, onClickLanguageLink, language, resizeEvent, t } = this.props;
    return (
      resizeEvent &&
      resizeEvent.width > 567 &&
      showLanguageLink && <LanguageLink onClick={onClickLanguageLink} language={language} t={t} />
    );
  }

  onSearchInputChange = () => {
    this.delayedQuery(this._searchbar.value);
  };

  delayedQuery = debounce((q) => this.props.onSearchInputChange(q, this.props.resizeEvent.width > 567), 500);

  onShowMoreSearchResults = () => {
    this.props.onSearchResult();
    this.props.onSearchInputChange(this._searchbar.value, false);
    this._searchbar.disable();
  };

  render() {
    const {
      showMessengerButton,
      onClickGoToMessenger,
      onClickLogoLink,
      showCategoriesMenu,
      onClickOpenCategoriesMenu,
      openCategoriesMenuButton,
      onSearchClickClear,
      onSearchInputChange,
      onSearchbarDisable,
      onSearchbarEnable,
      showCartLink,
      showWishList,
      showSearchbar,
      findedProducts,
      findedProductsCount,
      findProductLoading,
      onFindedProductItemClick,
      resizeEvent,
      showBackLink,
      t,
      ...rest
    } = this.props;
    return (
      <F7Navbar sliding noShadow noHairline {...rest}>
        <NavLeft
          style={{
            flex: "auto" /* for correct search bar sliding in iOS */,
          }}
        >
          {showMessengerButton && (
            <ToMessengerButton text={t("To Messenger")} className="pure-hidden-xs" onClick={onClickGoToMessenger} />
          )}
          {showBackLink && (
            <Link iconOnly style={{ flex: "0 0 auto" }} onClick={onClickLogoLink}>
              <Icon className="icon-back" />
            </Link>
          )}
          <Link className="pure-hidden-xs" style={{ flex: "0 0 auto" }} onClick={onClickLogoLink}>
            <Logo full />
          </Link>
          <div
            style={{
              display: "flex",
              flex: "1 1 auto",
              justifyContent: "center",
            }}
          >
            {showCategoriesMenu && (
              <OpenCategoriesButton
                className="pure-hidden-xs"
                text={t("Categories")}
                onClick={onClickOpenCategoriesMenu}
                opened={openCategoriesMenuButton}
              />
            )}
            {showSearchbar && (
              <SearchBar
                id={this._searchbarId}
                noShadow
                noHairline
                backdrop
                customSearch
                onSearchbarEnable={onSearchbarEnable}
                onSearchbarDisable={() => {
                  onSearchbarDisable();
                }}
                onChange={this.onSearchInputChange}
                onClickClear={onSearchClickClear}
                findedProducts={findedProducts}
                findedProductsCount={findedProductsCount}
                onFindedProductItemClick={(uid) => {
                  this._searchbar.disable();
                  onFindedProductItemClick(uid);
                }}
                autocomplete={
                  (findProductLoading || (findedProducts && findedProducts.length > 0)) &&
                  this._searchbar &&
                  this._searchbar.enabled &&
                  resizeEvent.width > 567
                }
                preloader={findProductLoading}
                onShowMore={this.onShowMoreSearchResults}
              />
            )}
          </div>
        </NavLeft>
        <NavRight>
          {this.renderLanguageLink()}
          {showCartLink ? <ConnectedCartLink /> : showWishList ? <MyWishButton /> : null}
          {this.renderProfileLink()}
        </NavRight>
      </F7Navbar>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  resizeEvent: state.rootReducer.resizeEvent,
});

const mapStateToPropsDependsOfRoute = (state: IApplicationStore, ownProps: Props) => {
  const {
    rootReducer: { f7, resizeEvent },
  } = state;
  const {
    view: { current },
  } = f7;
  const {
    cartReducer: { items },
  } = state;

  const isDetailsPage =
    current && current.router.currentRoute.url && current.router.currentRoute.url.includes("/product-details/");
  const isCartHasItems = items.length > 0;

  if (isCartHasItems) {
    ownProps.showCartLink = true;
  }

  if (isDetailsPage && resizeEvent.width > 567) {
    ownProps.showSearchbar = true;
  }

  return ownProps;
};

const dependsOfRoutesConnector = connect(mapStateToPropsDependsOfRoute);
const mapDispatchToPropsSearchBehaviorConnector = (dispatch: any) =>
  bindActionCreators(
    {
      onSearchInputChange: (val: string, autocomplete = false) => {
        return searchProducts(
          {
            name: val,
            category: null,
          },
          autocomplete
        );
      },
    },
    dispatch
  );

export default compose<React.FC<Props>>(
  connect(mapStateToProps, null),
  connect(null, mapDispatchToPropsSearchBehaviorConnector),
  dependsOfRoutesConnector,
  withTranslation()
)(Navbar);
