import React, { FC } from "react";
import { List } from "framework7-react";
import { ListInput } from "./ListInput";
import { useTranslation } from "react-i18next";
import { IApplicationStore } from "../store/rootReducer";
import { useSelector } from "react-redux";
import { DeliveryAddress } from "./ThirdParty/ThirdPartyOrder";
import { defaultCountryCode } from "../actions/checkoutActions";

export interface DeliveryErrorMessages {
  countryCode?: string;
  city?: string;
  firstAddressLine?: string;
  secondAddressLine?: string;
  postalCode?: string;
  dueDate?: string;
}

interface DeliveryInfoEditFormProps {
  onChange(fieldName, value): void;
  address: DeliveryAddress;
  errors?: DeliveryErrorMessages;
  onFieldClick: () => void;
}

const DeliveryInfoEditForm: FC<DeliveryInfoEditFormProps> = ({ onChange, address, errors, onFieldClick }) => {
  const { t } = useTranslation();

  //REDUX STATES
  const [countryList, cityList] = useSelector((state: IApplicationStore) => [
    state.classificatorReducer.entitiesClassificators.Delivery_Country,
    state.classificatorReducer.entitiesClassificators.Delivery_City,
  ]);

  const flagStates = useSelector((state: IApplicationStore) =>
    state.classificatorReducer.countryClassificator.filter((c) => c.code !== defaultCountryCode)
  );

  //FUNCTIONS
  const handleChange = ({ target }) => onChange(target.name, target.value);

  return (
    <List noHairlinesMd form noHairlinesBetweenMd className="no-margin-vertical">
      <ListInput
        name="countryCode"
        label={t("Country").toString()}
        type="select"
        placeholder={t("Please select country").toString()}
        onFocus={onFieldClick}
        errorMessage={errors.countryCode}
        errorMessageForce
        value={address.countryCode}
        onChange={handleChange}
        slot="list"
      >
        <option value="">{t("Please select country")}</option>
        {countryList.map((country) => (
          <option key={country.code} value={country.code}>
            {country.value}
          </option>
        ))}
      </ListInput>

      <ListInput
        name="city"
        label={t("City").toString()}
        type="select"
        placeholder={t("Please select city").toString()}
        onFocus={onFieldClick}
        errorMessage={errors.city}
        errorMessageForce
        onChange={handleChange}
        slot="list"
      >
        <option value="">{address.countryCode ? t("Please select city") : t("Please select country first")}</option>
        {cityList
          .filter((city) => city.dependency === address.countryCode)
          .map((city) => (
            <option key={city.code} value={city.code}>
              {city.value}
            </option>
          ))}
      </ListInput>

      <ListInput
        name="firstAddressLine"
        label={t("Address Line").toString()}
        floatingLabel
        type="text"
        placeholder=""
        onFocus={onFieldClick}
        errorMessage={errors.firstAddressLine}
        errorMessageForce
        onBlur={handleChange}
        slot="list"
        autocomplete="off"
      />

      <ListInput
        name="postalCode"
        label={t("Flag State").toString()}
        type="select"
        placeholder={t("Please select country").toString()}
        onFocus={onFieldClick}
        errorMessage={errors.secondAddressLine}
        errorMessageForce
        onBlur={handleChange}
        slot="list"
      >
        <option value="">{t("Please select country")}</option>
        {flagStates.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </ListInput>

      <ListInput
        name="dueDate"
        label={t("Estimated Date of Arrival").toString()}
        type="date"
        onFocus={onFieldClick}
        errorMessage={errors.dueDate}
        errorMessageForce
        onChange={handleChange}
        slot="list"
        autocomplete="off"
      />
    </List>
  );
};

export default DeliveryInfoEditForm;
