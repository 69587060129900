import { AnyAction } from "redux";
import {
  ACCOUNT_ADD_UPDATE_ADDRESS,
  ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS,
  ACCOUNT_ADD_UPDATE_ADDRESS_ERROR,
  ACCOUNT_REMOVE_ADDRESS,
  ACCOUNT_REMOVE_ADDRESS_SUCCESS,
  ACCOUNT_REMOVE_ADDRESS_ERROR,
} from "../actions/profileActions";
import {
  SESSION_RESTORING,
  SESSION_LOADING,
  SESSION_SUCCESS,
  SESSION_LOGOUT,
  SESSION_ERROR,
  REGISTRATION_ERROR,
  REGISTRATION_LOADING,
  REGISTRATION_SUCCESS,
  VALIDATION_SUCCESS,
  VALIDATION_LOADING,
  REGISTRATION_CLEAR,
  ONETIME_PASSWORD_SUCCESS,
  ONETIME_PASSWORD_LOADING,
  ONETIME_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  ONETIME_PASSWORD_LOGIN_LOADING,
  ONETIME_PASSWORD_LOGIN_SUCCESS,
  ONETIME_PASSWORD_LOGIN_ERROR,
} from "../actions/sessionActions";
import { Account, CommonApiResponse, CreatePersonalAccountRequest } from "../types/commonapi";

export interface Token extends CommonApiResponse {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  active: boolean;
}

export interface Profile extends Account {}

export interface CreateAccountRequest extends CreatePersonalAccountRequest {
  phone: string;
  city?: string;
  addressLine?: string;
  postalCode?: string;
  passwordRepeat?: string;
  accept: boolean;
}

export interface ICreateAccountFormError {
  message: string;
  parameters: string[];
}

export interface ISessionState {
  restoring: boolean;
  loading: boolean;
  profile?: Profile;
  error: any;
  logged: boolean;
  registered: boolean;
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  formErrors: ICreateAccountFormError[];
  request: CreateAccountRequest;
  registrationLoading: boolean;
  registrationError: any;
  passwordResetLoading: boolean;
  passwordLoginLoading: boolean;
  passwordChangeLoading: boolean;
  passwordError: any;
}

const emptyRequest: CreateAccountRequest = {
  firstName: "",
  lastName: "",
  birthDate: null,
  email: "",
  phone: "",
  country: "",
  city: "",
  postalCode: "",
  addressLine: "",
  password: "",
  passwordRepeat: "",
  accept: false,
};

const initialState: ISessionState = {
  restoring: false,
  loading: false,
  profile: null,
  error: null,
  logged: false,
  registered: false,
  accessToken: null,
  expiresIn: null,
  refreshToken: null,
  formErrors: [],
  request: emptyRequest,
  registrationLoading: false,
  registrationError: null,
  passwordResetLoading: false,
  passwordLoginLoading: false,
  passwordChangeLoading: false,
  passwordError: null,
};

const sessionReducer = (state = initialState, action: AnyAction): ISessionState => {
  switch (action.type) {
    case SESSION_RESTORING:
      return {
        ...state,
        restoring: true,
      };
    case SESSION_LOADING:
      return {
        ...state,
        restoring: false,
        logged: false,
        loading: true,
        error: null,
      };
    case SESSION_SUCCESS:
      return {
        ...state,
        restoring: false,
        loading: false,
        profile: action.profile,
        error: null,
        logged: true,
        ...action.authData,
      };
    case SESSION_ERROR:
      return {
        ...state,
        restoring: false,
        loading: false,
        profile: null,
        error: action.error,
        passwordError: action.error,
        logged: false,
      };
    case SESSION_LOGOUT:
      return initialState;
    case REGISTRATION_LOADING:
      return {
        ...state,
        restoring: false,
        loading: false,
        error: null,
        formErrors: initialState.formErrors,
        logged: false,
        registered: false,
        registrationLoading: true,
        registrationError: null,
      };
    case VALIDATION_LOADING:
      return {
        ...state,
        restoring: false,
        loading: false,
        error: null,
        formErrors: initialState.formErrors,
        logged: false,
        registered: false,
        registrationLoading: true,
        registrationError: null,
      };
    case VALIDATION_SUCCESS:
      if (state.request.birthDate === null) {
        //state.request.birthDate = moment('2000-01-01').format('YYYY-MM-DD');
      }
      return {
        ...state,
        restoring: false,
        loading: false,
        error: null,
        formErrors: initialState.formErrors,
        logged: false,
        registered: false,
        registrationLoading: false,
        registrationError: null,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        restoring: false,
        loading: false,
        //profile: action.profile,
        error: null,
        logged: false,
        registered: true,
        registrationLoading: false,
        registrationError: null,
      };
    case REGISTRATION_ERROR:
      state.request.passwordRepeat = state.request.password;
      state.request.accept = false;
      return {
        ...state,
        restoring: false,
        loading: false,
        profile: null,
        error: null,
        logged: false,
        registered: false,
        registrationLoading: false,
        registrationError: action.error,
        formErrors: action.parameters
          ? [...state.formErrors, { message: action.error, parameters: action.parameters }]
          : state.formErrors,
      };
    case REGISTRATION_CLEAR:
      return {
        ...state,
        restoring: false,
        loading: false,
        profile: null,
        error: null,
        logged: false,
        registered: false,
        registrationLoading: false,
        registrationError: null,
        request: initialState.request,
        formErrors: [],
      };
    case ONETIME_PASSWORD_LOADING:
      return {
        ...state,
        passwordError: null,
        logged: false,
        profile: null,
        passwordResetLoading: true,
      };
    case ONETIME_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordError: null,
        logged: false,
        profile: null,
        passwordResetLoading: false,
      };
    case ONETIME_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.error,
        logged: false,
        profile: null,
        passwordResetLoading: false,
      };
    case ONETIME_PASSWORD_LOGIN_LOADING:
      return {
        ...state,
        passwordError: null,
        logged: false,
        profile: null,
        passwordLoginLoading: true,
      };
    case ONETIME_PASSWORD_LOGIN_SUCCESS:
      return {
        ...state,
        //logged: true,
        //profile: action.profile,
        passwordError: null,
        passwordLoginLoading: false,
        ...action.authData,
      };
    case ONETIME_PASSWORD_LOGIN_ERROR:
      return {
        ...state,
        passwordError: action.error,
        logged: false,
        profile: null,
        passwordLoginLoading: false,
      };
    case CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        passwordError: null,
        passwordChangeLoading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordError: null,
        passwordChangeLoading: false,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.error,
        passwordChangeLoading: false,
      };
    case ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS:
    case ACCOUNT_REMOVE_ADDRESS_SUCCESS: {
      const { addresses } = action;
      const { profile } = state;

      if (!profile) {
        return state;
      }

      profile.addresses = [...addresses];

      return {
        ...state,
        profile: { ...profile },
      };
    }
    case ACCOUNT_ADD_UPDATE_ADDRESS:
    case ACCOUNT_ADD_UPDATE_ADDRESS_ERROR:
    case ACCOUNT_REMOVE_ADDRESS:
    case ACCOUNT_REMOVE_ADDRESS_ERROR: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default sessionReducer;
