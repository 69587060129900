import React from "react";
import { Device } from "../framework7-custom";
import {
  App,
  View,
} from "framework7-react";
import { Provider } from "react-redux";

import cordovaApp from "../cordova-app";
import routes from "../routes";
import configureStore from "../store/Store";
import { F7_INIT, LOCAL_CONFIG_LOADED } from "../store/rootReducer";
import { loadLocalConfig, getAuthCodeFromURL } from "../utils";
import { I18nextProvider } from "react-i18next";
import i18n, { language } from "../i18n";
import { Framework7Params } from "framework7/components/app/app-class";
import {
  loginWithUserPassword,
  loginWithCode, clearSession,
} from "../actions/sessionActions";
import { loadCategories } from "../actions/categoryActions";
import {
  EntityClassificatorType,
  loadClassificator,
  loadCurrencyClassificator,
} from "../actions/classificatorActions";
import { Dispatch, AnyAction } from "redux";
import { PersistGate } from "redux-persist/integration/react";

export const { store, persistor } = configureStore();
const isDebuggingInChrome = !!(window as any).navigator.userAgent;

if (isDebuggingInChrome) {
  window["store"] = store;
}

type State = {
  f7params: Framework7Params;
  username?: string;
  password?: string;
  init?: boolean;
};

const classificatorTypes: EntityClassificatorType[] = [
  "Product_Status",
  "Payment_Options",
];

export default class extends React.Component<any, State> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      // Framework7 Parameters
      f7params: {
        id: "com.marketplace.app", // App bundle ID
        name: "Marketplace", // App name
        theme: "auto", // Automatic theme detection

        // App routes
        routes: routes,

        // Register service worker
        /*
        serviceWorker: Device.cordova ? {} : {
          path: process.env.APP_PATH + 'service-worker.js',
        },
         */
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
        view: {
          pushState: true,
          stackPages: true,
          pushStateSeparator: "#",
        },
        swipeout: {
          removeElements: false,
        },
      },
      // Login screen demo data
      username: "",
      password: "",
      init: false,
    };
  }

  componentDidMount() {
    const { $f7ready } = this;
    $f7ready(async (f7) => {
      f7.preloader.show();

      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }

      await this.loadLocalConfig();
      this.auth();

      /* TODO expire state with interval */
      //const state = store.getState();
      this.loadAllClassificators(classificatorTypes, store.dispatch);

      store.dispatch({ type: F7_INIT, f7 });
      store.subscribe(this.listener);
    });
  }

  listener = () => {
    const state = store.getState();
    const { profile } = state.sessionReducer;
    if (profile && profile.uid && !this.state.init) {
      this.setState({ init: true }, () => this.$f7.preloader.hide());
    } else {
      store.dispatch(clearSession() as any);
    }
  };

  auth() {
    const authCode = getAuthCodeFromURL();
    if (authCode && authCode.length >= 32) {
      store.dispatch(loginWithCode(authCode) as any);
    } else if (process.env.USERNAME && process.env.PASSWORD) {
      store.dispatch(
        loginWithUserPassword(process.env.USERNAME, process.env.PASSWORD) as any
      );
    } else {
      store.dispatch(clearSession() as any);
    }
  }

  loadLocalConfig = async () => {
    const localConfig = await loadLocalConfig();
    store.dispatch({ type: LOCAL_CONFIG_LOADED, localConfig });
  };

  alertLoginData() {
    this.$f7?.dialog.alert(
      "Username: " +
        this.state.username +
        "<br>Password: " +
        this.state.password,
      () => {
        this.$f7?.loginScreen.close();
      }
    );
  }

  loadAllClassificators = (
    types: EntityClassificatorType[],
    dispatch: Dispatch<AnyAction>
  ) => {
    // Entities classificators
    types.forEach((type) => dispatch(loadClassificator(type) as any));
    // Single classificators
    dispatch(loadCurrencyClassificator() as any);
    dispatch(loadCategories() as any);
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <App params={this.state.f7params}>
              {this.state.init && <View className="safe-areas" main url="/" />}
            </App>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    );
  }
}
