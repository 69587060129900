import React, { Component } from "react";
import {
  Page,
  Navbar,
  List,
  ListItem,
  Icon,
  Popup,
  NavTitle,
  NavRight,
  Link,
  NavLeft,
} from "framework7-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { IApplicationStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import {} from "../../utils";
import { Address } from "../../types/commonapi";
import { selectDeliveryAddress } from "../../actions/checkoutActions";
import "./style.less";
import connectAccountAddress, {
  IAccountAddressProps,
} from "../../store/connectAccountAddress";

type Props = WithTranslation &
  IAccountAddressProps & {
    selectDeliveryAddress?(address: Address): () => void;
  };

type State = {
  addDeliveryInformation?: boolean;
};

class DeliveryInfoChoosePage extends Component<Props & Popup.Props, State> {
  constructor(props: Readonly<WithTranslation & Props & Popup.Props>) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t,
      accountAddressState: { addresses },
      onPopupClosed,
      ...rest
    } = this.props;
    return (
      <Popup
        id="delivery_info_choose__popup"
        swipeToClose
        {...rest}
        onPopupOpened={(instance: any) => {
          this.setState({ addDeliveryInformation: false });
          if (this.props.onPopupOpened) {
            this.props.onPopupOpened(instance);
          }
        }}
        onPopupClosed={(instance: any) => {
          if (onPopupClosed) {
            // @ts-ignore
            onPopupClosed(instance, this.state.addDeliveryInformation);
          }
        }}
      >
        <Page>
          <Navbar noHairline noShadow>
            <NavLeft>
              <Link popupClose iconOnly icon="icon-back" />
            </NavLeft>
            <NavTitle sliding>{t("Delivery information")}</NavTitle>
          </Navbar>
          <List mediaList noChevron noHairlines noHairlinesBetween>
            {addresses.map((item) => {
              return (
                <ListItem
                  key={item.uid}
                  radio
                  name="delivery-media-radio"
                  value={item.uid}
                  title={item.firstAddressLine}
                  onClick={() => {
                    this.props.selectDeliveryAddress(item);
                    this.$f7.popup.close();
                  }}
                >
                  <div slot="text">
                    {item.country.name}, {item.city} <br />
                    {item.firstAddressLine} <br />
                    {item.postalCode}
                  </div>
                </ListItem>
              );
            })}
            <ListItem
              className="item-add-link"
              link
              title={t("Add delivery Information").toString()}
              noChevron
              onClick={() => {
                this.setState({ addDeliveryInformation: true }, () => {
                  this.$f7.popup.close();
                });
              }}
            >
              <span slot="media">
                <Icon ios="f7:plus" md="material:add" />
              </span>
            </ListItem>
          </List>
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({});

const mapDispatchToProps = (dispatch: any, props: Props) => ({
  selectDeliveryAddress: (address: Address) =>
    dispatch(selectDeliveryAddress(address)),
});

export default compose(
  withTranslation(),
  connectAccountAddress,
  connect(mapStateToProps, mapDispatchToProps)
)(DeliveryInfoChoosePage) as React.ComponentClass<Popup.Props>;
