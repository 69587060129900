import { IApplicationStore } from "./rootReducer"
import { connect } from "react-redux"

const mapStateToProps = (state: IApplicationStore) => {
  const { localConfigLoaded, localConfig } = state.rootReducer
  return {
    localConfigLoaded,
    localConfig
  }
}

export default connect(mapStateToProps)
