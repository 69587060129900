import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Provider, connect } from "react-redux";
import { store, persistor } from "./components/App";
import { PersistGate } from "redux-persist/integration/react";
import F7App from "./F7App";
import {
  ClaimSubjectClassificatorType,
  EntityClassificatorType,
  loadClaimSubjectsClassificator,
  loadClassificator,
  loadCountryClassificator,
  loadCurrencyClassificator,
} from "./actions/classificatorActions";
import {
  loadLocalConfig,
  getQueryParameterFromURL,
  setLayoutTheme,
} from "./utils";
import { IPreloaderProps } from "./store/connectPreloader";
import { authByUrl } from "./actions/sessionActions";
import { loadCategories } from "./actions/categoryActions";
import { ILocalConfig, IApplicationStore } from "./store/rootReducer";
import { Profile } from "./reducers/sessionReducer";
import { getProfile } from "./selectors/profile";
import Analytics from "./analytics";
import mixpanel from 'mixpanel-browser';
import GA4React from "ga-4-react";
//import ReactGA from 'react-ga';

let loadingClassificatorTypes: EntityClassificatorType[] = [
  "Product_Status",
  "Account_Status",
  "Account_Type",
  "Payment_Options",
  "Delivery_Duration",
  "Delivery_Prices",
  "Delivery_Country",
  "Delivery_City",
  "Url_app",
];

const loadingSubjectClassificatorTypes: ClaimSubjectClassificatorType[] = [
  "Application",
  "Order",
  "Product",
];

type Props = IPreloaderProps & {
  profile?: Profile;
  updateLocalConfig?: (localConfig: ILocalConfig) => void;
  authByUrl?: () => void;
  loadClassificators?: () => void;
  loadMyCurrencies?(): void;
};

const checkTheme = () => {
  const isDarkModeEnabled = getQueryParameterFromURL("darkMode") === "true";
  setLayoutTheme(isDarkModeEnabled ? "dark" : "light");
};

export let analytics = new Analytics([]);

const isLocal = process.env.NODE_ENV === "development";
if (isLocal) {
  analytics.addProvider("console");
}

const mixpanelToken = process.env.MIXPANEL_TOKEN;
if (mixpanelToken && mixpanelToken.length > 0) {
  mixpanel.init(mixpanelToken);
  analytics.addProvider("mixpanel");
}

export let ga4react: GA4React;

const googleAnalyticsId = process.env.GOOGLE_ANALYTICS_ID;
if (googleAnalyticsId && googleAnalyticsId.length > 0) {
  ga4react = new GA4React(googleAnalyticsId);
  ga4react.initialize();
  analytics.addProvider("google");
}

class Setup extends React.Component<Props> {
  async componentDidMount() {
    checkTheme();

    const localConfig = await loadLocalConfig();
    this.props.updateLocalConfig(localConfig);
    this.props.authByUrl();
    this.props.loadClassificators();

    analytics.openWebsite(this.props.profile);
  }

  render() {
    return <F7App />;
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateLocalConfig: (localConfig: ILocalConfig) =>
    dispatch({ type: "LOCAL_CONFIG_LOADED", localConfig }),
  authByUrl: () => dispatch(authByUrl()),
  loadClassificators: () => {
    // Entities classificators
    loadingClassificatorTypes.forEach((type) =>
      dispatch(loadClassificator(type) as any)
    );
    // Claim Subject classificators
    loadingSubjectClassificatorTypes.forEach((subjectType) =>
      dispatch(loadClaimSubjectsClassificator(subjectType) as any)
    );
    // Single classificators
    dispatch(loadCurrencyClassificator() as any);
    dispatch(loadCountryClassificator() as any);
    dispatch(loadCategories() as any);
  },
});

const ConnectedSetup = connect(mapStateToProps, mapDispatchToProps)(Setup);

const Wrappers = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <ConnectedSetup />
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

export default Wrappers;
