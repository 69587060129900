import { connect } from "react-redux";
import { IApplicationStore } from "./rootReducer";
import { Profile } from "../reducers/sessionReducer";
import { getProfile } from "../selectors/profile";

const mapStateToProps = (state: IApplicationStore): { profile: Profile } => ({
  profile: getProfile(state)
})

export default connect(mapStateToProps)
