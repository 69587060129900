import React from 'react';
import { formatPrice } from '../../utils';
import classNames from 'classnames';

import './style.less';

type Props = {
  className?: string,
  price: number,
  discountedPrice?: number,
  currencyCode: string,
}

export default ({ price, discountedPrice, currencyCode, className }: Props) => (
  <div className={classNames('price', typeof discountedPrice !== 'undefined' ? 'discounted' : '', className)}>
    {typeof discountedPrice !== 'undefined' ?
      (
        <>
          <del>
            <span className="amount">
              {formatPrice(price, currencyCode)}
            </span>
          </del>
          <ins>
            <span className="amount">
              {formatPrice(discountedPrice, currencyCode)}
            </span>
          </ins>
        </>
      )
      :
      (
        <ins>
          <span className="amount">
            {formatPrice(price, currencyCode)}
          </span>
        </ins>
      )}
  </div>
)