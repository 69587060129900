import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { IApplicationStore } from "../../store/rootReducer";
import { Profile } from "../../reducers/sessionReducer";

import "./style.less";

type Props = Partial<WithTranslation> & {
  uid?: string;
  status?: string;
  email?: string;
  points?: number;
};

const ProfileStatus = ({ uid, status, email, points, t }: Props) => (
  <div className="profile-status">
    <span className="uid">{email}</span>
    <span className="uid">
      {t("Customer ID")}: {uid}
    </span>
    <span className="status">{status}</span>
    <span className="bonus-point">
      {t("Bonus Points")}: {points}
    </span>
  </div>
);

const mapStateToProps = (state: IApplicationStore, { profile }: { profile: Profile }): Props => {
  const status =
    state.classificatorReducer.entitiesClassificators.Account_Status.filter(
      (item) => item.code === profile.status
    )[0] || {};
  const primaryEmail = profile?.accountEmails?.find((email) => email.primary)?.email;
  const points = state.myCurrenciesReducer.currencies.find((cur) => cur.currency?.code === "USB")?.balance;
  return {
    uid: profile.uid,
    status: status.value,
    email: primaryEmail,
    points: points ?? 0,
  };
};

export default compose(withTranslation(), connect(mapStateToProps, null))(ProfileStatus) as React.FC<Props>;
