import { AnyAction } from "redux";
import { Banner } from '../types/marketplaceapi';
import { MARKETING_BANNERS_LIST_LOADING, MARKETING_BANNERS_LIST_LOADING_SUCCESS } from '../actions/bannersActions';

export interface IBannersState {
  loading?: boolean,
  error?: boolean,
  banners?: Banner[],
}

const initialState: IBannersState = {
  loading: null,
  error: null,
  banners: []
}

const bannersReducer = (state = initialState, action: AnyAction): IBannersState => {
  switch (action.type) {
    case MARKETING_BANNERS_LIST_LOADING:
      return {
        ...state,
        banners: []
      }
    case MARKETING_BANNERS_LIST_LOADING_SUCCESS:
      return {
        ...state,
        banners: action.banners
      }
    default:
      return state
  }
}

export default bannersReducer