import React, { useEffect, useState } from "react";
import { BlockTitle, Button, F7Popup, Icon, Link, Navbar, NavRight, Popup } from "framework7-react";
import { useTranslation } from "react-i18next";
import { Profile } from "../../reducers/sessionReducer";
import Framework7 from "framework7";
import { AccountEmailWsControllerApi } from "../../types/commonapi";
import { useSelector } from "react-redux";
import { IApplicationStore } from "../../store/rootReducer";

interface Props {
  opened: boolean;
  profile: Profile;
  f7: Framework7;
}

const ViewProfilePopup = (props: Props & F7Popup.Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<"first" | "second">("first");
  const [newEmail, setNewEmail] = useState<string>("");
  const [reset, setReset] = useState(false);

  const resizeEvent = useSelector((state: IApplicationStore) => state.rootReducer.resizeEvent);

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setPage("first");
        setNewEmail("");
        setReset(false);
      }, 2000);
    }
  }, [reset]);

  const checkEmail = () => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newEmail);

  const renderInfo = () => {
    return (
      <div className="list no-hairlines-md">
        <ul>
          <li className="item-content item-input">
            <div className="item-inner">
              <div className="item-title item-label">{t("Name")}</div>
              <div className="item-input-wrap">
                <input
                  type="text"
                  disabled={true}
                  placeholder={`${props.profile?.person?.name} ${props.profile?.person?.surname}`}
                />
              </div>
            </div>
          </li>
          <li className="item-content item-input">
            <div className="item-inner">
              <div className="item-title item-label">{t("Customer ID")}</div>
              <div className="item-input-wrap">
                <input type="text" disabled={true} placeholder={`${props.profile?.uid}`} />
              </div>
            </div>
          </li>
          <li className="item-content item-input">
            <div className="item-inner">
              <div className="item-title item-label">{t("E-mail")}</div>
              <div className="item-input-wrap" style={{ display: "flex" }}>
                <input
                  type="text"
                  disabled={true}
                  placeholder={`${props.profile?.accountEmails?.find((email) => email.primary)?.email}`}
                />
                <span onClick={() => setPage("second")} style={{ cursor: "pointer" }}>
                  <Icon material="edit" />
                </span>
              </div>
            </div>
          </li>
          <li className="item-content item-input">
            <div className="item-inner">
              <div className="item-title item-label">{t("Country of Residence")}</div>
              <div className="item-input-wrap">
                <input type="text" disabled={true} placeholder={`${props.profile?.country?.name}`} />
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const changeHandler = async () => {
    if (!checkEmail()) return;
    try {
      const result = await new AccountEmailWsControllerApi().addEmailUsingPUT({ email: newEmail });
      if (result?.errorData?.errorMessage) {
        props.f7.dialog.alert(result?.errorData?.errorMessage, () => {
          props.f7.dialog.close();
          props.onPopupClosed();
          setReset(true);
        });
      } else {
        props.f7.dialog.alert(t("Change email success"), () => {
          props.f7.dialog.close();
          props.onPopupClosed();
          setReset(true);
        });
      }
    } catch (err) {
      err.message &&
        props.f7.dialog.alert(err.message, () => {
          props.f7.dialog.close();
          props.onPopupClosed();
          setReset(true);
        });
      console.log(err);
    }
  };

  const renderChangeEmail = () => {
    return (
      <div className="list no-hairlines-md">
        <ul>
          <li className="item-content item-input">
            <div className="item-inner">
              <div className="item-title item-label">{t("Current Email")}</div>
              <div className="item-input-wrap">
                <input
                  type="text"
                  disabled={true}
                  placeholder={`${props.profile?.accountEmails?.find((email) => email.primary)?.email}`}
                />
              </div>
            </div>
          </li>
          <li className="item-content item-input">
            <div className="item-inner">
              <div className="item-title item-floating-label">{t("New E-mail address")}</div>
              <div className="item-input-wrap">
                <input
                  type="email"
                  name="new-email"
                  defaultValue={newEmail ?? ""}
                  onChange={(event) => setNewEmail(event.target.value)}
                />
              </div>
            </div>
          </li>
        </ul>
        <Button large fill round onClick={changeHandler} disabled={!checkEmail()} style={{ margin: "30px 10px" }}>
          {t("Change")}
        </Button>
      </div>
    );
  };

  const closeHandler = () => {
    props.onPopupClosed();
    setReset(true);
  };

  if (!props.profile) return null;

  const isMobile = resizeEvent.width < 567;

  return (
    <div>
      <Popup
        id={!isMobile ? "share-container" : undefined}
        opened={props.opened && page === "first"}
        style={{ height: !isMobile ? 450 : undefined, borderRadius: !isMobile ? 20 : 0 }}
      >
        <Navbar noHairline noShadow>
          <BlockTitle medium style={{ paddingBottom: "20px" }}>
            {t("View Profile")}
          </BlockTitle>
          <NavRight>
            <Link popupClose onClick={closeHandler}>
              {t("Close")}
            </Link>
          </NavRight>
        </Navbar>
        {renderInfo()}
      </Popup>
      <Popup
        id={!isMobile ? "share-container" : undefined}
        opened={props.opened && page === "second"}
        style={{ height: !isMobile ? 450 : undefined, borderRadius: !isMobile ? 20 : 0 }}
      >
        <Navbar noHairline noShadow>
          <BlockTitle medium style={{ paddingBottom: "20px" }}>
            {t("View Profile")}
          </BlockTitle>
          <NavRight>
            <Link popupClose onClick={closeHandler}>
              {t("Close")}
            </Link>
          </NavRight>
        </Navbar>
        {renderChangeEmail()}
      </Popup>
    </div>
  );
};

export default ViewProfilePopup;
