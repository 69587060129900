import React from 'react';
import './style.less';

type Props = {
  text: string,
  substringLength?: number,
}

const stripHtmlTags = (text: string, all?: boolean): string => {
  const tags = all ? [] : ['p', 'ul', 'ol', 'li', 'strong', 'em', 'sub', 'sup'];
  let _tags = [], _tag = "";

  for (let _a = 1 ; _a < tags.length ; _a++) {
    _tag = tags[_a].replace(/[<>\/]/g, '').trim();

    if ( tags[_a].length > 0 ) {
      _tags.push(_tag);
    }
  }

  const _re = new RegExp( "<(?!\\s*\\/?(" + _tags.join("|") + ")\\s*\\/?>)[^>]*>", "g" );

  return text.replace( _re, ' ' );
}

const substring = (text: string, length: number): string => {
  return text.substr(0, length) + '...';
}

const createHtmlObject = (text: string) => {
  return {__html: text};
}

const DescriptionDetailsText = (props: Props) => {
  const { text, substringLength } = props;
  return text && text.length
    ? (
      substringLength && substringLength > 0
        ? <>{substring(stripHtmlTags(text, true), substringLength)}</>
        : (<div dangerouslySetInnerHTML={createHtmlObject(stripHtmlTags(text))}/>)
    ) : null
}

export default DescriptionDetailsText
