import { DeliveryMethod } from "../reducers/cartReducer";
import { IProduct } from "../reducers/productReducer";

export const CART_ADD = "CART_ADD";
export const CART_UPDATE = "CART_UPDATE";
export const CART_REMOVE = "CART_REMOVE";
export const CART_REMOVE_BY_SELLER = "CART_REMOVE_BY_SELLER";
export const CART_CLEAR = "CART_CLEAR";
export const SET_DELIVERY_INFO = "SET_DELIVERY_INFO";
export const SET_DELIVERY_PRICE = "SET_DELIVERY_PRICE";
export const SET_DELIVERY = "SET_DELIVERY";

export const cartAdd = (item: IProduct, count: number) => {
  return {
    type: CART_ADD,
    item,
    count,
  };
};

export const cartUpdate = (item: IProduct, count: number) => {
  return {
    type: CART_UPDATE,
    item,
    count,
  };
};

export const cartRemove = (item: IProduct, count: number) => {
  return {
    type: CART_REMOVE,
    item,
    count,
  };
};

export const cartRemoveBySeller = (seller: string) => {
  return {
    type: CART_REMOVE_BY_SELLER,
    seller,
  };
};

export const cartClear = () => {
  return {
    type: CART_CLEAR,
  };
};

export const setDeliveryInformation = (delivery: DeliveryMethod) => {
  return {
    type: SET_DELIVERY_INFO,
    delivery,
  };
};
export const setDeliveryPrice = (deliveryPrice: number) => {
  return {
    type: SET_DELIVERY_PRICE,
    deliveryPrice,
  };
};
