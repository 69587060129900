import React from "react";
import { Product } from "../../types/marketplaceapi";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { Badge } from "framework7-react";

import "./style.less";
import { IApplicationStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import classNames from "classnames";

const StatusEnum = Product.StatusEnum;

type Props = Partial<WithTranslation> & {
  status: Product.StatusEnum;
  statusText?: string;
};

const getProductStatusText = (status: Product.StatusEnum): string => {
  switch (status) {
    case StatusEnum.DRF:
      return "Draft";
    case StatusEnum.AFR:
      return "Assigned for Review";
    case StatusEnum.DCL:
      return "Declined";
    case StatusEnum.APR:
      return "Approved";
    case StatusEnum.PBL:
      return "Published";
    case StatusEnum.SUS:
      return "Suspended";
    case StatusEnum.EXP:
      return "Expired";
    case StatusEnum.OOS:
      return "Out of Stock";
    case StatusEnum.DSC:
      return "Discontinued";
    default:
      return "";
  }
};

const ProductStatusBadge = ({ status, statusText, t }: Props) =>
  status ? (
    <Badge
      className={classNames(
        "product-status-badge",
        `product-status-badge-${status.toString()}`
      )}
    >
      {statusText}
    </Badge>
  ) : null;

const mapStateToProps = (state: IApplicationStore, ownProps: Props) => {
  let { statusText } = ownProps;
  if (!statusText) {
    const val = state.classificatorReducer.entitiesClassificators.Product_Status.filter(
      (item) =>
        item.code.toLowerCase() === ownProps.status.toString().toLowerCase()
    )[0];
    statusText = val ? val.value : ownProps.status.toString();
  }
  return {
    ...ownProps,
    statusText,
  };
};

export default compose<React.FC<Props>>(
  withTranslation(),
  connect(mapStateToProps, null)
)(ProductStatusBadge);
