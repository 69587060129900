import React from "react";
import { Swiper, SwiperSlide, Block, Link, Icon, f7 } from "framework7-react";
import { Price } from "./Price/index";
import { getCategoryNameByCategoryId, createThumbnailVideoURLLink } from "../utils";
import { compose } from "redux";
import connectCategoriesClassificator from "../store/connectCategoriesClassificator";
import { ICategoryClassificator } from "../reducers/categoryReducer";
import AddWishButton from "./AddWishButton";
import { addToWishList } from "../actions/productActions";
import { connect } from "react-redux";
import ChatWithSeller from "./ChatWithSeller";

import "./Slider.less";
import { WithTranslation, withTranslation } from "react-i18next";
import { IcCrown } from "../components-ui/icons";
import { hasFeatureCode } from "./Catalog/CatalogItem";
import classNames from "classnames";
import { DescriptionDetailsText } from "./ProductDetails";

const defaultSliderParams: any = {
  centeredSlides: true,
  spaceBetween: 8,
  slidesPerView: "auto",
};

export enum SliderType {
  "top" = 0,
  "big",
  "small",
  "images",
}

export type SliderItem = {
  uid?: string;
  href?: string;
  name?: string;
  image?: string;
  promo?: string;
  description?: string;
  category?: string;
  categoryName?: string;
  price?: number;
  priceDiscount?: number;
  currencyCode?: string;
  onClick?(item: SliderItem): void;
  wish?: boolean;
  sellerPhone?: string;
  featureCodes?: string[];
  videoId?: string;
  videoType?: string;
};

type Props = Partial<WithTranslation> & {
  className?: string;
  slides: SliderItem[];
  type?: SliderType;
  showIfEmpty?: boolean;
  categoriesClassificator?: ICategoryClassificator[];
  addToWish?(uid?: string): void;
  startChat?(uid: string): void;
  onClick?(item: SliderItem): void;
  showFeaturesHiglight?: boolean;
  videoPlayOnClick?(videoId: string, videoType: string): void;
  slideIndex?: number;
};

const SlideItem = ({
  type,
  item,
  empty,
  classificator = [],
  onClickAddToWish,
  onClickStartChat,
  onClick,
  t,
  showFeaturesHiglight,
  videoPlayOnClick,
}: {
  type: SliderType;
  item: SliderItem;
  empty?: boolean;
  classificator?: ICategoryClassificator[];
  onClickAddToWish?(uid: string): void;
  onClickStartChat?(uid: string): void;
  onClick?(item: SliderItem): void;
  t?: any;
  showFeaturesHiglight?: boolean;
  videoPlayOnClick?(videoUrl: string, videoType: string): void;
}) => {
  const { image, name, promo, featureCodes, videoId, videoType } = item;

  switch (type) {
    case SliderType.top:
      return (
        <SwiperSlide className="link" style={getSlideBackgroundStyle(image)}>
          <div
            onClick={() => {
              if (onClick) onClick(item);
            }}
            onMouseDown={(e) => {
              if (e.button === 4 || e.button === 1) {
                if (window.location.hostname === "localhost") {
                  window.open(`/#${item.href}`);
                } else {
                  window.open(`/market/#${item.href}`);
                }
              }
            }}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <p className="name">{name}</p>
            <p className="promo">{promo}</p>
          </div>
        </SwiperSlide>
      );
    case SliderType.big:
      return (
        <SwiperSlide className="big-slide no-padding">
          <div
            // href={item.href}
            className="link slider-link"
            onClick={() => (item.onClick ? item.onClick(item) : {})}
            onMouseDown={(e) => {
              if (e.button === 4 || e.button === 1) {
                if (window.location.hostname === "localhost") {
                  window.open(`/#${item.href}`);
                } else {
                  window.open(`/market/#${item.href}`);
                }
              }
            }}
          >
            <div className="header-image" style={getSlideBackgroundStyle(image)} />
            <Block className="info">
              <p className="title">{item.name}</p>
              <span className="category">
                {item.categoryName || getCategoryNameByCategoryId(item.category, classificator)}
              </span>
              <div className="description">
                <DescriptionDetailsText text={item.description} />
              </div>
              {!empty && (
                <Price price={item.price} discountedPrice={item.priceDiscount} currencyCode={item.currencyCode} />
              )}
            </Block>
          </div>
          {!empty && (
            <AddWishButton
              active={item.wish}
              withShadow
              onClick={() => {
                onClickAddToWish(item.uid);
              }}
            />
          )}
          {!empty && process.env.MESSENGER === "true" && (
            <ChatWithSeller text={t("Chat with seller")} onClick={() => onClickStartChat(item.uid)} />
          )}
        </SwiperSlide>
      );
    case SliderType.small:
      return (
        <SwiperSlide className="small-slide no-padding">
          <div
            className={classNames(
              "link",
              showFeaturesHiglight && hasFeatureCode("higlight_bold", featureCodes) && "feature-higlight-bold"
            )}
            onClick={() => (item.onClick ? item.onClick(item) : {})}
            onMouseDown={(e) => {
              if (e.button === 4 || e.button === 1) {
                if (window.location.hostname === "localhost") {
                  window.open(`/#${item.href}`);
                } else {
                  window.open(`/market/#${item.href}`);
                }
              }
            }}
          >
            <div className="header-image">{image && <img src={image} />}</div>
            <Block className="info">
              <div className="title-row">
                <div className="title">{item.name}</div>
                {showFeaturesHiglight && hasFeatureCode("vip", featureCodes) && (
                  <div className="feature-icon-crow">
                    <IcCrown />
                  </div>
                )}
              </div>
              <p className="category">{item.categoryName || item.category}</p>
              <div className="description">
                <DescriptionDetailsText text={item.description} />
              </div>
              {!empty && (
                <Price price={item.price} discountedPrice={item.priceDiscount} currencyCode={item.currencyCode} />
              )}
            </Block>
          </div>
          {!empty && (
            <AddWishButton
              active={item.wish}
              withShadow
              onClick={() => {
                onClickAddToWish(item.uid);
              }}
            />
          )}
        </SwiperSlide>
      );
    case SliderType.images: {
      return image ? (
        <SwiperSlide style={getSlideBackgroundStyle(image)} />
      ) : (
        <SwiperSlide>
          <Link
            onClick={() => videoPlayOnClick && videoPlayOnClick(videoId, videoType)}
            style={{
              backgroundColor: "#efefef",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: "100%",
              height: "100%",
              flexShrink: 0,
              backgroundImage: `url(${createThumbnailVideoURLLink(videoId, videoType)})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <Icon material="play_circle_filled" size="5rem" style={{ color: "rgb(202, 202, 202)" }} />
          </Link>
        </SwiperSlide>
      );
    }
    default:
      return null;
  }
};

function emptySlides(type: SliderType): JSX.Element[] {
  const slidesCounts = type === SliderType.small ? 2 : 1;
  return [slidesCounts].map((i) => <SlideItem key={i} type={type} item={{}} empty />);
}

class Slider extends React.Component<Props> {
  _instance: any;

  componentDidUpdate(prevProps: Props) {
    const { slideIndex } = this.props;
    if (slideIndex !== prevProps.slideIndex && this._instance.swiper) {
      this._instance.swiper.slideTo(slideIndex, 380);
    }
  }

  render() {
    const {
      className,
      slides,
      type = SliderType.top,
      showIfEmpty,
      categoriesClassificator,
      addToWish,
      startChat,
      onClick,
      videoPlayOnClick,
      t,
      showFeaturesHiglight,
    } = this.props;
    return (
      <Swiper
        ref={(ref) => (this._instance = ref)}
        className={`slider ${type === SliderType.small ? " small-slider" : ""}${className ? ` ${className}` : ""}`}
        init
        pagination={type === SliderType.top || type === SliderType.images}
        params={getParams(type, slides.length)}
        navigation
      >
        {slides.length
          ? slides.map((item, i) => (
              <SlideItem
                key={i}
                type={type}
                item={item}
                classificator={categoriesClassificator}
                onClickAddToWish={addToWish}
                onClickStartChat={startChat}
                onClick={onClick}
                t={t}
                showFeaturesHiglight={showFeaturesHiglight}
                videoPlayOnClick={videoPlayOnClick}
              />
            ))
          : showIfEmpty
          ? emptySlides(type)
          : null}
      </Swiper>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  addToWish: (uid?: string) => dispatch(addToWishList(uid)),
});

export default compose(
  withTranslation(),
  connectCategoriesClassificator,
  connect(null, mapDispatchToProps)
)(Slider) as React.ComponentType<Props>;

// Helpers
const getParams = (type: SliderType, slides: number) => {
  let p = { ...defaultSliderParams };
  p.loop = true;

  if (type === SliderType.small) {
    if (slides < 5) p.loop = false;
    p.centeredSlides = false;
    p.breakpoints = {
      425: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 16,
      },
    };
  } else if (type === SliderType.big) {
    p.breakpoints = {
      768: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 16,
      },
    };
  } else if (type === SliderType.top) {
    p.slidesPerView = 1;
    p.autoplay = {
      delay: 5000,
    };
    p.breakpoints = {
      768: {
        spaceBetween: 16,
      },
    };
  }

  return p;
};

const getSlideBackgroundStyle = (url: string) => (url ? { backgroundImage: `url(${url})` } : null);
