import React, { PureComponent } from "react";
import "./style.less";
import { Link, Icon } from "framework7-react";
import classNames from "classnames";

type Props = Link.Props & {
  opened?: boolean;
};

class OpenCategoriesButton extends PureComponent<Props> {
  render() {
    const { className, text, opened, ...rest } = this.props;
    return (
      <Link
        className={classNames(
          "no-ripple",
          "open-categories-button",
          opened && "opened",
          className
        )}
        {...rest}
      >
        <span>{text}</span>
        <Icon material="expand_more" />
      </Link>
    );
  }
}

export default OpenCategoriesButton;
