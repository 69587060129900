import moment from "moment";
import { CalculatedDelivery, PaymentMethods } from "../reducers/checkoutReducer";
import { IApplicationStore } from "../store/rootReducer";
import { Address, Card } from "../types/commonapi";
import { CalculateDeliveryRequest, CartControllerApi, ProductDelivery } from "../types/marketplaceapi";
import _ from "lodash";
import BigNumber from "bignumber.js";

export const CHECKOUT_SELECT_PAYMENT_METHOD = "CHECKOUT_SELECT_PAYMENT_METHOD";
export const CHECKOUT_SELECT_PAYMENT_ADDRESS = "CHECKOUT_SELECT_PAYMENT_ADDRESS";
export const CHECKOUT_SELECT_DELIVERY_ADDRESS = "CHECKOUT_SELECT_DELIVERY_ADDRESS";
export const CHECKOUT_SELECT_DELIVERY_METHOD = "CHECKOUT_SELECT_DELIVERY_METHOD";
export const CHECKOUT_SELECT_DELIVERY_DATE = "CHECKOUT_SELECT_DELIVERY_DATE";
export const CHECKOUT_CALCULATE_DELIVERY_LOADING = "CHECKOUT_CALCULATE_DELIVERY_LOADING";
export const CHECKOUT_CALCULATE_DELIVERY_SUCCESS = "CHECKOUT_CALCULATE_DELIVERY_SUCCESS";
export const CHECKOUT_CALCULATE_DELIVERY_ERROR = "CHECKOUT_CALCULATE_DELIVERY_ERROR";
export const CHECKOUT_CALCULATE_AMOUNT_TO_PAY = "CHECKOUT_CALCULATE_AMOUNT_TO_PAY";

export const defaultCountryCode = "CA";

export interface CalculatedDeliveryRequest {
  shippingPrice: number;
  shippingCurrency: string;
  methods: ProductDelivery[];
}

export const selectPaymentMethod = (method: PaymentMethods, paymentCard?: Card) => (dispatch: any) => {
  dispatch({
    type: CHECKOUT_SELECT_PAYMENT_METHOD,
    method,
    paymentCard,
  });
};

export const selectPaymentAddress = (address: Address) => (dispatch: any) => {
  dispatch({
    type: CHECKOUT_SELECT_PAYMENT_ADDRESS,
    address,
  });
};

export const selectDeliveryAddress = (address: Address) => (dispatch: any) => {
  dispatch({
    type: CHECKOUT_SELECT_DELIVERY_ADDRESS,
    address,
  });
};

export const selectDeliveryMethod = (delivery: ProductDelivery) => (dispatch: any) => {
  dispatch({
    type: CHECKOUT_SELECT_DELIVERY_METHOD,
    delivery,
  });
};

export const selectDeliveryDate = (date: string) => (dispatch: any) => {
  dispatch({
    type: CHECKOUT_SELECT_DELIVERY_DATE,
    date,
  });
};

const calculateDeliverySuccess = (calculatedDelivery: CalculatedDelivery) => ({
  type: CHECKOUT_CALCULATE_DELIVERY_SUCCESS,
  calculatedDelivery,
});

const calculateDeliveryError = (error: any) => ({
  type: CHECKOUT_CALCULATE_DELIVERY_ERROR,
  error,
});

export const calculateDelivery = () => async (dispatch: any, getState: () => IApplicationStore) => {
  dispatch(calculateDeliveryLoading());

  const state = getState();
  const { selectedDeliveryAddress, selectedDeliveryDate } = state.checkoutReducer;
  const { items } = state.cartReducer;
  const { language } = state.rootReducer;

  try {
    const deliveryDate = selectedDeliveryDate
      ? moment(selectedDeliveryDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss")
      : undefined;

    const request: CalculateDeliveryRequest = {
      deliveryDate,
      items: [],
    };

    if (selectedDeliveryAddress?.city) {
      request.deliveryAddress = {
        countryCode: selectedDeliveryAddress.country.code,
        city: selectedDeliveryAddress.city,
        postalCode: selectedDeliveryAddress.postalCode,
        firstAddressLine: selectedDeliveryAddress.firstAddressLine,
        secondAddressLine: selectedDeliveryAddress.secondAddressLine,
      };
    } else {
      request.deliveryAddress = {
        countryCode: defaultCountryCode,
      };
    }

    let currency = null;

    items.forEach((item) => {
      request.items.push({
        productUid: item.product.uid,
        quantity: item.count,
      });
      currency = item.product.currencyCode;
    });

    const response = (await new CartControllerApi().calculateDeliveryUsingPOST(request, language)).body || [];
    const data = response[0];

    dispatch(
      calculateDeliverySuccess({
        shippingPrice: data.totalShippingPrice,
        shippingCurrency: data.shippingPriceCurrency || currency,
        methods: data.productDeliveryList || [],
        bonusMoneyAmountForOrder: data.bonusMoneyAmountForOrder,
        bonusMoneyAmountTotal: data.bonusMoneyAmountTotal,
      })
    );
  } catch (error) {
    dispatch(calculateDeliveryError(error.toString()));
  }
};

export const calculateAmountToPay = () => async (dispatch: any, getState: () => IApplicationStore) => {
  const state = getState();
  const items = state.cartReducer.items;
  if (items.length === 0) return;
  const calculatedDelivery = state.checkoutReducer.calculatedDelivery;
  if (!calculatedDelivery) return;
  let amountToPay = new BigNumber(state.cartReducer.total).minus(calculatedDelivery.bonusMoneyAmountForOrder);
  dispatch({
    type: CHECKOUT_CALCULATE_AMOUNT_TO_PAY,
    bonusMoney: calculatedDelivery.bonusMoneyAmountTotal,
    amountToPay,
  });
};

const calculateDeliveryLoading = () => ({
  type: CHECKOUT_CALCULATE_DELIVERY_LOADING,
});
