import i18n from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import { getQueryParameterFromURL } from "./utils";

export const language = getQueryParameterFromURL("language") || "en";
moment.locale(language);

const skin = process.env.SKIN;
const languages = ['en', 'ru', 'fr'];
let translations = {
  en: {},
  ru: {},
  fr: {},
};

languages.forEach(lng => {
  translations[lng] = require(`./static/locales/${lng}.json`);
  try {
    const skinTranslations = require(`./static/locales/${lng}-${skin}.json`);
    if (skinTranslations && skinTranslations.translation) {
      translations[lng].translation = {...translations[lng].translation, ...skinTranslations.translation};
    }
  } catch (err) {
    console.debug(err.message);
  }
});

i18n.use(initReactI18next).init({
  lng: language,
  fallbackLng: "en",
  resources: translations,
  interpolation: {
    escapeValue: false,
  },
  /* https://stackoverflow.com/questions/27015970/i18next-json-dot-in-key-or-label/34037706 */
  nsSeparator: ":::",
  keySeparator: "::",
});

export default i18n;
