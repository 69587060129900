// Import React and ReactDOM
import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";

// Import Framework7
import Framework7 from "./framework7-custom";

// Import Framework7-React Plugin
import Framework7React from "framework7-react";

// Import Framework7 Styles
import "./css/framework7-custom.less";

const skin = process.env.SKIN;

// Import Icons and App Custom Styles
import(`./css/${skin}/icons.css`);
import(`./css/${skin}/light-theme.less`);
import(`./css/${skin}/dark-theme.less`);
import(`./css/${skin}/colors.less`);

import "./css/icons.css";
import "./css/common.less";

// Import App Component
import Setup from "./Setup";

const isLocal = process.env.NODE_ENV === "development";

if (!isLocal) {
  console.log("-------------- Sentry init --------------");
  Sentry.init({
    dsn:
      "https://a7a827bfbe774c9297e3673cf13afb52@o402913.ingest.sentry.io/5275421",
  });
}

// Init F7 React Plugin
Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(React.createElement(Setup), document.getElementById("app"));
