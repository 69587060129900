import React from "react";
import {
  Popup,
  F7Popup,
  Page,
  Navbar,
  NavRight,
  Link,
  Block, BlockTitle, List, Icon, ListItem,
} from "framework7-react";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { IClassificator } from "../../reducers/classificatorReducer";
import { Profile } from "../../reducers/sessionReducer";
import { getProfile } from "../../selectors/profile";
import connectLocalConfig from "../../store/connectLocalConfig";
import { IApplicationStore, ILocalConfig } from "../../store/rootReducer";
import { withTranslation, WithTranslation } from "react-i18next";
import { Logo } from "../Logo";

import "./style.less";

const pjson = require("../../../package.json");

type Props = F7Popup.Props & Pick<WithTranslation, "t"> & {
  profile?: Profile;
  urls: IClassificator[];
  onContactSupportClick: any;
  localConfig?: ILocalConfig;
}

class AboutPopup extends React.Component<Props> {
  constructor(props: Readonly<Props>) {
    super(props);
  }

  private appName = process.env.APP_NAME;
  private appSiteLink = process.env.APP_SITE_LINK;
  private appSiteTitle = process.env.APP_SITE_TITLE;
  private companyName = process.env.COMPANY_NAME;

  menuItemEnabled = (item: string): boolean => {
    const { localConfig } = this.props;
    const profileMenuItems = localConfig.profileMenuItems;
    return profileMenuItems && profileMenuItems.length > 0 && profileMenuItems.includes(item);
  };

  currentYear = () => {
    const now = new Date();
    return now.getFullYear();
  };

  getLinkUrl = (code: string) => {
    const { urls } = this.props;
    const filtered = urls.filter(u => u.code === code);
    return filtered.length > 0 ? filtered[0].value : '#';
  };

  redirectToLinkUrl = (event, code) => {
    event.preventDefault();
    const url = this.getLinkUrl(code);
    window.open(url);
  };

  redirectToUrl = (event, url) => {
    event.preventDefault();
    window.open(url);
  };

  render() {
    const { onContactSupportClick, className, t, ...props } = this.props;

    return (
    <Popup {...props} className={classNames("about-popup", className)}>
      <Page>
        <Navbar
          noShadow
          noHairline
        >
          <NavRight>
            <Link popupClose>
              <i className="icon ic-close"/>
            </Link>
          </NavRight>
        </Navbar>
        <Block className="text-align-center">
          <Block>
            <Logo full={false} />
          </Block>
          <BlockTitle large className="name">
            {this.appName}
          </BlockTitle>
          <p className="version">Version {pjson.version}</p>
        </Block>
        <List noHairlines>
          {this.menuItemEnabled('About_TermsAndConditions') && (
            <ListItem
              link="#"
              title={t('Terms & Conditions').toString()}
              onClick={(event) => this.redirectToLinkUrl(event, 'TermsAndConditions')}
            >
              <Icon slot="media" f7="doc" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_PrivacyPolicy') && (
            <ListItem
              link="#"
              title={t('Privacy Policy').toString()}
              onClick={(event) => this.redirectToLinkUrl(event, 'PrivacyPolicy')}
            >
              <Icon slot="media" f7="checkmark_shield" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_ShippingAndDelivery')
            && this.getLinkUrl('ShippingAndDelivery') != '#'
            &&
          (
            <ListItem
              link="#"
              title={t('Shipping & Delivery').toString()}
              onClick={(event) => this.redirectToLinkUrl(event, 'ShippingAndDelivery')}
            >
              <Icon slot="media" f7="doc" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_OrderCancellations')
            && this.getLinkUrl('OrderCancellations') != '#'
            &&
          (
            <ListItem
              link="#"
              title={t('Order Cancellations, Returns and Exchanges').toString()}
              onClick={(event) => this.redirectToLinkUrl(event, 'OrderCancellations')}
            >
              <Icon slot="media" f7="doc" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_FAQs')
          && this.getLinkUrl('FAQs') != '#'
          &&
          (
            <ListItem
              link="#"
              title={t('FAQs').toString()}
              onClick={(event) => this.redirectToLinkUrl(event, 'FAQs')}
            >
              <Icon slot="media" f7="doc" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_AboutUs')
            && this.getLinkUrl('AboutUs') != '#'
            &&
          (
            <ListItem
              link="#"
              title={t('About Us').toString()}
              onClick={(event) => this.redirectToLinkUrl(event, 'AboutUs')}
            >
              <Icon slot="media" f7="doc" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_ContactSupport') && (
            <ListItem
              link="#"
              title={t('Contact Support').toString()}
              onClick={onContactSupportClick}
              popupClose
            >
              <Icon slot="media" f7="headphones" />
            </ListItem>
          )}
          {this.menuItemEnabled('About_RedirectToSite') && (
            <ListItem
              link="#"
              title={this.appSiteTitle}
              onClick={(event) => this.redirectToUrl(event, this.appSiteLink)}
            >
              <Icon slot="media" f7="arrow_up_right_circle" />
            </ListItem>
          )}
        </List>
        <Block className="copyright">
          &copy; {this.currentYear()} {this.companyName}
        </Block>
      </Page>
    </Popup>
    )
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  profile: getProfile(state),
  urls: state.classificatorReducer.entitiesClassificators.Url_app,
});
export default compose<any>(
  withTranslation(),
  connectLocalConfig,
  connect(mapStateToProps, null)
)(AboutPopup);
