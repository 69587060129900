import { connect } from "react-redux";
import { loadAllDeals, reloadAllDeals } from "../actions/allDealsActions";
import { IApplicationStore } from "./rootReducer";
import { ISearchParams } from "../actions/productActions";

const mapStateToProps = (state: IApplicationStore) => ({
  allDealsLoading: state.allDealsReducer.loading,
  allDealsProducts: state.allDealsReducer.products || [],
  allDealsCount: state.allDealsReducer.count,
  allDealsOffset: state.allDealsReducer.offset
})

const mapDispatchToProps = (dispatch: any) => ({
  loadAllDeals: (searchParams: ISearchParams) => dispatch(loadAllDeals(searchParams)),
  reloadAllDeals: () => dispatch(reloadAllDeals())
})

export default connect(mapStateToProps, mapDispatchToProps)
