import React from "react";
import {
  Page,
  BlockTitle,
  Block,
  Navbar,
  Preloader,
  List,
  ListItem,
  ListGroup,
} from "framework7-react";

import "./style.less";
import classNames from "classnames";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { Transaction } from "../../types/commonapi";
import { IApplicationStore } from "../../store/rootReducer";
import { loadTransactions } from "../../actions/transactionActions";
import { connect } from "react-redux";
import { formatDate, formatPrice } from "../../utils";
import { IcReceived, IcSent } from "../../components-ui/icons";
import OperationDetails from "./operation-details__sheet";

type Props = Page.Props & {
  loading?: boolean;
  error?: any;
  transactions: Transaction[];
  loadTransactions?(): void;
};

class TransactionsPage extends React.Component<
  Props & WithTranslation,
  {
    selectedTransaction?: Transaction;
    operationDetailsSheetOpened?: boolean;
  }
> {
  constructor(props: Readonly<Props & WithTranslation>) {
    super(props);
    this.state = {
      operationDetailsSheetOpened: false,
    };
  }

  pageInitHandle = () => {
    if (!this.props.transactions.length) {
      this.props.loadTransactions();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { loading, error, t } = this.props;
    if (error && error !== prevProps.error) {
      this.$f7.dialog.alert(t(error));
    }
  }

  transactionClickHandler = (item: Transaction) => {
    this.setState({
      selectedTransaction: item,
      operationDetailsSheetOpened: true,
    });
  };

  render() {
    const { t, loading, transactions } = this.props;
    const { selectedTransaction, operationDetailsSheetOpened } = this.state;

    return (
      <Page
        id="transactions_page"
        name="transactions-page"
        onPageInit={this.pageInitHandle}
      >
        <Navbar
          title={t("Transactions")}
          backLink={t("Back").toString()}
          noHairline
          noShadow
        />

        {loading && (
          <Block className="text-align-center">
            <Preloader />
          </Block>
        )}

        {!loading && (
          <List className="transactions" noHairlines noChevron>
            {Object.keys(transactions).reverse().map((key: string) => (
              <ListGroup key={key}>
                <ListItem title={formatDate(key)} groupTitle>
                  {formatDate(key)}
                </ListItem>
                {transactions[key].map((item: any) => (
                  <ListItem
                    key={item.transactionUid}
                    link
                    title={getTransactionActionName(item).toString()}
                    footer={
                      /* TODO move to method */
                      (item.creditCard
                        ? item.creditCard.cardMask + " | "
                        : "") +
                      "" +
                      (item.transactionDate
                        ? formatDate(
                            item.transactionDate.toString().substr(0, 8)
                          )
                        : null)
                    }
                    onClick={() => this.transactionClickHandler(item)}
                  >
                    <div slot="media" className="image">
                      {item.amount > 0 ? (
                        <IcReceived />
                      ) : item.amount === 0 ? null : (
                        <IcSent />
                      )}
                    </div>
                    <div
                      slot="after"
                      className={classNames(
                        "amount",
                        item.amount > 0
                          ? "debit"
                          : item.amount === 0
                          ? ""
                          : "credit"
                      )}
                    >
                      {formatPrice(item.amount, item.currencyCode)}
                    </div>
                  </ListItem>
                ))}
              </ListGroup>
            ))}
          </List>
        )}

        <OperationDetails
          opened={operationDetailsSheetOpened}
          item={selectedTransaction}
          onSheetClosed={() =>
            this.setState({ operationDetailsSheetOpened: false })
          }
        />
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  ...state.transactionReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadTransactions: () => dispatch(loadTransactions(true)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(TransactionsPage);

// Helpers
function getTransactionActionName(item: Transaction) {
  // @ts-ignore
  return item.transactionActionName;
}
