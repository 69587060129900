import axios, { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/browser";
import { getQueryParameterFromURL } from "../utils";
import { store } from "../components/App";
import { restoreSession } from "../actions/sessionActions";

const apiURL = "";

export const requestConfig: AxiosRequestConfig = {
  baseURL: process.env.HOST + apiURL,
  responseType: "json",
  withCredentials: false,
};

export const client = axios.create(requestConfig);

client.interceptors.request.use(
  (config) => {
    //addCompress(config);
    addLanguageQueryParameter(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let subscribers = [];

client.interceptors.response.use(
  (res) => {
    const { accessToken } = res.data;
    if (accessToken) {
      updateAxiosClientCredential(accessToken);
    }
    return res;
  },
  (error) => {
    const originalRequest: any = error.config;

    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      // refresh token
      originalRequest._retry = true;
      /*
      const oldRefreshToken = store.getState().sessionReducer.refreshToken;
      */
      store.dispatch(restoreSession() as any);
      return client(originalRequest);
    } else if (error.response && error.response.status === 403) {
      subscribers.push(originalRequest);
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

let axiosCredentialInterceptorsId: number;

export const updateAxiosClientCredential = (accessToken: string) => {
  try {
    client.interceptors.request.eject(axiosCredentialInterceptorsId);
  } catch (error) {}

  axiosCredentialInterceptorsId = client.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  });
};

const addLanguageQueryParameter = (config: AxiosRequestConfig) => {
  const language = getQueryParameterFromURL("language");
  if (language && config.url.indexOf("language=") === -1) {
    config.params = {
      language,
      ...config.params,
    };
  }
};

const addCompress = (config: AxiosRequestConfig) => {
  config.headers["Accept-Encoding"] = "qzip";
};

export const marketplaceapiURL = "/marketplaceapi";
export const commonapiURL = "/commonapi";
export const paymentapiURL = "/paymentapi";
