import { Profile } from "../reducers/sessionReducer";
import { PRODUCT_ADDED_TO_WISH } from "./productActions";
import { IApplicationStore } from "../store/rootReducer";
import { getPlatform, Platform } from "../utils";
import { AccountAddressWsControllerApi, Address } from "../types/commonapi";

export const PROFILE_UPDATING = "PROFILE_UPDATING";
export const PROFILE_UPDATING_SUCCESS = "PROFILE_UPDATING_SUCCESS";
export const PROFILE_UPDATING_ERROR = "PROFILE_UPDATING_ERROR";

export const PROFILE_GO_TO_MESSENGER = "PROFILE_GO_TO_MESSENGER";

export const ACCOUNT_ADD_UPDATE_ADDRESS = "ACCOUNT_ADD_UPDATE_ADDRESS";
export const ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS =
  "ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS";
export const ACCOUNT_ADD_UPDATE_ADDRESS_ERROR =
  "ACCOUNT_ADD_UPDATE_ADDRESS_ERROR";

export const ACCOUNT_REMOVE_ADDRESS = "ACCOUNT_REMOVE_ADDRESS";
export const ACCOUNT_REMOVE_ADDRESS_SUCCESS = "ACCOUNT_REMOVE_ADDRESS_SUCCESS";
export const ACCOUNT_REMOVE_ADDRESS_ERROR = "ACCOUNT_REMOVE_ADDRESS_ERROR";

export const isProductInWish = (
  uid: string,
  state: IApplicationStore
): boolean => {
  const { productsWishList = [] } = state.productReducer;
  return productsWishList.filter((item) => item.uid === uid).length > 0;
};

export const updateProfile = (profile: Profile) => (dispatch: any) => {
  dispatch(profileUpdatingAction());

  setTimeout(() => {
    dispatch(profileUpdatingSuccessAction(profile));
  }, 1000);
};

export const goToMessenger = () => (dispatch: any) => {
  const platform = getPlatform();
  if (platform == Platform.Android) {
  } else if (platform == Platform.iOS) {
  } else {
    window.parent.postMessage({ goToMessenger: true }, "*");
  }
  dispatch({ type: PROFILE_GO_TO_MESSENGER });
};

export const addOrUpdateAddress = (address: Address) => async (
  dispatch: any,
  getState: () => IApplicationStore
) => {
  dispatch(addOrUpdateAddressAction());

  const state = getState();
  const profile = state.profileReducer.profile;

  try {
    let prepare = {
      city: address.city,
      countryCode: address.country.code,
      firstAddressLine: address.firstAddressLine,
      postalCode: address.postalCode,
      secondAddressLine: address.secondAddressLine,
      state: address.state || "",
      uid: null,
    };

    // @ts-ignore
    if (address.dueDate) {
      // @ts-ignore
      prepare = { ...prepare, dueDate: address.dueDate };
    }

    const addresses = (
      await new AccountAddressWsControllerApi().addAddressUsingPUT({
        address: prepare,
        primary: false,
        type: "O",
      })
    ).account.addresses;
    dispatch(addOrUpdateAddressSuccessAction(addresses));
  } catch (err) {
    dispatch(addOrUpdateAddressErrorAction(err));
  }
};

export const removeAddress = (uid: string) => async (
  dispatch: any,
  getState: () => IApplicationStore
) => {
  dispatch(removeAddressAction());

  const state = getState();
  const profile = state.profileReducer.profile;

  try {
    const address = profile.addresses.filter((item) => item.uid === uid)[0];
    const addresses = (
      await new AccountAddressWsControllerApi().deleteAddressUsingDELETE({
        address,
      })
    ).account.addresses;
    dispatch(removeAddressSuccessAction(addresses));
  } catch (err) {
    dispatch(removeAddressErrorAction(err));
  }
};

const profileUpdatingAction = () => ({
  type: PROFILE_UPDATING,
});

const profileUpdatingSuccessAction = (profile?: Profile) => ({
  type: PROFILE_UPDATING_SUCCESS,
  profile,
});

const profileUpdatingErrorAction = (error: any) => ({
  type: PROFILE_UPDATING_ERROR,
  error,
});

const productAddedToWish = (uid: string) => ({
  type: PRODUCT_ADDED_TO_WISH,
  uid,
});

const addOrUpdateAddressAction = () => ({
  type: ACCOUNT_ADD_UPDATE_ADDRESS,
});

const addOrUpdateAddressSuccessAction = (addresses: Address[]) => ({
  type: ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS,
  addresses,
});

const addOrUpdateAddressErrorAction = (error: any) => ({
  type: ACCOUNT_ADD_UPDATE_ADDRESS_ERROR,
  error,
});

const removeAddressAction = () => ({
  type: ACCOUNT_REMOVE_ADDRESS,
});

const removeAddressSuccessAction = (addresses: Address[]) => ({
  type: ACCOUNT_REMOVE_ADDRESS_SUCCESS,
  addresses,
});

const removeAddressErrorAction = (error: any) => ({
  type: ACCOUNT_REMOVE_ADDRESS_ERROR,
  error,
});
