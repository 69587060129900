import React from 'react';
import {
  Searchbar, Link, F7Link, Icon
} from 'framework7-react';

import './ShareButton.less';
import { Device } from 'framework7';

export default (props: F7Link.Props) => (
  <Link className="share-button" href="#" iconOnly {...props}>
    <Icon material="share"></Icon>
  </Link>
)