import { IApplicationStore } from "./rootReducer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { searchProducts, searchClear } from "../actions/productActions";

export type ISearchConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: IApplicationStore) => ({
  searchTerm: state.productReducer.searchTerm,
  searchLoading: state.productReducer.loading,
  searchedProducts: state.productReducer.products,
  searchLoadingAutocomplete: state.productReducer.loadingAutocomplete,
  searchProductsAutocomplete: state.productReducer.productsAutocomplete,
  searchProductsAutocompleteCount: state.productReducer.productsAutocompleteCount,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      search: searchProducts,
      clearSearch: searchClear,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps);
