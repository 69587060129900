import React, { FC } from "react";
import { Link, F7Link, Icon } from "framework7-react";
import classNames from "classnames";

import "./AddWishButton.less";

type Props = F7Link.Props & {
  active?: boolean;
  withShadow?: boolean;
};

const AddWishButton: FC<Props> = (props) => {
  return (
    <Link
      href="#"
      iconOnly
      {...props}
      className={`add-wish ${props.className ? props.className : ""} ${props.active ? "active" : ""}`}
    >
      {props.withShadow ? (
        // <i className={`icon ${props.active ? "ic-wish-shadow-active" : "ic-wish-shadow-inactive"}`}></i>
        <i className={classNames("icon", props.active ? "ic-wish-shadow-active" : "ic-wish-shadow-inactive")}></i>
      ) : (
        <Icon material={props.active ? "favorite" : "favorite_border"}></Icon>
      )}
    </Link>
  );
};

export default AddWishButton;
