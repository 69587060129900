import React, { useEffect, useState } from "react";
import { BlockTitle, Button, f7, F7Popup, Link, Navbar, NavRight, Popup } from "framework7-react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";
import { ReferralProgramWsControllerApiFp } from "../../types/commonapi";
import { useSelector } from "react-redux";
import { IApplicationStore } from "../../store/rootReducer";

interface Props {
  opened: boolean;
}

const InviteFriendPopUp = (props: Props & F7Popup.Props) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [url, setUrl] = useState<string>("");
  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    setOpened(props.opened);
    const fn = ReferralProgramWsControllerApiFp().getReferralProgramCodeUsingPOST({});
    fn()
      .then((response) => setCode(response.referralProgramCode))
      .catch(console.log);
  }, [props.opened]);

  useEffect(() => {
    code && setUrl(`${process.env.HOST}/market/#/?action=register&referral=${code}`);
  }, [code]);

  const resizeEvent = useSelector((state: IApplicationStore) => state.rootReducer.resizeEvent);
  const isMobile = resizeEvent.width < 567;

  return (
    <Popup
      id="share-container"
      swipeToClose
      swipeHandler=".swipe-handler"
      opened={opened}
      {...props}
      style={{ width: !isMobile ? 400 : undefined }}
    >
      <Navbar noHairline noShadow>
        <BlockTitle medium style={{ paddingBottom: "20px" }}>
          {t("Invite a Friend")}
        </BlockTitle>
        <NavRight>
          <Link popupClose onClick={() => setOpened(false)}>
            {t("Close")}
          </Link>
        </NavRight>
      </Navbar>
      <div style={{ marginTop: 10, marginLeft: 16, marginRight: 16 }}>{t("Invite Form Text")}</div>
      <BlockTitle medium style={{ paddingBottom: "10px" }}>
        {t("Invite Code")}
      </BlockTitle>
      <div className="url-container" style={{ marginLeft: 16, marginRight: 16 }}>
        <div className="url" style={{ flex: 1 }}>
          {code}
        </div>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(code);
            f7.dialog.preloader(t("Copied"));
            setTimeout(function () {
              f7.dialog.close();
            }, 1000);
          }}
        >
          {t("copy")}
        </Button>
      </div>
      <BlockTitle medium style={{ paddingBottom: "10px" }}>
        Share Invitation
      </BlockTitle>
      <div className="share-buttons-container">
        <div className="social-network">
          <EmailShareButton url={url}>
            <EmailIcon size={60} round />
          </EmailShareButton>
        </div>
        <div className="social-network">
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={60} round />
          </WhatsappShareButton>
        </div>
        <div className="social-network">
          <FacebookShareButton url={url}>
            <FacebookIcon size={60} round />
          </FacebookShareButton>
        </div>
        <div className="social-network">
          <TelegramShareButton url={url}>
            <TelegramIcon size={60} round />
          </TelegramShareButton>
        </div>
      </div>
      <div className="url-container" style={{ marginLeft: 16, marginRight: 16 }}>
        <div className="url">{url}</div>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(url);
            f7.dialog.preloader(t("Copied"));
            setTimeout(function () {
              f7.dialog.close();
            }, 1000);
          }}
        >
          {t("copy")}
        </Button>
      </div>
    </Popup>
  );
};

export default InviteFriendPopUp;
