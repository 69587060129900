import React, { Component } from "react";
import {
  Page,
  Block,
  BlockTitle,
  Icon,
  List,
  ListItem,
  Sheet,
  Row,
  Col,
  Popup,
  NavRight,
  Link,
  Stepper,
  F7Navbar,
} from "framework7-react";
import { compose } from "redux";
import { logoutUser } from "../actions/sessionActions";
import { AboutPopup } from "../components/AboutPopup";
import { ContactSupportPopup } from "../components/ContactSupportPopup";
import { LanguageLink } from "../components/LanguageLink";
import LanguagePopover from "../components/LanguagePopover/LanguagePopover";
import LoginDesktopPopup from "../components/LoginDesktop/LoginDesktopPopup";
import { RegisterDesktopPopup } from "../components/RegisterDesktop";
import connectFilter from "../store/connectFilter";
import connectCategories from "../store/connectCategories";
import { IApplicationStore } from "../store/rootReducer";
import { IProduct } from "../reducers/productReducer";
import { connect } from "react-redux";
import connectCategoriesClassificator from "../store/connectCategoriesClassificator";
import { Price } from "../components/Price/index";
import AddWishButton from "../components/AddWishButton";
import {
  addToWishList,
  ISearchParams,
  loadProductWishList,
  searchClear,
  searchProducts,
} from "../actions/productActions";
import Slider, { SliderType, SliderItem } from "../components/Slider";
import { loadProductDetail } from "../actions/productActions";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "../components/ThemedButton";
import connectChat, { IChatProps } from "../store/connectChat";
import connectShare, { IShareProps } from "../store/connectShare";
import Map from "../components/Map";
import {
  DescriptionDetails,
  TechnicalDetails,
  CustomDetails,
  CategoryDetails,
  ProductPostedBlock,
} from "../components/ProductDetails";
import { getProfile } from "../selectors/profile";
import { VideoPlayer } from "../components/VideoPlayer";
import { goToMessenger } from "../actions/profileActions";
import { chooseCategory } from "../actions/filterActions";
import { CategoriesMenuDesktop } from "../components/categories-menu-desktop";
import { ProfilePopover } from "../components/profile-popover";
import connectCart, { ICartProps } from "../store/connectCart";
import AddToCartSheetPage from "./product-details-add-to-cart__sheet";
import { ProductParamsForm } from "../components/product-params-form";

import "./categories.less";
import "./product-details.less";
import Navbar from "../components/navbar";
import connectSearch, { ISearchConnectorProps } from "../store/connectSearch";
import { ShortRegistrationPopup } from "../components/ShortRegistrationPopup";
import { ForgotPasswordPopup } from "../components/ForgotPassword";
import { analytics } from "../Setup";
import ShareComponent from "../components/ShareComponent/SharePopup";
import InviteFriendPopUp from "../components/InviteFriendPopUp";
import ViewProfilePopup from "../components/ViewProfilePopup";
import ProductDetailsAddToCartSheetNew from "./product-details-add-to-cart_sheetNew";
import { Router } from "framework7/modules/router/router";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithTranslation &
  IChatProps &
  IShareProps &
  ICartProps &
  ISearchConnectorProps & {
    f7router: Router.Router;
  };

type State = {
  searchBarEnable?: boolean;
  selectCategorySheetOpened?: boolean;
  categoriesMenuOpened?: boolean;
  profilePopoverOpened?: boolean;
  viewProfilePopupOpened?: boolean;
  sortingMenuPopoverOpened?: boolean;
  languagePopoverOpened?: boolean;
  addToCartSheetOpened?: boolean;
  addToCartSheetItemCount?: number;
  addToCartStepperInit?: boolean /* bug with Stepper */;
  loginPopupOpened?: boolean;
  registerPopupOpened?: boolean;
  invitePopupOpened?: boolean;
  contactSupportPopupOpened?: boolean;
  aboutPopupOpened?: boolean;
  formValid: boolean;
  shortRegisterPopupOpened?: boolean;
  forgotPasswordPopupOpened?: boolean;
  sharePopupOpened?: boolean;
  addedInCard: boolean;
};

const addToCartItemDefaultState = {
  addToCartSheetItemCount: 1,
  addToCartStepperInit: false,
};

const deliveryKeysTitles = {
  shippingAllowed: "Shipping allowed",
  pickupAllowed: "Pick up allowed",
  returnAccepted: "Return allowed",
};

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "198px",
};

const config = process.env.CONFIG;

class ProductDetailsPage extends Component<
  Props,
  {
    playVideoSheetOpened?: boolean;
    videoId?: string;
    videoType?: string;
    imageHoveredItemImageSwitcher?: number;
    openedFromCart?: boolean;
  } & State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      playVideoSheetOpened: false,
      addToCartSheetOpened: false,
      openedFromCart: false,
      loginPopupOpened: false,
      registerPopupOpened: false,
      invitePopupOpened: false,
      viewProfilePopupOpened: false,
      contactSupportPopupOpened: false,
      aboutPopupOpened: false,
      formValid: false,
      shortRegisterPopupOpened: false,
      forgotPasswordPopupOpened: false,
      sharePopupOpened: false,
      addedInCard: false,
      ...addToCartItemDefaultState,
    };
  }

  // @ts-ignore
  private messenger = process.env.MESSENGER === "true";
  private simplifiedRegistration = process.env.SIMPLIFIED_REGISTRATION === "true";

  pageInitHandle = () => {
    const filteredRoutes = this.$f7router.history.filter((item) => item !== this.$f7route.path);

    if (filteredRoutes.length && filteredRoutes[filteredRoutes.length - 1] === "/cart/") {
      this.setState({ openedFromCart: true });
    }
  };

  pageAfterInHandle = () => {
    this.setState({ addedInCard: false });
    this.loadProductDetails();
  };

  async componentDidUpdate(prevProps: Props) {
    this.handleChatErrors(prevProps);
    this.handleShareErrors(prevProps);

    const { profile } = this.props;
    if (profile && profile.uid && profile.uid !== prevProps.profile.uid) {
      this.props.loadProductWishList();
    }
  }

  handleChatErrors = (prevProps: Props) => {
    const { error } = this.props.chatReducer;
    if (error && error !== prevProps.chatReducer.error) {
      this.$f7?.dialog.alert(error);
    }
  };

  handleShareErrors = (prevProps: Props) => {
    const { error } = this.props.shareStore;
    if (error && error !== prevProps.shareStore.error) {
      this.$f7?.dialog.alert(error);
    }
  };

  loadProductDetails = () => {
    if (this.props.productDetailsLoading || this.props.productDetailsLoadingError) {
      return;
    }

    // @ts-ignore
    const { uid, item, profile } = this.props;
    if (item && item.description) {
      analytics.openProduct(profile, item);
      return;
    }

    this.props.loadProductDetail(uid);
  };

  addToWish = () => {
    const { item, profile } = this.props;
    this.props.addToWish(item.uid);
    analytics.addToWishList(profile, item);
  };

  isAddedToWishList = () => {
    // @ts-ignore
    const { uid, wishList } = this.props;
    return wishList.filter((item) => item.uid === uid).length > 0;
  };

  getProductImagesSlides = (): SliderItem[] => {
    const { item } = this.props;
    const thumbnails = Object.keys(item)
      .filter((item) => item.startsWith("imageThumbnailUrl"))
      .map((key) => item[key]);

    return item.images.map((image, i) => ({ image, small: thumbnails[i] })) || [];
  };

  renderAddressBlock = () => {
    const {
      item: { address, coordinates },
      t,
    } = this.props;
    if (!address) return null;

    let addressString = "";
    try {
      if (address.countryCode) addressString = address.countryCode;
      if (address.city) addressString = addressString + (addressString ? ", " : "") + address.city;
    } catch (err) {}

    function toLatLgnLiteral(coordinates: string) {
      try {
        const arr = coordinates.split(",");
        return { lat: arr[0], lng: arr[1] };
      } catch {}
      return { lat: 0, lng: 0 };
    }

    return (
      <Block>
        <div className="location-label">
          <i className="icon ic-location" />
          {addressString && <span className="address">{addressString}</span>}
          <span className="distance">0 {t("km")}</span>
        </div>
        {coordinates && (
          <Map containerStyle={containerStyle} zoom={17} center={toLatLgnLiteral(coordinates)} zoomControl />
        )}
      </Block>
    );
  };

  reportAdvertisementHandle = () => {
    this.setState({ contactSupportPopupOpened: true });
  };

  handleAddToCartMobile = () =>
    this.setState({
      ...addToCartItemDefaultState,
      addToCartStepperInit: true,
      addToCartSheetOpened: true,
    });

  handleAddToCartDesktop = () => {
    const { item } = this.props;
    const { addToCartSheetItemCount } = this.state;
    if (item.productParams && item.productParams.length && item.productParams.filter((item) => item.mandatory).length) {
      if (this.state.formValid) {
        this.updateCartState(item, addToCartSheetItemCount);
        this.setState({ addedInCard: true });
      } else {
        this.$f7.dialog.alert("Please fill out additional parameters");
        return;
      }
    } else {
      this.updateCartState(item, addToCartSheetItemCount);
      this.setState({ addedInCard: true });
    }
  };

  handleProductParamsSheetClosed = () => {
    const { item } = this.props;
    const { addToCartSheetItemCount } = this.state;
    this.updateCartState(item, addToCartSheetItemCount);
  };

  updateCartState = (item: IProduct, count: number) => {
    this.setState({ ...addToCartItemDefaultState }, () => this.props.cartAdd(item, count));
    analytics.addToCart(this.props.profile, item, count);
  };

  renderBottomImageSwitcher = (imagesItems: any[]) => {
    if (imagesItems.length === 1) return null;

    return (
      <Block className="image-switcher">
        {imagesItems.slice(0, 5).map((item, i) => {
          return (
            <div
              key={i.toString()}
              className="image-switcher-item"
              onMouseOver={() =>
                this.setState({
                  imageHoveredItemImageSwitcher: i,
                })
              }
            >
              {item.videoId ? <i className="icon material-icons">play_circle_filled</i> : <img src={item.small} />}
            </div>
          );
        })}
      </Block>
    );
  };

  sharePopupClose = () => {
    this.setState({ sharePopupOpened: false });
  };

  renderProductDetails = () => {
    const {
      profile,
      item,
      t,
      entryDirect,
      resizeEvent: { isLG, isMD, isXL },
    } = this.props;

    const isSmallScreen = !isLG && !isMD && !isXL;

    if (!item) return <Block></Block>;

    const { imageHoveredItemImageSwitcher } = this.state;

    let imagesItems = this.getProductImagesSlides();

    if (item.video) {
      /* Video must be first */
      imagesItems = [{ videoId: item.video.id, videoType: item.video.type.toString() }, ...imagesItems];
    }

    return (
      <Row>
        <Col width="100" className="pure-hidden-xs">
          <F7Navbar title="" backLink={entryDirect ? false : t("Back").toString()} noHairline noShadow />
        </Col>
        <Col width="100" medium="50">
          <Block className="no-padding images">
            <Slider
              className="slider-images"
              slides={imagesItems}
              type={SliderType.images}
              videoPlayOnClick={(videoId, videoType) => {
                this.setState({
                  playVideoSheetOpened: true,
                  videoId,
                  videoType,
                });
              }}
              slideIndex={imageHoveredItemImageSwitcher}
            />

            {/* Bottom image switcher */}
            <div className="pure-hidden-xs">{this.renderBottomImageSwitcher(imagesItems)}</div>
          </Block>
        </Col>
        <Col width="100" medium="50">
          <BlockTitle className="product-title">
            <span>{item.name}</span>
            <div className="buttons-container pure-visible-xs">
              <ShareComponent uid={item.uid}></ShareComponent>
              <AddWishButton onClick={this.addToWish} active={this.isAddedToWishList()} />
            </div>
          </BlockTitle>
          <Block>
            <Price price={item.price} discountedPrice={item.discountedPrice} currencyCode={item.currencyCode} />

            {!isSmallScreen && (
              <div className="toolbar-container pure-hidden-xs">
                <div>
                  <Stepper
                    className="add-product-stepper"
                    onStepperChange={(val: any) => {
                      this.setState({ addToCartSheetItemCount: val });
                    }}
                    min={1}
                    value={this.state.addToCartSheetItemCount}
                  />
                </div>
              </div>
            )}

            {this.props.resizeEvent.width > 767 && this.renderAdditionalParameters()}

            {!isSmallScreen && (
              <div className="toolbar-container pure-hidden-xs">
                <div className="add-to-cart-btn-container">
                  {this.renderAddToCartButton(this.handleAddToCartDesktop)}
                </div>
                <div className="buttons-container">
                  <ShareComponent uid={item.uid}></ShareComponent>
                  <AddWishButton onClick={this.addToWish} active={this.isAddedToWishList()} />
                </div>
              </div>
            )}

            <DescriptionDetails text={item.description} moreLinkText={t("More")} lessLinkText={t("Less")} />
          </Block>

          <div className="accordion-item">
            <div className="accordion-item-content">
              <TechnicalDetails product={item} />
              <CustomDetails product={item} />
              <CategoryDetails categoryCode={item.category} />
              <ProductPostedBlock date={item.productDate} postedBlockTitle={t("Posted")} />
            </div>
            <div className="accordion-item-toggle">
              <Block>
                <a href="#">{t("Show all characteristics")}</a>
              </Block>
            </div>
          </div>

          <Block>
            <hr />
          </Block>

          {this.renderDeliveryInformationBlock()}

          <Block>
            <hr />
          </Block>

          {this.renderAddressBlock()}

          <Block className="pure-hidden-xs">
            <Button round fill className="report-advertisement-button" onClick={this.reportAdvertisementHandle}>
              {t("Report Advertisement")}
            </Button>
          </Block>
        </Col>
      </Row>
    );
  };

  renderDeliveryInformationBlock() {
    const { item, t } = this.props;
    var deliveryInfoKeys: (keyof Pick<typeof item, "shippingAllowed" | "pickupAllowed" | "returnAccepted">)[] = [
      "shippingAllowed",
      "returnAccepted",
      "pickupAllowed",
    ];

    const items = deliveryInfoKeys
      .map((key) => {
        return (
          item[key] && (
            <ListItem key={key}>
              <div slot="media">{item[key] && <Icon material="checkmark_alt" />}</div>
              <div slot="title">{t(deliveryKeysTitles[key])}</div>
            </ListItem>
          )
        );
      })
      .filter((item) => !!item);

    return (
      !!items.length && (
        <>
          <BlockTitle className="group-title">{t("Delivery Information")}</BlockTitle>
          <List noChevron noHairlines mediaList className="delivery-info">
            {items}
          </List>
        </>
      )
    );
  }

  onClickProfileLink = () => {
    const { profile } = this.props;
    if (profile && profile.uid) {
      this.handleClickProfileLink();
    } else {
      this.handleClickLoginLink();
    }
  };

  handleClickProfileLink = () => {
    this.setState({ profilePopoverOpened: true });
  };

  handleClickLoginLink = () => {
    this.setState({ loginPopupOpened: true });
  };

  renderLanguageLink = () => {
    const { resizeEvent, language } = this.props;
    const props = { language };
    if (resizeEvent && resizeEvent.width > 567 && language) {
      props["href"] = "#";
      props["className"] = "pure-hidden-xs";
      props["onClick"] = this.handleClickLanguageLink;
      return <LanguageLink {...props} t={this.props.t} />;
    }
    return null;
  };

  handleClickLanguageLink = () => {
    this.setState({ languagePopoverOpened: true });
  };

  renderAddToCartButton(handler: any) {
    const { t } = this.props;
    const { addedInCard } = this.state;
    return addedInCard ? (
      <Button
        href="/cart/"
        large
        fill
        round
        onClick={() => {
          this.setState({ addedInCard: false });
        }}
      >
        <Icon material="done" />
        {t("In Your Cart")}
      </Button>
    ) : (
      <Button large fill round onClick={handler}>
        {t("Add to cart")}
      </Button>
    );
  }

  renderAdditionalParameters = () => {
    const {
      item: { productParams },
    } = this.props;
    return productParams && productParams.length ? (
      <ProductParamsForm
        productParams={productParams}
        onChangeForm={(productParams, formValid) => this.setState({ formValid }, () => console.log(productParams))}
      />
    ) : null;
  };

  onClickLogoLink = () => {
    this.$f7.searchbar.disable(".search-bar");
    this.props.chooseCategory(null);
    this.props.clearSearch();
    this.$f7router.navigate("/");
  };

  onClickOpenCategoriesMenu = () =>
    this.setState({
      categoriesMenuOpened: !this.state.categoriesMenuOpened,
    });

  searchbarEnableHandle = () => {
    this.setState({ searchBarEnable: true });
  };

  searchbarDisableHandle = () => {
    this.setState({ searchBarEnable: false }, () => {
      this.props.clearSearch();
    });
  };

  searchbarClearHandle = () => {
    this.props.clearSearch();
  };

  handleRegisterButton = () => {
    if (this.simplifiedRegistration) {
      this.setState({
        shortRegisterPopupOpened: true,
      });
    } else {
      this.setState({
        registerPopupOpened: true,
      });
    }
    analytics.openRegistrationForm();
  };

  render() {
    const { t, resizeEvent, item, profile } = this.props;
    const { playVideoSheetOpened, videoId, videoType } = this.state;

    return (
      <Page
        id="product_details"
        name="product-details"
        onPageInit={this.pageInitHandle}
        onPageAfterIn={this.pageAfterInHandle}
      >
        <Navbar
          profile={profile}
          showProfileLink
          onClickProfileLink={this.onClickProfileLink}
          onClickLogoLink={this.onClickLogoLink}
          onClickOpenCategoriesMenu={this.onClickOpenCategoriesMenu}
          openCategoriesMenuButton={this.state.categoriesMenuOpened}
          language={this.props.language}
          onClickLanguageLink={this.handleClickLanguageLink}
          showLanguageLink
          onSearchbarEnable={this.searchbarEnableHandle}
          onSearchbarDisable={this.searchbarDisableHandle}
          onSearchClickClear={this.searchbarClearHandle}
          showSearchbar={false}
          findedProducts={this.props.searchProductsAutocomplete}
          findedProductsCount={this.props.searchProductsAutocompleteCount}
          findProductLoading={this.props.searchLoadingAutocomplete}
          onFindedProductItemClick={(uid) => this.$f7router.navigate(`/product-details/${uid}/`)}
          onSearchResult={() => {
            this.$f7router.navigate("/");
          }}
          showBackLink
          slot="fixed"
        />

        {this.renderProductDetails()}

        <Popup
          id="play_video_sheet"
          className="play-video-popup"
          swipeToClose
          backdrop
          opened={playVideoSheetOpened && resizeEvent.width >= 768}
          onPopupClosed={() => {
            if (resizeEvent.width >= 768) {
              this.setState({
                playVideoSheetOpened: false,
                videoId: null,
                videoType: null,
              });
            }
          }}
        >
          <Page>
            <F7Navbar title="" bgColor="black">
              <NavRight>
                <Link popupClose>{t("Close")}</Link>
              </NavRight>
            </F7Navbar>
            <VideoPlayer videoId={videoId} videoType={videoType} />
          </Page>
        </Popup>

        <Sheet
          id="play_video_sheet"
          swipeToClose
          backdrop
          opened={playVideoSheetOpened && resizeEvent.width < 768}
          onSheetClosed={() => {
            if (resizeEvent.width < 768) {
              this.setState({
                playVideoSheetOpened: false,
                videoId: null,
                videoType: null,
              });
            }
          }}
        >
          <VideoPlayer videoId={videoId} videoType={videoType} />
        </Sheet>

        <div slot="fixed">
          <CategoriesMenuDesktop className="pure-hidden-xs" opened={this.state.categoriesMenuOpened} />
        </div>

        <Block slot="fixed" className="add-to-cart-btn-container pure-visible-xs">
          {this.renderAddToCartButton(this.handleAddToCartMobile)}
        </Block>

        <ProfilePopover
          profile={this.props.profile}
          backdrop={false}
          opened={this.state.profilePopoverOpened}
          target=".profile-link"
          onPopoverClosed={() => this.setState({ profilePopoverOpened: false })}
          t={t}
          slot="fixed"
          onLogout={() => {
            this.$f7.preloader.show();
            this.props.logout();
          }}
          onInviteClick={() => this.setState({ invitePopupOpened: true, profilePopoverOpened: false })}
          onAboutClick={() => this.setState({ aboutPopupOpened: true })}
          onViewClick={() => this.setState({ viewProfilePopupOpened: true, profilePopoverOpened: false })}
        />

        <LanguagePopover
          backdrop={false}
          opened={this.state.languagePopoverOpened}
          target=".language-link"
          onPopoverClosed={() => this.setState({ languagePopoverOpened: false })}
          t={t}
          slot="fixed"
        />

        <LoginDesktopPopup
          f7router={this.props.f7router}
          opened={this.state.loginPopupOpened}
          onPopupClosed={() => this.setState({ loginPopupOpened: false })}
          t={t}
          slot="fixed"
          onRegister={() => {
            this.setState({ loginPopupOpened: false });
            this.handleRegisterButton();
          }}
          onForgotPassword={() => {
            this.setState({ loginPopupOpened: false });
            this.setState({ forgotPasswordPopupOpened: true });
          }}
        />

        {!this.simplifiedRegistration && (
          <RegisterDesktopPopup
            opened={this.state.registerPopupOpened}
            onPopupClosed={() => this.setState({ registerPopupOpened: false })}
            t={t}
            slot="fixed"
          />
        )}

        {this.simplifiedRegistration && (
          <ShortRegistrationPopup
            opened={this.state.shortRegisterPopupOpened}
            onPopupClosed={() => this.setState({ shortRegisterPopupOpened: false })}
            t={t}
            slot="fixed"
          />
        )}

        <ForgotPasswordPopup
          profile={profile}
          backdrop={false}
          opened={this.state.forgotPasswordPopupOpened}
          onPopupClosed={() => this.setState({ forgotPasswordPopupOpened: false })}
        />

        <ProductDetailsAddToCartSheetNew
          opened={this.state.addToCartSheetOpened}
          onSheetClosed={() => this.setState({ addToCartSheetOpened: false })}
          item={item}
          onAddToCartClick={(count, productParams) => {
            this.setState({ addToCartSheetOpened: false, addedInCard: true }, () => {
              this.updateCartState({ ...item, productParams }, count);
            });
          }}
        />

        {item && (
          <ContactSupportPopup
            profile={profile}
            category="Product"
            product={item}
            backdrop={false}
            opened={this.state.contactSupportPopupOpened}
            onPopupClosed={() => this.setState({ contactSupportPopupOpened: false })}
          />
        )}

        <AboutPopup
          profile={profile}
          backdrop={false}
          opened={this.state.aboutPopupOpened}
          onPopupClosed={() => this.setState({ aboutPopupOpened: false })}
          onContactSupportClick={() => this.setState({ contactSupportPopupOpened: true })}
        />

        <InviteFriendPopUp
          backdrop={false}
          opened={this.state.invitePopupOpened}
          onPopupClosed={() => this.setState({ invitePopupOpened: false })}
        />

        <ViewProfilePopup
          profile={profile}
          opened={this.state.viewProfilePopupOpened}
          f7={this.$f7}
          onPopupClosed={() => this.setState({ viewProfilePopupOpened: false })}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore, props: any) => {
  const { uid } = props;
  const { productDetails, productDetailsLoading, productDetailsLoadingError } = state.productReducer;

  let item = state.productReducer.products.find((p) => p.uid === uid);
  if (!item) {
    state.productReducer.productTypeGroups.forEach((t) =>
      t.products.forEach((p) => {
        if (p.uid === uid) item = p;
      })
    );
  }

  if (!item) {
    const { productsWishList } = state.productReducer;
    item = productsWishList.filter((item) => item.uid === uid)[0];
  }

  if (!item) item = state.productReducer.productsWishList.find((p) => p.uid === uid);
  if (!item) item = state.allGoodsReducer.products.find((p) => p.uid === uid);

  return {
    productDetailsLoading,
    productDetailsLoadingError,
    item: productDetails && productDetails.uid === uid ? productDetails : item,
    wishList: state.productReducer.productsWishList,
    updatingProfile: state.profileReducer.updating,
    profile: getProfile(state),
    entryDirect: entryPageNameEqual("product-details", state),
    resizeEvent: state.rootReducer.resizeEvent,
    language: state.rootReducer.language,
    searchedProducts: state.productReducer.products || [],
    searchLoading: state.productReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  addToWish: (uid?: string) => dispatch(addToWishList(uid)),
  loadProductDetail: (uid: string) => dispatch(loadProductDetail(uid)),
  loadProductWishList: () => dispatch(loadProductWishList()),
  goToMessenger: () => dispatch(goToMessenger()),
  chooseCategory: (catid?: string) => dispatch(chooseCategory(catid)),
  clearSearch: () => dispatch(searchClear()),
  logout: () => dispatch(logoutUser()),
  search: (searchParams: ISearchParams) => dispatch(searchProducts(searchParams)),
});

export default compose(
  withTranslation(),
  connectFilter,
  connectCategories,
  connectCategoriesClassificator,
  connectChat,
  connectShare,
  connectCart,
  connect(mapStateToProps, mapDispatchToProps),
  connectSearch
)(ProductDetailsPage);

// Helpers
const entryPageNameEqual = (pageName: string, store: IApplicationStore) => {
  return store.rootReducer.entryPageName === pageName;
};
