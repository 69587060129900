import React from "react";
import { Link, F7Link } from "framework7-react";
import classNames from "classnames";
import connectCart, { ICartProps } from "../../store/connectCart";
import { IcCart } from "../../components-ui/icons";
import { compose } from "redux";
import { Badge } from "../Badge";

import "./style.less";

type Props = F7Link.Props & ICartProps;

const CartLink = ({ className, ...props }: Props) => (
  <Link href="/cart/" {...props} className={classNames("cart-link", className)}>
    <IcCart />
    <Badge />
  </Link>
);

export const ConnectedCartLink = compose(connectCart)(CartLink);

export default CartLink;
