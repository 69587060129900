import { ProductListType, IProduct } from "../reducers/productReducer";
import { IApplicationStore } from "../store/rootReducer";
import { isProductInWish } from "./profileActions";
import {
  ProductControllerApi,
  ProductSearchListRequest,
  PublicControllerApi,
  WishListControllerApi,
} from "../types/marketplaceapi";
import { getSortByFields } from "../pages/all-filtres-popup";

export const PRODUCT_LIST_LOADING = "PRODUCT_LIST_LOADING";
export const PRODUCT_LIST_LOADING_SUCCESS = "PRODUCT_LIST_LOADING_SUCCESS";
export const PRODUCT_LIST_LOADING_ERROR = "PRODUCT_LIST_LOADING_ERROR";

export const PRODUCT_LIST_TYPE_LOADING = "PRODUCT_LIST_TYPE_LOADING";
export const PRODUCT_LIST_TYPE_LOADING_SUCCESS = "PRODUCT_LIST_TYPE_LOADING_SUCCESS";
export const PRODUCT_LIST_TYPE_LOADING_ERROR = "PRODUCT_LIST_TYPE_LOADING_ERROR";

export const PRODUCT_DETAILS_LOADING = "PRODUCT_DETAILS_LOADING";
export const PRODUCT_DETAILS_LOADING_SUCCESS = "PRODUCT_DETAILS_LOADING_SUCCESS";
export const PRODUCT_DETAILS_LOADING_ERROR = "PRODUCT_DETAILS_LOADING_ERROR";

export const PRODUCT_ADDED_TO_WISH = "PRODUCT_ADDED_TO_WISH";

export const PRODUCT_WISH_LIST_LOADING = "PRODUCT_WISH_LIST_LOADING";
export const PRODUCT_WISH_LIST_LOADING_SUCCESS = "PRODUCT_WISH_LIST_LOADING_SUCCESS";
export const PRODUCT_WISH_LIST_LOADING_ERROR = "PRODUCT_WISH_LIST_LOADING_ERROR";

export const SEARCH_UPDATE_RESULT_COUNT = "SEARCH_UPDATE_RESULT_COUNT";
export const CLEAR_SEARCH_RESULT = "CLEAR_SEARCH_RESULT";

export type SortBy = {
  direction?: "ASC" | "DESC";
  field?: string;
  sortingIndex?: number;
};

export type ISearchParams = Pick<
  ProductSearchListRequest,
  | "address"
  | "category"
  | "coordinates"
  | "hashtags"
  | "inStock"
  | "name"
  | "count"
  | "offset"
  | "sortBy"
  | "type"
  | "discountedPriceFrom"
> & {
  resetSearch?: boolean;
  resetSorting?: boolean;
};

export function mapProductDetailsImage(item: IProduct) {
  const keys = Object.keys(item);
  const imagesKeys = keys.filter((key) => key.includes("imageUrl"));
  return imagesKeys.map((key) => item[key]);
}

export async function loadProductDetails(uid: string, state: IApplicationStore): Promise<IProduct> {
  const { logged } = state.sessionReducer;
  const { language } = state.rootReducer;
  let item: IProduct;
  if (logged) {
    item = (await new ProductControllerApi().productSearchDetailsUsingPOST({ uid }, language)).body[0];
  } else {
    item = (await new PublicControllerApi().productSearchDetailsUsingPOST1({ uid }, language)).body[0];
  }
  item.wish = isProductInWish(item.uid, state);
  item.images = mapProductDetailsImage(item);
  return item;
}

export const searchProducts =
  (searchParams: ISearchParams, autocomplete = false) =>
  async (dispatch: any, getState: () => IApplicationStore) => {
    dispatch(productListLoadingAction(autocomplete));
    const state = getState();
    const { chosenCategoryId, chosenSubcategoryId, sortBy } = state.filterReducer;
    const { count, offset, searchTerm } = state.productReducer;

    let category = chosenSubcategoryId || chosenCategoryId;
    if (searchParams.category === null) {
      category = null;
    }

    const emptySearchParamName = typeof searchParams.name === "undefined" || searchParams.name == "";

    searchParams = {
      ...searchParams,
      category: category,
      name: emptySearchParamName ? searchTerm : searchParams.name,
      count: searchParams.name === searchTerm ? count : 15,
      offset: searchParams.name === searchTerm ? offset : 0,
    };

    if (sortBy.length) {
      searchParams.sortBy = getSortByFields(sortBy);
    }

    if (searchParams.resetSorting) {
      searchParams.offset = 0;
    }

    try {
      const { logged } = state.sessionReducer;
      const { language } = state.rootReducer;
      let body,
        totalCount,
        response = null;

      if (searchParams.count === 0) {
        searchParams.count = 15;
      }

      if (logged) {
        response = await new ProductControllerApi().productSearchUsingPOST(searchParams, language);
      } else {
        response = await new PublicControllerApi().productSearchUsingPOST1(searchParams, language);
      }

      body = response.body || [];
      totalCount = response.totalCount;

      /* TODO */
      body.forEach((item: IProduct) => {
        item.wish = isProductInWish(item.uid, state);
        item.images = mapProductDetailsImage(item);
      });

      return dispatch(
        productListLoadingSuccessAction(
          body,
          false,
          searchParams.name,
          totalCount,
          searchParams.resetSearch,
          searchParams.resetSorting,
          autocomplete
        )
      );
    } catch (err) {
      dispatch(productListLoadingErrorAction(err.toString()));
    }
  };

export const searchClear = (autocomplete = false) => {
  return productListLoadingSuccessAction([], autocomplete);
};

export const updateSearchResultCount =
  (searchParams: ISearchParams) => async (dispatch: any, getState: () => IApplicationStore) => {
    dispatch(productListLoadingAction());

    const state = getState();

    try {
      const { logged } = state.sessionReducer;
      const { language } = state.rootReducer;
      let totalCount,
        response = null;

      if (logged) {
        response = await new ProductControllerApi().productSearchUsingPOST(searchParams, language);
      } else {
        response = await new PublicControllerApi().productSearchUsingPOST1(searchParams, language);
      }

      totalCount = response.totalCount;

      dispatch({ type: SEARCH_UPDATE_RESULT_COUNT, totalCount });
    } catch (error) {}
  };

export const loadProductListType =
  (listType: ProductListType) => async (dispatch: any, getState: () => IApplicationStore) => {
    dispatch(productListTypeLoadingAction(listType));

    const state = getState();

    try {
      let items: IProduct[];

      const { logged } = state.sessionReducer;
      const { language } = state.rootReducer;

      if (logged) {
        const controller = new ProductControllerApi();
        items =
          (listType === "all"
            ? (await controller.productSearchUsingPOST({}, language)).body
            : listType === "disabled"
            ? console.log("disabled")
            : (await controller.productWidgetListUsingGET(listType, language)).body) || [];
      } else {
        const controller = new PublicControllerApi();
        items =
          (listType === "all"
            ? (await controller.productSearchUsingPOST1({}, language)).body
            : listType === "disabled"
            ? console.log("disabled")
            : (await controller.productWidgetListUsingGET1(listType, language)).body) || [];
      }

      items.forEach((item) => {
        item.wish = isProductInWish(item.uid, state);
        item.images = mapProductDetailsImage(item);
      });
      dispatch(productListTypeLoadingSuccessAction(items, listType));
    } catch (error) {
      dispatch(productListTypeLoadingErrorAction(error.toString(), listType));
    }
  };

export const loadProductListCategory =
  (listType: ProductListType) => async (dispatch: any, getState: () => IApplicationStore) => {
    dispatch(productListTypeLoadingAction(listType));

    const state = getState();

    try {
      const { logged } = state.sessionReducer;
      const { language } = state.rootReducer;

      let items: IProduct[];

      if (logged) {
        items =
          (
            await new ProductControllerApi().productSearchUsingPOST(
              {
                category: listType,
                count: 7,
                offset: 0,
              },
              language
            )
          ).body || [];
      } else {
        items =
          (
            await new PublicControllerApi().productSearchUsingPOST1(
              {
                category: listType,
                count: 7,
                offset: 0,
              },
              language
            )
          ).body || [];
      }

      items.forEach((item) => {
        item.wish = isProductInWish(item.uid, state);
        item.images = mapProductDetailsImage(item);
      });
      dispatch(productListTypeLoadingSuccessAction(items, listType));
    } catch (error) {
      dispatch(productListTypeLoadingErrorAction(error.toString(), listType));
    }
  };

export const loadProductDetail = (uid?: string) => async (dispatch: any, getState: () => IApplicationStore) => {
  dispatch(productDetailsLoadingAction());

  const state = getState();

  try {
    const data = await loadProductDetails(uid, state);
    dispatch(productDetailsLoadingSuccessAction(data));
  } catch (error) {
    dispatch(productDetailsLoadingErrorAction(error.toString()));
  }
};

export const loadProductWishList = () => async (dispatch: any, getState: () => IApplicationStore) => {
  const state = getState();
  const { productsWishList } = state.productReducer;

  dispatch(productWishListLoadingAction());

  const { logged } = state.sessionReducer;
  const { language } = state.rootReducer;

  if (logged) {
    try {
      const items: IProduct[] = (await new WishListControllerApi().wishListUsingGET(language)).body || [];
      items.forEach((item) => {
        item.wish = true;
        item.images = mapProductDetailsImage(item);
      });
      dispatch(productWishListLoadingSuccessAction(items));
    } catch (error) {
      dispatch(productWishListLoadingErrorAction(error.toString()));
    }
  } else {
    dispatch(productWishListLoadingSuccessAction(productsWishList));
  }
};

export const addToWishList = (uid?: string) => async (dispatch: any, getState: () => IApplicationStore) => {
  /* get before call productAddedToWish! */
  const state = getState();
  const { productsWishList } = state.productReducer;
  const productAlreadyAdded = productsWishList.filter((item) => item.uid === uid).length;

  dispatch(productAddedToWish(uid));

  if (state.sessionReducer.logged) {
    try {
      const controller = new WishListControllerApi();
      const items: IProduct[] =
        (productAlreadyAdded
          ? (await controller.removeFromWishListUsingDELETE(uid)).body || []
          : (await controller.addToWishListUsingPUT(uid)).body) || [];
      items.forEach((item) => {
        item.wish = true;
        item.images = mapProductDetailsImage(item);
      });
      dispatch(productWishListLoadingSuccessAction(items));
    } catch (err) {}
  } else {
    let items;
    if (productAlreadyAdded) {
      items = productsWishList.filter((item) => item.uid !== uid);
    } else {
      const item = await loadProductDetails(uid, state);
      item.wish = true;
      item.images = mapProductDetailsImage(item);
      items = productsWishList;
      items.push(item);
    }

    dispatch(productWishListLoadingSuccessAction(items));
  }
};

export const clearSearchResult = () => {
  return { type: CLEAR_SEARCH_RESULT };
};

const productListLoadingAction = (autocomplete?: boolean) => ({
  type: PRODUCT_LIST_LOADING,
  autocomplete,
});

const productListLoadingSuccessAction = (
  products: IProduct[],
  is_clear?: boolean,
  searchTerm?: string,
  totalCount?: number,
  is_reset?: boolean,
  isResetSorting?: boolean,
  autocomplete?: boolean
) => ({
  type: PRODUCT_LIST_LOADING_SUCCESS,
  products,
  is_clear,
  searchTerm,
  totalCount,
  is_reset,
  isResetSorting,
  autocomplete,
});

const productListLoadingErrorAction = (error: any) => ({
  type: PRODUCT_LIST_LOADING_ERROR,
  error,
});

const productListTypeLoadingAction = (listType: ProductListType) => ({
  type: PRODUCT_LIST_TYPE_LOADING,
  listType,
});

const productListTypeLoadingSuccessAction = (products: any[], listType: ProductListType) => ({
  type: PRODUCT_LIST_TYPE_LOADING_SUCCESS,
  products,
  listType,
});

const productListTypeLoadingErrorAction = (error: any, listType: ProductListType) => ({
  type: PRODUCT_LIST_TYPE_LOADING_ERROR,
  error,
  listType,
});

const productDetailsLoadingAction = () => ({
  type: PRODUCT_DETAILS_LOADING,
});

const productDetailsLoadingSuccessAction = (product: any) => ({
  type: PRODUCT_DETAILS_LOADING_SUCCESS,
  product,
});

const productDetailsLoadingErrorAction = (error: any) => ({
  type: PRODUCT_DETAILS_LOADING_ERROR,
  error,
});

const productWishListLoadingAction = () => ({
  type: PRODUCT_WISH_LIST_LOADING,
});

const productWishListLoadingSuccessAction = (products: IProduct[]) => ({
  type: PRODUCT_WISH_LIST_LOADING_SUCCESS,
  products,
});

const productWishListLoadingErrorAction = (error: any) => ({
  type: PRODUCT_WISH_LIST_LOADING_ERROR,
  error,
});

const productAddedToWish = (uid: string) => ({
  type: PRODUCT_ADDED_TO_WISH,
  uid,
});
