import React from "react";
import { BlockTitle, Block, Popup, PageContent } from "framework7-react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ThemedButton";

const CongratulationsPage = (props: Popup.Props) => {
  const { t } = useTranslation();
  return (
    <Popup id="congratulations_page" {...props}>
      <PageContent className="display-flex justify-content-center align-items-center">
        <BlockTitle medium className="success-text">
          {t("Your order is completed")}
        </BlockTitle>
        <Block className="text-align-center">
          <p>{t('Information about the order will be available in the section "My orders"')}</p>
        </Block>
        <Button fill large raised round popupClose>
          {t("Continue")}
        </Button>
      </PageContent>
    </Popup>
  );
};

export default CongratulationsPage;
