import React, { Component } from "react";
import { Page, Navbar, Block, BlockTitle, Link, NavLeft, NavRight } from "framework7-react";

import "./categories.less";
import { compose } from "redux";
import { logoutUser } from "../actions/sessionActions";
import { LanguageLink } from "../components/LanguageLink";
import LanguagePopover from "../components/LanguagePopover/LanguagePopover";
import LoginDesktopPopup from "../components/LoginDesktop/LoginDesktopPopup";
import { RegisterDesktopPopup } from "../components/RegisterDesktop";
import { IApplicationStore, ResizeEvent } from "../store/rootReducer";
import { IProduct, IProductState } from "../reducers/productReducer";
import "./wish-list.less";
import { connect } from "react-redux";
import { Catalog } from "../components/Catalog/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { loadProductWishList, addToWishList, searchClear } from "../actions/productActions";
import Slider, { SliderType, SliderItem } from "../components/Slider";
import { Logo } from "../components/Logo";
import MyWishButton from "../components/MyWishButton";
import { OpenCategoriesButton } from "../components/open-categories-button";
import { SearchBar } from "../components/search-bar";
import ToMessengerButton from "../components/to-messenger-button/ToMessengerButton";
import { chooseCategory } from "../actions/filterActions";
import { goToMessenger } from "../actions/profileActions";
import { ProfileLink } from "../components/ProfileLink";
import { getProfile } from "../selectors/profile";
import { Profile } from "../reducers/sessionReducer";
import { CategoriesMenuDesktop } from "../components/categories-menu-desktop";
import { ProfilePopover } from "../components/profile-popover";
import { ShortRegistrationPopup } from "../components/ShortRegistrationPopup";
import { analytics } from "../Setup";
import InviteFriendPopUp from "../components/InviteFriendPopUp";
import ViewProfilePopup from "../components/ViewProfilePopup";

type Props = WithTranslation & {
  productsWishListLoading?: boolean;
  productsWishList?: IProduct[];
  productsWishListLoadingError?: any;
  loadProductWishList?(): void;
  productState?: IProductState;
  addToWish?(uid?: string): void;
  resizeEvent?: ResizeEvent;
  goToMessenger?(): void;
  chooseCategory?(catid?: string | null): void;
  clearSearch?(): void;
  profile?: Profile;
  language?: string;
  logout?(): void;
};

class WishListPage extends Component<
  Props,
  {
    searchBarEnable?: boolean;
    selectCategorySheetOpened?: boolean;
    categoriesMenuOpened?: boolean;
    profilePopoverOpened?: boolean;
    viewProfilePopupOpened?: boolean;
    invitePopupOpened?: boolean;
    sortingMenuPopoverOpened?: boolean;
    languagePopoverOpened?: boolean;
    loginPopupOpened?: boolean;
    registerPopupOpened?: boolean;
    shortRegisterPopupOpened?: boolean;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      profilePopoverOpened: false,
      languagePopoverOpened: false,
      loginPopupOpened: false,
      invitePopupOpened: false,
      viewProfilePopupOpened: false,
      registerPopupOpened: false,
      shortRegisterPopupOpened: false,
    };
  }

  private messenger = process.env.MESSENGER === "true";
  private simplifiedRegistration = process.env.SIMPLIFIED_REGISTRATION === "true";

  pageInitHandle = () => {
    this.props.loadProductWishList();
  };

  componentDidUpdate(prevProps: Props) {
    const { productsWishListLoadingError } = this.props;
    if (productsWishListLoadingError && productsWishListLoadingError !== prevProps.productsWishListLoadingError) {
      this.$f7.dialog.alert(productsWishListLoadingError);
    }
  }

  sliderItemClickHandle = (item: SliderItem) => {
    this.$f7router.navigate(item.href);
  };

  getSlidesItems = (catid?: string): SliderItem[] => {
    const {
      productState: { productTypeGroups },
    } = this.props;
    if (productTypeGroups) {
      const popularType = productTypeGroups.filter((item) => item.type === catid)[0];
      if (popularType) {
        return popularType.products
          ? popularType.products.map((item) => {
              return {
                ...item,
                image: item.imageUrl1,
                priceDiscount: item.discountedPrice,
                href: `/product-details/${item.uid}/`,
                onClick: this.sliderItemClickHandle,
              };
            })
          : [];
      }
    }

    return [];
  };

  renderProfileLink = () => {
    const { profile, resizeEvent } = this.props;
    const props = { profile };
    if (resizeEvent && resizeEvent.width > 567 && profile && profile.uid) {
      props["href"] = "#";
      props["onClick"] = this.handleClickProfileLink;
    } else if (resizeEvent && resizeEvent.width > 567) {
      props["href"] = "#";
      props["onClick"] = this.handleClickLoginLink;
    }
    return <ProfileLink key="profile_link" {...props} />;
  };

  handleClickProfileLink = () => {
    this.setState({ profilePopoverOpened: true });
  };

  handleClickLoginLink = () => {
    this.setState({ loginPopupOpened: true });
  };

  renderLanguageLink = () => {
    const { resizeEvent, language } = this.props;
    const props = { language };
    if (resizeEvent && resizeEvent.width > 567 && language) {
      props["href"] = "#";
      props["className"] = "pure-hidden-xs";
      props["onClick"] = this.handleClickLanguageLink;
      return <LanguageLink {...props} t={this.props.t} />;
    }
    return null;
  };

  handleClickLanguageLink = () => {
    this.setState({ languagePopoverOpened: true });
  };

  handleRegisterButton = () => {
    if (this.simplifiedRegistration) {
      this.setState({
        shortRegisterPopupOpened: true,
      });
    } else {
      this.setState({
        registerPopupOpened: true,
      });
    }
    analytics.openRegistrationForm();
  };

  render() {
    const { productsWishList, t, resizeEvent, profile } = this.props;

    return (
      <Page id="wish_list" name="wish-list" onPageInit={this.pageInitHandle}>
        {resizeEvent.width < 568 && (
          <Navbar title={t("Your wish list")} backLink={t("Back").toString()} noHairline noShadow />
        )}

        {resizeEvent.width > 567 && (
          <Navbar sliding noShadow noHairline>
            <NavLeft
              style={{
                flex: "auto" /* for correct search bar sliding in iOS */,
              }}
            >
              {this.messenger && (
                <ToMessengerButton
                  text={t("To Messenger")}
                  className="pure-hidden-xs"
                  onClick={this.props.goToMessenger}
                />
              )}
              <Link
                className="pure-hidden-xs"
                style={{ flex: "0 0 auto" }}
                onClick={() => {
                  this.$f7.searchbar.disable(".search-bar");
                  this.props.chooseCategory(null);
                  this.props.clearSearch();
                  this.$f7router.back();
                }}
              >
                <Logo full />
              </Link>
              <div
                style={{
                  display: "flex",
                  flex: "1 1 auto",
                  justifyContent: "center",
                }}
              >
                <OpenCategoriesButton
                  className="pure-hidden-xs"
                  text={t("Categories")}
                  onClick={() => {
                    this.setState({
                      categoriesMenuOpened: !this.state.categoriesMenuOpened,
                    });
                  }}
                  opened={this.state.categoriesMenuOpened}
                />
                <SearchBar
                  noShadow
                  noHairline
                  init
                  backdrop
                  customSearch
                  onSearchbarEnable={() => {}}
                  onSearchbarDisable={() => {}}
                  onChange={() => {}}
                  onClickClear={() => {}}
                />
              </div>
            </NavLeft>
            <NavRight>
              {this.renderLanguageLink()}
              <MyWishButton />
              {this.renderProfileLink()}
            </NavRight>
          </Navbar>
        )}

        <div className="catalog-block-title block-title block-title-medium pure-hidden-xs">{t("Your wish list")}</div>

        <Catalog items={productsWishList} addToWish={this.props.addToWish} showFeaturesHiglight />
        <BlockTitle medium>{t("Similar products")}</BlockTitle>
        <Slider slides={this.getSlidesItems("011")} type={SliderType.small} />
        <Block></Block>

        <div slot="fixed">
          <CategoriesMenuDesktop className="pure-hidden-xs" opened={this.state.categoriesMenuOpened} />
        </div>

        <LanguagePopover
          backdrop={false}
          opened={this.state.languagePopoverOpened}
          target=".language-link"
          onPopoverClosed={() => this.setState({ languagePopoverOpened: false })}
          t={t}
          slot="fixed"
        />

        <ProfilePopover
          profile={this.props.profile}
          backdrop={false}
          opened={this.state.profilePopoverOpened}
          target=".profile-link"
          onPopoverClosed={() => this.setState({ profilePopoverOpened: false })}
          onInviteClick={() => this.setState({ invitePopupOpened: true, profilePopoverOpened: false })}
          onViewClick={() => this.setState({ viewProfilePopupOpened: true, profilePopoverOpened: false })}
          t={t}
          slot="fixed"
          onLogout={() => {
            this.$f7.preloader.show();
            this.props.logout();
          }}
        />

        <LoginDesktopPopup
          opened={this.state.loginPopupOpened}
          onPopupClosed={() => this.setState({ loginPopupOpened: false })}
          slot="fixed"
          onRegister={() => {
            this.setState({ loginPopupOpened: false });
            this.handleRegisterButton();
          }}
        />

        <RegisterDesktopPopup
          opened={this.state.registerPopupOpened}
          onPopupClosed={() => this.setState({ registerPopupOpened: false })}
          t={t}
          slot="fixed"
        />

        <ShortRegistrationPopup
          opened={this.state.shortRegisterPopupOpened}
          onPopupClosed={() => this.setState({ shortRegisterPopupOpened: false })}
          t={t}
          slot="fixed"
        />

        <InviteFriendPopUp
          backdrop={false}
          opened={this.state.invitePopupOpened}
          onPopupClosed={() => this.setState({ invitePopupOpened: false })}
        />

        <ViewProfilePopup
          profile={profile}
          opened={this.state.viewProfilePopupOpened}
          f7={this.$f7}
          onPopupClosed={() => this.setState({ viewProfilePopupOpened: false })}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  productsWishListLoading: state.productReducer.productsWishListLoading,
  productsWishList: state.productReducer.productsWishList,
  productsWishListLoadingError: state.productReducer.productsWishListLoadingError,

  productState: state.productReducer,
  resizeEvent: state.rootReducer.resizeEvent,
  profile: getProfile(state),
  language: state.rootReducer.language,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadProductWishList: () => dispatch(loadProductWishList()),
  addToWish: (uid?: string) => dispatch(addToWishList(uid)),
  goToMessenger: () => dispatch(goToMessenger()),
  chooseCategory: (catid?: string) => dispatch(chooseCategory(catid)),
  clearSearch: () => dispatch(searchClear()),
  logout: () => dispatch(logoutUser()),
});

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(WishListPage);
