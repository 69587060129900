import { createStore, applyMiddleware, Store } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import reduxThunk from "redux-thunk";
import logger from "redux-logger";
import { client } from "../axios";
import rootReducer, { IApplicationStore } from "./rootReducer";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import SetTransform from "./transforms";
import { CHOOSE_CATEGORY_SUB_CATEGORY } from "../actions/filterActions";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxPromise from "redux-promise";

const persistConfig: PersistConfig<IApplicationStore> & {
  whitelist: (keyof IApplicationStore)[];
} = {
  key: "root",
  storage,
  transforms: [SetTransform],
  whitelist: ["sessionReducer", "classificatorReducer", "cartReducer"],
};

const persistedReducer = persistReducer<IApplicationStore>(
  persistConfig,
  rootReducer
);

const options = {
  returnRejectedPromiseOnError: true,
};

const isDebuggingInChrome = !!(window as any).navigator.userAgent;
const isLocal = process.env.NODE_ENV === "development";

const modifyUrl = (store: Store) => (next: any) => (action: any) => {
  const state = store.getState() as IApplicationStore;
  const { f7 } = state.rootReducer;

  if (action.type === CHOOSE_CATEGORY_SUB_CATEGORY) {
    f7.view.current.router.navigate(
      "/category/" + action.catid + "/subcategory/" + action.subcatid + "/",
      { reloadAll: true }
    );
  }

  return next(action);
};

const configureStore = () => {
  const middlewares: any[] = [reduxThunk, axiosMiddleware(client, options)];

  if (isDebuggingInChrome && isLocal) {
    middlewares.push(logger);
  }

  /* TODO */
  middlewares.push(modifyUrl);
  // let store = createStore(rootReducer, composeWithDevTools(
  //   applyMiddleware(...middlewares)));

  let store = createStore(persistedReducer, applyMiddleware(...middlewares));
  let persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
