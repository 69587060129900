import React from "react";

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.46547 0H13.5345C14.8961 0 16 1.10383 16 2.46548V13.5345C16 14.8961 14.8961 16 13.5345 16H2.46547C1.10383 16 0 14.8961 0 13.5345V2.46548C0 1.10383 1.10383 0 2.46547 0Z"
      fill="#41B1DB"
    />
    <path
      d="M12.9988 5.54797C13.0122 6.01211 12.9099 6.44339 12.7365 6.85824C12.4341 7.58114 11.9938 8.23011 11.509 8.85444C10.8375 9.71699 10.1349 10.559 9.24546 11.2491C8.80964 11.5859 8.34269 11.8693 7.76901 11.9761C7.37321 12.05 7.03523 11.9514 6.75506 11.6968C6.35482 11.3312 6.12357 10.8753 5.96792 10.3824C5.6833 9.46644 5.42092 8.54227 5.1452 7.62632C5.05626 7.32237 4.94508 7.02664 4.78498 6.74734C4.74941 6.68983 4.71383 6.62822 4.67381 6.57482C4.43811 6.25034 4.27801 6.21748 3.91779 6.42285C3.78883 6.49678 3.66431 6.57482 3.53979 6.65286C3.47308 6.69805 3.42861 6.69805 3.37524 6.63233C3.26851 6.49678 3.15289 6.36124 3.03726 6.2298C2.99279 6.1764 2.97945 6.13533 3.04616 6.08604C3.75325 5.53565 4.39364 4.90721 5.15854 4.42254C5.43427 4.24592 5.72778 4.09805 6.08355 4.11038C6.44376 4.1227 6.72838 4.25824 6.9374 4.52522C7.17754 4.82917 7.27982 5.18241 7.34208 5.54797C7.49329 6.38588 7.61336 7.2279 7.84461 8.05349C7.92021 8.32869 8.00915 8.59978 8.16036 8.85033C8.34714 9.15428 8.54726 9.19535 8.80519 8.94069C9.36108 8.39441 9.77911 7.76187 10.0459 7.05128C10.1038 6.8952 10.1126 6.7268 10.1038 6.5625C10.086 6.24623 9.89029 6.05729 9.54786 6.03265C9.31216 6.01622 9.08091 6.04907 8.85411 6.1189C8.75183 6.15176 8.70291 6.13533 8.74293 6.02032C8.96084 5.42475 9.26769 4.87846 9.81914 4.48415C10.464 4.02001 11.2066 3.90501 11.9893 4.07341C12.5897 4.20074 12.8432 4.63202 12.9455 5.15366C12.9722 5.29331 12.981 5.42886 12.9988 5.54797Z"
      fill="white"
    />
  </svg>
);
