import AllDealsPage from "./pages/all-deals";
import HomePage from "./pages/home";
import AllFiltresPage from "./pages/all-filtres-popup";
import CategoriesPage from "./pages/categories";
import SubcategoriesPage from "./pages/subcategories";
import LocationPage from "./pages/location";
import ProductDetailsPage from "./pages/product-details";
import ProfilePage from "./pages/profile";
import WishListPage from "./pages/wish-list";
import SeeAllPage from "./pages/see-all";
import MyGoodsPage from "./pages/my-goods";
import { ProductCreatePage, SelectCategorySubcategorySheetPage } from "./pages/product-create";
import ProductEditPage from "./pages/product-edit";
import MyGoodsProductDetailsPage from "./pages/my-goods-product-details";
import SelectLocationSheet from "./pages/select-location-sheet";
import WalletPage from "./pages/wallet";
import CardsPage from "./pages/cards";
import NotFoundPage from "./pages/404";
import Framework7 from "framework7";
import { Router } from "framework7/modules/router/router";
import { routes as currencyRoutes } from "./pages/currencies";
import { routes as transactionsRoutes } from "./pages/transactions";
import PromoteProductPage from "./pages/product-promotion/promote-product";
import { routes as cartRoutes } from "./pages/cart";
import { routes as ordersRoutes } from "./pages/orders";
import ThirdPartyOrder from "./components/ThirdParty/ThirdPartyOrder";

export interface RouteParameters extends Router.RouteParameters {
  app?: Framework7;
}

const routes: RouteParameters[] = [
  {
    name: "HomePage",
    path: "/",
    component: HomePage,
    options: {
      clearPreviousHistory: true,
    },
  },
  {
    name: "Third-party",
    path: "/external-product/",
    component: ThirdPartyOrder,
    options: {
      clearPreviousHistory: true,
    },
  },
  {
    name: "login",
    path: "/login/",
    component: HomePage,
  },
  {
    name: "register",
    path: "/register/",
    component: HomePage,
  },
  {
    name: "HomePage_Category",
    path: "/category/:catid/subcategory/:subcatid/",
    component: HomePage,
  },
  {
    path: "/all-filtres/",
    component: AllFiltresPage,
  },
  {
    path: "/all-filtres/categories/",
    component: CategoriesPage,
  },
  {
    path: "/all-filtres/categories/subcategories/:catid/",
    component: SubcategoriesPage,
  },
  {
    path: "/all-filtres/categories/subcategories/:catid/(.*)/",
    component: SubcategoriesPage,
  },
  {
    path: "/location/",
    component: LocationPage,
  },
  {
    path: "/product-details/:uid/",
    component: ProductDetailsPage,
    options: {
      reloadAll: true,
      clearPreviousHistory: true,
    },
  },
  {
    path: "/profile/",
    component: ProfilePage,
    routes: [
      {
        path: "/my-goods/",
        component: MyGoodsPage,
        routes: [
          {
            path: "/add/:step/",
            component: ProductCreatePage,
          },
          {
            path: "/edit/:uid/",
            component: ProductCreatePage,
          },
          {
            path: "/edit/:uid/:step/",
            component: ProductCreatePage,
          },
          {
            path: "/product-details/:uid/",
            component: MyGoodsProductDetailsPage,
          },
          {
            path: "/product-details/:uid/promote/",
            component: PromoteProductPage,
          },
        ],
      },
      {
        path: "/wallet/",
        component: WalletPage,
        routes: [
          {
            path: "/cards/",
            component: CardsPage,
          },
          ...currencyRoutes,
        ],
      },
      ...transactionsRoutes,
      ...ordersRoutes,
    ],
  },
  {
    path: "/select-category-subcategory/",
    sheet: {
      component: SelectCategorySubcategorySheetPage,
    },
  },
  {
    path: "/wish-list/",
    component: WishListPage,
    options: {
      clearPreviousHistory: true,
    },
  },
  {
    path: "/see-all/:catid/",
    component: SeeAllPage,
  },
  {
    path: "/all-deals/",
    component: AllDealsPage,
  },
  {
    path: "/product-edit/",
    component: ProductEditPage,
  },
  {
    path: "/select-location-sheet/",
    component: SelectLocationSheet,
  },
  ...cartRoutes,
  {
    path: "(.*)",
    async: (routeTo, _routeFrom, resolve) => {
      if (routeTo.url) {
        let redirectUrl = null;
        ["http://", "https://", "viber://"].forEach((value) => {
          const index = routeTo.url.indexOf(value);
          if (index !== -1) {
            redirectUrl = routeTo.url.substring(index);
          }
        });
        if (redirectUrl) {
          window.open(redirectUrl);
          resolve({ url: "/" }, { clearPreviousHistory: true, force: true, reloadAll: true });
        } else {
          resolve({ component: NotFoundPage });
        }
      } else resolve({ component: NotFoundPage });
    },
  },
];

export default routes;
