import { SortBy } from "../reducers/filterReducer";

export const CHOOSE_CATEGORY = "CHOOSE_CATEGORY";
export const CHOOSE_SUB_CATEGORY = "CHOOSE_SUB_CATEGORY";
export const CHOOSE_CATEGORY_SUB_CATEGORY = "CHOOSE_CATEGORY_SUB_CATEGORY";
export const CHOOSE_SORT_BY = "CHOOSE_SORT_BY";
export const CLEAR_SORT_BY = "CLEAR_SORT_BY";

export const ALL_FILTRES_CHOOSE_CATEGORY = "ALL_FILTRES_CHOOSE_CATEGORY";
export const ALL_FILTRES_CHOOSE_SUB_CATEGORY =
  "ALL_FILTRES_CHOOSE_SUB_CATEGORY";
export const ALL_FILTRES_CHOOSE_SORT_BY = "ALL_FILTRES_CHOOSE_SORT_BY";
export const ALL_FILTRES_FILL = "ALL_FILTRES_FILL";
export const ALL_FILTRES_CLEAR = "ALL_FILTRES_CLEAR";
export const ALL_FILTRES_CLEAR_CATEGORY = "ALL_FILTRES_CLEAR_CATEGORY";
export const ALL_FILTRES_CLEAR_SUB_CATEGORY = "ALL_FILTRES_CLEAR_SUB_CATEGORY";
export const ALL_FILTRES_APPLY = "ALL_FILTRES_APPLY";

export const chooseCategory = (catid: string) => {
  return chooseCategoryAction(catid);
};

export const chooseSubcategory = (catid: string) => {
  return chooseSubcategoryAction(catid);
};

export const chooseCategorySubcategory = (catid: string, subcatid: string) => {
  return chooseCategorySubcategoryAction(catid, subcatid);
};

export const chooseSortBy = (sortBy: SortBy, refresh?: boolean) => {
  return chooseSortByAction(sortBy, refresh);
};

export const clearSortBy = () => {
  return { type: CLEAR_SORT_BY };
};

export const allFiltresChooseCategory = (catid: string) => {
  return allFiltresChooseCategoryAction(catid);
};

export const allFiltresChooseSubcategory = (catid: string) => {
  return allFiltresChooseSubcategoryAction(catid);
};

export const allFiltresChooseSortBy = (sortBy: SortBy) => {
  return allFiltresChooseSortByAction(sortBy);
};

export const allFiltresFill = () => {
  return { type: ALL_FILTRES_FILL };
};

export const allFiltresClear = () => {
  return { type: ALL_FILTRES_CLEAR };
};

export const allFiltresClearCategory = () => {
  return { type: ALL_FILTRES_CLEAR_CATEGORY };
};

export const allFiltresClearSubcategory = () => {
  return { type: ALL_FILTRES_CLEAR_SUB_CATEGORY };
};

export const applyAllFiltres = () => {
  return { type: ALL_FILTRES_APPLY };
};

const chooseCategoryAction = (catid: string) => ({
  type: CHOOSE_CATEGORY,
  catid,
});

const chooseSubcategoryAction = (catid: string) => ({
  type: CHOOSE_SUB_CATEGORY,
  catid,
});

const chooseCategorySubcategoryAction = (catid: string, subcatid: string) => ({
  type: CHOOSE_CATEGORY_SUB_CATEGORY,
  catid,
  subcatid,
});

const chooseSortByAction = (sortBy: SortBy, refresh?: boolean) => ({
  type: CHOOSE_SORT_BY,
  sortBy,
  refresh,
});

const allFiltresChooseCategoryAction = (catid: string) => ({
  type: ALL_FILTRES_CHOOSE_CATEGORY,
  catid,
});

const allFiltresChooseSubcategoryAction = (catid: string) => ({
  type: ALL_FILTRES_CHOOSE_SUB_CATEGORY,
  catid,
});

const allFiltresChooseSortByAction = (sortBy: SortBy) => ({
  type: ALL_FILTRES_CHOOSE_SORT_BY,
  sortBy,
});
