import Framework7 from "framework7";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { IProduct } from "../../reducers/productReducer";
import { IApplicationStore } from "../../store/rootReducer";

class SEOContainer extends Component<{
  f7: Framework7;
  productDetails: IProduct;
}> {
  constructor(props: Readonly<{ f7: Framework7; productDetails: IProduct }>) {
    super(props);

    this.props.f7.on("routeChange", () => {
      this.forceUpdate();
    });
  }

  render() {
    const { f7 } = this.props;
    const { current } = f7.view;

    let metaData = {
      title: process.env.APP_NAME,
      description: "",
      image: "",
    };

    if (current) {
      // @ts-ignore
      const { url } = current.router;

      if (url && url.includes("product-details") && this.props.productDetails) {
        const { productDetails } = this.props;
        metaData = {
          title: productDetails.name,
          description: productDetails.description,
          image: productDetails.imageUrl1,
        };
      }
    }

    return (
      metaData && (
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.description} />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={metaData.title} />
          <meta itemprop="description" content={metaData.description} />
          <meta itemprop="image" content={metaData.image} />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://reedbarger.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaData.title} />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:image" content={metaData.image} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaData.title} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image} />
        </Helmet>
      )
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  productDetails: state.productReducer.productDetails,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SEOContainer);
