import { Address } from "../types/commonapi";
import { IApplicationStore } from "./rootReducer";
import { connect } from "react-redux";
import { addOrUpdateAddress, removeAddress } from "../actions/profileActions";

export interface AccountAddressState {
  accountAddOrUpdateAddressLoading?: boolean;
  accountAddOrUpdateAddressError?: string;
  accountRemoveAddressLoading?: boolean;
  accountRemoveAddressError?: string;
  addresses: Address[];
}

export interface IAccountAddressProps {
  accountAddressState: AccountAddressState;

  addOrUpdateAccountAddress?(address: Address): () => void;

  removeAccountAddress?(): (uid: string) => void;
}

const mapStateToProps = (state: IApplicationStore): IAccountAddressProps => ({
  accountAddressState: {
    ...state.profileReducer,
    addresses:
      state.sessionReducer.profile && state.sessionReducer.profile.addresses
        ? state.sessionReducer.profile.addresses
        : [],
  },
});

const mapDispatchToProps = (dispatch: any) => ({
  addOrUpdateAccountAddress: (address: Address) => dispatch(addOrUpdateAddress(address)),
  removeAccountAddress: (uid: string) => dispatch(removeAddress(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps);
