import React, { ReactElement, useEffect, useState } from "react";
import { Popup, F7Popup, Page, Navbar, NavRight, Link, Block, ListInput, List, Button, f7 } from "framework7-react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { loginWithUserPassword } from "../../actions/sessionActions";
import { IApplicationStore } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { removeOmnideskLoginWidget, usePrevious } from "../../utils";
import { Router } from "framework7/modules/router/router";
import "./style.less";

type LoginDesktopPopupProps = F7Popup.Props & {
  onForgotPassword(): void;
  onRegister(): void;
  f7router: Router.Router;
};

const LoginDesktopPopup = ({
  className,
  onForgotPassword,
  onRegister,
  f7router,
  ...rest
}: LoginDesktopPopupProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state: IApplicationStore) => state.sessionReducer);
  const prevLoading = usePrevious(loading);
  const prevError = usePrevious(error);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!loading && prevLoading) {
      f7.preloader.hide();
      f7.popup.close();
      removeOmnideskLoginWidget();
      if (rest.opened) {
        if (error && !prevError) f7.dialog.alert(error, "Marketplace");
      }
    }
  }, [loading, error, rest.opened]);

  const login = (): void => {
    f7.preloader.show();
    dispatch(loginWithUserPassword(username, password));
  };

  return (
    <Popup {...rest} className={classNames("login-popup", className)}>
      <Page>
        <Navbar noShadow noHairline title={t("Login")}>
          <NavRight>
            <Link popupClose>{t("Close")}</Link>
          </NavRight>
        </Navbar>
        <Block>
          <List noHairlines form>
            <ListInput
              name="username"
              label={t("E-mail or phone").toString()}
              floatingLabel
              type="text"
              placeholder=""
              clearButton
              slot="list"
              onInput={(e): void => setUsername(e.target.value)}
            />
            <ListInput
              name="password"
              label={t("Password").toString()}
              floatingLabel
              type="password"
              placeholder=""
              clearButton
              slot="list"
              onInput={(e): void => setPassword(e.target.value)}
            />
          </List>
          <Block className="forgot-password-block">
            <Link className="forgot-password-link" onClick={onForgotPassword}>
              {t("Forgot your Password?")}
            </Link>
          </Block>
          <Block className="login-form">
            <Button fill large round raised onClick={login} disabled={loading}>
              {t("Login")}
            </Button>

            <p className="sign-up-text">
              {t("Not on marketplace yet?", { app: process.env.APP_NAME })} &nbsp;
              <Link popupClose onClick={onRegister}>
                {t("Sign Up")}
              </Link>
            </p>
          </Block>
        </Block>
      </Page>
    </Popup>
  );
};

export default LoginDesktopPopup;
