import { AnyAction, combineReducers } from "redux";
import allDealsReducer, { IAllDealsState } from "../reducers/allDealsReducer";
import contactSupportReducer, { IContactSupportState } from "../reducers/contactSupportReducer";
import sessionReducer, { ISessionState } from "../reducers/sessionReducer";
import Framework7 from "framework7/components/app/app-class";
import filterReducer, { IFilterState } from "../reducers/filterReducer";
import categoryReducer, { ICategoryClassificatorState, ICategoryClassificator } from "../reducers/categoryReducer";
import currencyReducer, { ICurrencyClassificatorState } from "../reducers/currencyReducer";
import productReducer, { IProductState } from "../reducers/productReducer";
import profileReducer, { IProfileState } from "../reducers/profileReducer";
import allGoodsReducer, { IAllGoodsState } from "../reducers/allGoodsReducer";
import chatReducer, { IChatState } from "../reducers/chatReducer";
import myGoodsReducer, { IMyGoodsState } from "../reducers/myGoodsReducer";
import paymentCardsReducer, { IPaymentCardsState } from "../reducers/paymentCardsReducer";
import productCreateReducer, { IProductCreateState } from "../reducers/productCreateReducer";
import shareReducer, { IShareState } from "../reducers/shareReducer";
import { CATEGORY_LIST_LOADING_SUCCESS } from "../actions/categoryActions";
import productStatusReducer, { IProductStatusState } from "../reducers/productStatusReducer";
import classificatorReducer, { IClassificatorState } from "../reducers/classificatorReducer";
import bannersReducer, { IBannersState } from "../reducers/bannersReducer";
import { REHYDRATE } from "redux-persist";
import { language } from "../i18n";
import preloaderReducer, { IPreloaderState } from "../reducers/preloaderReducer";
import productPromotionReducer, { IProductPromotionState } from "../reducers/productPromotionReducer";
import myCurrenciesReducer, { IMyCurrenciesState } from "../reducers/myCurrenciesReducer";
import ordersReducer, { IOrdersState } from "../reducers/ordersReducer";
import transactionReducer, { ITransactionsState } from "../reducers/transactionReducer";
import cartReducer, { ICartState } from "../reducers/cartReducer";
import checkoutReducer, { ICheckoutState } from "../reducers/checkoutReducer";
import { updateAxiosClientCredential } from "../axios";

export const F7_INIT = "F7_INIT";
export const INIT_ENTRY_PAGE_NAME = "INIT_ENTRY_PAGE_NAME";
export const LOCAL_CONFIG_LOADED = "LOCAL_CONFIG_LOADED";
export const LOCAL_CONFIG_UPDATED = "LOCAL_CONFIG_UPDATED";
export const ON_RESIZE_EVENT = "ON_RESIZE_EVENT";

export interface IApplicationStore {
  rootReducer: IAppState;
  sessionReducer: ISessionState;
  filterReducer: IFilterState;
  categoryReducer: ICategoryClassificatorState;
  currencyReducer: ICurrencyClassificatorState;
  productReducer: IProductState;
  profileReducer: IProfileState;
  allGoodsReducer: IAllGoodsState;
  chatReducer: IChatState;
  myGoodsReducer: IMyGoodsState;
  paymentCardsReducer: IPaymentCardsState;
  productCreateReducer: IProductCreateState;
  shareReducer: IShareState;
  productStatusReducer: IProductStatusState;
  classificatorReducer: IClassificatorState;
  bannersReducer: IBannersState;
  preloaderReducer: IPreloaderState;
  productPromotionReducer: IProductPromotionState;
  myCurrenciesReducer: IMyCurrenciesState;
  ordersReducer: IOrdersState;
  transactionReducer: ITransactionsState;
  cartReducer: ICartState;
  checkoutReducer: ICheckoutState;
  contactSupportReducer: IContactSupportState;
  allDealsReducer: IAllDealsState;
}

export interface ICategory {
  id?: string;
  name?: string;
  color?: string;
  icon?: string;
  productCount?: number;
}

export interface ILanguage {
  code?: string;
  title?: string;
}

export interface ILocalConfig {
  GoogleMapAPIkey?: string;
  categories: ICategory[];
  settingsEnabled?: boolean;
  theme?: "light" | "dark";
  sliderCategories?: string[];
  defaultLanguage?: string;
  languages: ILanguage[];
  appCode?: string;
  profileMenuItems?: string[];
  homeSliderType?: string;
  showLocationFilter?: boolean;
  buyerCurrencyCode?: string;
}

export interface ResizeEvent {
  width: number;
  height: number;
  isLandscape: boolean;
  ratio: number;
  isXS: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
}

export interface IAppState {
  f7?: Framework7;
  localConfigLoaded?: boolean;
  localConfig?: ILocalConfig;
  language?: string;
  isLanguageChanged?: boolean;
  entryPageName?: string;
  resizeEvent: ResizeEvent;
  delayLoading?: boolean;
}

const initialState: IAppState = {
  f7: null,
  localConfigLoaded: false,
  localConfig: {
    categories: [],
    languages: [],
    theme: "light",
    showLocationFilter: true,
  },
  language: language,
  isLanguageChanged: false,
  resizeEvent: {
    width: window.innerWidth,
    height: window.innerHeight,
    ratio: window.devicePixelRatio,
    isLG: false,
    isLandscape: false,
    isMD: false,
    isXL: false,
    isXS: false,
  },
  delayLoading: false,
};

const rootReducer = (state = initialState, action: AnyAction): IAppState => {
  switch (action.type) {
    case REHYDRATE:
      let isLanguageChanged = state.language !== language;
      const payload: IApplicationStore = action.payload;

      if (
        payload &&
        payload.sessionReducer &&
        payload.sessionReducer.accessToken &&
        payload.sessionReducer.profile &&
        payload.sessionReducer.profile.uid
      ) {
        updateAxiosClientCredential(payload.sessionReducer.accessToken);
      }

      if (payload && payload.sessionReducer && payload.sessionReducer.refreshToken) {
        state.delayLoading = true;
      }

      return {
        ...state,
        language: isLanguageChanged ? language : state.language,
        isLanguageChanged,
      };
    case F7_INIT:
      return {
        ...state,
        f7: action.f7,
      };
    case LOCAL_CONFIG_LOADED:
      return {
        ...state,
        localConfigLoaded: true,
        localConfig: { ...state.localConfig, ...action.localConfig },
      };
    case CATEGORY_LIST_LOADING_SUCCESS: {
      const { categories }: { categories: ICategoryClassificator[] } = action as any;
      const { localConfig } = state;

      if (localConfig && categories) {
        localConfig.categories = localConfig.categories.map((item) => {
          const categoryClassificatorItem = categories.filter((c) => c.code === item.id)[0];
          if (categoryClassificatorItem) {
            return { ...item, name: categoryClassificatorItem.value };
          }
          console.warn("Not found for " + item.name + ", " + item.id);
          return item;
        });
      }

      return {
        ...state,
        localConfig: { ...localConfig },
      };
    }
    case INIT_ENTRY_PAGE_NAME:
      return {
        ...state,
        entryPageName: action.pageName,
      };
    case ON_RESIZE_EVENT: {
      const { width, height } = action.payload;
      return {
        ...state,
        resizeEvent: {
          ...action.payload,
          isLandscape: width > height,
          ratio: width / height,
          isXL: width > 1280 || height > 1280,
          isLG: (width > 1024 && width < 1279) || (height > 1024 && height < 1279),
          isMD: (width > 768 && width < 1023) || (height > 768 && height < 1023),
          isXS: width <= 767 || height <= 767,
        },
      };
    }
  }
  return state;
};

// TODO
// https://redux.js.org/recipes/usage-with-typescript#type-checking-reducers
export default combineReducers<IApplicationStore>({
  rootReducer,
  sessionReducer,
  filterReducer,
  categoryReducer,
  currencyReducer,
  productReducer,
  profileReducer,
  allGoodsReducer,
  chatReducer,
  myGoodsReducer,
  paymentCardsReducer,
  productCreateReducer,
  shareReducer,
  productStatusReducer,
  classificatorReducer,
  bannersReducer,
  preloaderReducer,
  productPromotionReducer,
  myCurrenciesReducer,
  ordersReducer,
  transactionReducer,
  cartReducer,
  checkoutReducer,
  contactSupportReducer,
  allDealsReducer,
});
