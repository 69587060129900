import { connect } from "react-redux";
import { IApplicationStore } from "./rootReducer";
import { loadAllGoods } from "../actions/allGoodsActions";
import { ISearchParams } from "../actions/productActions";

const mapStateToProps = (state: IApplicationStore) => ({
  allGoodsLoading: state.allGoodsReducer.loading,
  allGoodsProducts: state.allGoodsReducer.products || [],
  allGoodsCount: state.allGoodsReducer.count,
  allGoodsOffset: state.allGoodsReducer.offset
})

const mapDispatchToProps = (dispatch: any) => ({
  loadAllGoods: (searchParams: ISearchParams) => dispatch(loadAllGoods(searchParams))
})

export default connect(mapStateToProps, mapDispatchToProps)