import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { IApplicationStore } from '../store/rootReducer';
import { connect } from 'react-redux';
import { compose } from 'redux';

export class MapContainer extends Component<any> {
  render() {
    const { center } = this.props
    return (
      <Map
        centerAroundCurrentLocation
        google={this.props.google}
        zoom={14}
        disableDefaultUI
        center={center}
        initialCenter={center}
        {...this.props}
      >
        {this.props.center && <Marker position={center} />}
      </Map>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  apiKey: state.rootReducer.localConfig ? state.rootReducer.localConfig.GoogleMapAPIkey : '',
})

export default compose<any>(
  connect(mapStateToProps),
  GoogleApiWrapper(({ apiKey }) => ({
    apiKey: apiKey,
  }))
)(MapContainer)