import React, { Component } from "react";
import { BlockTitle, List, ListInput } from "framework7-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import { ProductParam } from "../../types/marketplaceapi";

type Props = {
  productParams: ProductParam[];
  onChangeForm?(productParams: ProductParam[], formValid?: boolean): void;
};

type State = {
  formErrors?: any;
  formValidFields?: any;
  formValid: boolean;
};

class ProductParamsForm extends Component<Props & WithTranslation, State> {
  constructor(props: Readonly<Props & WithTranslation>) {
    super(props);
    this.state = {
      formValidFields: {},
      formErrors: {},
      formValid: false,
    };
  }

  getInputName(code: string) {
    return `input_${code}`;
  }

  handleBlurInput = (e: any) => this.handleUserInput(e);

  handleUserInput = (e: any) => {
    let { name, value, rawValue = null } = e.target;
    value = rawValue !== null && name !== "expireDate" ? rawValue : value;
    // @ts-ignore
    this.setState({ [name]: value }, () => this.validateField(name, value));
  };

  handleInputClear = (e: any) => {
    let { name } = e.target;
    // @ts-ignore
    this.setState({ [name]: "" }, () => this.validateField(name, ""));
  };

  validateField = (fieldName: keyof State, value: string) => {
    const { t, productParams } = this.props;
    const param = productParams.filter((p) => p.code === fieldName.replace("input_", ""))[0];
    let formValidFields = this.state.formValidFields;
    let fieldValidationErrors = this.state.formErrors;
    let errorText = "";
    let requiredFieldErrorText = t("Please fill out this field.");

    if (param) {
      errorText = param.mandatory && !value.length ? requiredFieldErrorText : errorText;

      if (param.validationRegExp && value.length) {
        const regexp = new RegExp(param.validationRegExp);
        errorText = regexp.test(value) ? errorText : t("Please fill with correct format.");
      }

      fieldValidationErrors[fieldName] = errorText;
      formValidFields[fieldName] = !errorText.length;

      if (formValidFields[fieldName]) {
        // @ts-ignore
        param.value = value;
      }
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        formValidFields,
      },
      this.validateForm
    );
  };

  validateForm = () => {
    const { productParams, onChangeForm } = this.props;
    const formValid = !this.formHasErrors(this.state.formValidFields);
    this.setState({
      formValid,
    });

    if (onChangeForm) {
      onChangeForm(productParams, formValid);
    }
  };

  formHasErrors = (formValidFields: any) => {
    return Object.keys(formValidFields).some((key) => !formValidFields[key]);
  };

  getErrorMessagesProps = (fieldName: string) => {
    const { t } = this.props;
    return {
      errorMessage: t(this.state.formErrors[fieldName]),
      errorMessageForce: !!this.state.formErrors[fieldName],
    };
  };

  render() {
    const { productParams, t } = this.props;
    return (
      <div className="product-params-form">
        <BlockTitle className="item-title">{t("Additional parameters")}</BlockTitle>
        <List noHairlinesMd form noHairlinesBetweenMd>
          {productParams.map((param, i) => {
            let name = this.getInputName(param.code);
            return (
              <ListInput
                key={name}
                name={name}
                label={t(param.name).toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                required={param.mandatory}
                onBlur={this.handleBlurInput}
                onChange={this.handleUserInput}
                onInputClear={this.handleInputClear}
                value={this.state[name] || ""}
                slot="list"
                {...this.getErrorMessagesProps(name)}
              />
            );
          })}
        </List>
      </div>
    );
  }
}

export default compose(withTranslation())(ProductParamsForm);
