import React from "react";
import { Link, F7Link, Icon } from "framework7-react";
import classNames from "classnames";
import { i18n } from "i18next";
import { getI18n, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { IApplicationStore } from "../../store/rootReducer";

import "./style.less";

type Props = F7Link.Props & Pick<WithTranslation, "t">  & {
  language?: string;
  i18n?: i18n;
};

const LanguageLink = ({ className, ...props }: Props) => (
  <Link
    {...props}
    className={classNames("language-link", className)}
  >
    <span>{props.i18n.language.toUpperCase()}</span>
    <Icon material="expand_more" />
  </Link>
)

const mapStateToProps = (state: IApplicationStore) => ({
  language: state.rootReducer.language,
  i18n: getI18n(),
});

export default compose<React.FC<Props>>(
  withTranslation(),
  connect(mapStateToProps, null)
)(LanguageLink);
