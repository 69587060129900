import React, { useState } from 'react';
import { Block, Link } from 'framework7-react';

import './style.less';
import { DescriptionDetailsText } from "./index";

type Props = {
  text: string,
  moreLinkText?: string,
  lessLinkText?: string,
  expanded?: boolean,
}

const DescriptionDetails = (props: Props) => {
  const [expanded, moreClickHandle] = useState(props.expanded)
  const { text, moreLinkText, lessLinkText } = props

  return text && text.length ? (
    <div className="description">
      {text.length > 150 ? (
        <>
          <DescriptionDetailsText text={text} substringLength={expanded ? 0 : 150} />
          <Link className="more" onClick={() => moreClickHandle(!expanded)}>
            {expanded ? (lessLinkText || 'Less') : (moreLinkText || 'More')}
          </Link>
        </>
      ) : text}
    </div>
  ) : null
}

export default DescriptionDetails
