import { connect } from "react-redux";
import {
  cartAdd,
  cartRemove,
  cartUpdate,
  cartRemoveBySeller,
  setDeliveryInformation, setDeliveryPrice,
} from "../actions/cartActions";
import { DeliveryMethod } from "../reducers/cartReducer";
import { IProduct } from "../reducers/productReducer";
import { IApplicationStore } from "./rootReducer";

export interface ICartProps {
  isFetchingCart: boolean;
  isUpdatingCart: boolean;
  errorCart?: any;
  itemsCart: {
    product: IProduct;
    price: number;
    count: number;
    discount: number;
    total: number;
    deliveryCountryCode?: string;
    deliveryCode?: string;
  }[];
  delivery: DeliveryMethod;
  itemsTotalCart: number;
  discountCart: number;
  totalCart: number;
  cartAdd?(item: IProduct, count: number): void;
  cartUpdate?(item: IProduct, count: number): void;
  cartRemove?(item: IProduct, count: number): void;
  cartRemoveBySeller?(seller: string): void;
  setDeliveryInformation?(
    deliveryCountryCode: string,
    deliveryCode: string,
    delivery: DeliveryMethod,
    ownerUid: string
  ): void;
  setDeliveryPrice?(deliveryPrice: number): void;
}

const mapStateToProps = (state: IApplicationStore): ICartProps => ({
  isFetchingCart: state.cartReducer.isFetching,
  isUpdatingCart: state.cartReducer.isUpdating,
  errorCart: state.cartReducer.error,
  itemsCart: state.cartReducer.items,
  itemsTotalCart: state.cartReducer.itemsTotal,
  discountCart: state.cartReducer.discount,
  delivery: state.cartReducer.delivery,
  totalCart: state.cartReducer.total,
});

const mapDispatchToProps = (
  dispatch: any
): Pick<
  ICartProps,
  | "cartAdd"
  | "cartUpdate"
  | "cartRemove"
  | "cartRemoveBySeller"
  | "setDeliveryInformation"
  | "setDeliveryPrice"
> => ({
  cartAdd: (item: IProduct, count: number) => dispatch(cartAdd(item, count)),
  cartUpdate: (item: IProduct, count: number) =>
    dispatch(cartUpdate(item, count)),
  cartRemove: (item: IProduct, count: number) =>
    dispatch(cartRemove(item, count)),
  cartRemoveBySeller: (seller: string) => dispatch(cartRemoveBySeller(seller)),
  setDeliveryInformation: (
    deliveryCountryCode: string,
    deliveryCode: string,
    delivery: DeliveryMethod,
    ownerUid: string
  ) =>
    dispatch(
      setDeliveryInformation(deliveryCountryCode, deliveryCode, delivery, ownerUid)
    ),
  setDeliveryPrice: (deliveryPrice: number) => dispatch(setDeliveryPrice(deliveryPrice)),
});

export default connect(mapStateToProps, mapDispatchToProps);
