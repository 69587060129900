import React from "react";
import { Link, F7Link } from "framework7-react";
import { Profile } from "../../reducers/sessionReducer";
import { Avatar } from "../Avatar";
import classNames from "classnames";

import "./style.less";

type Props = F7Link.Props & {
  profile?: Profile;
};

export default ({ profile, className, ...props }: Props) => (
  <Link
    href={profile && profile.uid ? "/profile/" : "/login/"}
    {...props}
    className={classNames("profile-link", className)}
  >
    <Avatar className="small" profile={profile || {}} />
  </Link>
);
