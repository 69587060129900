import classNames from "classnames";
import {
  Badge,
  Block,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Col,
  List,
  ListItem,
  Navbar,
  Page,
  Preloader,
  Row,
  Link,
} from "framework7-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadOrders, selectOrder } from "../../actions/ordersActions";
import { IcArrowRight } from "../../components-ui/icons";
import { IClassificator } from "../../reducers/classificatorReducer";
import connectChat, { IChatProps } from "../../store/connectChat";
import { IApplicationStore, ResizeEvent } from "../../store/rootReducer";
import {
  ProductOrder,
  ProductOrderBuyer,
  ProductOrderItem,
  ProductOrderShippingAddress,
  ProductOrderShippingItem,
} from "../../types/paymentapi";
import { formatDateTime, formatPrice } from "../../utils";

import "./style.less";

type Props = IChatProps &
  Page.Props & {
    resizeEvent?: ResizeEvent;
    loading?: boolean;
    error?: any;
    orders: ProductOrder[];
    orderStates: IClassificator[];
    loadOrders?(): void;
    selectOrder?(order: ProductOrder): void;
  };

class OrdersPage extends React.Component<Props & WithTranslation> {
  constructor(props: Readonly<Props & WithTranslation>) {
    super(props);
    this.state = {};
  }

  pageInitHandle = () => {
    if (!this.props.orders.length) {
      this.props.loadOrders();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { error } = this.props;
    if (error && error !== prevProps.error) {
      this.$f7.dialog.alert(error);
    }
  }

  shippingAddress = (shippingAddress: ProductOrderShippingAddress): string => {
    return shippingAddress ? shippingAddress.fullAddress : "";
  };

  buyer = (buyer: ProductOrderBuyer): string => {
    return buyer.name + " " + buyer.surname;
  };

  buyerIdentity = (buyer: ProductOrderBuyer): string => {
    const identity: string[] = [];
    if (buyer.email) {
      identity.push(buyer.email);
    }
    if (buyer.phone) {
      identity.push(buyer.phone);
    }
    return identity.join(", ");
  };

  orderClickHandler = (order: ProductOrder) => {
    this.props.selectOrder(order);
    this.$f7router.navigate("details");
  };

  startChatHandle = (order: ProductOrder) => {
    const { loading } = this.props.chatReducer;
    if (loading) return;
    const sellerPhone = order.seller.phone;
    const message = this.props.t("Hi! I am contacting you with regards to my order No.", {
      orderNumber: order.orderNumber,
    });
    this.props.startChat(sellerPhone, message);
  };

  render() {
    const { t, loading, orders } = this.props;

    return (
      <Page id="orders_page" name="orders-page" onPageInit={this.pageInitHandle} onPageReinit={this.pageInitHandle}>
        <Navbar title={t("My Orders")} backLink={t("Back").toString()} noHairline noShadow />

        <Block />

        {loading && (
          <Block className="text-align-center">
            <Preloader />
          </Block>
        )}

        {!loading && (
          <Block className="orders">
            {orders.reverse().map((order: any) => (
              <Card key={order.uid} className="order" outline>
                <CardHeader className="order-header">
                  <Row className="pure-hidden-xs">
                    <Col medium="35" width="100" className="order-header-info">
                      <span>{t("Order")}:</span> {order.orderNumber}
                      <br />
                      {order.orderDate && (
                        <>
                          <span>{t("Order time")}:</span> {formatDateTime(order.orderDate)}
                        </>
                      )}
                    </Col>
                    <Col medium="30" width="60">
                      <Badge
                        className={classNames("order-status-badge", `order-status-badge-${order.status.toString()}`)}
                      >
                        {order.statusDescription}
                      </Badge>
                    </Col>
                    <Col medium="20" width="0">
                      &nbsp;
                    </Col>
                    <Col medium="15" width="40" className="amount pure-hidden-xs">
                      {formatPrice(order.amount, order.currencyCode, true)}
                    </Col>
                  </Row>
                  <Row className="pure-visible-xs" onClick={() => this.orderClickHandler(order)}>
                    <Col width="70">
                      <Row>
                        <Col width="100" className="no-padding">
                          <b>{order.orderNumber}</b>
                        </Col>
                        <Col width="100" className="no-padding">
                          <Badge
                            className={classNames(
                              "order-status-badge",
                              `order-status-badge-${order.status.toString()}`
                            )}
                          >
                            {order.statusDescription}
                          </Badge>
                        </Col>
                      </Row>
                    </Col>
                    <Col width="30" className="text-align-right padding-top-half">
                      <IcArrowRight />
                    </Col>
                  </Row>
                </CardHeader>
                <CardContent className="order-content">
                  <List mediaList noHairlines noChevron>
                    {order.items.map((item: ProductOrderItem) => (
                      <ListItem
                        key={item.productUid}
                        title={item.name}
                        subtitle={
                          item.parameters["northstar.imei-code"]
                            ? "IMEI: " + item.parameters["northstar.imei-code"]
                            : undefined
                        }
                      >
                        {item.imageUrl && <img slot="media" src={item.imageUrl} alt={item.name} />}
                        <div slot="text">{item.description}</div>
                        <div slot="after" className="pure-hidden-xs">
                          {formatPrice(item.price, order.currencyCode, true)} x {item.quantity}
                        </div>
                        <div slot="inner-end" className="pure-visible-xs mobile-price-text">
                          <b>{formatPrice(item.price, order.currencyCode, true)}</b>&nbsp;x {item.quantity}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardFooter className="pure-hidden-xs order-footer">
                  <Row>
                    <Col medium="35" width="100">
                      <b>{t("Contact")}</b>
                      <br />
                      {this.buyer(order.buyer)}
                      <br />
                      {this.buyerIdentity(order.buyer)}
                      <br />
                      {this.shippingAddress(order.shippingAddress)}
                    </Col>
                    <Col medium="30" width="100">
                      <b>{t("Delivery")}</b>
                      <br />
                      {order.shippingItems &&
                        order.shippingItems.map((shippingItem: ProductOrderShippingItem) => (
                          <span key={shippingItem.name}>
                            {shippingItem.name}: {formatPrice(shippingItem.price, order.currencyCode, true)}
                          </span>
                        ))}
                      {!order.shippingItems && <span>{t("Free of charge")}</span>}
                      {/*
                      <br/>
                      {t('Estimated delivery time')}: 3-7 days
                      */}
                    </Col>
                    <Col medium="20" width="100">
                      <b>{t("Seller")}</b>
                      <br />
                      {order.seller.name}
                      <br />
                      {order.seller.phone && (
                        <Link onClick={() => this.startChatHandle(order)}>{t("Contact seller")}</Link>
                      )}
                    </Col>
                    <Col medium="15" width="0" className="pure-hidden-xs">
                      &nbsp;
                    </Col>
                  </Row>
                </CardFooter>
                <CardFooter className="pure-visible-xs order-footer">
                  <Row>
                    <Col width="50">{t("Items")}:</Col>
                    <Col width="50" className="text-align-right">
                      {order.productCount}
                    </Col>
                  </Row>
                  <Row>
                    <Col width="50">{t("Total")}:</Col>
                    <Col width="50" className="text-align-right mobile-total-price">
                      {formatPrice(order.amount, order.currencyCode, true)}
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            ))}
          </Block>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  loading: state.ordersReducer.ordersLoading,
  error: state.ordersReducer.ordersLoadingError,
  orders: state.ordersReducer.orders,
  orderStates: state.classificatorReducer.entitiesClassificators.Order_State,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadOrders: () => dispatch(loadOrders()),
  selectOrder: (order: ProductOrder) => dispatch(selectOrder(order)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), connectChat, withTranslation())(OrdersPage);
