import React from "react";
import { Block, BlockTitle, List, ListItem, Page, Popover } from "framework7-react";
import { AboutPopup } from "../components/AboutPopup";
import { logoutUser } from "../actions/sessionActions";
import { ContactSupportPopup } from "../components/ContactSupportPopup";
import { ForgotPasswordPopup } from "../components/ForgotPassword";
import { LanguageLink } from "../components/LanguageLink";
import LanguagePopover from "../components/LanguagePopover/LanguagePopover";
import LoginDesktopPopup from "../components/LoginDesktop/LoginDesktopPopup";
import { RegisterDesktopPopup } from "../components/RegisterDesktop";
import connectAllDeals from "../store/connectAllDeals";
import { IApplicationStore, ILocalConfig, ResizeEvent } from "../store/rootReducer";
import { compose } from "redux";
import { connect } from "react-redux";
import { Profile } from "../reducers/sessionReducer";
import { getProfile } from "../selectors/profile";
import Slider, { SliderItem, SliderType } from "../components/Slider";
import CategoriesMenu from "../components/CategoriesMenu";
import CatalogBlockTitle from "../components/CatalogBlockTitle";
import { Catalog } from "../components/Catalog/index";
import {
  allFiltresClear,
  chooseCategory,
  chooseSortBy,
  chooseSubcategory,
  clearSortBy,
} from "../actions/filterActions";
import { loadCategories } from "../actions/categoryActions";
import {
  addToWishList,
  clearSearchResult,
  ISearchParams,
  loadProductListCategory,
  loadProductListType,
  loadProductWishList,
} from "../actions/productActions";
import { IProduct, IProductState, ProductListType } from "../reducers/productReducer";
import debounce from "lodash/debounce";
import { withTranslation, WithTranslation } from "react-i18next";
import { ICategoryClassificator } from "../reducers/categoryReducer";
import connectCategoriesClassificator from "../store/connectCategoriesClassificator";
import connectAllGoods from "../store/connectAllGoods";
import connectLocalConfig from "../store/connectLocalConfig";
import connectChat, { IChatProps } from "../store/connectChat";
import { BigMarketingBannerSlider } from "../components/BigMarketingBannerSlider";
import { Device, Dom7 } from "framework7";
import { getActionRefFromURL, removeOmnideskLoginWidget } from "../utils";
import SelectCategorySheet from "./select-category-sheet";
import { CategoriesMenuDesktop } from "../components/categories-menu-desktop";
import { goToMessenger } from "../actions/profileActions";
import { SortingButton } from "../components/sorting-button";
import { sortByTypes } from "../components/SortByButtonsGroup";
import { IFilterState, SortBy } from "../reducers/filterReducer";
import connectFilter from "../store/connectFilter";
import { getSortByFields } from "./all-filtres-popup";
import connectCart, { ICartProps } from "../store/connectCart";
import Navbar from "../components/navbar";
import connectSearch, { ISearchConnectorProps } from "../store/connectSearch";
import { ShortRegistrationPopup } from "../components/ShortRegistrationPopup";
import { analytics } from "../Setup";
import InviteFriendPopUp from "../components/InviteFriendPopUp";
import ViewProfilePopup from "../components/ViewProfilePopup";
import { Router } from "framework7/modules/router/router";
import ProfileMenu from "../components/ProfileMenu";

type Props = IChatProps &
  WithTranslation &
  IFilterState &
  ICartProps &
  ISearchConnectorProps & {
    profile?: Profile;
    loadCategories?(): void;
    chosenCategoryId?: string | null;
    chosenSubcategoryId?: string | null;
    chooseCategory?(catid?: string | null): void;
    chooseSubcategory?(catid?: string): void;
    clearSortBy?(): void;
    productState?: IProductState;
    loadAllGoods?(): void;
    loadProducts?(type: ProductListType): void;
    loadProductListCategory?(type: ProductListType): void;
    categoriesClassificator?: ICategoryClassificator[];
    allGoodsProducts?: IProduct[];
    loadAllGoods?(searchParams: ISearchParams): void;
    allGoodsCount?: number;
    allGoodsOffset?: number;
    allGoodsLoading?: boolean;
    localConfig?: ILocalConfig;
    localConfigLoaded?: boolean;
    addToWish?(uid?: string): void;
    loadProductWishList?(): () => Promise<void>;
    resizeEvent?: ResizeEvent;
    goToMessenger?(): void;
    clearFilter?(): void;
    chooseSortBy?(sortBy: SortBy, refresh?: boolean): void;
    language?: string;
    logout?(): void;
    productsLoading?: boolean;
    loadAllDeals?(searchParams: ISearchParams): void;
    reloadAllDeals?(): void;
    allDealsCount?: number;
    allDealsOffset?: number;
    allDealsLoading?: boolean;
    allDealsProducts?: IProduct[];
    delay: boolean;
    clearSearchResult?(): void;
    f7router: Router.Router;
  };

type State = {
  searchBarEnable?: boolean;
  selectCategorySheetOpened?: boolean;
  categoriesMenuOpened?: boolean;
  profilePopoverOpened?: boolean;
  sortingMenuPopoverOpened?: boolean;
  languagePopoverOpened?: boolean;
  loginPopupOpened?: boolean;
  invitePopupOpened?: boolean;
  referral?: string;
  registerPopupOpened?: boolean;
  aboutPopupOpened?: boolean;
  viewProfilePopupOpened?: boolean;
  contactSupportPopupOpened?: boolean;
  seeAll?: boolean;
  seeAllLoaded?: boolean;
  allDeals?: boolean;
  forgotPasswordPopupOpened?: boolean;
  actionRefAlert?: boolean;
  shortRegisterPopupOpened?: boolean;
  showBadges?: boolean;
};

class HomePage extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      searchBarEnable: false,
      selectCategorySheetOpened: false,
      loginPopupOpened: false,
      registerPopupOpened: false,
      aboutPopupOpened: false,
      viewProfilePopupOpened: false,
      invitePopupOpened: false,
      contactSupportPopupOpened: false,
      seeAll: false,
      seeAllLoaded: false,
      allDeals: false,
      forgotPasswordPopupOpened: false,
      actionRefAlert: false,
      shortRegisterPopupOpened: false,
      showBadges: false,
    };
  }

  private showAllGoods = process.env.SHOW_ALL_GOODS === "true";
  private simplifiedRegistration = process.env.SIMPLIFIED_REGISTRATION === "true";

  private homeUrl = process.env.HOME_URL;

  // @ts-ignore
  private page = null;

  async componentDidMount() {
    const { delay, profile } = this.props;
    const timeout = delay ? 1500 : 0;
    setTimeout(() => {
      this.loadMainSlider();
      this.loadSliders();
      this.loadAllGoods();
    }, timeout);

    const $odwLogin = Dom7("#omnidesk_widget_login_button");
    if ($odwLogin) {
      $odwLogin.on("click", () => {
        if (!this.props.profile || !this.props.profile.uid) {
          this.setState({ loginPopupOpened: true });
        } else {
          removeOmnideskLoginWidget();
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.handleAllLoading(prevProps);
    this.handleLocalConfigLoading(prevProps);
    this.handleInfiniteLoading(prevProps);
    this.handleSelectCategorySheet(prevProps);
    this.handleCategoryMenu(prevProps);
    this.handleChatErrors(prevProps);
    this.handleSorting(prevProps);

    if (
      prevProps.chosenCategoryId !== this.props.chosenCategoryId &&
      prevProps.chosenSubcategoryId !== this.props.chosenSubcategoryId &&
      !this.state.selectCategorySheetOpened
    ) {
      this.setState({ showBadges: !this.state.showBadges });
    }
  }

  clearSearch = () => {
    const { resizeEvent } = this.props;
    const isMobile = resizeEvent.width < 567;
    if (isMobile) {
      this.setState({ seeAll: false, seeAllLoaded: false, allDeals: false });
    }
    this.props.clearSearch(!isMobile);
    this.props.clearSortBy();
  };

  getSliderType = (): string => {
    return this.props.localConfig.homeSliderType;
  };

  handleLocalConfigLoading = (prevProps: Props) => {
    const { localConfigLoaded, delay } = this.props;
    if (localConfigLoaded && localConfigLoaded !== prevProps.localConfigLoaded) {
      setTimeout(
        () => {
          this.loadMainSlider();
        },
        delay ? 1000 : 0
      );
    }
  };

  loadMainSlider = () => {
    const { localConfigLoaded, chosenCategoryId, chosenSubcategoryId } = this.props;
    if (localConfigLoaded && !chosenCategoryId && !chosenSubcategoryId) {
      this.props.loadProducts(this.getSliderType());
    }
  };

  handlePageInit = (page: any) => {
    this.page = page;
    if (this.$f7router.currentRoute.params) {
      this.clearSearch();
      const { catid, subcatid } = this.$f7router.currentRoute.params;
      this.props.chooseCategory(catid);
      this.props.chooseSubcategory(subcatid);
      if (catid && subcatid) {
        this.props.search({
          category: subcatid,
          count: 15,
          offset: 0,
          resetSearch: true,
          resetSorting: true,
        });
      } else if (catid) {
        this.setState({ seeAll: true }, () => {
          this.props.chooseCategory(catid);
          this.props.search({
            category: catid,
            count: 15,
            offset: 0,
            resetSearch: true,
            resetSorting: true,
          });
        });
      }
    }

    // TODO: Check why categories are not defined on page init
    setTimeout(() => this.loadSliders(), 2000);

    this.setState({
      searchBarEnable: false,
      selectCategorySheetOpened: false,
      categoriesMenuOpened: false,
      profilePopoverOpened: false,
      sortingMenuPopoverOpened: false,
      languagePopoverOpened: false,
      loginPopupOpened: false,
      registerPopupOpened: false,
      shortRegisterPopupOpened: false,
    });

    setTimeout(() => {
      this.handleLoginRegisterFromRoute();
      this.handleActionRefFromUrl();
    }, 3500);
  };

  async handleAllLoading(prevProps: Props) {
    /* TODO */
    const { profile, productsLoading } = this.props;
    if (
      profile &&
      profile.uid &&
      profile.uid !== prevProps.profile.uid &&
      !productsLoading &&
      productsLoading !== prevProps.productsLoading
    ) {
      this.clearSearch();
      this.props.loadProductWishList();
      this.loadMainSlider();
      this.loadAllGoods();

      /* TODO Maybe, we will add on profile loaded event?! */
      if (this.$f7router.currentRoute.params) {
        const { catid, subcatid } = this.$f7router.currentRoute.params;
        if (catid && subcatid) {
          this.props.chooseCategory(catid);
          this.props.chooseSubcategory(subcatid);
          this.props.search({
            category: subcatid,
            count: 15,
            offset: 0,
            resetSearch: true,
            resetSorting: true,
          });
        } else if (catid) {
          this.setState({ seeAll: true }, () => {
            this.props.chooseCategory(catid);
            this.props.search({
              category: catid,
              count: 15,
              offset: 0,
              resetSearch: true,
              resetSorting: true,
            });
          });
        }
      }
    }
  }

  handleLoginRegisterFromRoute = () => {
    if (!this.$f7router.currentRoute.query) {
      return;
    }

    const action = this.$f7router.currentRoute.query["action"] || null;
    if (action === "login" && !this.state.loginPopupOpened) {
      this.setState({ loginPopupOpened: true }, () => this.$f7router.navigate("/"));
    }

    if (action === "register" && !this.state.registerPopupOpened) {
      if (this.$f7router.currentRoute.query["referral"]) {
        this.setState({ referral: this.$f7router.currentRoute.query["referral"] });
      }
      if (this.simplifiedRegistration) {
        this.setState({ shortRegisterPopupOpened: true }, () => this.$f7router.navigate("/"));
      } else {
        this.setState({ registerPopupOpened: true }, () => this.$f7router.navigate("/"));
      }
    }
  };

  handleBannerAction = (action: string) => {
    if (action === "login" && !this.state.loginPopupOpened) {
      this.setState({ loginPopupOpened: true });
    }

    if (action === "register" && !this.state.registerPopupOpened) {
      this.handleRegisterButton();
    }
  };

  handleActionRefFromUrl = () => {
    const { t } = this.props;
    const actionRef = getActionRefFromURL();
    if (actionRef && !this.state.actionRefAlert) {
      this.setState({ actionRefAlert: true }, () => {
        this.$f7.dialog.alert(t(actionRef), () => {
          this.setState({ actionRefAlert: false });
        });
      });
    }
  };

  loadSliders = () => {
    const { chosenCategoryId, chosenSubcategoryId } = this.props;
    const { sliderCategories } = this.props.localConfig;

    if (chosenCategoryId || chosenSubcategoryId) {
      return;
    }

    if (!sliderCategories) {
      return;
    }

    if (this.$f7router.currentRoute.params) {
      const { catid, subcatid } = this.$f7router.currentRoute.params;
      if (catid || subcatid) {
        return;
      }
    }

    sliderCategories.forEach((category: string) => this.props.loadProductListCategory(category));
  };

  handleInfiniteLoading = (prevProps: Props) => {
    if (prevProps.productState.loading && prevProps.productState.loading !== this.props.productState.loading) {
      this._lock = false;
    }
    if (prevProps.allGoodsLoading && prevProps.allGoodsLoading !== this.props.allGoodsLoading) {
      this._lock = false;
    }
    if (prevProps.productsLoading && prevProps.productsLoading !== this.props.productsLoading) {
      this._lock = false;
    }
    if (prevProps.allDealsLoading && prevProps.allDealsLoading !== this.props.allDealsLoading) {
      this._lock = false;
    }
  };

  handleSelectCategorySheet(prevProps: Props) {
    const { chosenCategoryId, chosenSubcategoryId } = this.props;
    const { selectCategorySheetOpened, seeAll, seeAllLoaded } = this.state;
    const isSelectedAnotherCategory = prevProps.chosenCategoryId !== chosenCategoryId;
    const isSelectedOnlyCategory = chosenCategoryId && isSelectedAnotherCategory && !this.props.chosenSubcategoryId;
    if (isSelectedOnlyCategory && !selectCategorySheetOpened && !seeAll) {
      this.toggleSelectCategorySheet(true);
    } else if (
      chosenCategoryId &&
      chosenSubcategoryId &&
      selectCategorySheetOpened &&
      !seeAll &&
      !this.props.productState.loading
    ) {
      /* TODO */
      this.toggleSelectCategorySheet(false);
      this.search();
    } else if (chosenCategoryId && seeAll && !seeAllLoaded && !this.props.productState.loading) {
      this.clearSearch();
      this.setState({ seeAllLoaded: true }, () => {
        this.search();
      });
    }
  }

  handleCategoryMenu = (prevProps: Props) => {
    const { chosenCategoryId, chosenSubcategoryId } = this.props;
    const { categoriesMenuOpened } = this.state;
    if (
      chosenCategoryId &&
      chosenSubcategoryId &&
      (prevProps.chosenCategoryId !== chosenCategoryId || prevProps.chosenSubcategoryId !== chosenSubcategoryId) &&
      categoriesMenuOpened
    ) {
      this.setState({ categoriesMenuOpened: false }, () => {
        this.clearSearch();
        this.search();
      });
    }
  };

  handleSorting = (prevProps: Props) => {
    const { allDeals } = this.state;
    const { sortBy } = this.props;
    if (!allDeals && prevProps.sortBy !== sortBy) {
      const { chosenCategoryId, chosenSubcategoryId } = this.props;
      const { searchTerm } = this.props.productState;
      const params: ISearchParams = {
        category: chosenSubcategoryId || chosenCategoryId,
        name: searchTerm,
        count: 15,
        offset: 0,
        resetSorting: true,
      };

      if (sortBy.length) {
        params.sortBy = getSortByFields(sortBy);
      }

      this.props.search(params);
    }

    if (allDeals && prevProps.sortBy !== sortBy) {
      this.props.reloadAllDeals();
      this.delayedAllDealsLoading();
    }
  };

  toggleSelectCategorySheet(show = true) {
    this.setState({ selectCategorySheetOpened: show });
  }

  handleChatErrors = (prevProps: Props) => {
    if (this.$f7route.url === this.$f7router.currentRoute.url) {
      const { error } = this.props.chatReducer;
      if (error && error !== prevProps.chatReducer.error) {
        this.$f7?.dialog.alert(error);
      }
    }
  };

  handleOnSelectCategorySheetClosed = () => {
    this.toggleSelectCategorySheet(false);
    requestAnimationFrame(() => {
      this.clearFilter();

      if (this.props.chosenCategoryId && this.props.chosenSubcategoryId) {
        this.setState({ showBadges: true });
      }
    });
  };

  clearFilter = () => {
    const { chosenSubcategoryId } = this.props;

    if (!chosenSubcategoryId) {
      this.props.chooseCategory(null);
    }
  };

  seeAllHandle(catid?: string) {
    if (catid) {
      this.setState({ seeAll: true }, () => {
        this.props.chooseCategory(catid);
      });
    }

    if (catid) {
      this.setState({ seeAll: true, showBadges: true }, () => {
        this.props.chooseCategory(catid);
        this.props.search({});
      });
    }
  }

  seeAllDealsHandle() {
    this.setState({ allDeals: true }, () => {
      this.loadAllDeals();
    });
  }

  categoryClickHandle = (catid: string) => {
    this.props.clearFilter();
    this.props.clearSearchResult();
    this.props.clearSearch();

    if (catid === "all_filtres") {
      this.$f7router.navigate("/all-filtres/");
      return;
    }

    this.props.chooseCategory(catid);
  };

  clearFilterHandle = () => {
    this.clearCategoryFilterHandle();

    this.props.clearFilter();
    this.props.clearSearchResult();
    this.props.clearSearch();

    this.setState({ showBadges: false });
  };

  clearCategoryFilterHandle = () => {
    this.props.chooseCategory(null);
    this.props.chooseSubcategory(null);
  };

  clearSearchResult = () => {
    this.props.clearSearchResult();
  };

  searchbarEnableHandle = () => {
    this.setState({ searchBarEnable: true });
  };

  searchbarDisableHandle = () => {
    const { chosenSubcategoryId, searchTerm } = this.props;
    this.setState({ searchBarEnable: false }, () => {
      this._lock = false;
    });
    if (!chosenSubcategoryId && !searchTerm) {
      this.clearSearch();
    }
  };

  private searchBarInputEl = null;

  delayedQuery = debounce((q) => this.search(q), 500);

  initSearchBarInputEl = () => {
    if (!this.searchBarInputEl) {
      this.searchBarInputEl = Device.ios
        ? this.$$(".page-current").parent().find('input[type="search"]')
        : this.$$('.page-current input[type="search"]');
    }
  };

  searchbarChangeHandle = () => {
    this.initSearchBarInputEl();
    let val = this.searchBarInputEl.val();
    this.delayedQuery(val);
  };

  _lock: boolean = false;

  loadMore = () => {
    const { searchBarEnable, allDeals } = this.state;
    const { searchLoading } = this.props;
    const { products, totalCount } = this.props.productState;
    const isFilterEnable = this.isFilterEnable();

    this.initSearchBarInputEl();

    if (this._lock) return;
    this._lock = true;

    if ((searchBarEnable || isFilterEnable) && !searchLoading && products.length !== totalCount) {
      this.search(this.searchBarInputEl.val());
    }

    if (allDeals && !this.props.allDealsLoading) {
      this.loadAllDeals();
    }

    if (!searchBarEnable && !isFilterEnable && !this.props.allGoodsLoading) {
      this.loadAllGoods();
    }
  };

  searchbarClearHandle = () => {
    this.clearSearch();
  };

  search = (query?: string) => {
    this.initSearchBarInputEl();

    if (!query && this.state.searchBarEnable) {
      this.clearSearch();
      return;
    }

    const { chosenCategoryId, chosenSubcategoryId, sortBy } = this.props;
    const { count, offset, searchTerm } = this.props.productState;

    if (!chosenCategoryId && !chosenSubcategoryId && !searchTerm) {
      return;
    }

    const mobile = this.props.resizeEvent.width < 567;
    const categorySelected = chosenSubcategoryId || chosenCategoryId;
    const emptyQuery = typeof query === "undefined" || query == "";

    const params: ISearchParams = {
      category: categorySelected,
      name: emptyQuery ? searchTerm : query,
      count: query === searchTerm ? count : 15,
      offset: query === searchTerm ? offset : 0,
    };

    if (!mobile && searchTerm && emptyQuery) {
      params.category = null;
    }

    if (sortBy.length) {
      params.sortBy = getSortByFields(sortBy);
    }

    this.props.search(params);
  };

  isFilterEnable = () => {
    const mobile = this.props.resizeEvent.width < 567;
    const searchBarResult = !this.state.searchBarEnable && this.props.searchTerm;

    return (this.state.searchBarEnable && mobile) || (searchBarResult && !mobile) || this.isCategorySearch();
  };

  isCategorySearch = () => {
    return this.props.chosenCategoryId || this.props.chosenSubcategoryId;
  };

  isQuerySearch = () => {
    return this.props.searchTerm;
  };

  sliderItemClickHandle = (item: SliderItem) => {
    this.$f7router.navigate(item.href);
  };

  getSliderProducts = (): SliderItem[] => {
    const {
      productState: { productTypeGroups },
    } = this.props;

    if (productTypeGroups) {
      const sliderItems = productTypeGroups.filter((item) => item.type === this.getSliderType())[0];

      if (sliderItems) {
        return sliderItems.products
          ? sliderItems.products.map((item) => {
              return {
                ...item,
                image: item.imageUrl1,
                priceDiscount: item.discountedPrice,
                href: `/product-details/${item.uid}/`,
                onClick: this.sliderItemClickHandle,
                description: item.shortDescription,
              };
            })
          : [];
      }
    }

    return [];
  };

  getSlidesItems = (catid?: string): SliderItem[] => {
    const {
      productState: { productTypeGroups },
    } = this.props;
    if (productTypeGroups) {
      const categoryItems = productTypeGroups.filter((item) => item.type === catid)[0];
      if (categoryItems) {
        return categoryItems.products
          ? categoryItems.products.map((item) => {
              return {
                ...item,
                image: item.imageThumbnailUrl1 || item.imageUrl1,
                priceDiscount: item.discountedPrice,
                href: `/product-details/${item.uid}/`,
                onClick: this.sliderItemClickHandle,
                description: item.shortDescription,
              };
            })
          : [];
      }
    }

    return [];
  };

  loadAllGoods = () => {
    if (!this.showAllGoods) {
      return;
    }

    const { allGoodsLoading, allGoodsCount, allGoodsOffset } = this.props;
    if (!allGoodsCount) return;

    if (!allGoodsLoading) {
      const params: ISearchParams = {
        count: allGoodsCount,
        offset: allGoodsOffset,
      };
      this.props.loadAllGoods(params);
    }
  };

  delayedAllDealsLoading = debounce(() => this.loadAllDeals(), 300);

  loadAllDeals = () => {
    if (!this.state.allDeals) {
      return;
    }

    const { allDealsLoading, allDealsCount, allDealsOffset, sortBy } = this.props;
    if (!allDealsCount) return;

    if (!allDealsLoading) {
      const params: ISearchParams = {
        count: allDealsCount,
        offset: allDealsOffset,
        discountedPriceFrom: 0.01,
      };

      if (sortBy.length) {
        params.sortBy = getSortByFields(sortBy);
      }

      this.props.loadAllDeals(params);
    }
  };

  startChatHandle = (uid: string) => {
    const products = this.props.productState.productTypeGroups.filter((item) => item.type === "popular")[0];
    const item = products.products.filter((item) => item.uid === uid)[0];

    const { loading } = this.props.chatReducer;
    if (loading) return;

    this.props.startChatWithOrder(item);
  };

  addToWishList = (uid: string) => {
    const { profile } = this.props;
    this.props.addToWish(uid);
    analytics.addToWishListWithUid(profile, uid);
  };

  renderCategoryTitleInSearchResultList() {
    const selectedCategory = this.props.categoriesClassificator.length
      ? this.props.categoriesClassificator.filter(
          (item) => item.categoryCode === (this.props.chosenSubcategoryId || this.props.chosenCategoryId)
        )[0]
      : null;

    return selectedCategory ? (
      <div className="catalog-block-title block-title block-title-medium pure-hidden-xs">
        {selectedCategory.categoryName} {selectedCategory.productCount > 0 && <>({selectedCategory.productCount})</>}
        {this.renderSortingMenu()}
      </div>
    ) : null;
  }

  renderAllDealsTitleWithSorting() {
    const { t } = this.props;

    return (
      <div className="catalog-block-title block-title block-title-medium pure-hidden-xs">
        {t("Current Deals")}
        {this.renderSortingMenu()}
      </div>
    );
  }

  renderSearchTermWithSorting() {
    const { t, searchTerm } = this.props;

    return (
      <div className="catalog-block-title block-title block-title-medium pure-hidden-xs">
        {t("Search Criteria")}: {searchTerm}
        {this.renderSortingMenu()}
      </div>
    );
  }

  renderSortingMenu() {
    const { t } = this.props;

    const sortTypes = sortByTypes.reduce((prev, cur) => {
      prev.push(...cur);
      return prev;
    }, []);
    const sortBy = this.props.sortBy[0] || SortBy.sales_first;
    const selectedSortType = sortTypes.filter((item) => item.type === sortBy)[0];

    return (
      <SortingButton
        text={t(selectedSortType.text)}
        onClick={() => {
          /* TODO */
          const inst = this.$f7.popover.get(".sorting-menu");
          if (inst) {
            this.$f7.popover.open(inst.el, ".sorting-button");
            this.setState({
              sortingMenuPopoverOpened: !this.state.sortingMenuPopoverOpened,
            });
          }
        }}
        opened={this.state.sortingMenuPopoverOpened}
      />
    );
  }

  handleClickProfileLink = () => {
    this.setState({ profilePopoverOpened: true });
  };

  handleClickLoginLink = () => {
    this.setState({ loginPopupOpened: true });
  };

  handleClickLanguageLink = () => {
    this.setState({ languagePopoverOpened: true });
  };

  renderSortingMenuPopover() {
    const { t } = this.props;

    return (
      <Popover
        className="sorting-menu"
        opened={this.state.sortingMenuPopoverOpened}
        onPopoverClosed={() => this.setState({ sortingMenuPopoverOpened: false })}
        backdrop={false}
        slot="fixed"
      >
        <List noHairlines noChevron noHairlinesBetween>
          {sortByTypes
            .reduce((prev, cur) => {
              prev.push(...cur);
              return prev;
            }, [])
            .map((item, i) => (
              <ListItem
                key={i.toString()}
                link="#"
                popoverClose
                title={t(item.text).toString()}
                onClick={() => {
                  this.setState(
                    {
                      sortingMenuPopoverOpened: false,
                    },
                    () => this.props.chooseSortBy(item.type, true)
                  );
                }}
              />
            ))}
        </List>
      </Popover>
    );
  }

  renderCategoriesSliderItem(category: string) {
    const items = this.getSlidesItems(category);

    if (items.length > 0) {
      const selectedCategory =
        this.props.categoriesClassificator.length &&
        this.props.categoriesClassificator.filter((item) => item.categoryCode === category)[0];
      const categoryTitle = selectedCategory.categoryName;
      const productCount = selectedCategory.productCount || 0;

      return (
        <>
          <CatalogBlockTitle medium onClick={() => this.seeAllHandle(category)}>
            {categoryTitle} {productCount > 0 && <>({productCount})</>}
          </CatalogBlockTitle>
          <Slider slides={items} type={SliderType.small} showIfEmpty={false} showFeaturesHiglight />
        </>
      );
    }

    return <></>;
  }

  onClickLogoLink = () => {
    if (
      this.homeUrl &&
      !this.state.categoriesMenuOpened &&
      !this.state.seeAll &&
      !this.state.allDeals &&
      !this.props.chosenCategoryId
    ) {
      window.location.href = this.homeUrl;
      return;
    }

    const { searchbar } = this.$f7;
    searchbar.disable(".search-bar");
    this.props.chooseCategory(null);
    this.clearSearch();
    this.setState({
      categoriesMenuOpened: false,
      searchBarEnable: false,
      allDeals: false,
    });
    this.$f7router.navigate("/", {
      reloadAll: true,
    });
  };

  onClickOpenCategoriesMenu = () =>
    this.setState({
      categoriesMenuOpened: !this.state.categoriesMenuOpened,
    });

  handleRegisterButton = () => {
    if (this.simplifiedRegistration) {
      this.setState({
        shortRegisterPopupOpened: true,
      });
    } else {
      this.setState({
        registerPopupOpened: true,
      });
    }
    analytics.openRegistrationForm();
  };

  render() {
    const { searchedProducts, searchLoading, allGoodsLoading, profile, t } = this.props;
    let isFilterEnable = this.isFilterEnable();
    const { searchBarEnable, selectCategorySheetOpened, allDeals } = this.state;
    const { sliderCategories } = this.props.localConfig;
    const showOnSearch = !isFilterEnable;

    return (
      <Page
        id="home_page"
        name="home-page"
        infinite
        infiniteDistance={300}
        infinitePreloader={searchLoading || allGoodsLoading}
        onInfinite={this.loadMore}
        onPageInit={this.handlePageInit}
      >
        <Navbar
          profile={profile}
          showProfileLink
          showCategoriesMenu
          showWishList
          f7router={this.$f7router}
          onClickLogoLink={this.onClickLogoLink}
          onClickOpenCategoriesMenu={this.onClickOpenCategoriesMenu}
          openCategoriesMenuButton={this.state.categoriesMenuOpened}
          language={this.props.language}
          onClickLanguageLink={this.handleClickLanguageLink}
          showLanguageLink
          onSearchbarEnable={this.searchbarEnableHandle}
          onSearchbarDisable={this.searchbarDisableHandle}
          onSearchClickClear={this.searchbarClearHandle}
          showSearchbar
          findedProducts={this.props.searchProductsAutocomplete}
          findedProductsCount={this.props.searchProductsAutocompleteCount}
          findProductLoading={this.props.searchLoadingAutocomplete}
          onFindedProductItemClick={(uid) => this.$f7router.navigate(`/product-details/${uid}/`)}
          onSearchResult={() => {
            this.clearCategoryFilterHandle();
            this.clearSearchResult();
          }}
          slot="fixed"
        />

        {!searchBarEnable && (
          <CategoriesMenu
            className="pure-visible-xs"
            categoryOnClick={this.categoryClickHandle}
            clearFilterOnClick={this.clearFilterHandle}
            showBadges={this.state.showBadges}
          />
        )}

        {showOnSearch && !allDeals && (
          <>
            <Block className="no-padding">
              <BigMarketingBannerSlider actionClick={(action) => this.handleBannerAction(action)} />
            </Block>

            {this.getSliderType() === "popular" && <BlockTitle medium>{t("Most popular")}</BlockTitle>}
            {this.getSliderType() === "deals" && (
              <CatalogBlockTitle medium onClick={() => this.seeAllDealsHandle()}>
                {t("Current Deals")}
              </CatalogBlockTitle>
            )}
            {this.getSliderType() === "disabled" ||
              this.getSliderType() === null ||
              (this.getSliderType() === undefined && <></>)}
            <Slider slides={this.getSliderProducts()} type={SliderType.big} startChat={this.startChatHandle} />

            {sliderCategories &&
              sliderCategories.length > 0 &&
              sliderCategories.map((category) => <div key={category}>{this.renderCategoriesSliderItem(category)}</div>)}

            {this.showAllGoods && (
              <>
                <CatalogBlockTitle medium>{t("All goods")}</CatalogBlockTitle>
                <Catalog items={this.props.allGoodsProducts} addToWish={this.addToWishList} showFeaturesHiglight />
              </>
            )}
          </>
        )}

        {isFilterEnable && !showOnSearch && !allDeals && (
          <>
            {this.isCategorySearch() && this.renderCategoryTitleInSearchResultList()}
            {this.isQuerySearch() && !this.isCategorySearch() && this.renderSearchTermWithSorting()}
            <Catalog items={searchedProducts} addToWish={this.addToWishList} showFeaturesHiglight />
          </>
        )}

        {allDeals && (
          <>
            {this.renderAllDealsTitleWithSorting()}
            <Catalog items={this.props.allDealsProducts} addToWish={this.addToWishList} showFeaturesHiglight />
          </>
        )}

        <div slot="fixed">
          <CategoriesMenuDesktop
            className="pure-hidden-xs"
            opened={this.state.categoriesMenuOpened}
            onLeave={() => {
              this.setState({ categoriesMenuOpened: false });
            }}
          />
        </div>

        <SelectCategorySheet
          opened={selectCategorySheetOpened}
          onSheetClosed={this.handleOnSelectCategorySheetClosed}
          slot="fixed"
        />

        <LanguagePopover
          backdrop={false}
          opened={this.state.languagePopoverOpened}
          target=".language-link"
          onPopoverClosed={() => this.setState({ languagePopoverOpened: false })}
          t={t}
          slot="fixed"
        />

        {this.renderSortingMenuPopover()}
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  chosenCategoryId: state.filterReducer.chosenCategoryId,
  chosenSubcategoryId: state.filterReducer.chosenSubcategoryId,
  profile: getProfile(state),
  productsLoading: state.productReducer.loading,
  productState: state.productReducer,
  resizeEvent: state.rootReducer.resizeEvent,
  sortBy: state.filterReducer.sortBy,
  language: state.rootReducer.language,
  delay: state.rootReducer.delayLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadCategories: () => dispatch(loadCategories()),
  chooseCategory: (catid?: string) => dispatch(chooseCategory(catid)),
  chooseSubcategory: (catid?: string) => dispatch(chooseSubcategory(catid)),
  clearSortBy: () => dispatch(clearSortBy()),
  loadProducts: (type: ProductListType) => dispatch(loadProductListType(type)),
  loadProductListCategory: (type: ProductListType) => dispatch(loadProductListCategory(type)),
  addToWish: (uid?: string) => dispatch(addToWishList(uid)),
  loadProductWishList: () => dispatch(loadProductWishList()),
  goToMessenger: () => dispatch(goToMessenger()),
  clearFilter: () => dispatch(allFiltresClear()),
  chooseSortBy: (sortBy: SortBy, refresh?: boolean) => dispatch(chooseSortBy(sortBy, refresh)),
  logout: () => dispatch(logoutUser()),
  clearSearchResult: () => dispatch(clearSearchResult()),
});

export default compose(
  connectLocalConfig,
  connectCategoriesClassificator,
  connectAllGoods,
  connectChat,
  connectFilter,
  connectCart,
  connectAllDeals,
  connect(mapStateToProps, mapDispatchToProps),
  connectSearch,
  withTranslation()
)(HomePage);
