import classNames from "classnames";
import React from "react";
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  Badge,
  Row,
  Col,
  List,
  ListItem,
  Icon, Link,
} from "framework7-react";

import "./style.less";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import connectChat, { IChatProps } from "../../store/connectChat";
import { IApplicationStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import {
  ProductOrder,
  ProductOrderItem,
  ProductOrderShippingItem,
} from "../../types/paymentapi";
import { formatDateTime, formatPrice } from "../../utils";

type Props = IChatProps & Page.Props & {
  order: ProductOrder;
};

class OrderDetailsPage extends React.Component<Props & WithTranslation> {
  constructor(props: Readonly<Props & WithTranslation>) {
    super(props);
    this.state = {};
  }

  shippingAddress = (): string => {
    const { order } = this.props;
    return order.shippingAddress ? order.shippingAddress.fullAddress : '';
  };

  buyer = (): string => {
    const { order } = this.props;
    return order.buyer.name + ' ' + order.buyer.surname;
  };

  buyerIdentity = (): string => {
    const { order } = this.props;
    const identity: string[] = [];
    if (order.buyer.email) {
      identity.push(order.buyer.email);
    }
    if (order.buyer.phone) {
      identity.push(order.buyer.phone);
    }
    return identity.join(', ');
  };

  deliveryAmount = (): string => {
    const { order } = this.props;
    let deliveryAmount: number = 0;
    if (order.shippingItems) {
      order.shippingItems.forEach((item) => {
        deliveryAmount += item.price;
      });
    }
    return formatPrice(deliveryAmount, order.currencyCode, true);
  }

  startChatHandle = () => {
    const { loading } = this.props.chatReducer;
    if (loading) return;

    const { order, t } = this.props;
    const sellerPhone = order.seller.phone;
    const message = t(
      "Hi! I am contacting you with regards to my order No.",
      { orderNumber: order.orderNumber }
    );
    this.props.startChat(sellerPhone, message);
  };


  render() {
    const { t, order } = this.props;

    return (
      <Page
        id="order_details_page"
        name="order-details-page"
      >
        <Navbar
          title={t("Order Details")}
          backLink={t("Back").toString()}
          noHairline
          noShadow
        />

        <Block className="order-header">
          <Row>
            <Col width="100">
              <Badge
                className={classNames(
                  "order-status-badge",
                  `order-status-badge-${order.status.toString()}`
                )}
              >
                {order.statusDescription}
              </Badge>
            </Col>
          </Row>
          <Row>
            <Col width="100" className="order-header-info">
              <span>{t('Order')}:</span> {order.orderNumber}
              <br/>
              {order.orderDate && (
                <>
                  <span>{t('Order time')}:</span> {formatDateTime(order.orderDate)}
                </>
              )}
            </Col>
          </Row>
          <Row className="margin-top-half">
            <Col width="100">
              <b>{t('Contact')}</b>
              <br/>
              {this.buyer()}
              <br/>
              {this.buyerIdentity()}
              <br/>
              {this.shippingAddress()}
            </Col>
          </Row>
          <Row>
            <Col width="100">
              <b>{t('Delivery')}</b>
              <br/>
              {order.shippingItems && order.shippingItems.map((shippingItem: ProductOrderShippingItem) => (
                <span key={shippingItem.name}>
                    {shippingItem.name}: {formatPrice(shippingItem.price, order.currencyCode, true)}
                  </span>
              ))}
              {!order.shippingItems && (
                <span>{t('Free of charge')}</span>
              )}
              {/*
                <br/>
                {t('Estimated delivery time')}: 3-7 days
                */}
            </Col>
          </Row>
          <Row>
            <Col width="100">
              <b>{t('Seller')}</b>
              <br/>
              <Row>
                <Col width="80">
                  {order.seller.name}
                </Col>
                {order.seller.phone && (
                  <Col width="20" className="text-align-right">
                    <Link onClick={() => this.startChatHandle()}>
                      <Icon f7="chat_bubble_text"/>
                    </Link>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Block>

        <BlockTitle>{t('Order Info')}</BlockTitle>
        <Block className="order-content">
          <List mediaList noHairlines noChevron>
            {order.items.map((item: ProductOrderItem) => (
              <ListItem
                key={item.productUid}
                title={item.name}
              >
                {item.imageUrl && (<img slot="media" src={item.imageUrl}  alt={item.name} />)}
                <div slot="text">{item.description}</div>
                <div slot="inner-end" className="pure-visible-xs mobile-price-text">
                  <b>{formatPrice(item.price, order.currencyCode, true)}</b>&nbsp;x {item.quantity}
                </div>
              </ListItem>
            ))}
          </List>
        </Block>

        <BlockTitle>{t('Order Summary')}</BlockTitle>
        <Block className="order-footer">
          <Row>
            <Col width="50">
              {t('Items')}:
            </Col>
            <Col width="50" className="text-align-right">
              {order.productCount}
            </Col>
          </Row>
          <Row>
            <Col width="50">
              {t('Delivery')}:
            </Col>
            <Col width="50" className="text-align-right">
              {this.deliveryAmount()}
            </Col>
          </Row>
          <Row>
            <Col width="50" className="mobile-total-price">
              {t('Total')}:
            </Col>
            <Col width="50" className="text-align-right mobile-total-price">
              {formatPrice(order.amount, order.currencyCode, true)}
            </Col>
          </Row>
        </Block>
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  order: state.ordersReducer.selectedOrder,
});

export default compose(
  connect(mapStateToProps, null),
  connectChat,
  withTranslation()
)(OrderDetailsPage);
