import { AnyAction } from "redux";
import {
  ORDERS_PROMO_ORDER_CREATING,
  ORDERS_PROMO_ORDER_CREATING_SUCCESS,
  ORDERS_PROMO_ORDER_CREATING_ERROR,
  ORDERS_PRODUCTS_ORDER_CREATING,
  ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS,
  ORDERS_PRODUCTS_ORDER_CREATING_ERROR,
  ORDERS_LOADING,
  ORDERS_LOADING_SUCCESS,
  ORDERS_LOADING_ERROR,
  ORDER_DETAILS_SUCCESS,
  ORDER_CLEAR,
  ORDER_CLEAR_ERROR_MESSAGE,
} from "../actions/ordersActions";
import { PaymentApiOrder, ProductOrder } from "../types/paymentapi";

export interface IOrdersState {
  orderCreating?: boolean;
  orderCreatingError?: any;
  order: PaymentApiOrder;
  ordersLoading?: boolean;
  ordersLoadingError?: any;
  orders?: ProductOrder[];
  selectedOrder?: ProductOrder;
}

const initialState: IOrdersState = {
  orderCreating: false,
  orderCreatingError: null,
  ordersLoadingError: null,
  ordersLoading: false,
  orders: [],
  selectedOrder: null,
};

const ordersReducer = (state = initialState, action: AnyAction): IOrdersState => {
  switch (action.type) {
    case ORDERS_PROMO_ORDER_CREATING:
      return {
        ...state,
        orderCreating: true,
        orderCreatingError: null,
      };
    case ORDERS_PROMO_ORDER_CREATING_SUCCESS:
      return {
        ...state,
        orderCreating: false,
        order: action.order,
      };
    case ORDERS_PROMO_ORDER_CREATING_ERROR:
      return {
        ...state,
        orderCreating: false,
        orderCreatingError: action.error,
      };
    case ORDERS_PRODUCTS_ORDER_CREATING:
      return {
        ...state,
        orderCreating: true,
        orderCreatingError: null,
      };
    case ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS:
      return {
        ...state,
        orderCreating: false,
        orderCreatingError: null,
        order: action.order,
      };
    case ORDERS_PRODUCTS_ORDER_CREATING_ERROR:
      return {
        ...state,
        orderCreating: false,
        orderCreatingError: action.error,
      };
    case ORDERS_LOADING:
      return {
        ...state,
        ordersLoading: true,
        ordersLoadingError: null,
      };
    case ORDERS_LOADING_SUCCESS:
      let { orders } = action;
      return {
        ...state,
        ordersLoading: false,
        orders: orders,
        ordersLoadingError: null,
      };
    case ORDERS_LOADING_ERROR:
      return {
        ...state,
        ordersLoading: false,
        ordersLoadingError: action.error,
      };
    case ORDER_DETAILS_SUCCESS:
      let { order } = action;
      return {
        ...state,
        selectedOrder: order,
      };
    case ORDER_CLEAR:
      return {
        ...state,
        order: null,
        ordersLoadingError: null,
      };
    case ORDER_CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        orderCreatingError: null,
      };
    default:
      return state;
  }
};

export default ordersReducer;
