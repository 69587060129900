import React from "react";
import {
  ListItem,
  F7ListItem,
  F7SwipeoutActions,
  F7SwipeoutButton,
} from "framework7-react";
import { Price } from "../Price/index";
import { LikeButton } from "../LikeButton";
import { IProduct } from "../../reducers/productReducer";
import { getSubcategoryNameBySubcategoryId } from "../../utils";
import { ICategoryClassificator } from "../../reducers/categoryReducer";
import ProductStatusBadge from "../Badges/ProductStatusBadge";
import {
  DescriptionDetailsText,
  ViewDetails,
  WishDetails,
} from "../ProductDetails";
import { IcCrown } from "../../components-ui/icons";

export type SwipeoutActions = F7SwipeoutActions.Props & {
  buttons: F7SwipeoutButton.Props[];
};

type Props = F7ListItem.Props & {
  item: IProduct;
  categories?: ICategoryClassificator[];
  addToWish(uid: string): void;
  swipeoutActions?: SwipeoutActions[];
  simplePrice?: boolean;
  showStatus?: boolean;
  showAnalytics?: boolean;
  showFeaturesHiglight?: boolean;
};

/* TODO */
export const hasFeatureCode = (
  code: "higlight_bold" | "top_daily" | "vip",
  featureCodes: string[]
) => featureCodes && featureCodes.includes(code);

export default ({
  categories,
  item,
  item: {
    uid,
    name,
    shortDescription,
    category,
    price,
    currencyCode,
    discountedPrice,
    wish,
    imageThumbnailUrl1,
    status,
  },
  addToWish,
  onClick,
  onSwipeoutDeleted,
  swipeoutActions,
  simplePrice,
  showStatus,
  showAnalytics,
  showFeaturesHiglight,
  ...props
}: Props) => (
  <ListItem
    link={onClick ? "#" : `/product-details/${uid}/`}
    subtitle={
      showStatus ? "" : getSubcategoryNameBySubcategoryId(category, categories)
    }
    noChevron
    onClick={() => onClick && onClick(uid)}
    swipeout={swipeoutActions && swipeoutActions.length > 0}
    className={
      showFeaturesHiglight && hasFeatureCode("higlight_bold", item.featureCodes)
        ? "feature-higlight-bold"
        : ""
    }
    {...props}
  >
    <div slot="text">
      <DescriptionDetailsText text={shortDescription}/>
    </div>
    <div slot="inner-start" className="item-title-row">
      <div className="item-title">
        <div className="catalog-item-title">{name}</div>
      </div>
      {showFeaturesHiglight && hasFeatureCode("vip", item.featureCodes) && (
        <div className="feature-icon-crow">
          <IcCrown />
        </div>
      )}
    </div>
    <div slot="inner-end">
      <Price
        className={simplePrice ? "simple" : ""}
        {...{
          price,
          discountedPrice: simplePrice ? undefined : discountedPrice,
          currencyCode,
        }}
      />
    </div>
    <div slot="media" className="image">
      {imageThumbnailUrl1 && <img src={imageThumbnailUrl1} />}
    </div>
    {typeof addToWish === "function" && (
      <div slot="root-start" className="item-content a-pos">
        <div className="item-media">
          <div className="image">
            <LikeButton active={wish} onClick={() => addToWish(uid)} />
          </div>
        </div>
      </div>
    )}
    {showStatus && (
      <div slot="subtitle">
        <ProductStatusBadge status={status} statusText={item.statusValue} />
      </div>
    )}
    {showAnalytics && (
      <div slot="inner-end">
        <div className="product-stats">
          <ViewDetails count={item.viewCount} />
          <WishDetails count={item.wishCount} />
        </div>
      </div>
    )}
    {swipeoutActions && swipeoutActions.length
      ? swipeoutActions.map((_item, i) => (
          <F7SwipeoutActions key={i.toString()} {..._item}>
            {_item.buttons.map((__item, i) => (
              <F7SwipeoutButton
                key={i.toString()}
                {...{ ...__item, onClick: () => __item.onClick(item) }}
              />
            ))}
          </F7SwipeoutActions>
        ))
      : null}
  </ListItem>
);
