import React, { FC } from "react";
import { ListItem } from "framework7-react";

import "./style.less";

interface AddCardListItemProps {
  props: ListItem.Props;
}
const AddCardListItem: FC<AddCardListItemProps> = (props) => {
  return (
    <ListItem className="add-card-list-item" link {...props}>
      <span slot="media">
        <i className="icon ic-add-card" />
      </span>
    </ListItem>
  );
};

export default AddCardListItem;
