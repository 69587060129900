/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from "url";
import portableFetch from "../../axios/portableFetchWrapper";
import { Configuration } from "./configuration";

const BASE_PATH = "/paymentapi".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface AccountCallback
 */
export interface AccountCallback {
  /**
   *
   * @type {string}
   * @memberof AccountCallback
   */
  accessToken?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCallback
   */
  accountUid?: string;
  /**
   *
   * @type {string}
   * @memberof AccountCallback
   */
  callbackUrl?: string;
}

/**
 *
 * @export
 * @interface BankCardPaymentRequest
 */
export interface BankCardPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof BankCardPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof BankCardPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  merchantUid?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof BankCardPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentRequest
   */
  token?: string;
}

/**
 *
 * @export
 * @interface BankCardPaymentResponse
 */
export interface BankCardPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof BankCardPaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof BankCardPaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof BankCardPaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentResponse
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BankCardPaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof BankCardPaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface BankLinkPaymentRequest
 */
export interface BankLinkPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof BankLinkPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof BankLinkPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof BankLinkPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {PaymentPhone}
   * @memberof BankLinkPaymentRequest
   */
  phone?: PaymentPhone;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  pspCode?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentRequest
   */
  successUrl?: string;
}

/**
 *
 * @export
 * @interface BankLinkPaymentResponse
 */
export interface BankLinkPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof BankLinkPaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof BankLinkPaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof BankLinkPaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentResponse
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BankLinkPaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof BankLinkPaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface BankTransferPaymentRequest
 */
export interface BankTransferPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof BankTransferPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  bankAccountUid?: string;
  /**
   *
   * @type {number}
   * @memberof BankTransferPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof BankTransferPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {PaymentPhone}
   * @memberof BankTransferPaymentRequest
   */
  phone?: PaymentPhone;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentRequest
   */
  successUrl?: string;
}

/**
 *
 * @export
 * @interface BankTransferPaymentResponse
 */
export interface BankTransferPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof BankTransferPaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof BankTransferPaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof BankTransferPaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof BankTransferPaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof BankTransferPaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface BrandedCardPaymentRequest
 */
export interface BrandedCardPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof BrandedCardPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  cardUid?: string;
  /**
   *
   * @type {number}
   * @memberof BrandedCardPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof BrandedCardPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {PaymentPhone}
   * @memberof BrandedCardPaymentRequest
   */
  phone?: PaymentPhone;
  /**
   *
   * @type {string}
   * @memberof BrandedCardPaymentRequest
   */
  successUrl?: string;
}

/**
 *
 * @export
 * @interface CalculateExternalOrderResponse
 */
export interface CalculateExternalOrderResponse {
  /**
   *
   * @type {boolean}
   * @memberof CalculateExternalOrderResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof CalculateExternalOrderResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {number}
   * @memberof CalculateExternalOrderResponse
   */
  amountTotal?: number;
  /**
   *
   * @type {number}
   * @memberof CalculateExternalOrderResponse
   */
  bonusMoneyAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CalculateExternalOrderResponse
   */
  bonusMoneyAmountTotal?: number;
  /**
   *
   * @type {string}
   * @memberof CalculateExternalOrderResponse
   */
  currencyCode?: string;
  /**
   *
   * @type {PaymentApiOrderAddress}
   * @memberof CalculateExternalOrderResponse
   */
  deliveryAddress?: PaymentApiOrderAddress;
  /**
   *
   * @type {Date}
   * @memberof CalculateExternalOrderResponse
   */
  dueDate?: Date;
  /**
   *
   * @type {ErrorData}
   * @memberof CalculateExternalOrderResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<ExternalProductInfo>}
   * @memberof CalculateExternalOrderResponse
   */
  externalProductList?: Array<ExternalProductInfo>;
  /**
   *
   * @type {number}
   * @memberof CalculateExternalOrderResponse
   */
  maxBonusMoneyAmountForOrder?: number;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof CalculateExternalOrderResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof CalculateExternalOrderResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof CalculateExternalOrderResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof CalculateExternalOrderResponse
   */
  totalShippingPrice?: number;
  /**
   *
   * @type {WarningData}
   * @memberof CalculateExternalOrderResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface CallbackAccount
 */
export interface CallbackAccount {
  /**
   *
   * @type {CallbackAccountAddress}
   * @memberof CallbackAccount
   */
  address?: CallbackAccountAddress;
  /**
   *
   * @type {string}
   * @memberof CallbackAccount
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackAccount
   */
  ipAddress?: string;
  /**
   *
   * @type {CallbackAccountPhone}
   * @memberof CallbackAccount
   */
  phone?: CallbackAccountPhone;
}

/**
 *
 * @export
 * @interface CallbackAccountAddress
 */
export interface CallbackAccountAddress {
  /**
   *
   * @type {string}
   * @memberof CallbackAccountAddress
   */
  countryCode?: string;
}

/**
 *
 * @export
 * @interface CallbackAccountPhone
 */
export interface CallbackAccountPhone {
  /**
   *
   * @type {string}
   * @memberof CallbackAccountPhone
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackAccountPhone
   */
  number?: string;
}

/**
 *
 * @export
 * @interface CallbackData
 */
export interface CallbackData {
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  addressName?: string;
  /**
   *
   * @type {Money}
   * @memberof CallbackData
   */
  amount?: Money;
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  hash?: string;
  /**
   *
   * @type {Money}
   * @memberof CallbackData
   */
  nativeAmount?: Money;
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  network?: CallbackData.NetworkEnum;
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  status?: CallbackData.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof CallbackData
   */
  type?: CallbackData.TypeEnum;
}

/**
 * @export
 * @namespace CallbackData
 */
export namespace CallbackData {
  /**
   * @export
   * @enum {string}
   */
  export enum NetworkEnum {
    BTC = <any>"BTC",
    ETH = <any>"ETH",
    LTC = <any>"LTC",
    BCH = <any>"BCH",
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PENDING = <any>"PENDING",
    COMPLETED = <any>"COMPLETED",
    FAILED = <any>"FAILED",
    CANCELED = <any>"CANCELED",
    EXPIRED = <any>"EXPIRED",
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    BUY = <any>"BUY",
    SELL = <any>"SELL",
    SEND = <any>"SEND",
    TRANSFER = <any>"TRANSFER",
    DEPOSIT = <any>"DEPOSIT",
    WITHDRAWAL = <any>"WITHDRAWAL",
  }
}

/**
 *
 * @export
 * @interface CallbackTransaction
 */
export interface CallbackTransaction {
  /**
   *
   * @type {string}
   * @memberof CallbackTransaction
   */
  action?: string;
  /**
   *
   * @type {number}
   * @memberof CallbackTransaction
   */
  amount?: number;
  /**
   *
   * @type {Date}
   * @memberof CallbackTransaction
   */
  date?: Date;
  /**
   *
   * @type {CallbackTransactionError}
   * @memberof CallbackTransaction
   */
  error?: CallbackTransactionError;
  /**
   *
   * @type {Array<CallbackTransactionParam>}
   * @memberof CallbackTransaction
   */
  parameters?: Array<CallbackTransactionParam>;
  /**
   *
   * @type {CallbackAccount}
   * @memberof CallbackTransaction
   */
  payer?: CallbackAccount;
  /**
   *
   * @type {string}
   * @memberof CallbackTransaction
   */
  paymentMethodCode?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackTransaction
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackTransaction
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackTransaction
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface CallbackTransactionError
 */
export interface CallbackTransactionError {
  /**
   *
   * @type {string}
   * @memberof CallbackTransactionError
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackTransactionError
   */
  message?: string;
}

/**
 *
 * @export
 * @interface CallbackTransactionParam
 */
export interface CallbackTransactionParam {
  /**
   *
   * @type {string}
   * @memberof CallbackTransactionParam
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CallbackTransactionParam
   */
  value?: string;
}

/**
 *
 * @export
 * @interface CancelRecurringSubscriptionRequest
 */
export interface CancelRecurringSubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof CancelRecurringSubscriptionRequest
   */
  subscriptionUid?: string;
}

/**
 *
 * @export
 * @interface CardToCardPaymentRequest
 */
export interface CardToCardPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof CardToCardPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  destinationAccountUid?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  destinationCardToken?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  errorUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  sourceCardToken?: string;
  /**
   *
   * @type {string}
   * @memberof CardToCardPaymentRequest
   */
  successUrl?: string;
}

/**
 *
 * @export
 * @interface CardUidPaymentRequest
 */
export interface CardUidPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof CardUidPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  cardUid?: string;
  /**
   *
   * @type {number}
   * @memberof CardUidPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof CardUidPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {PaymentPhone}
   * @memberof CardUidPaymentRequest
   */
  phone?: PaymentPhone;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CardUidPaymentRequest
   */
  token?: string;
}

/**
 *
 * @export
 * @interface CheckPaymentRequest
 */
export interface CheckPaymentRequest {
  /**
   *
   * @type {string}
   * @memberof CheckPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {string}
   * @memberof CheckPaymentRequest
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof CheckPaymentRequest
   */
  transactionUid?: string;
}

/**
 *
 * @export
 * @interface CreateCryptoOrderRequest
 */
export interface CreateCryptoOrderRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCryptoOrderRequest
   */
  cryptoCurrencies?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCryptoOrderRequest
   */
  currencies?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCryptoOrderRequest
   */
  operationTypes?: Array<CreateCryptoOrderRequest.OperationTypesEnum>;
}

/**
 * @export
 * @namespace CreateCryptoOrderRequest
 */
export namespace CreateCryptoOrderRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum OperationTypesEnum {
    BUY = <any>"BUY",
    SELL = <any>"SELL",
  }
}

/**
 *
 * @export
 * @interface CreateExternalProductsPurchaseOrderRequest
 */
export interface CreateExternalProductsPurchaseOrderRequest {
  /**
   *
   * @type {number}
   * @memberof CreateExternalProductsPurchaseOrderRequest
   */
  bonusMoneyAmount?: number;
  /**
   *
   * @type {PaymentApiOrderAddress}
   * @memberof CreateExternalProductsPurchaseOrderRequest
   */
  deliveryAddress?: PaymentApiOrderAddress;
  /**
   *
   * @type {string}
   * @memberof CreateExternalProductsPurchaseOrderRequest
   */
  dueDate?: string;
  /**
   *
   * @type {Array<ExternalProductInfo>}
   * @memberof CreateExternalProductsPurchaseOrderRequest
   */
  externalProductList?: Array<ExternalProductInfo>;
  /**
   *
   * @type {string}
   * @memberof CreateExternalProductsPurchaseOrderRequest
   */
  paymentMethod?: string;
}

/**
 *
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
  /**
   *
   * @type {PaymentApiOrder}
   * @memberof CreateOrderRequest
   */
  order?: PaymentApiOrder;
}

/**
 *
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof CreateOrderResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {PaymentApiOrder}
   * @memberof CreateOrderResponse
   */
  order?: PaymentApiOrder;
  /**
   *
   * @type {string}
   * @memberof CreateOrderResponse
   */
  redirectUrl?: string;
  /**
   *
   * @type {WarningData}
   * @memberof CreateOrderResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface CreateProductFeatureOrderRequest
 */
export interface CreateProductFeatureOrderRequest {
  /**
   *
   * @type {string}
   * @memberof CreateProductFeatureOrderRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreateProductFeatureOrderRequest
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof CreateProductFeatureOrderRequest
   */
  productUid?: string;
}

/**
 *
 * @export
 * @interface CreateProductOrderResponse
 */
export interface CreateProductOrderResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof CreateProductOrderResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {ProductOrder}
   * @memberof CreateProductOrderResponse
   */
  order?: ProductOrder;
  /**
   *
   * @type {string}
   * @memberof CreateProductOrderResponse
   */
  redirectUrl?: string;
  /**
   *
   * @type {WarningData}
   * @memberof CreateProductOrderResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface CreateProductsPurchaseOrderRequest
 */
export interface CreateProductsPurchaseOrderRequest {
  /**
   *
   * @type {number}
   * @memberof CreateProductsPurchaseOrderRequest
   */
  bonusMoneyAmount?: number;
  /**
   *
   * @type {PaymentApiOrderAddress}
   * @memberof CreateProductsPurchaseOrderRequest
   */
  deliveryAddress?: PaymentApiOrderAddress;
  /**
   *
   * @type {string}
   * @memberof CreateProductsPurchaseOrderRequest
   */
  dueDate?: string;
  /**
   *
   * @type {Array<ProductPurchaseItem>}
   * @memberof CreateProductsPurchaseOrderRequest
   */
  products?: Array<ProductPurchaseItem>;
}

/**
 *
 * @export
 * @interface CreditCardPaymentRequest
 */
export interface CreditCardPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof CreditCardPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof CreditCardPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof CreditCardPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {PaymentPhone}
   * @memberof CreditCardPaymentRequest
   */
  phone?: PaymentPhone;
  /**
   *
   * @type {boolean}
   * @memberof CreditCardPaymentRequest
   */
  saveCard?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentRequest
   */
  token?: string;
}

/**
 *
 * @export
 * @interface CreditCardPaymentResponse
 */
export interface CreditCardPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreditCardPaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof CreditCardPaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof CreditCardPaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentResponse
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreditCardPaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof CreditCardPaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface EWalletPaymentRequest
 */
export interface EWalletPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof EWalletPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof EWalletPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  iban?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof EWalletPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentRequest
   */
  wallet?: string;
}

/**
 *
 * @export
 * @interface EWalletPaymentResponse
 */
export interface EWalletPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof EWalletPaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof EWalletPaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof EWalletPaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletPaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof EWalletPaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface ErrorData
 */
export interface ErrorData {
  /**
   *
   * @type {number}
   * @memberof ErrorData
   */
  errorCode?: number;
  /**
   *
   * @type {string}
   * @memberof ErrorData
   */
  errorMessage?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ErrorData
   */
  parameters?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ErrorData
   */
  requestUid?: string;
}

/**
 *
 * @export
 * @interface ExternalProductInfo
 */
export interface ExternalProductInfo {
  /**
   *
   * @type {string}
   * @memberof ExternalProductInfo
   */
  buyerCurrencyCode?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalProductInfo
   */
  buyerPrice?: number;
  /**
   *
   * @type {number}
   * @memberof ExternalProductInfo
   */
  calculatedPrice?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalProductInfo
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalProductInfo
   */
  productIndex?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalProductInfo
   */
  productUrl?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalProductInfo
   */
  quantity?: number;
}

/**
 *
 * @export
 * @interface GetOrderDetailsRequest
 */
export interface GetOrderDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof GetOrderDetailsRequest
   */
  externalOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrderDetailsRequest
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface GetOrderListRequest
 */
export interface GetOrderListRequest {
  /**
   *
   * @type {string}
   * @memberof GetOrderListRequest
   */
  dateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrderListRequest
   */
  dateTo?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrderListRequest
   */
  status?: string;
}

/**
 *
 * @export
 * @interface GetTokenRequest
 */
export interface GetTokenRequest {
  /**
   *
   * @type {string}
   * @memberof GetTokenRequest
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof GetTokenRequest
   */
  refreshToken?: string;
  /**
   *
   * @type {string}
   * @memberof GetTokenRequest
   */
  username?: string;
}

/**
 *
 * @export
 * @interface GetTokenResponse
 */
export interface GetTokenResponse {
  /**
   *
   * @type {string}
   * @memberof GetTokenResponse
   */
  accessToken?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof GetTokenResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {number}
   * @memberof GetTokenResponse
   */
  expiresIn?: number;
  /**
   *
   * @type {string}
   * @memberof GetTokenResponse
   */
  refreshToken?: string;
  /**
   *
   * @type {WarningData}
   * @memberof GetTokenResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface MakeRecurringPaymentRequest
 */
export interface MakeRecurringPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof MakeRecurringPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof MakeRecurringPaymentRequest
   */
  externalOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof MakeRecurringPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {string}
   * @memberof MakeRecurringPaymentRequest
   */
  subscriptionUid?: string;
}

/**
 *
 * @export
 * @interface MapstringTariffItem
 */
export interface MapstringTariffItem {
  [key: string]: TariffItem;
}

/**
 *
 * @export
 * @interface MerchantCallback
 */
export interface MerchantCallback {
  /**
   *
   * @type {number}
   * @memberof MerchantCallback
   */
  amountTotal?: number;
  /**
   *
   * @type {CallbackAccount}
   * @memberof MerchantCallback
   */
  buyer?: CallbackAccount;
  /**
   *
   * @type {string}
   * @memberof MerchantCallback
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantCallback
   */
  externalOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantCallback
   */
  merchantUrl?: string;
  /**
   *
   * @type {Date}
   * @memberof MerchantCallback
   */
  recurringExpirationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof MerchantCallback
   */
  recurringMaxAmountPerTrx?: number;
  /**
   *
   * @type {number}
   * @memberof MerchantCallback
   */
  recurringMinIntervalDays?: number;
  /**
   *
   * @type {CallbackAccount}
   * @memberof MerchantCallback
   */
  seller?: CallbackAccount;
  /**
   *
   * @type {string}
   * @memberof MerchantCallback
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantCallback
   */
  subscriptionUid?: string;
  /**
   *
   * @type {boolean}
   * @memberof MerchantCallback
   */
  testOrder?: boolean;
  /**
   *
   * @type {Array<CallbackTransaction>}
   * @memberof MerchantCallback
   */
  transactions?: Array<CallbackTransaction>;
  /**
   *
   * @type {string}
   * @memberof MerchantCallback
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface Money
 */
export interface Money {
  /**
   *
   * @type {string}
   * @memberof Money
   */
  amount?: string;
  /**
   *
   * @type {string}
   * @memberof Money
   */
  currency?: string;
}

/**
 *
 * @export
 * @interface OrderListResponse
 */
export interface OrderListResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof OrderListResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<MerchantCallback>}
   * @memberof OrderListResponse
   */
  orderList?: Array<MerchantCallback>;
  /**
   *
   * @type {WarningData}
   * @memberof OrderListResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof OrderResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {PaymentApiOrder}
   * @memberof OrderResponse
   */
  order?: PaymentApiOrder;
  /**
   *
   * @type {WarningData}
   * @memberof OrderResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface OrderStorageObject
 */
export interface OrderStorageObject {
  /**
   *
   * @type {string}
   * @memberof OrderStorageObject
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OrderStorageObject
   */
  url?: string;
}

/**
 *
 * @export
 * @interface OrderStorageObjectListResponse
 */
export interface OrderStorageObjectListResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof OrderStorageObjectListResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<OrderStorageObject>}
   * @memberof OrderStorageObjectListResponse
   */
  objectList?: Array<OrderStorageObject>;
  /**
   *
   * @type {string}
   * @memberof OrderStorageObjectListResponse
   */
  orderUid?: string;
  /**
   *
   * @type {WarningData}
   * @memberof OrderStorageObjectListResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface OrderStorageRequest
 */
export interface OrderStorageRequest {
  /**
   *
   * @type {string}
   * @memberof OrderStorageRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OrderStorageRequest
   */
  orderUid?: string;
}

/**
 *
 * @export
 * @interface PaymentActionConfirmationRequest
 */
export interface PaymentActionConfirmationRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentActionConfirmationRequest
   */
  batchUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentActionConfirmationRequest
   */
  confirmationCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentActionConfirmationRequest
   */
  orderUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentActionConfirmationRequest
   */
  paymentUid?: string;
}

/**
 *
 * @export
 * @interface PaymentActionConfirmationResponse
 */
export interface PaymentActionConfirmationResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof PaymentActionConfirmationResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentResponse>}
   * @memberof PaymentActionConfirmationResponse
   */
  responseList?: Array<PaymentResponse>;
  /**
   *
   * @type {WarningData}
   * @memberof PaymentActionConfirmationResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface PaymentApiOrder
 */
export interface PaymentApiOrder {
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiOrder
   */
  acceptPaymentsIfOrderExpired?: boolean;
  /**
   *
   * @type {PaymentApiOrderAffiliateInfo}
   * @memberof PaymentApiOrder
   */
  affiliateInfo?: PaymentApiOrderAffiliateInfo;
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiOrder
   */
  allowPartialPayments?: boolean;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  amountPayed?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  amountTotal?: number;
  /**
   *
   * @type {Array<PaymentApiOrderCurrency>}
   * @memberof PaymentApiOrder
   */
  availableCurrencyList?: Array<PaymentApiOrderCurrency>;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  batchUid?: string;
  /**
   *
   * @type {PaymentApiOrderAccount}
   * @memberof PaymentApiOrder
   */
  buyer?: PaymentApiOrderAccount;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  currencyCode?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  discountAmount?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  discountAmountCalculated?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  discountPercent?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  dueDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  externalOrderId?: string;
  /**
   *
   * @type {PaymentApiOrderExternalPayout}
   * @memberof PaymentApiOrder
   */
  externalPayout?: PaymentApiOrderExternalPayout;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  firstPartialPaymentMinAmountPrc?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  issueDate?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  itemsAmountSubtotal?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  itemsAmountTotal?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  itemsDiscountAmount?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  itemsTaxAmount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  lastRefundDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  moneyRequestUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  note?: string;
  /**
   *
   * @type {Array<PaymentApiOrderItem>}
   * @memberof PaymentApiOrder
   */
  orderItems?: Array<PaymentApiOrderItem>;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  orderNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  paymentFormUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  paymentPageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  provisionChannel?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiOrder
   */
  recurring?: boolean;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  recurringExpirationDate?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  recurringMaxAmountPerTrx?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  recurringMinIntervalDays?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  reference?: string;
  /**
   *
   * @type {PaymentApiOrderAccount}
   * @memberof PaymentApiOrder
   */
  seller?: PaymentApiOrderAccount;
  /**
   *
   * @type {PaymentApiOrderAddress}
   * @memberof PaymentApiOrder
   */
  shippingAddress?: PaymentApiOrderAddress;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  subscriptionUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  successUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiOrder
   */
  taxBeforeDiscount?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiOrder
   */
  taxInclusive?: boolean;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  terms?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrder
   */
  totalRefundAmount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrder
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderAccount
 */
export interface PaymentApiOrderAccount {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  additionalInfo?: string;
  /**
   *
   * @type {PaymentApiOrderAddress}
   * @memberof PaymentApiOrderAccount
   */
  address?: PaymentApiOrderAddress;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  birthDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  externalUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  iban?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  name?: string;
  /**
   *
   * @type {PaymentApiOrderAccountPhone}
   * @memberof PaymentApiOrderAccount
   */
  phone?: PaymentApiOrderAccountPhone;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  registrationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  taxpayerIdentificationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccount
   */
  website?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderAccountPhone
 */
export interface PaymentApiOrderAccountPhone {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccountPhone
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccountPhone
   */
  fullNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAccountPhone
   */
  number?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderAddress
 */
export interface PaymentApiOrderAddress {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  firstAddressLine?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  secondAddressLine?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAddress
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderAffiliateInfo
 */
export interface PaymentApiOrderAffiliateInfo {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAffiliateInfo
   */
  affiliateId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAffiliateInfo
   */
  bannerId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAffiliateInfo
   */
  campaignId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderAffiliateInfo
   */
  customParameters?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderCurrency
 */
export interface PaymentApiOrderCurrency {
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderCurrency
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderCurrency
   */
  currencyCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiOrderCurrency
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderCurrency
   */
  rate?: number;
}

/**
 *
 * @export
 * @interface PaymentApiOrderExternalPayout
 */
export interface PaymentApiOrderExternalPayout {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderExternalPayout
   */
  bankAccountUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderExternalPayout
   */
  linkedCardUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderExternalPayout
   */
  paymentMethod?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderExternalPayout
   */
  walletUid?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderItem
 */
export interface PaymentApiOrderItem {
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  amountSubtotal?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  amountTotal?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItem
   */
  cryptoAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItem
   */
  cryptoCurrencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItem
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  discountAmount?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  discountAmountCalculated?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  discountPercent?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItem
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  orderDiscountAmountPerItemCalculated?: number;
  /**
   *
   * @type {Array<PaymentApiOrderItemParam>}
   * @memberof PaymentApiOrderItem
   */
  parameters?: Array<PaymentApiOrderItemParam>;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  priceUnit?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItem
   */
  productUid?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  quantity?: number;
  /**
   *
   * @type {PaymentApiOrderItemTax}
   * @memberof PaymentApiOrderItem
   */
  tax?: PaymentApiOrderItemTax;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItem
   */
  taxAmount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItem
   */
  type?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderItemParam
 */
export interface PaymentApiOrderItemParam {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItemParam
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItemParam
   */
  value?: string;
}

/**
 *
 * @export
 * @interface PaymentApiOrderItemTax
 */
export interface PaymentApiOrderItemTax {
  /**
   *
   * @type {string}
   * @memberof PaymentApiOrderItemTax
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentApiOrderItemTax
   */
  rate?: number;
}

/**
 *
 * @export
 * @interface PaymentApiProject
 */
export interface PaymentApiProject {
  /**
   *
   * @type {string}
   * @memberof PaymentApiProject
   */
  buildDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiProject
   */
  currentTime?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiProject
   */
  projectVersion?: string;
}

/**
 *
 * @export
 * @interface PaymentApiRate
 */
export interface PaymentApiRate {
  /**
   *
   * @type {Money}
   * @memberof PaymentApiRate
   */
  amount?: Money;
  /**
   *
   * @type {string}
   * @memberof PaymentApiRate
   */
  currencyPair?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiRate
   */
  fromCurrency?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiRate
   */
  operationType?: PaymentApiRate.OperationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentApiRate
   */
  toCurrency?: string;
}

/**
 * @export
 * @namespace PaymentApiRate
 */
export namespace PaymentApiRate {
  /**
   * @export
   * @enum {string}
   */
  export enum OperationTypeEnum {
    BUY = <any>"BUY",
    SELL = <any>"SELL",
  }
}

/**
 *
 * @export
 * @interface PaymentApiResponse
 */
export interface PaymentApiResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof PaymentApiResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {WarningData}
   * @memberof PaymentApiResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface PaymentApiSimpleOrder
 */
export interface PaymentApiSimpleOrder {
  /**
   *
   * @type {number}
   * @memberof PaymentApiSimpleOrder
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  checksum?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  dueDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  externalOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  merchantUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  orderNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentApiSimpleOrder
   */
  recurring?: boolean;
  /**
   *
   * @type {Date}
   * @memberof PaymentApiSimpleOrder
   */
  recurringExpirationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof PaymentApiSimpleOrder
   */
  recurringMaxAmountPerTrx?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentApiSimpleOrder
   */
  recurringMinIntervalDays?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  subscriptionUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApiSimpleOrder
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface PaymentAttribute
 */
export interface PaymentAttribute {
  /**
   *
   * @type {string}
   * @memberof PaymentAttribute
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAttribute
   */
  value?: string;
}

/**
 *
 * @export
 * @interface PaymentParameter
 */
export interface PaymentParameter {
  /**
   *
   * @type {string}
   * @memberof PaymentParameter
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentParameter
   */
  value?: string;
}

/**
 *
 * @export
 * @interface PaymentPhone
 */
export interface PaymentPhone {
  /**
   *
   * @type {string}
   * @memberof PaymentPhone
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPhone
   */
  fullNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPhone
   */
  number?: string;
}

/**
 *
 * @export
 * @interface PaymentResponse
 */
export interface PaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof PaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof PaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof PaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof PaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof PaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof PaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface ProductOrder
 */
export interface ProductOrder {
  /**
   *
   * @type {number}
   * @memberof ProductOrder
   */
  amount?: number;
  /**
   *
   * @type {ProductOrderBuyer}
   * @memberof ProductOrder
   */
  buyer?: ProductOrderBuyer;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  estimatedDeliveryDate?: string;
  /**
   *
   * @type {Array<ProductOrderItem>}
   * @memberof ProductOrder
   */
  items?: Array<ProductOrderItem>;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  lastRefundDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  orderDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  orderNumber?: string;
  /**
   *
   * @type {number}
   * @memberof ProductOrder
   */
  productCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProductOrder
   */
  rent?: boolean;
  /**
   *
   * @type {ProductOrderSeller}
   * @memberof ProductOrder
   */
  seller?: ProductOrderSeller;
  /**
   *
   * @type {ProductOrderShippingAddress}
   * @memberof ProductOrder
   */
  shippingAddress?: ProductOrderShippingAddress;
  /**
   *
   * @type {Array<ProductOrderShippingItem>}
   * @memberof ProductOrder
   */
  shippingItems?: Array<ProductOrderShippingItem>;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  statusDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  statusExtended?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  statusExtendedComments?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  statusExtendedDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  statusExtendedDescription?: string;
  /**
   *
   * @type {number}
   * @memberof ProductOrder
   */
  totalRefundAmount?: number;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  trackingCarrier?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  trackingNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  trackingUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrder
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface ProductOrderBuyer
 */
export interface ProductOrderBuyer {
  /**
   *
   * @type {string}
   * @memberof ProductOrderBuyer
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderBuyer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderBuyer
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderBuyer
   */
  surname?: string;
}

/**
 *
 * @export
 * @interface ProductOrderItem
 */
export interface ProductOrderItem {
  /**
   *
   * @type {string}
   * @memberof ProductOrderItem
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderItem
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderItem
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ProductOrderItem
   */
  parameters?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof ProductOrderItem
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof ProductOrderItem
   */
  productUid?: string;
  /**
   *
   * @type {number}
   * @memberof ProductOrderItem
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof ProductOrderItem
   */
  type?: string;
}

/**
 *
 * @export
 * @interface ProductOrderListResponse
 */
export interface ProductOrderListResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof ProductOrderListResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<ProductOrder>}
   * @memberof ProductOrderListResponse
   */
  orderList?: Array<ProductOrder>;
  /**
   *
   * @type {WarningData}
   * @memberof ProductOrderListResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface ProductOrderSeller
 */
export interface ProductOrderSeller {
  /**
   *
   * @type {string}
   * @memberof ProductOrderSeller
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderSeller
   */
  phone?: string;
}

/**
 *
 * @export
 * @interface ProductOrderShippingAddress
 */
export interface ProductOrderShippingAddress {
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  countryName?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  fullAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingAddress
   */
  state?: string;
}

/**
 *
 * @export
 * @interface ProductOrderShippingItem
 */
export interface ProductOrderShippingItem {
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingItem
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOrderShippingItem
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProductOrderShippingItem
   */
  price?: number;
}

/**
 *
 * @export
 * @interface ProductPurchaseItem
 */
export interface ProductPurchaseItem {
  /**
   *
   * @type {string}
   * @memberof ProductPurchaseItem
   */
  deliveryMethodOptionUid?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ProductPurchaseItem
   */
  parameters?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof ProductPurchaseItem
   */
  productUid?: string;
  /**
   *
   * @type {number}
   * @memberof ProductPurchaseItem
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof ProductPurchaseItem
   */
  rentTimeFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ProductPurchaseItem
   */
  rentTimeTo?: string;
}

/**
 *
 * @export
 * @interface PurchaseExternalPaymentRequest
 */
export interface PurchaseExternalPaymentRequest {
  /**
   *
   * @type {number}
   * @memberof PurchaseExternalPaymentRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PurchaseExternalPaymentRequest
   */
  commission?: number;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentRequest
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentRequest
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentRequest
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentRequest
   */
  orderUid?: string;
  /**
   *
   * @type {Array<PaymentAttribute>}
   * @memberof PurchaseExternalPaymentRequest
   */
  parameters?: Array<PaymentAttribute>;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentRequest
   */
  paymentMethod?: string;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentRequest
   */
  walletUid?: string;
}

/**
 *
 * @export
 * @interface PurchaseExternalPaymentResponse
 */
export interface PurchaseExternalPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof PurchaseExternalPaymentResponse
   */
  actionConfirmation?: boolean;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentResponse
   */
  actionConfirmationType?: string;
  /**
   *
   * @type {ErrorData}
   * @memberof PurchaseExternalPaymentResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentParameter>}
   * @memberof PurchaseExternalPaymentResponse
   */
  parameters?: Array<PaymentParameter>;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentResponse
   */
  paymentUid?: string;
  /**
   *
   * @type {string}
   * @memberof PurchaseExternalPaymentResponse
   */
  status?: string;
  /**
   *
   * @type {WarningData}
   * @memberof PurchaseExternalPaymentResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface Rate
 */
export interface Rate {
  /**
   *
   * @type {Money}
   * @memberof Rate
   */
  amount?: Money;
  /**
   *
   * @type {string}
   * @memberof Rate
   */
  currencyPair?: string;
}

/**
 *
 * @export
 * @interface RateListResponse
 */
export interface RateListResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof RateListResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Array<PaymentApiRate>}
   * @memberof RateListResponse
   */
  rates?: Array<PaymentApiRate>;
  /**
   *
   * @type {WarningData}
   * @memberof RateListResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface RateResponse
 */
export interface RateResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof RateResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {Rate}
   * @memberof RateResponse
   */
  rate?: Rate;
  /**
   *
   * @type {WarningData}
   * @memberof RateResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface RefundOrderRequest
 */
export interface RefundOrderRequest {
  /**
   *
   * @type {number}
   * @memberof RefundOrderRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof RefundOrderRequest
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof RefundOrderRequest
   */
  publicTransactionId?: string;
  /**
   *
   * @type {Array<RefundedItem>}
   * @memberof RefundOrderRequest
   */
  refundedItems?: Array<RefundedItem>;
  /**
   *
   * @type {string}
   * @memberof RefundOrderRequest
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface RefundedItem
 */
export interface RefundedItem {
  /**
   *
   * @type {string}
   * @memberof RefundedItem
   */
  itemUid?: string;
  /**
   *
   * @type {number}
   * @memberof RefundedItem
   */
  quantity?: number;
}

/**
 *
 * @export
 * @interface SetOrderStatusRequest
 */
export interface SetOrderStatusRequest {
  /**
   *
   * @type {string}
   * @memberof SetOrderStatusRequest
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof SetOrderStatusRequest
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface SetProductOrderStatusRequest
 */
export interface SetProductOrderStatusRequest {
  /**
   *
   * @type {Date}
   * @memberof SetProductOrderStatusRequest
   */
  estimatedDeliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof SetProductOrderStatusRequest
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof SetProductOrderStatusRequest
   */
  statusExtended?: string;
  /**
   *
   * @type {string}
   * @memberof SetProductOrderStatusRequest
   */
  statusExtendedComments?: string;
  /**
   *
   * @type {string}
   * @memberof SetProductOrderStatusRequest
   */
  trackingCarrier?: string;
  /**
   *
   * @type {string}
   * @memberof SetProductOrderStatusRequest
   */
  trackingNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SetProductOrderStatusRequest
   */
  uid?: string;
}

/**
 *
 * @export
 * @interface TariffCurrencyResponse
 */
export interface TariffCurrencyResponse {
  /**
   *
   * @type {{ [key: string]: Array<TariffItem>; }}
   * @memberof TariffCurrencyResponse
   */
  currency?: { [key: string]: Array<TariffItem> };
  /**
   *
   * @type {ErrorData}
   * @memberof TariffCurrencyResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {string}
   * @memberof TariffCurrencyResponse
   */
  orderUid?: string;
  /**
   *
   * @type {number}
   * @memberof TariffCurrencyResponse
   */
  originalOrderAmount?: number;
  /**
   *
   * @type {WarningData}
   * @memberof TariffCurrencyResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface TariffItem
 */
export interface TariffItem {
  /**
   *
   * @type {string}
   * @memberof TariffItem
   */
  actionType?: string;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  amountFrom?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  amountMax?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  amountMin?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  amountReceived?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  amountSent?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  amountTo?: number;
  /**
   *
   * @type {string}
   * @memberof TariffItem
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  commission?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  commissionAmountAdditional?: number;
  /**
   *
   * @type {number}
   * @memberof TariffItem
   */
  commissionPercent?: number;
  /**
   *
   * @type {string}
   * @memberof TariffItem
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof TariffItem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TariffItem
   */
  paymentMethod?: string;
  /**
   *
   * @type {string}
   * @memberof TariffItem
   */
  psp?: string;
}

/**
 *
 * @export
 * @interface TariffListResponse
 */
export interface TariffListResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof TariffListResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {string}
   * @memberof TariffListResponse
   */
  orderUid?: string;
  /**
   *
   * @type {number}
   * @memberof TariffListResponse
   */
  originalOrderAmount?: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: TariffItem; }; }}
   * @memberof TariffListResponse
   */
  tariffList?: { [key: string]: { [key: string]: TariffItem } };
  /**
   *
   * @type {WarningData}
   * @memberof TariffListResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface TariffResponse
 */
export interface TariffResponse {
  /**
   *
   * @type {ErrorData}
   * @memberof TariffResponse
   */
  errorData?: ErrorData;
  /**
   *
   * @type {string}
   * @memberof TariffResponse
   */
  orderUid?: string;
  /**
   *
   * @type {number}
   * @memberof TariffResponse
   */
  originalOrderAmount?: number;
  /**
   *
   * @type {{ [key: string]: TariffItem; }}
   * @memberof TariffResponse
   */
  paymentMethods?: { [key: string]: TariffItem };
  /**
   *
   * @type {WarningData}
   * @memberof TariffResponse
   */
  warning?: WarningData;
}

/**
 *
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
  /**
   *
   * @type {string}
   * @memberof TokenRequest
   */
  accessToken?: string;
}

/**
 *
 * @export
 * @interface WarningData
 */
export interface WarningData {
  /**
   *
   * @type {number}
   * @memberof WarningData
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof WarningData
   */
  message?: string;
}

/**
 * AdminControllerApi - fetch parameter creator
 * @export
 */
export const AdminControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary createDraftOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDraftOrderUsingPOST(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createDraftOrderUsingPOST."
        );
      }
      const localVarPath = `/admin/order/draft/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createOrderUsingPOST."
        );
      }
      const localVarPath = `/admin/order/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary deleteDraftOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDraftOrderUsingPOST(request: OrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteDraftOrderUsingPOST."
        );
      }
      const localVarPath = `/admin/order/draft/delete`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary sendDraftOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendDraftOrderUsingPOST(request: OrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling sendDraftOrderUsingPOST."
        );
      }
      const localVarPath = `/admin/order/draft/send`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary updateDraftOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDraftOrderUsingPOST(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateDraftOrderUsingPOST."
        );
      }
      const localVarPath = `/admin/order/draft/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary createDraftOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDraftOrderUsingPOST(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).createDraftOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).createOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary deleteDraftOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDraftOrderUsingPOST(
      request: OrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiResponse> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deleteDraftOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary sendDraftOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendDraftOrderUsingPOST(
      request: OrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).sendDraftOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary updateDraftOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDraftOrderUsingPOST(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateDraftOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary createDraftOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDraftOrderUsingPOST(request: CreateOrderRequest, options?: any) {
      return AdminControllerApiFp(configuration).createDraftOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST(request: CreateOrderRequest, options?: any) {
      return AdminControllerApiFp(configuration).createOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary deleteDraftOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDraftOrderUsingPOST(request: OrderRequest, options?: any) {
      return AdminControllerApiFp(configuration).deleteDraftOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary sendDraftOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendDraftOrderUsingPOST(request: OrderRequest, options?: any) {
      return AdminControllerApiFp(configuration).sendDraftOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary updateDraftOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDraftOrderUsingPOST(request: CreateOrderRequest, options?: any) {
      return AdminControllerApiFp(configuration).updateDraftOrderUsingPOST(request, options)(fetch, basePath);
    },
  };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
  /**
   *
   * @summary createDraftOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public createDraftOrderUsingPOST(request: CreateOrderRequest, options?: any) {
    return AdminControllerApiFp(this.configuration).createDraftOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public createOrderUsingPOST(request: CreateOrderRequest, options?: any) {
    return AdminControllerApiFp(this.configuration).createOrderUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary deleteDraftOrder
   * @param {OrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public deleteDraftOrderUsingPOST(request: OrderRequest, options?: any) {
    return AdminControllerApiFp(this.configuration).deleteDraftOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary sendDraftOrder
   * @param {OrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public sendDraftOrderUsingPOST(request: OrderRequest, options?: any) {
    return AdminControllerApiFp(this.configuration).sendDraftOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary updateDraftOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public updateDraftOrderUsingPOST(request: CreateOrderRequest, options?: any) {
    return AdminControllerApiFp(this.configuration).updateDraftOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * AuthWsControllerApi - fetch parameter creator
 * @export
 */
export const AuthWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary checkToken
     * @param {TokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTokenUsingPOST(request: TokenRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling checkTokenUsingPOST."
        );
      }
      const localVarPath = `/auth/token/check`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"TokenRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary expireToken
     * @param {TokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireTokenUsingPOST(request: TokenRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling expireTokenUsingPOST."
        );
      }
      const localVarPath = `/auth/token/expire`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"TokenRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getToken
     * @param {GetTokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTokenUsingPOST(request: GetTokenRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getTokenUsingPOST."
        );
      }
      const localVarPath = `/auth/token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetTokenRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary refreshToken
     * @param {GetTokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenUsingPOST(request: GetTokenRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling refreshTokenUsingPOST."
        );
      }
      const localVarPath = `/auth/token/refresh`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetTokenRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthWsControllerApi - functional programming interface
 * @export
 */
export const AuthWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary checkToken
     * @param {TokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTokenUsingPOST(
      request: TokenRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
      const localVarFetchArgs = AuthWsControllerApiFetchParamCreator(configuration).checkTokenUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary expireToken
     * @param {TokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireTokenUsingPOST(
      request: TokenRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiResponse> {
      const localVarFetchArgs = AuthWsControllerApiFetchParamCreator(configuration).expireTokenUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getToken
     * @param {GetTokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTokenUsingPOST(
      request: GetTokenRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
      const localVarFetchArgs = AuthWsControllerApiFetchParamCreator(configuration).getTokenUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary refreshToken
     * @param {GetTokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenUsingPOST(
      request: GetTokenRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
      const localVarFetchArgs = AuthWsControllerApiFetchParamCreator(configuration).refreshTokenUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * AuthWsControllerApi - factory interface
 * @export
 */
export const AuthWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary checkToken
     * @param {TokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTokenUsingPOST(request: TokenRequest, options?: any) {
      return AuthWsControllerApiFp(configuration).checkTokenUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary expireToken
     * @param {TokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireTokenUsingPOST(request: TokenRequest, options?: any) {
      return AuthWsControllerApiFp(configuration).expireTokenUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary getToken
     * @param {GetTokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTokenUsingPOST(request: GetTokenRequest, options?: any) {
      return AuthWsControllerApiFp(configuration).getTokenUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary refreshToken
     * @param {GetTokenRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenUsingPOST(request: GetTokenRequest, options?: any) {
      return AuthWsControllerApiFp(configuration).refreshTokenUsingPOST(request, options)(fetch, basePath);
    },
  };
};

/**
 * AuthWsControllerApi - object-oriented interface
 * @export
 * @class AuthWsControllerApi
 * @extends {BaseAPI}
 */
export class AuthWsControllerApi extends BaseAPI {
  /**
   *
   * @summary checkToken
   * @param {TokenRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthWsControllerApi
   */
  public checkTokenUsingPOST(request: TokenRequest, options?: any) {
    return AuthWsControllerApiFp(this.configuration).checkTokenUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary expireToken
   * @param {TokenRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthWsControllerApi
   */
  public expireTokenUsingPOST(request: TokenRequest, options?: any) {
    return AuthWsControllerApiFp(this.configuration).expireTokenUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary getToken
   * @param {GetTokenRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthWsControllerApi
   */
  public getTokenUsingPOST(request: GetTokenRequest, options?: any) {
    return AuthWsControllerApiFp(this.configuration).getTokenUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary refreshToken
   * @param {GetTokenRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthWsControllerApi
   */
  public refreshTokenUsingPOST(request: GetTokenRequest, options?: any) {
    return AuthWsControllerApiFp(this.configuration).refreshTokenUsingPOST(request, options)(this.fetch, this.basePath);
  }
}

/**
 * BankLinkPaymentWsControllerApi - fetch parameter creator
 * @export
 */
export const BankLinkPaymentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary paymentAnonymous
     * @param {BankLinkPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentAnonymousUsingPOST(request: BankLinkPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentAnonymousUsingPOST."
        );
      }
      const localVarPath = `/payment/banklink`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"BankLinkPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary paymentRegistered
     * @param {BankLinkPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentRegisteredUsingPOST(request: BankLinkPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentRegisteredUsingPOST."
        );
      }
      const localVarPath = `/payment/banklink/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"BankLinkPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BankLinkPaymentWsControllerApi - functional programming interface
 * @export
 */
export const BankLinkPaymentWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary paymentAnonymous
     * @param {BankLinkPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentAnonymousUsingPOST(
      request: BankLinkPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BankLinkPaymentResponse> {
      const localVarFetchArgs = BankLinkPaymentWsControllerApiFetchParamCreator(
        configuration
      ).paymentAnonymousUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary paymentRegistered
     * @param {BankLinkPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentRegisteredUsingPOST(
      request: BankLinkPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BankLinkPaymentResponse> {
      const localVarFetchArgs = BankLinkPaymentWsControllerApiFetchParamCreator(
        configuration
      ).paymentRegisteredUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * BankLinkPaymentWsControllerApi - factory interface
 * @export
 */
export const BankLinkPaymentWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary paymentAnonymous
     * @param {BankLinkPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentAnonymousUsingPOST(request: BankLinkPaymentRequest, options?: any) {
      return BankLinkPaymentWsControllerApiFp(configuration).paymentAnonymousUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary paymentRegistered
     * @param {BankLinkPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentRegisteredUsingPOST(request: BankLinkPaymentRequest, options?: any) {
      return BankLinkPaymentWsControllerApiFp(configuration).paymentRegisteredUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * BankLinkPaymentWsControllerApi - object-oriented interface
 * @export
 * @class BankLinkPaymentWsControllerApi
 * @extends {BaseAPI}
 */
export class BankLinkPaymentWsControllerApi extends BaseAPI {
  /**
   *
   * @summary paymentAnonymous
   * @param {BankLinkPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankLinkPaymentWsControllerApi
   */
  public paymentAnonymousUsingPOST(request: BankLinkPaymentRequest, options?: any) {
    return BankLinkPaymentWsControllerApiFp(this.configuration).paymentAnonymousUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary paymentRegistered
   * @param {BankLinkPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankLinkPaymentWsControllerApi
   */
  public paymentRegisteredUsingPOST(request: BankLinkPaymentRequest, options?: any) {
    return BankLinkPaymentWsControllerApiFp(this.configuration).paymentRegisteredUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * BankTransferPaymentWsControllerApi - fetch parameter creator
 * @export
 */
export const BankTransferPaymentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary withdraw
     * @param {BankTransferPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUsingPOST(request: BankTransferPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling withdrawUsingPOST."
        );
      }
      const localVarPath = `/withdraw/banktransfer`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"BankTransferPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BankTransferPaymentWsControllerApi - functional programming interface
 * @export
 */
export const BankTransferPaymentWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary withdraw
     * @param {BankTransferPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUsingPOST(
      request: BankTransferPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BankTransferPaymentResponse> {
      const localVarFetchArgs = BankTransferPaymentWsControllerApiFetchParamCreator(configuration).withdrawUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * BankTransferPaymentWsControllerApi - factory interface
 * @export
 */
export const BankTransferPaymentWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary withdraw
     * @param {BankTransferPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUsingPOST(request: BankTransferPaymentRequest, options?: any) {
      return BankTransferPaymentWsControllerApiFp(configuration).withdrawUsingPOST(request, options)(fetch, basePath);
    },
  };
};

/**
 * BankTransferPaymentWsControllerApi - object-oriented interface
 * @export
 * @class BankTransferPaymentWsControllerApi
 * @extends {BaseAPI}
 */
export class BankTransferPaymentWsControllerApi extends BaseAPI {
  /**
   *
   * @summary withdraw
   * @param {BankTransferPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankTransferPaymentWsControllerApi
   */
  public withdrawUsingPOST(request: BankTransferPaymentRequest, options?: any) {
    return BankTransferPaymentWsControllerApiFp(this.configuration).withdrawUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * BitcoinCallbackControllerApi - fetch parameter creator
 * @export
 */
export const BitcoinCallbackControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary blockChainCallback
     * @param {string} order order
     * @param {number} [confirmations] confirmations
     * @param {string} [secret] secret
     * @param {string} [transactionHash] transaction_hash
     * @param {number} [value] value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockChainCallbackUsingGET(
      order: string,
      confirmations?: number,
      secret?: string,
      transactionHash?: string,
      value?: number,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'order' is not null or undefined
      if (order === null || order === undefined) {
        throw new RequiredError(
          "order",
          "Required parameter order was null or undefined when calling blockChainCallbackUsingGET."
        );
      }
      const localVarPath = `/callback/blockchain`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (confirmations !== undefined) {
        localVarQueryParameter["confirmations"] = confirmations;
      }

      if (order !== undefined) {
        localVarQueryParameter["order"] = order;
      }

      if (secret !== undefined) {
        localVarQueryParameter["secret"] = secret;
      }

      if (transactionHash !== undefined) {
        localVarQueryParameter["transaction_hash"] = transactionHash;
      }

      if (value !== undefined) {
        localVarQueryParameter["value"] = value;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BitcoinCallbackControllerApi - functional programming interface
 * @export
 */
export const BitcoinCallbackControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary blockChainCallback
     * @param {string} order order
     * @param {number} [confirmations] confirmations
     * @param {string} [secret] secret
     * @param {string} [transactionHash] transaction_hash
     * @param {number} [value] value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockChainCallbackUsingGET(
      order: string,
      confirmations?: number,
      secret?: string,
      transactionHash?: string,
      value?: number,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = BitcoinCallbackControllerApiFetchParamCreator(configuration).blockChainCallbackUsingGET(
        order,
        confirmations,
        secret,
        transactionHash,
        value,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * BitcoinCallbackControllerApi - factory interface
 * @export
 */
export const BitcoinCallbackControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary blockChainCallback
     * @param {string} order order
     * @param {number} [confirmations] confirmations
     * @param {string} [secret] secret
     * @param {string} [transactionHash] transaction_hash
     * @param {number} [value] value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockChainCallbackUsingGET(
      order: string,
      confirmations?: number,
      secret?: string,
      transactionHash?: string,
      value?: number,
      options?: any
    ) {
      return BitcoinCallbackControllerApiFp(configuration).blockChainCallbackUsingGET(
        order,
        confirmations,
        secret,
        transactionHash,
        value,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * BitcoinCallbackControllerApi - object-oriented interface
 * @export
 * @class BitcoinCallbackControllerApi
 * @extends {BaseAPI}
 */
export class BitcoinCallbackControllerApi extends BaseAPI {
  /**
   *
   * @summary blockChainCallback
   * @param {string} order order
   * @param {number} [confirmations] confirmations
   * @param {string} [secret] secret
   * @param {string} [transactionHash] transaction_hash
   * @param {number} [value] value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BitcoinCallbackControllerApi
   */
  public blockChainCallbackUsingGET(
    order: string,
    confirmations?: number,
    secret?: string,
    transactionHash?: string,
    value?: number,
    options?: any
  ) {
    return BitcoinCallbackControllerApiFp(this.configuration).blockChainCallbackUsingGET(
      order,
      confirmations,
      secret,
      transactionHash,
      value,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * CallbackControllerApi - fetch parameter creator
 * @export
 */
export const CallbackControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary accountCallback
     * @param {AccountCallback} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCallbackUsingPOST(callback: AccountCallback, options: any = {}): FetchArgs {
      // verify required parameter 'callback' is not null or undefined
      if (callback === null || callback === undefined) {
        throw new RequiredError(
          "callback",
          "Required parameter callback was null or undefined when calling accountCallbackUsingPOST."
        );
      }
      const localVarPath = `/callback/account`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"AccountCallback" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(callback || {}) : callback || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary corsysCallback
     * @param {MerchantCallback} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corsysCallbackUsingPOST(callback: MerchantCallback, options: any = {}): FetchArgs {
      // verify required parameter 'callback' is not null or undefined
      if (callback === null || callback === undefined) {
        throw new RequiredError(
          "callback",
          "Required parameter callback was null or undefined when calling corsysCallbackUsingPOST."
        );
      }
      const localVarPath = `/callback/corsys`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"MerchantCallback" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(callback || {}) : callback || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CallbackControllerApi - functional programming interface
 * @export
 */
export const CallbackControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary accountCallback
     * @param {AccountCallback} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCallbackUsingPOST(
      callback: AccountCallback,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CallbackControllerApiFetchParamCreator(configuration).accountCallbackUsingPOST(
        callback,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary corsysCallback
     * @param {MerchantCallback} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corsysCallbackUsingPOST(
      callback: MerchantCallback,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CallbackControllerApiFetchParamCreator(configuration).corsysCallbackUsingPOST(
        callback,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CallbackControllerApi - factory interface
 * @export
 */
export const CallbackControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary accountCallback
     * @param {AccountCallback} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCallbackUsingPOST(callback: AccountCallback, options?: any) {
      return CallbackControllerApiFp(configuration).accountCallbackUsingPOST(callback, options)(fetch, basePath);
    },
    /**
     *
     * @summary corsysCallback
     * @param {MerchantCallback} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corsysCallbackUsingPOST(callback: MerchantCallback, options?: any) {
      return CallbackControllerApiFp(configuration).corsysCallbackUsingPOST(callback, options)(fetch, basePath);
    },
  };
};

/**
 * CallbackControllerApi - object-oriented interface
 * @export
 * @class CallbackControllerApi
 * @extends {BaseAPI}
 */
export class CallbackControllerApi extends BaseAPI {
  /**
   *
   * @summary accountCallback
   * @param {AccountCallback} callback callback
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallbackControllerApi
   */
  public accountCallbackUsingPOST(callback: AccountCallback, options?: any) {
    return CallbackControllerApiFp(this.configuration).accountCallbackUsingPOST(callback, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary corsysCallback
   * @param {MerchantCallback} callback callback
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CallbackControllerApi
   */
  public corsysCallbackUsingPOST(callback: MerchantCallback, options?: any) {
    return CallbackControllerApiFp(this.configuration).corsysCallbackUsingPOST(callback, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * CreditCardPaymentWsControllerApi - fetch parameter creator
 * @export
 */
export const CreditCardPaymentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary cardToCardTransfer
     * @param {CardToCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardToCardTransferUsingPOST(request: CardToCardPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling cardToCardTransferUsingPOST."
        );
      }
      const localVarPath = `/payment/card2card`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CardToCardPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary payment
     * @param {CardUidPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST(request: CardUidPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentUsingPOST."
        );
      }
      const localVarPath = `/payment/carduid`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CardUidPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary payment
     * @param {CreditCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST1(request: CreditCardPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentUsingPOST1."
        );
      }
      const localVarPath = `/payment/creditcard`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreditCardPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary withdrawIssuedCard
     * @param {BrandedCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawIssuedCardUsingPOST(request: BrandedCardPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling withdrawIssuedCardUsingPOST."
        );
      }
      const localVarPath = `/withdraw/issuedcard`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"BrandedCardPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary withdraw
     * @param {CardUidPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUsingPOST1(request: CardUidPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling withdrawUsingPOST1."
        );
      }
      const localVarPath = `/withdraw/creditcard`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CardUidPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreditCardPaymentWsControllerApi - functional programming interface
 * @export
 */
export const CreditCardPaymentWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary cardToCardTransfer
     * @param {CardToCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardToCardTransferUsingPOST(
      request: CardToCardPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardPaymentResponse> {
      const localVarFetchArgs = CreditCardPaymentWsControllerApiFetchParamCreator(
        configuration
      ).cardToCardTransferUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary payment
     * @param {CardUidPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST(
      request: CardUidPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardPaymentResponse> {
      const localVarFetchArgs = CreditCardPaymentWsControllerApiFetchParamCreator(configuration).paymentUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary payment
     * @param {CreditCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST1(
      request: CreditCardPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardPaymentResponse> {
      const localVarFetchArgs = CreditCardPaymentWsControllerApiFetchParamCreator(configuration).paymentUsingPOST1(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary withdrawIssuedCard
     * @param {BrandedCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawIssuedCardUsingPOST(
      request: BrandedCardPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardPaymentResponse> {
      const localVarFetchArgs = CreditCardPaymentWsControllerApiFetchParamCreator(
        configuration
      ).withdrawIssuedCardUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary withdraw
     * @param {CardUidPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUsingPOST1(
      request: CardUidPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardPaymentResponse> {
      const localVarFetchArgs = CreditCardPaymentWsControllerApiFetchParamCreator(configuration).withdrawUsingPOST1(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CreditCardPaymentWsControllerApi - factory interface
 * @export
 */
export const CreditCardPaymentWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary cardToCardTransfer
     * @param {CardToCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardToCardTransferUsingPOST(request: CardToCardPaymentRequest, options?: any) {
      return CreditCardPaymentWsControllerApiFp(configuration).cardToCardTransferUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary payment
     * @param {CardUidPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST(request: CardUidPaymentRequest, options?: any) {
      return CreditCardPaymentWsControllerApiFp(configuration).paymentUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary payment
     * @param {CreditCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST1(request: CreditCardPaymentRequest, options?: any) {
      return CreditCardPaymentWsControllerApiFp(configuration).paymentUsingPOST1(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary withdrawIssuedCard
     * @param {BrandedCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawIssuedCardUsingPOST(request: BrandedCardPaymentRequest, options?: any) {
      return CreditCardPaymentWsControllerApiFp(configuration).withdrawIssuedCardUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary withdraw
     * @param {CardUidPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUsingPOST1(request: CardUidPaymentRequest, options?: any) {
      return CreditCardPaymentWsControllerApiFp(configuration).withdrawUsingPOST1(request, options)(fetch, basePath);
    },
  };
};

/**
 * CreditCardPaymentWsControllerApi - object-oriented interface
 * @export
 * @class CreditCardPaymentWsControllerApi
 * @extends {BaseAPI}
 */
export class CreditCardPaymentWsControllerApi extends BaseAPI {
  /**
   *
   * @summary cardToCardTransfer
   * @param {CardToCardPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditCardPaymentWsControllerApi
   */
  public cardToCardTransferUsingPOST(request: CardToCardPaymentRequest, options?: any) {
    return CreditCardPaymentWsControllerApiFp(this.configuration).cardToCardTransferUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary payment
   * @param {CardUidPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditCardPaymentWsControllerApi
   */
  public paymentUsingPOST(request: CardUidPaymentRequest, options?: any) {
    return CreditCardPaymentWsControllerApiFp(this.configuration).paymentUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary payment
   * @param {CreditCardPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditCardPaymentWsControllerApi
   */
  public paymentUsingPOST1(request: CreditCardPaymentRequest, options?: any) {
    return CreditCardPaymentWsControllerApiFp(this.configuration).paymentUsingPOST1(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary withdrawIssuedCard
   * @param {BrandedCardPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditCardPaymentWsControllerApi
   */
  public withdrawIssuedCardUsingPOST(request: BrandedCardPaymentRequest, options?: any) {
    return CreditCardPaymentWsControllerApiFp(this.configuration).withdrawIssuedCardUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary withdraw
   * @param {CardUidPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditCardPaymentWsControllerApi
   */
  public withdrawUsingPOST1(request: CardUidPaymentRequest, options?: any) {
    return CreditCardPaymentWsControllerApiFp(this.configuration).withdrawUsingPOST1(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * CryptoApiCallbackControllerApi - fetch parameter creator
 * @export
 */
export const CryptoApiCallbackControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary processCallback
     * @param {CallbackData} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCallbackUsingPOST(callback: CallbackData, options: any = {}): FetchArgs {
      // verify required parameter 'callback' is not null or undefined
      if (callback === null || callback === undefined) {
        throw new RequiredError(
          "callback",
          "Required parameter callback was null or undefined when calling processCallbackUsingPOST."
        );
      }
      const localVarPath = `/callback/cryptoapi`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CallbackData" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(callback || {}) : callback || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CryptoApiCallbackControllerApi - functional programming interface
 * @export
 */
export const CryptoApiCallbackControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary processCallback
     * @param {CallbackData} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCallbackUsingPOST(
      callback: CallbackData,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
      const localVarFetchArgs = CryptoApiCallbackControllerApiFetchParamCreator(configuration).processCallbackUsingPOST(
        callback,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CryptoApiCallbackControllerApi - factory interface
 * @export
 */
export const CryptoApiCallbackControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary processCallback
     * @param {CallbackData} callback callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCallbackUsingPOST(callback: CallbackData, options?: any) {
      return CryptoApiCallbackControllerApiFp(configuration).processCallbackUsingPOST(callback, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * CryptoApiCallbackControllerApi - object-oriented interface
 * @export
 * @class CryptoApiCallbackControllerApi
 * @extends {BaseAPI}
 */
export class CryptoApiCallbackControllerApi extends BaseAPI {
  /**
   *
   * @summary processCallback
   * @param {CallbackData} callback callback
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CryptoApiCallbackControllerApi
   */
  public processCallbackUsingPOST(callback: CallbackData, options?: any) {
    return CryptoApiCallbackControllerApiFp(this.configuration).processCallbackUsingPOST(callback, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * EWalletPaymentWsControllerApi - fetch parameter creator
 * @export
 */
export const EWalletPaymentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary payment
     * @param {EWalletPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST2(request: EWalletPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentUsingPOST2."
        );
      }
      const localVarPath = `/payment/ewallet`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"EWalletPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EWalletPaymentWsControllerApi - functional programming interface
 * @export
 */
export const EWalletPaymentWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary payment
     * @param {EWalletPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST2(
      request: EWalletPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<EWalletPaymentResponse> {
      const localVarFetchArgs = EWalletPaymentWsControllerApiFetchParamCreator(configuration).paymentUsingPOST2(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * EWalletPaymentWsControllerApi - factory interface
 * @export
 */
export const EWalletPaymentWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary payment
     * @param {EWalletPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST2(request: EWalletPaymentRequest, options?: any) {
      return EWalletPaymentWsControllerApiFp(configuration).paymentUsingPOST2(request, options)(fetch, basePath);
    },
  };
};

/**
 * EWalletPaymentWsControllerApi - object-oriented interface
 * @export
 * @class EWalletPaymentWsControllerApi
 * @extends {BaseAPI}
 */
export class EWalletPaymentWsControllerApi extends BaseAPI {
  /**
   *
   * @summary payment
   * @param {EWalletPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EWalletPaymentWsControllerApi
   */
  public paymentUsingPOST2(request: EWalletPaymentRequest, options?: any) {
    return EWalletPaymentWsControllerApiFp(this.configuration).paymentUsingPOST2(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * OrderDraftWsControllerApi - fetch parameter creator
 * @export
 */
export const OrderDraftWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST1(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createOrderUsingPOST1."
        );
      }
      const localVarPath = `/order/draft/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary deleteOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderUsingPOST(request: OrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteOrderUsingPOST."
        );
      }
      const localVarPath = `/order/draft/delete`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary sendOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendOrderUsingPOST(request: OrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling sendOrderUsingPOST."
        );
      }
      const localVarPath = `/order/draft/send`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary updateOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderUsingPOST(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling updateOrderUsingPOST."
        );
      }
      const localVarPath = `/order/draft/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderDraftWsControllerApi - functional programming interface
 * @export
 */
export const OrderDraftWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST1(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderDraftWsControllerApiFetchParamCreator(configuration).createOrderUsingPOST1(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary deleteOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderUsingPOST(
      request: OrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiResponse> {
      const localVarFetchArgs = OrderDraftWsControllerApiFetchParamCreator(configuration).deleteOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary sendOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendOrderUsingPOST(
      request: OrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderDraftWsControllerApiFetchParamCreator(configuration).sendOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary updateOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderUsingPOST(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderDraftWsControllerApiFetchParamCreator(configuration).updateOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * OrderDraftWsControllerApi - factory interface
 * @export
 */
export const OrderDraftWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST1(request: CreateOrderRequest, options?: any) {
      return OrderDraftWsControllerApiFp(configuration).createOrderUsingPOST1(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary deleteOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderUsingPOST(request: OrderRequest, options?: any) {
      return OrderDraftWsControllerApiFp(configuration).deleteOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary sendOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendOrderUsingPOST(request: OrderRequest, options?: any) {
      return OrderDraftWsControllerApiFp(configuration).sendOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary updateOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderUsingPOST(request: CreateOrderRequest, options?: any) {
      return OrderDraftWsControllerApiFp(configuration).updateOrderUsingPOST(request, options)(fetch, basePath);
    },
  };
};

/**
 * OrderDraftWsControllerApi - object-oriented interface
 * @export
 * @class OrderDraftWsControllerApi
 * @extends {BaseAPI}
 */
export class OrderDraftWsControllerApi extends BaseAPI {
  /**
   *
   * @summary createOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDraftWsControllerApi
   */
  public createOrderUsingPOST1(request: CreateOrderRequest, options?: any) {
    return OrderDraftWsControllerApiFp(this.configuration).createOrderUsingPOST1(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary deleteOrder
   * @param {OrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDraftWsControllerApi
   */
  public deleteOrderUsingPOST(request: OrderRequest, options?: any) {
    return OrderDraftWsControllerApiFp(this.configuration).deleteOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary sendOrder
   * @param {OrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDraftWsControllerApi
   */
  public sendOrderUsingPOST(request: OrderRequest, options?: any) {
    return OrderDraftWsControllerApiFp(this.configuration).sendOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary updateOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDraftWsControllerApi
   */
  public updateOrderUsingPOST(request: CreateOrderRequest, options?: any) {
    return OrderDraftWsControllerApiFp(this.configuration).updateOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * OrderWsControllerApi - fetch parameter creator
 * @export
 */
export const OrderWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary calculateExternalProductsPurchaseOrder
     * @param {CreateExternalProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateExternalProductsPurchaseOrderUsingPOST(
      request: CreateExternalProductsPurchaseOrderRequest,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling calculateExternalProductsPurchaseOrderUsingPOST."
        );
      }
      const localVarPath = `/order/products/external/calculate`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateExternalProductsPurchaseOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createBuyOrder
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBuyOrderUsingPOST(request: CreateCryptoOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createBuyOrderUsingPOST."
        );
      }
      const localVarPath = `/crypto/order/create/buy`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateCryptoOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createExternalProductsPurchaseOrder
     * @param {CreateExternalProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExternalProductsPurchaseOrderUsingPOST(
      request: CreateExternalProductsPurchaseOrderRequest,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createExternalProductsPurchaseOrderUsingPOST."
        );
      }
      const localVarPath = `/order/products/external/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateExternalProductsPurchaseOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST2(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createOrderUsingPOST2."
        );
      }
      const localVarPath = `/merchant/order/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST3(request: CreateOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createOrderUsingPOST3."
        );
      }
      const localVarPath = `/order/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createProductFeatureOrder
     * @param {CreateProductFeatureOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductFeatureOrderUsingPOST(request: CreateProductFeatureOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createProductFeatureOrderUsingPOST."
        );
      }
      const localVarPath = `/order/product-feature/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateProductFeatureOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createProductsPurchaseOrder
     * @param {CreateProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductsPurchaseOrderUsingPOST(request: CreateProductsPurchaseOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createProductsPurchaseOrderUsingPOST."
        );
      }
      const localVarPath = `/order/products/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateProductsPurchaseOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createSellOrder
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSellOrderUsingPOST(request: CreateCryptoOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling createSellOrderUsingPOST."
        );
      }
      const localVarPath = `/crypto/order/create/sell`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateCryptoOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getOrderDetails
     * @param {GetOrderDetailsRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderDetailsUsingPOST(request: GetOrderDetailsRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getOrderDetailsUsingPOST."
        );
      }
      const localVarPath = `/order/details`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetOrderDetailsRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getOrderList
     * @param {GetOrderListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderListUsingPOST(request: GetOrderListRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getOrderListUsingPOST."
        );
      }
      const localVarPath = `/order/list`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"GetOrderListRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getOrder
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderUsingGET(uid: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError("uid", "Required parameter uid was null or undefined when calling getOrderUsingGET.");
      }
      const localVarPath = `/merchant/order/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getOrder
     * @param {string} uid uid
     * @param {boolean} [full] full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderUsingGET1(uid: string, full?: boolean, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError("uid", "Required parameter uid was null or undefined when calling getOrderUsingGET1.");
      }
      const localVarPath = `/order/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (full !== undefined) {
        localVarQueryParameter["full"] = full;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getProductOrderList
     * @param {boolean} [buyer] buyer
     * @param {string} [language] language
     * @param {string} [orderNumber] orderNumber
     * @param {string} [orderStatus] orderStatus
     * @param {boolean} [seller] seller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductOrderListUsingGET(
      buyer?: boolean,
      language?: string,
      orderNumber?: string,
      orderStatus?: string,
      seller?: boolean,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/order/products/list`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (buyer !== undefined) {
        localVarQueryParameter["buyer"] = buyer;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      if (orderNumber !== undefined) {
        localVarQueryParameter["orderNumber"] = orderNumber;
      }

      if (orderStatus !== undefined) {
        localVarQueryParameter["orderStatus"] = orderStatus;
      }

      if (seller !== undefined) {
        localVarQueryParameter["seller"] = seller;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary refundOrder
     * @param {RefundOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refundOrderUsingPOST(request: RefundOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling refundOrderUsingPOST."
        );
      }
      const localVarPath = `/order/refund`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"RefundOrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary remindOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remindOrderUsingPOST(request: OrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling remindOrderUsingPOST."
        );
      }
      const localVarPath = `/order/remind`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OrderRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary setOrderStatus
     * @param {SetOrderStatusRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setOrderStatusUsingPOST(request: SetOrderStatusRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling setOrderStatusUsingPOST."
        );
      }
      const localVarPath = `/order/status`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"SetOrderStatusRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary setProductOrderStatus
     * @param {SetProductOrderStatusRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProductOrderStatusUsingPOST(
      request: SetProductOrderStatusRequest,
      language?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling setProductOrderStatusUsingPOST."
        );
      }
      const localVarPath = `/order/product/status`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"SetProductOrderStatusRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderWsControllerApi - functional programming interface
 * @export
 */
export const OrderWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary calculateExternalProductsPurchaseOrder
     * @param {CreateExternalProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateExternalProductsPurchaseOrderUsingPOST(
      request: CreateExternalProductsPurchaseOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CalculateExternalOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(
        configuration
      ).calculateExternalProductsPurchaseOrderUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createBuyOrder
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBuyOrderUsingPOST(
      request: CreateCryptoOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createBuyOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createExternalProductsPurchaseOrder
     * @param {CreateExternalProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExternalProductsPurchaseOrderUsingPOST(
      request: CreateExternalProductsPurchaseOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(
        configuration
      ).createExternalProductsPurchaseOrderUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST2(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createOrderUsingPOST2(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST3(
      request: CreateOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createOrderUsingPOST3(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createProductFeatureOrder
     * @param {CreateProductFeatureOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductFeatureOrderUsingPOST(
      request: CreateProductFeatureOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createProductFeatureOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createProductsPurchaseOrder
     * @param {CreateProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductsPurchaseOrderUsingPOST(
      request: CreateProductsPurchaseOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateProductOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(
        configuration
      ).createProductsPurchaseOrderUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary createSellOrder
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSellOrderUsingPOST(
      request: CreateCryptoOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createSellOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getOrderDetails
     * @param {GetOrderDetailsRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderDetailsUsingPOST(
      request: GetOrderDetailsRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderListResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderDetailsUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getOrderList
     * @param {GetOrderListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderListUsingPOST(
      request: GetOrderListRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderListResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderListUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getOrder
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderUsingGET(uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderUsingGET(uid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getOrder
     * @param {string} uid uid
     * @param {boolean} [full] full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderUsingGET1(
      uid: string,
      full?: boolean,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderUsingGET1(
        uid,
        full,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getProductOrderList
     * @param {boolean} [buyer] buyer
     * @param {string} [language] language
     * @param {string} [orderNumber] orderNumber
     * @param {string} [orderStatus] orderStatus
     * @param {boolean} [seller] seller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductOrderListUsingGET(
      buyer?: boolean,
      language?: string,
      orderNumber?: string,
      orderStatus?: string,
      seller?: boolean,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ProductOrderListResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getProductOrderListUsingGET(
        buyer,
        language,
        orderNumber,
        orderStatus,
        seller,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary refundOrder
     * @param {RefundOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refundOrderUsingPOST(
      request: RefundOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).refundOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary remindOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remindOrderUsingPOST(
      request: OrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).remindOrderUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary setOrderStatus
     * @param {SetOrderStatusRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setOrderStatusUsingPOST(
      request: SetOrderStatusRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).setOrderStatusUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary setProductOrderStatus
     * @param {SetProductOrderStatusRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProductOrderStatusUsingPOST(
      request: SetProductOrderStatusRequest,
      language?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ProductOrderListResponse> {
      const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).setProductOrderStatusUsingPOST(
        request,
        language,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * OrderWsControllerApi - factory interface
 * @export
 */
export const OrderWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary calculateExternalProductsPurchaseOrder
     * @param {CreateExternalProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateExternalProductsPurchaseOrderUsingPOST(
      request: CreateExternalProductsPurchaseOrderRequest,
      options?: any
    ) {
      return OrderWsControllerApiFp(configuration).calculateExternalProductsPurchaseOrderUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary createBuyOrder
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBuyOrderUsingPOST(request: CreateCryptoOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createBuyOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary createExternalProductsPurchaseOrder
     * @param {CreateExternalProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExternalProductsPurchaseOrderUsingPOST(request: CreateExternalProductsPurchaseOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createExternalProductsPurchaseOrderUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST2(request: CreateOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createOrderUsingPOST2(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary createOrder
     * @param {CreateOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST3(request: CreateOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createOrderUsingPOST3(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary createProductFeatureOrder
     * @param {CreateProductFeatureOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductFeatureOrderUsingPOST(request: CreateProductFeatureOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createProductFeatureOrderUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary createProductsPurchaseOrder
     * @param {CreateProductsPurchaseOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductsPurchaseOrderUsingPOST(request: CreateProductsPurchaseOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createProductsPurchaseOrderUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
    /**
     *
     * @summary createSellOrder
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSellOrderUsingPOST(request: CreateCryptoOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).createSellOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary getOrderDetails
     * @param {GetOrderDetailsRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderDetailsUsingPOST(request: GetOrderDetailsRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).getOrderDetailsUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary getOrderList
     * @param {GetOrderListRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderListUsingPOST(request: GetOrderListRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).getOrderListUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary getOrder
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderUsingGET(uid: string, options?: any) {
      return OrderWsControllerApiFp(configuration).getOrderUsingGET(uid, options)(fetch, basePath);
    },
    /**
     *
     * @summary getOrder
     * @param {string} uid uid
     * @param {boolean} [full] full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderUsingGET1(uid: string, full?: boolean, options?: any) {
      return OrderWsControllerApiFp(configuration).getOrderUsingGET1(uid, full, options)(fetch, basePath);
    },
    /**
     *
     * @summary getProductOrderList
     * @param {boolean} [buyer] buyer
     * @param {string} [language] language
     * @param {string} [orderNumber] orderNumber
     * @param {string} [orderStatus] orderStatus
     * @param {boolean} [seller] seller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductOrderListUsingGET(
      buyer?: boolean,
      language?: string,
      orderNumber?: string,
      orderStatus?: string,
      seller?: boolean,
      options?: any
    ) {
      return OrderWsControllerApiFp(configuration).getProductOrderListUsingGET(
        buyer,
        language,
        orderNumber,
        orderStatus,
        seller,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary refundOrder
     * @param {RefundOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refundOrderUsingPOST(request: RefundOrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).refundOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary remindOrder
     * @param {OrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remindOrderUsingPOST(request: OrderRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).remindOrderUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary setOrderStatus
     * @param {SetOrderStatusRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setOrderStatusUsingPOST(request: SetOrderStatusRequest, options?: any) {
      return OrderWsControllerApiFp(configuration).setOrderStatusUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary setProductOrderStatus
     * @param {SetProductOrderStatusRequest} request request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProductOrderStatusUsingPOST(request: SetProductOrderStatusRequest, language?: string, options?: any) {
      return OrderWsControllerApiFp(configuration).setProductOrderStatusUsingPOST(
        request,
        language,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * OrderWsControllerApi - object-oriented interface
 * @export
 * @class OrderWsControllerApi
 * @extends {BaseAPI}
 */
export class OrderWsControllerApi extends BaseAPI {
  /**
   *
   * @summary calculateExternalProductsPurchaseOrder
   * @param {CreateExternalProductsPurchaseOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public calculateExternalProductsPurchaseOrderUsingPOST(
    request: CreateExternalProductsPurchaseOrderRequest,
    options?: any
  ) {
    return OrderWsControllerApiFp(this.configuration).calculateExternalProductsPurchaseOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createBuyOrder
   * @param {CreateCryptoOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createBuyOrderUsingPOST(request: CreateCryptoOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).createBuyOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createExternalProductsPurchaseOrder
   * @param {CreateExternalProductsPurchaseOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createExternalProductsPurchaseOrderUsingPOST(
    request: CreateExternalProductsPurchaseOrderRequest,
    options?: any
  ) {
    return OrderWsControllerApiFp(this.configuration).createExternalProductsPurchaseOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createOrderUsingPOST2(request: CreateOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).createOrderUsingPOST2(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createOrder
   * @param {CreateOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createOrderUsingPOST3(request: CreateOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).createOrderUsingPOST3(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createProductFeatureOrder
   * @param {CreateProductFeatureOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createProductFeatureOrderUsingPOST(request: CreateProductFeatureOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).createProductFeatureOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createProductsPurchaseOrder
   * @param {CreateProductsPurchaseOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createProductsPurchaseOrderUsingPOST(request: CreateProductsPurchaseOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).createProductsPurchaseOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary createSellOrder
   * @param {CreateCryptoOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public createSellOrderUsingPOST(request: CreateCryptoOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).createSellOrderUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary getOrderDetails
   * @param {GetOrderDetailsRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public getOrderDetailsUsingPOST(request: GetOrderDetailsRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).getOrderDetailsUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary getOrderList
   * @param {GetOrderListRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public getOrderListUsingPOST(request: GetOrderListRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).getOrderListUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary getOrder
   * @param {string} uid uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public getOrderUsingGET(uid: string, options?: any) {
    return OrderWsControllerApiFp(this.configuration).getOrderUsingGET(uid, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary getOrder
   * @param {string} uid uid
   * @param {boolean} [full] full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public getOrderUsingGET1(uid: string, full?: boolean, options?: any) {
    return OrderWsControllerApiFp(this.configuration).getOrderUsingGET1(uid, full, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary getProductOrderList
   * @param {boolean} [buyer] buyer
   * @param {string} [language] language
   * @param {string} [orderNumber] orderNumber
   * @param {string} [orderStatus] orderStatus
   * @param {boolean} [seller] seller
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public getProductOrderListUsingGET(
    buyer?: boolean,
    language?: string,
    orderNumber?: string,
    orderStatus?: string,
    seller?: boolean,
    options?: any
  ) {
    return OrderWsControllerApiFp(this.configuration).getProductOrderListUsingGET(
      buyer,
      language,
      orderNumber,
      orderStatus,
      seller,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary refundOrder
   * @param {RefundOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public refundOrderUsingPOST(request: RefundOrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).refundOrderUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary remindOrder
   * @param {OrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public remindOrderUsingPOST(request: OrderRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).remindOrderUsingPOST(request, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary setOrderStatus
   * @param {SetOrderStatusRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public setOrderStatusUsingPOST(request: SetOrderStatusRequest, options?: any) {
    return OrderWsControllerApiFp(this.configuration).setOrderStatusUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary setProductOrderStatus
   * @param {SetProductOrderStatusRequest} request request
   * @param {string} [language] language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderWsControllerApi
   */
  public setProductOrderStatusUsingPOST(request: SetProductOrderStatusRequest, language?: string, options?: any) {
    return OrderWsControllerApiFp(this.configuration).setProductOrderStatusUsingPOST(
      request,
      language,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * PaymentWsControllerApi - fetch parameter creator
 * @export
 */
export const PaymentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary bankCardPayment
     * @param {BankCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankCardPaymentUsingPOST(request: BankCardPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling bankCardPaymentUsingPOST."
        );
      }
      const localVarPath = `/merchant/payment/bankcard`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"BankCardPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary checkPayment
     * @param {CheckPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPaymentUsingPOST(request: CheckPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling checkPaymentUsingPOST."
        );
      }
      const localVarPath = `/payment/check`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CheckPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary paymentConfirmationAction
     * @param {PaymentActionConfirmationRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentConfirmationActionUsingPOST(request: PaymentActionConfirmationRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentConfirmationActionUsingPOST."
        );
      }
      const localVarPath = `/payment/confirmation`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"PaymentActionConfirmationRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentWsControllerApi - functional programming interface
 * @export
 */
export const PaymentWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary bankCardPayment
     * @param {BankCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankCardPaymentUsingPOST(
      request: BankCardPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BankCardPaymentResponse> {
      const localVarFetchArgs = PaymentWsControllerApiFetchParamCreator(configuration).bankCardPaymentUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary checkPayment
     * @param {CheckPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPaymentUsingPOST(
      request: CheckPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentResponse> {
      const localVarFetchArgs = PaymentWsControllerApiFetchParamCreator(configuration).checkPaymentUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary paymentConfirmationAction
     * @param {PaymentActionConfirmationRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentConfirmationActionUsingPOST(
      request: PaymentActionConfirmationRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentActionConfirmationResponse> {
      const localVarFetchArgs = PaymentWsControllerApiFetchParamCreator(
        configuration
      ).paymentConfirmationActionUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PaymentWsControllerApi - factory interface
 * @export
 */
export const PaymentWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary bankCardPayment
     * @param {BankCardPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankCardPaymentUsingPOST(request: BankCardPaymentRequest, options?: any) {
      return PaymentWsControllerApiFp(configuration).bankCardPaymentUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary checkPayment
     * @param {CheckPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPaymentUsingPOST(request: CheckPaymentRequest, options?: any) {
      return PaymentWsControllerApiFp(configuration).checkPaymentUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary paymentConfirmationAction
     * @param {PaymentActionConfirmationRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentConfirmationActionUsingPOST(request: PaymentActionConfirmationRequest, options?: any) {
      return PaymentWsControllerApiFp(configuration).paymentConfirmationActionUsingPOST(request, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * PaymentWsControllerApi - object-oriented interface
 * @export
 * @class PaymentWsControllerApi
 * @extends {BaseAPI}
 */
export class PaymentWsControllerApi extends BaseAPI {
  /**
   *
   * @summary bankCardPayment
   * @param {BankCardPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentWsControllerApi
   */
  public bankCardPaymentUsingPOST(request: BankCardPaymentRequest, options?: any) {
    return PaymentWsControllerApiFp(this.configuration).bankCardPaymentUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary checkPayment
   * @param {CheckPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentWsControllerApi
   */
  public checkPaymentUsingPOST(request: CheckPaymentRequest, options?: any) {
    return PaymentWsControllerApiFp(this.configuration).checkPaymentUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary paymentConfirmationAction
   * @param {PaymentActionConfirmationRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentWsControllerApi
   */
  public paymentConfirmationActionUsingPOST(request: PaymentActionConfirmationRequest, options?: any) {
    return PaymentWsControllerApiFp(this.configuration).paymentConfirmationActionUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * ProjectControllerApi - fetch parameter creator
 * @export
 */
export const ProjectControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary getVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersionUsingGET(options: any = {}): FetchArgs {
      const localVarPath = `/version`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectControllerApi - functional programming interface
 * @export
 */
export const ProjectControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary getVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiProject> {
      const localVarFetchArgs = ProjectControllerApiFetchParamCreator(configuration).getVersionUsingGET(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ProjectControllerApi - factory interface
 * @export
 */
export const ProjectControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary getVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersionUsingGET(options?: any) {
      return ProjectControllerApiFp(configuration).getVersionUsingGET(options)(fetch, basePath);
    },
  };
};

/**
 * ProjectControllerApi - object-oriented interface
 * @export
 * @class ProjectControllerApi
 * @extends {BaseAPI}
 */
export class ProjectControllerApi extends BaseAPI {
  /**
   *
   * @summary getVersion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectControllerApi
   */
  public getVersionUsingGET(options?: any) {
    return ProjectControllerApiFp(this.configuration).getVersionUsingGET(options)(this.fetch, this.basePath);
  }
}

/**
 * PurchaseExternalPaymentWsControllerApi - fetch parameter creator
 * @export
 */
export const PurchaseExternalPaymentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary payment
     * @param {PurchaseExternalPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST3(request: PurchaseExternalPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling paymentUsingPOST3."
        );
      }
      const localVarPath = `/payment/purchasexternal`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"PurchaseExternalPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PurchaseExternalPaymentWsControllerApi - functional programming interface
 * @export
 */
export const PurchaseExternalPaymentWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary payment
     * @param {PurchaseExternalPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST3(
      request: PurchaseExternalPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PurchaseExternalPaymentResponse> {
      const localVarFetchArgs = PurchaseExternalPaymentWsControllerApiFetchParamCreator(
        configuration
      ).paymentUsingPOST3(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PurchaseExternalPaymentWsControllerApi - factory interface
 * @export
 */
export const PurchaseExternalPaymentWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary payment
     * @param {PurchaseExternalPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentUsingPOST3(request: PurchaseExternalPaymentRequest, options?: any) {
      return PurchaseExternalPaymentWsControllerApiFp(configuration).paymentUsingPOST3(request, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * PurchaseExternalPaymentWsControllerApi - object-oriented interface
 * @export
 * @class PurchaseExternalPaymentWsControllerApi
 * @extends {BaseAPI}
 */
export class PurchaseExternalPaymentWsControllerApi extends BaseAPI {
  /**
   *
   * @summary payment
   * @param {PurchaseExternalPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PurchaseExternalPaymentWsControllerApi
   */
  public paymentUsingPOST3(request: PurchaseExternalPaymentRequest, options?: any) {
    return PurchaseExternalPaymentWsControllerApiFp(this.configuration).paymentUsingPOST3(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * RateWsControllerApi - fetch parameter creator
 * @export
 */
export const RateWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary getRate
     * @param {string} from from
     * @param {'BUY' | 'SELL'} operation operation
     * @param {string} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRateUsingGET(from: string, operation: "BUY" | "SELL", to: string, options: any = {}): FetchArgs {
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError("from", "Required parameter from was null or undefined when calling getRateUsingGET.");
      }
      // verify required parameter 'operation' is not null or undefined
      if (operation === null || operation === undefined) {
        throw new RequiredError(
          "operation",
          "Required parameter operation was null or undefined when calling getRateUsingGET."
        );
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError("to", "Required parameter to was null or undefined when calling getRateUsingGET.");
      }
      const localVarPath = `/crypto/rate/{operation}/{from}-{to}`
        .replace(`{${"from"}}`, encodeURIComponent(String(from)))
        .replace(`{${"operation"}}`, encodeURIComponent(String(operation)))
        .replace(`{${"to"}}`, encodeURIComponent(String(to)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getRates
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatesUsingPOST(request: CreateCryptoOrderRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling getRatesUsingPOST."
        );
      }
      const localVarPath = `/crypto/rate`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CreateCryptoOrderRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RateWsControllerApi - functional programming interface
 * @export
 */
export const RateWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary getRate
     * @param {string} from from
     * @param {'BUY' | 'SELL'} operation operation
     * @param {string} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRateUsingGET(
      from: string,
      operation: "BUY" | "SELL",
      to: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<RateResponse> {
      const localVarFetchArgs = RateWsControllerApiFetchParamCreator(configuration).getRateUsingGET(
        from,
        operation,
        to,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getRates
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatesUsingPOST(
      request: CreateCryptoOrderRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<RateListResponse> {
      const localVarFetchArgs = RateWsControllerApiFetchParamCreator(configuration).getRatesUsingPOST(request, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * RateWsControllerApi - factory interface
 * @export
 */
export const RateWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary getRate
     * @param {string} from from
     * @param {'BUY' | 'SELL'} operation operation
     * @param {string} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRateUsingGET(from: string, operation: "BUY" | "SELL", to: string, options?: any) {
      return RateWsControllerApiFp(configuration).getRateUsingGET(from, operation, to, options)(fetch, basePath);
    },
    /**
     *
     * @summary getRates
     * @param {CreateCryptoOrderRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatesUsingPOST(request: CreateCryptoOrderRequest, options?: any) {
      return RateWsControllerApiFp(configuration).getRatesUsingPOST(request, options)(fetch, basePath);
    },
  };
};

/**
 * RateWsControllerApi - object-oriented interface
 * @export
 * @class RateWsControllerApi
 * @extends {BaseAPI}
 */
export class RateWsControllerApi extends BaseAPI {
  /**
   *
   * @summary getRate
   * @param {string} from from
   * @param {'BUY' | 'SELL'} operation operation
   * @param {string} to to
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RateWsControllerApi
   */
  public getRateUsingGET(from: string, operation: "BUY" | "SELL", to: string, options?: any) {
    return RateWsControllerApiFp(this.configuration).getRateUsingGET(
      from,
      operation,
      to,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary getRates
   * @param {CreateCryptoOrderRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RateWsControllerApi
   */
  public getRatesUsingPOST(request: CreateCryptoOrderRequest, options?: any) {
    return RateWsControllerApiFp(this.configuration).getRatesUsingPOST(request, options)(this.fetch, this.basePath);
  }
}

/**
 * RecurringWsControllerApi - fetch parameter creator
 * @export
 */
export const RecurringWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary cancelSubscription
     * @param {CancelRecurringSubscriptionRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscriptionUsingPOST(request: CancelRecurringSubscriptionRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling cancelSubscriptionUsingPOST."
        );
      }
      const localVarPath = `/recurring/cancel`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"CancelRecurringSubscriptionRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary makePayment
     * @param {MakeRecurringPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    makePaymentUsingPOST(request: MakeRecurringPaymentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling makePaymentUsingPOST."
        );
      }
      const localVarPath = `/recurring/payment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"MakeRecurringPaymentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecurringWsControllerApi - functional programming interface
 * @export
 */
export const RecurringWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary cancelSubscription
     * @param {CancelRecurringSubscriptionRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscriptionUsingPOST(
      request: CancelRecurringSubscriptionRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiResponse> {
      const localVarFetchArgs = RecurringWsControllerApiFetchParamCreator(configuration).cancelSubscriptionUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary makePayment
     * @param {MakeRecurringPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    makePaymentUsingPOST(
      request: MakeRecurringPaymentRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentResponse> {
      const localVarFetchArgs = RecurringWsControllerApiFetchParamCreator(configuration).makePaymentUsingPOST(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * RecurringWsControllerApi - factory interface
 * @export
 */
export const RecurringWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary cancelSubscription
     * @param {CancelRecurringSubscriptionRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscriptionUsingPOST(request: CancelRecurringSubscriptionRequest, options?: any) {
      return RecurringWsControllerApiFp(configuration).cancelSubscriptionUsingPOST(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary makePayment
     * @param {MakeRecurringPaymentRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    makePaymentUsingPOST(request: MakeRecurringPaymentRequest, options?: any) {
      return RecurringWsControllerApiFp(configuration).makePaymentUsingPOST(request, options)(fetch, basePath);
    },
  };
};

/**
 * RecurringWsControllerApi - object-oriented interface
 * @export
 * @class RecurringWsControllerApi
 * @extends {BaseAPI}
 */
export class RecurringWsControllerApi extends BaseAPI {
  /**
   *
   * @summary cancelSubscription
   * @param {CancelRecurringSubscriptionRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecurringWsControllerApi
   */
  public cancelSubscriptionUsingPOST(request: CancelRecurringSubscriptionRequest, options?: any) {
    return RecurringWsControllerApiFp(this.configuration).cancelSubscriptionUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary makePayment
   * @param {MakeRecurringPaymentRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecurringWsControllerApi
   */
  public makePaymentUsingPOST(request: MakeRecurringPaymentRequest, options?: any) {
    return RecurringWsControllerApiFp(this.configuration).makePaymentUsingPOST(request, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * StorageWsControllerApi - fetch parameter creator
 * @export
 */
export const StorageWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary deleteOrderObject
     * @param {OrderStorageRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderObjectUsingDELETE(request: OrderStorageRequest, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          "request",
          "Required parameter request was null or undefined when calling deleteOrderObjectUsingDELETE."
        );
      }
      const localVarPath = `/order/object`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>"OrderStorageRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getOrderObjects
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderObjectsUsingGET(uid: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling getOrderObjectsUsingGET."
        );
      }
      const localVarPath = `/order/object/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary uploadOrderObjects
     * @param {Array<any>} file file
     * @param {any} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadOrderObjectsUsingPOST(file: Array<any>, uid: any, options: any = {}): FetchArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling uploadOrderObjectsUsingPOST."
        );
      }
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling uploadOrderObjectsUsingPOST."
        );
      }
      const localVarPath = `/order/object`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new url.URLSearchParams();

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (file) {
        file.forEach((element) => {
          localVarFormParams.append("file", element as any);
        });
      }

      if (uid !== undefined) {
        localVarFormParams.set("uid", uid as any);
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded";

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StorageWsControllerApi - functional programming interface
 * @export
 */
export const StorageWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary deleteOrderObject
     * @param {OrderStorageRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderObjectUsingDELETE(
      request: OrderStorageRequest,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiResponse> {
      const localVarFetchArgs = StorageWsControllerApiFetchParamCreator(configuration).deleteOrderObjectUsingDELETE(
        request,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getOrderObjects
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderObjectsUsingGET(
      uid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderStorageObjectListResponse> {
      const localVarFetchArgs = StorageWsControllerApiFetchParamCreator(configuration).getOrderObjectsUsingGET(
        uid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary uploadOrderObjects
     * @param {Array<any>} file file
     * @param {any} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadOrderObjectsUsingPOST(
      file: Array<any>,
      uid: any,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentApiResponse> {
      const localVarFetchArgs = StorageWsControllerApiFetchParamCreator(configuration).uploadOrderObjectsUsingPOST(
        file,
        uid,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * StorageWsControllerApi - factory interface
 * @export
 */
export const StorageWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary deleteOrderObject
     * @param {OrderStorageRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderObjectUsingDELETE(request: OrderStorageRequest, options?: any) {
      return StorageWsControllerApiFp(configuration).deleteOrderObjectUsingDELETE(request, options)(fetch, basePath);
    },
    /**
     *
     * @summary getOrderObjects
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderObjectsUsingGET(uid: string, options?: any) {
      return StorageWsControllerApiFp(configuration).getOrderObjectsUsingGET(uid, options)(fetch, basePath);
    },
    /**
     *
     * @summary uploadOrderObjects
     * @param {Array<any>} file file
     * @param {any} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadOrderObjectsUsingPOST(file: Array<any>, uid: any, options?: any) {
      return StorageWsControllerApiFp(configuration).uploadOrderObjectsUsingPOST(file, uid, options)(fetch, basePath);
    },
  };
};

/**
 * StorageWsControllerApi - object-oriented interface
 * @export
 * @class StorageWsControllerApi
 * @extends {BaseAPI}
 */
export class StorageWsControllerApi extends BaseAPI {
  /**
   *
   * @summary deleteOrderObject
   * @param {OrderStorageRequest} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageWsControllerApi
   */
  public deleteOrderObjectUsingDELETE(request: OrderStorageRequest, options?: any) {
    return StorageWsControllerApiFp(this.configuration).deleteOrderObjectUsingDELETE(request, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary getOrderObjects
   * @param {string} uid uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageWsControllerApi
   */
  public getOrderObjectsUsingGET(uid: string, options?: any) {
    return StorageWsControllerApiFp(this.configuration).getOrderObjectsUsingGET(uid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary uploadOrderObjects
   * @param {Array<any>} file file
   * @param {any} uid uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StorageWsControllerApi
   */
  public uploadOrderObjectsUsingPOST(file: Array<any>, uid: any, options?: any) {
    return StorageWsControllerApiFp(this.configuration).uploadOrderObjectsUsingPOST(
      file,
      uid,
      options
    )(this.fetch, this.basePath);
  }
}

/**
 * TariffWsControllerApi - fetch parameter creator
 * @export
 */
export const TariffWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary getCurrencyTariffByOrderUid
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencyTariffByOrderUidUsingGET(uid: string, options: any = {}): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling getCurrencyTariffByOrderUidUsingGET."
        );
      }
      const localVarPath = `/tariff/order/currency/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getPspTariffByOrderUid
     * @param {string} uid uid
     * @param {number} [amount] amount
     * @param {string} [currencyCode] currencyCode
     * @param {string} [paymentMethodCode] paymentMethodCode
     * @param {string} [pspCode] pspCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPspTariffByOrderUidUsingGET(
      uid: string,
      amount?: number,
      currencyCode?: string,
      paymentMethodCode?: string,
      pspCode?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling getPspTariffByOrderUidUsingGET."
        );
      }
      const localVarPath = `/tariff/list/order/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (amount !== undefined) {
        localVarQueryParameter["amount"] = amount;
      }

      if (currencyCode !== undefined) {
        localVarQueryParameter["currencyCode"] = currencyCode;
      }

      if (paymentMethodCode !== undefined) {
        localVarQueryParameter["paymentMethodCode"] = paymentMethodCode;
      }

      if (pspCode !== undefined) {
        localVarQueryParameter["pspCode"] = pspCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getTariffByOrderUid
     * @param {string} uid uid
     * @param {number} [amount] amount
     * @param {string} [currencyCode] currencyCode
     * @param {string} [paymentMethodCode] paymentMethodCode
     * @param {string} [pspCode] pspCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffByOrderUidUsingGET(
      uid: string,
      amount?: number,
      currencyCode?: string,
      paymentMethodCode?: string,
      pspCode?: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'uid' is not null or undefined
      if (uid === null || uid === undefined) {
        throw new RequiredError(
          "uid",
          "Required parameter uid was null or undefined when calling getTariffByOrderUidUsingGET."
        );
      }
      const localVarPath = `/tariff/order/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authorization required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue =
          typeof configuration.accessToken === "function"
            ? configuration.accessToken("authorization", ["global"])
            : configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
      }

      if (amount !== undefined) {
        localVarQueryParameter["amount"] = amount;
      }

      if (currencyCode !== undefined) {
        localVarQueryParameter["currencyCode"] = currencyCode;
      }

      if (paymentMethodCode !== undefined) {
        localVarQueryParameter["paymentMethodCode"] = paymentMethodCode;
      }

      if (pspCode !== undefined) {
        localVarQueryParameter["pspCode"] = pspCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TariffWsControllerApi - functional programming interface
 * @export
 */
export const TariffWsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary getCurrencyTariffByOrderUid
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencyTariffByOrderUidUsingGET(
      uid: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<TariffCurrencyResponse> {
      const localVarFetchArgs = TariffWsControllerApiFetchParamCreator(
        configuration
      ).getCurrencyTariffByOrderUidUsingGET(uid, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getPspTariffByOrderUid
     * @param {string} uid uid
     * @param {number} [amount] amount
     * @param {string} [currencyCode] currencyCode
     * @param {string} [paymentMethodCode] paymentMethodCode
     * @param {string} [pspCode] pspCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPspTariffByOrderUidUsingGET(
      uid: string,
      amount?: number,
      currencyCode?: string,
      paymentMethodCode?: string,
      pspCode?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<TariffListResponse> {
      const localVarFetchArgs = TariffWsControllerApiFetchParamCreator(configuration).getPspTariffByOrderUidUsingGET(
        uid,
        amount,
        currencyCode,
        paymentMethodCode,
        pspCode,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary getTariffByOrderUid
     * @param {string} uid uid
     * @param {number} [amount] amount
     * @param {string} [currencyCode] currencyCode
     * @param {string} [paymentMethodCode] paymentMethodCode
     * @param {string} [pspCode] pspCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffByOrderUidUsingGET(
      uid: string,
      amount?: number,
      currencyCode?: string,
      paymentMethodCode?: string,
      pspCode?: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<TariffResponse> {
      const localVarFetchArgs = TariffWsControllerApiFetchParamCreator(configuration).getTariffByOrderUidUsingGET(
        uid,
        amount,
        currencyCode,
        paymentMethodCode,
        pspCode,
        options
      );
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * TariffWsControllerApi - factory interface
 * @export
 */
export const TariffWsControllerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     *
     * @summary getCurrencyTariffByOrderUid
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencyTariffByOrderUidUsingGET(uid: string, options?: any) {
      return TariffWsControllerApiFp(configuration).getCurrencyTariffByOrderUidUsingGET(uid, options)(fetch, basePath);
    },
    /**
     *
     * @summary getPspTariffByOrderUid
     * @param {string} uid uid
     * @param {number} [amount] amount
     * @param {string} [currencyCode] currencyCode
     * @param {string} [paymentMethodCode] paymentMethodCode
     * @param {string} [pspCode] pspCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPspTariffByOrderUidUsingGET(
      uid: string,
      amount?: number,
      currencyCode?: string,
      paymentMethodCode?: string,
      pspCode?: string,
      options?: any
    ) {
      return TariffWsControllerApiFp(configuration).getPspTariffByOrderUidUsingGET(
        uid,
        amount,
        currencyCode,
        paymentMethodCode,
        pspCode,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary getTariffByOrderUid
     * @param {string} uid uid
     * @param {number} [amount] amount
     * @param {string} [currencyCode] currencyCode
     * @param {string} [paymentMethodCode] paymentMethodCode
     * @param {string} [pspCode] pspCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffByOrderUidUsingGET(
      uid: string,
      amount?: number,
      currencyCode?: string,
      paymentMethodCode?: string,
      pspCode?: string,
      options?: any
    ) {
      return TariffWsControllerApiFp(configuration).getTariffByOrderUidUsingGET(
        uid,
        amount,
        currencyCode,
        paymentMethodCode,
        pspCode,
        options
      )(fetch, basePath);
    },
  };
};

/**
 * TariffWsControllerApi - object-oriented interface
 * @export
 * @class TariffWsControllerApi
 * @extends {BaseAPI}
 */
export class TariffWsControllerApi extends BaseAPI {
  /**
   *
   * @summary getCurrencyTariffByOrderUid
   * @param {string} uid uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffWsControllerApi
   */
  public getCurrencyTariffByOrderUidUsingGET(uid: string, options?: any) {
    return TariffWsControllerApiFp(this.configuration).getCurrencyTariffByOrderUidUsingGET(uid, options)(
      this.fetch,
      this.basePath
    );
  }

  /**
   *
   * @summary getPspTariffByOrderUid
   * @param {string} uid uid
   * @param {number} [amount] amount
   * @param {string} [currencyCode] currencyCode
   * @param {string} [paymentMethodCode] paymentMethodCode
   * @param {string} [pspCode] pspCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffWsControllerApi
   */
  public getPspTariffByOrderUidUsingGET(
    uid: string,
    amount?: number,
    currencyCode?: string,
    paymentMethodCode?: string,
    pspCode?: string,
    options?: any
  ) {
    return TariffWsControllerApiFp(this.configuration).getPspTariffByOrderUidUsingGET(
      uid,
      amount,
      currencyCode,
      paymentMethodCode,
      pspCode,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary getTariffByOrderUid
   * @param {string} uid uid
   * @param {number} [amount] amount
   * @param {string} [currencyCode] currencyCode
   * @param {string} [paymentMethodCode] paymentMethodCode
   * @param {string} [pspCode] pspCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffWsControllerApi
   */
  public getTariffByOrderUidUsingGET(
    uid: string,
    amount?: number,
    currencyCode?: string,
    paymentMethodCode?: string,
    pspCode?: string,
    options?: any
  ) {
    return TariffWsControllerApiFp(this.configuration).getTariffByOrderUidUsingGET(
      uid,
      amount,
      currencyCode,
      paymentMethodCode,
      pspCode,
      options
    )(this.fetch, this.basePath);
  }
}
