import React from "react";
import { Link, BlockTitle } from "framework7-react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "./CatalogBlockTitle.less";

const renderSeeAllLink = (props: any) => {
  return props.onClick ? (
    <Link href="#" onClick={props.onClick}>
      {props.t("See all")}
    </Link>
  ) : null;
};

const CatalogBlockTitle = (props: any) => (
  <BlockTitle className="catalog-block-title" {...props}>
    <span>{props.children}</span>
    {renderSeeAllLink(props)}
  </BlockTitle>
);

export default compose(withTranslation())(CatalogBlockTitle);
