import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import Slider from "../Slider";
import { Banner } from "../../types/marketplaceapi";
import { loadMarketingBanners, registerBannerClick } from "../../actions/bannersActions";
import { IApplicationStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import { ICategoryClassificator } from "../../reducers/categoryReducer";
import connectCategoriesClassificator from "../../store/connectCategoriesClassificator";
import { getQueryParameterFromURL } from "../../utils";
import { ISearchParams, searchProducts } from "../../actions/productActions";
import { chooseCategorySubcategory } from "../../actions/filterActions";

import "./style.less";
import { Profile } from "../../reducers/sessionReducer";
import connectProfile from "../../store/connectProfile";

type Props = WithTranslation & {
  loading: boolean;
  banners: Banner[];
  categoriesClassificator?: ICategoryClassificator[];
  loadMarketingBanners?(): () => void;
  chooseCategorySubcategory(catid?: string, subcatid?: string): () => void;
  registerBannerClick(bannerUid: string, channel?: string): () => void;
  search(searchParams: ISearchParams): () => void;
  profile: Profile;
  delay: boolean;
  actionClick?(action: string): void;
};

class BigMarketingBannerSlider extends React.Component<Props> {
  componentDidMount() {
    /* TODO */
    const { banners, delay } = this.props;
    if (!banners.length) {
      const timeout = delay ? 500 : 0;
      setTimeout(() => this.props.loadMarketingBanners(), timeout);
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.handleAllLoading(prevProps);
  }

  handleAllLoading(prevProps: Props) {
    /* TODO */
    const { loading, banners } = this.props;
    if (!loading && loading !== prevProps.loading && !banners.length) {
      this.props.loadMarketingBanners();
    }
  }

  marketingBannerClickHandle = (item: Banner) => {
    const { targetUrl, uid } = item;
    console.log("targetUrl", item);
    const categoryId = getQueryParameterFromURL("Category", targetUrl);
    const action = getQueryParameterFromURL("Action", targetUrl);
    if (categoryId) {
      this.categoryHandle(categoryId, uid);
    } else if (action) {
      this.props.actionClick(action);
    } else if (targetUrl.includes("external-product")) {
      this.$f7.views.main.router.navigate("external-product/");
    }
  };

  categoryHandle = (catid: string, uid: string) => {
    const { categoriesClassificator } = this.props;

    const category = categoriesClassificator.filter((cat) => cat.categoryCode === catid)[0];

    if (!category) return;

    function getUpperLevelCategory(category: ICategoryClassificator): ICategoryClassificator {
      return category.parent ? getUpperLevelCategory(category.parent) : category;
    }

    const upperLevelCategory = getUpperLevelCategory(category);
    const { categoryCode } = upperLevelCategory;

    this.props.registerBannerClick(uid);
    this.props.chooseCategorySubcategory(categoryCode, catid);
    this.props.search({ category: catid });
  };

  render() {
    const { banners } = this.props;
    return (
      <Slider
        slides={banners.map((item) => {
          return {
            image: item.imageUrl,
            uid: item.uid,
            targetUrl: item.targetUrl,
          };
        })}
        onClick={this.marketingBannerClickHandle}
        className="big-marketing-banner-slider"
      />
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  loading: state.bannersReducer.loading,
  banners: state.bannersReducer.banners,
  delay: state.rootReducer.delayLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadMarketingBanners: () => dispatch(loadMarketingBanners()),
  chooseCategorySubcategory: (catid?: string, subcatid?: string) =>
    dispatch(chooseCategorySubcategory(catid, subcatid)),
  search: (searchParams: ISearchParams) => dispatch(searchProducts(searchParams)),
  registerBannerClick: (bannerUid: string, channel?: string) => dispatch(registerBannerClick(bannerUid, channel)),
});

export default compose<any>(
  withTranslation(),
  connectCategoriesClassificator,
  connect(mapStateToProps, mapDispatchToProps),
  connectProfile
)(BigMarketingBannerSlider);
