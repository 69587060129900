import React, { Component } from "react";
import {
  Page,
  Navbar,
  BlockTitle,
  List,
  Row,
  Col,
  ListItem,
  Input,
  Fab,
  Icon,
  NavRight,
  Link,
  Chip,
  Block,
  NavLeft,
  NavTitle,
} from "framework7-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { ListInput } from "../../components/ListInput";
import { SliderImageUploader } from "../../components/SliderImageUploader";
import { IApplicationStore, ICategory } from "../../store/rootReducer";
import { IProduct } from "../../reducers/productReducer";
import {
  updateProductDraft,
  createSaveProduct,
  chooseSubcategory,
  attachFile,
  detachFile,
} from "../../actions/productCreateActions";
import classNames from "classnames";
import {
  getSubcategoryNameBySubcategoryId,
  convertISODateToInputDate,
  parseVideoURL,
  createVideoURLLink,
  createThumbnailVideoURLLink,
} from "../../utils";
import { ICategoryClassificator } from "../../reducers/categoryReducer";
import { chooseCategory } from "../../actions/productCreateActions";
import {
  IProductCreateUploadedFileInfo,
  IProductCreateFormError,
} from "../../reducers/productCreateReducer";
import connectCurrencies, {
  ICurrencyProps,
} from "../../store/connectCurrencies";
import { Profile } from "../../reducers/sessionReducer";
import { ProductVideo } from "../../types/marketplaceapi";

import "./style.less";
import { ListItemVideoPreview } from "../../components-ui/list-item-video-preview";
import { IcYoutube, IcVimeo } from "../../components-ui/icons";

const videoSocialIcons = {
  YOUTUBE: <IcYoutube />,
  VIMEO: <IcVimeo />,
};

type Props = WithTranslation &
  ICurrencyProps & {
    categories?: ICategoryClassificator[];
    category?: ICategory;
    chosenCategoryId?: string;
    loading?: boolean;
    saving?: boolean;
    error?: any;
    formErrors?: IProductCreateFormError[];
    product?: IProduct;
    files: IProductCreateUploadedFileInfo[];
    profile?: Profile;
    updateProductDraft?(item: IProduct, reset?: boolean): void;
    chooseCategory?(catid?: string): void;
    chooseSubCategory?(catid?: string): void;
    createSaveProduct?(
      item: IProduct,
      files: IProductCreateUploadedFileInfo[]
    ): void;
    attachFile?(index: number, file: File): void;
    detachFile?(index: number, productUid: string): void;
  };

type State = {
  step: number;
};

class ProductCreatePage extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      step: 1,
    };
  }

  pageInitHandle = () => {
    const { product, profile } = this.props;
    const { params } = this.$f7route;
    const step = parseInt(params.step);

    if (params.uid && !product.uid) {
      this.$f7.preloader.show();
    }

    product.uid = params.uid; /* update to val or null */
    product.languageCode = profile.language ? profile.language.code : "en";
    this.setState({ step }, () =>
      this.props.updateProductDraft(product, step === 1)
    );
  };

  handlePageBeforeRemove = () => {
    if (this.state.step === 1) {
      const { product } = this.props;
      this.props.updateProductDraft({ ...product, uid: null }, true);
    }
  };

  _preloader: any;

  componentDidUpdate(prevProps: Props) {
    const { loading } = this.props;
    if (prevProps.loading && !loading) {
      this.$f7.preloader.hide();
    }
    this.handleAttachingFileErrors(prevProps, this.props);
    this.handleStep3(prevProps);
  }

  handleAttachingFileErrors = (prevProps: Props, nextProps: Props) => {
    const { files } = nextProps;
    if (files.length === prevProps.files.length) {
      let errorText = "";
      files.forEach((item, i) => {
        if (item.error && prevProps.files[i].error !== item.error) {
          errorText += item.error + "\n";
        }
      });
      if (errorText.length > 0) this.$f7.dialog.alert(errorText);
    }
  };

  handleStep3 = (prevProps: Props) => {
    const { loading, error, formErrors, product, t } = this.props;
    const { step } = this.state;

    if (step === 3) {
      if (error && error !== prevProps.error) {
        if (this._preloader) this._preloader.close();
        this.$f7.dialog.alert(t(error));
      }

      if (loading && !prevProps.loading) {
        this._preloader = this.$f7.dialog.preloader(
          !product.uid ? t("Creating...") : t("Saving...")
        );
      }

      if (
        !loading &&
        prevProps.loading &&
        !error &&
        !formErrors.length &&
        product.uid
      ) {
        if (this._preloader) this._preloader.close();
        this.$f7.dialog.alert(
          !product.uid
            ? t("You successfully created your product")
            : t("You successfully saved your product"),
          "",
          () => {
            this.$f7router.back("/profile/my-goods/", {
              ignoreCache: true,
              animate: true,
              force: true,
            });
          }
        );
      }
    }
  };

  nextStepHandle = () => {
    const { step } = this.state;
    const {
      product: { uid },
    } = this.props;
    if (step < 3) {
      const url = !uid
        ? `/profile/my-goods/add/${step + 1}/`
        : `/profile/my-goods/edit/${uid}/${step + 1}/`;
      this.$f7router.navigate(url, {
        ignoreCache: true,
        animate: true,
        force: true,
      });
    } else {
      const { product, files } = this.props;
      this.props.createSaveProduct(product, files);
    }
  };

  prevStepHandle = () => this.$f7router.back();

  blurInputHandle = (e: {
    target: { name: any; value: any; type: any; checked: boolean };
  }) => {
    const { name, value, type } = e.target;
    const { product } = this.props;
    if (!name || !type)
      throw new Error("Name or type for field is not defined!");

    try {
      switch (type) {
        case "number":
          product[name] = parseFloat(value);
          break;
        case "checkbox":
          product[name] = e.target.checked;
          break;
        case "date": {
          let dateISO = new Date(value).toISOString();
          product[name] = dateISO.substr(0, dateISO.lastIndexOf("."));
          break;
        }
        default: {
          /* TODO */
          if (name === "video" && !!value && !!value.length) {
            const info = parseVideoURL(value) || { id: null, type: null };
            product.video = {
              id: info.id,
              type: info.type
                ? ProductVideo.TypeEnum[info.type.toUpperCase()]
                : "",
              enabled: true,
            };
          } else {
            product[name] = value;
          }
        }
      }
    } catch (err) {}

    this.props.updateProductDraft(product);
  };

  smartSelectInputChangeHandle = (e: {
    target: { value: any; name?: any; type?: any };
  }) => {
    const { name, value } = e.target;
    const { product } = this.props;
    product[name] = value;
    this.props.updateProductDraft(product);
  };

  selectCategoryHandle = () =>
    this.$f7router.navigate("/select-category-subcategory/", {
      props: {
        onSheetClosed: this.sheetClosedHandle,
      },
    });

  selectSubCategoryHandle = () =>
    this.props.chosenCategoryId &&
    this.$f7router.navigate("/select-category-subcategory/", {
      props: {
        catid: this.props.chosenCategoryId,
        onSheetClosed: (selectedId?: string) => {
          if (selectedId) {
            this.props.chooseSubCategory(selectedId);
          }
        },
      },
    });

  sheetClosedHandle = (selectedId?: string) => {
    const { chosenCategoryId } = this.props;
    if (selectedId && selectedId !== chosenCategoryId) {
      this.props.chooseCategory(selectedId);
    }
  };

  selectProductTypeHandle = (type: any) =>
    this.props.updateProductDraft({ ...this.props.product, type });

  attachFileHandle = (index: number, file?: File) => {
    if (file) {
      this.props.attachFile(index, file);
    }
  };

  /* TODO */
  detachFileHandle = (index: number) =>
    this.$f7.dialog.confirm(this.props.t("Sure?"), null, () =>
      this.props.detachFile(index, this.props.product.uid)
    );

  getDefaultValue = (fieldName: string, defaultValue: any = ""): any => {
    const { product } = this.props;

    if (fieldName === "video") {
      return product[fieldName] ? product[fieldName].id : defaultValue;
    }

    if (
      typeof product[fieldName] !== "undefined" &&
      product[fieldName] !== null &&
      product[fieldName].toString() !== "NaN"
    ) {
      return product[fieldName];
    } else {
      return defaultValue;
    }
  };

  getErrorProps = (fieldName: string, properties?: any) => {
    const { t } = this.props;
    const optionalProps = properties || {};
    let errorMessage = t("Please fill out this field.");

    let errorMessageForce =
      this.props.formErrors.filter((e) => e.parameters.includes(fieldName))
        .length > 0;

    if (!errorMessageForce) {
      const formError = this.props.formErrors.filter(
        (item) =>
          !!item.parameters.filter((__item) => {
            // @ts-ignore
            if (__item.parameters) {
              // @ts-ignore
              return !!__item.parameters.includes(fieldName);
            }
            return __item == fieldName;
          }).length
      )[0];

      if (formError) {
        errorMessageForce = true;
        errorMessage = t(formError.message);
      }
    }

    return {
      info: t("required").toString(),
      required: true,
      validateOnBlur: true,
      errorMessage,
      errorMessageForce,
      ...optionalProps,
    };
  };

  renderSteps = () => {
    const {
      loading,
      saving,
      product,
      category,
      categories = [],
      currencies = [],
      files,
      t,
    } = this.props;
    const { step } = this.state;

    if (loading && !saving /* prevent destroy slider image */) return null;

    switch (step) {
      case 1:
        return (
          <>
            <BlockTitle medium>{t("General Information")}</BlockTitle>
            <SliderImageUploader
              images={files}
              onSelectFile={this.attachFileHandle}
              onDeleteFile={this.detachFileHandle}
            />
            <List noHairlines form>
              <ListInput
                name="video"
                label={t("Video link").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("video")}
                {...this.getErrorProps("video", {
                  required: false,
                  info: t("Youtube, Vkontakte or Vimeo"),
                })}
              />
              {product.video && product.video.id && (
                <ListItemVideoPreview
                  title={product.name}
                  subtitle={createVideoURLLink(
                    product.video.id,
                    product.video.type.toString()
                  )}
                  icon={
                    product.video.type
                      ? videoSocialIcons[product.video.type.toString()]
                      : ""
                  }
                  image={createThumbnailVideoURLLink(
                    product.video.id,
                    product.video.type.toString()
                  )}
                  slot="list"
                />
              )}
              <ListItem
                link="#"
                onClick={this.selectCategoryHandle}
                title={t("Category").toString()}
                footer={t("required").toString()}
                after={category && category.name}
                slot="list"
                {...this.getErrorProps("category")}
              />
              <ListItem
                link="#"
                onClick={this.selectSubCategoryHandle}
                title={t("Subcategory").toString()}
                footer={t("required").toString()}
                after={getSubcategoryNameBySubcategoryId(
                  product.category,
                  categories
                )}
                slot="list"
                style={!category ? { opacity: 0.43 } : {}}
                {...this.getErrorProps("category")}
              />
            </List>
            <BlockTitle>{t("Type")}</BlockTitle>
            <Block className="product-type-btn-group">
              {[
                { name: "Product", val: "P" },
                { name: "Service", val: "S" },
              ].map((item, i) => (
                <Link
                  key={i}
                  onClick={() => this.selectProductTypeHandle(item.val)}
                >
                  <Chip
                    className={classNames(
                      item.val === product.type && "select"
                    )}
                    text={t(item.name).toString()}
                  />
                </Link>
              ))}
            </Block>
            <List noHairlines form>
              <ListInput
                name="name"
                label={t("Name").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("name")}
                {...this.getErrorProps("name")}
              />
              <ListInput
                name="description"
                label={t("Description").toString()}
                floatingLabel
                type="textarea"
                placeholder=""
                clearButton
                resizable
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("description")}
              />
              <ListInput
                name="hashtags"
                label={t("Hashtags").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("hashtags")}
              />
              <ListInput
                name="price"
                label={t("Price").toString()}
                floatingLabel
                type="number"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("price")}
                inputmode="decimal"
                min="0"
                step="0.01"
                {...this.getErrorProps("price")}
              />
              <ListInput
                name="discountedPrice"
                label={t("Price with discount").toString()}
                floatingLabel
                type="number"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                inputmode="decimal"
                min="0"
                step="0.01"
                defaultValue={this.getDefaultValue("discountedPrice")}
              />
              <ListInput
                name="quantity"
                label={t("Count of product").toString()}
                floatingLabel
                type="number"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("quantity")}
                inputmode="decimal"
                min="0"
                step="0"
                {...this.getErrorProps("quantity")}
              />
              <ListItem
                title={t("Currency").toString()}
                smartSelect
                smartSelectParams={{ openIn: "popover", closeOnSelect: true }}
                slot="list"
              >
                <select
                  name="currencyCode"
                  value={product.currencyCode}
                  onChange={this.smartSelectInputChangeHandle}
                >
                  {currencies.map((item) => (
                    <option key={item.code} value={item.code}>
                      {item.code}
                    </option>
                  ))}
                </select>
              </ListItem>
              <ListItem
                name="inStock"
                checkbox
                title={t("In stock").toString()}
                slot="list"
                onChange={this.blurInputHandle}
                checked={product.inStock}
              />
            </List>
          </>
        );
      case 2:
        return (
          <>
            <BlockTitle medium>{t("Manufacture")}</BlockTitle>
            <List noHairlines form>
              <ListInput
                name="manufacturer"
                label={t("Manufacturer").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("manufacturer")}
              />
              <ListInput
                name="model"
                label={t("Model").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("model")}
              />
              <ListInput
                name="color"
                label={t("Color").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("color")}
              />
              <ListItem slot="list">
                <Row>
                  <Col className="item-input">
                    <div className="item-title item-label item-floating-label">
                      {t("Size").toString()}
                    </div>
                    <Input
                      className="input-wrap"
                      name="size"
                      type="text"
                      placeholder=""
                      clearButton
                      onBlur={this.blurInputHandle}
                      defaultValue={this.getDefaultValue("size")}
                    />
                  </Col>
                  <Col className="item-input">
                    <div className="item-title item-label item-floating-label">
                      {t("Weight").toString()}
                    </div>
                    <Input
                      className="input-wrap"
                      name="weight"
                      type="number"
                      placeholder=""
                      clearButton
                      onBlur={this.blurInputHandle}
                      defaultValue={this.getDefaultValue("weight")}
                      min={0}
                    />
                  </Col>
                </Row>
              </ListItem>
              <ListInput
                name="location"
                label={t("Location").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                onBlur={this.blurInputHandle}
              />
            </List>
          </>
        );
      case 3:
        return (
          <>
            <BlockTitle medium>
              {t("Delivery information").toString()}
            </BlockTitle>
            <List noHairlines form>
              <ListItem
                name="pickupAllowed"
                checkbox
                title={t("Pick up allowed").toString()}
                slot="list"
                onChange={this.blurInputHandle}
                checked={product.pickupAllowed}
              />
              <ListItem
                name="shippingAllowed"
                checkbox
                title={t("Shipping allowed").toString()}
                slot="list"
                onChange={this.blurInputHandle}
                checked={product.shippingAllowed}
              />
              <ListItem
                name="returnAccepted"
                checkbox
                title={t("Return allowed").toString()}
                slot="list"
                onChange={this.blurInputHandle}
                checked={product.returnAccepted}
              />
              <ListItem slot="list">
                <Row className="w-100">
                  <Col className="item-input">
                    <div className="item-title item-label item-floating-label">
                      {t("Publication date")}
                    </div>
                    <Input
                      className="input-wrap"
                      name="publishDate"
                      type="date"
                      placeholder=""
                      clearButton
                      onBlur={this.blurInputHandle}
                      defaultValue={convertISODateToInputDate(
                        product.publishDate.toString()
                      )}
                      {...this.getErrorProps("publishDate", {
                        required: false,
                        errorMessage: t(
                          "Publish date must be lower than expiration date."
                        ),
                      })}
                    />
                  </Col>
                  <Col className="item-input">
                    <div className="item-title item-label item-floating-label">
                      {t("Expiration date")}
                    </div>
                    <Input
                      className="input-wrap"
                      name="expirationDate"
                      type="date"
                      placeholder=""
                      clearButton
                      onBlur={this.blurInputHandle}
                      defaultValue={convertISODateToInputDate(
                        product.expirationDate.toString()
                      )}
                      {...this.getErrorProps("expirationDate", {
                        required: false,
                        errorMessage: t(
                          "Expiration date must be upper than publish date."
                        ),
                      })}
                    />
                  </Col>
                </Row>
              </ListItem>
            </List>
          </>
        );
      default:
        return null;
    }
  };

  renderNextButton = () => {
    const { step } = this.state;
    return (
      <Fab position="right-bottom" onClick={this.nextStepHandle} slot="fixed">
        <Icon
          ios={step < 3 ? "f7:arrow_right" : "f7:checkmark_alt"}
          md={step < 3 ? "material:arrow_forward" : "material:check"}
        />
      </Fab>
    );
  };

  closeHandle = (backLink?: boolean) => {
    const { t } = this.props;
    const { step } = this.state;

    if (backLink && step > 1) {
      this.$f7router.back();
      return;
    }

    this.$f7.dialog.confirm(
      t(
        "All changes will be lost_Are you sure you would like to exit screen and discard the changes?"
      ),
      () => this.$f7router.back("/profile/my-goods/")
    );
  };

  render() {
    const { product, t } = this.props;
    let title = product ? (product.uid ? "Edit product" : "Add product") : "";

    return (
      <Page
        id="product_create"
        name="product-create"
        onPageInit={this.pageInitHandle}
        onPageBeforeRemove={this.handlePageBeforeRemove}
      >
        <Navbar noHairline noShadow>
          <NavLeft>
            <Link iconOnly onClick={() => this.closeHandle(true)}>
              <Icon className="icon-back" />
            </Link>
          </NavLeft>
          <NavTitle>{t(title)}</NavTitle>
          <NavRight>
            <Link iconOnly onClick={() => this.closeHandle()}>
              <Icon ios="f7:multiply" md="material:close" />
            </Link>
          </NavRight>
        </Navbar>

        {this.renderSteps()}
        {this.renderNextButton()}
      </Page>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  categories: state.categoryReducer.flat,
  category:
    state.rootReducer.localConfig &&
    state.rootReducer.localConfig.categories.filter(
      (item) => item.id === state.productCreateReducer.chosenCategoryId
    )[0],
  chosenCategoryId: state.productCreateReducer.chosenCategoryId,
  loading: state.productCreateReducer.loading,
  saving: state.productCreateReducer.saving,
  error: state.productCreateReducer.error,
  formErrors: state.productCreateReducer.formErrors,
  product: state.productCreateReducer.product,
  files: state.productCreateReducer.files,
  profile: state.sessionReducer.profile,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateProductDraft: (item: IProduct, reset: boolean) =>
    dispatch(updateProductDraft(item, reset)),
  chooseCategory: (catid?: string) => dispatch(chooseCategory(catid)),
  chooseSubCategory: (catid?: string) => dispatch(chooseSubcategory(catid)),
  createSaveProduct: (
    item: IProduct,
    files: IProductCreateUploadedFileInfo[]
  ) => dispatch(createSaveProduct(item, files)),
  attachFile: (index: number, file: File) => dispatch(attachFile(index, file)),
  detachFile: (index: number, productUid: string) =>
    dispatch(detachFile(index, productUid)),
});

export default compose(
  withTranslation(),
  connectCurrencies,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductCreatePage);
