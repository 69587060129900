import React from "react";
import { List, ListItem } from "framework7-react";
import { compose, Dispatch } from "redux";
import connectCategories from "../../store/connectCategories";
import { ICategory, IApplicationStore } from "../../store/rootReducer";
import connectFilter from "../../store/connectFilter";
import { ICategoryClassificator } from "../../reducers/categoryReducer";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import classNames from "classnames";

import "./style.less";
import { Dom7 } from "framework7";
import { chooseCategorySubcategory } from "../../actions/filterActions";

type Props = WithTranslation & {
  className?: string;
  categories?: ICategory[];
  chosenCategoryId?: string;
  chosenSubcategoryId?: string;
  chosenSubcategory?: ICategoryClassificator;
  categoryOnClick?(catid: string): void;
  clearFilterOnClick?(): void;
  opened?: boolean;
  subcategories?: ICategoryClassificator[];
  chooseCategorySubcategory?(catid?: string, subcatid?: string): void;
  onLeave?(): void;
};

type State = {
  subCatHovered?: string;
};

class CategoriesMenuDesktop extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.state = { subCatHovered: null };
  }

  componentDidMount() {
    /* TODO */
    const $el = Dom7(".categories-menu-desktop-n");
    $el.on("mouseover", ".item-link", this.handleMouseOver);
    $el.on("mouseleave", this.handleMouseLeave);
  }

  handleMouseOver(ev) {
    const $el = Dom7(ev.target).closest("a");
    const id = $el.find(".item-title span").attr("data-id");

    this.setState((state) => {
      const { subCatHovered } = state;
      if (subCatHovered !== id) return { ...state, subCatHovered: id };
      return state;
    });
  }

  handleMouseLeave() {
    this.closeHandle();
  }

  closeHandle = () => {
    this.props.onLeave();
  };

  getCategoryTitle = (code: string) => {
    const category = this.props.subcategories.filter((item) => item.categoryCode === code);
    return category.length > 0 ? category[0].categoryName : null;
  };

  render() {
    const {
      className,
      categories,
      chooseCategorySubcategory,
      opened,
      subcategories,
    } = this.props;

    const { subCatHovered } = this.state;

    return (
      <div
        className={classNames(
          "categories-menu-desktop-n",
          className,
          opened && "opened"
        )}
      >
        <div className="inner-container">
          <List className="categories" noHairlines noHairlinesBetween>
            {categories.map(
              (item) =>
                item.id !== "all_filtres" && (
                  <ListItem
                    link="#"
                    key={item.id}
                    className={classNames(
                      "no-ripple",
                      subCatHovered === item.id && "hovered"
                    )}
                  >
                    <span
                      slot="media"
                      className="category-item"
                      style={getItemStyles(item)}
                    >
                      <i className={`icon ${item.icon}`}/>
                    </span>

                    <span slot="title" data-id={item.id}>
                      {this.getCategoryTitle(item.id)}
                    </span>

                    {/*
                  <ul className="subcategories">
                    {subcategories
                      .filter((_item) => _item.categoryCode === item.id)
                      .map((item) => {
                        return item.children.map((item) => {
                          return (
                            <li key={item.categoryCode} className="sub-item">
                              <div className="category-title">
                                <a>{item.categoryName}</a>
                              </div>
                              <ul>
                                {flattenDeep(item.children).map((item) => (
                                  <div className="sub-title">
                                    <a>{item.categoryName}</a>
                                  </div>
                                ))}
                              </ul>
                            </li>
                          );
                        });
                      })}
                  </ul>
                  */}
                  </ListItem>
                )
            )}
          </List>

          <div className="subcategories-container">
            <ul className="subcategories">
              {subcategories
                .filter((item) => item.categoryCode === subCatHovered && item.productCount > 0)
                .map((item) => {
                  return item.children
                    .filter((item) => item.productCount > 0)
                    .map((item) => {
                    return (
                      <li key={item.categoryCode} className="sub-item">
                        <div className="category-title">
                          <a
                            onClick={() =>
                              chooseCategorySubcategory(
                                subCatHovered,
                                item.categoryCode
                              )
                            }
                          >
                            {item.categoryName} ({item.productCount})
                          </a>
                        </div>
                        <ul>
                          {flattenDeep(item.children)
                            .filter((item) => item.productCount > 0)
                            .map((_item) => (
                            <div key={_item.categoryCode} className="sub-title">
                              <a
                                onClick={() => {
                                  chooseCategorySubcategory(
                                    subCatHovered,
                                    _item.categoryCode
                                  );
                                }}
                              >
                                {_item.categoryName} ({_item.productCount})
                              </a>
                            </div>
                          ))}
                        </ul>
                      </li>
                    );
                  });
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => {
  const { chosenSubcategoryId } = state.filterReducer;
  return {
    subcategories: state.categoryReducer.categories,
    chosenSubcategory: state.categoryReducer.flat
      ? state.categoryReducer.flat.filter(
          (item) => item.categoryCode === chosenSubcategoryId
        )[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  chooseCategorySubcategory: (catid?: string, subcatid?: string) =>
    dispatch(chooseCategorySubcategory(catid, subcatid)),
});

const connectChosenSubcategory = connect(mapStateToProps, mapDispatchToProps);

export default compose<any>(
  withTranslation(),
  connectCategories,
  connectChosenSubcategory,
  connectFilter
)(CategoriesMenuDesktop);

// Helpers
// @ts-ignore
const getCategoriesClasses = (empty: boolean) =>
  `categories${empty ? " empty" : ""}`;
export const getItemStyles = (item: ICategory) => ({
  backgroundColor: item.color,
});

function flattenDeep(arr1: ICategoryClassificator[]): ICategoryClassificator[] {
  return arr1;
  /*
  return arr1.reduce(
    (acc, val) =>
      val.children ? acc.concat(flattenDeep(val.children)) : acc.concat(val),
    []
  );
  */
}
