import React, { Component } from "react";
import {
  Block,
  Checkbox,
  Col,
  Fab,
  Icon,
  Link,
  List,
  ListItem,
  Navbar,
  NavRight,
  NavTitle,
  Page,
  Popup,
  Row,
} from "framework7-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import { ListInput } from "../components/ListInput";
import { IApplicationStore, ResizeEvent } from "../store/rootReducer";
import { connect } from "react-redux";
import { savePaymentCard } from "../actions/paymentCardsActions";
import { SavedCard } from "../reducers/paymentCardsReducer";
import connectCards, { ICardsProps } from "../store/connectCards";
import { isValidCardExpDate, isValidCardFormat } from "../utils";
import { IcFramecard, IcMastercard, IcVisa } from "../components-ui/icons";

import "./payment-card-create.less";
import cloneDeep from "lodash/cloneDeep";
import { Address, Country } from "../types/commonapi";
import { Button } from "../components/ThemedButton";

const cardIcons = {
  unknown: <IcFramecard />,
  visa: <IcVisa />,
  mastercard: <IcMastercard />,
};

const IcCard = ({ cardType = "unknown" }) => (cardIcons[cardType] ? cardIcons[cardType] : cardIcons["unknown"]);

type Props = WithTranslation &
  ICardsProps & {
    saveCardLoading: boolean;
    saveCardError: any;
    savedCard: SavedCard;
    saveCard?(card: SavedCard): () => void;
    saveCardByDefault?: boolean;
    onSaveCard?(card: SavedCard): void;
    showAddressFields?: boolean;
    country?: Country;
    countryClassificator: Country[];
    resizeEvent: ResizeEvent;
    setPaymentCardCreateOpened?: (boolean) => void;
  };

type State = Pick<SavedCard, "cardNumber" | "cvv" | "cardHolder"> &
  Pick<Address, "country" | "city" | "firstAddressLine" | "postalCode"> & {
    expireDate: string;
    formErrors?: any;
    formValidFields?: any;
    formValid: boolean;
    cardType?: string;
    saveCard?: boolean;
  };

const initialState: State = {
  cardNumber: "",
  expireDate: "",
  cvv: "",
  cardHolder: "",
  country: "",
  city: "",
  firstAddressLine: "",
  postalCode: "",
  formErrors: {
    cardNumber: "",
    expireDate: "",
    cvv: "",
    cardHolder: "",
    country: "",
    city: "",
    firstAddressLine: "",
    postalCode: "",
  },
  formValidFields: {
    cardNumber: false,
    expireDate: false,
    cvv: false,
    cardHolder: false,
    country: false,
    city: false,
    firstAddressLine: false,
    postalCode: false,
  },
  formValid: false,
  cardType: "unknown",
};

class PaymentCardCreatePage extends Component<Props & Popup.Props, State> {
  constructor(props: Readonly<WithTranslation & Props & Popup.Props>) {
    super(props);
    this.state = {
      ...cloneDeep(initialState),
      saveCard: props.saveCardByDefault || false,
      country: props.country,
    };

    if (props.showAddressFields) {
      this.state.formValidFields.country = false;
      this.state.formValidFields.city = false;
      this.state.formValidFields.firstAddressLine = false;
      this.state.formValidFields.postalCode = false;

      if (props.country) {
        this.state.formValidFields.country = true;
      }
    }
  }
  handlePopupOpen = (instance: any) => {
    this.setState({ ...cloneDeep(initialState) });
    if (this.props.onPopupOpen) this.props.onPopupOpen(instance);
  };

  componentDidUpdate(prevProps: Props) {
    const { saveCardLoading, saveCardError, t } = this.props;

    if (saveCardLoading && !prevProps.saveCardLoading) {
      this.$f7.preloader.show();
    } else if (!saveCardLoading && prevProps.saveCardLoading) {
      this.$f7.preloader.hide();
    }

    if (saveCardError && saveCardError !== prevProps.saveCardError) {
      this.$f7.dialog.alert(saveCardError);
    }

    if (!saveCardLoading && !saveCardError && prevProps.saveCardLoading) {
      this.$f7.dialog.alert(t("CreditCardSavedSuccess"), () => this.$f7.popup.close());
    }
  }

  handleBlurInput = (e: any) => this.handleUserInput(e);

  handleUserInput = (e: any) => {
    let { name, value, rawValue = null } = e.target;
    value = rawValue !== null && name !== "expireDate" ? rawValue : value;
    // @ts-ignore
    this.setState({ [name]: value }, () => this.validateField(name, value));
  };

  handleInputClear = (e: any) => {
    let { name } = e.target;
    // @ts-ignore
    this.setState({ [name]: "" }, () => this.validateField(name, ""));
  };

  validateField = (fieldName: keyof State, value: string) => {
    const { t } = this.props;

    const { formValidFields, formErrors } = this.state;

    let errorText = "";
    let requiredFieldErrorText = t("Please fill out this field.");

    if (String(value).length === 0) {
      formErrors[fieldName] = requiredFieldErrorText;
      formValidFields[fieldName] = false;
      return this.setState({ formErrors, formValidFields }, this.validateForm);
    }

    switch (fieldName) {
      case "cardNumber":
        if (!isValidCardFormat(value)) {
          errorText = t("You entered the number incorrectly. Check it out and try again.");
        }
        break;
      case "expireDate":
        if (!isValidCardExpDate(value)) {
          errorText = t("Enter a valid date.");
        }
        break;
      case "cvv":
        if (value.length < 3) {
          errorText = t("Enter the correct CVV.");
        }
        break;
      case "cardHolder":
        if (!value.match(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/i)) {
          errorText = t("Latin characters only.");
        }
        break;
      case "city":
        if (!new RegExp("^[A-Za-z -]+$").test(value)) {
          errorText = t("Enter a valid City.");
        }
        break;
      case "firstAddressLine":
        if (!new RegExp("^[\\w \\\\.,@#\\-//]+$").test(value)) {
          errorText = t("Enter a valid Address.");
        }
        break;
      case "postalCode":
        if (value.length < 3) {
          errorText = "Minimum number of characters is 3";
        } else if (!new RegExp("^[A-Za-z0-9 ]+$").test(value)) {
          errorText = t("Enter a valid Postal Code.");
        }
        break;
    }

    formErrors[fieldName] = errorText || "";
    formValidFields[fieldName] = !errorText;
    this.setState({ formErrors, formValidFields }, this.validateForm);
  };

  validateForm = () => {
    this.setState({
      formValid: !this.formHasErrors(this.state.formValidFields),
    });
  };

  formHasErrors = (formValidFields: any) => {
    return Object.keys(formValidFields).some((key) => !formValidFields[key]);
  };

  getErrorMessagesProps = (fieldName: string) => {
    const { t } = this.props;
    return {
      errorMessage: t(this.state.formErrors[fieldName]),
      errorMessageForce: !!this.state.formErrors[fieldName],
    };
  };

  mapStateToEntity = (): SavedCard => {
    const { cardNumber, expireDate, cvv, cardHolder, saveCard, cardType } = this.state;
    const monthYearParts = expireDate.split("/");
    let savedCard: SavedCard = {
      cardNumber,
      expMonth: parseInt(monthYearParts[0]),
      expYear: parseInt(monthYearParts[1]),
      cvv,
      cardHolder,
      saveCard,
      type: cardType,
    };

    const { country, postalCode, city, firstAddressLine } = this.state;
    if (country || postalCode || city || firstAddressLine) {
      savedCard.address = {
        country,
        postalCode,
        city,
        firstAddressLine,
      };
    }

    return savedCard;
  };

  saveCardHandle = () => {
    const { saveCardLoading } = this.props;
    if (saveCardLoading || !this.state.formValid) {
      Object.keys(this.state.formValidFields).forEach((key) => {
        // @ts-ignore
        this.validateField(key, this.state[key]);
      });
      return;
    }
    const savedCard = this.mapStateToEntity();
    this.props.saveCard(savedCard);
    this.props.setPaymentCardCreateOpened(false);
  };

  handleCardTypeChanged = (cardType: string) => this.setState({ cardType });

  render() {
    const {
      saveCardLoading,
      t,
      saveCardByDefault,
      showAddressFields,
      resizeEvent: { isLG, isMD, isXL },
      ...rest
    } = this.props;
    const { cardType, saveCard } = this.state;
    const countries = this.props.countryClassificator;
    const isSmallScreen = !isLG && !isMD && !isXL;

    return (
      <Popup
        id="payment_card_create"
        swipeToClose={rest.swipeToClose || true}
        {...rest}
        onPopupOpen={this.handlePopupOpen}
      >
        <Page>
          <Navbar noHairline noShadow>
            <NavTitle sliding>{t("Add card")}</NavTitle>
            <NavRight>
              <Link popupClose>{t("Close")}</Link>
            </NavRight>
          </Navbar>
          <List noHairlinesMd form className="no-margin-top">
            <ListInput
              className="credit-card-list-item"
              name="cardNumber"
              label={t("Card Number").toString()}
              floatingLabel
              type="tel"
              maxlength={24}
              placeholder=""
              clearButton
              slot="list"
              cleaveFormatInputOptions={{
                creditCard: true,
                onCreditCardTypeChanged: this.handleCardTypeChanged,
              }}
              disabled={saveCardLoading}
              required
              onBlur={this.handleBlurInput}
              onInputClear={this.handleInputClear}
              value={this.state.cardNumber}
              {...this.getErrorMessagesProps("cardNumber")}
            >
              <div slot="input" className="card-type">
                <IcCard cardType={cardType} />
              </div>
            </ListInput>
            <ListItem slot="list" className="no-content-after">
              <Row>
                <Col>
                  <ListInput
                    name="expireDate"
                    label={t("MM/YY").toString()}
                    floatingLabel
                    type="tel"
                    placeholder=""
                    clearButton
                    wrap={false}
                    cleaveFormatInputOptions={{
                      datePattern: ["m", "y"],
                      date: true,
                    }}
                    disabled={saveCardLoading}
                    required
                    onBlur={this.handleBlurInput}
                    onInputClear={this.handleInputClear}
                    value={this.state.expireDate}
                    {...this.getErrorMessagesProps("expireDate")}
                  />
                </Col>
                <Col>
                  <ListInput
                    name="cvv"
                    label={t("CVV").toString()}
                    floatingLabel
                    type="tel"
                    maxlength={4}
                    placeholder=""
                    clearButton
                    wrap={false}
                    cleaveFormatInputOptions={{ blocks: [3] }}
                    disabled={saveCardLoading}
                    required
                    onBlur={this.handleBlurInput}
                    onInputClear={this.handleInputClear}
                    value={this.state.cvv}
                    {...this.getErrorMessagesProps("cvv")}
                  />
                </Col>
              </Row>
            </ListItem>
            <ListInput
              name="cardHolder"
              label={t("Card Holder").toString()}
              floatingLabel
              type="text"
              placeholder=""
              clearButton
              slot="list"
              disabled={saveCardLoading}
              required
              onBlur={this.handleBlurInput}
              onChange={this.handleUserInput}
              onInputClear={this.handleInputClear}
              value={this.state.cardHolder}
              {...this.getErrorMessagesProps("cardHolder")}
            />
          </List>
          {showAddressFields && (
            <List noHairlinesMd form className="no-margin-top">
              <ListInput
                name="country"
                label={t("Billing Address Country").toString()}
                type="select"
                placeholder=""
                slot="list"
                required
                disabled={saveCardLoading}
                onBlur={this.handleBlurInput}
                onChange={this.handleUserInput}
                value={this.state.country}
                {...this.getErrorMessagesProps("country")}
              >
                <option key={""} value={""} />
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </ListInput>
              <ListInput
                name="city"
                label={t("Billing Address City").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                maxlength={30}
                required
                disabled={saveCardLoading}
                onBlur={this.handleBlurInput}
                onChange={this.handleUserInput}
                onInputClear={this.handleInputClear}
                value={this.state.city}
                {...this.getErrorMessagesProps("city")}
              />
              <ListInput
                name="firstAddressLine"
                label={t("Billing Address Address Line").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                max={35}
                required
                disabled={saveCardLoading}
                onBlur={this.handleBlurInput}
                onChange={this.handleUserInput}
                onInputClear={this.handleInputClear}
                value={this.state.firstAddressLine}
                {...this.getErrorMessagesProps("firstAddressLine")}
              />
              <ListInput
                name="postalCode"
                label={t("Billing Address Postal Code").toString()}
                floatingLabel
                type="text"
                placeholder=""
                clearButton
                slot="list"
                minlength={3}
                maxlength={10}
                required
                disabled={saveCardLoading}
                onBlur={this.handleBlurInput}
                onChange={this.handleUserInput}
                onInputClear={this.handleInputClear}
                value={this.state.postalCode}
                {...this.getErrorMessagesProps("postalCode")}
              />
            </List>
          )}
          {!saveCardByDefault && (
            <Block>
              <Checkbox
                name="save-card"
                // value={saveCard}
                checked={saveCard}
                onChange={() => this.setState({ saveCard: !this.state.saveCard })}
              >
                {t("Save this card for future purchases")}
              </Checkbox>
            </Block>
          )}
          {isSmallScreen && (
            <Fab position="right-bottom" onClick={this.saveCardHandle} slot="fixed">
              <Icon ios="f7:checkmark_alt" md="material:check" />
            </Fab>
          )}
          {!isSmallScreen && (
            <Block className="text-align-center">
              <Button
                round
                large
                fill
                className="save-card-button"
                onClick={this.saveCardHandle}
                disabled={saveCardLoading}
              >
                {t("Save")}
              </Button>
            </Block>
          )}
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  ...state.paymentCardsReducer,
  countryClassificator: state.classificatorReducer.countryClassificator,
  resizeEvent: state.rootReducer.resizeEvent,
});

const mapDispatchToProps = (dispatch: any, props: Props) => ({
  saveCard: (card: SavedCard) =>
    typeof props.onSaveCard !== "undefined" ? props.onSaveCard(card) : dispatch(savePaymentCard(card)),
});

export default compose(
  withTranslation(),
  connectCards,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentCardCreatePage) as React.ComponentClass<
  Popup.Props & {
    saveCardByDefault?: boolean;
    showAddressFields?: boolean;
    onSaveCard?(card: SavedCard): void;
  }
>;
