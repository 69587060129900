import React, { FC, useEffect, useRef, useState } from "react";
import { BlockTitle, F7Sheet, Block, Icon, List, ListItem, Stepper, f7 } from "framework7-react";
import { useTranslation } from "react-i18next";
import { Sheet } from "../components/Sheet";
import { Button } from "../components/ThemedButton";
import { IProduct } from "../reducers/productReducer";
import { ProductParamsForm } from "../components/product-params-form";
import "./product-details.less";

type ProductDetailsProps = F7Sheet.Props & {
  item: IProduct;
  onAddToCartClick?(count: number, productParams: any[]): void;
};

const ProductDetailsAddToCartSheetNew: FC<ProductDetailsProps> = (props: ProductDetailsProps) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const [addToCartSheetItemCount, setAddToCartSheetItemCount] = useState<number>(1);
  const [addToCartSheetInputValue, setAddToCartSheetInputValue] = useState<number>(1);
  const [sheetOpen, setSheetOpen] = useState<boolean>(props.opened);
  const [formValid, setFormValid] = useState<boolean>(false);

  const { item, onSheetOpen, onSheetClose, onSheetClosed, onAddToCartClick, ...rest } = props;
  if (!item) return null;
  const { type, quantity, discountedPrice, price, currencyCode } = item;
  const isInputType: boolean = addToCartSheetItemCount === Infinity;
  const itemCount = type === "S" ? 10 : type === "I" ? 999 : quantity;
  const getItemCountList = (popoverQty, itemType) => {
    const data = [];
    if (popoverQty) {
      const isShowPlus = itemType === "P" || itemType === "I";

      for (let i = 0; i < popoverQty; i++) {
        if (popoverQty > 10 && i === 9) {
          data.push({
            value: isShowPlus ? Infinity : i + 1,
            title: `${i + 1}${isShowPlus ? "+" : ""}`,
          });
          break;
        }
        data.push({
          value: i + 1,
          title: i + 1,
        });
      }
    }
    return data;
  };
  const popoverQtyData = getItemCountList(itemCount, type);
  const commonPrice = addToCartSheetInputValue * (discountedPrice || price);
  const {
    item: { productParams },
  } = props;

  const handleChangeValue = (e) => {
    const value = e.target.value;
    const reg = /^[0-9]+$/;

    if ((value > 0 && value < 1000 && reg.test(value)) || value === "") {
      setAddToCartSheetInputValue(value);
    }
  };

  const handleAddToCart = () => {
    if (item.productParams?.length) {
      if (!formValid) {
        f7.dialog.alert(t("Check your fields!"));
        return;
      }
    }
    onAddToCartClick(addToCartSheetItemCount, productParams);
  };

  return (
    <Sheet
      id="add_to_cart__sheet"
      opened={sheetOpen}
      className="add-to-cart__sheet"
      swipeToClose={false}
      backdrop
      {...rest}
      onSheetOpen={(instance?: any) => {
        if (onSheetOpen) {
          onSheetOpen(instance);
        }
        setSheetOpen(true);
        setAddToCartSheetItemCount(1);
      }}
      onSheetClose={(instance?: any) => {
        if (onSheetClose) {
          onSheetClose(instance);
        }
      }}
      onSheetClosed={(instance?: any) => {
        if (onSheetClosed) {
          onSheetClosed(instance);
        }
        setSheetOpen(false);
        setAddToCartSheetItemCount(1);
        setFormValid(false);
      }}
    >
      <BlockTitle medium>{t("Item options")}</BlockTitle>
      <BlockTitle medium>
        {t("Choose Quantity")}
        <div className="add-to-cart__sheet-close-icon" onClick={onSheetClosed}>
          <Icon material="clear" />
        </div>
      </BlockTitle>
      <BlockTitle className="item-title">{t("Choose quantity")}</BlockTitle>
      <BlockTitle className="item-title">{`${t("Available")}: ${itemCount}`}</BlockTitle>
      <Block>
        <Stepper
          className="add-product-stepper"
          onStepperChange={(val: any) => {
            setAddToCartSheetItemCount(val);
          }}
          min={1}
          max={itemCount}
          value={addToCartSheetItemCount}
        />
      </Block>

      {productParams && productParams.length && (
        <ProductParamsForm
          productParams={productParams}
          onChangeForm={(productParams, formValid) => setFormValid(formValid)}
        />
      )}

      {item && (
        <Block className="add-to-cart__sheet-list">
          <List noHairlines noHairlinesBetween style={{ marginTop: 0 }}>
            {popoverQtyData.map((elem, index) => (
              <ListItem
                title={elem.title}
                key={index}
                onClick={() => {
                  setAddToCartSheetItemCount(elem.value);
                  setAddToCartSheetInputValue(elem.value);
                }}
              >
                {(elem.value === +addToCartSheetItemCount ||
                  (elem.value === Infinity && addToCartSheetItemCount > 9)) && (
                  <Icon material="done" className="selected-icon" />
                )}
              </ListItem>
            ))}
          </List>
        </Block>
      )}

      <Block className="add-to-cart__sheet-confirmation">
        {isInputType && (
          <div className={`add-to-cart__sheet-confirmation-input ${addToCartSheetInputValue ? "active" : ""}`}>
            <form>
              {addToCartSheetInputValue && <span>{t("Count of Product")}</span>}
              <input
                type="number"
                pattern="\d*"
                inputMode="decimal"
                ref={inputRef}
                value={addToCartSheetInputValue}
                onChange={handleChangeValue}
                onFocus={() => {
                  document.querySelector("#add_to_cart__sheet").classList.toggle("active");
                }}
                onBlur={() => {
                  document.querySelector("#add_to_cart__sheet").classList.toggle("active");
                }}
                placeholder={t("Count of Product")}
              />
            </form>
          </div>
        )}

        <Button
          className="add-to-cart__sheet-confirmation-button"
          disabled={!addToCartSheetInputValue}
          large
          fill
          round
          onClick={handleAddToCart}
        >
          <span>{`${commonPrice.toFixed(2)} ${currencyCode}`}</span>
          {t("Add to cart")}
        </Button>
      </Block>
    </Sheet>
  );
};

export default ProductDetailsAddToCartSheetNew;
