import React from "react";
import { Popup, F7Popup, Page, Navbar, NavRight, Link, Block, ListInput, List, Button } from "framework7-react";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { changePassword, loginWithOneTimePassword, oneTimePassword } from "../../actions/sessionActions";
import { Profile } from "../../reducers/sessionReducer";
import { getProfile } from "../../selectors/profile";
import { IApplicationStore } from "../../store/rootReducer";
import { withTranslation, WithTranslation } from "react-i18next";

import "./style.less";

type Props = F7Popup.Props &
  Pick<WithTranslation, "t"> & {
    profile?: Profile;
    loading: boolean;
    sending: boolean;
    changing: boolean;
    error: any;
    oneTimePassword?(email: string): void;
    loginWithCode?(email: string, code: string): void;
    changePassword?(password: string): void;
  };

type State = {
  email: string;
  code: string;
  password: string;
  passwordRepeat: string;
  step: string;
  showResend: boolean;
};

const initialState: State = {
  email: "",
  code: "",
  password: "",
  passwordRepeat: "",
  step: "init",
  showResend: false,
};

class ForgotPasswordPopup extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = initialState;
  }

  _resendTimeout: any;

  componentDidUpdate(prevProps: Props) {
    const { t, sending, loading, changing, error } = this.props;
    if (this.props.opened) {
      if (!sending && prevProps.sending) {
        this.processStepResult("code", error);
      }

      if (!loading && prevProps.loading) {
        this.processStepResult("password", error);
      }

      if (!changing && prevProps.changing) {
        this.$f7.preloader.hide();

        if (error) {
          this.showError(error);
        } else {
          this.clearState();
          this.$f7.popup.close();
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  sendOneTimePassword() {
    const { t } = this.props;

    const { email } = this.state;
    if (!email) {
      this.$f7.dialog.alert(t("Please fill out email field"));
      return;
    }

    this.activateResendTimeout();

    this.$f7.preloader.show();
    this.props.oneTimePassword(email);
  }

  loginWithCode() {
    const { t } = this.props;

    const { email, code } = this.state;
    if (!code) {
      this.$f7.dialog.alert(t("Please fill out code field"));
      return;
    }

    this.$f7.preloader.show();
    this.props.loginWithCode(email, code);
  }

  changePassword() {
    const { t } = this.props;

    const { password, passwordRepeat } = this.state;
    if (!password || !passwordRepeat) {
      this.$f7.dialog.alert(t("Please fill out password fields"));
      return;
    }

    if (password != passwordRepeat) {
      this.$f7.dialog.alert(t("Please make sure your passwords match"));
      return;
    }

    this.$f7.preloader.show();
    this.props.changePassword(password);
  }

  activateResendTimeout = () => {
    this.setState({ showResend: false });

    clearTimeout(this._resendTimeout);

    this._resendTimeout = setTimeout(() => {
      clearTimeout(this._resendTimeout);

      this.setState({ showResend: true });
    }, 20000);
  };

  processStepResult = (step: string, error?: any) => {
    this.$f7.preloader.hide();

    if (error) {
      this.clearState();
      this.showError(error);
    } else {
      this.setState({ step: step });
    }
  };

  showError = (error: string) => {
    const { t } = this.props;

    if (error == "Client is not authorized") {
      error = "Incorrect code. Please try again.";
    }

    this.$f7.dialog.alert(t(error));
  };

  clearState = () => {
    this.setState(initialState);
  };

  getTitle = () => {
    const { t } = this.props;
    const { step } = this.state;
    switch (step) {
      case "code":
        return t("Enter Code");
      case "password":
        return t("Change Password");
      default:
        return t("Password Reset");
    }
  };

  render() {
    const { className, t, ...props } = this.props;
    const { step, email, code, password, passwordRepeat } = this.state;
    return (
      <Popup {...props} className={classNames("forgot-password-popup", className)}>
        <Page>
          <Navbar noShadow noHairline title={this.getTitle()}>
            <NavRight>
              <Link popupClose onClick={this.clearState}>
                {t("Close")}
              </Link>
            </NavRight>
          </Navbar>
          {step == "init" && (
            <Block>
              <Block className="step-hint">{t("Enter your e-mail and we will send you code")}</Block>
              <List noHairlines form>
                <ListInput
                  name="email"
                  label={t("E-mail").toString()}
                  floatingLabel
                  type="email"
                  placeholder=""
                  value={email}
                  onInput={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                />
              </List>
              <Block>
                <Button
                  fill
                  large
                  round
                  raised
                  onClick={this.sendOneTimePassword.bind(this)}
                  disabled={this.props.loading}
                >
                  {t("Continue")}
                </Button>
              </Block>
            </Block>
          )}
          {step == "code" && (
            <Block>
              <Block className="step-hint">
                {t("We have sent you the code to your email")} &nbsp;
                <strong>{email}</strong>
              </Block>
              <List noHairlines form>
                <ListInput
                  name="code"
                  label={t("Enter Code").toString()}
                  floatingLabel
                  type="text"
                  placeholder=""
                  slot="list"
                  value={code}
                  onInput={(e) => {
                    this.setState({ code: e.target.value });
                  }}
                />
              </List>
              <Block>
                <Button fill large round raised onClick={this.loginWithCode.bind(this)} disabled={this.props.loading}>
                  {t("Continue")}
                </Button>

                {this.state.showResend && (
                  <p className="sign-up-text">
                    {t("Did not get a Code?")} &nbsp;
                    <Link onClick={this.sendOneTimePassword.bind(this)}>{t("Resend")}</Link>
                  </p>
                )}
              </Block>
            </Block>
          )}
          {step == "password" && (
            <Block>
              <List noHairlines form>
                <ListInput
                  name="password"
                  label={t("New Password").toString()}
                  floatingLabel
                  type="password"
                  placeholder=""
                  clearButton
                  slot="list"
                  value={password}
                  onInput={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                />
                <ListInput
                  name="passwordRepeat"
                  label={t("Repeat Password").toString()}
                  floatingLabel
                  type="password"
                  placeholder=""
                  clearButton
                  slot="list"
                  value={passwordRepeat}
                  onInput={(e) => {
                    this.setState({ passwordRepeat: e.target.value });
                  }}
                />
              </List>
              <Block>
                <Button fill large round raised onClick={this.changePassword.bind(this)} disabled={this.props.loading}>
                  {t("Done")}
                </Button>
              </Block>
            </Block>
          )}
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  profile: getProfile(state),
  loading: state.sessionReducer.passwordLoginLoading,
  sending: state.sessionReducer.passwordResetLoading,
  changing: state.sessionReducer.passwordChangeLoading,
  error: state.sessionReducer.passwordError,
});

const mapDispatchToProps = (dispatch: any) => ({
  oneTimePassword: (email: string) => dispatch(oneTimePassword(email)),
  loginWithCode: (email: string, code: string) => dispatch(loginWithOneTimePassword(email, code)),
  changePassword: (password: string) => dispatch(changePassword(password)),
});

export default compose<any>(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ForgotPasswordPopup);
