import React, { FC, useEffect, useState } from "react";
import { Col, f7, Icon, Link, List, ListItem, Page, Stepper, Sheet, Button } from "framework7-react";
import { Price } from "../../../components/Price";
import { chain } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationStore, ResizeEvent } from "../../../store/rootReducer";
import { cartRemove, cartUpdate, setDeliveryInformation } from "../../../actions/cartActions";
import { analytics } from "../../../Setup";
import AddWishButton from "../../../components/AddWishButton";
import { addToWishList, loadProductWishList } from "../../../actions/productActions";
import { useTranslation } from "react-i18next";
import Dom7 from "dom7";
import { IClassificator } from "../../../reducers/classificatorReducer";
import { DeliveryMethod } from "../../../reducers/cartReducer";
import i18n from "../../../i18n";

// import { Sheet } from "../../../components/Sheet";

const config = process.env.CONFIG;

interface ItemListProps {
  showButton: boolean;
}
const ItemList: FC<ItemListProps> = ({ showButton }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //redux

  //selectors
  const itemsCart = useSelector((state: IApplicationStore) => state.cartReducer.items);
  const wishList = useSelector((state: IApplicationStore) => state.productReducer.productsWishList);
  const [itemInfoMoreSheetOpened, setItemInfoMoreSheetOpened] = useState<boolean>(false);
  const [itemInfoId, setItemInfoId] = useState<string>("");
  const isAddedToWishList = (uid: string) => wishList.filter((item) => item.uid === uid).length;
  const [isDeliveryUpdated, setIsDeliveryUpdated] = useState(false);
  //functions

  const handleMoveToWishListClick = () => {
    setItemInfoMoreSheetOpened(false);
    dispatch(addToWishList(itemInfoId));
    //analytics.addToWishListWithUid(this.props.profile, itemInfoId);
  };

  const handleRemoveGoodsClick = () => {
    setItemInfoMoreSheetOpened(false);
    const itemFound = this.props.itemsCart.filter((item) => item.product.uid === itemInfoId)[0];
    if (itemFound) {
      dispatch(cartRemove(itemFound.product, itemFound.count));
    }
  };

  useEffect(() => {
    Dom7(".cart-catalog li.product-item").on("taphold", (e) => {
      const $itemEl = Dom7(e.target).closest("li");
      if ($itemEl) {
        const id = $itemEl.attr("id");
        setItemInfoMoreSheetOpened(true);
        setItemInfoId(id);
      }
    });
  });

  const updateDelivery = () => {
    const result = itemsCart.filter((item) => item.product.type !== "S");
    if (result.length == 0) {
      dispatch(setDeliveryInformation({ code: "free", price: 0 }));
    } else {
      dispatch(setDeliveryInformation({ code: "fixed", price: 10.00 }));
    }
    setIsDeliveryUpdated(true);
  };

  useEffect(() => {
    if (!isDeliveryUpdated) {
      updateDelivery();
    }
  });

  const removeHandle = (uid: string) => {
    setIsDeliveryUpdated(false);
    const item = itemsCart.filter((item) => item.product.uid === uid)[0];
    console.log("itemsCart", itemsCart);
    if (item) {
      f7.dialog.confirm(t("Really?"), () => {
        dispatch(cartRemove(item.product, item.count));
        setIsDeliveryUpdated(false);
      });
    }
    dispatch(loadProductWishList());
    setItemInfoMoreSheetOpened(false);
  };

  const handleOnChangeStepper = (item: any, value: number) => {
    setIsDeliveryUpdated(false);
    if (value === 0) {
      dispatch(cartRemove(item, 1));
      return;
    }
    dispatch(cartUpdate(item, value));
  };
  const groupedItems = chain(itemsCart)
    .groupBy((item) => {
      item.product.sellerUid;
    })
    .map((value, key) => {
      return { key, items: value };
    })
    .value();

  return (
    <List className="cart-catalog" mediaList noHairlines noHairlinesBetween id="item_list">
      <ul>
        {groupedItems.map((g) => {
          return (
            <div key={g.key} className="manuf-group-wrapper">
              {config !== "marine" && (
                <li className="manuf-group media-item no-chevron">
                  <div className="item-content">
                    <div className="group-info">
                      <div className="group-icon" />
                      <div className="group-title">{g.key}</div>
                    </div>
                  </div>
                </li>
              )}
              {g.items.map(
                ({
                  product,
                  product: {
                    shortDescription,
                    uid,
                    name,
                    price,
                    discountedPrice,
                    currencyCode,
                    imageThumbnailUrl1,
                    productParams,
                  },
                  count,
                }) => {
                  return (
                    <ListItem key={uid} noChevron id={uid} className="product-item">
                      <div slot="media" className="image">
                        {imageThumbnailUrl1 && <img src={imageThumbnailUrl1} />}
                      </div>
                      <div slot="inner-start" className="item-title-row">
                        <div className="item-title">{name}</div>
                      </div>
                      <div slot="inner-end" className="item-text">
                        {shortDescription}
                      </div>
                      {productParams && productParams.length && (
                        <div slot="inner-end" className="item-text product-parameters">
                          {productParams.map(
                            (p, i) => `${p.name} ${p.value}${i !== productParams.length - 1 ? "," : ""}`
                          )}
                        </div>
                      )}
                      <div slot="content-end" className="price-stepper">
                        {showButton && (
                          <Link
                            className="icon-more"
                            iconOnly
                            iconMaterial="more_vert"
                            onClick={() => {
                              setItemInfoMoreSheetOpened(true);
                              setItemInfoId(uid);
                            }}
                          />
                        )}
                        <div className="stepper-container">
                          <Stepper
                            min={1}
                            value={count}
                            onStepperChange={(val) => handleOnChangeStepper(product, val)}
                          />
                        </div>
                        <div className="buttons-container pure-hidden-xs">
                          <Price
                            {...{
                              price,
                              discountedPrice,
                              currencyCode,
                            }}
                          />
                          {showButton && (
                            <>
                              <AddWishButton
                                onClick={() => {
                                  dispatch(addToWishList(uid));
                                }}
                                active={isAddedToWishList(uid)}
                              />
                              <Link onClick={() => removeHandle(uid)}>
                                <Icon slot="media" ios="f7:trash" md="material:delete" />
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </div>
          );
        })}
      </ul>
      <Sheet
        id="item_info_more_sheet"
        swipeToClose
        backdrop
        opened={itemInfoMoreSheetOpened}
        onSheetClosed={() => {
          setItemInfoMoreSheetOpened(false);
        }}
        // slot="fixed"
        style={{ height: "auto" }}
      >
        <List noHairlines noChevron noHairlinesBetween>
          <ListItem link="#" title={t("Remove Goods").toString()} onClick={() => removeHandle(itemInfoId)}>
            <Icon slot="media" material="delete_outline" />
          </ListItem>
        </List>
      </Sheet>
    </List>
  );
};

export default ItemList;
