import React from "react";
import classNames from "classnames";

import "./style.less";

export default (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => (
  <div
    {...props}
    className={classNames("badge", "badge-mini", props.className)}
  />
);
