import React, { FC, useEffect, useState } from "react";
import { BlockTitle, Button, f7, Link, Navbar, NavRight, Popup } from "framework7-react";
import { IApplicationStore } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import ShareButton from "../ShareButton";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

interface SharePopupProps {
  uid?: string;
}

import "./style.less";

const ShareComponent: FC<SharePopupProps> = ({ uid }) => {
  const share = useSelector((state: IApplicationStore) => state.shareReducer);
  const [url, setUrl] = useState<string>("");
  const [opened, setOpened] = useState<boolean>(false);
  const pageTitle = document.title;
  const appName = process.env.APP_NAME;
  useEffect(() => {
    const url = window.location.toString();
    setUrl(url.substr(0, url.indexOf("#") + 1) + `/product-details/${uid}/`);
  });
  return (
    <div>
      <ShareButton
        onClick={() => {
          setOpened(true);
        }}
      />
      <Popup
        id="share-container"
        swipeToClose
        swipeHandler=".swipe-handler"
        opened={opened}
        onPopupClosed={() => {
          setOpened(false);
        }}
      >
        <Navbar>
          <BlockTitle medium style={{ paddingBottom: "20px" }}>
            Share social network
          </BlockTitle>
          <NavRight>
            <Link popupClose>Close</Link>
          </NavRight>
        </Navbar>

        <div className="share-buttons-container">
          <div className="social-network">
            <EmailShareButton url={url} title={`${appName} : ${pageTitle}`}>
              <EmailIcon size={60} round />
            </EmailShareButton>
          </div>
          <div className="social-network">
            <WhatsappShareButton url={url} title={`${appName} : ${pageTitle}`}>
              <WhatsappIcon size={60} round />
            </WhatsappShareButton>
          </div>
          <div className="social-network">
            <FacebookShareButton url={url} title={`${appName} : ${pageTitle}`}>
              <FacebookIcon size={60} round />
            </FacebookShareButton>
          </div>
          <div className="social-network">
            <TelegramShareButton url={url} title={`${appName} : ${pageTitle}`}>
              <TelegramIcon size={60} round />
            </TelegramShareButton>
          </div>
        </div>
        <div className="url-container">
          <div className="url">{url}</div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(url);
              f7.dialog.preloader("Copied!");
              setTimeout(function () {
                f7.dialog.close();
              }, 1000);
            }}
          >
            copy
          </Button>
        </div>
      </Popup>
    </div>
  );
};

export default ShareComponent;
