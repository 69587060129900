import React from "react";

export default ({ large = false }) =>
  large ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="8" width="48" height="32" rx="8" fill="#EDEDED" />
      <path
        d="M28.4794 16.0989H20.1875V31.5277H28.4794V16.0989Z"
        fill="#FF5F00"
      />
      <path
        d="M20.7139 23.8134C20.7139 20.6785 22.1354 17.8981 24.3202 16.099C22.7145 14.7905 20.6876 14 18.4764 14C13.2381 14 9 18.3888 9 23.8134C9 29.238 13.2381 33.6267 18.4764 33.6267C20.6876 33.6267 22.7145 32.8362 24.3202 31.5278C22.1354 29.7559 20.7139 26.9482 20.7139 23.8134Z"
        fill="#EB001B"
      />
      <path
        d="M39.6667 23.8132C39.6667 29.2379 35.4286 33.6266 30.1902 33.6266C27.9791 33.6266 25.9522 32.8361 24.3464 31.5276C26.5576 29.7285 27.9527 26.9481 27.9527 23.8132C27.9527 20.6784 26.5313 17.898 24.3464 16.0989C25.9522 14.7904 27.9791 13.9999 30.1902 13.9999C35.4286 13.9999 39.6667 18.4159 39.6667 23.8132Z"
        fill="#F79E1B"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="4" width="32" height="24" rx="4" fill="white" />
      <path
        d="M19.5146 9.71118H12.4849V22.2888H19.5146V9.71118Z"
        fill="#FF5F00"
      />
      <path
        d="M12.9309 16C12.9309 13.4445 14.136 11.1779 15.9883 9.71121C14.627 8.64456 12.9086 8.00012 11.034 8.00012C6.59299 8.00012 3 11.5779 3 16C3 20.4222 6.59299 23.9999 11.034 23.9999C12.9086 23.9999 14.627 23.3555 15.9883 22.2888C14.136 20.8444 12.9309 18.5555 12.9309 16Z"
        fill="#EB001B"
      />
      <path
        d="M28.9996 15.9999C28.9996 20.4221 25.4066 23.9998 20.9655 23.9998C19.0909 23.9998 17.3726 23.3554 16.0112 22.2887C17.8858 20.8221 19.0686 18.5554 19.0686 15.9999C19.0686 13.4444 17.8635 11.1777 16.0112 9.71109C17.3726 8.64444 19.0909 8 20.9655 8C25.4066 8 28.9996 11.6 28.9996 15.9999Z"
        fill="#F79E1B"
      />
    </svg>
  );
