import React from "react";
import { Stepper } from "framework7-react";
import "./style.less";

const StepperComponent = (props) => {
  return (
    <div id="stepper-container">
      <Stepper {...props} />
    </div>
  );
};

export default StepperComponent;
