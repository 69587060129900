import moment from "moment";
import { IProduct } from "../reducers/productReducer";
import { client, paymentapiURL } from "../axios";
import {
  OrderWsControllerApi,
  PaymentApiOrder,
  CreditCardPaymentWsControllerApi,
  EWalletPaymentWsControllerApi,
  EWalletPaymentRequest,
  ProductOrder,
  SetOrderStatusRequest,
  PaymentParameter,
  CreditCardPaymentRequest,
  CardUidPaymentRequest,
} from "../types/paymentapi";
import { throwIsHasErrorData } from "./paymentCardsActions";
import { IApplicationStore } from "../store/rootReducer";
import { generateToken } from "../utils";
import { loadProductDetails } from "./productCreateActions";
import { loadMyCurrencies } from "./myCurrenciesActions";
import { SavedCard } from "../reducers/paymentCardsReducer";
import { PaymentMethods } from "../reducers/checkoutReducer";
import { Address, LinkedCard } from "../types/commonapi";
import { cartClear } from "./cartActions";
import { allFiltresClear, chooseCategorySubcategory, clearSortBy } from "./filterActions";

export const ORDERS_PROMO_ORDER_CREATING = "ORDERS_PROMO_ORDER_CREATING";
export const ORDERS_PROMO_ORDER_CREATING_SUCCESS = "ORDERS_PROMO_ORDER_CREATING_SUCCESS";
export const ORDERS_PROMO_ORDER_CREATING_ERROR = "ORDERS_PROMO_ORDER_CREATING_ERROR";
export const ORDER_CLEAR_ERROR_MESSAGE = "ORDER_CLEAR_ERROR_MESSAGE";

export const ORDERS_PRODUCTS_ORDER_CREATING = "ORDERS_PRODUCTS_ORDER_CREATING";
export const ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS = "ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS";
export const ORDERS_PRODUCTS_ORDER_CREATING_ERROR = "ORDERS_PRODUCTS_ORDER_CREATING_ERROR";

export const ORDERS_LOADING = "ORDERS_LOADING";
export const ORDERS_LOADING_SUCCESS = "ORDERS_LOADING_SUCCESS";
export const ORDERS_LOADING_ERROR = "ORDERS_LOADING_ERROR";

export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";

export const ORDER_CANCEL_START = "ORDER_CANCEL_START";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_ERROR = "ORDER_CANCEL_ERROR";

export const ORDER_CLEAR = "ORDER_CLEAR";

const mapProductParams = (item: IProduct) => {
  if (!item.productParams) return {};
  const parameters = {};
  item.productParams.forEach((product) => {
    // @ts-ignore
    if (product.value) parameters[product.code] = product.value;
  });
  return parameters;
};

interface IPaymentApiOrder {
  successUrl?: string;
}

export const createOrder = async (item: IProduct): Promise<IPaymentApiOrder> => {
  const { uid } = item;
  const postData = { productUid: uid, quantity: 1 };
  return await (
    await client.post(paymentapiURL + "/order/product/create", postData)
  ).data;
};

export const createPromotionOrder =
  (
    feature: string,
    _featureCurrency: string,
    productUid: string,
    options: {
      cardUid?: string;
      cvc?: string;
      walletUid?: string;
      source: "card" | "wallet";
    },
    savedCard?: SavedCard
  ) =>
  async (dispatch: any, getState: () => IApplicationStore) => {
    dispatch(ordersPromoOrderCreating());

    const { source, cardUid, cvc } = options;
    const state = getState();
    const wallet = state.myCurrenciesReducer.currencies.filter((item) => item.primary)[0];

    setTimeout(async () => {
      try {
        const createOrderUsingPOST3Result = await new OrderWsControllerApi().createProductFeatureOrderUsingPOST({
          feature,
          productUid,
          currencyCode: wallet.currency.code,
        });
        throwIsHasErrorData(createOrderUsingPOST3Result);
        const { order } = createOrderUsingPOST3Result;

        switch (source) {
          case "card": {
            const card = savedCard || state.paymentCardsReducer.cards.filter((item) => item.uid === cardUid)[0];

            /* TODO */
            const token = await generateToken({
              cardNumber: null,
              cvv: cvc,
              cardHolder: null,
              expMonth: null,
              expYear: null,
              ...card,
            });

            const data = {
              orderUid: order.uid,
              amount: order.amount,
              currencyCode: order.currencyCode,
              paymentMethod: "bankcard",
              token,
              saveCard: savedCard ? savedCard.saveCard : true,
            };

            if ((order.seller as any).email) {
              data["email"] = (order.seller as any).email;
            } else if ((order.seller as any).phone) {
              data["phone"] = (order.seller as any).phone;
            }
            const paymentUsingPOST1Result = await new CreditCardPaymentWsControllerApi().paymentUsingPOST1(data);

            throwIsHasErrorData(paymentUsingPOST1Result);
            break;
          }
          case "wallet": {
            const data: EWalletPaymentRequest = {
              orderUid: order.uid,
              amount: order.amount,
              currencyCode: order.currencyCode,
              paymentMethod: "wallet",
            };

            if ((order.seller as any).email) {
              data["email"] = (order.seller as any).email;
            } else if ((order.seller as any).phone) {
              data["phone"] = (order.seller as any).phone;
            }

            const paymentUsingPOST2Result = await new EWalletPaymentWsControllerApi().paymentUsingPOST2(data);

            throwIsHasErrorData(paymentUsingPOST2Result);
            dispatch(loadMyCurrencies());
            break;
          }
        }

        dispatch(loadProductDetails(productUid));
        dispatch(ordersPromoOrderCreatingSuccess(order));
      } catch (err) {
        dispatch(ordersPromoOrderCreatingError(err.toString()));
      }
    }, 2000);
  };

export const createProductsPurchaseOrder =
  (
    paymentMethod: PaymentMethods,
    deliveryAddress: Address,
    products: {
      product: IProduct;
      count: number;
    }[],
    options: {
      cardUid?: string;
      cvc?: string;
    },
    savedCard?: LinkedCard | SavedCard,
    dueDate?: string,
    paymentAddress?: Address,
    linkedCard?: boolean
  ) =>
  async (dispatch: any, getState: () => IApplicationStore) => {
    dispatch(ordersProductsOrderCreating());

    const state = getState();
    const profile = state.sessionReducer.profile;
    const amountToPay = state.checkoutReducer.amountToPay;

    let order: PaymentApiOrder | null = null;

    const { calculatedDelivery } = state.checkoutReducer;
    if (!calculatedDelivery) return;

    try {
      // let dueDateFormatted = null;
      //
      // if (dueDate) {
      //   const dueDateParsed = moment(dueDate, "YYYY-MM-DD");
      //   dueDateFormatted = dueDateParsed.format("YYYYMMDDHHmmss");
      // }
      //
      // if (dueDate === dueDateFormatted) console.log('blyaaaaaaa')

      const { bonusMoneyAmountForOrder } = calculatedDelivery;
      const request = {
        deliveryAddress: null,
        bonusMoneyAmount: bonusMoneyAmountForOrder > 0 ? bonusMoneyAmountForOrder : undefined,
        products: products.map((item) => ({
          productUid: item.product.uid,
          quantity: item.count,
          parameters: mapProductParams(item.product),
        })),
        // dueDate: dueDateFormatted,
        dueDate,
      };

      if (deliveryAddress?.city && deliveryAddress?.country) {
        request.deliveryAddress = {
          countryCode: deliveryAddress.country.code,
          city: deliveryAddress.city,
          firstAddressLine: deliveryAddress.firstAddressLine,
          postalCode: deliveryAddress.postalCode,
          secondAddressLine: deliveryAddress.secondAddressLine,
          state: deliveryAddress.state,
        };
      }
      const result = await new OrderWsControllerApi().createProductsPurchaseOrderUsingPOST(request);
      throwIsHasErrorData(result);
      order = result.order;

      if (paymentMethod === "bankcard" && amountToPay > 0) {
        const { cardUid, cvc } = options;
        if (!linkedCard) {
          if (savedCard && savedCard.address) delete savedCard.address;

          const card = savedCard || state.paymentCardsReducer.cards.find((item) => item.uid === cardUid);
          const token = await generateToken({
            cardNumber: null,
            cvv: cvc,
            cardHolder: null,
            expMonth: null,
            expYear: null,
            ...card,
          });

          const bankCardPaymentOptions: CreditCardPaymentRequest = {
            orderUid: order.uid,
            amount: order.amount, //order.amountTotal
            currencyCode: order.currencyCode,
            paymentMethod: "bankcard",
            token,
            saveCard: savedCard?.saveCard || false,
          };

          const primaryEmail = profile.accountEmails.find((item) => item.primary);
          if (primaryEmail) bankCardPaymentOptions["email"] = primaryEmail.email;
          else {
            const secondaryEmail = profile.accountEmails[0];
            if (secondaryEmail) bankCardPaymentOptions["email"] = secondaryEmail.email;
            else {
              const primaryPhone = profile.accountPhones.find((item) => item.primary);
              if (primaryPhone)
                bankCardPaymentOptions["phone"] = {
                  countryCode: primaryPhone.countryCode,
                  number: primaryPhone.number,
                };
            }
          }

          if (paymentAddress) {
            const parameters: PaymentParameter[] = [];
            if (paymentAddress.country) {
              const countryCode =
                typeof paymentAddress.country === "string" ? paymentAddress.country : paymentAddress.country.code;
              parameters.push({
                name: "country",
                value: countryCode,
              });
            }
            if (paymentAddress.city) {
              parameters.push({
                name: "city",
                value: paymentAddress.city,
              });
            }
            if (paymentAddress.postalCode) {
              parameters.push({
                name: "zipcode",
                value: paymentAddress.postalCode,
              });
            }
            if (paymentAddress.firstAddressLine) {
              parameters.push({
                name: "address",
                value: paymentAddress.firstAddressLine,
              });
            }
            bankCardPaymentOptions.parameters = parameters;
          }

          const result = await new CreditCardPaymentWsControllerApi().paymentUsingPOST1(bankCardPaymentOptions);
          throwIsHasErrorData(result);
        } else {
          const card = savedCard || state.paymentCardsReducer.cards.find((item) => item.uid === cardUid);
          const token = await generateToken({
            cardNumber: null,
            cvv: cvc,
            cardHolder: null,
            expMonth: null,
            expYear: null,
            ...card,
          });

          const bankCardPaymentOptions: CardUidPaymentRequest = {
            orderUid: order.uid,
            amount: order.amount,
            currencyCode: order.currencyCode,
            paymentMethod: "bankcard",
            token,
            cardUid: savedCard.creditCardUid,
          };

          const primaryEmail = profile.accountEmails.find((item) => item.primary);
          if (primaryEmail) bankCardPaymentOptions["email"] = primaryEmail.email;
          else {
            const secondaryEmail = profile.accountEmails[0];
            if (secondaryEmail) bankCardPaymentOptions["email"] = secondaryEmail.email;
            else {
              const primaryPhone = profile.accountPhones.find((item) => item.primary);
              if (primaryPhone)
                bankCardPaymentOptions["phone"] = {
                  countryCode: primaryPhone.countryCode,
                  number: primaryPhone.number,
                };
            }
          }

          if (paymentAddress) {
            const parameters: PaymentParameter[] = [];
            if (paymentAddress.country) {
              const countryCode =
                typeof paymentAddress.country === "string" ? paymentAddress.country : paymentAddress.country.code;
              parameters.push({
                name: "country",
                value: countryCode,
              });
            }
            if (paymentAddress.city) {
              parameters.push({
                name: "city",
                value: paymentAddress.city,
              });
            }
            if (paymentAddress.postalCode) {
              parameters.push({
                name: "zipcode",
                value: paymentAddress.postalCode,
              });
            }
            if (paymentAddress.firstAddressLine) {
              parameters.push({
                name: "address",
                value: paymentAddress.firstAddressLine,
              });
            }
            bankCardPaymentOptions.parameters = parameters;
          }
          const result = await new CreditCardPaymentWsControllerApi().paymentUsingPOST(bankCardPaymentOptions);
          throwIsHasErrorData(result);
        }
      }

      dispatch(ordersProductsOrderCreatingSuccess(order));
      dispatch(cartClear());
      dispatch(clearSortBy());
      dispatch(allFiltresClear());
    } catch (err) {
      dispatch(ordersProductsOrderCreatingError(err));
      if (order) dispatch(cancelOrder(order.uid));
    }
  };

export const loadOrders = () => async (dispatch: any, getState: () => IApplicationStore) => {
  dispatch(ordersLoadingAction());
  try {
    const state = getState();
    const language = state.rootReducer.language;
    const items = (await new OrderWsControllerApi().getProductOrderListUsingGET(true, language)).orderList || [];
    //const items = require('../static/mock/orders.json');
    dispatch(ordersLoadingSuccessAction(items, false));
  } catch (error) {
    dispatch(ordersLoadingErrorAction(error.toString()));
  }
};

export const cancelOrder = (uid: string) => async (dispatch: any) => {
  dispatch(orderCancelStartAction());
  try {
    const request: SetOrderStatusRequest = {
      uid: uid,
      status: "CA",
    };
    const response = await new OrderWsControllerApi().setOrderStatusUsingPOST(request);
    // @ts-ignore
    const order: PaymentApiOrder = response.order;
    dispatch(orderCancelSuccessAction(order));
  } catch (error) {
    dispatch(orderCancelErrorAction(error.toString()));
  }
};

export const selectOrder = (order: ProductOrder) => async (dispatch: any) => {
  dispatch(orderDetailsAction(order));
};

const ordersPromoOrderCreating = () => ({
  type: ORDERS_PROMO_ORDER_CREATING,
});

const ordersPromoOrderCreatingSuccess = (order: PaymentApiOrder) => ({
  type: ORDERS_PROMO_ORDER_CREATING_SUCCESS,
  order,
});

const ordersPromoOrderCreatingError = (error) => ({
  type: ORDERS_PROMO_ORDER_CREATING_ERROR,
  error,
});

const ordersProductsOrderCreating = () => ({
  type: ORDERS_PRODUCTS_ORDER_CREATING,
});

const ordersProductsOrderCreatingSuccess = (order: PaymentApiOrder) => ({
  type: ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS,
  order,
});

const ordersProductsOrderCreatingError = (error) => ({
  type: ORDERS_PRODUCTS_ORDER_CREATING_ERROR,
  error,
});

const ordersLoadingAction = () => ({
  type: ORDERS_LOADING,
});

const ordersLoadingSuccessAction = (orders: ProductOrder[], groupByDate: boolean) => ({
  type: ORDERS_LOADING_SUCCESS,
  orders,
  groupByDate,
});

const ordersLoadingErrorAction = (error: any) => ({
  type: ORDERS_LOADING_ERROR,
  error,
});

const orderDetailsAction = (order: ProductOrder) => ({
  type: ORDER_DETAILS_SUCCESS,
  order,
});

const orderCancelStartAction = () => ({
  type: ORDER_CANCEL_START,
});

const orderCancelErrorAction = (error: any) => ({
  type: ORDER_CANCEL_ERROR,
  error,
});

const orderCancelSuccessAction = (order: PaymentApiOrder) => ({
  type: ORDER_CANCEL_SUCCESS,
  order,
});

export const orderClearAction = () => ({
  type: ORDER_CLEAR,
});

export const orderClearErrorMessageAction = () => ({
  type: ORDER_CLEAR_ERROR_MESSAGE,
});
