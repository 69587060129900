import React, { Component } from "react";
import {
  PageContent,
  BlockTitle,
  F7Sheet,
  List,
  ListItem,
  Block,
} from "framework7-react";
import { IApplicationStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import { compose } from "redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { Sheet as SheetNamespace } from "framework7/components/sheet/sheet";
import { Sheet } from "../../components/Sheet";

import "./style.less";
import { Transaction } from "../../types/commonapi";
import { formatPrice } from "../../utils";

type Props = WithTranslation &
  F7Sheet.Props & {
    item: Transaction;
  };

type State = {};

class OperationDetailsSheetPage extends Component<Props, State> {
  _sheet: SheetNamespace.Sheet = null;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.$f7ready(($f7) => {
      this._sheet = $f7.sheet.get("#select_category_subcategory_sheet");
    });
  }

  render() {
    const { t, item, ...rest } = this.props;

    return (
      <Sheet
        id="operation_details_sheet"
        className="operation-details-sheet"
        swipeToClose
        backdrop
        swipeToStep
        style={{ height: "auto" }}
        {...rest}
      >
        <PageContent>
          {item ? (
            <>
              <div className="sheet-modal-swipe-step">
                <BlockTitle medium>{t("Operation Details")}</BlockTitle>
                <Block className="item-title">{t("Receiver name")}</Block>
                <Block className="item-value">{item.buyerEmail}</Block>
                <Block className="item-title">{t("Sender Name")}</Block>
                <Block className="item-value">{item.payerEmail}</Block>
                <Block className="item-title">{t("Type Transaction")}</Block>
                <Block className="item-value">{item.transactionType}</Block>
                <Block className="item-title">
                  {t("Additional Information")}
                </Block>
                <Block className="item-value">None</Block>
              </div>
              {/* Rest of the sheet content that will opened with swipe */}
              <Block className="item-title">{t("ID")}</Block>
              <Block className="item-value">{item.transactionUid}</Block>
              {item.creditCard && (
                <>
                  <Block className="item-title">{t("Card")}</Block>
                  <Block className="item-value">
                    {item.creditCard.cardMask}
                  </Block>
                </>
              )}
              <Block className="item-title">{t("Amount")}</Block>
              <Block className="item-value">
                {formatPrice(item.amount, item.currencyCode)}
              </Block>
              <Block className="item-title">{t("Date")}</Block>
              <Block className="item-value">{item.transactionDate}</Block>
              <Block className="item-title">{t("Time")}</Block>
              <Block className="item-value">{item.transactionDate}</Block>
              <Block className="item-title">{t("State")}</Block>
              <Block className="item-value">{item.transactionState}</Block>
            </>
          ) : null}
        </PageContent>
      </Sheet>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => {
  return {};
};

export default compose<
  React.ComponentClass<
    F7Sheet.Props & {
      item: Transaction;
    }
  >
>(
  withTranslation(),
  connect(mapStateToProps, null)
)(OperationDetailsSheetPage);
