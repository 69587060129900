import { AnyAction } from "redux";
import {
  MESSAGE_SENDING,
  MESSAGE_SENDING_ERROR,
  MESSAGE_SENDING_SUCCESS,
} from "../actions/contactSupportActions";

export interface SupportMessage {
  email?: string;
  subject?: string;
  message?: string;
  parameters?: { [key: string]: string; };
}

export interface IContactSupportState {
  sending?: boolean,
  error?: any
}

const initialState: IContactSupportState = {
  sending: false,
  error: null
}

const profileReducer = (state = initialState, action: AnyAction): IContactSupportState => {
  switch (action.type) {
    case MESSAGE_SENDING:
      return {
        ...state,
        sending: true,
        error: null
      }
    case MESSAGE_SENDING_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null
      }
    case MESSAGE_SENDING_ERROR:
      return {
        ...state,
        sending: false,
        error: action.error
      }
    default:
      return state
  }
}

export default profileReducer
