import React, { FC, useState, memo } from "react";
import { List, ListInput, Row, Button, Icon, Col, BlockTitle, Stepper } from "framework7-react";
import { Product } from "./ThirdPartyOrder";
import { useTranslation } from "react-i18next";
import "./style.less";
import StepperComponent from "../StepperComponent/StepperComponent";

interface ProductInfoProps {
  product: Product;
  onProductChange(fieldName, index, value): void;
  removeProduct(id: number): void;
  onFieldClick(): void;
}

const ProductInfo: FC<ProductInfoProps> = ({
  product,
  onProductChange,
  removeProduct,
  onFieldClick,
}: ProductInfoProps) => {
  const { t } = useTranslation();

  const handleChangeQuantity = (value) => {
    if (value < 1) {
      value = 1;
    } else if (value >= 999) {
      value = 999;
    }
    onProductChange("quantity", product.id, value);
  };

  return (
    <List>
      <Row className="row-mobile">
        <Col width="70" className="div-mobile">
          <ListInput
            name="link"
            placeholder={t("e.g www.amazon.ca/products..").toString()}
            type="url"
            label={t("Link").toString()}
            onFocus={onFieldClick}
            errorMessage={t(product.errors["link"])}
            errorMessageForce
            onChange={(e) => onProductChange("link", product.id, e.target.value)}
            className="no-marker"
          />
          <ListInput
            name="comment"
            type="text"
            label={t("Product name and details").toString()}
            onFocus={onFieldClick}
            errorMessage={t(product.errors["comment"])}
            errorMessageForce
            onChange={(e) => onProductChange("comment", product.id, e.target.value)}
            placeholder={t("Product name, size, color, etc.").toString()}
            className="no-marker"
          />
          <ListInput
            name="priceInCad"
            type="text"
            label={t("Price in CAD").toString()}
            onFocus={onFieldClick}
            errorMessage={t(product.errors["priceInCad"])}
            errorMessageForce
            value={product.priceInCad}
            onChange={(e) => {
              onProductChange("priceInCad", product.id, e.target.value);
            }}
            onBlur={(_) => {
              try {
                const parsed = parseFloat(product.priceInCad);
                if (isNaN(parsed)) return onProductChange("priceInCad", product.id, "0");
                onProductChange("priceInCad", product.id, parsed.toString());
              } catch (err) {
                return;
              }
            }}
            placeholder={t("Insert the price").toString()}
            className="no-marker"
          />
          <ListInput
            name="usd_price"
            type="text"
            label={t("Calculated price in USD").toString()}
            value={product.calculatedPrice}
            className="no-marker"
          />
        </Col>
        <Col large="30" width="100" className="centre-div">
          <Row className="quantity-div">
            <div className="stepper-container-product-info">
              <StepperComponent
                min={1}
                max={999}
                value={product.quantity}
                onStepperChange={(val) => handleChangeQuantity(val)}
                errorMessage={t(product.errors["quantity"])}
              />
            </div>
            <Button onClick={() => removeProduct(product.id)}>
              <Icon style={{ color: "rgb(221, 36, 0)" }} ios="f7:trash" md="material:delete" />
            </Button>
          </Row>
        </Col>
      </Row>
    </List>
  );
};

export default memo(ProductInfo);
