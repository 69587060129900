import React from 'react';
import {
  Icon
} from 'framework7-react';
import classNames from 'classnames';

import './style.less';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  active?: boolean,
  bordered?: boolean,
}

export default ({ active, className, bordered, ...props }: Props) => (
  <div
    {...props}
    className={classNames('link', 'like-button', active ? 'active' : '', className)}
  >
    {bordered ?
      <Icon className={classNames(active ? 'ic-wish-shadow-active' : 'ic-wish-shadow-inactive')}></Icon> :
      <Icon material={active ? 'favorite' : 'favorite_border'}></Icon>
    }
  </div>
)