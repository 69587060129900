import { Profile } from "../reducers/sessionReducer";
import { IProduct } from "../reducers/productReducer";
import { formatPrice } from "../utils";
import mixpanel from 'mixpanel-browser';
import { ga4react } from "../Setup";
//import ReactGA from 'react-ga';

export type AnalyticsProvider = "mixpanel" | "google" | "console";

type AnalyticsCustomer = {
  customer_uid?: string;
  customer_name?: string;
};

type AnalyticsProduct = AnalyticsCustomer & {
  product_uid?: string;
  product_name?: string;
  product_price?: string;
  product_quantity?: number;
};

type AnalyticsSearch = AnalyticsCustomer & {
  search_query?: string;
};

class Analytics {
  private readonly providers: AnalyticsProvider[];

  constructor(providers: AnalyticsProvider[]) {
    this.providers = providers;
  }

  public addProvider(provider: AnalyticsProvider) {
    this.providers.push(provider);
    console.log('Providers: ' + JSON.stringify(this.providers));
  }

  public openWebsite(profile?: Profile): void {
    const data: AnalyticsCustomer = Analytics.buildCustomerData(profile);
    this.logEvent('Open Website', data);
  }

  public openRegistrationForm(profile?: Profile): void {
    const data: AnalyticsCustomer = Analytics.buildCustomerData(profile);
    this.logEvent('Open registration form', data);
  }

  public completeRegistrationForm(profile?: Profile): void {
    const data: AnalyticsCustomer = Analytics.buildCustomerData(profile);
    this.logEvent('Complete registration form', data);
  }

  public searchProduct(profile?: Profile, query?: string): void {
    const data: AnalyticsSearch = {
      ...Analytics.buildCustomerData(profile),
      search_query: query
    };
    this.logEvent('Search Product', data);
  }

  public openProduct(profile?: Profile, product?: IProduct): void {
    const data: AnalyticsProduct = {
      ...Analytics.buildCustomerData(profile),
      ...Analytics.buildProductData(product),
    };
    this.logEvent('Open Product Details', data);
  }

  public addToWishList(profile?: Profile, product?: IProduct): void {
    const data: AnalyticsProduct = {
      ...Analytics.buildCustomerData(profile),
      ...Analytics.buildProductData(product),
    };
    this.logEvent('Add to Wishlist', data);
  }

  public addToWishListWithUid(profile?: Profile, uid?: string): void {
    const data: AnalyticsProduct = {
      ...Analytics.buildCustomerData(profile),
      product_uid: uid,
    };
    this.logEvent('Add to Wishlist (UID)', data);
  }

  public addToCart(profile?: Profile, product?: IProduct, quantity?: number): void {
    const data: AnalyticsProduct = {
      ...Analytics.buildCustomerData(profile),
      ...Analytics.buildProductData(product),
      product_quantity: quantity
    };
    this.logEvent('Add to Cart', data);
  }

  public openCheckout(profile: Profile): void {
    const data: AnalyticsCustomer = Analytics.buildCustomerData(profile);
    this.logEvent('Open Checkout', data);
  }

  public paymentAttempt(profile: Profile): void {
    const data: AnalyticsCustomer = Analytics.buildCustomerData(profile);
    this.logEvent('Attempt Payment', data);
  }

  public paymentSuccess(profile: Profile): void {
    const data: AnalyticsCustomer = Analytics.buildCustomerData(profile);
    this.logEvent('Successful Payment', data);
  }

  private static buildCustomerData(profile?: Profile): AnalyticsCustomer {
    const data: AnalyticsCustomer = {};
    if (profile && profile.uid) {
      data.customer_uid = profile.uid;
      data.customer_name = profile.person.name + ' ' + profile.person.surname;
    }
    return data;
  }

  private static buildProductData(product?: IProduct): AnalyticsProduct {
    const data: AnalyticsProduct = {};
    if (product && product.uid) {
      data.product_uid = product.uid;
      data.product_name = product.name;
      data.product_price = formatPrice(product.discountedPrice || product.price, product.currencyCode);
    }
    return data;
  }

  private logEvent(event: string, data: any): void {
    this.providers.forEach((provider) => {
      switch (provider) {
        case "console":
          console.log('Event: ' + event + '. Data: ' + JSON.stringify(data));
          break;
        case "mixpanel":
          mixpanel.track(event, data);
          break;
        case "google":
          let label = '';
          let category = '';
          Object.entries(data).forEach(([key, value]) => {
            if (key == 'customer_uid') {
              label += value + ' ';
            }
            if (key == 'customer_name') {
              label += value + ' ';
            }
            if (key == 'product_uid') {
              category += value + ' ';
            }
            if (key == 'product_name') {
              category += value + ' ';
            }
          });
          ga4react.event(event, label, category);
          //ReactGA.event(eventData);
          break;
      }
    });

  }
}

export default Analytics