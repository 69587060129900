import Dom7 from "dom7";
import React from "react";
import {
  Popup,
  F7Popup,
  Page,
  Navbar,
  NavRight,
  Link,
  Block,
  ListInput,
  List,
  Button,
  ListItem,
} from "framework7-react";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { clearRegistration, register, validateRequest } from "../../actions/sessionActions";
import { IClassificator } from "../../reducers/classificatorReducer";
import { CreateAccountRequest, ICreateAccountFormError, Profile } from "../../reducers/sessionReducer";
import connectFilter from "../../store/connectFilter";
import { IApplicationStore } from "../../store/rootReducer";
import { Country } from "../../types/commonapi";
import { withTranslation, WithTranslation } from "react-i18next";

import "./style.less";

type Props = F7Popup.Props &
  Pick<WithTranslation, "t"> & {
    countries: Country[];
    profile?: Profile;
    loading: boolean;
    registrationLoading: boolean;
    error: any;
    registered: boolean;
    inviteCode?: string;
    request?: CreateAccountRequest;
    formErrors?: ICreateAccountFormError[];
    urls: IClassificator[];
    register?(request: CreateAccountRequest, fields: string[]): void;
    validateRequest?(request: CreateAccountRequest, fields: string[]): void;
    clear?(): void;
  };

type State = {
  accepted: boolean;
};

class ShortRegistrationPopup extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      accepted: false,
    };
  }

  private _lock: boolean = false;

  componentDidUpdate(prevProps: Props) {
    const { registrationLoading, request } = this.props;

    if (prevProps.registrationLoading && !registrationLoading) {
      this.setState({ accepted: request.accept });
      this.$f7.preloader.hide();
    }

    this.handleRegister(prevProps);

    if (this.props.inviteCode) {
      this.props.request["affiliateId"] = this.props.inviteCode;
    }
  }

  handleRegister = (prevProps: Props) => {
    const { registrationLoading, error, registered, t } = this.props;

    if (registrationLoading) {
      this.$f7.preloader.show();
    }
    if (this._lock && error && !prevProps.error) {
      this._lock = false;
      this.$f7.preloader.hide();
      this.$f7.dialog.alert(t(error));
    }

    if (this._lock && registered) {
      this._lock = false;
      this.$f7.preloader.hide();
      this.$f7.dialog.alert(
        t(
          "Your registration has been successful. <br> " +
            "You will receive an activation e-mail shortly containing further instructions on how to complete your registration."
        ),
        () => {
          this.reset();
          this.$f7.popup.close();
        }
      );
    }
  };

  getDefaultValue = (fieldName: string, defaultValue: any = ""): any => {
    const { request } = this.props;

    if (
      typeof request[fieldName] !== "undefined" &&
      request[fieldName] !== null &&
      request[fieldName].toString() !== "NaN"
    ) {
      return request[fieldName];
    } else {
      return defaultValue;
    }
  };

  blurInputHandle = (e: { target: { name: any; value: any; type: any; checked: boolean } }) => {
    const { name, value, type } = e.target;
    const { request } = this.props;
    if (!name || !type) throw new Error("Name or type for field is not defined!");

    try {
      switch (type) {
        case "number":
          request[name] = parseFloat(value);
          break;
        case "checkbox":
          request[name] = e.target.checked;
          break;
        default: {
          request[name] = value;
        }
      }
    } catch (err) {}
  };

  smartSelectInputChangeHandle = (e: { target: { value: any; name?: any; type?: any } }) => {
    const { name, value } = e.target;
    const { request } = this.props;
    request[name] = value;
  };

  getErrorProps = (fieldName: string, properties?: any) => {
    const { t } = this.props;
    const optionalProps = properties || {};
    let errorMessage = t("Please fill out this field.");

    let errorMessageForce = false;

    if (this.props.formErrors && this.props.formErrors.length > 0) {
      errorMessageForce = this.props.formErrors.filter((e) => e.parameters.includes(fieldName)).length > 0;
    }

    if (!errorMessageForce) {
      const formError = this.props.formErrors.filter(
        (item) =>
          !!item.parameters.filter((__item) => {
            // @ts-ignore
            if (__item.parameters) {
              // @ts-ignore
              return !!__item.parameters.includes(fieldName);
            }
            return __item == fieldName;
          }).length
      )[0];

      if (formError) {
        errorMessageForce = true;
        errorMessage = t(formError.message);
      }
    }

    return {
      info: t("required").toString(),
      required: true,
      validateOnBlur: true,
      ignoreStoreData: true,
      errorMessage,
      errorMessageForce,
      ...optionalProps,
    };
  };

  registerClickHandle = () => {
    if (this._lock) {
      if (!this.state.accepted) return;
      this._lock = false;
    }

    const { registrationLoading, request, formErrors } = this.props;
    formErrors.length = 0;
    if (!registrationLoading) this.$f7.preloader.show();

    this._lock = true;

    const fields = ["email", "password", "passwordRepeat", "firstName", "lastName", "country"];
    this.props.validateRequest(request, fields);

    if (!formErrors.length && this._lock) this.props.register(request, fields);
  };

  getAcceptLinkUrl = (code: string) => {
    const { urls } = this.props;
    const filtered = urls.filter((u) => u.code === code);
    return filtered.length > 0 ? filtered[0].value : "#";
  };

  reset = () => {
    this.props.clear();
    Dom7(".register-form-block input").each((_index, element) => {
      Dom7(element).val(null);
    });
    this.forceUpdate();
  };

  render() {
    const { className, t, ...props } = this.props;

    return (
      <Popup {...props} className={classNames("register-popup", className)}>
        <Page>
          <Navbar noShadow noHairline title={t("Register")}>
            <NavRight>
              <Link popupClose onClick={() => this.reset()}>
                {t("Close")}
              </Link>
            </NavRight>
          </Navbar>
          <Block className="register-form-block">
            <List noHairlines form className={classNames("register-form-list", "form-list-visible")}>
              <ListInput
                name="email"
                label={t("E-mail").toString()}
                floatingLabel
                type="email"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("email")}
                {...this.getErrorProps("email")}
              />
              <ListInput
                name="firstName"
                label={t("First Name").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                onInput={(e) => {
                  e.target.errorMessage = null;
                }}
                defaultValue={this.getDefaultValue("firstName")}
                {...this.getErrorProps("firstName")}
              />
              <ListInput
                name="lastName"
                label={t("Last Name").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("lastName")}
                {...this.getErrorProps("lastName")}
              />
              <ListInput
                name="country"
                label={t("Registration Country").toString()}
                floatingLabel
                type="select"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("country")}
                {...this.getErrorProps("country")}
              >
                <option key="" value="" />
                {this.props.countries.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </ListInput>
              <ListInput
                name="password"
                label={t("Password").toString()}
                floatingLabel
                type="password"
                placeholder=""
                clearButton
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("password")}
                {...this.getErrorProps("password")}
              />
              <ListInput
                name="passwordRepeat"
                label={t("Repeat Password").toString()}
                floatingLabel
                type="password"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                defaultValue={this.getDefaultValue("passwordRepeat")}
                {...this.getErrorProps("passwordRepeat")}
              />
              <ListInput
                name="affiliateId"
                label={t("Invite Code").toString()}
                floatingLabel
                type="text"
                placeholder=""
                slot="list"
                autocomplete="off"
                onBlur={this.blurInputHandle}
                disabled={!!this.props.inviteCode}
                defaultValue={this.props.inviteCode}
              />
              <ListItem
                name="accept"
                checkbox
                onChange={(e) => {
                  this.blurInputHandle(e);
                  this.setState({ accepted: e.target.checked });
                }}
                className="accept-checkbox"
                checked={this.state.accepted}
                {...this.getErrorProps("accept")}
              >
                <div className="item-title">
                  {t("You agree with our")}&nbsp;
                  <Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(this.getAcceptLinkUrl("PrivacyPolicy"));
                    }}
                  >
                    {t("PrivacyPolicy")}
                  </Link>
                  &nbsp;
                  {t("and")}&nbsp;
                  <Link
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(this.getAcceptLinkUrl("TermsAndConditions"));
                    }}
                  >
                    {t("TermsAndConditions")}
                  </Link>
                  &nbsp;
                </div>
              </ListItem>
            </List>

            <Block>
              <Button
                fill
                large
                round
                raised
                onClick={this.registerClickHandle}
                disabled={this.props.registrationLoading}
              >
                {t("Submit")}
              </Button>
            </Block>
          </Block>
        </Page>
      </Popup>
    );
  }
}

const mapStateToProps = (state: IApplicationStore) => ({
  countries: state.classificatorReducer.countryClassificator,
  profile: state.sessionReducer.profile,
  loading: state.sessionReducer.loading,
  registrationLoading: state.sessionReducer.registrationLoading,
  error: state.sessionReducer.registrationError,
  formErrors: state.sessionReducer.formErrors,
  request: state.sessionReducer.request,
  registered: state.sessionReducer.registered,
  urls: state.classificatorReducer.entitiesClassificators.Url_app,
});

const mapDispatchToProps = (dispatch: any) => ({
  register: (request: CreateAccountRequest, fields: string[]) => dispatch(register(request, fields)),
  validateRequest: (request: CreateAccountRequest, fields: string[]) => dispatch(validateRequest(request, fields)),
  clear: () => dispatch(clearRegistration()),
});

export default compose<any>(
  connectFilter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ShortRegistrationPopup);
