import React from "react";

export default () => (
  <svg
    className="ic-crown"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.5961 4.48087C15.7235 4.01394 15.1793 3.65654 14.8014 3.95888L11 7L8.44721 1.89443C8.26295 1.5259 7.73705 1.5259 7.55279 1.89443L5 7L1.19861 3.95889C0.820682 3.65655 0.276535 4.01395 0.403881 4.48088L2.79907 13.2632C2.91773 13.6982 3.31288 14 3.76383 14H12.2362C12.6872 14 13.0824 13.6982 13.201 13.2632L15.5961 4.48087Z" />
  </svg>
);
