import { IApplicationStore } from "../store/rootReducer"
import { getPlatform, Platform } from '../utils'
import {
  BannerControllerApi,
  PublicControllerApi,
} from "../types/marketplaceapi"

export const MARKETING_BANNERS_LIST_LOADING = 'MARKETING_BANNERS_LIST_LOADING'
export const MARKETING_BANNERS_LIST_LOADING_SUCCESS = 'MARKETING_BANNERS_LIST_LOADING_SUCCESS'

function getChannel() {
  const platform = getPlatform()
  switch (platform) {
    case Platform.Android:
      return 'android'
    case Platform.iOS:
      return 'ios'
    default:
      return 'web'
  }
}

const BANNER_COUNT = 5;

export const loadMarketingBanners = () => async (dispatch: any, getState: () => IApplicationStore) => {
  dispatch({ type: MARKETING_BANNERS_LIST_LOADING })

  const state = getState()
  const { logged } = state.sessionReducer;

  try {
    const channel = getChannel();
    const items = (logged
      ? (await new BannerControllerApi().bannerListUsingGET(channel, BANNER_COUNT))
      : (await new PublicControllerApi().bannerListUsingGET1(channel, BANNER_COUNT))
    ).body || []
    dispatch({ type: MARKETING_BANNERS_LIST_LOADING_SUCCESS, banners: items })
  } catch (error) { }
}

export const registerBannerClick = (bannerUid: string, channel?: string) => async (_dispatch: any, _getState: () => IApplicationStore) => {
  try {
    const state = _getState()
    const { logged } = state.sessionReducer;

    if (!channel) {
      channel = getChannel();
    }

    if (logged) {
      await new BannerControllerApi().processManualClickUsingGET(bannerUid, channel);
    } else {
      await new PublicControllerApi().processManualClickUsingGET1(bannerUid, channel);
    }
  } catch (error) { }
}
