import { Card } from "../types/commonapi";
import { loadLinkedPaymentCardsList, loadPaymentCardsList } from "../actions/paymentCardsActions";
import { IApplicationStore } from "./rootReducer";
import { connect } from "react-redux";

export interface ICardsProps {
  loading?: boolean;
  error?: any;
  cards: Card[];
  hasCards?: boolean;
  loadPaymentCardsList(): () => void;
  loadPaymentCardsList1(): () => void;
}

const mapStateToProps = (state: IApplicationStore) => ({
  ...state.paymentCardsReducer,
  hasCards: state.paymentCardsReducer.cards && state.paymentCardsReducer.cards.length > 0,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadPaymentCardsList: () => dispatch(loadPaymentCardsList()),
  loadPaymentCardsList1: () => dispatch(loadLinkedPaymentCardsList()),
});

export default connect(mapStateToProps, mapDispatchToProps);
